unit View.Tokens.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewTokensEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbtok_Id: TLabel;
    edtok_Id: TDBEdit;
    lbusr_Id: TLabel;
    edusr_Id: TDBEdit;
    lbtok_Token: TLabel;
    edtok_Token: TDBEdit;
    lbtok_ValidUntil: TLabel;
    edtok_ValidUntil: TDBEdit;
    lbtok_ValidQty: TLabel;
    edtok_ValidQty: TDBEdit;
    XDataClientTokensGet: TXDataWebClient;
    XDataClientTokensDelete: TXDataWebClient;
    TokensDataset: TXDataWebDataSet;
    TokensDatasettok_Id: TIntegerField;
    TokensDatasetusr_Id: TIntegerField;
    TokensDatasettok_Token: TStringField;
    TokensDatasettok_ValidUntil: TDateTimeField;
    TokensDatasettok_ValidQty: TIntegerField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientTokensGetLoad(Response: TXDataClientResponse);
    procedure XDataClientTokensDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientTokensDeleteError(Error: TXDataClientError);
    procedure TokensDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewTokensEdit: TFViewTokensEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewTokensEdit }

procedure TFViewTokensEdit.btSaveClick(Sender: TObject);
begin
  if TokensDataset.State in dsEditModes then
  begin
        TokensDataset.Post;
    TokensDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewTokensEdit.btDeleteClick(Sender: TObject);
begin
  if TokensDataset.State in dsEditModes then
    TokensDataset.Post;
  XDataClientTokensDelete.Delete(TokensDataset.EntitySetName,
    TJSObject(TokensDataset.CurrentData));
end;


class function TFViewTokensEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewTokensEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewTokensEdit.InsertEntity;
begin
  TokensDataset.Close;
  TokensDataset.SetJsonData('{}');
  TokensDataset.Open;
  TokensDataset.Insert;
end;

class function TFViewTokensEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewTokensEdit(AForm).FInserting := AInserting;
    TFViewTokensEdit(AForm).FId := AId;
    TFViewTokensEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewTokensEdit(AForm).InsertEntity
    else
      TFViewTokensEdit(AForm).LoadEntity;
    TFViewTokensEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewTokensEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewTokensEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    TokensDataset.Connection.Model.DefaultSchema.FindEntityType(
      TokensDataset.EntitySetName
    )
  );
  XDataClientTokensGet.Get(TokensDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewTokensEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewTokensEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewTokensEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewTokensEdit.XDataClientTokensGetLoad(Response: TXDataClientResponse);
begin
  TokensDataset.Close;
  TokensDataset.SetJsonData(Response.Result);
  TokensDataset.Open;
  
  TokensDataset.Edit;  
end;

procedure TFViewTokensEdit.XDataClientTokensDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewTokensEdit.XDataClientTokensDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewTokensEdit.TokensDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewTokensEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewTokensEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbtok_Id := TLabel.Create('view.tokens.form.lbtok_Id');
  lbusr_Id := TLabel.Create('view.tokens.form.lbusr_Id');
  lbtok_Token := TLabel.Create('view.tokens.form.lbtok_Token');
  lbtok_ValidUntil := TLabel.Create('view.tokens.form.lbtok_ValidUntil');
  lbtok_ValidQty := TLabel.Create('view.tokens.form.lbtok_ValidQty');
  pnlMessage := TPanel.Create('view.tokens.form.message');
  lbMessage := TLabel.Create('view.tokens.form.message.label');
  btCloseMessage := TButton.Create('view.tokens.form.message.button');
  btCancel := TButton.Create('view.tokens.form.btBack');
  btSave := TButton.Create('view.tokens.form.btSave');
  btDelete := TButton.Create('view.tokens.form.btDelete');
  edtok_Id := TDBEdit.Create('view.tokens.form.edtok_Id');
  edusr_Id := TDBEdit.Create('view.tokens.form.edusr_Id');
  edtok_Token := TDBEdit.Create('view.tokens.form.edtok_Token');
  edtok_ValidUntil := TDBEdit.Create('view.tokens.form.edtok_ValidUntil');
  edtok_ValidQty := TDBEdit.Create('view.tokens.form.edtok_ValidQty');
  DataSource := TDataSource.Create(Self);
  TokensDataset := TXDataWebDataSet.Create(Self);
  TokensDatasettok_Id := TIntegerField.Create(Self);
  TokensDatasetusr_Id := TIntegerField.Create(Self);
  TokensDatasettok_Token := TStringField.Create(Self);
  TokensDatasettok_ValidUntil := TDateTimeField.Create(Self);
  TokensDatasettok_ValidQty := TIntegerField.Create(Self);
  XDataClientTokensGet := TXDataWebClient.Create(Self);
  XDataClientTokensDelete := TXDataWebClient.Create(Self);

  lbtok_Id.BeforeLoadDFMValues;
  lbusr_Id.BeforeLoadDFMValues;
  lbtok_Token.BeforeLoadDFMValues;
  lbtok_ValidUntil.BeforeLoadDFMValues;
  lbtok_ValidQty.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edtok_Id.BeforeLoadDFMValues;
  edusr_Id.BeforeLoadDFMValues;
  edtok_Token.BeforeLoadDFMValues;
  edtok_ValidUntil.BeforeLoadDFMValues;
  edtok_ValidQty.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  TokensDataset.BeforeLoadDFMValues;
  TokensDatasettok_Id.BeforeLoadDFMValues;
  TokensDatasetusr_Id.BeforeLoadDFMValues;
  TokensDatasettok_Token.BeforeLoadDFMValues;
  TokensDatasettok_ValidUntil.BeforeLoadDFMValues;
  TokensDatasettok_ValidQty.BeforeLoadDFMValues;
  XDataClientTokensGet.BeforeLoadDFMValues;
  XDataClientTokensDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewTokensEdit';
    Width := 593;
    Height := 400;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbtok_Id.SetParentComponent(Self);
    lbtok_Id.Name := 'lbtok_Id';
    lbtok_Id.Left := 24;
    lbtok_Id.Top := 192;
    lbtok_Id.Width := 35;
    lbtok_Id.Height := 13;
    lbtok_Id.Caption := 'tok_Id:';
    lbtok_Id.HeightPercent := 100.000000000000000000;
    lbtok_Id.WidthPercent := 100.000000000000000000;
    lbusr_Id.SetParentComponent(Self);
    lbusr_Id.Name := 'lbusr_Id';
    lbusr_Id.Left := 24;
    lbusr_Id.Top := 219;
    lbusr_Id.Width := 35;
    lbusr_Id.Height := 13;
    lbusr_Id.Caption := 'usr_Id:';
    lbusr_Id.HeightPercent := 100.000000000000000000;
    lbusr_Id.WidthPercent := 100.000000000000000000;
    lbtok_Token.SetParentComponent(Self);
    lbtok_Token.Name := 'lbtok_Token';
    lbtok_Token.Left := 24;
    lbtok_Token.Top := 246;
    lbtok_Token.Width := 54;
    lbtok_Token.Height := 13;
    lbtok_Token.Caption := 'tok_Token:';
    lbtok_Token.HeightPercent := 100.000000000000000000;
    lbtok_Token.WidthPercent := 100.000000000000000000;
    lbtok_ValidUntil.SetParentComponent(Self);
    lbtok_ValidUntil.Name := 'lbtok_ValidUntil';
    lbtok_ValidUntil.Left := 24;
    lbtok_ValidUntil.Top := 273;
    lbtok_ValidUntil.Width := 68;
    lbtok_ValidUntil.Height := 13;
    lbtok_ValidUntil.Caption := 'tok_ValidUntil:';
    lbtok_ValidUntil.HeightPercent := 100.000000000000000000;
    lbtok_ValidUntil.WidthPercent := 100.000000000000000000;
    lbtok_ValidQty.SetParentComponent(Self);
    lbtok_ValidQty.Name := 'lbtok_ValidQty';
    lbtok_ValidQty.Left := 24;
    lbtok_ValidQty.Top := 300;
    lbtok_ValidQty.Width := 65;
    lbtok_ValidQty.Height := 13;
    lbtok_ValidQty.Caption := 'tok_ValidQty:';
    lbtok_ValidQty.HeightPercent := 100.000000000000000000;
    lbtok_ValidQty.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 340;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 340;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 340;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edtok_Id.SetParentComponent(Self);
    edtok_Id.Name := 'edtok_Id';
    edtok_Id.Left := 221;
    edtok_Id.Top := 189;
    edtok_Id.Width := 200;
    edtok_Id.Height := 21;
    edtok_Id.HeightPercent := 100.000000000000000000;
    edtok_Id.TabOrder := 6;
    edtok_Id.Text := 'edtok_Id';
    edtok_Id.WidthPercent := 100.000000000000000000;
    edtok_Id.DataField := 'tok_Id';
    edtok_Id.DataSource := DataSource;
    edusr_Id.SetParentComponent(Self);
    edusr_Id.Name := 'edusr_Id';
    edusr_Id.Left := 221;
    edusr_Id.Top := 216;
    edusr_Id.Width := 200;
    edusr_Id.Height := 21;
    edusr_Id.HeightPercent := 100.000000000000000000;
    edusr_Id.TabOrder := 7;
    edusr_Id.Text := 'edusr_Id';
    edusr_Id.WidthPercent := 100.000000000000000000;
    edusr_Id.DataField := 'usr_Id';
    edusr_Id.DataSource := DataSource;
    edtok_Token.SetParentComponent(Self);
    edtok_Token.Name := 'edtok_Token';
    edtok_Token.Left := 221;
    edtok_Token.Top := 243;
    edtok_Token.Width := 200;
    edtok_Token.Height := 21;
    edtok_Token.HeightPercent := 100.000000000000000000;
    edtok_Token.TabOrder := 8;
    edtok_Token.Text := 'edtok_Token';
    edtok_Token.WidthPercent := 100.000000000000000000;
    edtok_Token.DataField := 'tok_Token';
    edtok_Token.DataSource := DataSource;
    edtok_ValidUntil.SetParentComponent(Self);
    edtok_ValidUntil.Name := 'edtok_ValidUntil';
    edtok_ValidUntil.Left := 221;
    edtok_ValidUntil.Top := 270;
    edtok_ValidUntil.Width := 200;
    edtok_ValidUntil.Height := 21;
    edtok_ValidUntil.HeightPercent := 100.000000000000000000;
    edtok_ValidUntil.TabOrder := 9;
    edtok_ValidUntil.Text := 'edtok_ValidUntil';
    edtok_ValidUntil.WidthPercent := 100.000000000000000000;
    edtok_ValidUntil.DataField := 'tok_ValidUntil';
    edtok_ValidUntil.DataSource := DataSource;
    edtok_ValidQty.SetParentComponent(Self);
    edtok_ValidQty.Name := 'edtok_ValidQty';
    edtok_ValidQty.Left := 221;
    edtok_ValidQty.Top := 297;
    edtok_ValidQty.Width := 200;
    edtok_ValidQty.Height := 21;
    edtok_ValidQty.HeightPercent := 100.000000000000000000;
    edtok_ValidQty.TabOrder := 10;
    edtok_ValidQty.Text := 'edtok_ValidQty';
    edtok_ValidQty.WidthPercent := 100.000000000000000000;
    edtok_ValidQty.DataField := 'tok_ValidQty';
    edtok_ValidQty.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := TokensDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    TokensDataset.SetParentComponent(Self);
    TokensDataset.Name := 'TokensDataset';
    TokensDataset.AfterApplyUpdates := TokensDatasetAfterApplyUpdates;
    TokensDataset.EntitySetName := 'Tokens';
    TokensDataset.Connection := DMConnection.ApiConnection;
    TokensDataset.Left := 176;
    TokensDataset.Top := 8;
    TokensDatasettok_Id.SetParentComponent(TokensDataset);
    TokensDatasettok_Id.Name := 'TokensDatasettok_Id';
    TokensDatasettok_Id.FieldName := 'tok_Id';
    TokensDatasettok_Id.Required := True;
    TokensDatasetusr_Id.SetParentComponent(TokensDataset);
    TokensDatasetusr_Id.Name := 'TokensDatasetusr_Id';
    TokensDatasetusr_Id.FieldName := 'usr_Id';
    TokensDatasettok_Token.SetParentComponent(TokensDataset);
    TokensDatasettok_Token.Name := 'TokensDatasettok_Token';
    TokensDatasettok_Token.FieldName := 'tok_Token';
    TokensDatasettok_Token.Required := True;
    TokensDatasettok_Token.Size := 36;
    TokensDatasettok_ValidUntil.SetParentComponent(TokensDataset);
    TokensDatasettok_ValidUntil.Name := 'TokensDatasettok_ValidUntil';
    TokensDatasettok_ValidUntil.FieldName := 'tok_ValidUntil';
    TokensDatasettok_ValidUntil.Required := True;
    TokensDatasettok_ValidQty.SetParentComponent(TokensDataset);
    TokensDatasettok_ValidQty.Name := 'TokensDatasettok_ValidQty';
    TokensDatasettok_ValidQty.FieldName := 'tok_ValidQty';
    TokensDatasettok_ValidQty.Required := True;
    XDataClientTokensGet.SetParentComponent(Self);
    XDataClientTokensGet.Name := 'XDataClientTokensGet';
    XDataClientTokensGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientTokensGet, Self, 'OnLoad', 'XDataClientTokensGetLoad');
    XDataClientTokensGet.Left := 64;
    XDataClientTokensGet.Top := 8;
    XDataClientTokensDelete.SetParentComponent(Self);
    XDataClientTokensDelete.Name := 'XDataClientTokensDelete';
    XDataClientTokensDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientTokensDelete, Self, 'OnLoad', 'XDataClientTokensDeleteLoad');
    SetEvent(XDataClientTokensDelete, Self, 'OnError', 'XDataClientTokensDeleteError');
    XDataClientTokensDelete.Left := 64;
    XDataClientTokensDelete.Top := 56;
  finally
    lbtok_Id.AfterLoadDFMValues;
    lbusr_Id.AfterLoadDFMValues;
    lbtok_Token.AfterLoadDFMValues;
    lbtok_ValidUntil.AfterLoadDFMValues;
    lbtok_ValidQty.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edtok_Id.AfterLoadDFMValues;
    edusr_Id.AfterLoadDFMValues;
    edtok_Token.AfterLoadDFMValues;
    edtok_ValidUntil.AfterLoadDFMValues;
    edtok_ValidQty.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    TokensDataset.AfterLoadDFMValues;
    TokensDatasettok_Id.AfterLoadDFMValues;
    TokensDatasetusr_Id.AfterLoadDFMValues;
    TokensDatasettok_Token.AfterLoadDFMValues;
    TokensDatasettok_ValidUntil.AfterLoadDFMValues;
    TokensDatasettok_ValidQty.AfterLoadDFMValues;
    XDataClientTokensGet.AfterLoadDFMValues;
    XDataClientTokensDelete.AfterLoadDFMValues;
  end;
end;

end.
