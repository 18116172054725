unit View.Clients.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewClientsEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbcli_Id: TLabel;
    edcli_Id: TDBEdit;
    lbcli_Active: TLabel;
    edcli_Active: TDBEdit;
    lbcli_ClientName: TLabel;
    edcli_ClientName: TDBEdit;
    lbcli_Address: TLabel;
    edcli_Address: TDBEdit;
    lbcli_City: TLabel;
    edcli_City: TDBEdit;
    lbcli_Province: TLabel;
    edcli_Province: TDBEdit;
    lbcli_PostalCode: TLabel;
    edcli_PostalCode: TDBEdit;
    lbcli_Contact1FirstName: TLabel;
    edcli_Contact1FirstName: TDBEdit;
    lbcli_Contact1LastName: TLabel;
    edcli_Contact1LastName: TDBEdit;
    lbcli_Contact1Telephone: TLabel;
    edcli_Contact1Telephone: TDBEdit;
    lbcli_Contact1Email: TLabel;
    edcli_Contact1Email: TDBEdit;
    lbcli_Contact2FirstName: TLabel;
    edcli_Contact2FirstName: TDBEdit;
    lbcli_Contact2LastName: TLabel;
    edcli_Contact2LastName: TDBEdit;
    lbcli_Contact2Telephone: TLabel;
    edcli_Contact2Telephone: TDBEdit;
    lbcli_Contact2Email: TLabel;
    edcli_Contact2Email: TDBEdit;
    lbcli_ChildNotes: TLabel;
    edcli_ChildNotes: TDBEdit;
    lbcli_IsSeasonal: TLabel;
    edcli_IsSeasonal: TDBEdit;
    lbcli_FullTimer: TLabel;
    edcli_FullTimer: TDBEdit;
    lbcli_Notes: TLabel;
    edcli_Notes: TDBEdit;
    lbcli_CreatedBy: TLabel;
    edcli_CreatedBy: TDBEdit;
    lbcli_CreatedDate: TLabel;
    edcli_CreatedDate: TDBEdit;
    lbcli_LastModifiedBy: TLabel;
    edcli_LastModifiedBy: TDBEdit;
    lbcli_LastModifiedDate: TLabel;
    edcli_LastModifiedDate: TDBEdit;
    XDataClientClientsGet: TXDataWebClient;
    XDataClientClientsDelete: TXDataWebClient;
    ClientsDataset: TXDataWebDataSet;
    ClientsDatasetcli_Id: TIntegerField;
    ClientsDatasetcli_Active: TStringField;
    ClientsDatasetcli_ClientName: TStringField;
    ClientsDatasetcli_Address: TStringField;
    ClientsDatasetcli_City: TStringField;
    ClientsDatasetcli_Province: TStringField;
    ClientsDatasetcli_PostalCode: TStringField;
    ClientsDatasetcli_Contact1FirstName: TStringField;
    ClientsDatasetcli_Contact1LastName: TStringField;
    ClientsDatasetcli_Contact1Telephone: TStringField;
    ClientsDatasetcli_Contact1Email: TStringField;
    ClientsDatasetcli_Contact2FirstName: TStringField;
    ClientsDatasetcli_Contact2LastName: TStringField;
    ClientsDatasetcli_Contact2Telephone: TStringField;
    ClientsDatasetcli_Contact2Email: TStringField;
    ClientsDatasetcli_ChildNotes: TStringField;
    ClientsDatasetcli_IsSeasonal: TStringField;
    ClientsDatasetcli_FullTimer: TStringField;
    ClientsDatasetcli_Notes: TStringField;
    ClientsDatasetcli_CreatedBy: TIntegerField;
    ClientsDatasetcli_CreatedDate: TDateTimeField;
    ClientsDatasetcli_LastModifiedBy: TIntegerField;
    ClientsDatasetcli_LastModifiedDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientClientsGetLoad(Response: TXDataClientResponse);
    procedure XDataClientClientsDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientClientsDeleteError(Error: TXDataClientError);
    procedure ClientsDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewClientsEdit: TFViewClientsEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewClientsEdit }

procedure TFViewClientsEdit.btSaveClick(Sender: TObject);
begin
  if ClientsDataset.State in dsEditModes then
  begin
        ClientsDataset.Post;
    ClientsDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewClientsEdit.btDeleteClick(Sender: TObject);
begin
  if ClientsDataset.State in dsEditModes then
    ClientsDataset.Post;
  XDataClientClientsDelete.Delete(ClientsDataset.EntitySetName,
    TJSObject(ClientsDataset.CurrentData));
end;


class function TFViewClientsEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewClientsEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewClientsEdit.InsertEntity;
begin
  ClientsDataset.Close;
  ClientsDataset.SetJsonData('{}');
  ClientsDataset.Open;
  ClientsDataset.Insert;
end;

class function TFViewClientsEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewClientsEdit(AForm).FInserting := AInserting;
    TFViewClientsEdit(AForm).FId := AId;
    TFViewClientsEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewClientsEdit(AForm).InsertEntity
    else
      TFViewClientsEdit(AForm).LoadEntity;
    TFViewClientsEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewClientsEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewClientsEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    ClientsDataset.Connection.Model.DefaultSchema.FindEntityType(
      ClientsDataset.EntitySetName
    )
  );
  XDataClientClientsGet.Get(ClientsDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewClientsEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewClientsEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewClientsEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewClientsEdit.XDataClientClientsGetLoad(Response: TXDataClientResponse);
begin
  ClientsDataset.Close;
  ClientsDataset.SetJsonData(Response.Result);
  ClientsDataset.Open;
  
  ClientsDataset.Edit;  
end;

procedure TFViewClientsEdit.XDataClientClientsDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewClientsEdit.XDataClientClientsDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewClientsEdit.ClientsDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewClientsEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewClientsEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbcli_Id := TLabel.Create('view.clients.form.lbcli_Id');
  lbcli_Active := TLabel.Create('view.clients.form.lbcli_Active');
  lbcli_ClientName := TLabel.Create('view.clients.form.lbcli_ClientName');
  lbcli_Address := TLabel.Create('view.clients.form.lbcli_Address');
  lbcli_City := TLabel.Create('view.clients.form.lbcli_City');
  lbcli_Province := TLabel.Create('view.clients.form.lbcli_Province');
  lbcli_PostalCode := TLabel.Create('view.clients.form.lbcli_PostalCode');
  lbcli_Contact1FirstName := TLabel.Create('view.clients.form.lbcli_Contact1FirstName');
  lbcli_Contact1LastName := TLabel.Create('view.clients.form.lbcli_Contact1LastName');
  lbcli_Contact1Telephone := TLabel.Create('view.clients.form.lbcli_Contact1Telephone');
  lbcli_Contact1Email := TLabel.Create('view.clients.form.lbcli_Contact1Email');
  lbcli_Contact2FirstName := TLabel.Create('view.clients.form.lbcli_Contact2FirstName');
  lbcli_Contact2LastName := TLabel.Create('view.clients.form.lbcli_Contact2LastName');
  lbcli_Contact2Telephone := TLabel.Create('view.clients.form.lbcli_Contact2Telephone');
  lbcli_Contact2Email := TLabel.Create('view.clients.form.lbcli_Contact2Email');
  lbcli_ChildNotes := TLabel.Create('view.clients.form.lbcli_ChildNotes');
  lbcli_IsSeasonal := TLabel.Create('view.clients.form.lbcli_IsSeasonal');
  lbcli_FullTimer := TLabel.Create('view.clients.form.lbcli_FullTimer');
  lbcli_Notes := TLabel.Create('view.clients.form.lbcli_Notes');
  lbcli_CreatedBy := TLabel.Create('view.clients.form.lbcli_CreatedBy');
  lbcli_CreatedDate := TLabel.Create('view.clients.form.lbcli_CreatedDate');
  lbcli_LastModifiedBy := TLabel.Create('view.clients.form.lbcli_LastModifiedBy');
  lbcli_LastModifiedDate := TLabel.Create('view.clients.form.lbcli_LastModifiedDate');
  pnlMessage := TPanel.Create('view.clients.form.message');
  lbMessage := TLabel.Create('view.clients.form.message.label');
  btCloseMessage := TButton.Create('view.clients.form.message.button');
  btCancel := TButton.Create('view.clients.form.btBack');
  btSave := TButton.Create('view.clients.form.btSave');
  btDelete := TButton.Create('view.clients.form.btDelete');
  edcli_Id := TDBEdit.Create('view.clients.form.edcli_Id');
  edcli_Active := TDBEdit.Create('view.clients.form.edcli_Active');
  edcli_ClientName := TDBEdit.Create('view.clients.form.edcli_ClientName');
  edcli_Address := TDBEdit.Create('view.clients.form.edcli_Address');
  edcli_City := TDBEdit.Create('view.clients.form.edcli_City');
  edcli_Province := TDBEdit.Create('view.clients.form.edcli_Province');
  edcli_PostalCode := TDBEdit.Create('view.clients.form.edcli_PostalCode');
  edcli_Contact1FirstName := TDBEdit.Create('view.clients.form.edcli_Contact1FirstName');
  edcli_Contact1LastName := TDBEdit.Create('view.clients.form.edcli_Contact1LastName');
  edcli_Contact1Telephone := TDBEdit.Create('view.clients.form.edcli_Contact1Telephone');
  edcli_Contact1Email := TDBEdit.Create('view.clients.form.edcli_Contact1Email');
  edcli_Contact2FirstName := TDBEdit.Create('view.clients.form.edcli_Contact2FirstName');
  edcli_Contact2LastName := TDBEdit.Create('view.clients.form.edcli_Contact2LastName');
  edcli_Contact2Telephone := TDBEdit.Create('view.clients.form.edcli_Contact2Telephone');
  edcli_Contact2Email := TDBEdit.Create('view.clients.form.edcli_Contact2Email');
  edcli_ChildNotes := TDBEdit.Create('view.clients.form.edcli_ChildNotes');
  edcli_IsSeasonal := TDBEdit.Create('view.clients.form.edcli_IsSeasonal');
  edcli_FullTimer := TDBEdit.Create('view.clients.form.edcli_FullTimer');
  edcli_Notes := TDBEdit.Create('view.clients.form.edcli_Notes');
  edcli_CreatedBy := TDBEdit.Create('view.clients.form.edcli_CreatedBy');
  edcli_CreatedDate := TDBEdit.Create('view.clients.form.edcli_CreatedDate');
  edcli_LastModifiedBy := TDBEdit.Create('view.clients.form.edcli_LastModifiedBy');
  edcli_LastModifiedDate := TDBEdit.Create('view.clients.form.edcli_LastModifiedDate');
  DataSource := TDataSource.Create(Self);
  ClientsDataset := TXDataWebDataSet.Create(Self);
  ClientsDatasetcli_Id := TIntegerField.Create(Self);
  ClientsDatasetcli_Active := TStringField.Create(Self);
  ClientsDatasetcli_ClientName := TStringField.Create(Self);
  ClientsDatasetcli_Address := TStringField.Create(Self);
  ClientsDatasetcli_City := TStringField.Create(Self);
  ClientsDatasetcli_Province := TStringField.Create(Self);
  ClientsDatasetcli_PostalCode := TStringField.Create(Self);
  ClientsDatasetcli_Contact1FirstName := TStringField.Create(Self);
  ClientsDatasetcli_Contact1LastName := TStringField.Create(Self);
  ClientsDatasetcli_Contact1Telephone := TStringField.Create(Self);
  ClientsDatasetcli_Contact1Email := TStringField.Create(Self);
  ClientsDatasetcli_Contact2FirstName := TStringField.Create(Self);
  ClientsDatasetcli_Contact2LastName := TStringField.Create(Self);
  ClientsDatasetcli_Contact2Telephone := TStringField.Create(Self);
  ClientsDatasetcli_Contact2Email := TStringField.Create(Self);
  ClientsDatasetcli_ChildNotes := TStringField.Create(Self);
  ClientsDatasetcli_IsSeasonal := TStringField.Create(Self);
  ClientsDatasetcli_FullTimer := TStringField.Create(Self);
  ClientsDatasetcli_Notes := TStringField.Create(Self);
  ClientsDatasetcli_CreatedBy := TIntegerField.Create(Self);
  ClientsDatasetcli_CreatedDate := TDateTimeField.Create(Self);
  ClientsDatasetcli_LastModifiedBy := TIntegerField.Create(Self);
  ClientsDatasetcli_LastModifiedDate := TDateTimeField.Create(Self);
  XDataClientClientsGet := TXDataWebClient.Create(Self);
  XDataClientClientsDelete := TXDataWebClient.Create(Self);

  lbcli_Id.BeforeLoadDFMValues;
  lbcli_Active.BeforeLoadDFMValues;
  lbcli_ClientName.BeforeLoadDFMValues;
  lbcli_Address.BeforeLoadDFMValues;
  lbcli_City.BeforeLoadDFMValues;
  lbcli_Province.BeforeLoadDFMValues;
  lbcli_PostalCode.BeforeLoadDFMValues;
  lbcli_Contact1FirstName.BeforeLoadDFMValues;
  lbcli_Contact1LastName.BeforeLoadDFMValues;
  lbcli_Contact1Telephone.BeforeLoadDFMValues;
  lbcli_Contact1Email.BeforeLoadDFMValues;
  lbcli_Contact2FirstName.BeforeLoadDFMValues;
  lbcli_Contact2LastName.BeforeLoadDFMValues;
  lbcli_Contact2Telephone.BeforeLoadDFMValues;
  lbcli_Contact2Email.BeforeLoadDFMValues;
  lbcli_ChildNotes.BeforeLoadDFMValues;
  lbcli_IsSeasonal.BeforeLoadDFMValues;
  lbcli_FullTimer.BeforeLoadDFMValues;
  lbcli_Notes.BeforeLoadDFMValues;
  lbcli_CreatedBy.BeforeLoadDFMValues;
  lbcli_CreatedDate.BeforeLoadDFMValues;
  lbcli_LastModifiedBy.BeforeLoadDFMValues;
  lbcli_LastModifiedDate.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edcli_Id.BeforeLoadDFMValues;
  edcli_Active.BeforeLoadDFMValues;
  edcli_ClientName.BeforeLoadDFMValues;
  edcli_Address.BeforeLoadDFMValues;
  edcli_City.BeforeLoadDFMValues;
  edcli_Province.BeforeLoadDFMValues;
  edcli_PostalCode.BeforeLoadDFMValues;
  edcli_Contact1FirstName.BeforeLoadDFMValues;
  edcli_Contact1LastName.BeforeLoadDFMValues;
  edcli_Contact1Telephone.BeforeLoadDFMValues;
  edcli_Contact1Email.BeforeLoadDFMValues;
  edcli_Contact2FirstName.BeforeLoadDFMValues;
  edcli_Contact2LastName.BeforeLoadDFMValues;
  edcli_Contact2Telephone.BeforeLoadDFMValues;
  edcli_Contact2Email.BeforeLoadDFMValues;
  edcli_ChildNotes.BeforeLoadDFMValues;
  edcli_IsSeasonal.BeforeLoadDFMValues;
  edcli_FullTimer.BeforeLoadDFMValues;
  edcli_Notes.BeforeLoadDFMValues;
  edcli_CreatedBy.BeforeLoadDFMValues;
  edcli_CreatedDate.BeforeLoadDFMValues;
  edcli_LastModifiedBy.BeforeLoadDFMValues;
  edcli_LastModifiedDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  ClientsDataset.BeforeLoadDFMValues;
  ClientsDatasetcli_Id.BeforeLoadDFMValues;
  ClientsDatasetcli_Active.BeforeLoadDFMValues;
  ClientsDatasetcli_ClientName.BeforeLoadDFMValues;
  ClientsDatasetcli_Address.BeforeLoadDFMValues;
  ClientsDatasetcli_City.BeforeLoadDFMValues;
  ClientsDatasetcli_Province.BeforeLoadDFMValues;
  ClientsDatasetcli_PostalCode.BeforeLoadDFMValues;
  ClientsDatasetcli_Contact1FirstName.BeforeLoadDFMValues;
  ClientsDatasetcli_Contact1LastName.BeforeLoadDFMValues;
  ClientsDatasetcli_Contact1Telephone.BeforeLoadDFMValues;
  ClientsDatasetcli_Contact1Email.BeforeLoadDFMValues;
  ClientsDatasetcli_Contact2FirstName.BeforeLoadDFMValues;
  ClientsDatasetcli_Contact2LastName.BeforeLoadDFMValues;
  ClientsDatasetcli_Contact2Telephone.BeforeLoadDFMValues;
  ClientsDatasetcli_Contact2Email.BeforeLoadDFMValues;
  ClientsDatasetcli_ChildNotes.BeforeLoadDFMValues;
  ClientsDatasetcli_IsSeasonal.BeforeLoadDFMValues;
  ClientsDatasetcli_FullTimer.BeforeLoadDFMValues;
  ClientsDatasetcli_Notes.BeforeLoadDFMValues;
  ClientsDatasetcli_CreatedBy.BeforeLoadDFMValues;
  ClientsDatasetcli_CreatedDate.BeforeLoadDFMValues;
  ClientsDatasetcli_LastModifiedBy.BeforeLoadDFMValues;
  ClientsDatasetcli_LastModifiedDate.BeforeLoadDFMValues;
  XDataClientClientsGet.BeforeLoadDFMValues;
  XDataClientClientsDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewClientsEdit';
    Width := 593;
    Height := 886;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbcli_Id.SetParentComponent(Self);
    lbcli_Id.Name := 'lbcli_Id';
    lbcli_Id.Left := 24;
    lbcli_Id.Top := 192;
    lbcli_Id.Width := 29;
    lbcli_Id.Height := 13;
    lbcli_Id.Caption := 'cli_Id:';
    lbcli_Id.HeightPercent := 100.000000000000000000;
    lbcli_Id.WidthPercent := 100.000000000000000000;
    lbcli_Active.SetParentComponent(Self);
    lbcli_Active.Name := 'lbcli_Active';
    lbcli_Active.Left := 24;
    lbcli_Active.Top := 219;
    lbcli_Active.Width := 49;
    lbcli_Active.Height := 13;
    lbcli_Active.Caption := 'cli_Active:';
    lbcli_Active.HeightPercent := 100.000000000000000000;
    lbcli_Active.WidthPercent := 100.000000000000000000;
    lbcli_ClientName.SetParentComponent(Self);
    lbcli_ClientName.Name := 'lbcli_ClientName';
    lbcli_ClientName.Left := 24;
    lbcli_ClientName.Top := 246;
    lbcli_ClientName.Width := 73;
    lbcli_ClientName.Height := 13;
    lbcli_ClientName.Caption := 'cli_ClientName:';
    lbcli_ClientName.HeightPercent := 100.000000000000000000;
    lbcli_ClientName.WidthPercent := 100.000000000000000000;
    lbcli_Address.SetParentComponent(Self);
    lbcli_Address.Name := 'lbcli_Address';
    lbcli_Address.Left := 24;
    lbcli_Address.Top := 273;
    lbcli_Address.Width := 58;
    lbcli_Address.Height := 13;
    lbcli_Address.Caption := 'cli_Address:';
    lbcli_Address.HeightPercent := 100.000000000000000000;
    lbcli_Address.WidthPercent := 100.000000000000000000;
    lbcli_City.SetParentComponent(Self);
    lbcli_City.Name := 'lbcli_City';
    lbcli_City.Left := 24;
    lbcli_City.Top := 300;
    lbcli_City.Width := 38;
    lbcli_City.Height := 13;
    lbcli_City.Caption := 'cli_City:';
    lbcli_City.HeightPercent := 100.000000000000000000;
    lbcli_City.WidthPercent := 100.000000000000000000;
    lbcli_Province.SetParentComponent(Self);
    lbcli_Province.Name := 'lbcli_Province';
    lbcli_Province.Left := 24;
    lbcli_Province.Top := 327;
    lbcli_Province.Width := 60;
    lbcli_Province.Height := 13;
    lbcli_Province.Caption := 'cli_Province:';
    lbcli_Province.HeightPercent := 100.000000000000000000;
    lbcli_Province.WidthPercent := 100.000000000000000000;
    lbcli_PostalCode.SetParentComponent(Self);
    lbcli_PostalCode.Name := 'lbcli_PostalCode';
    lbcli_PostalCode.Left := 24;
    lbcli_PostalCode.Top := 354;
    lbcli_PostalCode.Width := 73;
    lbcli_PostalCode.Height := 13;
    lbcli_PostalCode.Caption := 'cli_PostalCode:';
    lbcli_PostalCode.HeightPercent := 100.000000000000000000;
    lbcli_PostalCode.WidthPercent := 100.000000000000000000;
    lbcli_Contact1FirstName.SetParentComponent(Self);
    lbcli_Contact1FirstName.Name := 'lbcli_Contact1FirstName';
    lbcli_Contact1FirstName.Left := 24;
    lbcli_Contact1FirstName.Top := 381;
    lbcli_Contact1FirstName.Width := 111;
    lbcli_Contact1FirstName.Height := 13;
    lbcli_Contact1FirstName.Caption := 'cli_Contact1FirstName:';
    lbcli_Contact1FirstName.HeightPercent := 100.000000000000000000;
    lbcli_Contact1FirstName.WidthPercent := 100.000000000000000000;
    lbcli_Contact1LastName.SetParentComponent(Self);
    lbcli_Contact1LastName.Name := 'lbcli_Contact1LastName';
    lbcli_Contact1LastName.Left := 24;
    lbcli_Contact1LastName.Top := 408;
    lbcli_Contact1LastName.Width := 110;
    lbcli_Contact1LastName.Height := 13;
    lbcli_Contact1LastName.Caption := 'cli_Contact1LastName:';
    lbcli_Contact1LastName.HeightPercent := 100.000000000000000000;
    lbcli_Contact1LastName.WidthPercent := 100.000000000000000000;
    lbcli_Contact1Telephone.SetParentComponent(Self);
    lbcli_Contact1Telephone.Name := 'lbcli_Contact1Telephone';
    lbcli_Contact1Telephone.Left := 24;
    lbcli_Contact1Telephone.Top := 435;
    lbcli_Contact1Telephone.Width := 113;
    lbcli_Contact1Telephone.Height := 13;
    lbcli_Contact1Telephone.Caption := 'cli_Contact1Telephone:';
    lbcli_Contact1Telephone.HeightPercent := 100.000000000000000000;
    lbcli_Contact1Telephone.WidthPercent := 100.000000000000000000;
    lbcli_Contact1Email.SetParentComponent(Self);
    lbcli_Contact1Email.Name := 'lbcli_Contact1Email';
    lbcli_Contact1Email.Left := 24;
    lbcli_Contact1Email.Top := 462;
    lbcli_Contact1Email.Width := 87;
    lbcli_Contact1Email.Height := 13;
    lbcli_Contact1Email.Caption := 'cli_Contact1Email:';
    lbcli_Contact1Email.HeightPercent := 100.000000000000000000;
    lbcli_Contact1Email.WidthPercent := 100.000000000000000000;
    lbcli_Contact2FirstName.SetParentComponent(Self);
    lbcli_Contact2FirstName.Name := 'lbcli_Contact2FirstName';
    lbcli_Contact2FirstName.Left := 24;
    lbcli_Contact2FirstName.Top := 489;
    lbcli_Contact2FirstName.Width := 111;
    lbcli_Contact2FirstName.Height := 13;
    lbcli_Contact2FirstName.Caption := 'cli_Contact2FirstName:';
    lbcli_Contact2FirstName.HeightPercent := 100.000000000000000000;
    lbcli_Contact2FirstName.WidthPercent := 100.000000000000000000;
    lbcli_Contact2LastName.SetParentComponent(Self);
    lbcli_Contact2LastName.Name := 'lbcli_Contact2LastName';
    lbcli_Contact2LastName.Left := 24;
    lbcli_Contact2LastName.Top := 516;
    lbcli_Contact2LastName.Width := 110;
    lbcli_Contact2LastName.Height := 13;
    lbcli_Contact2LastName.Caption := 'cli_Contact2LastName:';
    lbcli_Contact2LastName.HeightPercent := 100.000000000000000000;
    lbcli_Contact2LastName.WidthPercent := 100.000000000000000000;
    lbcli_Contact2Telephone.SetParentComponent(Self);
    lbcli_Contact2Telephone.Name := 'lbcli_Contact2Telephone';
    lbcli_Contact2Telephone.Left := 24;
    lbcli_Contact2Telephone.Top := 543;
    lbcli_Contact2Telephone.Width := 113;
    lbcli_Contact2Telephone.Height := 13;
    lbcli_Contact2Telephone.Caption := 'cli_Contact2Telephone:';
    lbcli_Contact2Telephone.HeightPercent := 100.000000000000000000;
    lbcli_Contact2Telephone.WidthPercent := 100.000000000000000000;
    lbcli_Contact2Email.SetParentComponent(Self);
    lbcli_Contact2Email.Name := 'lbcli_Contact2Email';
    lbcli_Contact2Email.Left := 24;
    lbcli_Contact2Email.Top := 570;
    lbcli_Contact2Email.Width := 87;
    lbcli_Contact2Email.Height := 13;
    lbcli_Contact2Email.Caption := 'cli_Contact2Email:';
    lbcli_Contact2Email.HeightPercent := 100.000000000000000000;
    lbcli_Contact2Email.WidthPercent := 100.000000000000000000;
    lbcli_ChildNotes.SetParentComponent(Self);
    lbcli_ChildNotes.Name := 'lbcli_ChildNotes';
    lbcli_ChildNotes.Left := 24;
    lbcli_ChildNotes.Top := 597;
    lbcli_ChildNotes.Width := 70;
    lbcli_ChildNotes.Height := 13;
    lbcli_ChildNotes.Caption := 'cli_ChildNotes:';
    lbcli_ChildNotes.HeightPercent := 100.000000000000000000;
    lbcli_ChildNotes.WidthPercent := 100.000000000000000000;
    lbcli_IsSeasonal.SetParentComponent(Self);
    lbcli_IsSeasonal.Name := 'lbcli_IsSeasonal';
    lbcli_IsSeasonal.Left := 24;
    lbcli_IsSeasonal.Top := 624;
    lbcli_IsSeasonal.Width := 71;
    lbcli_IsSeasonal.Height := 13;
    lbcli_IsSeasonal.Caption := 'cli_IsSeasonal:';
    lbcli_IsSeasonal.HeightPercent := 100.000000000000000000;
    lbcli_IsSeasonal.WidthPercent := 100.000000000000000000;
    lbcli_FullTimer.SetParentComponent(Self);
    lbcli_FullTimer.Name := 'lbcli_FullTimer';
    lbcli_FullTimer.Left := 24;
    lbcli_FullTimer.Top := 651;
    lbcli_FullTimer.Width := 61;
    lbcli_FullTimer.Height := 13;
    lbcli_FullTimer.Caption := 'cli_FullTimer:';
    lbcli_FullTimer.HeightPercent := 100.000000000000000000;
    lbcli_FullTimer.WidthPercent := 100.000000000000000000;
    lbcli_Notes.SetParentComponent(Self);
    lbcli_Notes.Name := 'lbcli_Notes';
    lbcli_Notes.Left := 24;
    lbcli_Notes.Top := 678;
    lbcli_Notes.Width := 47;
    lbcli_Notes.Height := 13;
    lbcli_Notes.Caption := 'cli_Notes:';
    lbcli_Notes.HeightPercent := 100.000000000000000000;
    lbcli_Notes.WidthPercent := 100.000000000000000000;
    lbcli_CreatedBy.SetParentComponent(Self);
    lbcli_CreatedBy.Name := 'lbcli_CreatedBy';
    lbcli_CreatedBy.Left := 24;
    lbcli_CreatedBy.Top := 705;
    lbcli_CreatedBy.Width := 70;
    lbcli_CreatedBy.Height := 13;
    lbcli_CreatedBy.Caption := 'cli_CreatedBy:';
    lbcli_CreatedBy.HeightPercent := 100.000000000000000000;
    lbcli_CreatedBy.WidthPercent := 100.000000000000000000;
    lbcli_CreatedDate.SetParentComponent(Self);
    lbcli_CreatedDate.Name := 'lbcli_CreatedDate';
    lbcli_CreatedDate.Left := 24;
    lbcli_CreatedDate.Top := 732;
    lbcli_CreatedDate.Width := 81;
    lbcli_CreatedDate.Height := 13;
    lbcli_CreatedDate.Caption := 'cli_CreatedDate:';
    lbcli_CreatedDate.HeightPercent := 100.000000000000000000;
    lbcli_CreatedDate.WidthPercent := 100.000000000000000000;
    lbcli_LastModifiedBy.SetParentComponent(Self);
    lbcli_LastModifiedBy.Name := 'lbcli_LastModifiedBy';
    lbcli_LastModifiedBy.Left := 24;
    lbcli_LastModifiedBy.Top := 759;
    lbcli_LastModifiedBy.Width := 91;
    lbcli_LastModifiedBy.Height := 13;
    lbcli_LastModifiedBy.Caption := 'cli_LastModifiedBy:';
    lbcli_LastModifiedBy.HeightPercent := 100.000000000000000000;
    lbcli_LastModifiedBy.WidthPercent := 100.000000000000000000;
    lbcli_LastModifiedDate.SetParentComponent(Self);
    lbcli_LastModifiedDate.Name := 'lbcli_LastModifiedDate';
    lbcli_LastModifiedDate.Left := 24;
    lbcli_LastModifiedDate.Top := 786;
    lbcli_LastModifiedDate.Width := 102;
    lbcli_LastModifiedDate.Height := 13;
    lbcli_LastModifiedDate.Caption := 'cli_LastModifiedDate:';
    lbcli_LastModifiedDate.HeightPercent := 100.000000000000000000;
    lbcli_LastModifiedDate.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 826;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 826;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 826;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edcli_Id.SetParentComponent(Self);
    edcli_Id.Name := 'edcli_Id';
    edcli_Id.Left := 221;
    edcli_Id.Top := 189;
    edcli_Id.Width := 200;
    edcli_Id.Height := 21;
    edcli_Id.HeightPercent := 100.000000000000000000;
    edcli_Id.TabOrder := 6;
    edcli_Id.Text := 'edcli_Id';
    edcli_Id.WidthPercent := 100.000000000000000000;
    edcli_Id.DataField := 'cli_Id';
    edcli_Id.DataSource := DataSource;
    edcli_Active.SetParentComponent(Self);
    edcli_Active.Name := 'edcli_Active';
    edcli_Active.Left := 221;
    edcli_Active.Top := 216;
    edcli_Active.Width := 200;
    edcli_Active.Height := 21;
    edcli_Active.HeightPercent := 100.000000000000000000;
    edcli_Active.TabOrder := 7;
    edcli_Active.Text := 'edcli_Active';
    edcli_Active.WidthPercent := 100.000000000000000000;
    edcli_Active.DataField := 'cli_Active';
    edcli_Active.DataSource := DataSource;
    edcli_ClientName.SetParentComponent(Self);
    edcli_ClientName.Name := 'edcli_ClientName';
    edcli_ClientName.Left := 221;
    edcli_ClientName.Top := 243;
    edcli_ClientName.Width := 200;
    edcli_ClientName.Height := 21;
    edcli_ClientName.HeightPercent := 100.000000000000000000;
    edcli_ClientName.TabOrder := 8;
    edcli_ClientName.Text := 'edcli_ClientName';
    edcli_ClientName.WidthPercent := 100.000000000000000000;
    edcli_ClientName.DataField := 'cli_ClientName';
    edcli_ClientName.DataSource := DataSource;
    edcli_Address.SetParentComponent(Self);
    edcli_Address.Name := 'edcli_Address';
    edcli_Address.Left := 221;
    edcli_Address.Top := 270;
    edcli_Address.Width := 200;
    edcli_Address.Height := 21;
    edcli_Address.HeightPercent := 100.000000000000000000;
    edcli_Address.TabOrder := 9;
    edcli_Address.Text := 'edcli_Address';
    edcli_Address.WidthPercent := 100.000000000000000000;
    edcli_Address.DataField := 'cli_Address';
    edcli_Address.DataSource := DataSource;
    edcli_City.SetParentComponent(Self);
    edcli_City.Name := 'edcli_City';
    edcli_City.Left := 221;
    edcli_City.Top := 297;
    edcli_City.Width := 200;
    edcli_City.Height := 21;
    edcli_City.HeightPercent := 100.000000000000000000;
    edcli_City.TabOrder := 10;
    edcli_City.Text := 'edcli_City';
    edcli_City.WidthPercent := 100.000000000000000000;
    edcli_City.DataField := 'cli_City';
    edcli_City.DataSource := DataSource;
    edcli_Province.SetParentComponent(Self);
    edcli_Province.Name := 'edcli_Province';
    edcli_Province.Left := 221;
    edcli_Province.Top := 324;
    edcli_Province.Width := 200;
    edcli_Province.Height := 21;
    edcli_Province.HeightPercent := 100.000000000000000000;
    edcli_Province.TabOrder := 11;
    edcli_Province.Text := 'edcli_Province';
    edcli_Province.WidthPercent := 100.000000000000000000;
    edcli_Province.DataField := 'cli_Province';
    edcli_Province.DataSource := DataSource;
    edcli_PostalCode.SetParentComponent(Self);
    edcli_PostalCode.Name := 'edcli_PostalCode';
    edcli_PostalCode.Left := 221;
    edcli_PostalCode.Top := 351;
    edcli_PostalCode.Width := 200;
    edcli_PostalCode.Height := 21;
    edcli_PostalCode.HeightPercent := 100.000000000000000000;
    edcli_PostalCode.TabOrder := 12;
    edcli_PostalCode.Text := 'edcli_PostalCode';
    edcli_PostalCode.WidthPercent := 100.000000000000000000;
    edcli_PostalCode.DataField := 'cli_PostalCode';
    edcli_PostalCode.DataSource := DataSource;
    edcli_Contact1FirstName.SetParentComponent(Self);
    edcli_Contact1FirstName.Name := 'edcli_Contact1FirstName';
    edcli_Contact1FirstName.Left := 221;
    edcli_Contact1FirstName.Top := 378;
    edcli_Contact1FirstName.Width := 200;
    edcli_Contact1FirstName.Height := 21;
    edcli_Contact1FirstName.HeightPercent := 100.000000000000000000;
    edcli_Contact1FirstName.TabOrder := 13;
    edcli_Contact1FirstName.Text := 'edcli_Contact1FirstName';
    edcli_Contact1FirstName.WidthPercent := 100.000000000000000000;
    edcli_Contact1FirstName.DataField := 'cli_Contact1FirstName';
    edcli_Contact1FirstName.DataSource := DataSource;
    edcli_Contact1LastName.SetParentComponent(Self);
    edcli_Contact1LastName.Name := 'edcli_Contact1LastName';
    edcli_Contact1LastName.Left := 221;
    edcli_Contact1LastName.Top := 405;
    edcli_Contact1LastName.Width := 200;
    edcli_Contact1LastName.Height := 21;
    edcli_Contact1LastName.HeightPercent := 100.000000000000000000;
    edcli_Contact1LastName.TabOrder := 14;
    edcli_Contact1LastName.Text := 'edcli_Contact1LastName';
    edcli_Contact1LastName.WidthPercent := 100.000000000000000000;
    edcli_Contact1LastName.DataField := 'cli_Contact1LastName';
    edcli_Contact1LastName.DataSource := DataSource;
    edcli_Contact1Telephone.SetParentComponent(Self);
    edcli_Contact1Telephone.Name := 'edcli_Contact1Telephone';
    edcli_Contact1Telephone.Left := 221;
    edcli_Contact1Telephone.Top := 432;
    edcli_Contact1Telephone.Width := 200;
    edcli_Contact1Telephone.Height := 21;
    edcli_Contact1Telephone.HeightPercent := 100.000000000000000000;
    edcli_Contact1Telephone.TabOrder := 15;
    edcli_Contact1Telephone.Text := 'edcli_Contact1Telephone';
    edcli_Contact1Telephone.WidthPercent := 100.000000000000000000;
    edcli_Contact1Telephone.DataField := 'cli_Contact1Telephone';
    edcli_Contact1Telephone.DataSource := DataSource;
    edcli_Contact1Email.SetParentComponent(Self);
    edcli_Contact1Email.Name := 'edcli_Contact1Email';
    edcli_Contact1Email.Left := 221;
    edcli_Contact1Email.Top := 459;
    edcli_Contact1Email.Width := 200;
    edcli_Contact1Email.Height := 21;
    edcli_Contact1Email.HeightPercent := 100.000000000000000000;
    edcli_Contact1Email.TabOrder := 16;
    edcli_Contact1Email.Text := 'edcli_Contact1Email';
    edcli_Contact1Email.WidthPercent := 100.000000000000000000;
    edcli_Contact1Email.DataField := 'cli_Contact1Email';
    edcli_Contact1Email.DataSource := DataSource;
    edcli_Contact2FirstName.SetParentComponent(Self);
    edcli_Contact2FirstName.Name := 'edcli_Contact2FirstName';
    edcli_Contact2FirstName.Left := 221;
    edcli_Contact2FirstName.Top := 486;
    edcli_Contact2FirstName.Width := 200;
    edcli_Contact2FirstName.Height := 21;
    edcli_Contact2FirstName.HeightPercent := 100.000000000000000000;
    edcli_Contact2FirstName.TabOrder := 17;
    edcli_Contact2FirstName.Text := 'edcli_Contact2FirstName';
    edcli_Contact2FirstName.WidthPercent := 100.000000000000000000;
    edcli_Contact2FirstName.DataField := 'cli_Contact2FirstName';
    edcli_Contact2FirstName.DataSource := DataSource;
    edcli_Contact2LastName.SetParentComponent(Self);
    edcli_Contact2LastName.Name := 'edcli_Contact2LastName';
    edcli_Contact2LastName.Left := 221;
    edcli_Contact2LastName.Top := 513;
    edcli_Contact2LastName.Width := 200;
    edcli_Contact2LastName.Height := 21;
    edcli_Contact2LastName.HeightPercent := 100.000000000000000000;
    edcli_Contact2LastName.TabOrder := 18;
    edcli_Contact2LastName.Text := 'edcli_Contact2LastName';
    edcli_Contact2LastName.WidthPercent := 100.000000000000000000;
    edcli_Contact2LastName.DataField := 'cli_Contact2LastName';
    edcli_Contact2LastName.DataSource := DataSource;
    edcli_Contact2Telephone.SetParentComponent(Self);
    edcli_Contact2Telephone.Name := 'edcli_Contact2Telephone';
    edcli_Contact2Telephone.Left := 221;
    edcli_Contact2Telephone.Top := 540;
    edcli_Contact2Telephone.Width := 200;
    edcli_Contact2Telephone.Height := 21;
    edcli_Contact2Telephone.HeightPercent := 100.000000000000000000;
    edcli_Contact2Telephone.TabOrder := 19;
    edcli_Contact2Telephone.Text := 'edcli_Contact2Telephone';
    edcli_Contact2Telephone.WidthPercent := 100.000000000000000000;
    edcli_Contact2Telephone.DataField := 'cli_Contact2Telephone';
    edcli_Contact2Telephone.DataSource := DataSource;
    edcli_Contact2Email.SetParentComponent(Self);
    edcli_Contact2Email.Name := 'edcli_Contact2Email';
    edcli_Contact2Email.Left := 221;
    edcli_Contact2Email.Top := 567;
    edcli_Contact2Email.Width := 200;
    edcli_Contact2Email.Height := 21;
    edcli_Contact2Email.HeightPercent := 100.000000000000000000;
    edcli_Contact2Email.TabOrder := 20;
    edcli_Contact2Email.Text := 'edcli_Contact2Email';
    edcli_Contact2Email.WidthPercent := 100.000000000000000000;
    edcli_Contact2Email.DataField := 'cli_Contact2Email';
    edcli_Contact2Email.DataSource := DataSource;
    edcli_ChildNotes.SetParentComponent(Self);
    edcli_ChildNotes.Name := 'edcli_ChildNotes';
    edcli_ChildNotes.Left := 221;
    edcli_ChildNotes.Top := 594;
    edcli_ChildNotes.Width := 200;
    edcli_ChildNotes.Height := 21;
    edcli_ChildNotes.HeightPercent := 100.000000000000000000;
    edcli_ChildNotes.TabOrder := 21;
    edcli_ChildNotes.Text := 'edcli_ChildNotes';
    edcli_ChildNotes.WidthPercent := 100.000000000000000000;
    edcli_ChildNotes.DataField := 'cli_ChildNotes';
    edcli_ChildNotes.DataSource := DataSource;
    edcli_IsSeasonal.SetParentComponent(Self);
    edcli_IsSeasonal.Name := 'edcli_IsSeasonal';
    edcli_IsSeasonal.Left := 221;
    edcli_IsSeasonal.Top := 621;
    edcli_IsSeasonal.Width := 200;
    edcli_IsSeasonal.Height := 21;
    edcli_IsSeasonal.HeightPercent := 100.000000000000000000;
    edcli_IsSeasonal.TabOrder := 22;
    edcli_IsSeasonal.Text := 'edcli_IsSeasonal';
    edcli_IsSeasonal.WidthPercent := 100.000000000000000000;
    edcli_IsSeasonal.DataField := 'cli_IsSeasonal';
    edcli_IsSeasonal.DataSource := DataSource;
    edcli_FullTimer.SetParentComponent(Self);
    edcli_FullTimer.Name := 'edcli_FullTimer';
    edcli_FullTimer.Left := 221;
    edcli_FullTimer.Top := 648;
    edcli_FullTimer.Width := 200;
    edcli_FullTimer.Height := 21;
    edcli_FullTimer.HeightPercent := 100.000000000000000000;
    edcli_FullTimer.TabOrder := 23;
    edcli_FullTimer.Text := 'edcli_FullTimer';
    edcli_FullTimer.WidthPercent := 100.000000000000000000;
    edcli_FullTimer.DataField := 'cli_FullTimer';
    edcli_FullTimer.DataSource := DataSource;
    edcli_Notes.SetParentComponent(Self);
    edcli_Notes.Name := 'edcli_Notes';
    edcli_Notes.Left := 221;
    edcli_Notes.Top := 675;
    edcli_Notes.Width := 200;
    edcli_Notes.Height := 21;
    edcli_Notes.HeightPercent := 100.000000000000000000;
    edcli_Notes.TabOrder := 24;
    edcli_Notes.Text := 'edcli_Notes';
    edcli_Notes.WidthPercent := 100.000000000000000000;
    edcli_Notes.DataField := 'cli_Notes';
    edcli_Notes.DataSource := DataSource;
    edcli_CreatedBy.SetParentComponent(Self);
    edcli_CreatedBy.Name := 'edcli_CreatedBy';
    edcli_CreatedBy.Left := 221;
    edcli_CreatedBy.Top := 702;
    edcli_CreatedBy.Width := 200;
    edcli_CreatedBy.Height := 21;
    edcli_CreatedBy.HeightPercent := 100.000000000000000000;
    edcli_CreatedBy.TabOrder := 25;
    edcli_CreatedBy.Text := 'edcli_CreatedBy';
    edcli_CreatedBy.WidthPercent := 100.000000000000000000;
    edcli_CreatedBy.DataField := 'cli_CreatedBy';
    edcli_CreatedBy.DataSource := DataSource;
    edcli_CreatedDate.SetParentComponent(Self);
    edcli_CreatedDate.Name := 'edcli_CreatedDate';
    edcli_CreatedDate.Left := 221;
    edcli_CreatedDate.Top := 729;
    edcli_CreatedDate.Width := 200;
    edcli_CreatedDate.Height := 21;
    edcli_CreatedDate.HeightPercent := 100.000000000000000000;
    edcli_CreatedDate.TabOrder := 26;
    edcli_CreatedDate.Text := 'edcli_CreatedDate';
    edcli_CreatedDate.WidthPercent := 100.000000000000000000;
    edcli_CreatedDate.DataField := 'cli_CreatedDate';
    edcli_CreatedDate.DataSource := DataSource;
    edcli_LastModifiedBy.SetParentComponent(Self);
    edcli_LastModifiedBy.Name := 'edcli_LastModifiedBy';
    edcli_LastModifiedBy.Left := 221;
    edcli_LastModifiedBy.Top := 756;
    edcli_LastModifiedBy.Width := 200;
    edcli_LastModifiedBy.Height := 21;
    edcli_LastModifiedBy.HeightPercent := 100.000000000000000000;
    edcli_LastModifiedBy.TabOrder := 27;
    edcli_LastModifiedBy.Text := 'edcli_LastModifiedBy';
    edcli_LastModifiedBy.WidthPercent := 100.000000000000000000;
    edcli_LastModifiedBy.DataField := 'cli_LastModifiedBy';
    edcli_LastModifiedBy.DataSource := DataSource;
    edcli_LastModifiedDate.SetParentComponent(Self);
    edcli_LastModifiedDate.Name := 'edcli_LastModifiedDate';
    edcli_LastModifiedDate.Left := 221;
    edcli_LastModifiedDate.Top := 783;
    edcli_LastModifiedDate.Width := 200;
    edcli_LastModifiedDate.Height := 21;
    edcli_LastModifiedDate.HeightPercent := 100.000000000000000000;
    edcli_LastModifiedDate.TabOrder := 28;
    edcli_LastModifiedDate.Text := 'edcli_LastModifiedDate';
    edcli_LastModifiedDate.WidthPercent := 100.000000000000000000;
    edcli_LastModifiedDate.DataField := 'cli_LastModifiedDate';
    edcli_LastModifiedDate.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := ClientsDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    ClientsDataset.SetParentComponent(Self);
    ClientsDataset.Name := 'ClientsDataset';
    ClientsDataset.AfterApplyUpdates := ClientsDatasetAfterApplyUpdates;
    ClientsDataset.EntitySetName := 'Clients';
    ClientsDataset.Connection := DMConnection.ApiConnection;
    ClientsDataset.Left := 176;
    ClientsDataset.Top := 8;
    ClientsDatasetcli_Id.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Id.Name := 'ClientsDatasetcli_Id';
    ClientsDatasetcli_Id.FieldName := 'cli_Id';
    ClientsDatasetcli_Id.Required := True;
    ClientsDatasetcli_Active.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Active.Name := 'ClientsDatasetcli_Active';
    ClientsDatasetcli_Active.FieldName := 'cli_Active';
    ClientsDatasetcli_Active.Size := 255;
    ClientsDatasetcli_ClientName.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_ClientName.Name := 'ClientsDatasetcli_ClientName';
    ClientsDatasetcli_ClientName.FieldName := 'cli_ClientName';
    ClientsDatasetcli_ClientName.Size := 100;
    ClientsDatasetcli_Address.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Address.Name := 'ClientsDatasetcli_Address';
    ClientsDatasetcli_Address.FieldName := 'cli_Address';
    ClientsDatasetcli_Address.Size := 150;
    ClientsDatasetcli_City.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_City.Name := 'ClientsDatasetcli_City';
    ClientsDatasetcli_City.FieldName := 'cli_City';
    ClientsDatasetcli_City.Size := 100;
    ClientsDatasetcli_Province.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Province.Name := 'ClientsDatasetcli_Province';
    ClientsDatasetcli_Province.FieldName := 'cli_Province';
    ClientsDatasetcli_Province.Size := 50;
    ClientsDatasetcli_PostalCode.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_PostalCode.Name := 'ClientsDatasetcli_PostalCode';
    ClientsDatasetcli_PostalCode.FieldName := 'cli_PostalCode';
    ClientsDatasetcli_PostalCode.Size := 50;
    ClientsDatasetcli_Contact1FirstName.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Contact1FirstName.Name := 'ClientsDatasetcli_Contact1FirstName';
    ClientsDatasetcli_Contact1FirstName.FieldName := 'cli_Contact1FirstName';
    ClientsDatasetcli_Contact1FirstName.Size := 50;
    ClientsDatasetcli_Contact1LastName.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Contact1LastName.Name := 'ClientsDatasetcli_Contact1LastName';
    ClientsDatasetcli_Contact1LastName.FieldName := 'cli_Contact1LastName';
    ClientsDatasetcli_Contact1LastName.Size := 50;
    ClientsDatasetcli_Contact1Telephone.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Contact1Telephone.Name := 'ClientsDatasetcli_Contact1Telephone';
    ClientsDatasetcli_Contact1Telephone.FieldName := 'cli_Contact1Telephone';
    ClientsDatasetcli_Contact1Telephone.Size := 50;
    ClientsDatasetcli_Contact1Email.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Contact1Email.Name := 'ClientsDatasetcli_Contact1Email';
    ClientsDatasetcli_Contact1Email.FieldName := 'cli_Contact1Email';
    ClientsDatasetcli_Contact1Email.Size := 100;
    ClientsDatasetcli_Contact2FirstName.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Contact2FirstName.Name := 'ClientsDatasetcli_Contact2FirstName';
    ClientsDatasetcli_Contact2FirstName.FieldName := 'cli_Contact2FirstName';
    ClientsDatasetcli_Contact2FirstName.Size := 50;
    ClientsDatasetcli_Contact2LastName.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Contact2LastName.Name := 'ClientsDatasetcli_Contact2LastName';
    ClientsDatasetcli_Contact2LastName.FieldName := 'cli_Contact2LastName';
    ClientsDatasetcli_Contact2LastName.Size := 50;
    ClientsDatasetcli_Contact2Telephone.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Contact2Telephone.Name := 'ClientsDatasetcli_Contact2Telephone';
    ClientsDatasetcli_Contact2Telephone.FieldName := 'cli_Contact2Telephone';
    ClientsDatasetcli_Contact2Telephone.Size := 50;
    ClientsDatasetcli_Contact2Email.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Contact2Email.Name := 'ClientsDatasetcli_Contact2Email';
    ClientsDatasetcli_Contact2Email.FieldName := 'cli_Contact2Email';
    ClientsDatasetcli_Contact2Email.Size := 100;
    ClientsDatasetcli_ChildNotes.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_ChildNotes.Name := 'ClientsDatasetcli_ChildNotes';
    ClientsDatasetcli_ChildNotes.FieldName := 'cli_ChildNotes';
    ClientsDatasetcli_ChildNotes.Size := 200;
    ClientsDatasetcli_IsSeasonal.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_IsSeasonal.Name := 'ClientsDatasetcli_IsSeasonal';
    ClientsDatasetcli_IsSeasonal.FieldName := 'cli_IsSeasonal';
    ClientsDatasetcli_IsSeasonal.Size := 255;
    ClientsDatasetcli_FullTimer.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_FullTimer.Name := 'ClientsDatasetcli_FullTimer';
    ClientsDatasetcli_FullTimer.FieldName := 'cli_FullTimer';
    ClientsDatasetcli_FullTimer.Size := 255;
    ClientsDatasetcli_Notes.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Notes.Name := 'ClientsDatasetcli_Notes';
    ClientsDatasetcli_Notes.FieldName := 'cli_Notes';
    ClientsDatasetcli_Notes.Size := 500;
    ClientsDatasetcli_CreatedBy.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_CreatedBy.Name := 'ClientsDatasetcli_CreatedBy';
    ClientsDatasetcli_CreatedBy.FieldName := 'cli_CreatedBy';
    ClientsDatasetcli_CreatedDate.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_CreatedDate.Name := 'ClientsDatasetcli_CreatedDate';
    ClientsDatasetcli_CreatedDate.FieldName := 'cli_CreatedDate';
    ClientsDatasetcli_LastModifiedBy.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_LastModifiedBy.Name := 'ClientsDatasetcli_LastModifiedBy';
    ClientsDatasetcli_LastModifiedBy.FieldName := 'cli_LastModifiedBy';
    ClientsDatasetcli_LastModifiedDate.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_LastModifiedDate.Name := 'ClientsDatasetcli_LastModifiedDate';
    ClientsDatasetcli_LastModifiedDate.FieldName := 'cli_LastModifiedDate';
    XDataClientClientsGet.SetParentComponent(Self);
    XDataClientClientsGet.Name := 'XDataClientClientsGet';
    XDataClientClientsGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientClientsGet, Self, 'OnLoad', 'XDataClientClientsGetLoad');
    XDataClientClientsGet.Left := 64;
    XDataClientClientsGet.Top := 8;
    XDataClientClientsDelete.SetParentComponent(Self);
    XDataClientClientsDelete.Name := 'XDataClientClientsDelete';
    XDataClientClientsDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientClientsDelete, Self, 'OnLoad', 'XDataClientClientsDeleteLoad');
    SetEvent(XDataClientClientsDelete, Self, 'OnError', 'XDataClientClientsDeleteError');
    XDataClientClientsDelete.Left := 64;
    XDataClientClientsDelete.Top := 56;
  finally
    lbcli_Id.AfterLoadDFMValues;
    lbcli_Active.AfterLoadDFMValues;
    lbcli_ClientName.AfterLoadDFMValues;
    lbcli_Address.AfterLoadDFMValues;
    lbcli_City.AfterLoadDFMValues;
    lbcli_Province.AfterLoadDFMValues;
    lbcli_PostalCode.AfterLoadDFMValues;
    lbcli_Contact1FirstName.AfterLoadDFMValues;
    lbcli_Contact1LastName.AfterLoadDFMValues;
    lbcli_Contact1Telephone.AfterLoadDFMValues;
    lbcli_Contact1Email.AfterLoadDFMValues;
    lbcli_Contact2FirstName.AfterLoadDFMValues;
    lbcli_Contact2LastName.AfterLoadDFMValues;
    lbcli_Contact2Telephone.AfterLoadDFMValues;
    lbcli_Contact2Email.AfterLoadDFMValues;
    lbcli_ChildNotes.AfterLoadDFMValues;
    lbcli_IsSeasonal.AfterLoadDFMValues;
    lbcli_FullTimer.AfterLoadDFMValues;
    lbcli_Notes.AfterLoadDFMValues;
    lbcli_CreatedBy.AfterLoadDFMValues;
    lbcli_CreatedDate.AfterLoadDFMValues;
    lbcli_LastModifiedBy.AfterLoadDFMValues;
    lbcli_LastModifiedDate.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edcli_Id.AfterLoadDFMValues;
    edcli_Active.AfterLoadDFMValues;
    edcli_ClientName.AfterLoadDFMValues;
    edcli_Address.AfterLoadDFMValues;
    edcli_City.AfterLoadDFMValues;
    edcli_Province.AfterLoadDFMValues;
    edcli_PostalCode.AfterLoadDFMValues;
    edcli_Contact1FirstName.AfterLoadDFMValues;
    edcli_Contact1LastName.AfterLoadDFMValues;
    edcli_Contact1Telephone.AfterLoadDFMValues;
    edcli_Contact1Email.AfterLoadDFMValues;
    edcli_Contact2FirstName.AfterLoadDFMValues;
    edcli_Contact2LastName.AfterLoadDFMValues;
    edcli_Contact2Telephone.AfterLoadDFMValues;
    edcli_Contact2Email.AfterLoadDFMValues;
    edcli_ChildNotes.AfterLoadDFMValues;
    edcli_IsSeasonal.AfterLoadDFMValues;
    edcli_FullTimer.AfterLoadDFMValues;
    edcli_Notes.AfterLoadDFMValues;
    edcli_CreatedBy.AfterLoadDFMValues;
    edcli_CreatedDate.AfterLoadDFMValues;
    edcli_LastModifiedBy.AfterLoadDFMValues;
    edcli_LastModifiedDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    ClientsDataset.AfterLoadDFMValues;
    ClientsDatasetcli_Id.AfterLoadDFMValues;
    ClientsDatasetcli_Active.AfterLoadDFMValues;
    ClientsDatasetcli_ClientName.AfterLoadDFMValues;
    ClientsDatasetcli_Address.AfterLoadDFMValues;
    ClientsDatasetcli_City.AfterLoadDFMValues;
    ClientsDatasetcli_Province.AfterLoadDFMValues;
    ClientsDatasetcli_PostalCode.AfterLoadDFMValues;
    ClientsDatasetcli_Contact1FirstName.AfterLoadDFMValues;
    ClientsDatasetcli_Contact1LastName.AfterLoadDFMValues;
    ClientsDatasetcli_Contact1Telephone.AfterLoadDFMValues;
    ClientsDatasetcli_Contact1Email.AfterLoadDFMValues;
    ClientsDatasetcli_Contact2FirstName.AfterLoadDFMValues;
    ClientsDatasetcli_Contact2LastName.AfterLoadDFMValues;
    ClientsDatasetcli_Contact2Telephone.AfterLoadDFMValues;
    ClientsDatasetcli_Contact2Email.AfterLoadDFMValues;
    ClientsDatasetcli_ChildNotes.AfterLoadDFMValues;
    ClientsDatasetcli_IsSeasonal.AfterLoadDFMValues;
    ClientsDatasetcli_FullTimer.AfterLoadDFMValues;
    ClientsDatasetcli_Notes.AfterLoadDFMValues;
    ClientsDatasetcli_CreatedBy.AfterLoadDFMValues;
    ClientsDatasetcli_CreatedDate.AfterLoadDFMValues;
    ClientsDatasetcli_LastModifiedBy.AfterLoadDFMValues;
    ClientsDatasetcli_LastModifiedDate.AfterLoadDFMValues;
    XDataClientClientsGet.AfterLoadDFMValues;
    XDataClientClientsDelete.AfterLoadDFMValues;
  end;
end;

end.
