unit View.Reserv.List;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, Math,

  WEBLib.StdCtrls, Web, JS, WEBLib.Lists,

  App.Types, WEBLib.Controls, WEBLib.Grids, Data.DB,
  XData.Web.Dataset, WEBLib.DBCtrls, WEBLib.DB, WEBLib.ExtCtrls,

  XData.Web.JsonDataset,

  Grid.Plugins,
  Crud.Utils;

type
  TFViewReservList = class(TForm)
    btNew: TButton;
    ReservDataset: TXDataWebDataSet;
    ReservDatasetres_Id: TIntegerField;
    ReservDatasetcs_Id: TIntegerField;
    ReservDatasetcli_Id: TIntegerField;
    ReservDatasetinv_Id: TIntegerField;
    ReservDatasetres_DateFrom: TDateField;
    ReservDatasetres_DateTo: TDateField;
    ReservDatasetres_NbrDays: TIntegerField;
    ReservDatasetres_AmountToPay: TIntegerField;
    ReservDatasetres_ManualOrOnline: TIntegerField;
    ReservDatasetres_CreatedBy: TIntegerField;
    ReservDatasetres_CreatedDate: TDateTimeField;
    ReservDatasetres_LastModifiedBy: TIntegerField;
    ReservDatasetres_LastModifiedDate: TDateTimeField;
    WebDBTableControl1: TDBTableControl;
    WebDataSource: TDataSource;
    cbPageSize: TComboBox;
    edSearch: TEdit;
    lbPaginationInfo: TLabel;
    lcPaginator: TListControl;
    procedure WebFormCreate(Sender: TObject);
    procedure btNewClick(Sender: TObject);
    procedure WebDBTableControl1GetCellChildren(Sender: TObject; ACol,
      ARow: Integer; AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure cbPageSizeChange(Sender: TObject);
    procedure edSearchChange(Sender: TObject);
  private
    FEditProc: TEditProc;
    FGridPlugin: TGridPlugin;
  public
    class function CreateForm(AElementID: string; AShowEditProc: TEditProc): TForm;
  protected procedure LoadDFMValues; override; end;

var
  FViewReservList: TFViewReservList;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewReservList }

procedure TFViewReservList.cbPageSizeChange(Sender: TObject);
begin
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text), True);
end;

class function TFViewReservList.CreateForm(AElementID: string;
  AShowEditProc: TEditProc): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewReservList(AForm).FEditProc := AShowEditProc;
  end;

begin
  Application.CreateForm(TFViewReservList, AElementID, Result, @AfterCreate);
end;

procedure TFViewReservList.edSearchChange(Sender: TObject);
begin
  FGridPlugin.SetFilterText(edSearch.Text, True);
end;

procedure TFViewReservList.btNewClick(Sender: TObject);
begin
  FEditProc( nil );
end;

procedure TFViewReservList.WebDBTableControl1GetCellChildren(Sender: TObject;
  ACol, ARow: Integer; AField: TField; AValue: string;
  AElement: TJSHTMLElementRecord);
var
  RecordId: JSValue;

  procedure EditButtonClick(Sender: TObject);
  begin
    FEditProc(RecordId);
  end;

var
  Button: TButton;
begin
  if ARow = 0 then
    Exit;

  if WebDBTableControl1.Columns[ACol].Title = 'Actions' then
  begin
    RecordId := TCrudUtils.GetIdFromObject(
      ReservDataset.Connection.Model.DefaultSchema.FindEntityType(ReservDataset.EntitySetName),
      ReservDataset.CurrentData
    );
    Button := TButton.Create(nil);
    Button.Caption := 'Edit';
    Button.OnClick := @EditButtonClick;
    Button.ElementClassName := 'btn btn-outline btn-default btn-xs';
    Button.ParentElement := AElement.element;
    Button.WidthStyle := TSizeStyle.ssAuto;
    Button.HeightStyle := TSizeStyle.ssAuto;
  end;
end;

procedure TFViewReservList.WebFormCreate(Sender: TObject);
begin
  FGridPlugin := TGridPlugin.Create(
    WebDBTableControl1,
    ReservDataset,
    lcPaginator,
    lbPaginationInfo
  );
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text));
  FGridPlugin.SetFilterText(edSearch.Text);
  FGridPlugin.Load;
end;

procedure TFViewReservList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbPaginationInfo := TLabel.Create('view.reserv.list.pagination.info');
  btNew := TButton.Create('view.reserv.list.newbutton');
  WebDBTableControl1 := TDBTableControl.Create('view.reserv.list.table');
  cbPageSize := TComboBox.Create('view.reserv.list.cbPageSize');
  edSearch := TEdit.Create('view.reserv.list.search');
  lcPaginator := TListControl.Create('view.reserv.list.pagination');
  ReservDataset := TXDataWebDataSet.Create(Self);
  ReservDatasetres_Id := TIntegerField.Create(Self);
  ReservDatasetcs_Id := TIntegerField.Create(Self);
  ReservDatasetcli_Id := TIntegerField.Create(Self);
  ReservDatasetinv_Id := TIntegerField.Create(Self);
  ReservDatasetres_DateFrom := TDateField.Create(Self);
  ReservDatasetres_DateTo := TDateField.Create(Self);
  ReservDatasetres_NbrDays := TIntegerField.Create(Self);
  ReservDatasetres_AmountToPay := TIntegerField.Create(Self);
  ReservDatasetres_ManualOrOnline := TIntegerField.Create(Self);
  ReservDatasetres_CreatedBy := TIntegerField.Create(Self);
  ReservDatasetres_CreatedDate := TDateTimeField.Create(Self);
  ReservDatasetres_LastModifiedBy := TIntegerField.Create(Self);
  ReservDatasetres_LastModifiedDate := TDateTimeField.Create(Self);
  WebDataSource := TDataSource.Create(Self);

  lbPaginationInfo.BeforeLoadDFMValues;
  btNew.BeforeLoadDFMValues;
  WebDBTableControl1.BeforeLoadDFMValues;
  cbPageSize.BeforeLoadDFMValues;
  edSearch.BeforeLoadDFMValues;
  lcPaginator.BeforeLoadDFMValues;
  ReservDataset.BeforeLoadDFMValues;
  ReservDatasetres_Id.BeforeLoadDFMValues;
  ReservDatasetcs_Id.BeforeLoadDFMValues;
  ReservDatasetcli_Id.BeforeLoadDFMValues;
  ReservDatasetinv_Id.BeforeLoadDFMValues;
  ReservDatasetres_DateFrom.BeforeLoadDFMValues;
  ReservDatasetres_DateTo.BeforeLoadDFMValues;
  ReservDatasetres_NbrDays.BeforeLoadDFMValues;
  ReservDatasetres_AmountToPay.BeforeLoadDFMValues;
  ReservDatasetres_ManualOrOnline.BeforeLoadDFMValues;
  ReservDatasetres_CreatedBy.BeforeLoadDFMValues;
  ReservDatasetres_CreatedDate.BeforeLoadDFMValues;
  ReservDatasetres_LastModifiedBy.BeforeLoadDFMValues;
  ReservDatasetres_LastModifiedDate.BeforeLoadDFMValues;
  WebDataSource.BeforeLoadDFMValues;
  try
    Name := 'FViewReservList';
    Width := 695;
    Height := 500;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbPaginationInfo.SetParentComponent(Self);
    lbPaginationInfo.Name := 'lbPaginationInfo';
    lbPaginationInfo.Left := 8;
    lbPaginationInfo.Top := 376;
    lbPaginationInfo.Width := 164;
    lbPaginationInfo.Height := 13;
    lbPaginationInfo.Caption := 'Affichage de 1 '#224' 10 de 57 entr'#233'es';
    lbPaginationInfo.HeightPercent := 100.000000000000000000;
    lbPaginationInfo.WidthPercent := 100.000000000000000000;
    btNew.SetParentComponent(Self);
    btNew.Name := 'btNew';
    btNew.Left := 8;
    btNew.Top := 8;
    btNew.Width := 50;
    btNew.Height := 25;
    btNew.Caption := 'Nouveau';
    btNew.HeightPercent := 100.000000000000000000;
    btNew.WidthPercent := 100.000000000000000000;
    SetEvent(btNew, Self, 'OnClick', 'btNewClick');
    WebDBTableControl1.SetParentComponent(Self);
    WebDBTableControl1.Name := 'WebDBTableControl1';
    WebDBTableControl1.Left := 8;
    WebDBTableControl1.Top := 94;
    WebDBTableControl1.Width := 521;
    WebDBTableControl1.Height := 265;
    WebDBTableControl1.ElementClassName := 'table-responsive';
    WebDBTableControl1.HeightPercent := 100.000000000000000000;
    WebDBTableControl1.WidthPercent := 100.000000000000000000;
    WebDBTableControl1.BorderColor := clSilver;
    WebDBTableControl1.ColHeader := False;
    WebDBTableControl1.ElementTableClassName := 'table table-striped table-bordered table-hover dataTable';
    SetEvent(WebDBTableControl1, Self, 'OnGetCellChildren', 'WebDBTableControl1GetCellChildren');
    WebDBTableControl1.Columns.Clear;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'res_Id';
      Title := '#';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'res_DateTo';
      Title := 'Date';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'res_NbrDays';
      Title := 'Nombre de jour';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'res_AmountToPay';
      Title := 'Montant d'#251;
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'res_ManualOrOnline';
      Title := 'Manuel ou En line';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      Title := 'Actions';
    end;
    WebDBTableControl1.DataSource := WebDataSource;
    cbPageSize.SetParentComponent(Self);
    cbPageSize.Name := 'cbPageSize';
    cbPageSize.Left := 8;
    cbPageSize.Top := 67;
    cbPageSize.Width := 81;
    cbPageSize.Height := 21;
    cbPageSize.HeightPercent := 100.000000000000000000;
    cbPageSize.TabOrder := 2;
    cbPageSize.Text := '50';
    cbPageSize.WidthPercent := 100.000000000000000000;
    SetEvent(cbPageSize, Self, 'OnChange', 'cbPageSizeChange');
    cbPageSize.ItemIndex := 2;
    cbPageSize.Items.BeginUpdate;
    try
      cbPageSize.Items.Clear;
      cbPageSize.Items.Add('10');
      cbPageSize.Items.Add('25');
      cbPageSize.Items.Add('50');
      cbPageSize.Items.Add('100');
    finally
      cbPageSize.Items.EndUpdate;
    end;
    edSearch.SetParentComponent(Self);
    edSearch.Name := 'edSearch';
    edSearch.Left := 408;
    edSearch.Top := 67;
    edSearch.Width := 121;
    edSearch.Height := 21;
    edSearch.HeightPercent := 100.000000000000000000;
    edSearch.TabOrder := 3;
    edSearch.WidthPercent := 100.000000000000000000;
    SetEvent(edSearch, Self, 'OnChange', 'edSearchChange');
    lcPaginator.SetParentComponent(Self);
    lcPaginator.Name := 'lcPaginator';
    lcPaginator.Left := 240;
    lcPaginator.Top := 365;
    lcPaginator.Width := 289;
    lcPaginator.Height := 25;
    lcPaginator.HeightPercent := 100.000000000000000000;
    lcPaginator.WidthPercent := 100.000000000000000000;
    lcPaginator.DefaultItemClassName := 'page-item';
    lcPaginator.DefaultItemLinkClassName := 'page-link';
    lcPaginator.ElementListClassName := 'pagination';
    lcPaginator.Style := lsPagination;
    ReservDataset.SetParentComponent(Self);
    ReservDataset.Name := 'ReservDataset';
    ReservDataset.EntitySetName := 'Reserv';
    ReservDataset.Connection := DMConnection.ApiConnection;
    ReservDataset.Left := 72;
    ReservDataset.Top := 144;
    ReservDatasetres_Id.SetParentComponent(ReservDataset);
    ReservDatasetres_Id.Name := 'ReservDatasetres_Id';
    ReservDatasetres_Id.FieldName := 'res_Id';
    ReservDatasetres_Id.Required := True;
    ReservDatasetcs_Id.SetParentComponent(ReservDataset);
    ReservDatasetcs_Id.Name := 'ReservDatasetcs_Id';
    ReservDatasetcs_Id.FieldName := 'cs_Id';
    ReservDatasetcli_Id.SetParentComponent(ReservDataset);
    ReservDatasetcli_Id.Name := 'ReservDatasetcli_Id';
    ReservDatasetcli_Id.FieldName := 'cli_Id';
    ReservDatasetinv_Id.SetParentComponent(ReservDataset);
    ReservDatasetinv_Id.Name := 'ReservDatasetinv_Id';
    ReservDatasetinv_Id.FieldName := 'inv_Id';
    ReservDatasetres_DateFrom.SetParentComponent(ReservDataset);
    ReservDatasetres_DateFrom.Name := 'ReservDatasetres_DateFrom';
    ReservDatasetres_DateFrom.FieldName := 'res_DateFrom';
    ReservDatasetres_DateTo.SetParentComponent(ReservDataset);
    ReservDatasetres_DateTo.Name := 'ReservDatasetres_DateTo';
    ReservDatasetres_DateTo.FieldName := 'res_DateTo';
    ReservDatasetres_NbrDays.SetParentComponent(ReservDataset);
    ReservDatasetres_NbrDays.Name := 'ReservDatasetres_NbrDays';
    ReservDatasetres_NbrDays.FieldName := 'res_NbrDays';
    ReservDatasetres_AmountToPay.SetParentComponent(ReservDataset);
    ReservDatasetres_AmountToPay.Name := 'ReservDatasetres_AmountToPay';
    ReservDatasetres_AmountToPay.FieldName := 'res_AmountToPay';
    ReservDatasetres_ManualOrOnline.SetParentComponent(ReservDataset);
    ReservDatasetres_ManualOrOnline.Name := 'ReservDatasetres_ManualOrOnline';
    ReservDatasetres_ManualOrOnline.FieldName := 'res_ManualOrOnline';
    ReservDatasetres_CreatedBy.SetParentComponent(ReservDataset);
    ReservDatasetres_CreatedBy.Name := 'ReservDatasetres_CreatedBy';
    ReservDatasetres_CreatedBy.FieldName := 'res_CreatedBy';
    ReservDatasetres_CreatedDate.SetParentComponent(ReservDataset);
    ReservDatasetres_CreatedDate.Name := 'ReservDatasetres_CreatedDate';
    ReservDatasetres_CreatedDate.FieldName := 'res_CreatedDate';
    ReservDatasetres_LastModifiedBy.SetParentComponent(ReservDataset);
    ReservDatasetres_LastModifiedBy.Name := 'ReservDatasetres_LastModifiedBy';
    ReservDatasetres_LastModifiedBy.FieldName := 'res_LastModifiedBy';
    ReservDatasetres_LastModifiedDate.SetParentComponent(ReservDataset);
    ReservDatasetres_LastModifiedDate.Name := 'ReservDatasetres_LastModifiedDate';
    ReservDatasetres_LastModifiedDate.FieldName := 'res_LastModifiedDate';
    WebDataSource.SetParentComponent(Self);
    WebDataSource.Name := 'WebDataSource';
    WebDataSource.DataSet := ReservDataset;
    WebDataSource.Left := 72;
    WebDataSource.Top := 200;
  finally
    lbPaginationInfo.AfterLoadDFMValues;
    btNew.AfterLoadDFMValues;
    WebDBTableControl1.AfterLoadDFMValues;
    cbPageSize.AfterLoadDFMValues;
    edSearch.AfterLoadDFMValues;
    lcPaginator.AfterLoadDFMValues;
    ReservDataset.AfterLoadDFMValues;
    ReservDatasetres_Id.AfterLoadDFMValues;
    ReservDatasetcs_Id.AfterLoadDFMValues;
    ReservDatasetcli_Id.AfterLoadDFMValues;
    ReservDatasetinv_Id.AfterLoadDFMValues;
    ReservDatasetres_DateFrom.AfterLoadDFMValues;
    ReservDatasetres_DateTo.AfterLoadDFMValues;
    ReservDatasetres_NbrDays.AfterLoadDFMValues;
    ReservDatasetres_AmountToPay.AfterLoadDFMValues;
    ReservDatasetres_ManualOrOnline.AfterLoadDFMValues;
    ReservDatasetres_CreatedBy.AfterLoadDFMValues;
    ReservDatasetres_CreatedDate.AfterLoadDFMValues;
    ReservDatasetres_LastModifiedBy.AfterLoadDFMValues;
    ReservDatasetres_LastModifiedDate.AfterLoadDFMValues;
    WebDataSource.AfterLoadDFMValues;
  end;
end;

end.
