unit View.Activities.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewActivitiesEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbact_Id: TLabel;
    edact_Id: TDBEdit;
    lbact_Name: TLabel;
    edact_Name: TDBEdit;
    lbact_Date: TLabel;
    edact_Date: TDBEdit;
    lbact_TicketNumber: TLabel;
    edact_TicketNumber: TDBEdit;
    lbact_CreatedBy: TLabel;
    edact_CreatedBy: TDBEdit;
    lbact_CreatedDate: TLabel;
    edact_CreatedDate: TDBEdit;
    lbact_LastModifiedBy: TLabel;
    edact_LastModifiedBy: TDBEdit;
    lbact_LastModifiedDate: TLabel;
    edact_LastModifiedDate: TDBEdit;
    XDataClientActivitiesGet: TXDataWebClient;
    XDataClientActivitiesDelete: TXDataWebClient;
    ActivitiesDataset: TXDataWebDataSet;
    ActivitiesDatasetact_Id: TIntegerField;
    ActivitiesDatasetact_Name: TStringField;
    ActivitiesDatasetact_Date: TDateTimeField;
    ActivitiesDatasetact_TicketNumber: TIntegerField;
    ActivitiesDatasetact_CreatedBy: TIntegerField;
    ActivitiesDatasetact_CreatedDate: TDateTimeField;
    ActivitiesDatasetact_LastModifiedBy: TIntegerField;
    ActivitiesDatasetact_LastModifiedDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientActivitiesGetLoad(Response: TXDataClientResponse);
    procedure XDataClientActivitiesDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientActivitiesDeleteError(Error: TXDataClientError);
    procedure ActivitiesDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewActivitiesEdit: TFViewActivitiesEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewActivitiesEdit }

procedure TFViewActivitiesEdit.btSaveClick(Sender: TObject);
begin
  if ActivitiesDataset.State in dsEditModes then
  begin
        ActivitiesDataset.Post;
    ActivitiesDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewActivitiesEdit.btDeleteClick(Sender: TObject);
begin
  if ActivitiesDataset.State in dsEditModes then
    ActivitiesDataset.Post;
  XDataClientActivitiesDelete.Delete(ActivitiesDataset.EntitySetName,
    TJSObject(ActivitiesDataset.CurrentData));
end;


class function TFViewActivitiesEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewActivitiesEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewActivitiesEdit.InsertEntity;
begin
  ActivitiesDataset.Close;
  ActivitiesDataset.SetJsonData('{}');
  ActivitiesDataset.Open;
  ActivitiesDataset.Insert;
end;

class function TFViewActivitiesEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewActivitiesEdit(AForm).FInserting := AInserting;
    TFViewActivitiesEdit(AForm).FId := AId;
    TFViewActivitiesEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewActivitiesEdit(AForm).InsertEntity
    else
      TFViewActivitiesEdit(AForm).LoadEntity;
    TFViewActivitiesEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewActivitiesEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewActivitiesEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    ActivitiesDataset.Connection.Model.DefaultSchema.FindEntityType(
      ActivitiesDataset.EntitySetName
    )
  );
  XDataClientActivitiesGet.Get(ActivitiesDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewActivitiesEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewActivitiesEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewActivitiesEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewActivitiesEdit.XDataClientActivitiesGetLoad(Response: TXDataClientResponse);
begin
  ActivitiesDataset.Close;
  ActivitiesDataset.SetJsonData(Response.Result);
  ActivitiesDataset.Open;
  
  ActivitiesDataset.Edit;  
end;

procedure TFViewActivitiesEdit.XDataClientActivitiesDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewActivitiesEdit.XDataClientActivitiesDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewActivitiesEdit.ActivitiesDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewActivitiesEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewActivitiesEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbact_Id := TLabel.Create('view.activities.form.lbact_Id');
  lbact_Name := TLabel.Create('view.activities.form.lbact_Name');
  lbact_Date := TLabel.Create('view.activities.form.lbact_Date');
  lbact_TicketNumber := TLabel.Create('view.activities.form.lbact_TicketNumber');
  lbact_CreatedBy := TLabel.Create('view.activities.form.lbact_CreatedBy');
  lbact_CreatedDate := TLabel.Create('view.activities.form.lbact_CreatedDate');
  lbact_LastModifiedBy := TLabel.Create('view.activities.form.lbact_LastModifiedBy');
  lbact_LastModifiedDate := TLabel.Create('view.activities.form.lbact_LastModifiedDate');
  pnlMessage := TPanel.Create('view.activities.form.message');
  lbMessage := TLabel.Create('view.activities.form.message.label');
  btCloseMessage := TButton.Create('view.activities.form.message.button');
  btCancel := TButton.Create('view.activities.form.btBack');
  btSave := TButton.Create('view.activities.form.btSave');
  btDelete := TButton.Create('view.activities.form.btDelete');
  edact_Id := TDBEdit.Create('view.activities.form.edact_Id');
  edact_Name := TDBEdit.Create('view.activities.form.edact_Name');
  edact_Date := TDBEdit.Create('view.activities.form.edact_Date');
  edact_TicketNumber := TDBEdit.Create('view.activities.form.edact_TicketNumber');
  edact_CreatedBy := TDBEdit.Create('view.activities.form.edact_CreatedBy');
  edact_CreatedDate := TDBEdit.Create('view.activities.form.edact_CreatedDate');
  edact_LastModifiedBy := TDBEdit.Create('view.activities.form.edact_LastModifiedBy');
  edact_LastModifiedDate := TDBEdit.Create('view.activities.form.edact_LastModifiedDate');
  DataSource := TDataSource.Create(Self);
  ActivitiesDataset := TXDataWebDataSet.Create(Self);
  ActivitiesDatasetact_Id := TIntegerField.Create(Self);
  ActivitiesDatasetact_Name := TStringField.Create(Self);
  ActivitiesDatasetact_Date := TDateTimeField.Create(Self);
  ActivitiesDatasetact_TicketNumber := TIntegerField.Create(Self);
  ActivitiesDatasetact_CreatedBy := TIntegerField.Create(Self);
  ActivitiesDatasetact_CreatedDate := TDateTimeField.Create(Self);
  ActivitiesDatasetact_LastModifiedBy := TIntegerField.Create(Self);
  ActivitiesDatasetact_LastModifiedDate := TDateTimeField.Create(Self);
  XDataClientActivitiesGet := TXDataWebClient.Create(Self);
  XDataClientActivitiesDelete := TXDataWebClient.Create(Self);

  lbact_Id.BeforeLoadDFMValues;
  lbact_Name.BeforeLoadDFMValues;
  lbact_Date.BeforeLoadDFMValues;
  lbact_TicketNumber.BeforeLoadDFMValues;
  lbact_CreatedBy.BeforeLoadDFMValues;
  lbact_CreatedDate.BeforeLoadDFMValues;
  lbact_LastModifiedBy.BeforeLoadDFMValues;
  lbact_LastModifiedDate.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edact_Id.BeforeLoadDFMValues;
  edact_Name.BeforeLoadDFMValues;
  edact_Date.BeforeLoadDFMValues;
  edact_TicketNumber.BeforeLoadDFMValues;
  edact_CreatedBy.BeforeLoadDFMValues;
  edact_CreatedDate.BeforeLoadDFMValues;
  edact_LastModifiedBy.BeforeLoadDFMValues;
  edact_LastModifiedDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  ActivitiesDataset.BeforeLoadDFMValues;
  ActivitiesDatasetact_Id.BeforeLoadDFMValues;
  ActivitiesDatasetact_Name.BeforeLoadDFMValues;
  ActivitiesDatasetact_Date.BeforeLoadDFMValues;
  ActivitiesDatasetact_TicketNumber.BeforeLoadDFMValues;
  ActivitiesDatasetact_CreatedBy.BeforeLoadDFMValues;
  ActivitiesDatasetact_CreatedDate.BeforeLoadDFMValues;
  ActivitiesDatasetact_LastModifiedBy.BeforeLoadDFMValues;
  ActivitiesDatasetact_LastModifiedDate.BeforeLoadDFMValues;
  XDataClientActivitiesGet.BeforeLoadDFMValues;
  XDataClientActivitiesDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewActivitiesEdit';
    Width := 593;
    Height := 481;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbact_Id.SetParentComponent(Self);
    lbact_Id.Name := 'lbact_Id';
    lbact_Id.Left := 24;
    lbact_Id.Top := 192;
    lbact_Id.Width := 35;
    lbact_Id.Height := 13;
    lbact_Id.Caption := 'act_Id:';
    lbact_Id.HeightPercent := 100.000000000000000000;
    lbact_Id.WidthPercent := 100.000000000000000000;
    lbact_Name.SetParentComponent(Self);
    lbact_Name.Name := 'lbact_Name';
    lbact_Name.Left := 24;
    lbact_Name.Top := 219;
    lbact_Name.Width := 52;
    lbact_Name.Height := 13;
    lbact_Name.Caption := 'act_Name:';
    lbact_Name.HeightPercent := 100.000000000000000000;
    lbact_Name.WidthPercent := 100.000000000000000000;
    lbact_Date.SetParentComponent(Self);
    lbact_Date.Name := 'lbact_Date';
    lbact_Date.Left := 24;
    lbact_Date.Top := 246;
    lbact_Date.Width := 48;
    lbact_Date.Height := 13;
    lbact_Date.Caption := 'act_Date:';
    lbact_Date.HeightPercent := 100.000000000000000000;
    lbact_Date.WidthPercent := 100.000000000000000000;
    lbact_TicketNumber.SetParentComponent(Self);
    lbact_TicketNumber.Name := 'lbact_TicketNumber';
    lbact_TicketNumber.Left := 24;
    lbact_TicketNumber.Top := 273;
    lbact_TicketNumber.Width := 90;
    lbact_TicketNumber.Height := 13;
    lbact_TicketNumber.Caption := 'act_TicketNumber:';
    lbact_TicketNumber.HeightPercent := 100.000000000000000000;
    lbact_TicketNumber.WidthPercent := 100.000000000000000000;
    lbact_CreatedBy.SetParentComponent(Self);
    lbact_CreatedBy.Name := 'lbact_CreatedBy';
    lbact_CreatedBy.Left := 24;
    lbact_CreatedBy.Top := 300;
    lbact_CreatedBy.Width := 76;
    lbact_CreatedBy.Height := 13;
    lbact_CreatedBy.Caption := 'act_CreatedBy:';
    lbact_CreatedBy.HeightPercent := 100.000000000000000000;
    lbact_CreatedBy.WidthPercent := 100.000000000000000000;
    lbact_CreatedDate.SetParentComponent(Self);
    lbact_CreatedDate.Name := 'lbact_CreatedDate';
    lbact_CreatedDate.Left := 24;
    lbact_CreatedDate.Top := 327;
    lbact_CreatedDate.Width := 87;
    lbact_CreatedDate.Height := 13;
    lbact_CreatedDate.Caption := 'act_CreatedDate:';
    lbact_CreatedDate.HeightPercent := 100.000000000000000000;
    lbact_CreatedDate.WidthPercent := 100.000000000000000000;
    lbact_LastModifiedBy.SetParentComponent(Self);
    lbact_LastModifiedBy.Name := 'lbact_LastModifiedBy';
    lbact_LastModifiedBy.Left := 24;
    lbact_LastModifiedBy.Top := 354;
    lbact_LastModifiedBy.Width := 97;
    lbact_LastModifiedBy.Height := 13;
    lbact_LastModifiedBy.Caption := 'act_LastModifiedBy:';
    lbact_LastModifiedBy.HeightPercent := 100.000000000000000000;
    lbact_LastModifiedBy.WidthPercent := 100.000000000000000000;
    lbact_LastModifiedDate.SetParentComponent(Self);
    lbact_LastModifiedDate.Name := 'lbact_LastModifiedDate';
    lbact_LastModifiedDate.Left := 24;
    lbact_LastModifiedDate.Top := 381;
    lbact_LastModifiedDate.Width := 108;
    lbact_LastModifiedDate.Height := 13;
    lbact_LastModifiedDate.Caption := 'act_LastModifiedDate:';
    lbact_LastModifiedDate.HeightPercent := 100.000000000000000000;
    lbact_LastModifiedDate.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 421;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 421;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 421;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edact_Id.SetParentComponent(Self);
    edact_Id.Name := 'edact_Id';
    edact_Id.Left := 221;
    edact_Id.Top := 189;
    edact_Id.Width := 200;
    edact_Id.Height := 21;
    edact_Id.HeightPercent := 100.000000000000000000;
    edact_Id.TabOrder := 6;
    edact_Id.Text := 'edact_Id';
    edact_Id.WidthPercent := 100.000000000000000000;
    edact_Id.DataField := 'act_Id';
    edact_Id.DataSource := DataSource;
    edact_Name.SetParentComponent(Self);
    edact_Name.Name := 'edact_Name';
    edact_Name.Left := 221;
    edact_Name.Top := 216;
    edact_Name.Width := 200;
    edact_Name.Height := 21;
    edact_Name.HeightPercent := 100.000000000000000000;
    edact_Name.TabOrder := 7;
    edact_Name.Text := 'edact_Name';
    edact_Name.WidthPercent := 100.000000000000000000;
    edact_Name.DataField := 'act_Name';
    edact_Name.DataSource := DataSource;
    edact_Date.SetParentComponent(Self);
    edact_Date.Name := 'edact_Date';
    edact_Date.Left := 221;
    edact_Date.Top := 243;
    edact_Date.Width := 200;
    edact_Date.Height := 21;
    edact_Date.HeightPercent := 100.000000000000000000;
    edact_Date.TabOrder := 8;
    edact_Date.Text := 'edact_Date';
    edact_Date.WidthPercent := 100.000000000000000000;
    edact_Date.DataField := 'act_Date';
    edact_Date.DataSource := DataSource;
    edact_TicketNumber.SetParentComponent(Self);
    edact_TicketNumber.Name := 'edact_TicketNumber';
    edact_TicketNumber.Left := 221;
    edact_TicketNumber.Top := 270;
    edact_TicketNumber.Width := 200;
    edact_TicketNumber.Height := 21;
    edact_TicketNumber.HeightPercent := 100.000000000000000000;
    edact_TicketNumber.TabOrder := 9;
    edact_TicketNumber.Text := 'edact_TicketNumber';
    edact_TicketNumber.WidthPercent := 100.000000000000000000;
    edact_TicketNumber.DataField := 'act_TicketNumber';
    edact_TicketNumber.DataSource := DataSource;
    edact_CreatedBy.SetParentComponent(Self);
    edact_CreatedBy.Name := 'edact_CreatedBy';
    edact_CreatedBy.Left := 221;
    edact_CreatedBy.Top := 297;
    edact_CreatedBy.Width := 200;
    edact_CreatedBy.Height := 21;
    edact_CreatedBy.HeightPercent := 100.000000000000000000;
    edact_CreatedBy.TabOrder := 10;
    edact_CreatedBy.Text := 'edact_CreatedBy';
    edact_CreatedBy.WidthPercent := 100.000000000000000000;
    edact_CreatedBy.DataField := 'act_CreatedBy';
    edact_CreatedBy.DataSource := DataSource;
    edact_CreatedDate.SetParentComponent(Self);
    edact_CreatedDate.Name := 'edact_CreatedDate';
    edact_CreatedDate.Left := 221;
    edact_CreatedDate.Top := 324;
    edact_CreatedDate.Width := 200;
    edact_CreatedDate.Height := 21;
    edact_CreatedDate.HeightPercent := 100.000000000000000000;
    edact_CreatedDate.TabOrder := 11;
    edact_CreatedDate.Text := 'edact_CreatedDate';
    edact_CreatedDate.WidthPercent := 100.000000000000000000;
    edact_CreatedDate.DataField := 'act_CreatedDate';
    edact_CreatedDate.DataSource := DataSource;
    edact_LastModifiedBy.SetParentComponent(Self);
    edact_LastModifiedBy.Name := 'edact_LastModifiedBy';
    edact_LastModifiedBy.Left := 221;
    edact_LastModifiedBy.Top := 351;
    edact_LastModifiedBy.Width := 200;
    edact_LastModifiedBy.Height := 21;
    edact_LastModifiedBy.HeightPercent := 100.000000000000000000;
    edact_LastModifiedBy.TabOrder := 12;
    edact_LastModifiedBy.Text := 'edact_LastModifiedBy';
    edact_LastModifiedBy.WidthPercent := 100.000000000000000000;
    edact_LastModifiedBy.DataField := 'act_LastModifiedBy';
    edact_LastModifiedBy.DataSource := DataSource;
    edact_LastModifiedDate.SetParentComponent(Self);
    edact_LastModifiedDate.Name := 'edact_LastModifiedDate';
    edact_LastModifiedDate.Left := 221;
    edact_LastModifiedDate.Top := 378;
    edact_LastModifiedDate.Width := 200;
    edact_LastModifiedDate.Height := 21;
    edact_LastModifiedDate.HeightPercent := 100.000000000000000000;
    edact_LastModifiedDate.TabOrder := 13;
    edact_LastModifiedDate.Text := 'edact_LastModifiedDate';
    edact_LastModifiedDate.WidthPercent := 100.000000000000000000;
    edact_LastModifiedDate.DataField := 'act_LastModifiedDate';
    edact_LastModifiedDate.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := ActivitiesDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    ActivitiesDataset.SetParentComponent(Self);
    ActivitiesDataset.Name := 'ActivitiesDataset';
    ActivitiesDataset.AfterApplyUpdates := ActivitiesDatasetAfterApplyUpdates;
    ActivitiesDataset.EntitySetName := 'Activities';
    ActivitiesDataset.Connection := DMConnection.ApiConnection;
    ActivitiesDataset.Left := 176;
    ActivitiesDataset.Top := 8;
    ActivitiesDatasetact_Id.SetParentComponent(ActivitiesDataset);
    ActivitiesDatasetact_Id.Name := 'ActivitiesDatasetact_Id';
    ActivitiesDatasetact_Id.FieldName := 'act_Id';
    ActivitiesDatasetact_Id.Required := True;
    ActivitiesDatasetact_Name.SetParentComponent(ActivitiesDataset);
    ActivitiesDatasetact_Name.Name := 'ActivitiesDatasetact_Name';
    ActivitiesDatasetact_Name.FieldName := 'act_Name';
    ActivitiesDatasetact_Name.Size := 50;
    ActivitiesDatasetact_Date.SetParentComponent(ActivitiesDataset);
    ActivitiesDatasetact_Date.Name := 'ActivitiesDatasetact_Date';
    ActivitiesDatasetact_Date.FieldName := 'act_Date';
    ActivitiesDatasetact_TicketNumber.SetParentComponent(ActivitiesDataset);
    ActivitiesDatasetact_TicketNumber.Name := 'ActivitiesDatasetact_TicketNumber';
    ActivitiesDatasetact_TicketNumber.FieldName := 'act_TicketNumber';
    ActivitiesDatasetact_CreatedBy.SetParentComponent(ActivitiesDataset);
    ActivitiesDatasetact_CreatedBy.Name := 'ActivitiesDatasetact_CreatedBy';
    ActivitiesDatasetact_CreatedBy.FieldName := 'act_CreatedBy';
    ActivitiesDatasetact_CreatedDate.SetParentComponent(ActivitiesDataset);
    ActivitiesDatasetact_CreatedDate.Name := 'ActivitiesDatasetact_CreatedDate';
    ActivitiesDatasetact_CreatedDate.FieldName := 'act_CreatedDate';
    ActivitiesDatasetact_LastModifiedBy.SetParentComponent(ActivitiesDataset);
    ActivitiesDatasetact_LastModifiedBy.Name := 'ActivitiesDatasetact_LastModifiedBy';
    ActivitiesDatasetact_LastModifiedBy.FieldName := 'act_LastModifiedBy';
    ActivitiesDatasetact_LastModifiedDate.SetParentComponent(ActivitiesDataset);
    ActivitiesDatasetact_LastModifiedDate.Name := 'ActivitiesDatasetact_LastModifiedDate';
    ActivitiesDatasetact_LastModifiedDate.FieldName := 'act_LastModifiedDate';
    XDataClientActivitiesGet.SetParentComponent(Self);
    XDataClientActivitiesGet.Name := 'XDataClientActivitiesGet';
    XDataClientActivitiesGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientActivitiesGet, Self, 'OnLoad', 'XDataClientActivitiesGetLoad');
    XDataClientActivitiesGet.Left := 64;
    XDataClientActivitiesGet.Top := 8;
    XDataClientActivitiesDelete.SetParentComponent(Self);
    XDataClientActivitiesDelete.Name := 'XDataClientActivitiesDelete';
    XDataClientActivitiesDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientActivitiesDelete, Self, 'OnLoad', 'XDataClientActivitiesDeleteLoad');
    SetEvent(XDataClientActivitiesDelete, Self, 'OnError', 'XDataClientActivitiesDeleteError');
    XDataClientActivitiesDelete.Left := 64;
    XDataClientActivitiesDelete.Top := 56;
  finally
    lbact_Id.AfterLoadDFMValues;
    lbact_Name.AfterLoadDFMValues;
    lbact_Date.AfterLoadDFMValues;
    lbact_TicketNumber.AfterLoadDFMValues;
    lbact_CreatedBy.AfterLoadDFMValues;
    lbact_CreatedDate.AfterLoadDFMValues;
    lbact_LastModifiedBy.AfterLoadDFMValues;
    lbact_LastModifiedDate.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edact_Id.AfterLoadDFMValues;
    edact_Name.AfterLoadDFMValues;
    edact_Date.AfterLoadDFMValues;
    edact_TicketNumber.AfterLoadDFMValues;
    edact_CreatedBy.AfterLoadDFMValues;
    edact_CreatedDate.AfterLoadDFMValues;
    edact_LastModifiedBy.AfterLoadDFMValues;
    edact_LastModifiedDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    ActivitiesDataset.AfterLoadDFMValues;
    ActivitiesDatasetact_Id.AfterLoadDFMValues;
    ActivitiesDatasetact_Name.AfterLoadDFMValues;
    ActivitiesDatasetact_Date.AfterLoadDFMValues;
    ActivitiesDatasetact_TicketNumber.AfterLoadDFMValues;
    ActivitiesDatasetact_CreatedBy.AfterLoadDFMValues;
    ActivitiesDatasetact_CreatedDate.AfterLoadDFMValues;
    ActivitiesDatasetact_LastModifiedBy.AfterLoadDFMValues;
    ActivitiesDatasetact_LastModifiedDate.AfterLoadDFMValues;
    XDataClientActivitiesGet.AfterLoadDFMValues;
    XDataClientActivitiesDelete.AfterLoadDFMValues;
  end;
end;

end.
