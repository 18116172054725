unit View.Home;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.Controls, WEBLib.Grids,
  XData.Web.Client, WEBLib.ExtCtrls, DB;

type
  TFViewHome = class(TForm)
    WebLabel1: TLabel;
    tcHome: TTableControl;
    XDataClient: TXDataWebClient;
    procedure WebFormCreate(Sender: TObject);
    procedure XDataClientLoad(Response: TXDataClientResponse);
  protected procedure LoadDFMValues; override; end;

var
  FViewHome: TFViewHome;

implementation

uses
  JS, XData.Model.Classes,
  Container.Connection;

{$R *.dfm}


procedure TFViewHome.WebFormCreate(Sender: TObject);
var
  Container: TXDataEntityContainer;
  EntitySet: TXDataEntitySet;
  I: integer;
begin
  Container := XDataClient.Connection.Model.DefaultEntityContainer;

  tcHome.ColCount := 2;
  tcHome.RowCount := Container.EntitySets.Count + 1;

  tcHome.Cells[0, 0] := 'EntitySetName';
  tcHome.Cells[1, 0] := 'RecordCount';

  for I := 0 to Container.EntitySets.Count - 1 do
  begin
    EntitySet := Container.EntitySets[I];
    XDataClient.List(EntitySet.Name, '$top=0&$inlinecount=allpages', EntitySet.Name);
    tcHome.Cells[0, I + 1] := EntitySet.Name;
  end;
end;

procedure TFViewHome.XDataClientLoad(Response: TXDataClientResponse);
var
  I: integer;
  ResponseEntityName: string;
  RecordCount: string;
begin
  RecordCount := IntToStr(Response.Count);
  ResponseEntityName := Response.RequestId;
  for I := 1 to tcHome.RowCount do
  begin
    if tcHome.Cells[0, I] = ResponseEntityName then
    begin
      tcHome.Cells[1, I] := RecordCount;
      Break;
    end;
  end;
end;

procedure TFViewHome.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create('view.home.title');
  tcHome := TTableControl.Create('view.home.list');
  XDataClient := TXDataWebClient.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  tcHome.BeforeLoadDFMValues;
  XDataClient.BeforeLoadDFMValues;
  try
    Name := 'FViewHome';
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 24;
    WebLabel1.Top := 24;
    WebLabel1.Width := 27;
    WebLabel1.Height := 13;
    WebLabel1.Caption := 'Home';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    tcHome.SetParentComponent(Self);
    tcHome.Name := 'tcHome';
    tcHome.Left := 24;
    tcHome.Top := 43;
    tcHome.Width := 429;
    tcHome.Height := 214;
    tcHome.ElementClassName := 'table-responsive';
    tcHome.HeightPercent := 100.000000000000000000;
    tcHome.WidthPercent := 100.000000000000000000;
    tcHome.Align := alCustom;
    tcHome.BorderColor := clSilver;
    tcHome.ColHeader := False;
    tcHome.ElementTableClassName := 'table table-striped table-bordered table-hover';
    tcHome.ColCount := 1;
    tcHome.RowCount := 1;
    XDataClient.SetParentComponent(Self);
    XDataClient.Name := 'XDataClient';
    XDataClient.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClient, Self, 'OnLoad', 'XDataClientLoad');
    XDataClient.Left := 496;
    XDataClient.Top := 64;
  finally
    WebLabel1.AfterLoadDFMValues;
    tcHome.AfterLoadDFMValues;
    XDataClient.AfterLoadDFMValues;
  end;
end;

end.
