unit View.Crud.Container;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, WEBLib.Controls, Vcl.Forms,
  WEBLib.ExtCtrls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common,

  App.Types;

type
  TShowCrudListProc = reference to function(const AElementId: string;
    AEditProc: TEditProc): TForm;
  TShowCrudEditProc = reference to function(const AElementId: string;
    AListProc: TListProc; AId: JSValue): TForm;

  TFViewCrudContainer = class(TForm)
    WebPanel1: TPanel;
    lbTitle: TLabel;
  private
    FChildForm: TForm;
    FShowCrudListProc: TShowCrudListProc;
    FShowCrudEditProc: TShowCrudEditProc;
    procedure ShowListForm;
    procedure ShowEditForm(AId: TValue);
  public
    class function CreateForm(AElementID: string;
      ATitle: string; AShowCrudListProc: TShowCrudListProc;
      AShowCrudEditProc: TShowCrudEditProc): TForm;
  protected procedure LoadDFMValues; override; end;

var
  FViewCrudContainer: TFViewCrudContainer;

implementation


{$R *.dfm}

class function TFViewCrudContainer.CreateForm(AElementID, ATitle: string;
  AShowCrudListProc: TShowCrudListProc; AShowCrudEditProc: TShowCrudEditProc): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewCrudContainer(AForm).lbTitle.Caption := ATitle;
    TFViewCrudContainer(AForm).FShowCrudListProc := AShowCrudListProc;
    TFViewCrudContainer(AForm).FShowCrudEditProc := AShowCrudEditProc;
    TFViewCrudContainer(AForm).ShowListForm;
  end;

begin
  Application.CreateForm(TFViewCrudContainer, AElementID, Result, @AfterCreate);
end;

procedure TFViewCrudContainer.ShowEditForm(AId: JSValue);
begin
  if Assigned(FChildForm) then
    FChildForm.Free;
  FChildForm := FShowCrudEditProc(WebPanel1.ElementID, @ShowListForm, AId);
end;

procedure TFViewCrudContainer.ShowListForm;
begin
  if Assigned(FChildForm) then
    FChildForm.Free;
  FChildForm := FShowCrudListProc(WebPanel1.ElementID, @ShowEditForm);
end;

procedure TFViewCrudContainer.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbTitle := TLabel.Create('view.crudcontainer.title');
  WebPanel1 := TPanel.Create('view.crudcontainer.webpanel');

  lbTitle.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  try
    Name := 'FViewCrudContainer';
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    lbTitle.SetParentComponent(Self);
    lbTitle.Name := 'lbTitle';
    lbTitle.Left := 24;
    lbTitle.Top := 0;
    lbTitle.Width := 20;
    lbTitle.Height := 13;
    lbTitle.Caption := 'Title';
    lbTitle.HeightPercent := 100.000000000000000000;
    lbTitle.WidthPercent := 100.000000000000000000;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 24;
    WebPanel1.Top := 19;
    WebPanel1.Width := 521;
    WebPanel1.Height := 289;
  finally
    lbTitle.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
  end;
end;

end.
