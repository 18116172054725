unit View.ActivityBooking.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewActivityBookingEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbab_Id: TLabel;
    edab_Id: TDBEdit;
    lbact_Id: TLabel;
    edact_Id: TDBEdit;
    lbusr_Id: TLabel;
    edusr_Id: TDBEdit;
    XDataClientActivityBookingGet: TXDataWebClient;
    XDataClientActivityBookingDelete: TXDataWebClient;
    ActivityBookingDataset: TXDataWebDataSet;
    ActivityBookingDatasetab_Id: TIntegerField;
    ActivityBookingDatasetact_Id: TIntegerField;
    ActivityBookingDatasetusr_Id: TIntegerField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientActivityBookingGetLoad(Response: TXDataClientResponse);
    procedure XDataClientActivityBookingDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientActivityBookingDeleteError(Error: TXDataClientError);
    procedure ActivityBookingDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewActivityBookingEdit: TFViewActivityBookingEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewActivityBookingEdit }

procedure TFViewActivityBookingEdit.btSaveClick(Sender: TObject);
begin
  if ActivityBookingDataset.State in dsEditModes then
  begin
        ActivityBookingDataset.Post;
    ActivityBookingDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewActivityBookingEdit.btDeleteClick(Sender: TObject);
begin
  if ActivityBookingDataset.State in dsEditModes then
    ActivityBookingDataset.Post;
  XDataClientActivityBookingDelete.Delete(ActivityBookingDataset.EntitySetName,
    TJSObject(ActivityBookingDataset.CurrentData));
end;


class function TFViewActivityBookingEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewActivityBookingEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewActivityBookingEdit.InsertEntity;
begin
  ActivityBookingDataset.Close;
  ActivityBookingDataset.SetJsonData('{}');
  ActivityBookingDataset.Open;
  ActivityBookingDataset.Insert;
end;

class function TFViewActivityBookingEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewActivityBookingEdit(AForm).FInserting := AInserting;
    TFViewActivityBookingEdit(AForm).FId := AId;
    TFViewActivityBookingEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewActivityBookingEdit(AForm).InsertEntity
    else
      TFViewActivityBookingEdit(AForm).LoadEntity;
    TFViewActivityBookingEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewActivityBookingEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewActivityBookingEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    ActivityBookingDataset.Connection.Model.DefaultSchema.FindEntityType(
      ActivityBookingDataset.EntitySetName
    )
  );
  XDataClientActivityBookingGet.Get(ActivityBookingDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewActivityBookingEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewActivityBookingEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewActivityBookingEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewActivityBookingEdit.XDataClientActivityBookingGetLoad(Response: TXDataClientResponse);
begin
  ActivityBookingDataset.Close;
  ActivityBookingDataset.SetJsonData(Response.Result);
  ActivityBookingDataset.Open;
  
  ActivityBookingDataset.Edit;  
end;

procedure TFViewActivityBookingEdit.XDataClientActivityBookingDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewActivityBookingEdit.XDataClientActivityBookingDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewActivityBookingEdit.ActivityBookingDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewActivityBookingEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewActivityBookingEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbab_Id := TLabel.Create('view.activitybooking.form.lbab_Id');
  lbact_Id := TLabel.Create('view.activitybooking.form.lbact_Id');
  lbusr_Id := TLabel.Create('view.activitybooking.form.lbusr_Id');
  pnlMessage := TPanel.Create('view.activitybooking.form.message');
  lbMessage := TLabel.Create('view.activitybooking.form.message.label');
  btCloseMessage := TButton.Create('view.activitybooking.form.message.button');
  btCancel := TButton.Create('view.activitybooking.form.btBack');
  btSave := TButton.Create('view.activitybooking.form.btSave');
  btDelete := TButton.Create('view.activitybooking.form.btDelete');
  edab_Id := TDBEdit.Create('view.activitybooking.form.edab_Id');
  edact_Id := TDBEdit.Create('view.activitybooking.form.edact_Id');
  edusr_Id := TDBEdit.Create('view.activitybooking.form.edusr_Id');
  DataSource := TDataSource.Create(Self);
  ActivityBookingDataset := TXDataWebDataSet.Create(Self);
  ActivityBookingDatasetab_Id := TIntegerField.Create(Self);
  ActivityBookingDatasetact_Id := TIntegerField.Create(Self);
  ActivityBookingDatasetusr_Id := TIntegerField.Create(Self);
  XDataClientActivityBookingGet := TXDataWebClient.Create(Self);
  XDataClientActivityBookingDelete := TXDataWebClient.Create(Self);

  lbab_Id.BeforeLoadDFMValues;
  lbact_Id.BeforeLoadDFMValues;
  lbusr_Id.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edab_Id.BeforeLoadDFMValues;
  edact_Id.BeforeLoadDFMValues;
  edusr_Id.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  ActivityBookingDataset.BeforeLoadDFMValues;
  ActivityBookingDatasetab_Id.BeforeLoadDFMValues;
  ActivityBookingDatasetact_Id.BeforeLoadDFMValues;
  ActivityBookingDatasetusr_Id.BeforeLoadDFMValues;
  XDataClientActivityBookingGet.BeforeLoadDFMValues;
  XDataClientActivityBookingDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewActivityBookingEdit';
    Width := 593;
    Height := 346;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbab_Id.SetParentComponent(Self);
    lbab_Id.Name := 'lbab_Id';
    lbab_Id.Left := 24;
    lbab_Id.Top := 192;
    lbab_Id.Width := 32;
    lbab_Id.Height := 13;
    lbab_Id.Caption := 'ab_Id:';
    lbab_Id.HeightPercent := 100.000000000000000000;
    lbab_Id.WidthPercent := 100.000000000000000000;
    lbact_Id.SetParentComponent(Self);
    lbact_Id.Name := 'lbact_Id';
    lbact_Id.Left := 24;
    lbact_Id.Top := 219;
    lbact_Id.Width := 35;
    lbact_Id.Height := 13;
    lbact_Id.Caption := 'act_Id:';
    lbact_Id.HeightPercent := 100.000000000000000000;
    lbact_Id.WidthPercent := 100.000000000000000000;
    lbusr_Id.SetParentComponent(Self);
    lbusr_Id.Name := 'lbusr_Id';
    lbusr_Id.Left := 24;
    lbusr_Id.Top := 246;
    lbusr_Id.Width := 35;
    lbusr_Id.Height := 13;
    lbusr_Id.Caption := 'usr_Id:';
    lbusr_Id.HeightPercent := 100.000000000000000000;
    lbusr_Id.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 286;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 286;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 286;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edab_Id.SetParentComponent(Self);
    edab_Id.Name := 'edab_Id';
    edab_Id.Left := 221;
    edab_Id.Top := 189;
    edab_Id.Width := 200;
    edab_Id.Height := 21;
    edab_Id.HeightPercent := 100.000000000000000000;
    edab_Id.TabOrder := 6;
    edab_Id.Text := 'edab_Id';
    edab_Id.WidthPercent := 100.000000000000000000;
    edab_Id.DataField := 'ab_Id';
    edab_Id.DataSource := DataSource;
    edact_Id.SetParentComponent(Self);
    edact_Id.Name := 'edact_Id';
    edact_Id.Left := 221;
    edact_Id.Top := 216;
    edact_Id.Width := 200;
    edact_Id.Height := 21;
    edact_Id.HeightPercent := 100.000000000000000000;
    edact_Id.TabOrder := 7;
    edact_Id.Text := 'edact_Id';
    edact_Id.WidthPercent := 100.000000000000000000;
    edact_Id.DataField := 'act_Id';
    edact_Id.DataSource := DataSource;
    edusr_Id.SetParentComponent(Self);
    edusr_Id.Name := 'edusr_Id';
    edusr_Id.Left := 221;
    edusr_Id.Top := 243;
    edusr_Id.Width := 200;
    edusr_Id.Height := 21;
    edusr_Id.HeightPercent := 100.000000000000000000;
    edusr_Id.TabOrder := 8;
    edusr_Id.Text := 'edusr_Id';
    edusr_Id.WidthPercent := 100.000000000000000000;
    edusr_Id.DataField := 'usr_Id';
    edusr_Id.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := ActivityBookingDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    ActivityBookingDataset.SetParentComponent(Self);
    ActivityBookingDataset.Name := 'ActivityBookingDataset';
    ActivityBookingDataset.AfterApplyUpdates := ActivityBookingDatasetAfterApplyUpdates;
    ActivityBookingDataset.EntitySetName := 'ActivityBooking';
    ActivityBookingDataset.Connection := DMConnection.ApiConnection;
    ActivityBookingDataset.Left := 176;
    ActivityBookingDataset.Top := 8;
    ActivityBookingDatasetab_Id.SetParentComponent(ActivityBookingDataset);
    ActivityBookingDatasetab_Id.Name := 'ActivityBookingDatasetab_Id';
    ActivityBookingDatasetab_Id.FieldName := 'ab_Id';
    ActivityBookingDatasetab_Id.Required := True;
    ActivityBookingDatasetact_Id.SetParentComponent(ActivityBookingDataset);
    ActivityBookingDatasetact_Id.Name := 'ActivityBookingDatasetact_Id';
    ActivityBookingDatasetact_Id.FieldName := 'act_Id';
    ActivityBookingDatasetusr_Id.SetParentComponent(ActivityBookingDataset);
    ActivityBookingDatasetusr_Id.Name := 'ActivityBookingDatasetusr_Id';
    ActivityBookingDatasetusr_Id.FieldName := 'usr_Id';
    XDataClientActivityBookingGet.SetParentComponent(Self);
    XDataClientActivityBookingGet.Name := 'XDataClientActivityBookingGet';
    XDataClientActivityBookingGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientActivityBookingGet, Self, 'OnLoad', 'XDataClientActivityBookingGetLoad');
    XDataClientActivityBookingGet.Left := 64;
    XDataClientActivityBookingGet.Top := 8;
    XDataClientActivityBookingDelete.SetParentComponent(Self);
    XDataClientActivityBookingDelete.Name := 'XDataClientActivityBookingDelete';
    XDataClientActivityBookingDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientActivityBookingDelete, Self, 'OnLoad', 'XDataClientActivityBookingDeleteLoad');
    SetEvent(XDataClientActivityBookingDelete, Self, 'OnError', 'XDataClientActivityBookingDeleteError');
    XDataClientActivityBookingDelete.Left := 64;
    XDataClientActivityBookingDelete.Top := 56;
  finally
    lbab_Id.AfterLoadDFMValues;
    lbact_Id.AfterLoadDFMValues;
    lbusr_Id.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edab_Id.AfterLoadDFMValues;
    edact_Id.AfterLoadDFMValues;
    edusr_Id.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    ActivityBookingDataset.AfterLoadDFMValues;
    ActivityBookingDatasetab_Id.AfterLoadDFMValues;
    ActivityBookingDatasetact_Id.AfterLoadDFMValues;
    ActivityBookingDatasetusr_Id.AfterLoadDFMValues;
    XDataClientActivityBookingGet.AfterLoadDFMValues;
    XDataClientActivityBookingDelete.AfterLoadDFMValues;
  end;
end;

end.
