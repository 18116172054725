unit View.InvoiceHeader.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewInvoiceHeaderEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbinv_ID: TLabel;
    edinv_ID: TDBEdit;
    lbinv_Date: TLabel;
    edinv_Date: TDBEdit;
    lbemp_Id: TLabel;
    edemp_Id: TDBEdit;
    lbcli_Id: TLabel;
    edcli_Id: TDBEdit;
    lbinv_Type: TLabel;
    edinv_Type: TDBEdit;
    lbinv_Amount: TLabel;
    edinv_Amount: TDBEdit;
    lbinv_AmountTx1: TLabel;
    edinv_AmountTx1: TDBEdit;
    lbinv_AmountTx2: TLabel;
    edinv_AmountTx2: TDBEdit;
    lbinv_AmountTx3: TLabel;
    edinv_AmountTx3: TDBEdit;
    lbinv_AmountTotal: TLabel;
    edinv_AmountTotal: TDBEdit;
    lbinv_AmountPaid: TLabel;
    edinv_AmountPaid: TDBEdit;
    lbinv_AmountToPay: TLabel;
    edinv_AmountToPay: TDBEdit;
    lbinv_CreatedBy: TLabel;
    edinv_CreatedBy: TDBEdit;
    lbinv_CreatedDate: TLabel;
    edinv_CreatedDate: TDBEdit;
    lbinv_LastModifiedBy: TLabel;
    edinv_LastModifiedBy: TDBEdit;
    lbinv_LastModifiedDate: TLabel;
    edinv_LastModifiedDate: TDBEdit;
    XDataClientInvoiceHeaderGet: TXDataWebClient;
    XDataClientInvoiceHeaderDelete: TXDataWebClient;
    InvoiceHeaderDataset: TXDataWebDataSet;
    InvoiceHeaderDatasetinv_ID: TIntegerField;
    InvoiceHeaderDatasetinv_Date: TDateTimeField;
    InvoiceHeaderDatasetemp_Id: TIntegerField;
    InvoiceHeaderDatasetcli_Id: TIntegerField;
    InvoiceHeaderDatasetinv_Type: TIntegerField;
    InvoiceHeaderDatasetinv_Amount: TFloatField;
    InvoiceHeaderDatasetinv_AmountTx1: TFloatField;
    InvoiceHeaderDatasetinv_AmountTx2: TFloatField;
    InvoiceHeaderDatasetinv_AmountTx3: TFloatField;
    InvoiceHeaderDatasetinv_AmountTotal: TFloatField;
    InvoiceHeaderDatasetinv_AmountPaid: TFloatField;
    InvoiceHeaderDatasetinv_AmountToPay: TFloatField;
    InvoiceHeaderDatasetinv_CreatedBy: TIntegerField;
    InvoiceHeaderDatasetinv_CreatedDate: TDateTimeField;
    InvoiceHeaderDatasetinv_LastModifiedBy: TIntegerField;
    InvoiceHeaderDatasetinv_LastModifiedDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientInvoiceHeaderGetLoad(Response: TXDataClientResponse);
    procedure XDataClientInvoiceHeaderDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientInvoiceHeaderDeleteError(Error: TXDataClientError);
    procedure InvoiceHeaderDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewInvoiceHeaderEdit: TFViewInvoiceHeaderEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewInvoiceHeaderEdit }

procedure TFViewInvoiceHeaderEdit.btSaveClick(Sender: TObject);
begin
  if InvoiceHeaderDataset.State in dsEditModes then
  begin
        InvoiceHeaderDataset.Post;
    InvoiceHeaderDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewInvoiceHeaderEdit.btDeleteClick(Sender: TObject);
begin
  if InvoiceHeaderDataset.State in dsEditModes then
    InvoiceHeaderDataset.Post;
  XDataClientInvoiceHeaderDelete.Delete(InvoiceHeaderDataset.EntitySetName,
    TJSObject(InvoiceHeaderDataset.CurrentData));
end;


class function TFViewInvoiceHeaderEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewInvoiceHeaderEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewInvoiceHeaderEdit.InsertEntity;
begin
  InvoiceHeaderDataset.Close;
  InvoiceHeaderDataset.SetJsonData('{}');
  InvoiceHeaderDataset.Open;
  InvoiceHeaderDataset.Insert;
end;

class function TFViewInvoiceHeaderEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewInvoiceHeaderEdit(AForm).FInserting := AInserting;
    TFViewInvoiceHeaderEdit(AForm).FId := AId;
    TFViewInvoiceHeaderEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewInvoiceHeaderEdit(AForm).InsertEntity
    else
      TFViewInvoiceHeaderEdit(AForm).LoadEntity;
    TFViewInvoiceHeaderEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewInvoiceHeaderEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewInvoiceHeaderEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    InvoiceHeaderDataset.Connection.Model.DefaultSchema.FindEntityType(
      InvoiceHeaderDataset.EntitySetName
    )
  );
  XDataClientInvoiceHeaderGet.Get(InvoiceHeaderDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewInvoiceHeaderEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewInvoiceHeaderEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewInvoiceHeaderEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewInvoiceHeaderEdit.XDataClientInvoiceHeaderGetLoad(Response: TXDataClientResponse);
begin
  InvoiceHeaderDataset.Close;
  InvoiceHeaderDataset.SetJsonData(Response.Result);
  InvoiceHeaderDataset.Open;
  
  InvoiceHeaderDataset.Edit;  
end;

procedure TFViewInvoiceHeaderEdit.XDataClientInvoiceHeaderDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewInvoiceHeaderEdit.XDataClientInvoiceHeaderDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewInvoiceHeaderEdit.InvoiceHeaderDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewInvoiceHeaderEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewInvoiceHeaderEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbinv_ID := TLabel.Create('view.invoiceheader.form.lbinv_ID');
  lbinv_Date := TLabel.Create('view.invoiceheader.form.lbinv_Date');
  lbemp_Id := TLabel.Create('view.invoiceheader.form.lbemp_Id');
  lbcli_Id := TLabel.Create('view.invoiceheader.form.lbcli_Id');
  lbinv_Type := TLabel.Create('view.invoiceheader.form.lbinv_Type');
  lbinv_Amount := TLabel.Create('view.invoiceheader.form.lbinv_Amount');
  lbinv_AmountTx1 := TLabel.Create('view.invoiceheader.form.lbinv_AmountTx1');
  lbinv_AmountTx2 := TLabel.Create('view.invoiceheader.form.lbinv_AmountTx2');
  lbinv_AmountTx3 := TLabel.Create('view.invoiceheader.form.lbinv_AmountTx3');
  lbinv_AmountTotal := TLabel.Create('view.invoiceheader.form.lbinv_AmountTotal');
  lbinv_AmountPaid := TLabel.Create('view.invoiceheader.form.lbinv_AmountPaid');
  lbinv_AmountToPay := TLabel.Create('view.invoiceheader.form.lbinv_AmountToPay');
  lbinv_CreatedBy := TLabel.Create('view.invoiceheader.form.lbinv_CreatedBy');
  lbinv_CreatedDate := TLabel.Create('view.invoiceheader.form.lbinv_CreatedDate');
  lbinv_LastModifiedBy := TLabel.Create('view.invoiceheader.form.lbinv_LastModifiedBy');
  lbinv_LastModifiedDate := TLabel.Create('view.invoiceheader.form.lbinv_LastModifiedDate');
  pnlMessage := TPanel.Create('view.invoiceheader.form.message');
  lbMessage := TLabel.Create('view.invoiceheader.form.message.label');
  btCloseMessage := TButton.Create('view.invoiceheader.form.message.button');
  btCancel := TButton.Create('view.invoiceheader.form.btBack');
  btSave := TButton.Create('view.invoiceheader.form.btSave');
  btDelete := TButton.Create('view.invoiceheader.form.btDelete');
  edinv_ID := TDBEdit.Create('view.invoiceheader.form.edinv_ID');
  edinv_Date := TDBEdit.Create('view.invoiceheader.form.edinv_Date');
  edemp_Id := TDBEdit.Create('view.invoiceheader.form.edemp_Id');
  edcli_Id := TDBEdit.Create('view.invoiceheader.form.edcli_Id');
  edinv_Type := TDBEdit.Create('view.invoiceheader.form.edinv_Type');
  edinv_Amount := TDBEdit.Create('view.invoiceheader.form.edinv_Amount');
  edinv_AmountTx1 := TDBEdit.Create('view.invoiceheader.form.edinv_AmountTx1');
  edinv_AmountTx2 := TDBEdit.Create('view.invoiceheader.form.edinv_AmountTx2');
  edinv_AmountTx3 := TDBEdit.Create('view.invoiceheader.form.edinv_AmountTx3');
  edinv_AmountTotal := TDBEdit.Create('view.invoiceheader.form.edinv_AmountTotal');
  edinv_AmountPaid := TDBEdit.Create('view.invoiceheader.form.edinv_AmountPaid');
  edinv_AmountToPay := TDBEdit.Create('view.invoiceheader.form.edinv_AmountToPay');
  edinv_CreatedBy := TDBEdit.Create('view.invoiceheader.form.edinv_CreatedBy');
  edinv_CreatedDate := TDBEdit.Create('view.invoiceheader.form.edinv_CreatedDate');
  edinv_LastModifiedBy := TDBEdit.Create('view.invoiceheader.form.edinv_LastModifiedBy');
  edinv_LastModifiedDate := TDBEdit.Create('view.invoiceheader.form.edinv_LastModifiedDate');
  DataSource := TDataSource.Create(Self);
  InvoiceHeaderDataset := TXDataWebDataSet.Create(Self);
  InvoiceHeaderDatasetinv_ID := TIntegerField.Create(Self);
  InvoiceHeaderDatasetinv_Date := TDateTimeField.Create(Self);
  InvoiceHeaderDatasetemp_Id := TIntegerField.Create(Self);
  InvoiceHeaderDatasetcli_Id := TIntegerField.Create(Self);
  InvoiceHeaderDatasetinv_Type := TIntegerField.Create(Self);
  InvoiceHeaderDatasetinv_Amount := TFloatField.Create(Self);
  InvoiceHeaderDatasetinv_AmountTx1 := TFloatField.Create(Self);
  InvoiceHeaderDatasetinv_AmountTx2 := TFloatField.Create(Self);
  InvoiceHeaderDatasetinv_AmountTx3 := TFloatField.Create(Self);
  InvoiceHeaderDatasetinv_AmountTotal := TFloatField.Create(Self);
  InvoiceHeaderDatasetinv_AmountPaid := TFloatField.Create(Self);
  InvoiceHeaderDatasetinv_AmountToPay := TFloatField.Create(Self);
  InvoiceHeaderDatasetinv_CreatedBy := TIntegerField.Create(Self);
  InvoiceHeaderDatasetinv_CreatedDate := TDateTimeField.Create(Self);
  InvoiceHeaderDatasetinv_LastModifiedBy := TIntegerField.Create(Self);
  InvoiceHeaderDatasetinv_LastModifiedDate := TDateTimeField.Create(Self);
  XDataClientInvoiceHeaderGet := TXDataWebClient.Create(Self);
  XDataClientInvoiceHeaderDelete := TXDataWebClient.Create(Self);

  lbinv_ID.BeforeLoadDFMValues;
  lbinv_Date.BeforeLoadDFMValues;
  lbemp_Id.BeforeLoadDFMValues;
  lbcli_Id.BeforeLoadDFMValues;
  lbinv_Type.BeforeLoadDFMValues;
  lbinv_Amount.BeforeLoadDFMValues;
  lbinv_AmountTx1.BeforeLoadDFMValues;
  lbinv_AmountTx2.BeforeLoadDFMValues;
  lbinv_AmountTx3.BeforeLoadDFMValues;
  lbinv_AmountTotal.BeforeLoadDFMValues;
  lbinv_AmountPaid.BeforeLoadDFMValues;
  lbinv_AmountToPay.BeforeLoadDFMValues;
  lbinv_CreatedBy.BeforeLoadDFMValues;
  lbinv_CreatedDate.BeforeLoadDFMValues;
  lbinv_LastModifiedBy.BeforeLoadDFMValues;
  lbinv_LastModifiedDate.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edinv_ID.BeforeLoadDFMValues;
  edinv_Date.BeforeLoadDFMValues;
  edemp_Id.BeforeLoadDFMValues;
  edcli_Id.BeforeLoadDFMValues;
  edinv_Type.BeforeLoadDFMValues;
  edinv_Amount.BeforeLoadDFMValues;
  edinv_AmountTx1.BeforeLoadDFMValues;
  edinv_AmountTx2.BeforeLoadDFMValues;
  edinv_AmountTx3.BeforeLoadDFMValues;
  edinv_AmountTotal.BeforeLoadDFMValues;
  edinv_AmountPaid.BeforeLoadDFMValues;
  edinv_AmountToPay.BeforeLoadDFMValues;
  edinv_CreatedBy.BeforeLoadDFMValues;
  edinv_CreatedDate.BeforeLoadDFMValues;
  edinv_LastModifiedBy.BeforeLoadDFMValues;
  edinv_LastModifiedDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  InvoiceHeaderDataset.BeforeLoadDFMValues;
  InvoiceHeaderDatasetinv_ID.BeforeLoadDFMValues;
  InvoiceHeaderDatasetinv_Date.BeforeLoadDFMValues;
  InvoiceHeaderDatasetemp_Id.BeforeLoadDFMValues;
  InvoiceHeaderDatasetcli_Id.BeforeLoadDFMValues;
  InvoiceHeaderDatasetinv_Type.BeforeLoadDFMValues;
  InvoiceHeaderDatasetinv_Amount.BeforeLoadDFMValues;
  InvoiceHeaderDatasetinv_AmountTx1.BeforeLoadDFMValues;
  InvoiceHeaderDatasetinv_AmountTx2.BeforeLoadDFMValues;
  InvoiceHeaderDatasetinv_AmountTx3.BeforeLoadDFMValues;
  InvoiceHeaderDatasetinv_AmountTotal.BeforeLoadDFMValues;
  InvoiceHeaderDatasetinv_AmountPaid.BeforeLoadDFMValues;
  InvoiceHeaderDatasetinv_AmountToPay.BeforeLoadDFMValues;
  InvoiceHeaderDatasetinv_CreatedBy.BeforeLoadDFMValues;
  InvoiceHeaderDatasetinv_CreatedDate.BeforeLoadDFMValues;
  InvoiceHeaderDatasetinv_LastModifiedBy.BeforeLoadDFMValues;
  InvoiceHeaderDatasetinv_LastModifiedDate.BeforeLoadDFMValues;
  XDataClientInvoiceHeaderGet.BeforeLoadDFMValues;
  XDataClientInvoiceHeaderDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewInvoiceHeaderEdit';
    Width := 593;
    Height := 697;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbinv_ID.SetParentComponent(Self);
    lbinv_ID.Name := 'lbinv_ID';
    lbinv_ID.Left := 24;
    lbinv_ID.Top := 192;
    lbinv_ID.Width := 35;
    lbinv_ID.Height := 13;
    lbinv_ID.Caption := 'inv_ID:';
    lbinv_ID.HeightPercent := 100.000000000000000000;
    lbinv_ID.WidthPercent := 100.000000000000000000;
    lbinv_Date.SetParentComponent(Self);
    lbinv_Date.Name := 'lbinv_Date';
    lbinv_Date.Left := 24;
    lbinv_Date.Top := 219;
    lbinv_Date.Width := 47;
    lbinv_Date.Height := 13;
    lbinv_Date.Caption := 'inv_Date:';
    lbinv_Date.HeightPercent := 100.000000000000000000;
    lbinv_Date.WidthPercent := 100.000000000000000000;
    lbemp_Id.SetParentComponent(Self);
    lbemp_Id.Name := 'lbemp_Id';
    lbemp_Id.Left := 24;
    lbemp_Id.Top := 246;
    lbemp_Id.Width := 40;
    lbemp_Id.Height := 13;
    lbemp_Id.Caption := 'emp_Id:';
    lbemp_Id.HeightPercent := 100.000000000000000000;
    lbemp_Id.WidthPercent := 100.000000000000000000;
    lbcli_Id.SetParentComponent(Self);
    lbcli_Id.Name := 'lbcli_Id';
    lbcli_Id.Left := 24;
    lbcli_Id.Top := 273;
    lbcli_Id.Width := 29;
    lbcli_Id.Height := 13;
    lbcli_Id.Caption := 'cli_Id:';
    lbcli_Id.HeightPercent := 100.000000000000000000;
    lbcli_Id.WidthPercent := 100.000000000000000000;
    lbinv_Type.SetParentComponent(Self);
    lbinv_Type.Name := 'lbinv_Type';
    lbinv_Type.Left := 24;
    lbinv_Type.Top := 300;
    lbinv_Type.Width := 48;
    lbinv_Type.Height := 13;
    lbinv_Type.Caption := 'inv_Type:';
    lbinv_Type.HeightPercent := 100.000000000000000000;
    lbinv_Type.WidthPercent := 100.000000000000000000;
    lbinv_Amount.SetParentComponent(Self);
    lbinv_Amount.Name := 'lbinv_Amount';
    lbinv_Amount.Left := 24;
    lbinv_Amount.Top := 327;
    lbinv_Amount.Width := 61;
    lbinv_Amount.Height := 13;
    lbinv_Amount.Caption := 'inv_Amount:';
    lbinv_Amount.HeightPercent := 100.000000000000000000;
    lbinv_Amount.WidthPercent := 100.000000000000000000;
    lbinv_AmountTx1.SetParentComponent(Self);
    lbinv_AmountTx1.Name := 'lbinv_AmountTx1';
    lbinv_AmountTx1.Left := 24;
    lbinv_AmountTx1.Top := 354;
    lbinv_AmountTx1.Width := 79;
    lbinv_AmountTx1.Height := 13;
    lbinv_AmountTx1.Caption := 'inv_AmountTx1:';
    lbinv_AmountTx1.HeightPercent := 100.000000000000000000;
    lbinv_AmountTx1.WidthPercent := 100.000000000000000000;
    lbinv_AmountTx2.SetParentComponent(Self);
    lbinv_AmountTx2.Name := 'lbinv_AmountTx2';
    lbinv_AmountTx2.Left := 24;
    lbinv_AmountTx2.Top := 381;
    lbinv_AmountTx2.Width := 79;
    lbinv_AmountTx2.Height := 13;
    lbinv_AmountTx2.Caption := 'inv_AmountTx2:';
    lbinv_AmountTx2.HeightPercent := 100.000000000000000000;
    lbinv_AmountTx2.WidthPercent := 100.000000000000000000;
    lbinv_AmountTx3.SetParentComponent(Self);
    lbinv_AmountTx3.Name := 'lbinv_AmountTx3';
    lbinv_AmountTx3.Left := 24;
    lbinv_AmountTx3.Top := 408;
    lbinv_AmountTx3.Width := 79;
    lbinv_AmountTx3.Height := 13;
    lbinv_AmountTx3.Caption := 'inv_AmountTx3:';
    lbinv_AmountTx3.HeightPercent := 100.000000000000000000;
    lbinv_AmountTx3.WidthPercent := 100.000000000000000000;
    lbinv_AmountTotal.SetParentComponent(Self);
    lbinv_AmountTotal.Name := 'lbinv_AmountTotal';
    lbinv_AmountTotal.Left := 24;
    lbinv_AmountTotal.Top := 435;
    lbinv_AmountTotal.Width := 85;
    lbinv_AmountTotal.Height := 13;
    lbinv_AmountTotal.Caption := 'inv_AmountTotal:';
    lbinv_AmountTotal.HeightPercent := 100.000000000000000000;
    lbinv_AmountTotal.WidthPercent := 100.000000000000000000;
    lbinv_AmountPaid.SetParentComponent(Self);
    lbinv_AmountPaid.Name := 'lbinv_AmountPaid';
    lbinv_AmountPaid.Left := 24;
    lbinv_AmountPaid.Top := 462;
    lbinv_AmountPaid.Width := 81;
    lbinv_AmountPaid.Height := 13;
    lbinv_AmountPaid.Caption := 'inv_AmountPaid:';
    lbinv_AmountPaid.HeightPercent := 100.000000000000000000;
    lbinv_AmountPaid.WidthPercent := 100.000000000000000000;
    lbinv_AmountToPay.SetParentComponent(Self);
    lbinv_AmountToPay.Name := 'lbinv_AmountToPay';
    lbinv_AmountToPay.Left := 24;
    lbinv_AmountToPay.Top := 489;
    lbinv_AmountToPay.Width := 91;
    lbinv_AmountToPay.Height := 13;
    lbinv_AmountToPay.Caption := 'inv_AmountToPay:';
    lbinv_AmountToPay.HeightPercent := 100.000000000000000000;
    lbinv_AmountToPay.WidthPercent := 100.000000000000000000;
    lbinv_CreatedBy.SetParentComponent(Self);
    lbinv_CreatedBy.Name := 'lbinv_CreatedBy';
    lbinv_CreatedBy.Left := 24;
    lbinv_CreatedBy.Top := 516;
    lbinv_CreatedBy.Width := 75;
    lbinv_CreatedBy.Height := 13;
    lbinv_CreatedBy.Caption := 'inv_CreatedBy:';
    lbinv_CreatedBy.HeightPercent := 100.000000000000000000;
    lbinv_CreatedBy.WidthPercent := 100.000000000000000000;
    lbinv_CreatedDate.SetParentComponent(Self);
    lbinv_CreatedDate.Name := 'lbinv_CreatedDate';
    lbinv_CreatedDate.Left := 24;
    lbinv_CreatedDate.Top := 543;
    lbinv_CreatedDate.Width := 86;
    lbinv_CreatedDate.Height := 13;
    lbinv_CreatedDate.Caption := 'inv_CreatedDate:';
    lbinv_CreatedDate.HeightPercent := 100.000000000000000000;
    lbinv_CreatedDate.WidthPercent := 100.000000000000000000;
    lbinv_LastModifiedBy.SetParentComponent(Self);
    lbinv_LastModifiedBy.Name := 'lbinv_LastModifiedBy';
    lbinv_LastModifiedBy.Left := 24;
    lbinv_LastModifiedBy.Top := 570;
    lbinv_LastModifiedBy.Width := 96;
    lbinv_LastModifiedBy.Height := 13;
    lbinv_LastModifiedBy.Caption := 'inv_LastModifiedBy:';
    lbinv_LastModifiedBy.HeightPercent := 100.000000000000000000;
    lbinv_LastModifiedBy.WidthPercent := 100.000000000000000000;
    lbinv_LastModifiedDate.SetParentComponent(Self);
    lbinv_LastModifiedDate.Name := 'lbinv_LastModifiedDate';
    lbinv_LastModifiedDate.Left := 24;
    lbinv_LastModifiedDate.Top := 597;
    lbinv_LastModifiedDate.Width := 107;
    lbinv_LastModifiedDate.Height := 13;
    lbinv_LastModifiedDate.Caption := 'inv_LastModifiedDate:';
    lbinv_LastModifiedDate.HeightPercent := 100.000000000000000000;
    lbinv_LastModifiedDate.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 637;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 637;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 637;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edinv_ID.SetParentComponent(Self);
    edinv_ID.Name := 'edinv_ID';
    edinv_ID.Left := 221;
    edinv_ID.Top := 189;
    edinv_ID.Width := 200;
    edinv_ID.Height := 21;
    edinv_ID.HeightPercent := 100.000000000000000000;
    edinv_ID.TabOrder := 6;
    edinv_ID.Text := 'edinv_ID';
    edinv_ID.WidthPercent := 100.000000000000000000;
    edinv_ID.DataField := 'inv_ID';
    edinv_ID.DataSource := DataSource;
    edinv_Date.SetParentComponent(Self);
    edinv_Date.Name := 'edinv_Date';
    edinv_Date.Left := 221;
    edinv_Date.Top := 216;
    edinv_Date.Width := 200;
    edinv_Date.Height := 21;
    edinv_Date.HeightPercent := 100.000000000000000000;
    edinv_Date.TabOrder := 7;
    edinv_Date.Text := 'edinv_Date';
    edinv_Date.WidthPercent := 100.000000000000000000;
    edinv_Date.DataField := 'inv_Date';
    edinv_Date.DataSource := DataSource;
    edemp_Id.SetParentComponent(Self);
    edemp_Id.Name := 'edemp_Id';
    edemp_Id.Left := 221;
    edemp_Id.Top := 243;
    edemp_Id.Width := 200;
    edemp_Id.Height := 21;
    edemp_Id.HeightPercent := 100.000000000000000000;
    edemp_Id.TabOrder := 8;
    edemp_Id.Text := 'edemp_Id';
    edemp_Id.WidthPercent := 100.000000000000000000;
    edemp_Id.DataField := 'emp_Id';
    edemp_Id.DataSource := DataSource;
    edcli_Id.SetParentComponent(Self);
    edcli_Id.Name := 'edcli_Id';
    edcli_Id.Left := 221;
    edcli_Id.Top := 270;
    edcli_Id.Width := 200;
    edcli_Id.Height := 21;
    edcli_Id.HeightPercent := 100.000000000000000000;
    edcli_Id.TabOrder := 9;
    edcli_Id.Text := 'edcli_Id';
    edcli_Id.WidthPercent := 100.000000000000000000;
    edcli_Id.DataField := 'cli_Id';
    edcli_Id.DataSource := DataSource;
    edinv_Type.SetParentComponent(Self);
    edinv_Type.Name := 'edinv_Type';
    edinv_Type.Left := 221;
    edinv_Type.Top := 297;
    edinv_Type.Width := 200;
    edinv_Type.Height := 21;
    edinv_Type.HeightPercent := 100.000000000000000000;
    edinv_Type.TabOrder := 10;
    edinv_Type.Text := 'edinv_Type';
    edinv_Type.WidthPercent := 100.000000000000000000;
    edinv_Type.DataField := 'inv_Type';
    edinv_Type.DataSource := DataSource;
    edinv_Amount.SetParentComponent(Self);
    edinv_Amount.Name := 'edinv_Amount';
    edinv_Amount.Left := 221;
    edinv_Amount.Top := 324;
    edinv_Amount.Width := 200;
    edinv_Amount.Height := 21;
    edinv_Amount.HeightPercent := 100.000000000000000000;
    edinv_Amount.TabOrder := 11;
    edinv_Amount.Text := 'edinv_Amount';
    edinv_Amount.WidthPercent := 100.000000000000000000;
    edinv_Amount.DataField := 'inv_Amount';
    edinv_Amount.DataSource := DataSource;
    edinv_AmountTx1.SetParentComponent(Self);
    edinv_AmountTx1.Name := 'edinv_AmountTx1';
    edinv_AmountTx1.Left := 221;
    edinv_AmountTx1.Top := 351;
    edinv_AmountTx1.Width := 200;
    edinv_AmountTx1.Height := 21;
    edinv_AmountTx1.HeightPercent := 100.000000000000000000;
    edinv_AmountTx1.TabOrder := 12;
    edinv_AmountTx1.Text := 'edinv_AmountTx1';
    edinv_AmountTx1.WidthPercent := 100.000000000000000000;
    edinv_AmountTx1.DataField := 'inv_AmountTx1';
    edinv_AmountTx1.DataSource := DataSource;
    edinv_AmountTx2.SetParentComponent(Self);
    edinv_AmountTx2.Name := 'edinv_AmountTx2';
    edinv_AmountTx2.Left := 221;
    edinv_AmountTx2.Top := 378;
    edinv_AmountTx2.Width := 200;
    edinv_AmountTx2.Height := 21;
    edinv_AmountTx2.HeightPercent := 100.000000000000000000;
    edinv_AmountTx2.TabOrder := 13;
    edinv_AmountTx2.Text := 'edinv_AmountTx2';
    edinv_AmountTx2.WidthPercent := 100.000000000000000000;
    edinv_AmountTx2.DataField := 'inv_AmountTx2';
    edinv_AmountTx2.DataSource := DataSource;
    edinv_AmountTx3.SetParentComponent(Self);
    edinv_AmountTx3.Name := 'edinv_AmountTx3';
    edinv_AmountTx3.Left := 221;
    edinv_AmountTx3.Top := 405;
    edinv_AmountTx3.Width := 200;
    edinv_AmountTx3.Height := 21;
    edinv_AmountTx3.HeightPercent := 100.000000000000000000;
    edinv_AmountTx3.TabOrder := 14;
    edinv_AmountTx3.Text := 'edinv_AmountTx3';
    edinv_AmountTx3.WidthPercent := 100.000000000000000000;
    edinv_AmountTx3.DataField := 'inv_AmountTx3';
    edinv_AmountTx3.DataSource := DataSource;
    edinv_AmountTotal.SetParentComponent(Self);
    edinv_AmountTotal.Name := 'edinv_AmountTotal';
    edinv_AmountTotal.Left := 221;
    edinv_AmountTotal.Top := 432;
    edinv_AmountTotal.Width := 200;
    edinv_AmountTotal.Height := 21;
    edinv_AmountTotal.HeightPercent := 100.000000000000000000;
    edinv_AmountTotal.TabOrder := 15;
    edinv_AmountTotal.Text := 'edinv_AmountTotal';
    edinv_AmountTotal.WidthPercent := 100.000000000000000000;
    edinv_AmountTotal.DataField := 'inv_AmountTotal';
    edinv_AmountTotal.DataSource := DataSource;
    edinv_AmountPaid.SetParentComponent(Self);
    edinv_AmountPaid.Name := 'edinv_AmountPaid';
    edinv_AmountPaid.Left := 221;
    edinv_AmountPaid.Top := 459;
    edinv_AmountPaid.Width := 200;
    edinv_AmountPaid.Height := 21;
    edinv_AmountPaid.HeightPercent := 100.000000000000000000;
    edinv_AmountPaid.TabOrder := 16;
    edinv_AmountPaid.Text := 'edinv_AmountPaid';
    edinv_AmountPaid.WidthPercent := 100.000000000000000000;
    edinv_AmountPaid.DataField := 'inv_AmountPaid';
    edinv_AmountPaid.DataSource := DataSource;
    edinv_AmountToPay.SetParentComponent(Self);
    edinv_AmountToPay.Name := 'edinv_AmountToPay';
    edinv_AmountToPay.Left := 221;
    edinv_AmountToPay.Top := 486;
    edinv_AmountToPay.Width := 200;
    edinv_AmountToPay.Height := 21;
    edinv_AmountToPay.HeightPercent := 100.000000000000000000;
    edinv_AmountToPay.TabOrder := 17;
    edinv_AmountToPay.Text := 'edinv_AmountToPay';
    edinv_AmountToPay.WidthPercent := 100.000000000000000000;
    edinv_AmountToPay.DataField := 'inv_AmountToPay';
    edinv_AmountToPay.DataSource := DataSource;
    edinv_CreatedBy.SetParentComponent(Self);
    edinv_CreatedBy.Name := 'edinv_CreatedBy';
    edinv_CreatedBy.Left := 221;
    edinv_CreatedBy.Top := 513;
    edinv_CreatedBy.Width := 200;
    edinv_CreatedBy.Height := 21;
    edinv_CreatedBy.HeightPercent := 100.000000000000000000;
    edinv_CreatedBy.TabOrder := 18;
    edinv_CreatedBy.Text := 'edinv_CreatedBy';
    edinv_CreatedBy.WidthPercent := 100.000000000000000000;
    edinv_CreatedBy.DataField := 'inv_CreatedBy';
    edinv_CreatedBy.DataSource := DataSource;
    edinv_CreatedDate.SetParentComponent(Self);
    edinv_CreatedDate.Name := 'edinv_CreatedDate';
    edinv_CreatedDate.Left := 221;
    edinv_CreatedDate.Top := 540;
    edinv_CreatedDate.Width := 200;
    edinv_CreatedDate.Height := 21;
    edinv_CreatedDate.HeightPercent := 100.000000000000000000;
    edinv_CreatedDate.TabOrder := 19;
    edinv_CreatedDate.Text := 'edinv_CreatedDate';
    edinv_CreatedDate.WidthPercent := 100.000000000000000000;
    edinv_CreatedDate.DataField := 'inv_CreatedDate';
    edinv_CreatedDate.DataSource := DataSource;
    edinv_LastModifiedBy.SetParentComponent(Self);
    edinv_LastModifiedBy.Name := 'edinv_LastModifiedBy';
    edinv_LastModifiedBy.Left := 221;
    edinv_LastModifiedBy.Top := 567;
    edinv_LastModifiedBy.Width := 200;
    edinv_LastModifiedBy.Height := 21;
    edinv_LastModifiedBy.HeightPercent := 100.000000000000000000;
    edinv_LastModifiedBy.TabOrder := 20;
    edinv_LastModifiedBy.Text := 'edinv_LastModifiedBy';
    edinv_LastModifiedBy.WidthPercent := 100.000000000000000000;
    edinv_LastModifiedBy.DataField := 'inv_LastModifiedBy';
    edinv_LastModifiedBy.DataSource := DataSource;
    edinv_LastModifiedDate.SetParentComponent(Self);
    edinv_LastModifiedDate.Name := 'edinv_LastModifiedDate';
    edinv_LastModifiedDate.Left := 221;
    edinv_LastModifiedDate.Top := 594;
    edinv_LastModifiedDate.Width := 200;
    edinv_LastModifiedDate.Height := 21;
    edinv_LastModifiedDate.HeightPercent := 100.000000000000000000;
    edinv_LastModifiedDate.TabOrder := 21;
    edinv_LastModifiedDate.Text := 'edinv_LastModifiedDate';
    edinv_LastModifiedDate.WidthPercent := 100.000000000000000000;
    edinv_LastModifiedDate.DataField := 'inv_LastModifiedDate';
    edinv_LastModifiedDate.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := InvoiceHeaderDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    InvoiceHeaderDataset.SetParentComponent(Self);
    InvoiceHeaderDataset.Name := 'InvoiceHeaderDataset';
    InvoiceHeaderDataset.AfterApplyUpdates := InvoiceHeaderDatasetAfterApplyUpdates;
    InvoiceHeaderDataset.EntitySetName := 'InvoiceHeader';
    InvoiceHeaderDataset.Connection := DMConnection.ApiConnection;
    InvoiceHeaderDataset.Left := 176;
    InvoiceHeaderDataset.Top := 8;
    InvoiceHeaderDatasetinv_ID.SetParentComponent(InvoiceHeaderDataset);
    InvoiceHeaderDatasetinv_ID.Name := 'InvoiceHeaderDatasetinv_ID';
    InvoiceHeaderDatasetinv_ID.FieldName := 'inv_ID';
    InvoiceHeaderDatasetinv_ID.Required := True;
    InvoiceHeaderDatasetinv_Date.SetParentComponent(InvoiceHeaderDataset);
    InvoiceHeaderDatasetinv_Date.Name := 'InvoiceHeaderDatasetinv_Date';
    InvoiceHeaderDatasetinv_Date.FieldName := 'inv_Date';
    InvoiceHeaderDatasetemp_Id.SetParentComponent(InvoiceHeaderDataset);
    InvoiceHeaderDatasetemp_Id.Name := 'InvoiceHeaderDatasetemp_Id';
    InvoiceHeaderDatasetemp_Id.FieldName := 'emp_Id';
    InvoiceHeaderDatasetcli_Id.SetParentComponent(InvoiceHeaderDataset);
    InvoiceHeaderDatasetcli_Id.Name := 'InvoiceHeaderDatasetcli_Id';
    InvoiceHeaderDatasetcli_Id.FieldName := 'cli_Id';
    InvoiceHeaderDatasetinv_Type.SetParentComponent(InvoiceHeaderDataset);
    InvoiceHeaderDatasetinv_Type.Name := 'InvoiceHeaderDatasetinv_Type';
    InvoiceHeaderDatasetinv_Type.FieldName := 'inv_Type';
    InvoiceHeaderDatasetinv_Amount.SetParentComponent(InvoiceHeaderDataset);
    InvoiceHeaderDatasetinv_Amount.Name := 'InvoiceHeaderDatasetinv_Amount';
    InvoiceHeaderDatasetinv_Amount.FieldName := 'inv_Amount';
    InvoiceHeaderDatasetinv_AmountTx1.SetParentComponent(InvoiceHeaderDataset);
    InvoiceHeaderDatasetinv_AmountTx1.Name := 'InvoiceHeaderDatasetinv_AmountTx1';
    InvoiceHeaderDatasetinv_AmountTx1.FieldName := 'inv_AmountTx1';
    InvoiceHeaderDatasetinv_AmountTx2.SetParentComponent(InvoiceHeaderDataset);
    InvoiceHeaderDatasetinv_AmountTx2.Name := 'InvoiceHeaderDatasetinv_AmountTx2';
    InvoiceHeaderDatasetinv_AmountTx2.FieldName := 'inv_AmountTx2';
    InvoiceHeaderDatasetinv_AmountTx3.SetParentComponent(InvoiceHeaderDataset);
    InvoiceHeaderDatasetinv_AmountTx3.Name := 'InvoiceHeaderDatasetinv_AmountTx3';
    InvoiceHeaderDatasetinv_AmountTx3.FieldName := 'inv_AmountTx3';
    InvoiceHeaderDatasetinv_AmountTotal.SetParentComponent(InvoiceHeaderDataset);
    InvoiceHeaderDatasetinv_AmountTotal.Name := 'InvoiceHeaderDatasetinv_AmountTotal';
    InvoiceHeaderDatasetinv_AmountTotal.FieldName := 'inv_AmountTotal';
    InvoiceHeaderDatasetinv_AmountPaid.SetParentComponent(InvoiceHeaderDataset);
    InvoiceHeaderDatasetinv_AmountPaid.Name := 'InvoiceHeaderDatasetinv_AmountPaid';
    InvoiceHeaderDatasetinv_AmountPaid.FieldName := 'inv_AmountPaid';
    InvoiceHeaderDatasetinv_AmountToPay.SetParentComponent(InvoiceHeaderDataset);
    InvoiceHeaderDatasetinv_AmountToPay.Name := 'InvoiceHeaderDatasetinv_AmountToPay';
    InvoiceHeaderDatasetinv_AmountToPay.FieldName := 'inv_AmountToPay';
    InvoiceHeaderDatasetinv_CreatedBy.SetParentComponent(InvoiceHeaderDataset);
    InvoiceHeaderDatasetinv_CreatedBy.Name := 'InvoiceHeaderDatasetinv_CreatedBy';
    InvoiceHeaderDatasetinv_CreatedBy.FieldName := 'inv_CreatedBy';
    InvoiceHeaderDatasetinv_CreatedDate.SetParentComponent(InvoiceHeaderDataset);
    InvoiceHeaderDatasetinv_CreatedDate.Name := 'InvoiceHeaderDatasetinv_CreatedDate';
    InvoiceHeaderDatasetinv_CreatedDate.FieldName := 'inv_CreatedDate';
    InvoiceHeaderDatasetinv_LastModifiedBy.SetParentComponent(InvoiceHeaderDataset);
    InvoiceHeaderDatasetinv_LastModifiedBy.Name := 'InvoiceHeaderDatasetinv_LastModifiedBy';
    InvoiceHeaderDatasetinv_LastModifiedBy.FieldName := 'inv_LastModifiedBy';
    InvoiceHeaderDatasetinv_LastModifiedDate.SetParentComponent(InvoiceHeaderDataset);
    InvoiceHeaderDatasetinv_LastModifiedDate.Name := 'InvoiceHeaderDatasetinv_LastModifiedDate';
    InvoiceHeaderDatasetinv_LastModifiedDate.FieldName := 'inv_LastModifiedDate';
    XDataClientInvoiceHeaderGet.SetParentComponent(Self);
    XDataClientInvoiceHeaderGet.Name := 'XDataClientInvoiceHeaderGet';
    XDataClientInvoiceHeaderGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientInvoiceHeaderGet, Self, 'OnLoad', 'XDataClientInvoiceHeaderGetLoad');
    XDataClientInvoiceHeaderGet.Left := 64;
    XDataClientInvoiceHeaderGet.Top := 8;
    XDataClientInvoiceHeaderDelete.SetParentComponent(Self);
    XDataClientInvoiceHeaderDelete.Name := 'XDataClientInvoiceHeaderDelete';
    XDataClientInvoiceHeaderDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientInvoiceHeaderDelete, Self, 'OnLoad', 'XDataClientInvoiceHeaderDeleteLoad');
    SetEvent(XDataClientInvoiceHeaderDelete, Self, 'OnError', 'XDataClientInvoiceHeaderDeleteError');
    XDataClientInvoiceHeaderDelete.Left := 64;
    XDataClientInvoiceHeaderDelete.Top := 56;
  finally
    lbinv_ID.AfterLoadDFMValues;
    lbinv_Date.AfterLoadDFMValues;
    lbemp_Id.AfterLoadDFMValues;
    lbcli_Id.AfterLoadDFMValues;
    lbinv_Type.AfterLoadDFMValues;
    lbinv_Amount.AfterLoadDFMValues;
    lbinv_AmountTx1.AfterLoadDFMValues;
    lbinv_AmountTx2.AfterLoadDFMValues;
    lbinv_AmountTx3.AfterLoadDFMValues;
    lbinv_AmountTotal.AfterLoadDFMValues;
    lbinv_AmountPaid.AfterLoadDFMValues;
    lbinv_AmountToPay.AfterLoadDFMValues;
    lbinv_CreatedBy.AfterLoadDFMValues;
    lbinv_CreatedDate.AfterLoadDFMValues;
    lbinv_LastModifiedBy.AfterLoadDFMValues;
    lbinv_LastModifiedDate.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edinv_ID.AfterLoadDFMValues;
    edinv_Date.AfterLoadDFMValues;
    edemp_Id.AfterLoadDFMValues;
    edcli_Id.AfterLoadDFMValues;
    edinv_Type.AfterLoadDFMValues;
    edinv_Amount.AfterLoadDFMValues;
    edinv_AmountTx1.AfterLoadDFMValues;
    edinv_AmountTx2.AfterLoadDFMValues;
    edinv_AmountTx3.AfterLoadDFMValues;
    edinv_AmountTotal.AfterLoadDFMValues;
    edinv_AmountPaid.AfterLoadDFMValues;
    edinv_AmountToPay.AfterLoadDFMValues;
    edinv_CreatedBy.AfterLoadDFMValues;
    edinv_CreatedDate.AfterLoadDFMValues;
    edinv_LastModifiedBy.AfterLoadDFMValues;
    edinv_LastModifiedDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    InvoiceHeaderDataset.AfterLoadDFMValues;
    InvoiceHeaderDatasetinv_ID.AfterLoadDFMValues;
    InvoiceHeaderDatasetinv_Date.AfterLoadDFMValues;
    InvoiceHeaderDatasetemp_Id.AfterLoadDFMValues;
    InvoiceHeaderDatasetcli_Id.AfterLoadDFMValues;
    InvoiceHeaderDatasetinv_Type.AfterLoadDFMValues;
    InvoiceHeaderDatasetinv_Amount.AfterLoadDFMValues;
    InvoiceHeaderDatasetinv_AmountTx1.AfterLoadDFMValues;
    InvoiceHeaderDatasetinv_AmountTx2.AfterLoadDFMValues;
    InvoiceHeaderDatasetinv_AmountTx3.AfterLoadDFMValues;
    InvoiceHeaderDatasetinv_AmountTotal.AfterLoadDFMValues;
    InvoiceHeaderDatasetinv_AmountPaid.AfterLoadDFMValues;
    InvoiceHeaderDatasetinv_AmountToPay.AfterLoadDFMValues;
    InvoiceHeaderDatasetinv_CreatedBy.AfterLoadDFMValues;
    InvoiceHeaderDatasetinv_CreatedDate.AfterLoadDFMValues;
    InvoiceHeaderDatasetinv_LastModifiedBy.AfterLoadDFMValues;
    InvoiceHeaderDatasetinv_LastModifiedDate.AfterLoadDFMValues;
    XDataClientInvoiceHeaderGet.AfterLoadDFMValues;
    XDataClientInvoiceHeaderDelete.AfterLoadDFMValues;
  end;
end;

end.
