unit View.InvoiceDetail.List;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, Math,

  WEBLib.StdCtrls, Web, JS, WEBLib.Lists,

  App.Types, WEBLib.Controls, WEBLib.Grids, Data.DB,
  XData.Web.Dataset, WEBLib.DBCtrls, WEBLib.DB, WEBLib.ExtCtrls,

  XData.Web.JsonDataset,

  Grid.Plugins,
  Crud.Utils;

type
  TFViewInvoiceDetailList = class(TForm)
    btNew: TButton;
    InvoiceDetailDataset: TXDataWebDataSet;
    InvoiceDetailDatasetdet_Id: TIntegerField;
    InvoiceDetailDatasetdet_Qty: TFloatField;
    InvoiceDetailDatasetcat_Id: TIntegerField;
    InvoiceDetailDatasetcs_Id: TIntegerField;
    InvoiceDetailDatasetra_Id: TIntegerField;
    InvoiceDetailDatasetcp_Id: TIntegerField;
    InvoiceDetailDatasetdet_Description: TStringField;
    InvoiceDetailDatasetdet_Price: TFloatField;
    InvoiceDetailDatasetdet_Ext: TFloatField;
    WebDBTableControl1: TDBTableControl;
    WebDataSource: TDataSource;
    cbPageSize: TComboBox;
    edSearch: TEdit;
    lbPaginationInfo: TLabel;
    lcPaginator: TListControl;
    procedure WebFormCreate(Sender: TObject);
    procedure btNewClick(Sender: TObject);
    procedure WebDBTableControl1GetCellChildren(Sender: TObject; ACol,
      ARow: Integer; AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure cbPageSizeChange(Sender: TObject);
    procedure edSearchChange(Sender: TObject);
  private
    FEditProc: TEditProc;
    FGridPlugin: TGridPlugin;
  public
    class function CreateForm(AElementID: string; AShowEditProc: TEditProc): TForm;
  protected procedure LoadDFMValues; override; end;

var
  FViewInvoiceDetailList: TFViewInvoiceDetailList;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewInvoiceDetailList }

procedure TFViewInvoiceDetailList.cbPageSizeChange(Sender: TObject);
begin
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text), True);
end;

class function TFViewInvoiceDetailList.CreateForm(AElementID: string;
  AShowEditProc: TEditProc): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewInvoiceDetailList(AForm).FEditProc := AShowEditProc;
  end;

begin
  Application.CreateForm(TFViewInvoiceDetailList, AElementID, Result, @AfterCreate);
end;

procedure TFViewInvoiceDetailList.edSearchChange(Sender: TObject);
begin
  FGridPlugin.SetFilterText(edSearch.Text, True);
end;

procedure TFViewInvoiceDetailList.btNewClick(Sender: TObject);
begin
  FEditProc( nil );
end;

procedure TFViewInvoiceDetailList.WebDBTableControl1GetCellChildren(Sender: TObject;
  ACol, ARow: Integer; AField: TField; AValue: string;
  AElement: TJSHTMLElementRecord);
var
  RecordId: JSValue;

  procedure EditButtonClick(Sender: TObject);
  begin
    FEditProc(RecordId);
  end;

var
  Button: TButton;
begin
  if ARow = 0 then
    Exit;

  if WebDBTableControl1.Columns[ACol].Title = 'Actions' then
  begin
    RecordId := TCrudUtils.GetIdFromObject(
      InvoiceDetailDataset.Connection.Model.DefaultSchema.FindEntityType(InvoiceDetailDataset.EntitySetName),
      InvoiceDetailDataset.CurrentData
    );
    Button := TButton.Create(nil);
    Button.Caption := 'Edit';
    Button.OnClick := @EditButtonClick;
    Button.ElementClassName := 'btn btn-outline btn-default btn-xs';
    Button.ParentElement := AElement.element;
    Button.WidthStyle := TSizeStyle.ssAuto;
    Button.HeightStyle := TSizeStyle.ssAuto;
  end;
end;

procedure TFViewInvoiceDetailList.WebFormCreate(Sender: TObject);
begin
  FGridPlugin := TGridPlugin.Create(
    WebDBTableControl1,
    InvoiceDetailDataset,
    lcPaginator,
    lbPaginationInfo
  );
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text));
  FGridPlugin.SetFilterText(edSearch.Text);
  FGridPlugin.Load;
end;

procedure TFViewInvoiceDetailList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbPaginationInfo := TLabel.Create('view.invoicedetail.list.pagination.info');
  btNew := TButton.Create('view.invoicedetail.list.newbutton');
  WebDBTableControl1 := TDBTableControl.Create('view.invoicedetail.list.table');
  cbPageSize := TComboBox.Create('view.invoicedetail.list.cbPageSize');
  edSearch := TEdit.Create('view.invoicedetail.list.search');
  lcPaginator := TListControl.Create('view.invoicedetail.list.pagination');
  InvoiceDetailDataset := TXDataWebDataSet.Create(Self);
  InvoiceDetailDatasetdet_Id := TIntegerField.Create(Self);
  InvoiceDetailDatasetdet_Qty := TFloatField.Create(Self);
  InvoiceDetailDatasetcat_Id := TIntegerField.Create(Self);
  InvoiceDetailDatasetcs_Id := TIntegerField.Create(Self);
  InvoiceDetailDatasetra_Id := TIntegerField.Create(Self);
  InvoiceDetailDatasetcp_Id := TIntegerField.Create(Self);
  InvoiceDetailDatasetdet_Description := TStringField.Create(Self);
  InvoiceDetailDatasetdet_Price := TFloatField.Create(Self);
  InvoiceDetailDatasetdet_Ext := TFloatField.Create(Self);
  WebDataSource := TDataSource.Create(Self);

  lbPaginationInfo.BeforeLoadDFMValues;
  btNew.BeforeLoadDFMValues;
  WebDBTableControl1.BeforeLoadDFMValues;
  cbPageSize.BeforeLoadDFMValues;
  edSearch.BeforeLoadDFMValues;
  lcPaginator.BeforeLoadDFMValues;
  InvoiceDetailDataset.BeforeLoadDFMValues;
  InvoiceDetailDatasetdet_Id.BeforeLoadDFMValues;
  InvoiceDetailDatasetdet_Qty.BeforeLoadDFMValues;
  InvoiceDetailDatasetcat_Id.BeforeLoadDFMValues;
  InvoiceDetailDatasetcs_Id.BeforeLoadDFMValues;
  InvoiceDetailDatasetra_Id.BeforeLoadDFMValues;
  InvoiceDetailDatasetcp_Id.BeforeLoadDFMValues;
  InvoiceDetailDatasetdet_Description.BeforeLoadDFMValues;
  InvoiceDetailDatasetdet_Price.BeforeLoadDFMValues;
  InvoiceDetailDatasetdet_Ext.BeforeLoadDFMValues;
  WebDataSource.BeforeLoadDFMValues;
  try
    Name := 'FViewInvoiceDetailList';
    Width := 695;
    Height := 500;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbPaginationInfo.SetParentComponent(Self);
    lbPaginationInfo.Name := 'lbPaginationInfo';
    lbPaginationInfo.Left := 8;
    lbPaginationInfo.Top := 376;
    lbPaginationInfo.Width := 141;
    lbPaginationInfo.Height := 13;
    lbPaginationInfo.Caption := 'Showing 1 to 10 of 57 entries';
    lbPaginationInfo.HeightPercent := 100.000000000000000000;
    lbPaginationInfo.WidthPercent := 100.000000000000000000;
    btNew.SetParentComponent(Self);
    btNew.Name := 'btNew';
    btNew.Left := 8;
    btNew.Top := 8;
    btNew.Width := 50;
    btNew.Height := 25;
    btNew.Caption := 'New';
    btNew.HeightPercent := 100.000000000000000000;
    btNew.WidthPercent := 100.000000000000000000;
    SetEvent(btNew, Self, 'OnClick', 'btNewClick');
    WebDBTableControl1.SetParentComponent(Self);
    WebDBTableControl1.Name := 'WebDBTableControl1';
    WebDBTableControl1.Left := 8;
    WebDBTableControl1.Top := 94;
    WebDBTableControl1.Width := 521;
    WebDBTableControl1.Height := 265;
    WebDBTableControl1.ElementClassName := 'table-responsive';
    WebDBTableControl1.HeightPercent := 100.000000000000000000;
    WebDBTableControl1.WidthPercent := 100.000000000000000000;
    WebDBTableControl1.BorderColor := clSilver;
    WebDBTableControl1.ColHeader := False;
    WebDBTableControl1.ElementTableClassName := 'table table-striped table-bordered table-hover dataTable';
    SetEvent(WebDBTableControl1, Self, 'OnGetCellChildren', 'WebDBTableControl1GetCellChildren');
    WebDBTableControl1.Columns.Clear;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'det_Id';
      Title := 'det_Id';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'det_Qty';
      Title := 'det_Qty';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cat_Id';
      Title := 'cat_Id';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cs_Id';
      Title := 'cs_Id';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'ra_Id';
      Title := 'ra_Id';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cp_Id';
      Title := 'cp_Id';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'det_Description';
      Title := 'det_Description';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'det_Price';
      Title := 'det_Price';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'det_Ext';
      Title := 'det_Ext';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      Title := 'Actions';
    end;
    WebDBTableControl1.DataSource := WebDataSource;
    cbPageSize.SetParentComponent(Self);
    cbPageSize.Name := 'cbPageSize';
    cbPageSize.Left := 8;
    cbPageSize.Top := 67;
    cbPageSize.Width := 81;
    cbPageSize.Height := 21;
    cbPageSize.HeightPercent := 100.000000000000000000;
    cbPageSize.TabOrder := 2;
    cbPageSize.Text := '10';
    cbPageSize.WidthPercent := 100.000000000000000000;
    SetEvent(cbPageSize, Self, 'OnChange', 'cbPageSizeChange');
    cbPageSize.ItemIndex := 0;
    cbPageSize.Items.BeginUpdate;
    try
      cbPageSize.Items.Clear;
      cbPageSize.Items.Add('10');
      cbPageSize.Items.Add('25');
      cbPageSize.Items.Add('50');
      cbPageSize.Items.Add('100');
    finally
      cbPageSize.Items.EndUpdate;
    end;
    edSearch.SetParentComponent(Self);
    edSearch.Name := 'edSearch';
    edSearch.Left := 408;
    edSearch.Top := 67;
    edSearch.Width := 121;
    edSearch.Height := 21;
    edSearch.HeightPercent := 100.000000000000000000;
    edSearch.TabOrder := 3;
    edSearch.WidthPercent := 100.000000000000000000;
    SetEvent(edSearch, Self, 'OnChange', 'edSearchChange');
    lcPaginator.SetParentComponent(Self);
    lcPaginator.Name := 'lcPaginator';
    lcPaginator.Left := 240;
    lcPaginator.Top := 365;
    lcPaginator.Width := 289;
    lcPaginator.Height := 25;
    lcPaginator.HeightPercent := 100.000000000000000000;
    lcPaginator.WidthPercent := 100.000000000000000000;
    lcPaginator.DefaultItemClassName := 'page-item';
    lcPaginator.DefaultItemLinkClassName := 'page-link';
    lcPaginator.ElementListClassName := 'pagination';
    lcPaginator.Style := lsPagination;
    InvoiceDetailDataset.SetParentComponent(Self);
    InvoiceDetailDataset.Name := 'InvoiceDetailDataset';
    InvoiceDetailDataset.EntitySetName := 'InvoiceDetail';
    InvoiceDetailDataset.Connection := DMConnection.ApiConnection;
    InvoiceDetailDataset.Left := 72;
    InvoiceDetailDataset.Top := 144;
    InvoiceDetailDatasetdet_Id.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetdet_Id.Name := 'InvoiceDetailDatasetdet_Id';
    InvoiceDetailDatasetdet_Id.FieldName := 'det_Id';
    InvoiceDetailDatasetdet_Id.Required := True;
    InvoiceDetailDatasetdet_Qty.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetdet_Qty.Name := 'InvoiceDetailDatasetdet_Qty';
    InvoiceDetailDatasetdet_Qty.FieldName := 'det_Qty';
    InvoiceDetailDatasetcat_Id.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetcat_Id.Name := 'InvoiceDetailDatasetcat_Id';
    InvoiceDetailDatasetcat_Id.FieldName := 'cat_Id';
    InvoiceDetailDatasetcs_Id.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetcs_Id.Name := 'InvoiceDetailDatasetcs_Id';
    InvoiceDetailDatasetcs_Id.FieldName := 'cs_Id';
    InvoiceDetailDatasetra_Id.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetra_Id.Name := 'InvoiceDetailDatasetra_Id';
    InvoiceDetailDatasetra_Id.FieldName := 'ra_Id';
    InvoiceDetailDatasetcp_Id.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetcp_Id.Name := 'InvoiceDetailDatasetcp_Id';
    InvoiceDetailDatasetcp_Id.FieldName := 'cp_Id';
    InvoiceDetailDatasetdet_Description.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetdet_Description.Name := 'InvoiceDetailDatasetdet_Description';
    InvoiceDetailDatasetdet_Description.FieldName := 'det_Description';
    InvoiceDetailDatasetdet_Description.Size := 150;
    InvoiceDetailDatasetdet_Price.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetdet_Price.Name := 'InvoiceDetailDatasetdet_Price';
    InvoiceDetailDatasetdet_Price.FieldName := 'det_Price';
    InvoiceDetailDatasetdet_Ext.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetdet_Ext.Name := 'InvoiceDetailDatasetdet_Ext';
    InvoiceDetailDatasetdet_Ext.FieldName := 'det_Ext';
    WebDataSource.SetParentComponent(Self);
    WebDataSource.Name := 'WebDataSource';
    WebDataSource.DataSet := InvoiceDetailDataset;
    WebDataSource.Left := 72;
    WebDataSource.Top := 200;
  finally
    lbPaginationInfo.AfterLoadDFMValues;
    btNew.AfterLoadDFMValues;
    WebDBTableControl1.AfterLoadDFMValues;
    cbPageSize.AfterLoadDFMValues;
    edSearch.AfterLoadDFMValues;
    lcPaginator.AfterLoadDFMValues;
    InvoiceDetailDataset.AfterLoadDFMValues;
    InvoiceDetailDatasetdet_Id.AfterLoadDFMValues;
    InvoiceDetailDatasetdet_Qty.AfterLoadDFMValues;
    InvoiceDetailDatasetcat_Id.AfterLoadDFMValues;
    InvoiceDetailDatasetcs_Id.AfterLoadDFMValues;
    InvoiceDetailDatasetra_Id.AfterLoadDFMValues;
    InvoiceDetailDatasetcp_Id.AfterLoadDFMValues;
    InvoiceDetailDatasetdet_Description.AfterLoadDFMValues;
    InvoiceDetailDatasetdet_Price.AfterLoadDFMValues;
    InvoiceDetailDatasetdet_Ext.AfterLoadDFMValues;
    WebDataSource.AfterLoadDFMValues;
  end;
end;

end.
