unit View.Payments.List;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, Math,

  WEBLib.StdCtrls, Web, JS, WEBLib.Lists,

  App.Types, WEBLib.Controls, WEBLib.Grids, Data.DB,
  XData.Web.Dataset, WEBLib.DBCtrls, WEBLib.DB, WEBLib.ExtCtrls,

  XData.Web.JsonDataset,

  Grid.Plugins,
  Crud.Utils;

type
  TFViewPaymentsList = class(TForm)
    btNew: TButton;
    PaymentsDataset: TXDataWebDataSet;
    PaymentsDatasetpay_Id: TIntegerField;
    PaymentsDatasetemp_Id: TIntegerField;
    PaymentsDatasetcli_Id: TIntegerField;
    PaymentsDatasetinv_Id: TIntegerField;
    PaymentsDatasetpay_Date: TDateTimeField;
    PaymentsDatasetpay_Amount: TFloatField;
    PaymentsDatasetpay_Cash: TFloatField;
    PaymentsDatasetpay_Chq: TFloatField;
    PaymentsDatasetpay_Dt: TFloatField;
    PaymentsDatasetpay_Ct: TFloatField;
    PaymentsDatasetpay_Online: TFloatField;
    PaymentsDatasetpay_Tranfer: TFloatField;
    PaymentsDatasetpay_CreatedBy: TIntegerField;
    PaymentsDatasetpay_CreatedDate: TDateTimeField;
    PaymentsDatasetpay_LastModifiedBy: TIntegerField;
    PaymentsDatasetpay_LastModifiedDate: TDateTimeField;
    WebDBTableControl1: TDBTableControl;
    WebDataSource: TDataSource;
    cbPageSize: TComboBox;
    edSearch: TEdit;
    lbPaginationInfo: TLabel;
    lcPaginator: TListControl;
    procedure WebFormCreate(Sender: TObject);
    procedure btNewClick(Sender: TObject);
    procedure WebDBTableControl1GetCellChildren(Sender: TObject; ACol,
      ARow: Integer; AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure cbPageSizeChange(Sender: TObject);
    procedure edSearchChange(Sender: TObject);
  private
    FEditProc: TEditProc;
    FGridPlugin: TGridPlugin;
  public
    class function CreateForm(AElementID: string; AShowEditProc: TEditProc): TForm;
  protected procedure LoadDFMValues; override; end;

var
  FViewPaymentsList: TFViewPaymentsList;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewPaymentsList }

procedure TFViewPaymentsList.cbPageSizeChange(Sender: TObject);
begin
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text), True);
end;

class function TFViewPaymentsList.CreateForm(AElementID: string;
  AShowEditProc: TEditProc): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewPaymentsList(AForm).FEditProc := AShowEditProc;
  end;

begin
  Application.CreateForm(TFViewPaymentsList, AElementID, Result, @AfterCreate);
end;

procedure TFViewPaymentsList.edSearchChange(Sender: TObject);
begin
  FGridPlugin.SetFilterText(edSearch.Text, True);
end;

procedure TFViewPaymentsList.btNewClick(Sender: TObject);
begin
  FEditProc( nil );
end;

procedure TFViewPaymentsList.WebDBTableControl1GetCellChildren(Sender: TObject;
  ACol, ARow: Integer; AField: TField; AValue: string;
  AElement: TJSHTMLElementRecord);
var
  RecordId: JSValue;

  procedure EditButtonClick(Sender: TObject);
  begin
    FEditProc(RecordId);
  end;

var
  Button: TButton;
begin
  if ARow = 0 then
    Exit;

  if WebDBTableControl1.Columns[ACol].Title = 'Actions' then
  begin
    RecordId := TCrudUtils.GetIdFromObject(
      PaymentsDataset.Connection.Model.DefaultSchema.FindEntityType(PaymentsDataset.EntitySetName),
      PaymentsDataset.CurrentData
    );
    Button := TButton.Create(nil);
    Button.Caption := 'Edit';
    Button.OnClick := @EditButtonClick;
    Button.ElementClassName := 'btn btn-outline btn-default btn-xs';
    Button.ParentElement := AElement.element;
    Button.WidthStyle := TSizeStyle.ssAuto;
    Button.HeightStyle := TSizeStyle.ssAuto;
  end;
end;

procedure TFViewPaymentsList.WebFormCreate(Sender: TObject);
begin
  FGridPlugin := TGridPlugin.Create(
    WebDBTableControl1,
    PaymentsDataset,
    lcPaginator,
    lbPaginationInfo
  );
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text));
  FGridPlugin.SetFilterText(edSearch.Text);
  FGridPlugin.Load;
end;

procedure TFViewPaymentsList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbPaginationInfo := TLabel.Create('view.payments.list.pagination.info');
  btNew := TButton.Create('view.payments.list.newbutton');
  WebDBTableControl1 := TDBTableControl.Create('view.payments.list.table');
  cbPageSize := TComboBox.Create('view.payments.list.cbPageSize');
  edSearch := TEdit.Create('view.payments.list.search');
  lcPaginator := TListControl.Create('view.payments.list.pagination');
  PaymentsDataset := TXDataWebDataSet.Create(Self);
  PaymentsDatasetpay_Id := TIntegerField.Create(Self);
  PaymentsDatasetemp_Id := TIntegerField.Create(Self);
  PaymentsDatasetcli_Id := TIntegerField.Create(Self);
  PaymentsDatasetinv_Id := TIntegerField.Create(Self);
  PaymentsDatasetpay_Date := TDateTimeField.Create(Self);
  PaymentsDatasetpay_Amount := TFloatField.Create(Self);
  PaymentsDatasetpay_Cash := TFloatField.Create(Self);
  PaymentsDatasetpay_Chq := TFloatField.Create(Self);
  PaymentsDatasetpay_Dt := TFloatField.Create(Self);
  PaymentsDatasetpay_Ct := TFloatField.Create(Self);
  PaymentsDatasetpay_Online := TFloatField.Create(Self);
  PaymentsDatasetpay_Tranfer := TFloatField.Create(Self);
  PaymentsDatasetpay_CreatedBy := TIntegerField.Create(Self);
  PaymentsDatasetpay_CreatedDate := TDateTimeField.Create(Self);
  PaymentsDatasetpay_LastModifiedBy := TIntegerField.Create(Self);
  PaymentsDatasetpay_LastModifiedDate := TDateTimeField.Create(Self);
  WebDataSource := TDataSource.Create(Self);

  lbPaginationInfo.BeforeLoadDFMValues;
  btNew.BeforeLoadDFMValues;
  WebDBTableControl1.BeforeLoadDFMValues;
  cbPageSize.BeforeLoadDFMValues;
  edSearch.BeforeLoadDFMValues;
  lcPaginator.BeforeLoadDFMValues;
  PaymentsDataset.BeforeLoadDFMValues;
  PaymentsDatasetpay_Id.BeforeLoadDFMValues;
  PaymentsDatasetemp_Id.BeforeLoadDFMValues;
  PaymentsDatasetcli_Id.BeforeLoadDFMValues;
  PaymentsDatasetinv_Id.BeforeLoadDFMValues;
  PaymentsDatasetpay_Date.BeforeLoadDFMValues;
  PaymentsDatasetpay_Amount.BeforeLoadDFMValues;
  PaymentsDatasetpay_Cash.BeforeLoadDFMValues;
  PaymentsDatasetpay_Chq.BeforeLoadDFMValues;
  PaymentsDatasetpay_Dt.BeforeLoadDFMValues;
  PaymentsDatasetpay_Ct.BeforeLoadDFMValues;
  PaymentsDatasetpay_Online.BeforeLoadDFMValues;
  PaymentsDatasetpay_Tranfer.BeforeLoadDFMValues;
  PaymentsDatasetpay_CreatedBy.BeforeLoadDFMValues;
  PaymentsDatasetpay_CreatedDate.BeforeLoadDFMValues;
  PaymentsDatasetpay_LastModifiedBy.BeforeLoadDFMValues;
  PaymentsDatasetpay_LastModifiedDate.BeforeLoadDFMValues;
  WebDataSource.BeforeLoadDFMValues;
  try
    Name := 'FViewPaymentsList';
    Width := 695;
    Height := 500;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbPaginationInfo.SetParentComponent(Self);
    lbPaginationInfo.Name := 'lbPaginationInfo';
    lbPaginationInfo.Left := 8;
    lbPaginationInfo.Top := 376;
    lbPaginationInfo.Width := 141;
    lbPaginationInfo.Height := 13;
    lbPaginationInfo.Caption := 'Showing 1 to 10 of 57 entries';
    lbPaginationInfo.HeightPercent := 100.000000000000000000;
    lbPaginationInfo.WidthPercent := 100.000000000000000000;
    btNew.SetParentComponent(Self);
    btNew.Name := 'btNew';
    btNew.Left := 8;
    btNew.Top := 8;
    btNew.Width := 50;
    btNew.Height := 25;
    btNew.Caption := 'New';
    btNew.HeightPercent := 100.000000000000000000;
    btNew.WidthPercent := 100.000000000000000000;
    SetEvent(btNew, Self, 'OnClick', 'btNewClick');
    WebDBTableControl1.SetParentComponent(Self);
    WebDBTableControl1.Name := 'WebDBTableControl1';
    WebDBTableControl1.Left := 8;
    WebDBTableControl1.Top := 94;
    WebDBTableControl1.Width := 521;
    WebDBTableControl1.Height := 265;
    WebDBTableControl1.ElementClassName := 'table-responsive';
    WebDBTableControl1.HeightPercent := 100.000000000000000000;
    WebDBTableControl1.WidthPercent := 100.000000000000000000;
    WebDBTableControl1.BorderColor := clSilver;
    WebDBTableControl1.ColHeader := False;
    WebDBTableControl1.ElementTableClassName := 'table table-striped table-bordered table-hover dataTable';
    SetEvent(WebDBTableControl1, Self, 'OnGetCellChildren', 'WebDBTableControl1GetCellChildren');
    WebDBTableControl1.Columns.Clear;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'pay_Id';
      Title := 'pay_Id';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'emp_Id';
      Title := 'emp_Id';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_Id';
      Title := 'cli_Id';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'inv_Id';
      Title := 'inv_Id';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'pay_Date';
      Title := 'pay_Date';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'pay_Amount';
      Title := 'pay_Amount';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'pay_Cash';
      Title := 'pay_Cash';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'pay_Chq';
      Title := 'pay_Chq';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'pay_Dt';
      Title := 'pay_Dt';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'pay_Ct';
      Title := 'pay_Ct';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'pay_Online';
      Title := 'pay_Online';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'pay_Tranfer';
      Title := 'pay_Tranfer';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'pay_CreatedBy';
      Title := 'pay_CreatedBy';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'pay_CreatedDate';
      Title := 'pay_CreatedDate';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'pay_LastModifiedBy';
      Title := 'pay_LastModifiedBy';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'pay_LastModifiedDate';
      Title := 'pay_LastModifiedDate';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      Title := 'Actions';
    end;
    WebDBTableControl1.DataSource := WebDataSource;
    cbPageSize.SetParentComponent(Self);
    cbPageSize.Name := 'cbPageSize';
    cbPageSize.Left := 8;
    cbPageSize.Top := 67;
    cbPageSize.Width := 81;
    cbPageSize.Height := 21;
    cbPageSize.HeightPercent := 100.000000000000000000;
    cbPageSize.TabOrder := 2;
    cbPageSize.Text := '10';
    cbPageSize.WidthPercent := 100.000000000000000000;
    SetEvent(cbPageSize, Self, 'OnChange', 'cbPageSizeChange');
    cbPageSize.ItemIndex := 0;
    cbPageSize.Items.BeginUpdate;
    try
      cbPageSize.Items.Clear;
      cbPageSize.Items.Add('10');
      cbPageSize.Items.Add('25');
      cbPageSize.Items.Add('50');
      cbPageSize.Items.Add('100');
    finally
      cbPageSize.Items.EndUpdate;
    end;
    edSearch.SetParentComponent(Self);
    edSearch.Name := 'edSearch';
    edSearch.Left := 408;
    edSearch.Top := 67;
    edSearch.Width := 121;
    edSearch.Height := 21;
    edSearch.HeightPercent := 100.000000000000000000;
    edSearch.TabOrder := 3;
    edSearch.WidthPercent := 100.000000000000000000;
    SetEvent(edSearch, Self, 'OnChange', 'edSearchChange');
    lcPaginator.SetParentComponent(Self);
    lcPaginator.Name := 'lcPaginator';
    lcPaginator.Left := 240;
    lcPaginator.Top := 365;
    lcPaginator.Width := 289;
    lcPaginator.Height := 25;
    lcPaginator.HeightPercent := 100.000000000000000000;
    lcPaginator.WidthPercent := 100.000000000000000000;
    lcPaginator.DefaultItemClassName := 'page-item';
    lcPaginator.DefaultItemLinkClassName := 'page-link';
    lcPaginator.ElementListClassName := 'pagination';
    lcPaginator.Style := lsPagination;
    PaymentsDataset.SetParentComponent(Self);
    PaymentsDataset.Name := 'PaymentsDataset';
    PaymentsDataset.EntitySetName := 'Payments';
    PaymentsDataset.Connection := DMConnection.ApiConnection;
    PaymentsDataset.Left := 72;
    PaymentsDataset.Top := 144;
    PaymentsDatasetpay_Id.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Id.Name := 'PaymentsDatasetpay_Id';
    PaymentsDatasetpay_Id.FieldName := 'pay_Id';
    PaymentsDatasetpay_Id.Required := True;
    PaymentsDatasetemp_Id.SetParentComponent(PaymentsDataset);
    PaymentsDatasetemp_Id.Name := 'PaymentsDatasetemp_Id';
    PaymentsDatasetemp_Id.FieldName := 'emp_Id';
    PaymentsDatasetcli_Id.SetParentComponent(PaymentsDataset);
    PaymentsDatasetcli_Id.Name := 'PaymentsDatasetcli_Id';
    PaymentsDatasetcli_Id.FieldName := 'cli_Id';
    PaymentsDatasetinv_Id.SetParentComponent(PaymentsDataset);
    PaymentsDatasetinv_Id.Name := 'PaymentsDatasetinv_Id';
    PaymentsDatasetinv_Id.FieldName := 'inv_Id';
    PaymentsDatasetpay_Date.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Date.Name := 'PaymentsDatasetpay_Date';
    PaymentsDatasetpay_Date.FieldName := 'pay_Date';
    PaymentsDatasetpay_Amount.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Amount.Name := 'PaymentsDatasetpay_Amount';
    PaymentsDatasetpay_Amount.FieldName := 'pay_Amount';
    PaymentsDatasetpay_Cash.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Cash.Name := 'PaymentsDatasetpay_Cash';
    PaymentsDatasetpay_Cash.FieldName := 'pay_Cash';
    PaymentsDatasetpay_Chq.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Chq.Name := 'PaymentsDatasetpay_Chq';
    PaymentsDatasetpay_Chq.FieldName := 'pay_Chq';
    PaymentsDatasetpay_Dt.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Dt.Name := 'PaymentsDatasetpay_Dt';
    PaymentsDatasetpay_Dt.FieldName := 'pay_Dt';
    PaymentsDatasetpay_Ct.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Ct.Name := 'PaymentsDatasetpay_Ct';
    PaymentsDatasetpay_Ct.FieldName := 'pay_Ct';
    PaymentsDatasetpay_Online.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Online.Name := 'PaymentsDatasetpay_Online';
    PaymentsDatasetpay_Online.FieldName := 'pay_Online';
    PaymentsDatasetpay_Tranfer.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Tranfer.Name := 'PaymentsDatasetpay_Tranfer';
    PaymentsDatasetpay_Tranfer.FieldName := 'pay_Tranfer';
    PaymentsDatasetpay_CreatedBy.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_CreatedBy.Name := 'PaymentsDatasetpay_CreatedBy';
    PaymentsDatasetpay_CreatedBy.FieldName := 'pay_CreatedBy';
    PaymentsDatasetpay_CreatedDate.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_CreatedDate.Name := 'PaymentsDatasetpay_CreatedDate';
    PaymentsDatasetpay_CreatedDate.FieldName := 'pay_CreatedDate';
    PaymentsDatasetpay_LastModifiedBy.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_LastModifiedBy.Name := 'PaymentsDatasetpay_LastModifiedBy';
    PaymentsDatasetpay_LastModifiedBy.FieldName := 'pay_LastModifiedBy';
    PaymentsDatasetpay_LastModifiedDate.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_LastModifiedDate.Name := 'PaymentsDatasetpay_LastModifiedDate';
    PaymentsDatasetpay_LastModifiedDate.FieldName := 'pay_LastModifiedDate';
    WebDataSource.SetParentComponent(Self);
    WebDataSource.Name := 'WebDataSource';
    WebDataSource.DataSet := PaymentsDataset;
    WebDataSource.Left := 72;
    WebDataSource.Top := 200;
  finally
    lbPaginationInfo.AfterLoadDFMValues;
    btNew.AfterLoadDFMValues;
    WebDBTableControl1.AfterLoadDFMValues;
    cbPageSize.AfterLoadDFMValues;
    edSearch.AfterLoadDFMValues;
    lcPaginator.AfterLoadDFMValues;
    PaymentsDataset.AfterLoadDFMValues;
    PaymentsDatasetpay_Id.AfterLoadDFMValues;
    PaymentsDatasetemp_Id.AfterLoadDFMValues;
    PaymentsDatasetcli_Id.AfterLoadDFMValues;
    PaymentsDatasetinv_Id.AfterLoadDFMValues;
    PaymentsDatasetpay_Date.AfterLoadDFMValues;
    PaymentsDatasetpay_Amount.AfterLoadDFMValues;
    PaymentsDatasetpay_Cash.AfterLoadDFMValues;
    PaymentsDatasetpay_Chq.AfterLoadDFMValues;
    PaymentsDatasetpay_Dt.AfterLoadDFMValues;
    PaymentsDatasetpay_Ct.AfterLoadDFMValues;
    PaymentsDatasetpay_Online.AfterLoadDFMValues;
    PaymentsDatasetpay_Tranfer.AfterLoadDFMValues;
    PaymentsDatasetpay_CreatedBy.AfterLoadDFMValues;
    PaymentsDatasetpay_CreatedDate.AfterLoadDFMValues;
    PaymentsDatasetpay_LastModifiedBy.AfterLoadDFMValues;
    PaymentsDatasetpay_LastModifiedDate.AfterLoadDFMValues;
    WebDataSource.AfterLoadDFMValues;
  end;
end;

end.
