unit View.CompanyInfo.List;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, Math,

  WEBLib.StdCtrls, Web, JS, WEBLib.Lists,

  App.Types, WEBLib.Controls, WEBLib.Grids, Data.DB,
  XData.Web.Dataset, WEBLib.DBCtrls, WEBLib.DB, WEBLib.ExtCtrls,

  XData.Web.JsonDataset,

  Grid.Plugins,
  Crud.Utils;

type
  TFViewCompanyInfoList = class(TForm)
    btNew: TButton;
    CompanyInfoDataset: TXDataWebDataSet;
    CompanyInfoDatasetcom_Id: TIntegerField;
    CompanyInfoDatasetcom_Name: TStringField;
    CompanyInfoDatasetcom_Token: TStringField;
    CompanyInfoDatasetcom_Adresse: TStringField;
    CompanyInfoDatasetcom_City: TStringField;
    CompanyInfoDatasetcom_Province: TStringField;
    CompanyInfoDatasetcom_PostalCode: TStringField;
    CompanyInfoDatasetcom_Telephone: TStringField;
    CompanyInfoDatasetcom_Email: TStringField;
    CompanyInfoDatasetcom_Tx1Name: TStringField;
    CompanyInfoDatasetcom_Tx1Rate: TFloatField;
    CompanyInfoDatasetcom_Tx1Number: TIntegerField;
    CompanyInfoDatasetcom_Tx1Active: TIntegerField;
    CompanyInfoDatasetcom_Tx2Name: TStringField;
    CompanyInfoDatasetcom_Tx2Rate: TFloatField;
    CompanyInfoDatasetcom_Tx2Number: TStringField;
    CompanyInfoDatasetcom_Tx2Active: TIntegerField;
    CompanyInfoDatasetcom_Tx3Name: TStringField;
    CompanyInfoDatasetcom_Tx3Rate: TFloatField;
    CompanyInfoDatasetcom_Tx3Number: TStringField;
    CompanyInfoDatasetcom_Tx3Active: TIntegerField;
    CompanyInfoDatasetcon_Slogan: TStringField;
    CompanyInfoDatasetcom_LastModifiedBy: TIntegerField;
    CompanyInfoDatasetcom_LastModifiedDate: TDateTimeField;
    WebDBTableControl1: TDBTableControl;
    WebDataSource: TDataSource;
    cbPageSize: TComboBox;
    edSearch: TEdit;
    lbPaginationInfo: TLabel;
    lcPaginator: TListControl;
    procedure WebFormCreate(Sender: TObject);
    procedure btNewClick(Sender: TObject);
    procedure WebDBTableControl1GetCellChildren(Sender: TObject; ACol,
      ARow: Integer; AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure cbPageSizeChange(Sender: TObject);
    procedure edSearchChange(Sender: TObject);
  private
    FEditProc: TEditProc;
    FGridPlugin: TGridPlugin;
  public
    class function CreateForm(AElementID: string; AShowEditProc: TEditProc): TForm;
  protected procedure LoadDFMValues; override; end;

var
  FViewCompanyInfoList: TFViewCompanyInfoList;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewCompanyInfoList }

procedure TFViewCompanyInfoList.cbPageSizeChange(Sender: TObject);
begin
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text), True);
end;

class function TFViewCompanyInfoList.CreateForm(AElementID: string;
  AShowEditProc: TEditProc): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewCompanyInfoList(AForm).FEditProc := AShowEditProc;
  end;

begin
  Application.CreateForm(TFViewCompanyInfoList, AElementID, Result, @AfterCreate);
end;

procedure TFViewCompanyInfoList.edSearchChange(Sender: TObject);
begin
  FGridPlugin.SetFilterText(edSearch.Text, True);
end;

procedure TFViewCompanyInfoList.btNewClick(Sender: TObject);
begin
  FEditProc( nil );
end;

procedure TFViewCompanyInfoList.WebDBTableControl1GetCellChildren(Sender: TObject;
  ACol, ARow: Integer; AField: TField; AValue: string;
  AElement: TJSHTMLElementRecord);
var
  RecordId: JSValue;

  procedure EditButtonClick(Sender: TObject);
  begin
    FEditProc(RecordId);
  end;

var
  Button: TButton;
begin
  if ARow = 0 then
    Exit;

  if WebDBTableControl1.Columns[ACol].Title = 'Actions' then
  begin
    RecordId := TCrudUtils.GetIdFromObject(
      CompanyInfoDataset.Connection.Model.DefaultSchema.FindEntityType(CompanyInfoDataset.EntitySetName),
      CompanyInfoDataset.CurrentData
    );
    Button := TButton.Create(nil);
    Button.Caption := 'Edit';
    Button.OnClick := @EditButtonClick;
    Button.ElementClassName := 'btn btn-outline btn-default btn-xs';
    Button.ParentElement := AElement.element;
    Button.WidthStyle := TSizeStyle.ssAuto;
    Button.HeightStyle := TSizeStyle.ssAuto;
  end;
end;

procedure TFViewCompanyInfoList.WebFormCreate(Sender: TObject);
begin
  FGridPlugin := TGridPlugin.Create(
    WebDBTableControl1,
    CompanyInfoDataset,
    lcPaginator,
    lbPaginationInfo
  );
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text));
  FGridPlugin.SetFilterText(edSearch.Text);
  FGridPlugin.Load;
end;

procedure TFViewCompanyInfoList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbPaginationInfo := TLabel.Create('view.companyinfo.list.pagination.info');
  btNew := TButton.Create('view.companyinfo.list.newbutton');
  WebDBTableControl1 := TDBTableControl.Create('view.companyinfo.list.table');
  cbPageSize := TComboBox.Create('view.companyinfo.list.cbPageSize');
  edSearch := TEdit.Create('view.companyinfo.list.search');
  lcPaginator := TListControl.Create('view.companyinfo.list.pagination');
  CompanyInfoDataset := TXDataWebDataSet.Create(Self);
  CompanyInfoDatasetcom_Id := TIntegerField.Create(Self);
  CompanyInfoDatasetcom_Name := TStringField.Create(Self);
  CompanyInfoDatasetcom_Token := TStringField.Create(Self);
  CompanyInfoDatasetcom_Adresse := TStringField.Create(Self);
  CompanyInfoDatasetcom_City := TStringField.Create(Self);
  CompanyInfoDatasetcom_Province := TStringField.Create(Self);
  CompanyInfoDatasetcom_PostalCode := TStringField.Create(Self);
  CompanyInfoDatasetcom_Telephone := TStringField.Create(Self);
  CompanyInfoDatasetcom_Email := TStringField.Create(Self);
  CompanyInfoDatasetcom_Tx1Name := TStringField.Create(Self);
  CompanyInfoDatasetcom_Tx1Rate := TFloatField.Create(Self);
  CompanyInfoDatasetcom_Tx1Number := TIntegerField.Create(Self);
  CompanyInfoDatasetcom_Tx1Active := TIntegerField.Create(Self);
  CompanyInfoDatasetcom_Tx2Name := TStringField.Create(Self);
  CompanyInfoDatasetcom_Tx2Rate := TFloatField.Create(Self);
  CompanyInfoDatasetcom_Tx2Number := TStringField.Create(Self);
  CompanyInfoDatasetcom_Tx2Active := TIntegerField.Create(Self);
  CompanyInfoDatasetcom_Tx3Name := TStringField.Create(Self);
  CompanyInfoDatasetcom_Tx3Rate := TFloatField.Create(Self);
  CompanyInfoDatasetcom_Tx3Number := TStringField.Create(Self);
  CompanyInfoDatasetcom_Tx3Active := TIntegerField.Create(Self);
  CompanyInfoDatasetcon_Slogan := TStringField.Create(Self);
  CompanyInfoDatasetcom_LastModifiedBy := TIntegerField.Create(Self);
  CompanyInfoDatasetcom_LastModifiedDate := TDateTimeField.Create(Self);
  WebDataSource := TDataSource.Create(Self);

  lbPaginationInfo.BeforeLoadDFMValues;
  btNew.BeforeLoadDFMValues;
  WebDBTableControl1.BeforeLoadDFMValues;
  cbPageSize.BeforeLoadDFMValues;
  edSearch.BeforeLoadDFMValues;
  lcPaginator.BeforeLoadDFMValues;
  CompanyInfoDataset.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Id.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Name.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Token.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Adresse.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_City.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Province.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_PostalCode.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Telephone.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Email.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx1Name.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx1Rate.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx1Number.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx1Active.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx2Name.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx2Rate.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx2Number.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx2Active.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx3Name.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx3Rate.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx3Number.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx3Active.BeforeLoadDFMValues;
  CompanyInfoDatasetcon_Slogan.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_LastModifiedBy.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_LastModifiedDate.BeforeLoadDFMValues;
  WebDataSource.BeforeLoadDFMValues;
  try
    Name := 'FViewCompanyInfoList';
    Width := 695;
    Height := 500;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbPaginationInfo.SetParentComponent(Self);
    lbPaginationInfo.Name := 'lbPaginationInfo';
    lbPaginationInfo.Left := 8;
    lbPaginationInfo.Top := 376;
    lbPaginationInfo.Width := 141;
    lbPaginationInfo.Height := 13;
    lbPaginationInfo.Caption := 'Showing 1 to 10 of 57 entries';
    lbPaginationInfo.HeightPercent := 100.000000000000000000;
    lbPaginationInfo.WidthPercent := 100.000000000000000000;
    btNew.SetParentComponent(Self);
    btNew.Name := 'btNew';
    btNew.Left := 8;
    btNew.Top := 8;
    btNew.Width := 50;
    btNew.Height := 25;
    btNew.Caption := 'New';
    btNew.HeightPercent := 100.000000000000000000;
    btNew.WidthPercent := 100.000000000000000000;
    SetEvent(btNew, Self, 'OnClick', 'btNewClick');
    WebDBTableControl1.SetParentComponent(Self);
    WebDBTableControl1.Name := 'WebDBTableControl1';
    WebDBTableControl1.Left := 8;
    WebDBTableControl1.Top := 94;
    WebDBTableControl1.Width := 521;
    WebDBTableControl1.Height := 265;
    WebDBTableControl1.ElementClassName := 'table-responsive';
    WebDBTableControl1.HeightPercent := 100.000000000000000000;
    WebDBTableControl1.WidthPercent := 100.000000000000000000;
    WebDBTableControl1.BorderColor := clSilver;
    WebDBTableControl1.ColHeader := False;
    WebDBTableControl1.ElementTableClassName := 'table table-striped table-bordered table-hover dataTable';
    SetEvent(WebDBTableControl1, Self, 'OnGetCellChildren', 'WebDBTableControl1GetCellChildren');
    WebDBTableControl1.Columns.Clear;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Id';
      Title := 'com_Id';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Name';
      Title := 'com_Name';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Token';
      Title := 'com_Token';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Adresse';
      Title := 'com_Adresse';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_City';
      Title := 'com_City';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Province';
      Title := 'com_Province';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_PostalCode';
      Title := 'com_PostalCode';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Telephone';
      Title := 'com_Telephone';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Email';
      Title := 'com_Email';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Tx1Name';
      Title := 'com_Tx1Name';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Tx1Rate';
      Title := 'com_Tx1Rate';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Tx1Number';
      Title := 'com_Tx1Number';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Tx1Active';
      Title := 'com_Tx1Active';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Tx2Name';
      Title := 'com_Tx2Name';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Tx2Rate';
      Title := 'com_Tx2Rate';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Tx2Number';
      Title := 'com_Tx2Number';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Tx2Active';
      Title := 'com_Tx2Active';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Tx3Name';
      Title := 'com_Tx3Name';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Tx3Rate';
      Title := 'com_Tx3Rate';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Tx3Number';
      Title := 'com_Tx3Number';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_Tx3Active';
      Title := 'com_Tx3Active';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'con_Slogan';
      Title := 'con_Slogan';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_LastModifiedBy';
      Title := 'com_LastModifiedBy';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'com_LastModifiedDate';
      Title := 'com_LastModifiedDate';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      Title := 'Actions';
    end;
    WebDBTableControl1.DataSource := WebDataSource;
    cbPageSize.SetParentComponent(Self);
    cbPageSize.Name := 'cbPageSize';
    cbPageSize.Left := 8;
    cbPageSize.Top := 67;
    cbPageSize.Width := 81;
    cbPageSize.Height := 21;
    cbPageSize.HeightPercent := 100.000000000000000000;
    cbPageSize.TabOrder := 2;
    cbPageSize.Text := '10';
    cbPageSize.WidthPercent := 100.000000000000000000;
    SetEvent(cbPageSize, Self, 'OnChange', 'cbPageSizeChange');
    cbPageSize.ItemIndex := 0;
    cbPageSize.Items.BeginUpdate;
    try
      cbPageSize.Items.Clear;
      cbPageSize.Items.Add('10');
      cbPageSize.Items.Add('25');
      cbPageSize.Items.Add('50');
      cbPageSize.Items.Add('100');
    finally
      cbPageSize.Items.EndUpdate;
    end;
    edSearch.SetParentComponent(Self);
    edSearch.Name := 'edSearch';
    edSearch.Left := 408;
    edSearch.Top := 67;
    edSearch.Width := 121;
    edSearch.Height := 21;
    edSearch.HeightPercent := 100.000000000000000000;
    edSearch.TabOrder := 3;
    edSearch.WidthPercent := 100.000000000000000000;
    SetEvent(edSearch, Self, 'OnChange', 'edSearchChange');
    lcPaginator.SetParentComponent(Self);
    lcPaginator.Name := 'lcPaginator';
    lcPaginator.Left := 240;
    lcPaginator.Top := 365;
    lcPaginator.Width := 289;
    lcPaginator.Height := 25;
    lcPaginator.HeightPercent := 100.000000000000000000;
    lcPaginator.WidthPercent := 100.000000000000000000;
    lcPaginator.DefaultItemClassName := 'page-item';
    lcPaginator.DefaultItemLinkClassName := 'page-link';
    lcPaginator.ElementListClassName := 'pagination';
    lcPaginator.Style := lsPagination;
    CompanyInfoDataset.SetParentComponent(Self);
    CompanyInfoDataset.Name := 'CompanyInfoDataset';
    CompanyInfoDataset.EntitySetName := 'CompanyInfo';
    CompanyInfoDataset.Connection := DMConnection.ApiConnection;
    CompanyInfoDataset.Left := 72;
    CompanyInfoDataset.Top := 144;
    CompanyInfoDatasetcom_Id.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Id.Name := 'CompanyInfoDatasetcom_Id';
    CompanyInfoDatasetcom_Id.FieldName := 'com_Id';
    CompanyInfoDatasetcom_Id.Required := True;
    CompanyInfoDatasetcom_Name.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Name.Name := 'CompanyInfoDatasetcom_Name';
    CompanyInfoDatasetcom_Name.FieldName := 'com_Name';
    CompanyInfoDatasetcom_Name.Size := 50;
    CompanyInfoDatasetcom_Token.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Token.Name := 'CompanyInfoDatasetcom_Token';
    CompanyInfoDatasetcom_Token.FieldName := 'com_Token';
    CompanyInfoDatasetcom_Token.Size := 50;
    CompanyInfoDatasetcom_Adresse.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Adresse.Name := 'CompanyInfoDatasetcom_Adresse';
    CompanyInfoDatasetcom_Adresse.FieldName := 'com_Adresse';
    CompanyInfoDatasetcom_Adresse.Size := 150;
    CompanyInfoDatasetcom_City.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_City.Name := 'CompanyInfoDatasetcom_City';
    CompanyInfoDatasetcom_City.FieldName := 'com_City';
    CompanyInfoDatasetcom_City.Size := 100;
    CompanyInfoDatasetcom_Province.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Province.Name := 'CompanyInfoDatasetcom_Province';
    CompanyInfoDatasetcom_Province.FieldName := 'com_Province';
    CompanyInfoDatasetcom_Province.Size := 50;
    CompanyInfoDatasetcom_PostalCode.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_PostalCode.Name := 'CompanyInfoDatasetcom_PostalCode';
    CompanyInfoDatasetcom_PostalCode.FieldName := 'com_PostalCode';
    CompanyInfoDatasetcom_PostalCode.Size := 50;
    CompanyInfoDatasetcom_Telephone.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Telephone.Name := 'CompanyInfoDatasetcom_Telephone';
    CompanyInfoDatasetcom_Telephone.FieldName := 'com_Telephone';
    CompanyInfoDatasetcom_Telephone.Size := 50;
    CompanyInfoDatasetcom_Email.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Email.Name := 'CompanyInfoDatasetcom_Email';
    CompanyInfoDatasetcom_Email.FieldName := 'com_Email';
    CompanyInfoDatasetcom_Email.Size := 75;
    CompanyInfoDatasetcom_Tx1Name.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx1Name.Name := 'CompanyInfoDatasetcom_Tx1Name';
    CompanyInfoDatasetcom_Tx1Name.FieldName := 'com_Tx1Name';
    CompanyInfoDatasetcom_Tx1Name.Size := 50;
    CompanyInfoDatasetcom_Tx1Rate.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx1Rate.Name := 'CompanyInfoDatasetcom_Tx1Rate';
    CompanyInfoDatasetcom_Tx1Rate.FieldName := 'com_Tx1Rate';
    CompanyInfoDatasetcom_Tx1Number.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx1Number.Name := 'CompanyInfoDatasetcom_Tx1Number';
    CompanyInfoDatasetcom_Tx1Number.FieldName := 'com_Tx1Number';
    CompanyInfoDatasetcom_Tx1Active.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx1Active.Name := 'CompanyInfoDatasetcom_Tx1Active';
    CompanyInfoDatasetcom_Tx1Active.FieldName := 'com_Tx1Active';
    CompanyInfoDatasetcom_Tx2Name.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx2Name.Name := 'CompanyInfoDatasetcom_Tx2Name';
    CompanyInfoDatasetcom_Tx2Name.FieldName := 'com_Tx2Name';
    CompanyInfoDatasetcom_Tx2Name.Size := 50;
    CompanyInfoDatasetcom_Tx2Rate.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx2Rate.Name := 'CompanyInfoDatasetcom_Tx2Rate';
    CompanyInfoDatasetcom_Tx2Rate.FieldName := 'com_Tx2Rate';
    CompanyInfoDatasetcom_Tx2Number.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx2Number.Name := 'CompanyInfoDatasetcom_Tx2Number';
    CompanyInfoDatasetcom_Tx2Number.FieldName := 'com_Tx2Number';
    CompanyInfoDatasetcom_Tx2Number.Size := 50;
    CompanyInfoDatasetcom_Tx2Active.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx2Active.Name := 'CompanyInfoDatasetcom_Tx2Active';
    CompanyInfoDatasetcom_Tx2Active.FieldName := 'com_Tx2Active';
    CompanyInfoDatasetcom_Tx3Name.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx3Name.Name := 'CompanyInfoDatasetcom_Tx3Name';
    CompanyInfoDatasetcom_Tx3Name.FieldName := 'com_Tx3Name';
    CompanyInfoDatasetcom_Tx3Name.Size := 50;
    CompanyInfoDatasetcom_Tx3Rate.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx3Rate.Name := 'CompanyInfoDatasetcom_Tx3Rate';
    CompanyInfoDatasetcom_Tx3Rate.FieldName := 'com_Tx3Rate';
    CompanyInfoDatasetcom_Tx3Number.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx3Number.Name := 'CompanyInfoDatasetcom_Tx3Number';
    CompanyInfoDatasetcom_Tx3Number.FieldName := 'com_Tx3Number';
    CompanyInfoDatasetcom_Tx3Number.Size := 50;
    CompanyInfoDatasetcom_Tx3Active.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx3Active.Name := 'CompanyInfoDatasetcom_Tx3Active';
    CompanyInfoDatasetcom_Tx3Active.FieldName := 'com_Tx3Active';
    CompanyInfoDatasetcon_Slogan.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcon_Slogan.Name := 'CompanyInfoDatasetcon_Slogan';
    CompanyInfoDatasetcon_Slogan.FieldName := 'con_Slogan';
    CompanyInfoDatasetcon_Slogan.Size := 50;
    CompanyInfoDatasetcom_LastModifiedBy.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_LastModifiedBy.Name := 'CompanyInfoDatasetcom_LastModifiedBy';
    CompanyInfoDatasetcom_LastModifiedBy.FieldName := 'com_LastModifiedBy';
    CompanyInfoDatasetcom_LastModifiedDate.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_LastModifiedDate.Name := 'CompanyInfoDatasetcom_LastModifiedDate';
    CompanyInfoDatasetcom_LastModifiedDate.FieldName := 'com_LastModifiedDate';
    WebDataSource.SetParentComponent(Self);
    WebDataSource.Name := 'WebDataSource';
    WebDataSource.DataSet := CompanyInfoDataset;
    WebDataSource.Left := 72;
    WebDataSource.Top := 200;
  finally
    lbPaginationInfo.AfterLoadDFMValues;
    btNew.AfterLoadDFMValues;
    WebDBTableControl1.AfterLoadDFMValues;
    cbPageSize.AfterLoadDFMValues;
    edSearch.AfterLoadDFMValues;
    lcPaginator.AfterLoadDFMValues;
    CompanyInfoDataset.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Id.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Name.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Token.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Adresse.AfterLoadDFMValues;
    CompanyInfoDatasetcom_City.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Province.AfterLoadDFMValues;
    CompanyInfoDatasetcom_PostalCode.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Telephone.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Email.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx1Name.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx1Rate.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx1Number.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx1Active.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx2Name.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx2Rate.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx2Number.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx2Active.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx3Name.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx3Rate.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx3Number.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx3Active.AfterLoadDFMValues;
    CompanyInfoDatasetcon_Slogan.AfterLoadDFMValues;
    CompanyInfoDatasetcom_LastModifiedBy.AfterLoadDFMValues;
    CompanyInfoDatasetcom_LastModifiedDate.AfterLoadDFMValues;
    WebDataSource.AfterLoadDFMValues;
  end;
end;

end.
