unit View.CompanyInfo.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewCompanyInfoEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbcom_Id: TLabel;
    edcom_Id: TDBEdit;
    lbcom_Name: TLabel;
    edcom_Name: TDBEdit;
    lbcom_Token: TLabel;
    edcom_Token: TDBEdit;
    lbcom_Adresse: TLabel;
    edcom_Adresse: TDBEdit;
    lbcom_City: TLabel;
    edcom_City: TDBEdit;
    lbcom_Province: TLabel;
    edcom_Province: TDBEdit;
    lbcom_PostalCode: TLabel;
    edcom_PostalCode: TDBEdit;
    lbcom_Telephone: TLabel;
    edcom_Telephone: TDBEdit;
    lbcom_Email: TLabel;
    edcom_Email: TDBEdit;
    lbcom_Tx1Name: TLabel;
    edcom_Tx1Name: TDBEdit;
    lbcom_Tx1Rate: TLabel;
    edcom_Tx1Rate: TDBEdit;
    lbcom_Tx1Number: TLabel;
    edcom_Tx1Number: TDBEdit;
    lbcom_Tx1Active: TLabel;
    edcom_Tx1Active: TDBEdit;
    lbcom_Tx2Name: TLabel;
    edcom_Tx2Name: TDBEdit;
    lbcom_Tx2Rate: TLabel;
    edcom_Tx2Rate: TDBEdit;
    lbcom_Tx2Number: TLabel;
    edcom_Tx2Number: TDBEdit;
    lbcom_Tx2Active: TLabel;
    edcom_Tx2Active: TDBEdit;
    lbcom_Tx3Name: TLabel;
    edcom_Tx3Name: TDBEdit;
    lbcom_Tx3Rate: TLabel;
    edcom_Tx3Rate: TDBEdit;
    lbcom_Tx3Number: TLabel;
    edcom_Tx3Number: TDBEdit;
    lbcom_Tx3Active: TLabel;
    edcom_Tx3Active: TDBEdit;
    lbcon_Slogan: TLabel;
    edcon_Slogan: TDBEdit;
    lbcom_LastModifiedBy: TLabel;
    edcom_LastModifiedBy: TDBEdit;
    lbcom_LastModifiedDate: TLabel;
    edcom_LastModifiedDate: TDBEdit;
    XDataClientCompanyInfoGet: TXDataWebClient;
    XDataClientCompanyInfoDelete: TXDataWebClient;
    CompanyInfoDataset: TXDataWebDataSet;
    CompanyInfoDatasetcom_Id: TIntegerField;
    CompanyInfoDatasetcom_Name: TStringField;
    CompanyInfoDatasetcom_Token: TStringField;
    CompanyInfoDatasetcom_Adresse: TStringField;
    CompanyInfoDatasetcom_City: TStringField;
    CompanyInfoDatasetcom_Province: TStringField;
    CompanyInfoDatasetcom_PostalCode: TStringField;
    CompanyInfoDatasetcom_Telephone: TStringField;
    CompanyInfoDatasetcom_Email: TStringField;
    CompanyInfoDatasetcom_Tx1Name: TStringField;
    CompanyInfoDatasetcom_Tx1Rate: TFloatField;
    CompanyInfoDatasetcom_Tx1Number: TIntegerField;
    CompanyInfoDatasetcom_Tx1Active: TIntegerField;
    CompanyInfoDatasetcom_Tx2Name: TStringField;
    CompanyInfoDatasetcom_Tx2Rate: TFloatField;
    CompanyInfoDatasetcom_Tx2Number: TStringField;
    CompanyInfoDatasetcom_Tx2Active: TIntegerField;
    CompanyInfoDatasetcom_Tx3Name: TStringField;
    CompanyInfoDatasetcom_Tx3Rate: TFloatField;
    CompanyInfoDatasetcom_Tx3Number: TStringField;
    CompanyInfoDatasetcom_Tx3Active: TIntegerField;
    CompanyInfoDatasetcon_Slogan: TStringField;
    CompanyInfoDatasetcom_LastModifiedBy: TIntegerField;
    CompanyInfoDatasetcom_LastModifiedDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientCompanyInfoGetLoad(Response: TXDataClientResponse);
    procedure XDataClientCompanyInfoDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientCompanyInfoDeleteError(Error: TXDataClientError);
    procedure CompanyInfoDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewCompanyInfoEdit: TFViewCompanyInfoEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewCompanyInfoEdit }

procedure TFViewCompanyInfoEdit.btSaveClick(Sender: TObject);
begin
  if CompanyInfoDataset.State in dsEditModes then
  begin
        CompanyInfoDataset.Post;
    CompanyInfoDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewCompanyInfoEdit.btDeleteClick(Sender: TObject);
begin
  if CompanyInfoDataset.State in dsEditModes then
    CompanyInfoDataset.Post;
  XDataClientCompanyInfoDelete.Delete(CompanyInfoDataset.EntitySetName,
    TJSObject(CompanyInfoDataset.CurrentData));
end;


class function TFViewCompanyInfoEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewCompanyInfoEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewCompanyInfoEdit.InsertEntity;
begin
  CompanyInfoDataset.Close;
  CompanyInfoDataset.SetJsonData('{}');
  CompanyInfoDataset.Open;
  CompanyInfoDataset.Insert;
end;

class function TFViewCompanyInfoEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewCompanyInfoEdit(AForm).FInserting := AInserting;
    TFViewCompanyInfoEdit(AForm).FId := AId;
    TFViewCompanyInfoEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewCompanyInfoEdit(AForm).InsertEntity
    else
      TFViewCompanyInfoEdit(AForm).LoadEntity;
    TFViewCompanyInfoEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewCompanyInfoEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewCompanyInfoEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    CompanyInfoDataset.Connection.Model.DefaultSchema.FindEntityType(
      CompanyInfoDataset.EntitySetName
    )
  );
  XDataClientCompanyInfoGet.Get(CompanyInfoDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewCompanyInfoEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewCompanyInfoEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewCompanyInfoEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewCompanyInfoEdit.XDataClientCompanyInfoGetLoad(Response: TXDataClientResponse);
begin
  CompanyInfoDataset.Close;
  CompanyInfoDataset.SetJsonData(Response.Result);
  CompanyInfoDataset.Open;
  
  CompanyInfoDataset.Edit;  
end;

procedure TFViewCompanyInfoEdit.XDataClientCompanyInfoDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewCompanyInfoEdit.XDataClientCompanyInfoDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewCompanyInfoEdit.CompanyInfoDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewCompanyInfoEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewCompanyInfoEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbcom_Id := TLabel.Create('view.companyinfo.form.lbcom_Id');
  lbcom_Name := TLabel.Create('view.companyinfo.form.lbcom_Name');
  lbcom_Token := TLabel.Create('view.companyinfo.form.lbcom_Token');
  lbcom_Adresse := TLabel.Create('view.companyinfo.form.lbcom_Adresse');
  lbcom_City := TLabel.Create('view.companyinfo.form.lbcom_City');
  lbcom_Province := TLabel.Create('view.companyinfo.form.lbcom_Province');
  lbcom_PostalCode := TLabel.Create('view.companyinfo.form.lbcom_PostalCode');
  lbcom_Telephone := TLabel.Create('view.companyinfo.form.lbcom_Telephone');
  lbcom_Email := TLabel.Create('view.companyinfo.form.lbcom_Email');
  lbcom_Tx1Name := TLabel.Create('view.companyinfo.form.lbcom_Tx1Name');
  lbcom_Tx1Rate := TLabel.Create('view.companyinfo.form.lbcom_Tx1Rate');
  lbcom_Tx1Number := TLabel.Create('view.companyinfo.form.lbcom_Tx1Number');
  lbcom_Tx1Active := TLabel.Create('view.companyinfo.form.lbcom_Tx1Active');
  lbcom_Tx2Name := TLabel.Create('view.companyinfo.form.lbcom_Tx2Name');
  lbcom_Tx2Rate := TLabel.Create('view.companyinfo.form.lbcom_Tx2Rate');
  lbcom_Tx2Number := TLabel.Create('view.companyinfo.form.lbcom_Tx2Number');
  lbcom_Tx2Active := TLabel.Create('view.companyinfo.form.lbcom_Tx2Active');
  lbcom_Tx3Name := TLabel.Create('view.companyinfo.form.lbcom_Tx3Name');
  lbcom_Tx3Rate := TLabel.Create('view.companyinfo.form.lbcom_Tx3Rate');
  lbcom_Tx3Number := TLabel.Create('view.companyinfo.form.lbcom_Tx3Number');
  lbcom_Tx3Active := TLabel.Create('view.companyinfo.form.lbcom_Tx3Active');
  lbcon_Slogan := TLabel.Create('view.companyinfo.form.lbcon_Slogan');
  lbcom_LastModifiedBy := TLabel.Create('view.companyinfo.form.lbcom_LastModifiedBy');
  lbcom_LastModifiedDate := TLabel.Create('view.companyinfo.form.lbcom_LastModifiedDate');
  pnlMessage := TPanel.Create('view.companyinfo.form.message');
  lbMessage := TLabel.Create('view.companyinfo.form.message.label');
  btCloseMessage := TButton.Create('view.companyinfo.form.message.button');
  btCancel := TButton.Create('view.companyinfo.form.btBack');
  btSave := TButton.Create('view.companyinfo.form.btSave');
  btDelete := TButton.Create('view.companyinfo.form.btDelete');
  edcom_Id := TDBEdit.Create('view.companyinfo.form.edcom_Id');
  edcom_Name := TDBEdit.Create('view.companyinfo.form.edcom_Name');
  edcom_Token := TDBEdit.Create('view.companyinfo.form.edcom_Token');
  edcom_Adresse := TDBEdit.Create('view.companyinfo.form.edcom_Adresse');
  edcom_City := TDBEdit.Create('view.companyinfo.form.edcom_City');
  edcom_Province := TDBEdit.Create('view.companyinfo.form.edcom_Province');
  edcom_PostalCode := TDBEdit.Create('view.companyinfo.form.edcom_PostalCode');
  edcom_Telephone := TDBEdit.Create('view.companyinfo.form.edcom_Telephone');
  edcom_Email := TDBEdit.Create('view.companyinfo.form.edcom_Email');
  edcom_Tx1Name := TDBEdit.Create('view.companyinfo.form.edcom_Tx1Name');
  edcom_Tx1Rate := TDBEdit.Create('view.companyinfo.form.edcom_Tx1Rate');
  edcom_Tx1Number := TDBEdit.Create('view.companyinfo.form.edcom_Tx1Number');
  edcom_Tx1Active := TDBEdit.Create('view.companyinfo.form.edcom_Tx1Active');
  edcom_Tx2Name := TDBEdit.Create('view.companyinfo.form.edcom_Tx2Name');
  edcom_Tx2Rate := TDBEdit.Create('view.companyinfo.form.edcom_Tx2Rate');
  edcom_Tx2Number := TDBEdit.Create('view.companyinfo.form.edcom_Tx2Number');
  edcom_Tx2Active := TDBEdit.Create('view.companyinfo.form.edcom_Tx2Active');
  edcom_Tx3Name := TDBEdit.Create('view.companyinfo.form.edcom_Tx3Name');
  edcom_Tx3Rate := TDBEdit.Create('view.companyinfo.form.edcom_Tx3Rate');
  edcom_Tx3Number := TDBEdit.Create('view.companyinfo.form.edcom_Tx3Number');
  edcom_Tx3Active := TDBEdit.Create('view.companyinfo.form.edcom_Tx3Active');
  edcon_Slogan := TDBEdit.Create('view.companyinfo.form.edcon_Slogan');
  edcom_LastModifiedBy := TDBEdit.Create('view.companyinfo.form.edcom_LastModifiedBy');
  edcom_LastModifiedDate := TDBEdit.Create('view.companyinfo.form.edcom_LastModifiedDate');
  DataSource := TDataSource.Create(Self);
  CompanyInfoDataset := TXDataWebDataSet.Create(Self);
  CompanyInfoDatasetcom_Id := TIntegerField.Create(Self);
  CompanyInfoDatasetcom_Name := TStringField.Create(Self);
  CompanyInfoDatasetcom_Token := TStringField.Create(Self);
  CompanyInfoDatasetcom_Adresse := TStringField.Create(Self);
  CompanyInfoDatasetcom_City := TStringField.Create(Self);
  CompanyInfoDatasetcom_Province := TStringField.Create(Self);
  CompanyInfoDatasetcom_PostalCode := TStringField.Create(Self);
  CompanyInfoDatasetcom_Telephone := TStringField.Create(Self);
  CompanyInfoDatasetcom_Email := TStringField.Create(Self);
  CompanyInfoDatasetcom_Tx1Name := TStringField.Create(Self);
  CompanyInfoDatasetcom_Tx1Rate := TFloatField.Create(Self);
  CompanyInfoDatasetcom_Tx1Number := TIntegerField.Create(Self);
  CompanyInfoDatasetcom_Tx1Active := TIntegerField.Create(Self);
  CompanyInfoDatasetcom_Tx2Name := TStringField.Create(Self);
  CompanyInfoDatasetcom_Tx2Rate := TFloatField.Create(Self);
  CompanyInfoDatasetcom_Tx2Number := TStringField.Create(Self);
  CompanyInfoDatasetcom_Tx2Active := TIntegerField.Create(Self);
  CompanyInfoDatasetcom_Tx3Name := TStringField.Create(Self);
  CompanyInfoDatasetcom_Tx3Rate := TFloatField.Create(Self);
  CompanyInfoDatasetcom_Tx3Number := TStringField.Create(Self);
  CompanyInfoDatasetcom_Tx3Active := TIntegerField.Create(Self);
  CompanyInfoDatasetcon_Slogan := TStringField.Create(Self);
  CompanyInfoDatasetcom_LastModifiedBy := TIntegerField.Create(Self);
  CompanyInfoDatasetcom_LastModifiedDate := TDateTimeField.Create(Self);
  XDataClientCompanyInfoGet := TXDataWebClient.Create(Self);
  XDataClientCompanyInfoDelete := TXDataWebClient.Create(Self);

  lbcom_Id.BeforeLoadDFMValues;
  lbcom_Name.BeforeLoadDFMValues;
  lbcom_Token.BeforeLoadDFMValues;
  lbcom_Adresse.BeforeLoadDFMValues;
  lbcom_City.BeforeLoadDFMValues;
  lbcom_Province.BeforeLoadDFMValues;
  lbcom_PostalCode.BeforeLoadDFMValues;
  lbcom_Telephone.BeforeLoadDFMValues;
  lbcom_Email.BeforeLoadDFMValues;
  lbcom_Tx1Name.BeforeLoadDFMValues;
  lbcom_Tx1Rate.BeforeLoadDFMValues;
  lbcom_Tx1Number.BeforeLoadDFMValues;
  lbcom_Tx1Active.BeforeLoadDFMValues;
  lbcom_Tx2Name.BeforeLoadDFMValues;
  lbcom_Tx2Rate.BeforeLoadDFMValues;
  lbcom_Tx2Number.BeforeLoadDFMValues;
  lbcom_Tx2Active.BeforeLoadDFMValues;
  lbcom_Tx3Name.BeforeLoadDFMValues;
  lbcom_Tx3Rate.BeforeLoadDFMValues;
  lbcom_Tx3Number.BeforeLoadDFMValues;
  lbcom_Tx3Active.BeforeLoadDFMValues;
  lbcon_Slogan.BeforeLoadDFMValues;
  lbcom_LastModifiedBy.BeforeLoadDFMValues;
  lbcom_LastModifiedDate.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edcom_Id.BeforeLoadDFMValues;
  edcom_Name.BeforeLoadDFMValues;
  edcom_Token.BeforeLoadDFMValues;
  edcom_Adresse.BeforeLoadDFMValues;
  edcom_City.BeforeLoadDFMValues;
  edcom_Province.BeforeLoadDFMValues;
  edcom_PostalCode.BeforeLoadDFMValues;
  edcom_Telephone.BeforeLoadDFMValues;
  edcom_Email.BeforeLoadDFMValues;
  edcom_Tx1Name.BeforeLoadDFMValues;
  edcom_Tx1Rate.BeforeLoadDFMValues;
  edcom_Tx1Number.BeforeLoadDFMValues;
  edcom_Tx1Active.BeforeLoadDFMValues;
  edcom_Tx2Name.BeforeLoadDFMValues;
  edcom_Tx2Rate.BeforeLoadDFMValues;
  edcom_Tx2Number.BeforeLoadDFMValues;
  edcom_Tx2Active.BeforeLoadDFMValues;
  edcom_Tx3Name.BeforeLoadDFMValues;
  edcom_Tx3Rate.BeforeLoadDFMValues;
  edcom_Tx3Number.BeforeLoadDFMValues;
  edcom_Tx3Active.BeforeLoadDFMValues;
  edcon_Slogan.BeforeLoadDFMValues;
  edcom_LastModifiedBy.BeforeLoadDFMValues;
  edcom_LastModifiedDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  CompanyInfoDataset.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Id.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Name.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Token.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Adresse.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_City.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Province.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_PostalCode.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Telephone.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Email.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx1Name.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx1Rate.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx1Number.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx1Active.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx2Name.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx2Rate.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx2Number.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx2Active.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx3Name.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx3Rate.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx3Number.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_Tx3Active.BeforeLoadDFMValues;
  CompanyInfoDatasetcon_Slogan.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_LastModifiedBy.BeforeLoadDFMValues;
  CompanyInfoDatasetcom_LastModifiedDate.BeforeLoadDFMValues;
  XDataClientCompanyInfoGet.BeforeLoadDFMValues;
  XDataClientCompanyInfoDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewCompanyInfoEdit';
    Width := 593;
    Height := 913;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbcom_Id.SetParentComponent(Self);
    lbcom_Id.Name := 'lbcom_Id';
    lbcom_Id.Left := 24;
    lbcom_Id.Top := 192;
    lbcom_Id.Width := 39;
    lbcom_Id.Height := 13;
    lbcom_Id.Caption := 'com_Id:';
    lbcom_Id.HeightPercent := 100.000000000000000000;
    lbcom_Id.WidthPercent := 100.000000000000000000;
    lbcom_Name.SetParentComponent(Self);
    lbcom_Name.Name := 'lbcom_Name';
    lbcom_Name.Left := 24;
    lbcom_Name.Top := 219;
    lbcom_Name.Width := 56;
    lbcom_Name.Height := 13;
    lbcom_Name.Caption := 'com_Name:';
    lbcom_Name.HeightPercent := 100.000000000000000000;
    lbcom_Name.WidthPercent := 100.000000000000000000;
    lbcom_Token.SetParentComponent(Self);
    lbcom_Token.Name := 'lbcom_Token';
    lbcom_Token.Left := 24;
    lbcom_Token.Top := 246;
    lbcom_Token.Width := 58;
    lbcom_Token.Height := 13;
    lbcom_Token.Caption := 'com_Token:';
    lbcom_Token.HeightPercent := 100.000000000000000000;
    lbcom_Token.WidthPercent := 100.000000000000000000;
    lbcom_Adresse.SetParentComponent(Self);
    lbcom_Adresse.Name := 'lbcom_Adresse';
    lbcom_Adresse.Left := 24;
    lbcom_Adresse.Top := 273;
    lbcom_Adresse.Width := 68;
    lbcom_Adresse.Height := 13;
    lbcom_Adresse.Caption := 'com_Adresse:';
    lbcom_Adresse.HeightPercent := 100.000000000000000000;
    lbcom_Adresse.WidthPercent := 100.000000000000000000;
    lbcom_City.SetParentComponent(Self);
    lbcom_City.Name := 'lbcom_City';
    lbcom_City.Left := 24;
    lbcom_City.Top := 300;
    lbcom_City.Width := 48;
    lbcom_City.Height := 13;
    lbcom_City.Caption := 'com_City:';
    lbcom_City.HeightPercent := 100.000000000000000000;
    lbcom_City.WidthPercent := 100.000000000000000000;
    lbcom_Province.SetParentComponent(Self);
    lbcom_Province.Name := 'lbcom_Province';
    lbcom_Province.Left := 24;
    lbcom_Province.Top := 327;
    lbcom_Province.Width := 70;
    lbcom_Province.Height := 13;
    lbcom_Province.Caption := 'com_Province:';
    lbcom_Province.HeightPercent := 100.000000000000000000;
    lbcom_Province.WidthPercent := 100.000000000000000000;
    lbcom_PostalCode.SetParentComponent(Self);
    lbcom_PostalCode.Name := 'lbcom_PostalCode';
    lbcom_PostalCode.Left := 24;
    lbcom_PostalCode.Top := 354;
    lbcom_PostalCode.Width := 83;
    lbcom_PostalCode.Height := 13;
    lbcom_PostalCode.Caption := 'com_PostalCode:';
    lbcom_PostalCode.HeightPercent := 100.000000000000000000;
    lbcom_PostalCode.WidthPercent := 100.000000000000000000;
    lbcom_Telephone.SetParentComponent(Self);
    lbcom_Telephone.Name := 'lbcom_Telephone';
    lbcom_Telephone.Left := 24;
    lbcom_Telephone.Top := 381;
    lbcom_Telephone.Width := 79;
    lbcom_Telephone.Height := 13;
    lbcom_Telephone.Caption := 'com_Telephone:';
    lbcom_Telephone.HeightPercent := 100.000000000000000000;
    lbcom_Telephone.WidthPercent := 100.000000000000000000;
    lbcom_Email.SetParentComponent(Self);
    lbcom_Email.Name := 'lbcom_Email';
    lbcom_Email.Left := 24;
    lbcom_Email.Top := 408;
    lbcom_Email.Width := 53;
    lbcom_Email.Height := 13;
    lbcom_Email.Caption := 'com_Email:';
    lbcom_Email.HeightPercent := 100.000000000000000000;
    lbcom_Email.WidthPercent := 100.000000000000000000;
    lbcom_Tx1Name.SetParentComponent(Self);
    lbcom_Tx1Name.Name := 'lbcom_Tx1Name';
    lbcom_Tx1Name.Left := 24;
    lbcom_Tx1Name.Top := 435;
    lbcom_Tx1Name.Width := 74;
    lbcom_Tx1Name.Height := 13;
    lbcom_Tx1Name.Caption := 'com_Tx1Name:';
    lbcom_Tx1Name.HeightPercent := 100.000000000000000000;
    lbcom_Tx1Name.WidthPercent := 100.000000000000000000;
    lbcom_Tx1Rate.SetParentComponent(Self);
    lbcom_Tx1Rate.Name := 'lbcom_Tx1Rate';
    lbcom_Tx1Rate.Left := 24;
    lbcom_Tx1Rate.Top := 462;
    lbcom_Tx1Rate.Width := 70;
    lbcom_Tx1Rate.Height := 13;
    lbcom_Tx1Rate.Caption := 'com_Tx1Rate:';
    lbcom_Tx1Rate.HeightPercent := 100.000000000000000000;
    lbcom_Tx1Rate.WidthPercent := 100.000000000000000000;
    lbcom_Tx1Number.SetParentComponent(Self);
    lbcom_Tx1Number.Name := 'lbcom_Tx1Number';
    lbcom_Tx1Number.Left := 24;
    lbcom_Tx1Number.Top := 489;
    lbcom_Tx1Number.Width := 84;
    lbcom_Tx1Number.Height := 13;
    lbcom_Tx1Number.Caption := 'com_Tx1Number:';
    lbcom_Tx1Number.HeightPercent := 100.000000000000000000;
    lbcom_Tx1Number.WidthPercent := 100.000000000000000000;
    lbcom_Tx1Active.SetParentComponent(Self);
    lbcom_Tx1Active.Name := 'lbcom_Tx1Active';
    lbcom_Tx1Active.Left := 24;
    lbcom_Tx1Active.Top := 516;
    lbcom_Tx1Active.Width := 77;
    lbcom_Tx1Active.Height := 13;
    lbcom_Tx1Active.Caption := 'com_Tx1Active:';
    lbcom_Tx1Active.HeightPercent := 100.000000000000000000;
    lbcom_Tx1Active.WidthPercent := 100.000000000000000000;
    lbcom_Tx2Name.SetParentComponent(Self);
    lbcom_Tx2Name.Name := 'lbcom_Tx2Name';
    lbcom_Tx2Name.Left := 24;
    lbcom_Tx2Name.Top := 543;
    lbcom_Tx2Name.Width := 74;
    lbcom_Tx2Name.Height := 13;
    lbcom_Tx2Name.Caption := 'com_Tx2Name:';
    lbcom_Tx2Name.HeightPercent := 100.000000000000000000;
    lbcom_Tx2Name.WidthPercent := 100.000000000000000000;
    lbcom_Tx2Rate.SetParentComponent(Self);
    lbcom_Tx2Rate.Name := 'lbcom_Tx2Rate';
    lbcom_Tx2Rate.Left := 24;
    lbcom_Tx2Rate.Top := 570;
    lbcom_Tx2Rate.Width := 70;
    lbcom_Tx2Rate.Height := 13;
    lbcom_Tx2Rate.Caption := 'com_Tx2Rate:';
    lbcom_Tx2Rate.HeightPercent := 100.000000000000000000;
    lbcom_Tx2Rate.WidthPercent := 100.000000000000000000;
    lbcom_Tx2Number.SetParentComponent(Self);
    lbcom_Tx2Number.Name := 'lbcom_Tx2Number';
    lbcom_Tx2Number.Left := 24;
    lbcom_Tx2Number.Top := 597;
    lbcom_Tx2Number.Width := 84;
    lbcom_Tx2Number.Height := 13;
    lbcom_Tx2Number.Caption := 'com_Tx2Number:';
    lbcom_Tx2Number.HeightPercent := 100.000000000000000000;
    lbcom_Tx2Number.WidthPercent := 100.000000000000000000;
    lbcom_Tx2Active.SetParentComponent(Self);
    lbcom_Tx2Active.Name := 'lbcom_Tx2Active';
    lbcom_Tx2Active.Left := 24;
    lbcom_Tx2Active.Top := 624;
    lbcom_Tx2Active.Width := 77;
    lbcom_Tx2Active.Height := 13;
    lbcom_Tx2Active.Caption := 'com_Tx2Active:';
    lbcom_Tx2Active.HeightPercent := 100.000000000000000000;
    lbcom_Tx2Active.WidthPercent := 100.000000000000000000;
    lbcom_Tx3Name.SetParentComponent(Self);
    lbcom_Tx3Name.Name := 'lbcom_Tx3Name';
    lbcom_Tx3Name.Left := 24;
    lbcom_Tx3Name.Top := 651;
    lbcom_Tx3Name.Width := 74;
    lbcom_Tx3Name.Height := 13;
    lbcom_Tx3Name.Caption := 'com_Tx3Name:';
    lbcom_Tx3Name.HeightPercent := 100.000000000000000000;
    lbcom_Tx3Name.WidthPercent := 100.000000000000000000;
    lbcom_Tx3Rate.SetParentComponent(Self);
    lbcom_Tx3Rate.Name := 'lbcom_Tx3Rate';
    lbcom_Tx3Rate.Left := 24;
    lbcom_Tx3Rate.Top := 678;
    lbcom_Tx3Rate.Width := 70;
    lbcom_Tx3Rate.Height := 13;
    lbcom_Tx3Rate.Caption := 'com_Tx3Rate:';
    lbcom_Tx3Rate.HeightPercent := 100.000000000000000000;
    lbcom_Tx3Rate.WidthPercent := 100.000000000000000000;
    lbcom_Tx3Number.SetParentComponent(Self);
    lbcom_Tx3Number.Name := 'lbcom_Tx3Number';
    lbcom_Tx3Number.Left := 24;
    lbcom_Tx3Number.Top := 705;
    lbcom_Tx3Number.Width := 84;
    lbcom_Tx3Number.Height := 13;
    lbcom_Tx3Number.Caption := 'com_Tx3Number:';
    lbcom_Tx3Number.HeightPercent := 100.000000000000000000;
    lbcom_Tx3Number.WidthPercent := 100.000000000000000000;
    lbcom_Tx3Active.SetParentComponent(Self);
    lbcom_Tx3Active.Name := 'lbcom_Tx3Active';
    lbcom_Tx3Active.Left := 24;
    lbcom_Tx3Active.Top := 732;
    lbcom_Tx3Active.Width := 77;
    lbcom_Tx3Active.Height := 13;
    lbcom_Tx3Active.Caption := 'com_Tx3Active:';
    lbcom_Tx3Active.HeightPercent := 100.000000000000000000;
    lbcom_Tx3Active.WidthPercent := 100.000000000000000000;
    lbcon_Slogan.SetParentComponent(Self);
    lbcon_Slogan.Name := 'lbcon_Slogan';
    lbcon_Slogan.Left := 24;
    lbcon_Slogan.Top := 759;
    lbcon_Slogan.Width := 59;
    lbcon_Slogan.Height := 13;
    lbcon_Slogan.Caption := 'con_Slogan:';
    lbcon_Slogan.HeightPercent := 100.000000000000000000;
    lbcon_Slogan.WidthPercent := 100.000000000000000000;
    lbcom_LastModifiedBy.SetParentComponent(Self);
    lbcom_LastModifiedBy.Name := 'lbcom_LastModifiedBy';
    lbcom_LastModifiedBy.Left := 24;
    lbcom_LastModifiedBy.Top := 786;
    lbcom_LastModifiedBy.Width := 101;
    lbcom_LastModifiedBy.Height := 13;
    lbcom_LastModifiedBy.Caption := 'com_LastModifiedBy:';
    lbcom_LastModifiedBy.HeightPercent := 100.000000000000000000;
    lbcom_LastModifiedBy.WidthPercent := 100.000000000000000000;
    lbcom_LastModifiedDate.SetParentComponent(Self);
    lbcom_LastModifiedDate.Name := 'lbcom_LastModifiedDate';
    lbcom_LastModifiedDate.Left := 24;
    lbcom_LastModifiedDate.Top := 813;
    lbcom_LastModifiedDate.Width := 112;
    lbcom_LastModifiedDate.Height := 13;
    lbcom_LastModifiedDate.Caption := 'com_LastModifiedDate:';
    lbcom_LastModifiedDate.HeightPercent := 100.000000000000000000;
    lbcom_LastModifiedDate.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 853;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 853;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 853;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edcom_Id.SetParentComponent(Self);
    edcom_Id.Name := 'edcom_Id';
    edcom_Id.Left := 221;
    edcom_Id.Top := 189;
    edcom_Id.Width := 200;
    edcom_Id.Height := 21;
    edcom_Id.HeightPercent := 100.000000000000000000;
    edcom_Id.TabOrder := 6;
    edcom_Id.Text := 'edcom_Id';
    edcom_Id.WidthPercent := 100.000000000000000000;
    edcom_Id.DataField := 'com_Id';
    edcom_Id.DataSource := DataSource;
    edcom_Name.SetParentComponent(Self);
    edcom_Name.Name := 'edcom_Name';
    edcom_Name.Left := 221;
    edcom_Name.Top := 216;
    edcom_Name.Width := 200;
    edcom_Name.Height := 21;
    edcom_Name.HeightPercent := 100.000000000000000000;
    edcom_Name.TabOrder := 7;
    edcom_Name.Text := 'edcom_Name';
    edcom_Name.WidthPercent := 100.000000000000000000;
    edcom_Name.DataField := 'com_Name';
    edcom_Name.DataSource := DataSource;
    edcom_Token.SetParentComponent(Self);
    edcom_Token.Name := 'edcom_Token';
    edcom_Token.Left := 221;
    edcom_Token.Top := 243;
    edcom_Token.Width := 200;
    edcom_Token.Height := 21;
    edcom_Token.HeightPercent := 100.000000000000000000;
    edcom_Token.TabOrder := 8;
    edcom_Token.Text := 'edcom_Token';
    edcom_Token.WidthPercent := 100.000000000000000000;
    edcom_Token.DataField := 'com_Token';
    edcom_Token.DataSource := DataSource;
    edcom_Adresse.SetParentComponent(Self);
    edcom_Adresse.Name := 'edcom_Adresse';
    edcom_Adresse.Left := 221;
    edcom_Adresse.Top := 270;
    edcom_Adresse.Width := 200;
    edcom_Adresse.Height := 21;
    edcom_Adresse.HeightPercent := 100.000000000000000000;
    edcom_Adresse.TabOrder := 9;
    edcom_Adresse.Text := 'edcom_Adresse';
    edcom_Adresse.WidthPercent := 100.000000000000000000;
    edcom_Adresse.DataField := 'com_Adresse';
    edcom_Adresse.DataSource := DataSource;
    edcom_City.SetParentComponent(Self);
    edcom_City.Name := 'edcom_City';
    edcom_City.Left := 221;
    edcom_City.Top := 297;
    edcom_City.Width := 200;
    edcom_City.Height := 21;
    edcom_City.HeightPercent := 100.000000000000000000;
    edcom_City.TabOrder := 10;
    edcom_City.Text := 'edcom_City';
    edcom_City.WidthPercent := 100.000000000000000000;
    edcom_City.DataField := 'com_City';
    edcom_City.DataSource := DataSource;
    edcom_Province.SetParentComponent(Self);
    edcom_Province.Name := 'edcom_Province';
    edcom_Province.Left := 221;
    edcom_Province.Top := 324;
    edcom_Province.Width := 200;
    edcom_Province.Height := 21;
    edcom_Province.HeightPercent := 100.000000000000000000;
    edcom_Province.TabOrder := 11;
    edcom_Province.Text := 'edcom_Province';
    edcom_Province.WidthPercent := 100.000000000000000000;
    edcom_Province.DataField := 'com_Province';
    edcom_Province.DataSource := DataSource;
    edcom_PostalCode.SetParentComponent(Self);
    edcom_PostalCode.Name := 'edcom_PostalCode';
    edcom_PostalCode.Left := 221;
    edcom_PostalCode.Top := 351;
    edcom_PostalCode.Width := 200;
    edcom_PostalCode.Height := 21;
    edcom_PostalCode.HeightPercent := 100.000000000000000000;
    edcom_PostalCode.TabOrder := 12;
    edcom_PostalCode.Text := 'edcom_PostalCode';
    edcom_PostalCode.WidthPercent := 100.000000000000000000;
    edcom_PostalCode.DataField := 'com_PostalCode';
    edcom_PostalCode.DataSource := DataSource;
    edcom_Telephone.SetParentComponent(Self);
    edcom_Telephone.Name := 'edcom_Telephone';
    edcom_Telephone.Left := 221;
    edcom_Telephone.Top := 378;
    edcom_Telephone.Width := 200;
    edcom_Telephone.Height := 21;
    edcom_Telephone.HeightPercent := 100.000000000000000000;
    edcom_Telephone.TabOrder := 13;
    edcom_Telephone.Text := 'edcom_Telephone';
    edcom_Telephone.WidthPercent := 100.000000000000000000;
    edcom_Telephone.DataField := 'com_Telephone';
    edcom_Telephone.DataSource := DataSource;
    edcom_Email.SetParentComponent(Self);
    edcom_Email.Name := 'edcom_Email';
    edcom_Email.Left := 221;
    edcom_Email.Top := 405;
    edcom_Email.Width := 200;
    edcom_Email.Height := 21;
    edcom_Email.HeightPercent := 100.000000000000000000;
    edcom_Email.TabOrder := 14;
    edcom_Email.Text := 'edcom_Email';
    edcom_Email.WidthPercent := 100.000000000000000000;
    edcom_Email.DataField := 'com_Email';
    edcom_Email.DataSource := DataSource;
    edcom_Tx1Name.SetParentComponent(Self);
    edcom_Tx1Name.Name := 'edcom_Tx1Name';
    edcom_Tx1Name.Left := 221;
    edcom_Tx1Name.Top := 432;
    edcom_Tx1Name.Width := 200;
    edcom_Tx1Name.Height := 21;
    edcom_Tx1Name.HeightPercent := 100.000000000000000000;
    edcom_Tx1Name.TabOrder := 15;
    edcom_Tx1Name.Text := 'edcom_Tx1Name';
    edcom_Tx1Name.WidthPercent := 100.000000000000000000;
    edcom_Tx1Name.DataField := 'com_Tx1Name';
    edcom_Tx1Name.DataSource := DataSource;
    edcom_Tx1Rate.SetParentComponent(Self);
    edcom_Tx1Rate.Name := 'edcom_Tx1Rate';
    edcom_Tx1Rate.Left := 221;
    edcom_Tx1Rate.Top := 459;
    edcom_Tx1Rate.Width := 200;
    edcom_Tx1Rate.Height := 21;
    edcom_Tx1Rate.HeightPercent := 100.000000000000000000;
    edcom_Tx1Rate.TabOrder := 16;
    edcom_Tx1Rate.Text := 'edcom_Tx1Rate';
    edcom_Tx1Rate.WidthPercent := 100.000000000000000000;
    edcom_Tx1Rate.DataField := 'com_Tx1Rate';
    edcom_Tx1Rate.DataSource := DataSource;
    edcom_Tx1Number.SetParentComponent(Self);
    edcom_Tx1Number.Name := 'edcom_Tx1Number';
    edcom_Tx1Number.Left := 221;
    edcom_Tx1Number.Top := 486;
    edcom_Tx1Number.Width := 200;
    edcom_Tx1Number.Height := 21;
    edcom_Tx1Number.HeightPercent := 100.000000000000000000;
    edcom_Tx1Number.TabOrder := 17;
    edcom_Tx1Number.Text := 'edcom_Tx1Number';
    edcom_Tx1Number.WidthPercent := 100.000000000000000000;
    edcom_Tx1Number.DataField := 'com_Tx1Number';
    edcom_Tx1Number.DataSource := DataSource;
    edcom_Tx1Active.SetParentComponent(Self);
    edcom_Tx1Active.Name := 'edcom_Tx1Active';
    edcom_Tx1Active.Left := 221;
    edcom_Tx1Active.Top := 513;
    edcom_Tx1Active.Width := 200;
    edcom_Tx1Active.Height := 21;
    edcom_Tx1Active.HeightPercent := 100.000000000000000000;
    edcom_Tx1Active.TabOrder := 18;
    edcom_Tx1Active.Text := 'edcom_Tx1Active';
    edcom_Tx1Active.WidthPercent := 100.000000000000000000;
    edcom_Tx1Active.DataField := 'com_Tx1Active';
    edcom_Tx1Active.DataSource := DataSource;
    edcom_Tx2Name.SetParentComponent(Self);
    edcom_Tx2Name.Name := 'edcom_Tx2Name';
    edcom_Tx2Name.Left := 221;
    edcom_Tx2Name.Top := 540;
    edcom_Tx2Name.Width := 200;
    edcom_Tx2Name.Height := 21;
    edcom_Tx2Name.HeightPercent := 100.000000000000000000;
    edcom_Tx2Name.TabOrder := 19;
    edcom_Tx2Name.Text := 'edcom_Tx2Name';
    edcom_Tx2Name.WidthPercent := 100.000000000000000000;
    edcom_Tx2Name.DataField := 'com_Tx2Name';
    edcom_Tx2Name.DataSource := DataSource;
    edcom_Tx2Rate.SetParentComponent(Self);
    edcom_Tx2Rate.Name := 'edcom_Tx2Rate';
    edcom_Tx2Rate.Left := 221;
    edcom_Tx2Rate.Top := 567;
    edcom_Tx2Rate.Width := 200;
    edcom_Tx2Rate.Height := 21;
    edcom_Tx2Rate.HeightPercent := 100.000000000000000000;
    edcom_Tx2Rate.TabOrder := 20;
    edcom_Tx2Rate.Text := 'edcom_Tx2Rate';
    edcom_Tx2Rate.WidthPercent := 100.000000000000000000;
    edcom_Tx2Rate.DataField := 'com_Tx2Rate';
    edcom_Tx2Rate.DataSource := DataSource;
    edcom_Tx2Number.SetParentComponent(Self);
    edcom_Tx2Number.Name := 'edcom_Tx2Number';
    edcom_Tx2Number.Left := 221;
    edcom_Tx2Number.Top := 594;
    edcom_Tx2Number.Width := 200;
    edcom_Tx2Number.Height := 21;
    edcom_Tx2Number.HeightPercent := 100.000000000000000000;
    edcom_Tx2Number.TabOrder := 21;
    edcom_Tx2Number.Text := 'edcom_Tx2Number';
    edcom_Tx2Number.WidthPercent := 100.000000000000000000;
    edcom_Tx2Number.DataField := 'com_Tx2Number';
    edcom_Tx2Number.DataSource := DataSource;
    edcom_Tx2Active.SetParentComponent(Self);
    edcom_Tx2Active.Name := 'edcom_Tx2Active';
    edcom_Tx2Active.Left := 221;
    edcom_Tx2Active.Top := 621;
    edcom_Tx2Active.Width := 200;
    edcom_Tx2Active.Height := 21;
    edcom_Tx2Active.HeightPercent := 100.000000000000000000;
    edcom_Tx2Active.TabOrder := 22;
    edcom_Tx2Active.Text := 'edcom_Tx2Active';
    edcom_Tx2Active.WidthPercent := 100.000000000000000000;
    edcom_Tx2Active.DataField := 'com_Tx2Active';
    edcom_Tx2Active.DataSource := DataSource;
    edcom_Tx3Name.SetParentComponent(Self);
    edcom_Tx3Name.Name := 'edcom_Tx3Name';
    edcom_Tx3Name.Left := 221;
    edcom_Tx3Name.Top := 648;
    edcom_Tx3Name.Width := 200;
    edcom_Tx3Name.Height := 21;
    edcom_Tx3Name.HeightPercent := 100.000000000000000000;
    edcom_Tx3Name.TabOrder := 23;
    edcom_Tx3Name.Text := 'edcom_Tx3Name';
    edcom_Tx3Name.WidthPercent := 100.000000000000000000;
    edcom_Tx3Name.DataField := 'com_Tx3Name';
    edcom_Tx3Name.DataSource := DataSource;
    edcom_Tx3Rate.SetParentComponent(Self);
    edcom_Tx3Rate.Name := 'edcom_Tx3Rate';
    edcom_Tx3Rate.Left := 221;
    edcom_Tx3Rate.Top := 675;
    edcom_Tx3Rate.Width := 200;
    edcom_Tx3Rate.Height := 21;
    edcom_Tx3Rate.HeightPercent := 100.000000000000000000;
    edcom_Tx3Rate.TabOrder := 24;
    edcom_Tx3Rate.Text := 'edcom_Tx3Rate';
    edcom_Tx3Rate.WidthPercent := 100.000000000000000000;
    edcom_Tx3Rate.DataField := 'com_Tx3Rate';
    edcom_Tx3Rate.DataSource := DataSource;
    edcom_Tx3Number.SetParentComponent(Self);
    edcom_Tx3Number.Name := 'edcom_Tx3Number';
    edcom_Tx3Number.Left := 221;
    edcom_Tx3Number.Top := 702;
    edcom_Tx3Number.Width := 200;
    edcom_Tx3Number.Height := 21;
    edcom_Tx3Number.HeightPercent := 100.000000000000000000;
    edcom_Tx3Number.TabOrder := 25;
    edcom_Tx3Number.Text := 'edcom_Tx3Number';
    edcom_Tx3Number.WidthPercent := 100.000000000000000000;
    edcom_Tx3Number.DataField := 'com_Tx3Number';
    edcom_Tx3Number.DataSource := DataSource;
    edcom_Tx3Active.SetParentComponent(Self);
    edcom_Tx3Active.Name := 'edcom_Tx3Active';
    edcom_Tx3Active.Left := 221;
    edcom_Tx3Active.Top := 729;
    edcom_Tx3Active.Width := 200;
    edcom_Tx3Active.Height := 21;
    edcom_Tx3Active.HeightPercent := 100.000000000000000000;
    edcom_Tx3Active.TabOrder := 26;
    edcom_Tx3Active.Text := 'edcom_Tx3Active';
    edcom_Tx3Active.WidthPercent := 100.000000000000000000;
    edcom_Tx3Active.DataField := 'com_Tx3Active';
    edcom_Tx3Active.DataSource := DataSource;
    edcon_Slogan.SetParentComponent(Self);
    edcon_Slogan.Name := 'edcon_Slogan';
    edcon_Slogan.Left := 221;
    edcon_Slogan.Top := 756;
    edcon_Slogan.Width := 200;
    edcon_Slogan.Height := 21;
    edcon_Slogan.HeightPercent := 100.000000000000000000;
    edcon_Slogan.TabOrder := 27;
    edcon_Slogan.Text := 'edcon_Slogan';
    edcon_Slogan.WidthPercent := 100.000000000000000000;
    edcon_Slogan.DataField := 'con_Slogan';
    edcon_Slogan.DataSource := DataSource;
    edcom_LastModifiedBy.SetParentComponent(Self);
    edcom_LastModifiedBy.Name := 'edcom_LastModifiedBy';
    edcom_LastModifiedBy.Left := 221;
    edcom_LastModifiedBy.Top := 783;
    edcom_LastModifiedBy.Width := 200;
    edcom_LastModifiedBy.Height := 21;
    edcom_LastModifiedBy.HeightPercent := 100.000000000000000000;
    edcom_LastModifiedBy.TabOrder := 28;
    edcom_LastModifiedBy.Text := 'edcom_LastModifiedBy';
    edcom_LastModifiedBy.WidthPercent := 100.000000000000000000;
    edcom_LastModifiedBy.DataField := 'com_LastModifiedBy';
    edcom_LastModifiedBy.DataSource := DataSource;
    edcom_LastModifiedDate.SetParentComponent(Self);
    edcom_LastModifiedDate.Name := 'edcom_LastModifiedDate';
    edcom_LastModifiedDate.Left := 221;
    edcom_LastModifiedDate.Top := 810;
    edcom_LastModifiedDate.Width := 200;
    edcom_LastModifiedDate.Height := 21;
    edcom_LastModifiedDate.HeightPercent := 100.000000000000000000;
    edcom_LastModifiedDate.TabOrder := 29;
    edcom_LastModifiedDate.Text := 'edcom_LastModifiedDate';
    edcom_LastModifiedDate.WidthPercent := 100.000000000000000000;
    edcom_LastModifiedDate.DataField := 'com_LastModifiedDate';
    edcom_LastModifiedDate.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := CompanyInfoDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    CompanyInfoDataset.SetParentComponent(Self);
    CompanyInfoDataset.Name := 'CompanyInfoDataset';
    CompanyInfoDataset.AfterApplyUpdates := CompanyInfoDatasetAfterApplyUpdates;
    CompanyInfoDataset.EntitySetName := 'CompanyInfo';
    CompanyInfoDataset.Connection := DMConnection.ApiConnection;
    CompanyInfoDataset.Left := 176;
    CompanyInfoDataset.Top := 8;
    CompanyInfoDatasetcom_Id.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Id.Name := 'CompanyInfoDatasetcom_Id';
    CompanyInfoDatasetcom_Id.FieldName := 'com_Id';
    CompanyInfoDatasetcom_Id.Required := True;
    CompanyInfoDatasetcom_Name.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Name.Name := 'CompanyInfoDatasetcom_Name';
    CompanyInfoDatasetcom_Name.FieldName := 'com_Name';
    CompanyInfoDatasetcom_Name.Size := 50;
    CompanyInfoDatasetcom_Token.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Token.Name := 'CompanyInfoDatasetcom_Token';
    CompanyInfoDatasetcom_Token.FieldName := 'com_Token';
    CompanyInfoDatasetcom_Token.Size := 50;
    CompanyInfoDatasetcom_Adresse.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Adresse.Name := 'CompanyInfoDatasetcom_Adresse';
    CompanyInfoDatasetcom_Adresse.FieldName := 'com_Adresse';
    CompanyInfoDatasetcom_Adresse.Size := 150;
    CompanyInfoDatasetcom_City.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_City.Name := 'CompanyInfoDatasetcom_City';
    CompanyInfoDatasetcom_City.FieldName := 'com_City';
    CompanyInfoDatasetcom_City.Size := 100;
    CompanyInfoDatasetcom_Province.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Province.Name := 'CompanyInfoDatasetcom_Province';
    CompanyInfoDatasetcom_Province.FieldName := 'com_Province';
    CompanyInfoDatasetcom_Province.Size := 50;
    CompanyInfoDatasetcom_PostalCode.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_PostalCode.Name := 'CompanyInfoDatasetcom_PostalCode';
    CompanyInfoDatasetcom_PostalCode.FieldName := 'com_PostalCode';
    CompanyInfoDatasetcom_PostalCode.Size := 50;
    CompanyInfoDatasetcom_Telephone.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Telephone.Name := 'CompanyInfoDatasetcom_Telephone';
    CompanyInfoDatasetcom_Telephone.FieldName := 'com_Telephone';
    CompanyInfoDatasetcom_Telephone.Size := 50;
    CompanyInfoDatasetcom_Email.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Email.Name := 'CompanyInfoDatasetcom_Email';
    CompanyInfoDatasetcom_Email.FieldName := 'com_Email';
    CompanyInfoDatasetcom_Email.Size := 75;
    CompanyInfoDatasetcom_Tx1Name.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx1Name.Name := 'CompanyInfoDatasetcom_Tx1Name';
    CompanyInfoDatasetcom_Tx1Name.FieldName := 'com_Tx1Name';
    CompanyInfoDatasetcom_Tx1Name.Size := 50;
    CompanyInfoDatasetcom_Tx1Rate.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx1Rate.Name := 'CompanyInfoDatasetcom_Tx1Rate';
    CompanyInfoDatasetcom_Tx1Rate.FieldName := 'com_Tx1Rate';
    CompanyInfoDatasetcom_Tx1Number.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx1Number.Name := 'CompanyInfoDatasetcom_Tx1Number';
    CompanyInfoDatasetcom_Tx1Number.FieldName := 'com_Tx1Number';
    CompanyInfoDatasetcom_Tx1Active.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx1Active.Name := 'CompanyInfoDatasetcom_Tx1Active';
    CompanyInfoDatasetcom_Tx1Active.FieldName := 'com_Tx1Active';
    CompanyInfoDatasetcom_Tx2Name.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx2Name.Name := 'CompanyInfoDatasetcom_Tx2Name';
    CompanyInfoDatasetcom_Tx2Name.FieldName := 'com_Tx2Name';
    CompanyInfoDatasetcom_Tx2Name.Size := 50;
    CompanyInfoDatasetcom_Tx2Rate.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx2Rate.Name := 'CompanyInfoDatasetcom_Tx2Rate';
    CompanyInfoDatasetcom_Tx2Rate.FieldName := 'com_Tx2Rate';
    CompanyInfoDatasetcom_Tx2Number.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx2Number.Name := 'CompanyInfoDatasetcom_Tx2Number';
    CompanyInfoDatasetcom_Tx2Number.FieldName := 'com_Tx2Number';
    CompanyInfoDatasetcom_Tx2Number.Size := 50;
    CompanyInfoDatasetcom_Tx2Active.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx2Active.Name := 'CompanyInfoDatasetcom_Tx2Active';
    CompanyInfoDatasetcom_Tx2Active.FieldName := 'com_Tx2Active';
    CompanyInfoDatasetcom_Tx3Name.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx3Name.Name := 'CompanyInfoDatasetcom_Tx3Name';
    CompanyInfoDatasetcom_Tx3Name.FieldName := 'com_Tx3Name';
    CompanyInfoDatasetcom_Tx3Name.Size := 50;
    CompanyInfoDatasetcom_Tx3Rate.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx3Rate.Name := 'CompanyInfoDatasetcom_Tx3Rate';
    CompanyInfoDatasetcom_Tx3Rate.FieldName := 'com_Tx3Rate';
    CompanyInfoDatasetcom_Tx3Number.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx3Number.Name := 'CompanyInfoDatasetcom_Tx3Number';
    CompanyInfoDatasetcom_Tx3Number.FieldName := 'com_Tx3Number';
    CompanyInfoDatasetcom_Tx3Number.Size := 50;
    CompanyInfoDatasetcom_Tx3Active.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_Tx3Active.Name := 'CompanyInfoDatasetcom_Tx3Active';
    CompanyInfoDatasetcom_Tx3Active.FieldName := 'com_Tx3Active';
    CompanyInfoDatasetcon_Slogan.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcon_Slogan.Name := 'CompanyInfoDatasetcon_Slogan';
    CompanyInfoDatasetcon_Slogan.FieldName := 'con_Slogan';
    CompanyInfoDatasetcon_Slogan.Size := 50;
    CompanyInfoDatasetcom_LastModifiedBy.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_LastModifiedBy.Name := 'CompanyInfoDatasetcom_LastModifiedBy';
    CompanyInfoDatasetcom_LastModifiedBy.FieldName := 'com_LastModifiedBy';
    CompanyInfoDatasetcom_LastModifiedDate.SetParentComponent(CompanyInfoDataset);
    CompanyInfoDatasetcom_LastModifiedDate.Name := 'CompanyInfoDatasetcom_LastModifiedDate';
    CompanyInfoDatasetcom_LastModifiedDate.FieldName := 'com_LastModifiedDate';
    XDataClientCompanyInfoGet.SetParentComponent(Self);
    XDataClientCompanyInfoGet.Name := 'XDataClientCompanyInfoGet';
    XDataClientCompanyInfoGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientCompanyInfoGet, Self, 'OnLoad', 'XDataClientCompanyInfoGetLoad');
    XDataClientCompanyInfoGet.Left := 64;
    XDataClientCompanyInfoGet.Top := 8;
    XDataClientCompanyInfoDelete.SetParentComponent(Self);
    XDataClientCompanyInfoDelete.Name := 'XDataClientCompanyInfoDelete';
    XDataClientCompanyInfoDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientCompanyInfoDelete, Self, 'OnLoad', 'XDataClientCompanyInfoDeleteLoad');
    SetEvent(XDataClientCompanyInfoDelete, Self, 'OnError', 'XDataClientCompanyInfoDeleteError');
    XDataClientCompanyInfoDelete.Left := 64;
    XDataClientCompanyInfoDelete.Top := 56;
  finally
    lbcom_Id.AfterLoadDFMValues;
    lbcom_Name.AfterLoadDFMValues;
    lbcom_Token.AfterLoadDFMValues;
    lbcom_Adresse.AfterLoadDFMValues;
    lbcom_City.AfterLoadDFMValues;
    lbcom_Province.AfterLoadDFMValues;
    lbcom_PostalCode.AfterLoadDFMValues;
    lbcom_Telephone.AfterLoadDFMValues;
    lbcom_Email.AfterLoadDFMValues;
    lbcom_Tx1Name.AfterLoadDFMValues;
    lbcom_Tx1Rate.AfterLoadDFMValues;
    lbcom_Tx1Number.AfterLoadDFMValues;
    lbcom_Tx1Active.AfterLoadDFMValues;
    lbcom_Tx2Name.AfterLoadDFMValues;
    lbcom_Tx2Rate.AfterLoadDFMValues;
    lbcom_Tx2Number.AfterLoadDFMValues;
    lbcom_Tx2Active.AfterLoadDFMValues;
    lbcom_Tx3Name.AfterLoadDFMValues;
    lbcom_Tx3Rate.AfterLoadDFMValues;
    lbcom_Tx3Number.AfterLoadDFMValues;
    lbcom_Tx3Active.AfterLoadDFMValues;
    lbcon_Slogan.AfterLoadDFMValues;
    lbcom_LastModifiedBy.AfterLoadDFMValues;
    lbcom_LastModifiedDate.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edcom_Id.AfterLoadDFMValues;
    edcom_Name.AfterLoadDFMValues;
    edcom_Token.AfterLoadDFMValues;
    edcom_Adresse.AfterLoadDFMValues;
    edcom_City.AfterLoadDFMValues;
    edcom_Province.AfterLoadDFMValues;
    edcom_PostalCode.AfterLoadDFMValues;
    edcom_Telephone.AfterLoadDFMValues;
    edcom_Email.AfterLoadDFMValues;
    edcom_Tx1Name.AfterLoadDFMValues;
    edcom_Tx1Rate.AfterLoadDFMValues;
    edcom_Tx1Number.AfterLoadDFMValues;
    edcom_Tx1Active.AfterLoadDFMValues;
    edcom_Tx2Name.AfterLoadDFMValues;
    edcom_Tx2Rate.AfterLoadDFMValues;
    edcom_Tx2Number.AfterLoadDFMValues;
    edcom_Tx2Active.AfterLoadDFMValues;
    edcom_Tx3Name.AfterLoadDFMValues;
    edcom_Tx3Rate.AfterLoadDFMValues;
    edcom_Tx3Number.AfterLoadDFMValues;
    edcom_Tx3Active.AfterLoadDFMValues;
    edcon_Slogan.AfterLoadDFMValues;
    edcom_LastModifiedBy.AfterLoadDFMValues;
    edcom_LastModifiedDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    CompanyInfoDataset.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Id.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Name.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Token.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Adresse.AfterLoadDFMValues;
    CompanyInfoDatasetcom_City.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Province.AfterLoadDFMValues;
    CompanyInfoDatasetcom_PostalCode.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Telephone.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Email.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx1Name.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx1Rate.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx1Number.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx1Active.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx2Name.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx2Rate.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx2Number.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx2Active.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx3Name.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx3Rate.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx3Number.AfterLoadDFMValues;
    CompanyInfoDatasetcom_Tx3Active.AfterLoadDFMValues;
    CompanyInfoDatasetcon_Slogan.AfterLoadDFMValues;
    CompanyInfoDatasetcom_LastModifiedBy.AfterLoadDFMValues;
    CompanyInfoDatasetcom_LastModifiedDate.AfterLoadDFMValues;
    XDataClientCompanyInfoGet.AfterLoadDFMValues;
    XDataClientCompanyInfoDelete.AfterLoadDFMValues;
  end;
end;

end.
