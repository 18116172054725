program Campedium;

{$R *.dres}

uses
  Vcl.Forms,
  XData.Web.Connection,
  App.Config in 'core\App.Config.pas',
  App.Types in 'core\App.Types.pas',
  Auth.Service in 'core\Auth.Service.pas',
  Dataset.Plugins in 'core\Dataset.Plugins.pas',
  Grid.Plugins in 'core\Grid.Plugins.pas',
  Grid.Settings in 'core\Grid.Settings.pas',
  Paginator.Plugins in 'core\Paginator.Plugins.pas',
  Crud.Utils in 'core\Crud.Utils.pas',
  Container.Connection in 'Container.Connection.pas',
  View.Login in 'View.Login.pas'{*.html},
  View.Main in 'View.Main.pas'{*.html},
  View.Crud.Container in 'View.Crud.Container.pas'{*.html},
  View.UserProfile in 'View.UserProfile.pas'{*.html},
  View.ErrorPage in 'View.ErrorPage.pas'{*.html},
  View.Home in 'View.Home.pas'{*.html},
  View.InvoiceDetail.List in 'View.InvoiceDetail.List.pas'{*.html},
  View.InvoiceDetail.Edit in 'View.InvoiceDetail.Edit.pas'{*.html},
  View.Employee.List in 'View.Employee.List.pas'{*.html},
  View.Employee.Edit in 'View.Employee.Edit.pas'{*.html},
  View.SalesReports.List in 'View.SalesReports.List.pas'{*.html},
  View.SalesReports.Edit in 'View.SalesReports.Edit.pas'{*.html},
  View.Season.List in 'View.Season.List.pas'{*.html},
  View.Season.Edit in 'View.Season.Edit.pas'{*.html},
  View.Settings.List in 'View.Settings.List.pas'{*.html},
  View.Settings.Edit in 'View.Settings.Edit.pas'{*.html},
  View.Activities.List in 'View.Activities.List.pas'{*.html},
  View.Activities.Edit in 'View.Activities.Edit.pas'{*.html},
  View.ContratModelType.List in 'View.ContratModelType.List.pas'{*.html},
  View.ContratModelType.Edit in 'View.ContratModelType.Edit.pas'{*.html},
  View.Payments.List in 'View.Payments.List.pas'{*.html},
  View.Payments.Edit in 'View.Payments.Edit.pas'{*.html},
  View.InvoiceHeader.List in 'View.InvoiceHeader.List.pas'{*.html},
  View.InvoiceHeader.Edit in 'View.InvoiceHeader.Edit.pas'{*.html},
  View.Tokens.List in 'View.Tokens.List.pas'{*.html},
  View.Tokens.Edit in 'View.Tokens.Edit.pas'{*.html},
  View.AnnounceList.List in 'View.AnnounceList.List.pas'{*.html},
  View.AnnounceList.Edit in 'View.AnnounceList.Edit.pas'{*.html},
  View.CampSite.List in 'View.CampSite.List.pas'{*.html},
  View.CampSite.Edit in 'View.CampSite.Edit.pas'{*.html},
  View.CompProducts.List in 'View.CompProducts.List.pas'{*.html},
  View.CompProducts.Edit in 'View.CompProducts.Edit.pas'{*.html},
  View.CampSitePhoto.List in 'View.CampSitePhoto.List.pas'{*.html},
  View.CampSitePhoto.Edit in 'View.CampSitePhoto.Edit.pas'{*.html},
  View.ContractClass.List in 'View.ContractClass.List.pas'{*.html},
  View.ContractClass.Edit in 'View.ContractClass.Edit.pas'{*.html},
  View.CompanyInfo.List in 'View.CompanyInfo.List.pas'{*.html},
  View.CompanyInfo.Edit in 'View.CompanyInfo.Edit.pas'{*.html},
  View.IncomeCategory.List in 'View.IncomeCategory.List.pas'{*.html},
  View.IncomeCategory.Edit in 'View.IncomeCategory.Edit.pas'{*.html},
  View.ActivityBooking.List in 'View.ActivityBooking.List.pas'{*.html},
  View.ActivityBooking.Edit in 'View.ActivityBooking.Edit.pas'{*.html},
  View.RentalAsset.List in 'View.RentalAsset.List.pas'{*.html},
  View.RentalAsset.Edit in 'View.RentalAsset.Edit.pas'{*.html},
  View.ServicingList.List in 'View.ServicingList.List.pas'{*.html},
  View.ServicingList.Edit in 'View.ServicingList.Edit.pas'{*.html},
  View.Users.List in 'View.Users.List.pas'{*.html},
  View.Users.Edit in 'View.Users.Edit.pas'{*.html},
  View.Reserv.List in 'View.Reserv.List.pas'{*.html},
  View.Reserv.Edit in 'View.Reserv.Edit.pas'{*.html},
  View.Clients.List in 'View.Clients.List.pas'{*.html},
  View.Clients.Edit in 'View.Clients.Edit.pas'{*.html},
  View.EstateInventory.List in 'View.EstateInventory.List.pas'{*.html},
  View.EstateInventory.Edit in 'View.EstateInventory.Edit.pas'{*.html};

{$R *.res}

procedure DisplayLoginView(AMessage: string = ''); forward;

procedure DisplayMainView;

  procedure ConnectProc;
  begin
    if Assigned(FViewLogin) then FViewLogin.Free;                               //aded for login-ing
    TFViewMain.Display('body', @DisplayLoginView);
  end;

begin
  if not DMConnection.ApiConnection.Connected then
    DMConnection.ApiConnection.Open(@ConnectProc)
  else
    ConnectProc;
end;

procedure DisplayLoginView(AMessage: string);
begin
  AuthService.Logout;
  DMConnection.ApiConnection.Connected := False;
  if Assigned(FViewMain) then FViewMain.Free;                                   //aded for login-ing
  TFViewLogin.Display('body', @DisplayMainView, AMessage);
end;

procedure UnauthorizedAccessProc(AMessage: string);
begin
  DisplayLoginView(AMessage);
end;

procedure StartApplication;
begin
  if (not AuthService.Authenticated) or AuthService.TokenExpired then           //aded for login-ing
    DisplayLoginView                                                            //aded for login-ing
  else                                                                          //aded for login-ing
    DisplayMainView;
end;

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateFormDirect(TDMConnection, DMConnection);
  Application.Run;
  DMConnection.InitApp(@StartApplication, @UnauthorizedAccessProc);
end.
