unit View.Clients.List;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, Math,

  WEBLib.StdCtrls, Web, JS, WEBLib.Lists,

  App.Types, WEBLib.Controls, WEBLib.Grids, Data.DB,
  WEBLib.DBCtrls, WEBLib.DB, WEBLib.ExtCtrls,

  XData.Web.JsonDataset,
  XData.Web.Dataset,

  Grid.Plugins,
  Crud.Utils;

type
  TFViewClientsList = class(TForm)
    btNew: TButton;
    ClientsDataset: TXDataWebDataSet;
    ClientsDatasetcli_Id: TIntegerField;
    ClientsDatasetcli_Active: TStringField;
    ClientsDatasetcli_ClientName: TStringField;
    ClientsDatasetcli_Address: TStringField;
    ClientsDatasetcli_City: TStringField;
    ClientsDatasetcli_Province: TStringField;
    ClientsDatasetcli_PostalCode: TStringField;
    ClientsDatasetcli_Contact1FirstName: TStringField;
    ClientsDatasetcli_Contact1LastName: TStringField;
    ClientsDatasetcli_Contact1Telephone: TStringField;
    ClientsDatasetcli_Contact1Email: TStringField;
    ClientsDatasetcli_Contact2FirstName: TStringField;
    ClientsDatasetcli_Contact2LastName: TStringField;
    ClientsDatasetcli_Contact2Telephone: TStringField;
    ClientsDatasetcli_Contact2Email: TStringField;
    ClientsDatasetcli_ChildNotes: TStringField;
    ClientsDatasetcli_IsSeasonal: TStringField;
    ClientsDatasetcli_FullTimer: TStringField;
    ClientsDatasetcli_Notes: TStringField;
    ClientsDatasetcli_CreatedBy: TIntegerField;
    ClientsDatasetcli_CreatedDate: TDateTimeField;
    ClientsDatasetcli_LastModifiedBy: TIntegerField;
    ClientsDatasetcli_LastModifiedDate: TDateTimeField;
    WebDBTableControl1: TDBTableControl;
    WebDataSource: TDataSource;
    cbPageSize: TComboBox;
    edSearch: TEdit;
    lbPaginationInfo: TLabel;
    lcPaginator: TListControl;
    procedure WebFormCreate(Sender: TObject);
    procedure btNewClick(Sender: TObject);
    procedure WebDBTableControl1GetCellChildren(Sender: TObject; ACol,
      ARow: Integer; AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure cbPageSizeChange(Sender: TObject);
    procedure edSearchChange(Sender: TObject);
  private
    FEditProc: TEditProc;
    FGridPlugin: TGridPlugin;
  public
    class function CreateForm(AElementID: string; AShowEditProc: TEditProc): TForm;
  protected procedure LoadDFMValues; override; end;

var
  FViewClientsList: TFViewClientsList;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewClientsList }

procedure TFViewClientsList.cbPageSizeChange(Sender: TObject);
begin
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text), True);
end;

class function TFViewClientsList.CreateForm(AElementID: string;
  AShowEditProc: TEditProc): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewClientsList(AForm).FEditProc := AShowEditProc;
  end;

begin
  Application.CreateForm(TFViewClientsList, AElementID, Result, @AfterCreate);
end;

procedure TFViewClientsList.edSearchChange(Sender: TObject);
begin
  FGridPlugin.SetFilterText(edSearch.Text, True);
end;

procedure TFViewClientsList.btNewClick(Sender: TObject);
begin
  FEditProc( nil );
end;

procedure TFViewClientsList.WebDBTableControl1GetCellChildren(Sender: TObject;
  ACol, ARow: Integer; AField: TField; AValue: string;
  AElement: TJSHTMLElementRecord);
var
  RecordId: JSValue;

  procedure EditButtonClick(Sender: TObject);
  begin
    FEditProc(RecordId);
  end;

var
  Button: TButton;
begin
  if ARow = 0 then
    Exit;

  if WebDBTableControl1.Columns[ACol].Title = 'Actions' then
  begin
    RecordId := TCrudUtils.GetIdFromObject(
      ClientsDataset.Connection.Model.DefaultSchema.FindEntityType(ClientsDataset.EntitySetName),
      ClientsDataset.CurrentData
    );
    Button := TButton.Create(nil);
    Button.Caption := 'Edit';
    Button.OnClick := @EditButtonClick;
    Button.ElementClassName := 'btn btn-outline btn-default btn-xs';
    Button.ParentElement := AElement.element;
    Button.WidthStyle := TSizeStyle.ssAuto;
    Button.HeightStyle := TSizeStyle.ssAuto;
  end;
end;

procedure TFViewClientsList.WebFormCreate(Sender: TObject);
begin
  FGridPlugin := TGridPlugin.Create(
    WebDBTableControl1,
    ClientsDataset,
    lcPaginator,
    lbPaginationInfo
  );
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text));
  FGridPlugin.SetFilterText(edSearch.Text);
  FGridPlugin.Load;
end;

procedure TFViewClientsList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbPaginationInfo := TLabel.Create('view.clients.list.pagination.info');
  btNew := TButton.Create('view.clients.list.newbutton');
  WebDBTableControl1 := TDBTableControl.Create('view.clients.list.table');
  cbPageSize := TComboBox.Create('view.clients.list.cbPageSize');
  edSearch := TEdit.Create('view.clients.list.search');
  lcPaginator := TListControl.Create('view.clients.list.pagination');
  ClientsDataset := TXDataWebDataSet.Create(Self);
  ClientsDatasetcli_Id := TIntegerField.Create(Self);
  ClientsDatasetcli_Active := TStringField.Create(Self);
  ClientsDatasetcli_ClientName := TStringField.Create(Self);
  ClientsDatasetcli_Address := TStringField.Create(Self);
  ClientsDatasetcli_City := TStringField.Create(Self);
  ClientsDatasetcli_Province := TStringField.Create(Self);
  ClientsDatasetcli_PostalCode := TStringField.Create(Self);
  ClientsDatasetcli_Contact1FirstName := TStringField.Create(Self);
  ClientsDatasetcli_Contact1LastName := TStringField.Create(Self);
  ClientsDatasetcli_Contact1Telephone := TStringField.Create(Self);
  ClientsDatasetcli_Contact1Email := TStringField.Create(Self);
  ClientsDatasetcli_Contact2FirstName := TStringField.Create(Self);
  ClientsDatasetcli_Contact2LastName := TStringField.Create(Self);
  ClientsDatasetcli_Contact2Telephone := TStringField.Create(Self);
  ClientsDatasetcli_Contact2Email := TStringField.Create(Self);
  ClientsDatasetcli_ChildNotes := TStringField.Create(Self);
  ClientsDatasetcli_IsSeasonal := TStringField.Create(Self);
  ClientsDatasetcli_FullTimer := TStringField.Create(Self);
  ClientsDatasetcli_Notes := TStringField.Create(Self);
  ClientsDatasetcli_CreatedBy := TIntegerField.Create(Self);
  ClientsDatasetcli_CreatedDate := TDateTimeField.Create(Self);
  ClientsDatasetcli_LastModifiedBy := TIntegerField.Create(Self);
  ClientsDatasetcli_LastModifiedDate := TDateTimeField.Create(Self);
  WebDataSource := TDataSource.Create(Self);

  lbPaginationInfo.BeforeLoadDFMValues;
  btNew.BeforeLoadDFMValues;
  WebDBTableControl1.BeforeLoadDFMValues;
  cbPageSize.BeforeLoadDFMValues;
  edSearch.BeforeLoadDFMValues;
  lcPaginator.BeforeLoadDFMValues;
  ClientsDataset.BeforeLoadDFMValues;
  ClientsDatasetcli_Id.BeforeLoadDFMValues;
  ClientsDatasetcli_Active.BeforeLoadDFMValues;
  ClientsDatasetcli_ClientName.BeforeLoadDFMValues;
  ClientsDatasetcli_Address.BeforeLoadDFMValues;
  ClientsDatasetcli_City.BeforeLoadDFMValues;
  ClientsDatasetcli_Province.BeforeLoadDFMValues;
  ClientsDatasetcli_PostalCode.BeforeLoadDFMValues;
  ClientsDatasetcli_Contact1FirstName.BeforeLoadDFMValues;
  ClientsDatasetcli_Contact1LastName.BeforeLoadDFMValues;
  ClientsDatasetcli_Contact1Telephone.BeforeLoadDFMValues;
  ClientsDatasetcli_Contact1Email.BeforeLoadDFMValues;
  ClientsDatasetcli_Contact2FirstName.BeforeLoadDFMValues;
  ClientsDatasetcli_Contact2LastName.BeforeLoadDFMValues;
  ClientsDatasetcli_Contact2Telephone.BeforeLoadDFMValues;
  ClientsDatasetcli_Contact2Email.BeforeLoadDFMValues;
  ClientsDatasetcli_ChildNotes.BeforeLoadDFMValues;
  ClientsDatasetcli_IsSeasonal.BeforeLoadDFMValues;
  ClientsDatasetcli_FullTimer.BeforeLoadDFMValues;
  ClientsDatasetcli_Notes.BeforeLoadDFMValues;
  ClientsDatasetcli_CreatedBy.BeforeLoadDFMValues;
  ClientsDatasetcli_CreatedDate.BeforeLoadDFMValues;
  ClientsDatasetcli_LastModifiedBy.BeforeLoadDFMValues;
  ClientsDatasetcli_LastModifiedDate.BeforeLoadDFMValues;
  WebDataSource.BeforeLoadDFMValues;
  try
    Name := 'FViewClientsList';
    Width := 695;
    Height := 500;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbPaginationInfo.SetParentComponent(Self);
    lbPaginationInfo.Name := 'lbPaginationInfo';
    lbPaginationInfo.Left := 8;
    lbPaginationInfo.Top := 376;
    lbPaginationInfo.Width := 141;
    lbPaginationInfo.Height := 13;
    lbPaginationInfo.Caption := 'Showing 1 to 10 of 57 entries';
    lbPaginationInfo.HeightPercent := 100.000000000000000000;
    lbPaginationInfo.WidthPercent := 100.000000000000000000;
    btNew.SetParentComponent(Self);
    btNew.Name := 'btNew';
    btNew.Left := 8;
    btNew.Top := 8;
    btNew.Width := 50;
    btNew.Height := 25;
    btNew.Caption := 'New';
    btNew.HeightPercent := 100.000000000000000000;
    btNew.WidthPercent := 100.000000000000000000;
    SetEvent(btNew, Self, 'OnClick', 'btNewClick');
    WebDBTableControl1.SetParentComponent(Self);
    WebDBTableControl1.Name := 'WebDBTableControl1';
    WebDBTableControl1.Left := 8;
    WebDBTableControl1.Top := 94;
    WebDBTableControl1.Width := 521;
    WebDBTableControl1.Height := 265;
    WebDBTableControl1.ElementClassName := 'table-responsive';
    WebDBTableControl1.HeightPercent := 100.000000000000000000;
    WebDBTableControl1.WidthPercent := 100.000000000000000000;
    WebDBTableControl1.BorderColor := clSilver;
    WebDBTableControl1.ColHeader := False;
    WebDBTableControl1.ElementTableClassName := 'table table-striped table-bordered table-hover dataTable';
    SetEvent(WebDBTableControl1, Self, 'OnGetCellChildren', 'WebDBTableControl1GetCellChildren');
    WebDBTableControl1.Columns.Clear;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_Id';
      Title := 'cli_Id';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_Active';
      Title := 'cli_Active';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_ClientName';
      Title := 'cli_ClientName';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_Address';
      Title := 'cli_Address';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_City';
      Title := 'cli_City';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_Province';
      Title := 'cli_Province';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_PostalCode';
      Title := 'cli_PostalCode';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_Contact1FirstName';
      Title := 'cli_Contact1FirstName';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_Contact1LastName';
      Title := 'cli_Contact1LastName';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_Contact1Telephone';
      Title := 'cli_Contact1Telephone';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_Contact1Email';
      Title := 'cli_Contact1Email';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_Contact2FirstName';
      Title := 'cli_Contact2FirstName';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_Contact2LastName';
      Title := 'cli_Contact2LastName';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_Contact2Telephone';
      Title := 'cli_Contact2Telephone';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_Contact2Email';
      Title := 'cli_Contact2Email';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_ChildNotes';
      Title := 'cli_ChildNotes';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_IsSeasonal';
      Title := 'cli_IsSeasonal';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_FullTimer';
      Title := 'cli_FullTimer';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_Notes';
      Title := 'cli_Notes';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_CreatedBy';
      Title := 'cli_CreatedBy';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_CreatedDate';
      Title := 'cli_CreatedDate';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_LastModifiedBy';
      Title := 'cli_LastModifiedBy';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_LastModifiedDate';
      Title := 'cli_LastModifiedDate';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      Title := 'Actions';
    end;
    WebDBTableControl1.DataSource := WebDataSource;
    cbPageSize.SetParentComponent(Self);
    cbPageSize.Name := 'cbPageSize';
    cbPageSize.Left := 8;
    cbPageSize.Top := 67;
    cbPageSize.Width := 81;
    cbPageSize.Height := 21;
    cbPageSize.HeightPercent := 100.000000000000000000;
    cbPageSize.TabOrder := 2;
    cbPageSize.Text := '10';
    cbPageSize.WidthPercent := 100.000000000000000000;
    SetEvent(cbPageSize, Self, 'OnChange', 'cbPageSizeChange');
    cbPageSize.ItemIndex := 0;
    cbPageSize.Items.BeginUpdate;
    try
      cbPageSize.Items.Clear;
      cbPageSize.Items.Add('10');
      cbPageSize.Items.Add('25');
      cbPageSize.Items.Add('50');
      cbPageSize.Items.Add('100');
    finally
      cbPageSize.Items.EndUpdate;
    end;
    edSearch.SetParentComponent(Self);
    edSearch.Name := 'edSearch';
    edSearch.Left := 408;
    edSearch.Top := 67;
    edSearch.Width := 121;
    edSearch.Height := 21;
    edSearch.HeightPercent := 100.000000000000000000;
    edSearch.TabOrder := 3;
    edSearch.WidthPercent := 100.000000000000000000;
    SetEvent(edSearch, Self, 'OnChange', 'edSearchChange');
    lcPaginator.SetParentComponent(Self);
    lcPaginator.Name := 'lcPaginator';
    lcPaginator.Left := 240;
    lcPaginator.Top := 365;
    lcPaginator.Width := 289;
    lcPaginator.Height := 25;
    lcPaginator.HeightPercent := 100.000000000000000000;
    lcPaginator.WidthPercent := 100.000000000000000000;
    lcPaginator.DefaultItemClassName := 'page-item';
    lcPaginator.DefaultItemLinkClassName := 'page-link';
    lcPaginator.ElementListClassName := 'pagination';
    lcPaginator.Style := lsPagination;
    ClientsDataset.SetParentComponent(Self);
    ClientsDataset.Name := 'ClientsDataset';
    ClientsDataset.EntitySetName := 'Clients';
    ClientsDataset.Connection := DMConnection.ApiConnection;
    ClientsDataset.Left := 72;
    ClientsDataset.Top := 144;
    ClientsDatasetcli_Id.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Id.Name := 'ClientsDatasetcli_Id';
    ClientsDatasetcli_Id.FieldName := 'cli_Id';
    ClientsDatasetcli_Id.Required := True;
    ClientsDatasetcli_Active.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Active.Name := 'ClientsDatasetcli_Active';
    ClientsDatasetcli_Active.FieldName := 'cli_Active';
    ClientsDatasetcli_Active.Size := 255;
    ClientsDatasetcli_ClientName.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_ClientName.Name := 'ClientsDatasetcli_ClientName';
    ClientsDatasetcli_ClientName.FieldName := 'cli_ClientName';
    ClientsDatasetcli_ClientName.Size := 100;
    ClientsDatasetcli_Address.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Address.Name := 'ClientsDatasetcli_Address';
    ClientsDatasetcli_Address.FieldName := 'cli_Address';
    ClientsDatasetcli_Address.Size := 150;
    ClientsDatasetcli_City.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_City.Name := 'ClientsDatasetcli_City';
    ClientsDatasetcli_City.FieldName := 'cli_City';
    ClientsDatasetcli_City.Size := 100;
    ClientsDatasetcli_Province.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Province.Name := 'ClientsDatasetcli_Province';
    ClientsDatasetcli_Province.FieldName := 'cli_Province';
    ClientsDatasetcli_Province.Size := 50;
    ClientsDatasetcli_PostalCode.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_PostalCode.Name := 'ClientsDatasetcli_PostalCode';
    ClientsDatasetcli_PostalCode.FieldName := 'cli_PostalCode';
    ClientsDatasetcli_PostalCode.Size := 50;
    ClientsDatasetcli_Contact1FirstName.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Contact1FirstName.Name := 'ClientsDatasetcli_Contact1FirstName';
    ClientsDatasetcli_Contact1FirstName.FieldName := 'cli_Contact1FirstName';
    ClientsDatasetcli_Contact1FirstName.Size := 50;
    ClientsDatasetcli_Contact1LastName.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Contact1LastName.Name := 'ClientsDatasetcli_Contact1LastName';
    ClientsDatasetcli_Contact1LastName.FieldName := 'cli_Contact1LastName';
    ClientsDatasetcli_Contact1LastName.Size := 50;
    ClientsDatasetcli_Contact1Telephone.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Contact1Telephone.Name := 'ClientsDatasetcli_Contact1Telephone';
    ClientsDatasetcli_Contact1Telephone.FieldName := 'cli_Contact1Telephone';
    ClientsDatasetcli_Contact1Telephone.Size := 50;
    ClientsDatasetcli_Contact1Email.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Contact1Email.Name := 'ClientsDatasetcli_Contact1Email';
    ClientsDatasetcli_Contact1Email.FieldName := 'cli_Contact1Email';
    ClientsDatasetcli_Contact1Email.Size := 100;
    ClientsDatasetcli_Contact2FirstName.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Contact2FirstName.Name := 'ClientsDatasetcli_Contact2FirstName';
    ClientsDatasetcli_Contact2FirstName.FieldName := 'cli_Contact2FirstName';
    ClientsDatasetcli_Contact2FirstName.Size := 50;
    ClientsDatasetcli_Contact2LastName.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Contact2LastName.Name := 'ClientsDatasetcli_Contact2LastName';
    ClientsDatasetcli_Contact2LastName.FieldName := 'cli_Contact2LastName';
    ClientsDatasetcli_Contact2LastName.Size := 50;
    ClientsDatasetcli_Contact2Telephone.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Contact2Telephone.Name := 'ClientsDatasetcli_Contact2Telephone';
    ClientsDatasetcli_Contact2Telephone.FieldName := 'cli_Contact2Telephone';
    ClientsDatasetcli_Contact2Telephone.Size := 50;
    ClientsDatasetcli_Contact2Email.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Contact2Email.Name := 'ClientsDatasetcli_Contact2Email';
    ClientsDatasetcli_Contact2Email.FieldName := 'cli_Contact2Email';
    ClientsDatasetcli_Contact2Email.Size := 100;
    ClientsDatasetcli_ChildNotes.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_ChildNotes.Name := 'ClientsDatasetcli_ChildNotes';
    ClientsDatasetcli_ChildNotes.FieldName := 'cli_ChildNotes';
    ClientsDatasetcli_ChildNotes.Size := 200;
    ClientsDatasetcli_IsSeasonal.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_IsSeasonal.Name := 'ClientsDatasetcli_IsSeasonal';
    ClientsDatasetcli_IsSeasonal.FieldName := 'cli_IsSeasonal';
    ClientsDatasetcli_IsSeasonal.Size := 255;
    ClientsDatasetcli_FullTimer.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_FullTimer.Name := 'ClientsDatasetcli_FullTimer';
    ClientsDatasetcli_FullTimer.FieldName := 'cli_FullTimer';
    ClientsDatasetcli_FullTimer.Size := 255;
    ClientsDatasetcli_Notes.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_Notes.Name := 'ClientsDatasetcli_Notes';
    ClientsDatasetcli_Notes.FieldName := 'cli_Notes';
    ClientsDatasetcli_Notes.Size := 500;
    ClientsDatasetcli_CreatedBy.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_CreatedBy.Name := 'ClientsDatasetcli_CreatedBy';
    ClientsDatasetcli_CreatedBy.FieldName := 'cli_CreatedBy';
    ClientsDatasetcli_CreatedDate.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_CreatedDate.Name := 'ClientsDatasetcli_CreatedDate';
    ClientsDatasetcli_CreatedDate.FieldName := 'cli_CreatedDate';
    ClientsDatasetcli_LastModifiedBy.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_LastModifiedBy.Name := 'ClientsDatasetcli_LastModifiedBy';
    ClientsDatasetcli_LastModifiedBy.FieldName := 'cli_LastModifiedBy';
    ClientsDatasetcli_LastModifiedDate.SetParentComponent(ClientsDataset);
    ClientsDatasetcli_LastModifiedDate.Name := 'ClientsDatasetcli_LastModifiedDate';
    ClientsDatasetcli_LastModifiedDate.FieldName := 'cli_LastModifiedDate';
    WebDataSource.SetParentComponent(Self);
    WebDataSource.Name := 'WebDataSource';
    WebDataSource.DataSet := ClientsDataset;
    WebDataSource.Left := 72;
    WebDataSource.Top := 200;
  finally
    lbPaginationInfo.AfterLoadDFMValues;
    btNew.AfterLoadDFMValues;
    WebDBTableControl1.AfterLoadDFMValues;
    cbPageSize.AfterLoadDFMValues;
    edSearch.AfterLoadDFMValues;
    lcPaginator.AfterLoadDFMValues;
    ClientsDataset.AfterLoadDFMValues;
    ClientsDatasetcli_Id.AfterLoadDFMValues;
    ClientsDatasetcli_Active.AfterLoadDFMValues;
    ClientsDatasetcli_ClientName.AfterLoadDFMValues;
    ClientsDatasetcli_Address.AfterLoadDFMValues;
    ClientsDatasetcli_City.AfterLoadDFMValues;
    ClientsDatasetcli_Province.AfterLoadDFMValues;
    ClientsDatasetcli_PostalCode.AfterLoadDFMValues;
    ClientsDatasetcli_Contact1FirstName.AfterLoadDFMValues;
    ClientsDatasetcli_Contact1LastName.AfterLoadDFMValues;
    ClientsDatasetcli_Contact1Telephone.AfterLoadDFMValues;
    ClientsDatasetcli_Contact1Email.AfterLoadDFMValues;
    ClientsDatasetcli_Contact2FirstName.AfterLoadDFMValues;
    ClientsDatasetcli_Contact2LastName.AfterLoadDFMValues;
    ClientsDatasetcli_Contact2Telephone.AfterLoadDFMValues;
    ClientsDatasetcli_Contact2Email.AfterLoadDFMValues;
    ClientsDatasetcli_ChildNotes.AfterLoadDFMValues;
    ClientsDatasetcli_IsSeasonal.AfterLoadDFMValues;
    ClientsDatasetcli_FullTimer.AfterLoadDFMValues;
    ClientsDatasetcli_Notes.AfterLoadDFMValues;
    ClientsDatasetcli_CreatedBy.AfterLoadDFMValues;
    ClientsDatasetcli_CreatedDate.AfterLoadDFMValues;
    ClientsDatasetcli_LastModifiedBy.AfterLoadDFMValues;
    ClientsDatasetcli_LastModifiedDate.AfterLoadDFMValues;
    WebDataSource.AfterLoadDFMValues;
  end;
end;

end.
