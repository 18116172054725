unit View.Settings.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewSettingsEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbset_Id: TLabel;
    edset_Id: TDBEdit;
    lbset_SeasonActive: TLabel;
    edset_SeasonActive: TDBEdit;
    XDataClientSettingsGet: TXDataWebClient;
    XDataClientSettingsDelete: TXDataWebClient;
    SettingsDataset: TXDataWebDataSet;
    SettingsDatasetset_Id: TIntegerField;
    SettingsDatasetset_SeasonActive: TIntegerField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientSettingsGetLoad(Response: TXDataClientResponse);
    procedure XDataClientSettingsDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientSettingsDeleteError(Error: TXDataClientError);
    procedure SettingsDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewSettingsEdit: TFViewSettingsEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewSettingsEdit }

procedure TFViewSettingsEdit.btSaveClick(Sender: TObject);
begin
  if SettingsDataset.State in dsEditModes then
  begin
        SettingsDataset.Post;
    SettingsDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewSettingsEdit.btDeleteClick(Sender: TObject);
begin
  if SettingsDataset.State in dsEditModes then
    SettingsDataset.Post;
  XDataClientSettingsDelete.Delete(SettingsDataset.EntitySetName,
    TJSObject(SettingsDataset.CurrentData));
end;


class function TFViewSettingsEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewSettingsEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewSettingsEdit.InsertEntity;
begin
  SettingsDataset.Close;
  SettingsDataset.SetJsonData('{}');
  SettingsDataset.Open;
  SettingsDataset.Insert;
end;

class function TFViewSettingsEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewSettingsEdit(AForm).FInserting := AInserting;
    TFViewSettingsEdit(AForm).FId := AId;
    TFViewSettingsEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewSettingsEdit(AForm).InsertEntity
    else
      TFViewSettingsEdit(AForm).LoadEntity;
    TFViewSettingsEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewSettingsEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewSettingsEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    SettingsDataset.Connection.Model.DefaultSchema.FindEntityType(
      SettingsDataset.EntitySetName
    )
  );
  XDataClientSettingsGet.Get(SettingsDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewSettingsEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewSettingsEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewSettingsEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewSettingsEdit.XDataClientSettingsGetLoad(Response: TXDataClientResponse);
begin
  SettingsDataset.Close;
  SettingsDataset.SetJsonData(Response.Result);
  SettingsDataset.Open;
  
  SettingsDataset.Edit;  
end;

procedure TFViewSettingsEdit.XDataClientSettingsDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewSettingsEdit.XDataClientSettingsDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewSettingsEdit.SettingsDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewSettingsEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewSettingsEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbset_Id := TLabel.Create('view.settings.form.lbset_Id');
  lbset_SeasonActive := TLabel.Create('view.settings.form.lbset_SeasonActive');
  pnlMessage := TPanel.Create('view.settings.form.message');
  lbMessage := TLabel.Create('view.settings.form.message.label');
  btCloseMessage := TButton.Create('view.settings.form.message.button');
  btCancel := TButton.Create('view.settings.form.btBack');
  btSave := TButton.Create('view.settings.form.btSave');
  btDelete := TButton.Create('view.settings.form.btDelete');
  edset_Id := TDBEdit.Create('view.settings.form.edset_Id');
  edset_SeasonActive := TDBEdit.Create('view.settings.form.edset_SeasonActive');
  DataSource := TDataSource.Create(Self);
  SettingsDataset := TXDataWebDataSet.Create(Self);
  SettingsDatasetset_Id := TIntegerField.Create(Self);
  SettingsDatasetset_SeasonActive := TIntegerField.Create(Self);
  XDataClientSettingsGet := TXDataWebClient.Create(Self);
  XDataClientSettingsDelete := TXDataWebClient.Create(Self);

  lbset_Id.BeforeLoadDFMValues;
  lbset_SeasonActive.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edset_Id.BeforeLoadDFMValues;
  edset_SeasonActive.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  SettingsDataset.BeforeLoadDFMValues;
  SettingsDatasetset_Id.BeforeLoadDFMValues;
  SettingsDatasetset_SeasonActive.BeforeLoadDFMValues;
  XDataClientSettingsGet.BeforeLoadDFMValues;
  XDataClientSettingsDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewSettingsEdit';
    Width := 593;
    Height := 319;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbset_Id.SetParentComponent(Self);
    lbset_Id.Name := 'lbset_Id';
    lbset_Id.Left := 24;
    lbset_Id.Top := 192;
    lbset_Id.Width := 35;
    lbset_Id.Height := 13;
    lbset_Id.Caption := 'set_Id:';
    lbset_Id.HeightPercent := 100.000000000000000000;
    lbset_Id.WidthPercent := 100.000000000000000000;
    lbset_SeasonActive.SetParentComponent(Self);
    lbset_SeasonActive.Name := 'lbset_SeasonActive';
    lbset_SeasonActive.Left := 24;
    lbset_SeasonActive.Top := 219;
    lbset_SeasonActive.Width := 90;
    lbset_SeasonActive.Height := 13;
    lbset_SeasonActive.Caption := 'set_SeasonActive:';
    lbset_SeasonActive.HeightPercent := 100.000000000000000000;
    lbset_SeasonActive.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 259;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 259;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 259;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edset_Id.SetParentComponent(Self);
    edset_Id.Name := 'edset_Id';
    edset_Id.Left := 221;
    edset_Id.Top := 189;
    edset_Id.Width := 200;
    edset_Id.Height := 21;
    edset_Id.HeightPercent := 100.000000000000000000;
    edset_Id.TabOrder := 6;
    edset_Id.Text := 'edset_Id';
    edset_Id.WidthPercent := 100.000000000000000000;
    edset_Id.DataField := 'set_Id';
    edset_Id.DataSource := DataSource;
    edset_SeasonActive.SetParentComponent(Self);
    edset_SeasonActive.Name := 'edset_SeasonActive';
    edset_SeasonActive.Left := 221;
    edset_SeasonActive.Top := 216;
    edset_SeasonActive.Width := 200;
    edset_SeasonActive.Height := 21;
    edset_SeasonActive.HeightPercent := 100.000000000000000000;
    edset_SeasonActive.TabOrder := 7;
    edset_SeasonActive.Text := 'edset_SeasonActive';
    edset_SeasonActive.WidthPercent := 100.000000000000000000;
    edset_SeasonActive.DataField := 'set_SeasonActive';
    edset_SeasonActive.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := SettingsDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    SettingsDataset.SetParentComponent(Self);
    SettingsDataset.Name := 'SettingsDataset';
    SettingsDataset.AfterApplyUpdates := SettingsDatasetAfterApplyUpdates;
    SettingsDataset.EntitySetName := 'Settings';
    SettingsDataset.Connection := DMConnection.ApiConnection;
    SettingsDataset.Left := 176;
    SettingsDataset.Top := 8;
    SettingsDatasetset_Id.SetParentComponent(SettingsDataset);
    SettingsDatasetset_Id.Name := 'SettingsDatasetset_Id';
    SettingsDatasetset_Id.FieldName := 'set_Id';
    SettingsDatasetset_Id.Required := True;
    SettingsDatasetset_SeasonActive.SetParentComponent(SettingsDataset);
    SettingsDatasetset_SeasonActive.Name := 'SettingsDatasetset_SeasonActive';
    SettingsDatasetset_SeasonActive.FieldName := 'set_SeasonActive';
    XDataClientSettingsGet.SetParentComponent(Self);
    XDataClientSettingsGet.Name := 'XDataClientSettingsGet';
    XDataClientSettingsGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientSettingsGet, Self, 'OnLoad', 'XDataClientSettingsGetLoad');
    XDataClientSettingsGet.Left := 64;
    XDataClientSettingsGet.Top := 8;
    XDataClientSettingsDelete.SetParentComponent(Self);
    XDataClientSettingsDelete.Name := 'XDataClientSettingsDelete';
    XDataClientSettingsDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientSettingsDelete, Self, 'OnLoad', 'XDataClientSettingsDeleteLoad');
    SetEvent(XDataClientSettingsDelete, Self, 'OnError', 'XDataClientSettingsDeleteError');
    XDataClientSettingsDelete.Left := 64;
    XDataClientSettingsDelete.Top := 56;
  finally
    lbset_Id.AfterLoadDFMValues;
    lbset_SeasonActive.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edset_Id.AfterLoadDFMValues;
    edset_SeasonActive.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    SettingsDataset.AfterLoadDFMValues;
    SettingsDatasetset_Id.AfterLoadDFMValues;
    SettingsDatasetset_SeasonActive.AfterLoadDFMValues;
    XDataClientSettingsGet.AfterLoadDFMValues;
    XDataClientSettingsDelete.AfterLoadDFMValues;
  end;
end;

end.
