unit View.Tokens.List;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, Math,

  WEBLib.StdCtrls, Web, JS, WEBLib.Lists,

  App.Types, WEBLib.Controls, WEBLib.Grids, Data.DB,
  XData.Web.Dataset, WEBLib.DBCtrls, WEBLib.DB, WEBLib.ExtCtrls,

  XData.Web.JsonDataset,

  Grid.Plugins,
  Crud.Utils;

type
  TFViewTokensList = class(TForm)
    btNew: TButton;
    TokensDataset: TXDataWebDataSet;
    TokensDatasettok_Id: TIntegerField;
    TokensDatasetusr_Id: TIntegerField;
    TokensDatasettok_Token: TStringField;
    TokensDatasettok_ValidUntil: TDateTimeField;
    TokensDatasettok_ValidQty: TIntegerField;
    WebDBTableControl1: TDBTableControl;
    WebDataSource: TDataSource;
    cbPageSize: TComboBox;
    edSearch: TEdit;
    lbPaginationInfo: TLabel;
    lcPaginator: TListControl;
    procedure WebFormCreate(Sender: TObject);
    procedure btNewClick(Sender: TObject);
    procedure WebDBTableControl1GetCellChildren(Sender: TObject; ACol,
      ARow: Integer; AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure cbPageSizeChange(Sender: TObject);
    procedure edSearchChange(Sender: TObject);
  private
    FEditProc: TEditProc;
    FGridPlugin: TGridPlugin;
  public
    class function CreateForm(AElementID: string; AShowEditProc: TEditProc): TForm;
  protected procedure LoadDFMValues; override; end;

var
  FViewTokensList: TFViewTokensList;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewTokensList }

procedure TFViewTokensList.cbPageSizeChange(Sender: TObject);
begin
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text), True);
end;

class function TFViewTokensList.CreateForm(AElementID: string;
  AShowEditProc: TEditProc): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewTokensList(AForm).FEditProc := AShowEditProc;
  end;

begin
  Application.CreateForm(TFViewTokensList, AElementID, Result, @AfterCreate);
end;

procedure TFViewTokensList.edSearchChange(Sender: TObject);
begin
  FGridPlugin.SetFilterText(edSearch.Text, True);
end;

procedure TFViewTokensList.btNewClick(Sender: TObject);
begin
  FEditProc( nil );
end;

procedure TFViewTokensList.WebDBTableControl1GetCellChildren(Sender: TObject;
  ACol, ARow: Integer; AField: TField; AValue: string;
  AElement: TJSHTMLElementRecord);
var
  RecordId: JSValue;

  procedure EditButtonClick(Sender: TObject);
  begin
    FEditProc(RecordId);
  end;

var
  Button: TButton;
begin
  if ARow = 0 then
    Exit;

  if WebDBTableControl1.Columns[ACol].Title = 'Actions' then
  begin
    RecordId := TCrudUtils.GetIdFromObject(
      TokensDataset.Connection.Model.DefaultSchema.FindEntityType(TokensDataset.EntitySetName),
      TokensDataset.CurrentData
    );
    Button := TButton.Create(nil);
    Button.Caption := 'Edit';
    Button.OnClick := @EditButtonClick;
    Button.ElementClassName := 'btn btn-outline btn-default btn-xs';
    Button.ParentElement := AElement.element;
    Button.WidthStyle := TSizeStyle.ssAuto;
    Button.HeightStyle := TSizeStyle.ssAuto;
  end;
end;

procedure TFViewTokensList.WebFormCreate(Sender: TObject);
begin
  FGridPlugin := TGridPlugin.Create(
    WebDBTableControl1,
    TokensDataset,
    lcPaginator,
    lbPaginationInfo
  );
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text));
  FGridPlugin.SetFilterText(edSearch.Text);
  FGridPlugin.Load;
end;

procedure TFViewTokensList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbPaginationInfo := TLabel.Create('view.tokens.list.pagination.info');
  btNew := TButton.Create('view.tokens.list.newbutton');
  WebDBTableControl1 := TDBTableControl.Create('view.tokens.list.table');
  cbPageSize := TComboBox.Create('view.tokens.list.cbPageSize');
  edSearch := TEdit.Create('view.tokens.list.search');
  lcPaginator := TListControl.Create('view.tokens.list.pagination');
  TokensDataset := TXDataWebDataSet.Create(Self);
  TokensDatasettok_Id := TIntegerField.Create(Self);
  TokensDatasetusr_Id := TIntegerField.Create(Self);
  TokensDatasettok_Token := TStringField.Create(Self);
  TokensDatasettok_ValidUntil := TDateTimeField.Create(Self);
  TokensDatasettok_ValidQty := TIntegerField.Create(Self);
  WebDataSource := TDataSource.Create(Self);

  lbPaginationInfo.BeforeLoadDFMValues;
  btNew.BeforeLoadDFMValues;
  WebDBTableControl1.BeforeLoadDFMValues;
  cbPageSize.BeforeLoadDFMValues;
  edSearch.BeforeLoadDFMValues;
  lcPaginator.BeforeLoadDFMValues;
  TokensDataset.BeforeLoadDFMValues;
  TokensDatasettok_Id.BeforeLoadDFMValues;
  TokensDatasetusr_Id.BeforeLoadDFMValues;
  TokensDatasettok_Token.BeforeLoadDFMValues;
  TokensDatasettok_ValidUntil.BeforeLoadDFMValues;
  TokensDatasettok_ValidQty.BeforeLoadDFMValues;
  WebDataSource.BeforeLoadDFMValues;
  try
    Name := 'FViewTokensList';
    Width := 695;
    Height := 500;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbPaginationInfo.SetParentComponent(Self);
    lbPaginationInfo.Name := 'lbPaginationInfo';
    lbPaginationInfo.Left := 8;
    lbPaginationInfo.Top := 376;
    lbPaginationInfo.Width := 141;
    lbPaginationInfo.Height := 13;
    lbPaginationInfo.Caption := 'Showing 1 to 10 of 57 entries';
    lbPaginationInfo.HeightPercent := 100.000000000000000000;
    lbPaginationInfo.WidthPercent := 100.000000000000000000;
    btNew.SetParentComponent(Self);
    btNew.Name := 'btNew';
    btNew.Left := 8;
    btNew.Top := 8;
    btNew.Width := 50;
    btNew.Height := 25;
    btNew.Caption := 'New';
    btNew.HeightPercent := 100.000000000000000000;
    btNew.WidthPercent := 100.000000000000000000;
    SetEvent(btNew, Self, 'OnClick', 'btNewClick');
    WebDBTableControl1.SetParentComponent(Self);
    WebDBTableControl1.Name := 'WebDBTableControl1';
    WebDBTableControl1.Left := 8;
    WebDBTableControl1.Top := 94;
    WebDBTableControl1.Width := 521;
    WebDBTableControl1.Height := 265;
    WebDBTableControl1.ElementClassName := 'table-responsive';
    WebDBTableControl1.HeightPercent := 100.000000000000000000;
    WebDBTableControl1.WidthPercent := 100.000000000000000000;
    WebDBTableControl1.BorderColor := clSilver;
    WebDBTableControl1.ColHeader := False;
    WebDBTableControl1.ElementTableClassName := 'table table-striped table-bordered table-hover dataTable';
    SetEvent(WebDBTableControl1, Self, 'OnGetCellChildren', 'WebDBTableControl1GetCellChildren');
    WebDBTableControl1.Columns.Clear;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'tok_Id';
      Title := 'tok_Id';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'usr_Id';
      Title := 'usr_Id';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'tok_Token';
      Title := 'tok_Token';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'tok_ValidUntil';
      Title := 'tok_ValidUntil';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'tok_ValidQty';
      Title := 'tok_ValidQty';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      Title := 'Actions';
    end;
    WebDBTableControl1.DataSource := WebDataSource;
    cbPageSize.SetParentComponent(Self);
    cbPageSize.Name := 'cbPageSize';
    cbPageSize.Left := 8;
    cbPageSize.Top := 67;
    cbPageSize.Width := 81;
    cbPageSize.Height := 21;
    cbPageSize.HeightPercent := 100.000000000000000000;
    cbPageSize.TabOrder := 2;
    cbPageSize.Text := '10';
    cbPageSize.WidthPercent := 100.000000000000000000;
    SetEvent(cbPageSize, Self, 'OnChange', 'cbPageSizeChange');
    cbPageSize.ItemIndex := 0;
    cbPageSize.Items.BeginUpdate;
    try
      cbPageSize.Items.Clear;
      cbPageSize.Items.Add('10');
      cbPageSize.Items.Add('25');
      cbPageSize.Items.Add('50');
      cbPageSize.Items.Add('100');
    finally
      cbPageSize.Items.EndUpdate;
    end;
    edSearch.SetParentComponent(Self);
    edSearch.Name := 'edSearch';
    edSearch.Left := 408;
    edSearch.Top := 67;
    edSearch.Width := 121;
    edSearch.Height := 21;
    edSearch.HeightPercent := 100.000000000000000000;
    edSearch.TabOrder := 3;
    edSearch.WidthPercent := 100.000000000000000000;
    SetEvent(edSearch, Self, 'OnChange', 'edSearchChange');
    lcPaginator.SetParentComponent(Self);
    lcPaginator.Name := 'lcPaginator';
    lcPaginator.Left := 240;
    lcPaginator.Top := 365;
    lcPaginator.Width := 289;
    lcPaginator.Height := 25;
    lcPaginator.HeightPercent := 100.000000000000000000;
    lcPaginator.WidthPercent := 100.000000000000000000;
    lcPaginator.DefaultItemClassName := 'page-item';
    lcPaginator.DefaultItemLinkClassName := 'page-link';
    lcPaginator.ElementListClassName := 'pagination';
    lcPaginator.Style := lsPagination;
    TokensDataset.SetParentComponent(Self);
    TokensDataset.Name := 'TokensDataset';
    TokensDataset.EntitySetName := 'Tokens';
    TokensDataset.Connection := DMConnection.ApiConnection;
    TokensDataset.Left := 72;
    TokensDataset.Top := 144;
    TokensDatasettok_Id.SetParentComponent(TokensDataset);
    TokensDatasettok_Id.Name := 'TokensDatasettok_Id';
    TokensDatasettok_Id.FieldName := 'tok_Id';
    TokensDatasettok_Id.Required := True;
    TokensDatasetusr_Id.SetParentComponent(TokensDataset);
    TokensDatasetusr_Id.Name := 'TokensDatasetusr_Id';
    TokensDatasetusr_Id.FieldName := 'usr_Id';
    TokensDatasettok_Token.SetParentComponent(TokensDataset);
    TokensDatasettok_Token.Name := 'TokensDatasettok_Token';
    TokensDatasettok_Token.FieldName := 'tok_Token';
    TokensDatasettok_Token.Required := True;
    TokensDatasettok_Token.Size := 36;
    TokensDatasettok_ValidUntil.SetParentComponent(TokensDataset);
    TokensDatasettok_ValidUntil.Name := 'TokensDatasettok_ValidUntil';
    TokensDatasettok_ValidUntil.FieldName := 'tok_ValidUntil';
    TokensDatasettok_ValidUntil.Required := True;
    TokensDatasettok_ValidQty.SetParentComponent(TokensDataset);
    TokensDatasettok_ValidQty.Name := 'TokensDatasettok_ValidQty';
    TokensDatasettok_ValidQty.FieldName := 'tok_ValidQty';
    TokensDatasettok_ValidQty.Required := True;
    WebDataSource.SetParentComponent(Self);
    WebDataSource.Name := 'WebDataSource';
    WebDataSource.DataSet := TokensDataset;
    WebDataSource.Left := 72;
    WebDataSource.Top := 200;
  finally
    lbPaginationInfo.AfterLoadDFMValues;
    btNew.AfterLoadDFMValues;
    WebDBTableControl1.AfterLoadDFMValues;
    cbPageSize.AfterLoadDFMValues;
    edSearch.AfterLoadDFMValues;
    lcPaginator.AfterLoadDFMValues;
    TokensDataset.AfterLoadDFMValues;
    TokensDatasettok_Id.AfterLoadDFMValues;
    TokensDatasetusr_Id.AfterLoadDFMValues;
    TokensDatasettok_Token.AfterLoadDFMValues;
    TokensDatasettok_ValidUntil.AfterLoadDFMValues;
    TokensDatasettok_ValidQty.AfterLoadDFMValues;
    WebDataSource.AfterLoadDFMValues;
  end;
end;

end.
