unit View.Login;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Controls, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  XData.Web.Connection, WEBLib.ExtCtrls,
  App.Types,
  Container.Connection, WEBLib.Buttons;

type
  TFViewLogin = class(TForm)
    WebLabel1: TLabel;
    edUser: TEdit;
    edPassword: TEdit;
    btLogin: TButton;
    pnlMessage: TPanel;
    lbMessage: TLabel;
    WebButton1: TButton;
    WebSpeedButton1: TSpeedButton;
    procedure btLoginClick(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure edUserKeyPress(Sender: TObject; var Key: Char);
    procedure edPasswordKeyPress(Sender: TObject; var Key: Char);
  private
    FLoginProc: TSuccessProc;
    FMessage: string;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
  public
    class procedure Display(AElementId: string; LoginProc: TSuccessProc); overload;
    class procedure Display(AElementId: string; LoginProc: TSuccessProc;
      AMsg: string); overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewLogin: TFViewLogin;

implementation

uses
  Auth.Service,
  View.ErrorPage;

{$R *.dfm}

procedure TFViewLogin.btLoginClick(Sender: TObject);

  procedure LoginSuccess;
  begin
    FLoginProc;
  end;

  procedure LoginError(AMsg: string);
  begin
    ShowNotification('Login Error: ' + AMsg);
  end;

begin
  AuthService.Login(
    edUser.Text, edPassword.Text,
    @LoginSuccess,
    @LoginError
  );
end;

class procedure TFViewLogin.Display(AElementId: string; LoginProc: TSuccessProc);
begin
  TFViewLogin.Display(AElementId, LoginProc, '');
end;

class procedure TFViewLogin.Display(AElementId: string; LoginProc: TSuccessProc;
  AMsg: string);

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewLogin(AForm).FMessage := AMsg;
    TFViewLogin(AForm).FLoginProc := LoginProc;
  end;

begin
  if Assigned(FViewLogin) then
    FViewLogin.Free;
  FViewLogin := TFViewLogin.CreateNew(AElementId, @AfterCreate);
end;

procedure TFViewLogin.edUserKeyPress(Sender: TObject; var Key: Char);
begin
  if Key = #13 then
    edPassword.SetFocus;
end;

procedure TFViewLogin.edPasswordKeyPress(Sender: TObject; var Key: Char);
begin
  if Key = #13 then
    btLogin.Click;
end;

procedure TFViewLogin.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewLogin.ShowNotification(Notification: string);
begin
  if Notification <> '' then
  begin
    lbMessage.Caption := Notification;
    pnlMessage.ElementHandle.hidden := False;
  end;
end;

procedure TFViewLogin.WebButton1Click(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewLogin.WebFormCreate(Sender: TObject);
begin
  if FMessage <> '' then
    ShowNotification(FMessage)
  else
    HiddenNotification;
end;

procedure TFViewLogin.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create('view.login.title');
  edUser := TEdit.Create('view.login.eduser');
  edPassword := TEdit.Create('view.login.edpassword');
  btLogin := TButton.Create('view.login.btlogin');
  pnlMessage := TPanel.Create('view.login.message');
  lbMessage := TLabel.Create('view.login.message.label');
  WebButton1 := TButton.Create('view.login.message.button');
  WebSpeedButton1 := TSpeedButton.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  edUser.BeforeLoadDFMValues;
  edPassword.BeforeLoadDFMValues;
  btLogin.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebSpeedButton1.BeforeLoadDFMValues;
  try
    Name := 'FViewLogin';
    Width := 640;
    Height := 480;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 240;
    WebLabel1.Top := 112;
    WebLabel1.Width := 67;
    WebLabel1.Height := 13;
    WebLabel1.Caption := 'Please Sign In';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.Transparent := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    edUser.SetParentComponent(Self);
    edUser.Name := 'edUser';
    edUser.Left := 240;
    edUser.Top := 136;
    edUser.Width := 121;
    edUser.Height := 21;
    edUser.HeightPercent := 100.000000000000000000;
    edUser.TextHint := 'User';
    edUser.WidthPercent := 100.000000000000000000;
    SetEvent(edUser, Self, 'OnKeyPress', 'edUserKeyPress');
    edPassword.SetParentComponent(Self);
    edPassword.Name := 'edPassword';
    edPassword.Left := 240;
    edPassword.Top := 163;
    edPassword.Width := 121;
    edPassword.Height := 21;
    edPassword.HeightPercent := 100.000000000000000000;
    edPassword.PasswordChar := '*';
    edPassword.TabOrder := 1;
    edPassword.TextHint := 'Password';
    edPassword.WidthPercent := 100.000000000000000000;
    SetEvent(edPassword, Self, 'OnKeyPress', 'edPasswordKeyPress');
    btLogin.SetParentComponent(Self);
    btLogin.Name := 'btLogin';
    btLogin.Left := 240;
    btLogin.Top := 190;
    btLogin.Width := 121;
    btLogin.Height := 25;
    btLogin.Caption := '<i class="material-icons">lock</i><span style="vertical-align:super;">Login</span>';
    btLogin.HeightPercent := 100.000000000000000000;
    btLogin.TabOrder := 2;
    btLogin.WidthPercent := 100.000000000000000000;
    SetEvent(btLogin, Self, 'OnClick', 'btLoginClick');
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 240;
    pnlMessage.Top := 32;
    pnlMessage.Width := 121;
    pnlMessage.Height := 33;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 11;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    WebButton1.SetParentComponent(pnlMessage);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 96;
    WebButton1.Top := 3;
    WebButton1.Width := 22;
    WebButton1.Height := 25;
    WebButton1.Caption := 'x';
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebSpeedButton1.SetParentComponent(Self);
    WebSpeedButton1.Name := 'WebSpeedButton1';
    WebSpeedButton1.Left := 47;
    WebSpeedButton1.Top := 86;
    WebSpeedButton1.Width := 81;
    WebSpeedButton1.Height := 24;
    WebSpeedButton1.Caption := 'Login';
    WebSpeedButton1.Color := clNone;
    WebSpeedButton1.ElementClassName := 'btn btn-lg';
    WebSpeedButton1.HeightPercent := 100.000000000000000000;
    WebSpeedButton1.MaterialGlyph := 'lock';
    WebSpeedButton1.TabOrder := 4;
    WebSpeedButton1.Visible := False;
    WebSpeedButton1.WidthPercent := 100.000000000000000000;
  finally
    WebLabel1.AfterLoadDFMValues;
    edUser.AfterLoadDFMValues;
    edPassword.AfterLoadDFMValues;
    btLogin.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebSpeedButton1.AfterLoadDFMValues;
  end;
end;

end.
