unit View.AnnounceList.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewAnnounceListEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbal_Id: TLabel;
    edal_Id: TDBEdit;
    lbei_ID: TLabel;
    edei_ID: TDBEdit;
    lbal_PublishFrom: TLabel;
    edal_PublishFrom: TDBEdit;
    lbal_PublishTo: TLabel;
    edal_PublishTo: TDBEdit;
    XDataClientAnnounceListGet: TXDataWebClient;
    XDataClientAnnounceListDelete: TXDataWebClient;
    AnnounceListDataset: TXDataWebDataSet;
    AnnounceListDatasetal_Id: TIntegerField;
    AnnounceListDatasetei_ID: TIntegerField;
    AnnounceListDatasetal_PublishFrom: TDateTimeField;
    AnnounceListDatasetal_PublishTo: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientAnnounceListGetLoad(Response: TXDataClientResponse);
    procedure XDataClientAnnounceListDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientAnnounceListDeleteError(Error: TXDataClientError);
    procedure AnnounceListDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewAnnounceListEdit: TFViewAnnounceListEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewAnnounceListEdit }

procedure TFViewAnnounceListEdit.btSaveClick(Sender: TObject);
begin
  if AnnounceListDataset.State in dsEditModes then
  begin
        AnnounceListDataset.Post;
    AnnounceListDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewAnnounceListEdit.btDeleteClick(Sender: TObject);
begin
  if AnnounceListDataset.State in dsEditModes then
    AnnounceListDataset.Post;
  XDataClientAnnounceListDelete.Delete(AnnounceListDataset.EntitySetName,
    TJSObject(AnnounceListDataset.CurrentData));
end;


class function TFViewAnnounceListEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewAnnounceListEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewAnnounceListEdit.InsertEntity;
begin
  AnnounceListDataset.Close;
  AnnounceListDataset.SetJsonData('{}');
  AnnounceListDataset.Open;
  AnnounceListDataset.Insert;
end;

class function TFViewAnnounceListEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewAnnounceListEdit(AForm).FInserting := AInserting;
    TFViewAnnounceListEdit(AForm).FId := AId;
    TFViewAnnounceListEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewAnnounceListEdit(AForm).InsertEntity
    else
      TFViewAnnounceListEdit(AForm).LoadEntity;
    TFViewAnnounceListEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewAnnounceListEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewAnnounceListEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    AnnounceListDataset.Connection.Model.DefaultSchema.FindEntityType(
      AnnounceListDataset.EntitySetName
    )
  );
  XDataClientAnnounceListGet.Get(AnnounceListDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewAnnounceListEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewAnnounceListEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewAnnounceListEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewAnnounceListEdit.XDataClientAnnounceListGetLoad(Response: TXDataClientResponse);
begin
  AnnounceListDataset.Close;
  AnnounceListDataset.SetJsonData(Response.Result);
  AnnounceListDataset.Open;
  
  AnnounceListDataset.Edit;  
end;

procedure TFViewAnnounceListEdit.XDataClientAnnounceListDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewAnnounceListEdit.XDataClientAnnounceListDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewAnnounceListEdit.AnnounceListDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewAnnounceListEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewAnnounceListEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbal_Id := TLabel.Create('view.announcelist.form.lbal_Id');
  lbei_ID := TLabel.Create('view.announcelist.form.lbei_ID');
  lbal_PublishFrom := TLabel.Create('view.announcelist.form.lbal_PublishFrom');
  lbal_PublishTo := TLabel.Create('view.announcelist.form.lbal_PublishTo');
  pnlMessage := TPanel.Create('view.announcelist.form.message');
  lbMessage := TLabel.Create('view.announcelist.form.message.label');
  btCloseMessage := TButton.Create('view.announcelist.form.message.button');
  btCancel := TButton.Create('view.announcelist.form.btBack');
  btSave := TButton.Create('view.announcelist.form.btSave');
  btDelete := TButton.Create('view.announcelist.form.btDelete');
  edal_Id := TDBEdit.Create('view.announcelist.form.edal_Id');
  edei_ID := TDBEdit.Create('view.announcelist.form.edei_ID');
  edal_PublishFrom := TDBEdit.Create('view.announcelist.form.edal_PublishFrom');
  edal_PublishTo := TDBEdit.Create('view.announcelist.form.edal_PublishTo');
  DataSource := TDataSource.Create(Self);
  AnnounceListDataset := TXDataWebDataSet.Create(Self);
  AnnounceListDatasetal_Id := TIntegerField.Create(Self);
  AnnounceListDatasetei_ID := TIntegerField.Create(Self);
  AnnounceListDatasetal_PublishFrom := TDateTimeField.Create(Self);
  AnnounceListDatasetal_PublishTo := TDateTimeField.Create(Self);
  XDataClientAnnounceListGet := TXDataWebClient.Create(Self);
  XDataClientAnnounceListDelete := TXDataWebClient.Create(Self);

  lbal_Id.BeforeLoadDFMValues;
  lbei_ID.BeforeLoadDFMValues;
  lbal_PublishFrom.BeforeLoadDFMValues;
  lbal_PublishTo.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edal_Id.BeforeLoadDFMValues;
  edei_ID.BeforeLoadDFMValues;
  edal_PublishFrom.BeforeLoadDFMValues;
  edal_PublishTo.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  AnnounceListDataset.BeforeLoadDFMValues;
  AnnounceListDatasetal_Id.BeforeLoadDFMValues;
  AnnounceListDatasetei_ID.BeforeLoadDFMValues;
  AnnounceListDatasetal_PublishFrom.BeforeLoadDFMValues;
  AnnounceListDatasetal_PublishTo.BeforeLoadDFMValues;
  XDataClientAnnounceListGet.BeforeLoadDFMValues;
  XDataClientAnnounceListDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewAnnounceListEdit';
    Width := 593;
    Height := 373;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbal_Id.SetParentComponent(Self);
    lbal_Id.Name := 'lbal_Id';
    lbal_Id.Left := 24;
    lbal_Id.Top := 192;
    lbal_Id.Width := 28;
    lbal_Id.Height := 13;
    lbal_Id.Caption := 'al_Id:';
    lbal_Id.HeightPercent := 100.000000000000000000;
    lbal_Id.WidthPercent := 100.000000000000000000;
    lbei_ID.SetParentComponent(Self);
    lbei_ID.Name := 'lbei_ID';
    lbei_ID.Left := 24;
    lbei_ID.Top := 219;
    lbei_ID.Width := 29;
    lbei_ID.Height := 13;
    lbei_ID.Caption := 'ei_ID:';
    lbei_ID.HeightPercent := 100.000000000000000000;
    lbei_ID.WidthPercent := 100.000000000000000000;
    lbal_PublishFrom.SetParentComponent(Self);
    lbal_PublishFrom.Name := 'lbal_PublishFrom';
    lbal_PublishFrom.Left := 24;
    lbal_PublishFrom.Top := 246;
    lbal_PublishFrom.Width := 75;
    lbal_PublishFrom.Height := 13;
    lbal_PublishFrom.Caption := 'al_PublishFrom:';
    lbal_PublishFrom.HeightPercent := 100.000000000000000000;
    lbal_PublishFrom.WidthPercent := 100.000000000000000000;
    lbal_PublishTo.SetParentComponent(Self);
    lbal_PublishTo.Name := 'lbal_PublishTo';
    lbal_PublishTo.Left := 24;
    lbal_PublishTo.Top := 273;
    lbal_PublishTo.Width := 63;
    lbal_PublishTo.Height := 13;
    lbal_PublishTo.Caption := 'al_PublishTo:';
    lbal_PublishTo.HeightPercent := 100.000000000000000000;
    lbal_PublishTo.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 313;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 313;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 313;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edal_Id.SetParentComponent(Self);
    edal_Id.Name := 'edal_Id';
    edal_Id.Left := 221;
    edal_Id.Top := 189;
    edal_Id.Width := 200;
    edal_Id.Height := 21;
    edal_Id.HeightPercent := 100.000000000000000000;
    edal_Id.TabOrder := 6;
    edal_Id.Text := 'edal_Id';
    edal_Id.WidthPercent := 100.000000000000000000;
    edal_Id.DataField := 'al_Id';
    edal_Id.DataSource := DataSource;
    edei_ID.SetParentComponent(Self);
    edei_ID.Name := 'edei_ID';
    edei_ID.Left := 221;
    edei_ID.Top := 216;
    edei_ID.Width := 200;
    edei_ID.Height := 21;
    edei_ID.HeightPercent := 100.000000000000000000;
    edei_ID.TabOrder := 7;
    edei_ID.Text := 'edei_ID';
    edei_ID.WidthPercent := 100.000000000000000000;
    edei_ID.DataField := 'ei_ID';
    edei_ID.DataSource := DataSource;
    edal_PublishFrom.SetParentComponent(Self);
    edal_PublishFrom.Name := 'edal_PublishFrom';
    edal_PublishFrom.Left := 221;
    edal_PublishFrom.Top := 243;
    edal_PublishFrom.Width := 200;
    edal_PublishFrom.Height := 21;
    edal_PublishFrom.HeightPercent := 100.000000000000000000;
    edal_PublishFrom.TabOrder := 8;
    edal_PublishFrom.Text := 'edal_PublishFrom';
    edal_PublishFrom.WidthPercent := 100.000000000000000000;
    edal_PublishFrom.DataField := 'al_PublishFrom';
    edal_PublishFrom.DataSource := DataSource;
    edal_PublishTo.SetParentComponent(Self);
    edal_PublishTo.Name := 'edal_PublishTo';
    edal_PublishTo.Left := 221;
    edal_PublishTo.Top := 270;
    edal_PublishTo.Width := 200;
    edal_PublishTo.Height := 21;
    edal_PublishTo.HeightPercent := 100.000000000000000000;
    edal_PublishTo.TabOrder := 9;
    edal_PublishTo.Text := 'edal_PublishTo';
    edal_PublishTo.WidthPercent := 100.000000000000000000;
    edal_PublishTo.DataField := 'al_PublishTo';
    edal_PublishTo.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := AnnounceListDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    AnnounceListDataset.SetParentComponent(Self);
    AnnounceListDataset.Name := 'AnnounceListDataset';
    AnnounceListDataset.AfterApplyUpdates := AnnounceListDatasetAfterApplyUpdates;
    AnnounceListDataset.EntitySetName := 'AnnounceList';
    AnnounceListDataset.Connection := DMConnection.ApiConnection;
    AnnounceListDataset.Left := 176;
    AnnounceListDataset.Top := 8;
    AnnounceListDatasetal_Id.SetParentComponent(AnnounceListDataset);
    AnnounceListDatasetal_Id.Name := 'AnnounceListDatasetal_Id';
    AnnounceListDatasetal_Id.FieldName := 'al_Id';
    AnnounceListDatasetal_Id.Required := True;
    AnnounceListDatasetei_ID.SetParentComponent(AnnounceListDataset);
    AnnounceListDatasetei_ID.Name := 'AnnounceListDatasetei_ID';
    AnnounceListDatasetei_ID.FieldName := 'ei_ID';
    AnnounceListDatasetal_PublishFrom.SetParentComponent(AnnounceListDataset);
    AnnounceListDatasetal_PublishFrom.Name := 'AnnounceListDatasetal_PublishFrom';
    AnnounceListDatasetal_PublishFrom.FieldName := 'al_PublishFrom';
    AnnounceListDatasetal_PublishTo.SetParentComponent(AnnounceListDataset);
    AnnounceListDatasetal_PublishTo.Name := 'AnnounceListDatasetal_PublishTo';
    AnnounceListDatasetal_PublishTo.FieldName := 'al_PublishTo';
    XDataClientAnnounceListGet.SetParentComponent(Self);
    XDataClientAnnounceListGet.Name := 'XDataClientAnnounceListGet';
    XDataClientAnnounceListGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientAnnounceListGet, Self, 'OnLoad', 'XDataClientAnnounceListGetLoad');
    XDataClientAnnounceListGet.Left := 64;
    XDataClientAnnounceListGet.Top := 8;
    XDataClientAnnounceListDelete.SetParentComponent(Self);
    XDataClientAnnounceListDelete.Name := 'XDataClientAnnounceListDelete';
    XDataClientAnnounceListDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientAnnounceListDelete, Self, 'OnLoad', 'XDataClientAnnounceListDeleteLoad');
    SetEvent(XDataClientAnnounceListDelete, Self, 'OnError', 'XDataClientAnnounceListDeleteError');
    XDataClientAnnounceListDelete.Left := 64;
    XDataClientAnnounceListDelete.Top := 56;
  finally
    lbal_Id.AfterLoadDFMValues;
    lbei_ID.AfterLoadDFMValues;
    lbal_PublishFrom.AfterLoadDFMValues;
    lbal_PublishTo.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edal_Id.AfterLoadDFMValues;
    edei_ID.AfterLoadDFMValues;
    edal_PublishFrom.AfterLoadDFMValues;
    edal_PublishTo.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    AnnounceListDataset.AfterLoadDFMValues;
    AnnounceListDatasetal_Id.AfterLoadDFMValues;
    AnnounceListDatasetei_ID.AfterLoadDFMValues;
    AnnounceListDatasetal_PublishFrom.AfterLoadDFMValues;
    AnnounceListDatasetal_PublishTo.AfterLoadDFMValues;
    XDataClientAnnounceListGet.AfterLoadDFMValues;
    XDataClientAnnounceListDelete.AfterLoadDFMValues;
  end;
end;

end.
