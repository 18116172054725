unit View.IncomeCategory.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewIncomeCategoryEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbcat_Id: TLabel;
    edcat_Id: TDBEdit;
    lbcat_Name: TLabel;
    edcat_Name: TDBEdit;
    lbcat_Notes: TLabel;
    edcat_Notes: TDBEdit;
    lbcat_IsCSAssignable: TLabel;
    edcat_IsCSAssignable: TDBEdit;
    lbcat_CreatedBy: TLabel;
    edcat_CreatedBy: TDBEdit;
    lbcat_CreatedDate: TLabel;
    edcat_CreatedDate: TDBEdit;
    lbcat_LastModifiedBy: TLabel;
    edcat_LastModifiedBy: TDBEdit;
    lbcat_LastModifiedDate: TLabel;
    edcat_LastModifiedDate: TDBEdit;
    XDataClientIncomeCategoryGet: TXDataWebClient;
    XDataClientIncomeCategoryDelete: TXDataWebClient;
    IncomeCategoryDataset: TXDataWebDataSet;
    IncomeCategoryDatasetcat_Id: TIntegerField;
    IncomeCategoryDatasetcat_Name: TStringField;
    IncomeCategoryDatasetcat_Notes: TStringField;
    IncomeCategoryDatasetcat_IsCSAssignable: TStringField;
    IncomeCategoryDatasetcat_CreatedBy: TIntegerField;
    IncomeCategoryDatasetcat_CreatedDate: TDateTimeField;
    IncomeCategoryDatasetcat_LastModifiedBy: TIntegerField;
    IncomeCategoryDatasetcat_LastModifiedDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientIncomeCategoryGetLoad(Response: TXDataClientResponse);
    procedure XDataClientIncomeCategoryDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientIncomeCategoryDeleteError(Error: TXDataClientError);
    procedure IncomeCategoryDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewIncomeCategoryEdit: TFViewIncomeCategoryEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewIncomeCategoryEdit }

procedure TFViewIncomeCategoryEdit.btSaveClick(Sender: TObject);
begin
  if IncomeCategoryDataset.State in dsEditModes then
  begin
        IncomeCategoryDataset.Post;
    IncomeCategoryDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewIncomeCategoryEdit.btDeleteClick(Sender: TObject);
begin
  if IncomeCategoryDataset.State in dsEditModes then
    IncomeCategoryDataset.Post;
  XDataClientIncomeCategoryDelete.Delete(IncomeCategoryDataset.EntitySetName,
    TJSObject(IncomeCategoryDataset.CurrentData));
end;


class function TFViewIncomeCategoryEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewIncomeCategoryEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewIncomeCategoryEdit.InsertEntity;
begin
  IncomeCategoryDataset.Close;
  IncomeCategoryDataset.SetJsonData('{}');
  IncomeCategoryDataset.Open;
  IncomeCategoryDataset.Insert;
end;

class function TFViewIncomeCategoryEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewIncomeCategoryEdit(AForm).FInserting := AInserting;
    TFViewIncomeCategoryEdit(AForm).FId := AId;
    TFViewIncomeCategoryEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewIncomeCategoryEdit(AForm).InsertEntity
    else
      TFViewIncomeCategoryEdit(AForm).LoadEntity;
    TFViewIncomeCategoryEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewIncomeCategoryEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewIncomeCategoryEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    IncomeCategoryDataset.Connection.Model.DefaultSchema.FindEntityType(
      IncomeCategoryDataset.EntitySetName
    )
  );
  XDataClientIncomeCategoryGet.Get(IncomeCategoryDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewIncomeCategoryEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewIncomeCategoryEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewIncomeCategoryEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewIncomeCategoryEdit.XDataClientIncomeCategoryGetLoad(Response: TXDataClientResponse);
begin
  IncomeCategoryDataset.Close;
  IncomeCategoryDataset.SetJsonData(Response.Result);
  IncomeCategoryDataset.Open;
  
  IncomeCategoryDataset.Edit;  
end;

procedure TFViewIncomeCategoryEdit.XDataClientIncomeCategoryDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewIncomeCategoryEdit.XDataClientIncomeCategoryDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewIncomeCategoryEdit.IncomeCategoryDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewIncomeCategoryEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewIncomeCategoryEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbcat_Id := TLabel.Create('view.incomecategory.form.lbcat_Id');
  lbcat_Name := TLabel.Create('view.incomecategory.form.lbcat_Name');
  lbcat_Notes := TLabel.Create('view.incomecategory.form.lbcat_Notes');
  lbcat_IsCSAssignable := TLabel.Create('view.incomecategory.form.lbcat_IsCSAssignable');
  lbcat_CreatedBy := TLabel.Create('view.incomecategory.form.lbcat_CreatedBy');
  lbcat_CreatedDate := TLabel.Create('view.incomecategory.form.lbcat_CreatedDate');
  lbcat_LastModifiedBy := TLabel.Create('view.incomecategory.form.lbcat_LastModifiedBy');
  lbcat_LastModifiedDate := TLabel.Create('view.incomecategory.form.lbcat_LastModifiedDate');
  pnlMessage := TPanel.Create('view.incomecategory.form.message');
  lbMessage := TLabel.Create('view.incomecategory.form.message.label');
  btCloseMessage := TButton.Create('view.incomecategory.form.message.button');
  btCancel := TButton.Create('view.incomecategory.form.btBack');
  btSave := TButton.Create('view.incomecategory.form.btSave');
  btDelete := TButton.Create('view.incomecategory.form.btDelete');
  edcat_Id := TDBEdit.Create('view.incomecategory.form.edcat_Id');
  edcat_Name := TDBEdit.Create('view.incomecategory.form.edcat_Name');
  edcat_Notes := TDBEdit.Create('view.incomecategory.form.edcat_Notes');
  edcat_IsCSAssignable := TDBEdit.Create('view.incomecategory.form.edcat_IsCSAssignable');
  edcat_CreatedBy := TDBEdit.Create('view.incomecategory.form.edcat_CreatedBy');
  edcat_CreatedDate := TDBEdit.Create('view.incomecategory.form.edcat_CreatedDate');
  edcat_LastModifiedBy := TDBEdit.Create('view.incomecategory.form.edcat_LastModifiedBy');
  edcat_LastModifiedDate := TDBEdit.Create('view.incomecategory.form.edcat_LastModifiedDate');
  DataSource := TDataSource.Create(Self);
  IncomeCategoryDataset := TXDataWebDataSet.Create(Self);
  IncomeCategoryDatasetcat_Id := TIntegerField.Create(Self);
  IncomeCategoryDatasetcat_Name := TStringField.Create(Self);
  IncomeCategoryDatasetcat_Notes := TStringField.Create(Self);
  IncomeCategoryDatasetcat_IsCSAssignable := TStringField.Create(Self);
  IncomeCategoryDatasetcat_CreatedBy := TIntegerField.Create(Self);
  IncomeCategoryDatasetcat_CreatedDate := TDateTimeField.Create(Self);
  IncomeCategoryDatasetcat_LastModifiedBy := TIntegerField.Create(Self);
  IncomeCategoryDatasetcat_LastModifiedDate := TDateTimeField.Create(Self);
  XDataClientIncomeCategoryGet := TXDataWebClient.Create(Self);
  XDataClientIncomeCategoryDelete := TXDataWebClient.Create(Self);

  lbcat_Id.BeforeLoadDFMValues;
  lbcat_Name.BeforeLoadDFMValues;
  lbcat_Notes.BeforeLoadDFMValues;
  lbcat_IsCSAssignable.BeforeLoadDFMValues;
  lbcat_CreatedBy.BeforeLoadDFMValues;
  lbcat_CreatedDate.BeforeLoadDFMValues;
  lbcat_LastModifiedBy.BeforeLoadDFMValues;
  lbcat_LastModifiedDate.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edcat_Id.BeforeLoadDFMValues;
  edcat_Name.BeforeLoadDFMValues;
  edcat_Notes.BeforeLoadDFMValues;
  edcat_IsCSAssignable.BeforeLoadDFMValues;
  edcat_CreatedBy.BeforeLoadDFMValues;
  edcat_CreatedDate.BeforeLoadDFMValues;
  edcat_LastModifiedBy.BeforeLoadDFMValues;
  edcat_LastModifiedDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  IncomeCategoryDataset.BeforeLoadDFMValues;
  IncomeCategoryDatasetcat_Id.BeforeLoadDFMValues;
  IncomeCategoryDatasetcat_Name.BeforeLoadDFMValues;
  IncomeCategoryDatasetcat_Notes.BeforeLoadDFMValues;
  IncomeCategoryDatasetcat_IsCSAssignable.BeforeLoadDFMValues;
  IncomeCategoryDatasetcat_CreatedBy.BeforeLoadDFMValues;
  IncomeCategoryDatasetcat_CreatedDate.BeforeLoadDFMValues;
  IncomeCategoryDatasetcat_LastModifiedBy.BeforeLoadDFMValues;
  IncomeCategoryDatasetcat_LastModifiedDate.BeforeLoadDFMValues;
  XDataClientIncomeCategoryGet.BeforeLoadDFMValues;
  XDataClientIncomeCategoryDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewIncomeCategoryEdit';
    Width := 593;
    Height := 481;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbcat_Id.SetParentComponent(Self);
    lbcat_Id.Name := 'lbcat_Id';
    lbcat_Id.Left := 24;
    lbcat_Id.Top := 192;
    lbcat_Id.Width := 35;
    lbcat_Id.Height := 13;
    lbcat_Id.Caption := 'cat_Id:';
    lbcat_Id.HeightPercent := 100.000000000000000000;
    lbcat_Id.WidthPercent := 100.000000000000000000;
    lbcat_Name.SetParentComponent(Self);
    lbcat_Name.Name := 'lbcat_Name';
    lbcat_Name.Left := 24;
    lbcat_Name.Top := 219;
    lbcat_Name.Width := 52;
    lbcat_Name.Height := 13;
    lbcat_Name.Caption := 'cat_Name:';
    lbcat_Name.HeightPercent := 100.000000000000000000;
    lbcat_Name.WidthPercent := 100.000000000000000000;
    lbcat_Notes.SetParentComponent(Self);
    lbcat_Notes.Name := 'lbcat_Notes';
    lbcat_Notes.Left := 24;
    lbcat_Notes.Top := 246;
    lbcat_Notes.Width := 53;
    lbcat_Notes.Height := 13;
    lbcat_Notes.Caption := 'cat_Notes:';
    lbcat_Notes.HeightPercent := 100.000000000000000000;
    lbcat_Notes.WidthPercent := 100.000000000000000000;
    lbcat_IsCSAssignable.SetParentComponent(Self);
    lbcat_IsCSAssignable.Name := 'lbcat_IsCSAssignable';
    lbcat_IsCSAssignable.Left := 24;
    lbcat_IsCSAssignable.Top := 273;
    lbcat_IsCSAssignable.Width := 98;
    lbcat_IsCSAssignable.Height := 13;
    lbcat_IsCSAssignable.Caption := 'cat_IsCSAssignable:';
    lbcat_IsCSAssignable.HeightPercent := 100.000000000000000000;
    lbcat_IsCSAssignable.WidthPercent := 100.000000000000000000;
    lbcat_CreatedBy.SetParentComponent(Self);
    lbcat_CreatedBy.Name := 'lbcat_CreatedBy';
    lbcat_CreatedBy.Left := 24;
    lbcat_CreatedBy.Top := 300;
    lbcat_CreatedBy.Width := 76;
    lbcat_CreatedBy.Height := 13;
    lbcat_CreatedBy.Caption := 'cat_CreatedBy:';
    lbcat_CreatedBy.HeightPercent := 100.000000000000000000;
    lbcat_CreatedBy.WidthPercent := 100.000000000000000000;
    lbcat_CreatedDate.SetParentComponent(Self);
    lbcat_CreatedDate.Name := 'lbcat_CreatedDate';
    lbcat_CreatedDate.Left := 24;
    lbcat_CreatedDate.Top := 327;
    lbcat_CreatedDate.Width := 87;
    lbcat_CreatedDate.Height := 13;
    lbcat_CreatedDate.Caption := 'cat_CreatedDate:';
    lbcat_CreatedDate.HeightPercent := 100.000000000000000000;
    lbcat_CreatedDate.WidthPercent := 100.000000000000000000;
    lbcat_LastModifiedBy.SetParentComponent(Self);
    lbcat_LastModifiedBy.Name := 'lbcat_LastModifiedBy';
    lbcat_LastModifiedBy.Left := 24;
    lbcat_LastModifiedBy.Top := 354;
    lbcat_LastModifiedBy.Width := 97;
    lbcat_LastModifiedBy.Height := 13;
    lbcat_LastModifiedBy.Caption := 'cat_LastModifiedBy:';
    lbcat_LastModifiedBy.HeightPercent := 100.000000000000000000;
    lbcat_LastModifiedBy.WidthPercent := 100.000000000000000000;
    lbcat_LastModifiedDate.SetParentComponent(Self);
    lbcat_LastModifiedDate.Name := 'lbcat_LastModifiedDate';
    lbcat_LastModifiedDate.Left := 24;
    lbcat_LastModifiedDate.Top := 381;
    lbcat_LastModifiedDate.Width := 108;
    lbcat_LastModifiedDate.Height := 13;
    lbcat_LastModifiedDate.Caption := 'cat_LastModifiedDate:';
    lbcat_LastModifiedDate.HeightPercent := 100.000000000000000000;
    lbcat_LastModifiedDate.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 421;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 421;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 421;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edcat_Id.SetParentComponent(Self);
    edcat_Id.Name := 'edcat_Id';
    edcat_Id.Left := 221;
    edcat_Id.Top := 189;
    edcat_Id.Width := 200;
    edcat_Id.Height := 21;
    edcat_Id.HeightPercent := 100.000000000000000000;
    edcat_Id.TabOrder := 6;
    edcat_Id.Text := 'edcat_Id';
    edcat_Id.WidthPercent := 100.000000000000000000;
    edcat_Id.DataField := 'cat_Id';
    edcat_Id.DataSource := DataSource;
    edcat_Name.SetParentComponent(Self);
    edcat_Name.Name := 'edcat_Name';
    edcat_Name.Left := 221;
    edcat_Name.Top := 216;
    edcat_Name.Width := 200;
    edcat_Name.Height := 21;
    edcat_Name.HeightPercent := 100.000000000000000000;
    edcat_Name.TabOrder := 7;
    edcat_Name.Text := 'edcat_Name';
    edcat_Name.WidthPercent := 100.000000000000000000;
    edcat_Name.DataField := 'cat_Name';
    edcat_Name.DataSource := DataSource;
    edcat_Notes.SetParentComponent(Self);
    edcat_Notes.Name := 'edcat_Notes';
    edcat_Notes.Left := 221;
    edcat_Notes.Top := 243;
    edcat_Notes.Width := 200;
    edcat_Notes.Height := 21;
    edcat_Notes.HeightPercent := 100.000000000000000000;
    edcat_Notes.TabOrder := 8;
    edcat_Notes.Text := 'edcat_Notes';
    edcat_Notes.WidthPercent := 100.000000000000000000;
    edcat_Notes.DataField := 'cat_Notes';
    edcat_Notes.DataSource := DataSource;
    edcat_IsCSAssignable.SetParentComponent(Self);
    edcat_IsCSAssignable.Name := 'edcat_IsCSAssignable';
    edcat_IsCSAssignable.Left := 221;
    edcat_IsCSAssignable.Top := 270;
    edcat_IsCSAssignable.Width := 200;
    edcat_IsCSAssignable.Height := 21;
    edcat_IsCSAssignable.HeightPercent := 100.000000000000000000;
    edcat_IsCSAssignable.TabOrder := 9;
    edcat_IsCSAssignable.Text := 'edcat_IsCSAssignable';
    edcat_IsCSAssignable.WidthPercent := 100.000000000000000000;
    edcat_IsCSAssignable.DataField := 'cat_IsCSAssignable';
    edcat_IsCSAssignable.DataSource := DataSource;
    edcat_CreatedBy.SetParentComponent(Self);
    edcat_CreatedBy.Name := 'edcat_CreatedBy';
    edcat_CreatedBy.Left := 221;
    edcat_CreatedBy.Top := 297;
    edcat_CreatedBy.Width := 200;
    edcat_CreatedBy.Height := 21;
    edcat_CreatedBy.HeightPercent := 100.000000000000000000;
    edcat_CreatedBy.TabOrder := 10;
    edcat_CreatedBy.Text := 'edcat_CreatedBy';
    edcat_CreatedBy.WidthPercent := 100.000000000000000000;
    edcat_CreatedBy.DataField := 'cat_CreatedBy';
    edcat_CreatedBy.DataSource := DataSource;
    edcat_CreatedDate.SetParentComponent(Self);
    edcat_CreatedDate.Name := 'edcat_CreatedDate';
    edcat_CreatedDate.Left := 221;
    edcat_CreatedDate.Top := 324;
    edcat_CreatedDate.Width := 200;
    edcat_CreatedDate.Height := 21;
    edcat_CreatedDate.HeightPercent := 100.000000000000000000;
    edcat_CreatedDate.TabOrder := 11;
    edcat_CreatedDate.Text := 'edcat_CreatedDate';
    edcat_CreatedDate.WidthPercent := 100.000000000000000000;
    edcat_CreatedDate.DataField := 'cat_CreatedDate';
    edcat_CreatedDate.DataSource := DataSource;
    edcat_LastModifiedBy.SetParentComponent(Self);
    edcat_LastModifiedBy.Name := 'edcat_LastModifiedBy';
    edcat_LastModifiedBy.Left := 221;
    edcat_LastModifiedBy.Top := 351;
    edcat_LastModifiedBy.Width := 200;
    edcat_LastModifiedBy.Height := 21;
    edcat_LastModifiedBy.HeightPercent := 100.000000000000000000;
    edcat_LastModifiedBy.TabOrder := 12;
    edcat_LastModifiedBy.Text := 'edcat_LastModifiedBy';
    edcat_LastModifiedBy.WidthPercent := 100.000000000000000000;
    edcat_LastModifiedBy.DataField := 'cat_LastModifiedBy';
    edcat_LastModifiedBy.DataSource := DataSource;
    edcat_LastModifiedDate.SetParentComponent(Self);
    edcat_LastModifiedDate.Name := 'edcat_LastModifiedDate';
    edcat_LastModifiedDate.Left := 221;
    edcat_LastModifiedDate.Top := 378;
    edcat_LastModifiedDate.Width := 200;
    edcat_LastModifiedDate.Height := 21;
    edcat_LastModifiedDate.HeightPercent := 100.000000000000000000;
    edcat_LastModifiedDate.TabOrder := 13;
    edcat_LastModifiedDate.Text := 'edcat_LastModifiedDate';
    edcat_LastModifiedDate.WidthPercent := 100.000000000000000000;
    edcat_LastModifiedDate.DataField := 'cat_LastModifiedDate';
    edcat_LastModifiedDate.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := IncomeCategoryDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    IncomeCategoryDataset.SetParentComponent(Self);
    IncomeCategoryDataset.Name := 'IncomeCategoryDataset';
    IncomeCategoryDataset.AfterApplyUpdates := IncomeCategoryDatasetAfterApplyUpdates;
    IncomeCategoryDataset.EntitySetName := 'IncomeCategory';
    IncomeCategoryDataset.Connection := DMConnection.ApiConnection;
    IncomeCategoryDataset.Left := 176;
    IncomeCategoryDataset.Top := 8;
    IncomeCategoryDatasetcat_Id.SetParentComponent(IncomeCategoryDataset);
    IncomeCategoryDatasetcat_Id.Name := 'IncomeCategoryDatasetcat_Id';
    IncomeCategoryDatasetcat_Id.FieldName := 'cat_Id';
    IncomeCategoryDatasetcat_Id.Required := True;
    IncomeCategoryDatasetcat_Name.SetParentComponent(IncomeCategoryDataset);
    IncomeCategoryDatasetcat_Name.Name := 'IncomeCategoryDatasetcat_Name';
    IncomeCategoryDatasetcat_Name.FieldName := 'cat_Name';
    IncomeCategoryDatasetcat_Name.Size := 50;
    IncomeCategoryDatasetcat_Notes.SetParentComponent(IncomeCategoryDataset);
    IncomeCategoryDatasetcat_Notes.Name := 'IncomeCategoryDatasetcat_Notes';
    IncomeCategoryDatasetcat_Notes.FieldName := 'cat_Notes';
    IncomeCategoryDatasetcat_Notes.Size := 250;
    IncomeCategoryDatasetcat_IsCSAssignable.SetParentComponent(IncomeCategoryDataset);
    IncomeCategoryDatasetcat_IsCSAssignable.Name := 'IncomeCategoryDatasetcat_IsCSAssignable';
    IncomeCategoryDatasetcat_IsCSAssignable.FieldName := 'cat_IsCSAssignable';
    IncomeCategoryDatasetcat_IsCSAssignable.Size := 255;
    IncomeCategoryDatasetcat_CreatedBy.SetParentComponent(IncomeCategoryDataset);
    IncomeCategoryDatasetcat_CreatedBy.Name := 'IncomeCategoryDatasetcat_CreatedBy';
    IncomeCategoryDatasetcat_CreatedBy.FieldName := 'cat_CreatedBy';
    IncomeCategoryDatasetcat_CreatedDate.SetParentComponent(IncomeCategoryDataset);
    IncomeCategoryDatasetcat_CreatedDate.Name := 'IncomeCategoryDatasetcat_CreatedDate';
    IncomeCategoryDatasetcat_CreatedDate.FieldName := 'cat_CreatedDate';
    IncomeCategoryDatasetcat_LastModifiedBy.SetParentComponent(IncomeCategoryDataset);
    IncomeCategoryDatasetcat_LastModifiedBy.Name := 'IncomeCategoryDatasetcat_LastModifiedBy';
    IncomeCategoryDatasetcat_LastModifiedBy.FieldName := 'cat_LastModifiedBy';
    IncomeCategoryDatasetcat_LastModifiedDate.SetParentComponent(IncomeCategoryDataset);
    IncomeCategoryDatasetcat_LastModifiedDate.Name := 'IncomeCategoryDatasetcat_LastModifiedDate';
    IncomeCategoryDatasetcat_LastModifiedDate.FieldName := 'cat_LastModifiedDate';
    XDataClientIncomeCategoryGet.SetParentComponent(Self);
    XDataClientIncomeCategoryGet.Name := 'XDataClientIncomeCategoryGet';
    XDataClientIncomeCategoryGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientIncomeCategoryGet, Self, 'OnLoad', 'XDataClientIncomeCategoryGetLoad');
    XDataClientIncomeCategoryGet.Left := 64;
    XDataClientIncomeCategoryGet.Top := 8;
    XDataClientIncomeCategoryDelete.SetParentComponent(Self);
    XDataClientIncomeCategoryDelete.Name := 'XDataClientIncomeCategoryDelete';
    XDataClientIncomeCategoryDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientIncomeCategoryDelete, Self, 'OnLoad', 'XDataClientIncomeCategoryDeleteLoad');
    SetEvent(XDataClientIncomeCategoryDelete, Self, 'OnError', 'XDataClientIncomeCategoryDeleteError');
    XDataClientIncomeCategoryDelete.Left := 64;
    XDataClientIncomeCategoryDelete.Top := 56;
  finally
    lbcat_Id.AfterLoadDFMValues;
    lbcat_Name.AfterLoadDFMValues;
    lbcat_Notes.AfterLoadDFMValues;
    lbcat_IsCSAssignable.AfterLoadDFMValues;
    lbcat_CreatedBy.AfterLoadDFMValues;
    lbcat_CreatedDate.AfterLoadDFMValues;
    lbcat_LastModifiedBy.AfterLoadDFMValues;
    lbcat_LastModifiedDate.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edcat_Id.AfterLoadDFMValues;
    edcat_Name.AfterLoadDFMValues;
    edcat_Notes.AfterLoadDFMValues;
    edcat_IsCSAssignable.AfterLoadDFMValues;
    edcat_CreatedBy.AfterLoadDFMValues;
    edcat_CreatedDate.AfterLoadDFMValues;
    edcat_LastModifiedBy.AfterLoadDFMValues;
    edcat_LastModifiedDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    IncomeCategoryDataset.AfterLoadDFMValues;
    IncomeCategoryDatasetcat_Id.AfterLoadDFMValues;
    IncomeCategoryDatasetcat_Name.AfterLoadDFMValues;
    IncomeCategoryDatasetcat_Notes.AfterLoadDFMValues;
    IncomeCategoryDatasetcat_IsCSAssignable.AfterLoadDFMValues;
    IncomeCategoryDatasetcat_CreatedBy.AfterLoadDFMValues;
    IncomeCategoryDatasetcat_CreatedDate.AfterLoadDFMValues;
    IncomeCategoryDatasetcat_LastModifiedBy.AfterLoadDFMValues;
    IncomeCategoryDatasetcat_LastModifiedDate.AfterLoadDFMValues;
    XDataClientIncomeCategoryGet.AfterLoadDFMValues;
    XDataClientIncomeCategoryDelete.AfterLoadDFMValues;
  end;
end;

end.
