unit View.SalesReports.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewSalesReportsEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbsr_Id: TLabel;
    edsr_Id: TDBEdit;
    lbsr_Date: TLabel;
    edsr_Date: TDBEdit;
    lbsr_By: TLabel;
    edsr_By: TDBEdit;
    lbsr_Amout: TLabel;
    edsr_Amout: TDBEdit;
    lbsr_AmoutTx1: TLabel;
    edsr_AmoutTx1: TDBEdit;
    lbsr_AmoutTx2: TLabel;
    edsr_AmoutTx2: TDBEdit;
    lbsr_AmoutTx3: TLabel;
    edsr_AmoutTx3: TDBEdit;
    lbsr_AmoutTotal: TLabel;
    edsr_AmoutTotal: TDBEdit;
    XDataClientSalesReportsGet: TXDataWebClient;
    XDataClientSalesReportsDelete: TXDataWebClient;
    SalesReportsDataset: TXDataWebDataSet;
    SalesReportsDatasetsr_Id: TIntegerField;
    SalesReportsDatasetsr_Date: TDateTimeField;
    SalesReportsDatasetsr_By: TIntegerField;
    SalesReportsDatasetsr_Amout: TFloatField;
    SalesReportsDatasetsr_AmoutTx1: TFloatField;
    SalesReportsDatasetsr_AmoutTx2: TFloatField;
    SalesReportsDatasetsr_AmoutTx3: TFloatField;
    SalesReportsDatasetsr_AmoutTotal: TFloatField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientSalesReportsGetLoad(Response: TXDataClientResponse);
    procedure XDataClientSalesReportsDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientSalesReportsDeleteError(Error: TXDataClientError);
    procedure SalesReportsDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewSalesReportsEdit: TFViewSalesReportsEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewSalesReportsEdit }

procedure TFViewSalesReportsEdit.btSaveClick(Sender: TObject);
begin
  if SalesReportsDataset.State in dsEditModes then
  begin
        SalesReportsDataset.Post;
    SalesReportsDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewSalesReportsEdit.btDeleteClick(Sender: TObject);
begin
  if SalesReportsDataset.State in dsEditModes then
    SalesReportsDataset.Post;
  XDataClientSalesReportsDelete.Delete(SalesReportsDataset.EntitySetName,
    TJSObject(SalesReportsDataset.CurrentData));
end;


class function TFViewSalesReportsEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewSalesReportsEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewSalesReportsEdit.InsertEntity;
begin
  SalesReportsDataset.Close;
  SalesReportsDataset.SetJsonData('{}');
  SalesReportsDataset.Open;
  SalesReportsDataset.Insert;
end;

class function TFViewSalesReportsEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewSalesReportsEdit(AForm).FInserting := AInserting;
    TFViewSalesReportsEdit(AForm).FId := AId;
    TFViewSalesReportsEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewSalesReportsEdit(AForm).InsertEntity
    else
      TFViewSalesReportsEdit(AForm).LoadEntity;
    TFViewSalesReportsEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewSalesReportsEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewSalesReportsEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    SalesReportsDataset.Connection.Model.DefaultSchema.FindEntityType(
      SalesReportsDataset.EntitySetName
    )
  );
  XDataClientSalesReportsGet.Get(SalesReportsDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewSalesReportsEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewSalesReportsEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewSalesReportsEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewSalesReportsEdit.XDataClientSalesReportsGetLoad(Response: TXDataClientResponse);
begin
  SalesReportsDataset.Close;
  SalesReportsDataset.SetJsonData(Response.Result);
  SalesReportsDataset.Open;
  
  SalesReportsDataset.Edit;  
end;

procedure TFViewSalesReportsEdit.XDataClientSalesReportsDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewSalesReportsEdit.XDataClientSalesReportsDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewSalesReportsEdit.SalesReportsDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewSalesReportsEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewSalesReportsEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbsr_Id := TLabel.Create('view.salesreports.form.lbsr_Id');
  lbsr_Date := TLabel.Create('view.salesreports.form.lbsr_Date');
  lbsr_By := TLabel.Create('view.salesreports.form.lbsr_By');
  lbsr_Amout := TLabel.Create('view.salesreports.form.lbsr_Amout');
  lbsr_AmoutTx1 := TLabel.Create('view.salesreports.form.lbsr_AmoutTx1');
  lbsr_AmoutTx2 := TLabel.Create('view.salesreports.form.lbsr_AmoutTx2');
  lbsr_AmoutTx3 := TLabel.Create('view.salesreports.form.lbsr_AmoutTx3');
  lbsr_AmoutTotal := TLabel.Create('view.salesreports.form.lbsr_AmoutTotal');
  pnlMessage := TPanel.Create('view.salesreports.form.message');
  lbMessage := TLabel.Create('view.salesreports.form.message.label');
  btCloseMessage := TButton.Create('view.salesreports.form.message.button');
  btCancel := TButton.Create('view.salesreports.form.btBack');
  btSave := TButton.Create('view.salesreports.form.btSave');
  btDelete := TButton.Create('view.salesreports.form.btDelete');
  edsr_Id := TDBEdit.Create('view.salesreports.form.edsr_Id');
  edsr_Date := TDBEdit.Create('view.salesreports.form.edsr_Date');
  edsr_By := TDBEdit.Create('view.salesreports.form.edsr_By');
  edsr_Amout := TDBEdit.Create('view.salesreports.form.edsr_Amout');
  edsr_AmoutTx1 := TDBEdit.Create('view.salesreports.form.edsr_AmoutTx1');
  edsr_AmoutTx2 := TDBEdit.Create('view.salesreports.form.edsr_AmoutTx2');
  edsr_AmoutTx3 := TDBEdit.Create('view.salesreports.form.edsr_AmoutTx3');
  edsr_AmoutTotal := TDBEdit.Create('view.salesreports.form.edsr_AmoutTotal');
  DataSource := TDataSource.Create(Self);
  SalesReportsDataset := TXDataWebDataSet.Create(Self);
  SalesReportsDatasetsr_Id := TIntegerField.Create(Self);
  SalesReportsDatasetsr_Date := TDateTimeField.Create(Self);
  SalesReportsDatasetsr_By := TIntegerField.Create(Self);
  SalesReportsDatasetsr_Amout := TFloatField.Create(Self);
  SalesReportsDatasetsr_AmoutTx1 := TFloatField.Create(Self);
  SalesReportsDatasetsr_AmoutTx2 := TFloatField.Create(Self);
  SalesReportsDatasetsr_AmoutTx3 := TFloatField.Create(Self);
  SalesReportsDatasetsr_AmoutTotal := TFloatField.Create(Self);
  XDataClientSalesReportsGet := TXDataWebClient.Create(Self);
  XDataClientSalesReportsDelete := TXDataWebClient.Create(Self);

  lbsr_Id.BeforeLoadDFMValues;
  lbsr_Date.BeforeLoadDFMValues;
  lbsr_By.BeforeLoadDFMValues;
  lbsr_Amout.BeforeLoadDFMValues;
  lbsr_AmoutTx1.BeforeLoadDFMValues;
  lbsr_AmoutTx2.BeforeLoadDFMValues;
  lbsr_AmoutTx3.BeforeLoadDFMValues;
  lbsr_AmoutTotal.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edsr_Id.BeforeLoadDFMValues;
  edsr_Date.BeforeLoadDFMValues;
  edsr_By.BeforeLoadDFMValues;
  edsr_Amout.BeforeLoadDFMValues;
  edsr_AmoutTx1.BeforeLoadDFMValues;
  edsr_AmoutTx2.BeforeLoadDFMValues;
  edsr_AmoutTx3.BeforeLoadDFMValues;
  edsr_AmoutTotal.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  SalesReportsDataset.BeforeLoadDFMValues;
  SalesReportsDatasetsr_Id.BeforeLoadDFMValues;
  SalesReportsDatasetsr_Date.BeforeLoadDFMValues;
  SalesReportsDatasetsr_By.BeforeLoadDFMValues;
  SalesReportsDatasetsr_Amout.BeforeLoadDFMValues;
  SalesReportsDatasetsr_AmoutTx1.BeforeLoadDFMValues;
  SalesReportsDatasetsr_AmoutTx2.BeforeLoadDFMValues;
  SalesReportsDatasetsr_AmoutTx3.BeforeLoadDFMValues;
  SalesReportsDatasetsr_AmoutTotal.BeforeLoadDFMValues;
  XDataClientSalesReportsGet.BeforeLoadDFMValues;
  XDataClientSalesReportsDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewSalesReportsEdit';
    Width := 593;
    Height := 481;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbsr_Id.SetParentComponent(Self);
    lbsr_Id.Name := 'lbsr_Id';
    lbsr_Id.Left := 24;
    lbsr_Id.Top := 192;
    lbsr_Id.Width := 29;
    lbsr_Id.Height := 13;
    lbsr_Id.Caption := 'sr_Id:';
    lbsr_Id.HeightPercent := 100.000000000000000000;
    lbsr_Id.WidthPercent := 100.000000000000000000;
    lbsr_Date.SetParentComponent(Self);
    lbsr_Date.Name := 'lbsr_Date';
    lbsr_Date.Left := 24;
    lbsr_Date.Top := 219;
    lbsr_Date.Width := 42;
    lbsr_Date.Height := 13;
    lbsr_Date.Caption := 'sr_Date:';
    lbsr_Date.HeightPercent := 100.000000000000000000;
    lbsr_Date.WidthPercent := 100.000000000000000000;
    lbsr_By.SetParentComponent(Self);
    lbsr_By.Name := 'lbsr_By';
    lbsr_By.Left := 24;
    lbsr_By.Top := 246;
    lbsr_By.Width := 31;
    lbsr_By.Height := 13;
    lbsr_By.Caption := 'sr_By:';
    lbsr_By.HeightPercent := 100.000000000000000000;
    lbsr_By.WidthPercent := 100.000000000000000000;
    lbsr_Amout.SetParentComponent(Self);
    lbsr_Amout.Name := 'lbsr_Amout';
    lbsr_Amout.Left := 24;
    lbsr_Amout.Top := 273;
    lbsr_Amout.Width := 50;
    lbsr_Amout.Height := 13;
    lbsr_Amout.Caption := 'sr_Amout:';
    lbsr_Amout.HeightPercent := 100.000000000000000000;
    lbsr_Amout.WidthPercent := 100.000000000000000000;
    lbsr_AmoutTx1.SetParentComponent(Self);
    lbsr_AmoutTx1.Name := 'lbsr_AmoutTx1';
    lbsr_AmoutTx1.Left := 24;
    lbsr_AmoutTx1.Top := 300;
    lbsr_AmoutTx1.Width := 68;
    lbsr_AmoutTx1.Height := 13;
    lbsr_AmoutTx1.Caption := 'sr_AmoutTx1:';
    lbsr_AmoutTx1.HeightPercent := 100.000000000000000000;
    lbsr_AmoutTx1.WidthPercent := 100.000000000000000000;
    lbsr_AmoutTx2.SetParentComponent(Self);
    lbsr_AmoutTx2.Name := 'lbsr_AmoutTx2';
    lbsr_AmoutTx2.Left := 24;
    lbsr_AmoutTx2.Top := 327;
    lbsr_AmoutTx2.Width := 68;
    lbsr_AmoutTx2.Height := 13;
    lbsr_AmoutTx2.Caption := 'sr_AmoutTx2:';
    lbsr_AmoutTx2.HeightPercent := 100.000000000000000000;
    lbsr_AmoutTx2.WidthPercent := 100.000000000000000000;
    lbsr_AmoutTx3.SetParentComponent(Self);
    lbsr_AmoutTx3.Name := 'lbsr_AmoutTx3';
    lbsr_AmoutTx3.Left := 24;
    lbsr_AmoutTx3.Top := 354;
    lbsr_AmoutTx3.Width := 68;
    lbsr_AmoutTx3.Height := 13;
    lbsr_AmoutTx3.Caption := 'sr_AmoutTx3:';
    lbsr_AmoutTx3.HeightPercent := 100.000000000000000000;
    lbsr_AmoutTx3.WidthPercent := 100.000000000000000000;
    lbsr_AmoutTotal.SetParentComponent(Self);
    lbsr_AmoutTotal.Name := 'lbsr_AmoutTotal';
    lbsr_AmoutTotal.Left := 24;
    lbsr_AmoutTotal.Top := 381;
    lbsr_AmoutTotal.Width := 74;
    lbsr_AmoutTotal.Height := 13;
    lbsr_AmoutTotal.Caption := 'sr_AmoutTotal:';
    lbsr_AmoutTotal.HeightPercent := 100.000000000000000000;
    lbsr_AmoutTotal.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 421;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 421;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 421;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edsr_Id.SetParentComponent(Self);
    edsr_Id.Name := 'edsr_Id';
    edsr_Id.Left := 221;
    edsr_Id.Top := 189;
    edsr_Id.Width := 200;
    edsr_Id.Height := 21;
    edsr_Id.HeightPercent := 100.000000000000000000;
    edsr_Id.TabOrder := 6;
    edsr_Id.Text := 'edsr_Id';
    edsr_Id.WidthPercent := 100.000000000000000000;
    edsr_Id.DataField := 'sr_Id';
    edsr_Id.DataSource := DataSource;
    edsr_Date.SetParentComponent(Self);
    edsr_Date.Name := 'edsr_Date';
    edsr_Date.Left := 221;
    edsr_Date.Top := 216;
    edsr_Date.Width := 200;
    edsr_Date.Height := 21;
    edsr_Date.HeightPercent := 100.000000000000000000;
    edsr_Date.TabOrder := 7;
    edsr_Date.Text := 'edsr_Date';
    edsr_Date.WidthPercent := 100.000000000000000000;
    edsr_Date.DataField := 'sr_Date';
    edsr_Date.DataSource := DataSource;
    edsr_By.SetParentComponent(Self);
    edsr_By.Name := 'edsr_By';
    edsr_By.Left := 221;
    edsr_By.Top := 243;
    edsr_By.Width := 200;
    edsr_By.Height := 21;
    edsr_By.HeightPercent := 100.000000000000000000;
    edsr_By.TabOrder := 8;
    edsr_By.Text := 'edsr_By';
    edsr_By.WidthPercent := 100.000000000000000000;
    edsr_By.DataField := 'sr_By';
    edsr_By.DataSource := DataSource;
    edsr_Amout.SetParentComponent(Self);
    edsr_Amout.Name := 'edsr_Amout';
    edsr_Amout.Left := 221;
    edsr_Amout.Top := 270;
    edsr_Amout.Width := 200;
    edsr_Amout.Height := 21;
    edsr_Amout.HeightPercent := 100.000000000000000000;
    edsr_Amout.TabOrder := 9;
    edsr_Amout.Text := 'edsr_Amout';
    edsr_Amout.WidthPercent := 100.000000000000000000;
    edsr_Amout.DataField := 'sr_Amout';
    edsr_Amout.DataSource := DataSource;
    edsr_AmoutTx1.SetParentComponent(Self);
    edsr_AmoutTx1.Name := 'edsr_AmoutTx1';
    edsr_AmoutTx1.Left := 221;
    edsr_AmoutTx1.Top := 297;
    edsr_AmoutTx1.Width := 200;
    edsr_AmoutTx1.Height := 21;
    edsr_AmoutTx1.HeightPercent := 100.000000000000000000;
    edsr_AmoutTx1.TabOrder := 10;
    edsr_AmoutTx1.Text := 'edsr_AmoutTx1';
    edsr_AmoutTx1.WidthPercent := 100.000000000000000000;
    edsr_AmoutTx1.DataField := 'sr_AmoutTx1';
    edsr_AmoutTx1.DataSource := DataSource;
    edsr_AmoutTx2.SetParentComponent(Self);
    edsr_AmoutTx2.Name := 'edsr_AmoutTx2';
    edsr_AmoutTx2.Left := 221;
    edsr_AmoutTx2.Top := 324;
    edsr_AmoutTx2.Width := 200;
    edsr_AmoutTx2.Height := 21;
    edsr_AmoutTx2.HeightPercent := 100.000000000000000000;
    edsr_AmoutTx2.TabOrder := 11;
    edsr_AmoutTx2.Text := 'edsr_AmoutTx2';
    edsr_AmoutTx2.WidthPercent := 100.000000000000000000;
    edsr_AmoutTx2.DataField := 'sr_AmoutTx2';
    edsr_AmoutTx2.DataSource := DataSource;
    edsr_AmoutTx3.SetParentComponent(Self);
    edsr_AmoutTx3.Name := 'edsr_AmoutTx3';
    edsr_AmoutTx3.Left := 221;
    edsr_AmoutTx3.Top := 351;
    edsr_AmoutTx3.Width := 200;
    edsr_AmoutTx3.Height := 21;
    edsr_AmoutTx3.HeightPercent := 100.000000000000000000;
    edsr_AmoutTx3.TabOrder := 12;
    edsr_AmoutTx3.Text := 'edsr_AmoutTx3';
    edsr_AmoutTx3.WidthPercent := 100.000000000000000000;
    edsr_AmoutTx3.DataField := 'sr_AmoutTx3';
    edsr_AmoutTx3.DataSource := DataSource;
    edsr_AmoutTotal.SetParentComponent(Self);
    edsr_AmoutTotal.Name := 'edsr_AmoutTotal';
    edsr_AmoutTotal.Left := 221;
    edsr_AmoutTotal.Top := 378;
    edsr_AmoutTotal.Width := 200;
    edsr_AmoutTotal.Height := 21;
    edsr_AmoutTotal.HeightPercent := 100.000000000000000000;
    edsr_AmoutTotal.TabOrder := 13;
    edsr_AmoutTotal.Text := 'edsr_AmoutTotal';
    edsr_AmoutTotal.WidthPercent := 100.000000000000000000;
    edsr_AmoutTotal.DataField := 'sr_AmoutTotal';
    edsr_AmoutTotal.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := SalesReportsDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    SalesReportsDataset.SetParentComponent(Self);
    SalesReportsDataset.Name := 'SalesReportsDataset';
    SalesReportsDataset.AfterApplyUpdates := SalesReportsDatasetAfterApplyUpdates;
    SalesReportsDataset.EntitySetName := 'SalesReports';
    SalesReportsDataset.Connection := DMConnection.ApiConnection;
    SalesReportsDataset.Left := 176;
    SalesReportsDataset.Top := 8;
    SalesReportsDatasetsr_Id.SetParentComponent(SalesReportsDataset);
    SalesReportsDatasetsr_Id.Name := 'SalesReportsDatasetsr_Id';
    SalesReportsDatasetsr_Id.FieldName := 'sr_Id';
    SalesReportsDatasetsr_Id.Required := True;
    SalesReportsDatasetsr_Date.SetParentComponent(SalesReportsDataset);
    SalesReportsDatasetsr_Date.Name := 'SalesReportsDatasetsr_Date';
    SalesReportsDatasetsr_Date.FieldName := 'sr_Date';
    SalesReportsDatasetsr_By.SetParentComponent(SalesReportsDataset);
    SalesReportsDatasetsr_By.Name := 'SalesReportsDatasetsr_By';
    SalesReportsDatasetsr_By.FieldName := 'sr_By';
    SalesReportsDatasetsr_Amout.SetParentComponent(SalesReportsDataset);
    SalesReportsDatasetsr_Amout.Name := 'SalesReportsDatasetsr_Amout';
    SalesReportsDatasetsr_Amout.FieldName := 'sr_Amout';
    SalesReportsDatasetsr_AmoutTx1.SetParentComponent(SalesReportsDataset);
    SalesReportsDatasetsr_AmoutTx1.Name := 'SalesReportsDatasetsr_AmoutTx1';
    SalesReportsDatasetsr_AmoutTx1.FieldName := 'sr_AmoutTx1';
    SalesReportsDatasetsr_AmoutTx2.SetParentComponent(SalesReportsDataset);
    SalesReportsDatasetsr_AmoutTx2.Name := 'SalesReportsDatasetsr_AmoutTx2';
    SalesReportsDatasetsr_AmoutTx2.FieldName := 'sr_AmoutTx2';
    SalesReportsDatasetsr_AmoutTx3.SetParentComponent(SalesReportsDataset);
    SalesReportsDatasetsr_AmoutTx3.Name := 'SalesReportsDatasetsr_AmoutTx3';
    SalesReportsDatasetsr_AmoutTx3.FieldName := 'sr_AmoutTx3';
    SalesReportsDatasetsr_AmoutTotal.SetParentComponent(SalesReportsDataset);
    SalesReportsDatasetsr_AmoutTotal.Name := 'SalesReportsDatasetsr_AmoutTotal';
    SalesReportsDatasetsr_AmoutTotal.FieldName := 'sr_AmoutTotal';
    XDataClientSalesReportsGet.SetParentComponent(Self);
    XDataClientSalesReportsGet.Name := 'XDataClientSalesReportsGet';
    XDataClientSalesReportsGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientSalesReportsGet, Self, 'OnLoad', 'XDataClientSalesReportsGetLoad');
    XDataClientSalesReportsGet.Left := 64;
    XDataClientSalesReportsGet.Top := 8;
    XDataClientSalesReportsDelete.SetParentComponent(Self);
    XDataClientSalesReportsDelete.Name := 'XDataClientSalesReportsDelete';
    XDataClientSalesReportsDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientSalesReportsDelete, Self, 'OnLoad', 'XDataClientSalesReportsDeleteLoad');
    SetEvent(XDataClientSalesReportsDelete, Self, 'OnError', 'XDataClientSalesReportsDeleteError');
    XDataClientSalesReportsDelete.Left := 64;
    XDataClientSalesReportsDelete.Top := 56;
  finally
    lbsr_Id.AfterLoadDFMValues;
    lbsr_Date.AfterLoadDFMValues;
    lbsr_By.AfterLoadDFMValues;
    lbsr_Amout.AfterLoadDFMValues;
    lbsr_AmoutTx1.AfterLoadDFMValues;
    lbsr_AmoutTx2.AfterLoadDFMValues;
    lbsr_AmoutTx3.AfterLoadDFMValues;
    lbsr_AmoutTotal.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edsr_Id.AfterLoadDFMValues;
    edsr_Date.AfterLoadDFMValues;
    edsr_By.AfterLoadDFMValues;
    edsr_Amout.AfterLoadDFMValues;
    edsr_AmoutTx1.AfterLoadDFMValues;
    edsr_AmoutTx2.AfterLoadDFMValues;
    edsr_AmoutTx3.AfterLoadDFMValues;
    edsr_AmoutTotal.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    SalesReportsDataset.AfterLoadDFMValues;
    SalesReportsDatasetsr_Id.AfterLoadDFMValues;
    SalesReportsDatasetsr_Date.AfterLoadDFMValues;
    SalesReportsDatasetsr_By.AfterLoadDFMValues;
    SalesReportsDatasetsr_Amout.AfterLoadDFMValues;
    SalesReportsDatasetsr_AmoutTx1.AfterLoadDFMValues;
    SalesReportsDatasetsr_AmoutTx2.AfterLoadDFMValues;
    SalesReportsDatasetsr_AmoutTx3.AfterLoadDFMValues;
    SalesReportsDatasetsr_AmoutTotal.AfterLoadDFMValues;
    XDataClientSalesReportsGet.AfterLoadDFMValues;
    XDataClientSalesReportsDelete.AfterLoadDFMValues;
  end;
end;

end.
