unit View.ContratModelType.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewContratModelTypeEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbtyp_Id: TLabel;
    edtyp_Id: TDBEdit;
    lbtype_Name: TLabel;
    edtype_Name: TDBEdit;
    lbtype_Notes: TLabel;
    edtype_Notes: TDBEdit;
    XDataClientContratModelTypeGet: TXDataWebClient;
    XDataClientContratModelTypeDelete: TXDataWebClient;
    ContratModelTypeDataset: TXDataWebDataSet;
    ContratModelTypeDatasettyp_Id: TIntegerField;
    ContratModelTypeDatasettype_Name: TStringField;
    ContratModelTypeDatasettype_Notes: TStringField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientContratModelTypeGetLoad(Response: TXDataClientResponse);
    procedure XDataClientContratModelTypeDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientContratModelTypeDeleteError(Error: TXDataClientError);
    procedure ContratModelTypeDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewContratModelTypeEdit: TFViewContratModelTypeEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewContratModelTypeEdit }

procedure TFViewContratModelTypeEdit.btSaveClick(Sender: TObject);
begin
  if ContratModelTypeDataset.State in dsEditModes then
  begin
        ContratModelTypeDataset.Post;
    ContratModelTypeDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewContratModelTypeEdit.btDeleteClick(Sender: TObject);
begin
  if ContratModelTypeDataset.State in dsEditModes then
    ContratModelTypeDataset.Post;
  XDataClientContratModelTypeDelete.Delete(ContratModelTypeDataset.EntitySetName,
    TJSObject(ContratModelTypeDataset.CurrentData));
end;


class function TFViewContratModelTypeEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewContratModelTypeEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewContratModelTypeEdit.InsertEntity;
begin
  ContratModelTypeDataset.Close;
  ContratModelTypeDataset.SetJsonData('{}');
  ContratModelTypeDataset.Open;
  ContratModelTypeDataset.Insert;
end;

class function TFViewContratModelTypeEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewContratModelTypeEdit(AForm).FInserting := AInserting;
    TFViewContratModelTypeEdit(AForm).FId := AId;
    TFViewContratModelTypeEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewContratModelTypeEdit(AForm).InsertEntity
    else
      TFViewContratModelTypeEdit(AForm).LoadEntity;
    TFViewContratModelTypeEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewContratModelTypeEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewContratModelTypeEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    ContratModelTypeDataset.Connection.Model.DefaultSchema.FindEntityType(
      ContratModelTypeDataset.EntitySetName
    )
  );
  XDataClientContratModelTypeGet.Get(ContratModelTypeDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewContratModelTypeEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewContratModelTypeEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewContratModelTypeEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewContratModelTypeEdit.XDataClientContratModelTypeGetLoad(Response: TXDataClientResponse);
begin
  ContratModelTypeDataset.Close;
  ContratModelTypeDataset.SetJsonData(Response.Result);
  ContratModelTypeDataset.Open;
  
  ContratModelTypeDataset.Edit;  
end;

procedure TFViewContratModelTypeEdit.XDataClientContratModelTypeDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewContratModelTypeEdit.XDataClientContratModelTypeDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewContratModelTypeEdit.ContratModelTypeDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewContratModelTypeEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewContratModelTypeEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbtyp_Id := TLabel.Create('view.contratmodeltype.form.lbtyp_Id');
  lbtype_Name := TLabel.Create('view.contratmodeltype.form.lbtype_Name');
  lbtype_Notes := TLabel.Create('view.contratmodeltype.form.lbtype_Notes');
  pnlMessage := TPanel.Create('view.contratmodeltype.form.message');
  lbMessage := TLabel.Create('view.contratmodeltype.form.message.label');
  btCloseMessage := TButton.Create('view.contratmodeltype.form.message.button');
  btCancel := TButton.Create('view.contratmodeltype.form.btBack');
  btSave := TButton.Create('view.contratmodeltype.form.btSave');
  btDelete := TButton.Create('view.contratmodeltype.form.btDelete');
  edtyp_Id := TDBEdit.Create('view.contratmodeltype.form.edtyp_Id');
  edtype_Name := TDBEdit.Create('view.contratmodeltype.form.edtype_Name');
  edtype_Notes := TDBEdit.Create('view.contratmodeltype.form.edtype_Notes');
  DataSource := TDataSource.Create(Self);
  ContratModelTypeDataset := TXDataWebDataSet.Create(Self);
  ContratModelTypeDatasettyp_Id := TIntegerField.Create(Self);
  ContratModelTypeDatasettype_Name := TStringField.Create(Self);
  ContratModelTypeDatasettype_Notes := TStringField.Create(Self);
  XDataClientContratModelTypeGet := TXDataWebClient.Create(Self);
  XDataClientContratModelTypeDelete := TXDataWebClient.Create(Self);

  lbtyp_Id.BeforeLoadDFMValues;
  lbtype_Name.BeforeLoadDFMValues;
  lbtype_Notes.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edtyp_Id.BeforeLoadDFMValues;
  edtype_Name.BeforeLoadDFMValues;
  edtype_Notes.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  ContratModelTypeDataset.BeforeLoadDFMValues;
  ContratModelTypeDatasettyp_Id.BeforeLoadDFMValues;
  ContratModelTypeDatasettype_Name.BeforeLoadDFMValues;
  ContratModelTypeDatasettype_Notes.BeforeLoadDFMValues;
  XDataClientContratModelTypeGet.BeforeLoadDFMValues;
  XDataClientContratModelTypeDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewContratModelTypeEdit';
    Width := 593;
    Height := 346;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbtyp_Id.SetParentComponent(Self);
    lbtyp_Id.Name := 'lbtyp_Id';
    lbtyp_Id.Left := 24;
    lbtyp_Id.Top := 192;
    lbtyp_Id.Width := 36;
    lbtyp_Id.Height := 13;
    lbtyp_Id.Caption := 'typ_Id:';
    lbtyp_Id.HeightPercent := 100.000000000000000000;
    lbtyp_Id.WidthPercent := 100.000000000000000000;
    lbtype_Name.SetParentComponent(Self);
    lbtype_Name.Name := 'lbtype_Name';
    lbtype_Name.Left := 24;
    lbtype_Name.Top := 219;
    lbtype_Name.Width := 59;
    lbtype_Name.Height := 13;
    lbtype_Name.Caption := 'type_Name:';
    lbtype_Name.HeightPercent := 100.000000000000000000;
    lbtype_Name.WidthPercent := 100.000000000000000000;
    lbtype_Notes.SetParentComponent(Self);
    lbtype_Notes.Name := 'lbtype_Notes';
    lbtype_Notes.Left := 24;
    lbtype_Notes.Top := 246;
    lbtype_Notes.Width := 60;
    lbtype_Notes.Height := 13;
    lbtype_Notes.Caption := 'type_Notes:';
    lbtype_Notes.HeightPercent := 100.000000000000000000;
    lbtype_Notes.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 286;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 286;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 286;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edtyp_Id.SetParentComponent(Self);
    edtyp_Id.Name := 'edtyp_Id';
    edtyp_Id.Left := 221;
    edtyp_Id.Top := 189;
    edtyp_Id.Width := 200;
    edtyp_Id.Height := 21;
    edtyp_Id.HeightPercent := 100.000000000000000000;
    edtyp_Id.TabOrder := 6;
    edtyp_Id.Text := 'edtyp_Id';
    edtyp_Id.WidthPercent := 100.000000000000000000;
    edtyp_Id.DataField := 'typ_Id';
    edtyp_Id.DataSource := DataSource;
    edtype_Name.SetParentComponent(Self);
    edtype_Name.Name := 'edtype_Name';
    edtype_Name.Left := 221;
    edtype_Name.Top := 216;
    edtype_Name.Width := 200;
    edtype_Name.Height := 21;
    edtype_Name.HeightPercent := 100.000000000000000000;
    edtype_Name.TabOrder := 7;
    edtype_Name.Text := 'edtype_Name';
    edtype_Name.WidthPercent := 100.000000000000000000;
    edtype_Name.DataField := 'type_Name';
    edtype_Name.DataSource := DataSource;
    edtype_Notes.SetParentComponent(Self);
    edtype_Notes.Name := 'edtype_Notes';
    edtype_Notes.Left := 221;
    edtype_Notes.Top := 243;
    edtype_Notes.Width := 200;
    edtype_Notes.Height := 21;
    edtype_Notes.HeightPercent := 100.000000000000000000;
    edtype_Notes.TabOrder := 8;
    edtype_Notes.Text := 'edtype_Notes';
    edtype_Notes.WidthPercent := 100.000000000000000000;
    edtype_Notes.DataField := 'type_Notes';
    edtype_Notes.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := ContratModelTypeDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    ContratModelTypeDataset.SetParentComponent(Self);
    ContratModelTypeDataset.Name := 'ContratModelTypeDataset';
    ContratModelTypeDataset.AfterApplyUpdates := ContratModelTypeDatasetAfterApplyUpdates;
    ContratModelTypeDataset.EntitySetName := 'ContratModelType';
    ContratModelTypeDataset.Connection := DMConnection.ApiConnection;
    ContratModelTypeDataset.Left := 176;
    ContratModelTypeDataset.Top := 8;
    ContratModelTypeDatasettyp_Id.SetParentComponent(ContratModelTypeDataset);
    ContratModelTypeDatasettyp_Id.Name := 'ContratModelTypeDatasettyp_Id';
    ContratModelTypeDatasettyp_Id.FieldName := 'typ_Id';
    ContratModelTypeDatasettyp_Id.Required := True;
    ContratModelTypeDatasettype_Name.SetParentComponent(ContratModelTypeDataset);
    ContratModelTypeDatasettype_Name.Name := 'ContratModelTypeDatasettype_Name';
    ContratModelTypeDatasettype_Name.FieldName := 'type_Name';
    ContratModelTypeDatasettype_Name.Size := 50;
    ContratModelTypeDatasettype_Notes.SetParentComponent(ContratModelTypeDataset);
    ContratModelTypeDatasettype_Notes.Name := 'ContratModelTypeDatasettype_Notes';
    ContratModelTypeDatasettype_Notes.FieldName := 'type_Notes';
    ContratModelTypeDatasettype_Notes.Size := 500;
    XDataClientContratModelTypeGet.SetParentComponent(Self);
    XDataClientContratModelTypeGet.Name := 'XDataClientContratModelTypeGet';
    XDataClientContratModelTypeGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientContratModelTypeGet, Self, 'OnLoad', 'XDataClientContratModelTypeGetLoad');
    XDataClientContratModelTypeGet.Left := 64;
    XDataClientContratModelTypeGet.Top := 8;
    XDataClientContratModelTypeDelete.SetParentComponent(Self);
    XDataClientContratModelTypeDelete.Name := 'XDataClientContratModelTypeDelete';
    XDataClientContratModelTypeDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientContratModelTypeDelete, Self, 'OnLoad', 'XDataClientContratModelTypeDeleteLoad');
    SetEvent(XDataClientContratModelTypeDelete, Self, 'OnError', 'XDataClientContratModelTypeDeleteError');
    XDataClientContratModelTypeDelete.Left := 64;
    XDataClientContratModelTypeDelete.Top := 56;
  finally
    lbtyp_Id.AfterLoadDFMValues;
    lbtype_Name.AfterLoadDFMValues;
    lbtype_Notes.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edtyp_Id.AfterLoadDFMValues;
    edtype_Name.AfterLoadDFMValues;
    edtype_Notes.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    ContratModelTypeDataset.AfterLoadDFMValues;
    ContratModelTypeDatasettyp_Id.AfterLoadDFMValues;
    ContratModelTypeDatasettype_Name.AfterLoadDFMValues;
    ContratModelTypeDatasettype_Notes.AfterLoadDFMValues;
    XDataClientContratModelTypeGet.AfterLoadDFMValues;
    XDataClientContratModelTypeDelete.AfterLoadDFMValues;
  end;
end;

end.
