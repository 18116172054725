unit View.CampSitePhoto.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewCampSitePhotoEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbcsp_Id: TLabel;
    edcsp_Id: TDBEdit;
    lbcs_Id: TLabel;
    cbcs_Id: TComboBox;
    XDataClientCampSitePhotoGet: TXDataWebClient;
    XDataClientCampSitePhotoDelete: TXDataWebClient;
    CampSitePhotoDataset: TXDataWebDataSet;
    CampSitePhotoDatasetcsp_Id: TIntegerField;
    CampSitePhotoDatasetcs_Id: TXDataWebEntityField;
    cs_IdDataset: TXDataWebDataSet;
    cs_IdDatasetcs_Id: TIntegerField;
    cs_IdDatasetcs_UID: TStringField;
    cs_IdDatasetcs_SiteName: TStringField;
    cs_IdDatasetctr_Id: TIntegerField;
    cs_IdDatasetcs_PseudoLength: TIntegerField;
    cs_IdDatasetcs_PseudoWidth: TIntegerField;
    cs_IdDatasetcs_MaxRVLength: TIntegerField;
    cs_IdDatasetcs_ElecAmp: TIntegerField;
    cs_IdDatasetcs_IsElec: TStringField;
    cs_IdDatasetcs_IsWater: TStringField;
    cs_IdDatasetcs_IsSewer: TStringField;
    cs_IdDatasetcs_IsPullThrough: TStringField;
    cs_IdDatasetcs_IsTentTrailerAddOnPossible: TStringField;
    cs_IdDatasetcs_IsFireTub: TStringField;
    cs_IdDatasetcs_IsPickNickTable: TStringField;
    cs_IdDatasetcs_IsTypeSeasonal: TStringField;
    cs_IdDatasetcs_Notes: TStringField;
    cs_IdDatasetcs_IsUnavailable: TStringField;
    cs_IdDatasetcs_UnavailableFrom: TDateTimeField;
    cs_IdDatasetcs_UnavailableTo: TIntegerField;
    cs_IdDatasetcs_CreatedBy: TIntegerField;
    cs_IdDatasetcs_CreatedDate: TDateTimeField;
    cs_IdDatasetcs_LastModifiedBy: TIntegerField;
    cs_IdDatasetcs_LastModifiedDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientCampSitePhotoGetLoad(Response: TXDataClientResponse);
    procedure XDataClientCampSitePhotoDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientCampSitePhotoDeleteError(Error: TXDataClientError);
    procedure CampSitePhotoDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
    procedure cs_IdDatasetAfterOpen(DataSet: TDataSet);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewCampSitePhotoEdit: TFViewCampSitePhotoEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewCampSitePhotoEdit }

procedure TFViewCampSitePhotoEdit.btSaveClick(Sender: TObject);
begin
  if CampSitePhotoDataset.State in dsEditModes then
  begin
        CampSitePhotoDatasetcs_Id.Value := cbcs_Id.Items.Objects[cbcs_Id.ItemIndex];
    CampSitePhotoDataset.Post;
    CampSitePhotoDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewCampSitePhotoEdit.btDeleteClick(Sender: TObject);
begin
  if CampSitePhotoDataset.State in dsEditModes then
    CampSitePhotoDataset.Post;
  XDataClientCampSitePhotoDelete.Delete(CampSitePhotoDataset.EntitySetName,
    TJSObject(CampSitePhotoDataset.CurrentData));
end;


class function TFViewCampSitePhotoEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewCampSitePhotoEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewCampSitePhotoEdit.InsertEntity;
begin
  CampSitePhotoDataset.Close;
  CampSitePhotoDataset.SetJsonData('{}');
  CampSitePhotoDataset.Open;
  CampSitePhotoDataset.Insert;
  TCrudUtils.LoadAssociationDataset(cs_IdDataset, 'cs_UID');
end;

class function TFViewCampSitePhotoEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewCampSitePhotoEdit(AForm).FInserting := AInserting;
    TFViewCampSitePhotoEdit(AForm).FId := AId;
    TFViewCampSitePhotoEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewCampSitePhotoEdit(AForm).InsertEntity
    else
      TFViewCampSitePhotoEdit(AForm).LoadEntity;
    TFViewCampSitePhotoEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewCampSitePhotoEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewCampSitePhotoEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    CampSitePhotoDataset.Connection.Model.DefaultSchema.FindEntityType(
      CampSitePhotoDataset.EntitySetName
    )
  );
  XDataClientCampSitePhotoGet.Get(CampSitePhotoDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewCampSitePhotoEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewCampSitePhotoEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewCampSitePhotoEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewCampSitePhotoEdit.XDataClientCampSitePhotoGetLoad(Response: TXDataClientResponse);
begin
  CampSitePhotoDataset.Close;
  CampSitePhotoDataset.SetJsonData(Response.Result);
  CampSitePhotoDataset.Open;
  
  CampSitePhotoDataset.Edit;  
  TCrudUtils.LoadAssociationDataset(cs_IdDataset, 'cs_UID');
end;

procedure TFViewCampSitePhotoEdit.XDataClientCampSitePhotoDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewCampSitePhotoEdit.XDataClientCampSitePhotoDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewCampSitePhotoEdit.CampSitePhotoDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewCampSitePhotoEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewCampSitePhotoEdit.cs_IdDatasetAfterOpen(DataSet: TDataSet);
begin
  TCrudUtils.PopulateAssociationCombobox(cbcs_Id,
    TXDataWebDataSet(DataSet), 'cs_UID');

  if FInserting then
    cbcs_Id.ItemIndex := -1
  else
  begin
    TCrudUtils.SelectAssociationItem(cbcs_Id, CampSitePhotoDatasetcs_Id);
  end;
end;

procedure TFViewCampSitePhotoEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbcsp_Id := TLabel.Create('view.campsitephoto.form.lbcsp_Id');
  lbcs_Id := TLabel.Create('view.campsitephoto.form.lbcs_Id');
  pnlMessage := TPanel.Create('view.campsitephoto.form.message');
  lbMessage := TLabel.Create('view.campsitephoto.form.message.label');
  btCloseMessage := TButton.Create('view.campsitephoto.form.message.button');
  btCancel := TButton.Create('view.campsitephoto.form.btBack');
  btSave := TButton.Create('view.campsitephoto.form.btSave');
  btDelete := TButton.Create('view.campsitephoto.form.btDelete');
  edcsp_Id := TDBEdit.Create('view.campsitephoto.form.edcsp_Id');
  cbcs_Id := TComboBox.Create('view.campsitephoto.form.cbcs_Id');
  DataSource := TDataSource.Create(Self);
  CampSitePhotoDataset := TXDataWebDataSet.Create(Self);
  CampSitePhotoDatasetcsp_Id := TIntegerField.Create(Self);
  CampSitePhotoDatasetcs_Id := TXDataWebEntityField.Create(Self);
  cs_IdDataset := TXDataWebDataSet.Create(Self);
  cs_IdDatasetcs_Id := TIntegerField.Create(Self);
  cs_IdDatasetcs_UID := TStringField.Create(Self);
  cs_IdDatasetcs_SiteName := TStringField.Create(Self);
  cs_IdDatasetctr_Id := TIntegerField.Create(Self);
  cs_IdDatasetcs_PseudoLength := TIntegerField.Create(Self);
  cs_IdDatasetcs_PseudoWidth := TIntegerField.Create(Self);
  cs_IdDatasetcs_MaxRVLength := TIntegerField.Create(Self);
  cs_IdDatasetcs_ElecAmp := TIntegerField.Create(Self);
  cs_IdDatasetcs_IsElec := TStringField.Create(Self);
  cs_IdDatasetcs_IsWater := TStringField.Create(Self);
  cs_IdDatasetcs_IsSewer := TStringField.Create(Self);
  cs_IdDatasetcs_IsPullThrough := TStringField.Create(Self);
  cs_IdDatasetcs_IsTentTrailerAddOnPossible := TStringField.Create(Self);
  cs_IdDatasetcs_IsFireTub := TStringField.Create(Self);
  cs_IdDatasetcs_IsPickNickTable := TStringField.Create(Self);
  cs_IdDatasetcs_IsTypeSeasonal := TStringField.Create(Self);
  cs_IdDatasetcs_Notes := TStringField.Create(Self);
  cs_IdDatasetcs_IsUnavailable := TStringField.Create(Self);
  cs_IdDatasetcs_UnavailableFrom := TDateTimeField.Create(Self);
  cs_IdDatasetcs_UnavailableTo := TIntegerField.Create(Self);
  cs_IdDatasetcs_CreatedBy := TIntegerField.Create(Self);
  cs_IdDatasetcs_CreatedDate := TDateTimeField.Create(Self);
  cs_IdDatasetcs_LastModifiedBy := TIntegerField.Create(Self);
  cs_IdDatasetcs_LastModifiedDate := TDateTimeField.Create(Self);
  XDataClientCampSitePhotoGet := TXDataWebClient.Create(Self);
  XDataClientCampSitePhotoDelete := TXDataWebClient.Create(Self);

  lbcsp_Id.BeforeLoadDFMValues;
  lbcs_Id.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edcsp_Id.BeforeLoadDFMValues;
  cbcs_Id.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  CampSitePhotoDataset.BeforeLoadDFMValues;
  CampSitePhotoDatasetcsp_Id.BeforeLoadDFMValues;
  CampSitePhotoDatasetcs_Id.BeforeLoadDFMValues;
  cs_IdDataset.BeforeLoadDFMValues;
  cs_IdDatasetcs_Id.BeforeLoadDFMValues;
  cs_IdDatasetcs_UID.BeforeLoadDFMValues;
  cs_IdDatasetcs_SiteName.BeforeLoadDFMValues;
  cs_IdDatasetctr_Id.BeforeLoadDFMValues;
  cs_IdDatasetcs_PseudoLength.BeforeLoadDFMValues;
  cs_IdDatasetcs_PseudoWidth.BeforeLoadDFMValues;
  cs_IdDatasetcs_MaxRVLength.BeforeLoadDFMValues;
  cs_IdDatasetcs_ElecAmp.BeforeLoadDFMValues;
  cs_IdDatasetcs_IsElec.BeforeLoadDFMValues;
  cs_IdDatasetcs_IsWater.BeforeLoadDFMValues;
  cs_IdDatasetcs_IsSewer.BeforeLoadDFMValues;
  cs_IdDatasetcs_IsPullThrough.BeforeLoadDFMValues;
  cs_IdDatasetcs_IsTentTrailerAddOnPossible.BeforeLoadDFMValues;
  cs_IdDatasetcs_IsFireTub.BeforeLoadDFMValues;
  cs_IdDatasetcs_IsPickNickTable.BeforeLoadDFMValues;
  cs_IdDatasetcs_IsTypeSeasonal.BeforeLoadDFMValues;
  cs_IdDatasetcs_Notes.BeforeLoadDFMValues;
  cs_IdDatasetcs_IsUnavailable.BeforeLoadDFMValues;
  cs_IdDatasetcs_UnavailableFrom.BeforeLoadDFMValues;
  cs_IdDatasetcs_UnavailableTo.BeforeLoadDFMValues;
  cs_IdDatasetcs_CreatedBy.BeforeLoadDFMValues;
  cs_IdDatasetcs_CreatedDate.BeforeLoadDFMValues;
  cs_IdDatasetcs_LastModifiedBy.BeforeLoadDFMValues;
  cs_IdDatasetcs_LastModifiedDate.BeforeLoadDFMValues;
  XDataClientCampSitePhotoGet.BeforeLoadDFMValues;
  XDataClientCampSitePhotoDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewCampSitePhotoEdit';
    Width := 593;
    Height := 319;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbcsp_Id.SetParentComponent(Self);
    lbcsp_Id.Name := 'lbcsp_Id';
    lbcsp_Id.Left := 24;
    lbcsp_Id.Top := 192;
    lbcsp_Id.Width := 36;
    lbcsp_Id.Height := 13;
    lbcsp_Id.Caption := 'csp_Id:';
    lbcsp_Id.HeightPercent := 100.000000000000000000;
    lbcsp_Id.WidthPercent := 100.000000000000000000;
    lbcs_Id.SetParentComponent(Self);
    lbcs_Id.Name := 'lbcs_Id';
    lbcs_Id.Left := 24;
    lbcs_Id.Top := 219;
    lbcs_Id.Width := 30;
    lbcs_Id.Height := 13;
    lbcs_Id.Caption := 'cs_Id:';
    lbcs_Id.HeightPercent := 100.000000000000000000;
    lbcs_Id.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 259;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 259;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 259;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edcsp_Id.SetParentComponent(Self);
    edcsp_Id.Name := 'edcsp_Id';
    edcsp_Id.Left := 221;
    edcsp_Id.Top := 189;
    edcsp_Id.Width := 200;
    edcsp_Id.Height := 21;
    edcsp_Id.HeightPercent := 100.000000000000000000;
    edcsp_Id.TabOrder := 6;
    edcsp_Id.Text := 'edcsp_Id';
    edcsp_Id.WidthPercent := 100.000000000000000000;
    edcsp_Id.DataField := 'csp_Id';
    edcsp_Id.DataSource := DataSource;
    cbcs_Id.SetParentComponent(Self);
    cbcs_Id.Name := 'cbcs_Id';
    cbcs_Id.Left := 221;
    cbcs_Id.Top := 216;
    cbcs_Id.Width := 200;
    cbcs_Id.Height := 21;
    cbcs_Id.HeightPercent := 100.000000000000000000;
    cbcs_Id.TabOrder := 7;
    cbcs_Id.Text := 'cbcs_Id';
    cbcs_Id.WidthPercent := 100.000000000000000000;
    cbcs_Id.ItemIndex := -1;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := CampSitePhotoDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    CampSitePhotoDataset.SetParentComponent(Self);
    CampSitePhotoDataset.Name := 'CampSitePhotoDataset';
    CampSitePhotoDataset.AfterApplyUpdates := CampSitePhotoDatasetAfterApplyUpdates;
    CampSitePhotoDataset.EntitySetName := 'CampSitePhoto';
    CampSitePhotoDataset.Connection := DMConnection.ApiConnection;
    CampSitePhotoDataset.Left := 176;
    CampSitePhotoDataset.Top := 8;
    CampSitePhotoDatasetcsp_Id.SetParentComponent(CampSitePhotoDataset);
    CampSitePhotoDatasetcsp_Id.Name := 'CampSitePhotoDatasetcsp_Id';
    CampSitePhotoDatasetcsp_Id.FieldName := 'csp_Id';
    CampSitePhotoDatasetcsp_Id.Required := True;
    CampSitePhotoDatasetcs_Id.SetParentComponent(CampSitePhotoDataset);
    CampSitePhotoDatasetcs_Id.Name := 'CampSitePhotoDatasetcs_Id';
    CampSitePhotoDatasetcs_Id.FieldName := 'cs_Id';
    CampSitePhotoDatasetcs_Id.Required := True;
    cs_IdDataset.SetParentComponent(Self);
    cs_IdDataset.Name := 'cs_IdDataset';
    cs_IdDataset.AfterOpen := cs_IdDatasetAfterOpen;
    cs_IdDataset.EntitySetName := 'CampSite';
    cs_IdDataset.Connection := DMConnection.ApiConnection;
    cs_IdDataset.Left := 284;
    cs_IdDataset.Top := 8;
    cs_IdDatasetcs_Id.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_Id.Name := 'cs_IdDatasetcs_Id';
    cs_IdDatasetcs_Id.FieldName := 'cs_Id';
    cs_IdDatasetcs_Id.Required := True;
    cs_IdDatasetcs_UID.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_UID.Name := 'cs_IdDatasetcs_UID';
    cs_IdDatasetcs_UID.FieldName := 'cs_UID';
    cs_IdDatasetcs_UID.Size := 15;
    cs_IdDatasetcs_SiteName.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_SiteName.Name := 'cs_IdDatasetcs_SiteName';
    cs_IdDatasetcs_SiteName.FieldName := 'cs_SiteName';
    cs_IdDatasetcs_SiteName.Size := 50;
    cs_IdDatasetctr_Id.SetParentComponent(cs_IdDataset);
    cs_IdDatasetctr_Id.Name := 'cs_IdDatasetctr_Id';
    cs_IdDatasetctr_Id.FieldName := 'ctr_Id';
    cs_IdDatasetcs_PseudoLength.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_PseudoLength.Name := 'cs_IdDatasetcs_PseudoLength';
    cs_IdDatasetcs_PseudoLength.FieldName := 'cs_PseudoLength';
    cs_IdDatasetcs_PseudoWidth.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_PseudoWidth.Name := 'cs_IdDatasetcs_PseudoWidth';
    cs_IdDatasetcs_PseudoWidth.FieldName := 'cs_PseudoWidth';
    cs_IdDatasetcs_MaxRVLength.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_MaxRVLength.Name := 'cs_IdDatasetcs_MaxRVLength';
    cs_IdDatasetcs_MaxRVLength.FieldName := 'cs_MaxRVLength';
    cs_IdDatasetcs_ElecAmp.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_ElecAmp.Name := 'cs_IdDatasetcs_ElecAmp';
    cs_IdDatasetcs_ElecAmp.FieldName := 'cs_ElecAmp';
    cs_IdDatasetcs_IsElec.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_IsElec.Name := 'cs_IdDatasetcs_IsElec';
    cs_IdDatasetcs_IsElec.FieldName := 'cs_IsElec';
    cs_IdDatasetcs_IsElec.Size := 255;
    cs_IdDatasetcs_IsWater.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_IsWater.Name := 'cs_IdDatasetcs_IsWater';
    cs_IdDatasetcs_IsWater.FieldName := 'cs_IsWater';
    cs_IdDatasetcs_IsWater.Size := 255;
    cs_IdDatasetcs_IsSewer.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_IsSewer.Name := 'cs_IdDatasetcs_IsSewer';
    cs_IdDatasetcs_IsSewer.FieldName := 'cs_IsSewer';
    cs_IdDatasetcs_IsSewer.Size := 255;
    cs_IdDatasetcs_IsPullThrough.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_IsPullThrough.Name := 'cs_IdDatasetcs_IsPullThrough';
    cs_IdDatasetcs_IsPullThrough.FieldName := 'cs_IsPullThrough';
    cs_IdDatasetcs_IsPullThrough.Size := 255;
    cs_IdDatasetcs_IsTentTrailerAddOnPossible.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_IsTentTrailerAddOnPossible.Name := 'cs_IdDatasetcs_IsTentTrailerAddOnPossible';
    cs_IdDatasetcs_IsTentTrailerAddOnPossible.FieldName := 'cs_IsTentTrailerAddOnPossible';
    cs_IdDatasetcs_IsTentTrailerAddOnPossible.Size := 255;
    cs_IdDatasetcs_IsFireTub.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_IsFireTub.Name := 'cs_IdDatasetcs_IsFireTub';
    cs_IdDatasetcs_IsFireTub.FieldName := 'cs_IsFireTub';
    cs_IdDatasetcs_IsFireTub.Size := 255;
    cs_IdDatasetcs_IsPickNickTable.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_IsPickNickTable.Name := 'cs_IdDatasetcs_IsPickNickTable';
    cs_IdDatasetcs_IsPickNickTable.FieldName := 'cs_IsPickNickTable';
    cs_IdDatasetcs_IsPickNickTable.Size := 255;
    cs_IdDatasetcs_IsTypeSeasonal.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_IsTypeSeasonal.Name := 'cs_IdDatasetcs_IsTypeSeasonal';
    cs_IdDatasetcs_IsTypeSeasonal.FieldName := 'cs_IsTypeSeasonal';
    cs_IdDatasetcs_IsTypeSeasonal.Size := 255;
    cs_IdDatasetcs_Notes.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_Notes.Name := 'cs_IdDatasetcs_Notes';
    cs_IdDatasetcs_Notes.FieldName := 'cs_Notes';
    cs_IdDatasetcs_Notes.Size := 500;
    cs_IdDatasetcs_IsUnavailable.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_IsUnavailable.Name := 'cs_IdDatasetcs_IsUnavailable';
    cs_IdDatasetcs_IsUnavailable.FieldName := 'cs_IsUnavailable';
    cs_IdDatasetcs_IsUnavailable.Size := 255;
    cs_IdDatasetcs_UnavailableFrom.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_UnavailableFrom.Name := 'cs_IdDatasetcs_UnavailableFrom';
    cs_IdDatasetcs_UnavailableFrom.FieldName := 'cs_UnavailableFrom';
    cs_IdDatasetcs_UnavailableTo.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_UnavailableTo.Name := 'cs_IdDatasetcs_UnavailableTo';
    cs_IdDatasetcs_UnavailableTo.FieldName := 'cs_UnavailableTo';
    cs_IdDatasetcs_CreatedBy.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_CreatedBy.Name := 'cs_IdDatasetcs_CreatedBy';
    cs_IdDatasetcs_CreatedBy.FieldName := 'cs_CreatedBy';
    cs_IdDatasetcs_CreatedDate.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_CreatedDate.Name := 'cs_IdDatasetcs_CreatedDate';
    cs_IdDatasetcs_CreatedDate.FieldName := 'cs_CreatedDate';
    cs_IdDatasetcs_LastModifiedBy.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_LastModifiedBy.Name := 'cs_IdDatasetcs_LastModifiedBy';
    cs_IdDatasetcs_LastModifiedBy.FieldName := 'cs_LastModifiedBy';
    cs_IdDatasetcs_LastModifiedDate.SetParentComponent(cs_IdDataset);
    cs_IdDatasetcs_LastModifiedDate.Name := 'cs_IdDatasetcs_LastModifiedDate';
    cs_IdDatasetcs_LastModifiedDate.FieldName := 'cs_LastModifiedDate';
    XDataClientCampSitePhotoGet.SetParentComponent(Self);
    XDataClientCampSitePhotoGet.Name := 'XDataClientCampSitePhotoGet';
    XDataClientCampSitePhotoGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientCampSitePhotoGet, Self, 'OnLoad', 'XDataClientCampSitePhotoGetLoad');
    XDataClientCampSitePhotoGet.Left := 64;
    XDataClientCampSitePhotoGet.Top := 8;
    XDataClientCampSitePhotoDelete.SetParentComponent(Self);
    XDataClientCampSitePhotoDelete.Name := 'XDataClientCampSitePhotoDelete';
    XDataClientCampSitePhotoDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientCampSitePhotoDelete, Self, 'OnLoad', 'XDataClientCampSitePhotoDeleteLoad');
    SetEvent(XDataClientCampSitePhotoDelete, Self, 'OnError', 'XDataClientCampSitePhotoDeleteError');
    XDataClientCampSitePhotoDelete.Left := 64;
    XDataClientCampSitePhotoDelete.Top := 56;
  finally
    lbcsp_Id.AfterLoadDFMValues;
    lbcs_Id.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edcsp_Id.AfterLoadDFMValues;
    cbcs_Id.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    CampSitePhotoDataset.AfterLoadDFMValues;
    CampSitePhotoDatasetcsp_Id.AfterLoadDFMValues;
    CampSitePhotoDatasetcs_Id.AfterLoadDFMValues;
    cs_IdDataset.AfterLoadDFMValues;
    cs_IdDatasetcs_Id.AfterLoadDFMValues;
    cs_IdDatasetcs_UID.AfterLoadDFMValues;
    cs_IdDatasetcs_SiteName.AfterLoadDFMValues;
    cs_IdDatasetctr_Id.AfterLoadDFMValues;
    cs_IdDatasetcs_PseudoLength.AfterLoadDFMValues;
    cs_IdDatasetcs_PseudoWidth.AfterLoadDFMValues;
    cs_IdDatasetcs_MaxRVLength.AfterLoadDFMValues;
    cs_IdDatasetcs_ElecAmp.AfterLoadDFMValues;
    cs_IdDatasetcs_IsElec.AfterLoadDFMValues;
    cs_IdDatasetcs_IsWater.AfterLoadDFMValues;
    cs_IdDatasetcs_IsSewer.AfterLoadDFMValues;
    cs_IdDatasetcs_IsPullThrough.AfterLoadDFMValues;
    cs_IdDatasetcs_IsTentTrailerAddOnPossible.AfterLoadDFMValues;
    cs_IdDatasetcs_IsFireTub.AfterLoadDFMValues;
    cs_IdDatasetcs_IsPickNickTable.AfterLoadDFMValues;
    cs_IdDatasetcs_IsTypeSeasonal.AfterLoadDFMValues;
    cs_IdDatasetcs_Notes.AfterLoadDFMValues;
    cs_IdDatasetcs_IsUnavailable.AfterLoadDFMValues;
    cs_IdDatasetcs_UnavailableFrom.AfterLoadDFMValues;
    cs_IdDatasetcs_UnavailableTo.AfterLoadDFMValues;
    cs_IdDatasetcs_CreatedBy.AfterLoadDFMValues;
    cs_IdDatasetcs_CreatedDate.AfterLoadDFMValues;
    cs_IdDatasetcs_LastModifiedBy.AfterLoadDFMValues;
    cs_IdDatasetcs_LastModifiedDate.AfterLoadDFMValues;
    XDataClientCampSitePhotoGet.AfterLoadDFMValues;
    XDataClientCampSitePhotoDelete.AfterLoadDFMValues;
  end;
end;

end.
