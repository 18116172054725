unit View.Season.List;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, Math,

  WEBLib.StdCtrls, Web, JS, WEBLib.Lists,

  App.Types, WEBLib.Controls, WEBLib.Grids, Data.DB,
  XData.Web.Dataset, WEBLib.DBCtrls, WEBLib.DB, WEBLib.ExtCtrls,

  XData.Web.JsonDataset,

  Grid.Plugins,
  Crud.Utils;

type
  TFViewSeasonList = class(TForm)
    btNew: TButton;
    SeasonDataset: TXDataWebDataSet;
    SeasonDatasetSeasonId: TIntegerField;
    SeasonDatasetSeasonName: TStringField;
    SeasonDatasetSeasonOpenningDate: TDateTimeField;
    SeasonDatasetSeasonClosingDate: TDateTimeField;
    WebDBTableControl1: TDBTableControl;
    WebDataSource: TDataSource;
    cbPageSize: TComboBox;
    edSearch: TEdit;
    lbPaginationInfo: TLabel;
    lcPaginator: TListControl;
    procedure WebFormCreate(Sender: TObject);
    procedure btNewClick(Sender: TObject);
    procedure WebDBTableControl1GetCellChildren(Sender: TObject; ACol,
      ARow: Integer; AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure cbPageSizeChange(Sender: TObject);
    procedure edSearchChange(Sender: TObject);
  private
    FEditProc: TEditProc;
    FGridPlugin: TGridPlugin;
  public
    class function CreateForm(AElementID: string; AShowEditProc: TEditProc): TForm;
  protected procedure LoadDFMValues; override; end;

var
  FViewSeasonList: TFViewSeasonList;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewSeasonList }

procedure TFViewSeasonList.cbPageSizeChange(Sender: TObject);
begin
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text), True);
end;

class function TFViewSeasonList.CreateForm(AElementID: string;
  AShowEditProc: TEditProc): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewSeasonList(AForm).FEditProc := AShowEditProc;
  end;

begin
  Application.CreateForm(TFViewSeasonList, AElementID, Result, @AfterCreate);
end;

procedure TFViewSeasonList.edSearchChange(Sender: TObject);
begin
  FGridPlugin.SetFilterText(edSearch.Text, True);
end;

procedure TFViewSeasonList.btNewClick(Sender: TObject);
begin
  FEditProc( nil );
end;

procedure TFViewSeasonList.WebDBTableControl1GetCellChildren(Sender: TObject;
  ACol, ARow: Integer; AField: TField; AValue: string;
  AElement: TJSHTMLElementRecord);
var
  RecordId: JSValue;

  procedure EditButtonClick(Sender: TObject);
  begin
    FEditProc(RecordId);
  end;

var
  Button: TButton;
begin
  if ARow = 0 then
    Exit;

  if WebDBTableControl1.Columns[ACol].Title = 'Actions' then
  begin
    RecordId := TCrudUtils.GetIdFromObject(
      SeasonDataset.Connection.Model.DefaultSchema.FindEntityType(SeasonDataset.EntitySetName),
      SeasonDataset.CurrentData
    );
    Button := TButton.Create(nil);
    Button.Caption := 'Edit';
    Button.OnClick := @EditButtonClick;
    Button.ElementClassName := 'btn btn-outline btn-default btn-xs';
    Button.ParentElement := AElement.element;
    Button.WidthStyle := TSizeStyle.ssAuto;
    Button.HeightStyle := TSizeStyle.ssAuto;
  end;
end;

procedure TFViewSeasonList.WebFormCreate(Sender: TObject);
begin
  FGridPlugin := TGridPlugin.Create(
    WebDBTableControl1,
    SeasonDataset,
    lcPaginator,
    lbPaginationInfo
  );
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text));
  FGridPlugin.SetFilterText(edSearch.Text);
  FGridPlugin.Load;
end;

procedure TFViewSeasonList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbPaginationInfo := TLabel.Create('view.season.list.pagination.info');
  btNew := TButton.Create('view.season.list.newbutton');
  WebDBTableControl1 := TDBTableControl.Create('view.season.list.table');
  cbPageSize := TComboBox.Create('view.season.list.cbPageSize');
  edSearch := TEdit.Create('view.season.list.search');
  lcPaginator := TListControl.Create('view.season.list.pagination');
  SeasonDataset := TXDataWebDataSet.Create(Self);
  SeasonDatasetSeasonId := TIntegerField.Create(Self);
  SeasonDatasetSeasonName := TStringField.Create(Self);
  SeasonDatasetSeasonOpenningDate := TDateTimeField.Create(Self);
  SeasonDatasetSeasonClosingDate := TDateTimeField.Create(Self);
  WebDataSource := TDataSource.Create(Self);

  lbPaginationInfo.BeforeLoadDFMValues;
  btNew.BeforeLoadDFMValues;
  WebDBTableControl1.BeforeLoadDFMValues;
  cbPageSize.BeforeLoadDFMValues;
  edSearch.BeforeLoadDFMValues;
  lcPaginator.BeforeLoadDFMValues;
  SeasonDataset.BeforeLoadDFMValues;
  SeasonDatasetSeasonId.BeforeLoadDFMValues;
  SeasonDatasetSeasonName.BeforeLoadDFMValues;
  SeasonDatasetSeasonOpenningDate.BeforeLoadDFMValues;
  SeasonDatasetSeasonClosingDate.BeforeLoadDFMValues;
  WebDataSource.BeforeLoadDFMValues;
  try
    Name := 'FViewSeasonList';
    Width := 695;
    Height := 500;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbPaginationInfo.SetParentComponent(Self);
    lbPaginationInfo.Name := 'lbPaginationInfo';
    lbPaginationInfo.Left := 8;
    lbPaginationInfo.Top := 376;
    lbPaginationInfo.Width := 141;
    lbPaginationInfo.Height := 13;
    lbPaginationInfo.Caption := 'Showing 1 to 10 of 57 entries';
    lbPaginationInfo.HeightPercent := 100.000000000000000000;
    lbPaginationInfo.WidthPercent := 100.000000000000000000;
    btNew.SetParentComponent(Self);
    btNew.Name := 'btNew';
    btNew.Left := 8;
    btNew.Top := 8;
    btNew.Width := 50;
    btNew.Height := 25;
    btNew.Caption := 'New';
    btNew.HeightPercent := 100.000000000000000000;
    btNew.WidthPercent := 100.000000000000000000;
    SetEvent(btNew, Self, 'OnClick', 'btNewClick');
    WebDBTableControl1.SetParentComponent(Self);
    WebDBTableControl1.Name := 'WebDBTableControl1';
    WebDBTableControl1.Left := 8;
    WebDBTableControl1.Top := 94;
    WebDBTableControl1.Width := 521;
    WebDBTableControl1.Height := 265;
    WebDBTableControl1.ElementClassName := 'table-responsive';
    WebDBTableControl1.HeightPercent := 100.000000000000000000;
    WebDBTableControl1.WidthPercent := 100.000000000000000000;
    WebDBTableControl1.BorderColor := clSilver;
    WebDBTableControl1.ColHeader := False;
    WebDBTableControl1.ElementTableClassName := 'table table-striped table-bordered table-hover dataTable';
    SetEvent(WebDBTableControl1, Self, 'OnGetCellChildren', 'WebDBTableControl1GetCellChildren');
    WebDBTableControl1.Columns.Clear;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'SeasonId';
      Title := 'SeasonId';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'SeasonName';
      Title := 'SeasonName';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'SeasonOpenningDate';
      Title := 'SeasonOpenningDate';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'SeasonClosingDate';
      Title := 'SeasonClosingDate';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      Title := 'Actions';
    end;
    WebDBTableControl1.DataSource := WebDataSource;
    cbPageSize.SetParentComponent(Self);
    cbPageSize.Name := 'cbPageSize';
    cbPageSize.Left := 8;
    cbPageSize.Top := 67;
    cbPageSize.Width := 81;
    cbPageSize.Height := 21;
    cbPageSize.HeightPercent := 100.000000000000000000;
    cbPageSize.TabOrder := 2;
    cbPageSize.Text := '10';
    cbPageSize.WidthPercent := 100.000000000000000000;
    SetEvent(cbPageSize, Self, 'OnChange', 'cbPageSizeChange');
    cbPageSize.ItemIndex := 0;
    cbPageSize.Items.BeginUpdate;
    try
      cbPageSize.Items.Clear;
      cbPageSize.Items.Add('10');
      cbPageSize.Items.Add('25');
      cbPageSize.Items.Add('50');
      cbPageSize.Items.Add('100');
    finally
      cbPageSize.Items.EndUpdate;
    end;
    edSearch.SetParentComponent(Self);
    edSearch.Name := 'edSearch';
    edSearch.Left := 408;
    edSearch.Top := 67;
    edSearch.Width := 121;
    edSearch.Height := 21;
    edSearch.HeightPercent := 100.000000000000000000;
    edSearch.TabOrder := 3;
    edSearch.WidthPercent := 100.000000000000000000;
    SetEvent(edSearch, Self, 'OnChange', 'edSearchChange');
    lcPaginator.SetParentComponent(Self);
    lcPaginator.Name := 'lcPaginator';
    lcPaginator.Left := 240;
    lcPaginator.Top := 365;
    lcPaginator.Width := 289;
    lcPaginator.Height := 25;
    lcPaginator.HeightPercent := 100.000000000000000000;
    lcPaginator.WidthPercent := 100.000000000000000000;
    lcPaginator.DefaultItemClassName := 'page-item';
    lcPaginator.DefaultItemLinkClassName := 'page-link';
    lcPaginator.ElementListClassName := 'pagination';
    lcPaginator.Style := lsPagination;
    SeasonDataset.SetParentComponent(Self);
    SeasonDataset.Name := 'SeasonDataset';
    SeasonDataset.EntitySetName := 'Season';
    SeasonDataset.Connection := DMConnection.ApiConnection;
    SeasonDataset.Left := 72;
    SeasonDataset.Top := 144;
    SeasonDatasetSeasonId.SetParentComponent(SeasonDataset);
    SeasonDatasetSeasonId.Name := 'SeasonDatasetSeasonId';
    SeasonDatasetSeasonId.FieldName := 'SeasonId';
    SeasonDatasetSeasonId.Required := True;
    SeasonDatasetSeasonName.SetParentComponent(SeasonDataset);
    SeasonDatasetSeasonName.Name := 'SeasonDatasetSeasonName';
    SeasonDatasetSeasonName.FieldName := 'SeasonName';
    SeasonDatasetSeasonName.Size := 50;
    SeasonDatasetSeasonOpenningDate.SetParentComponent(SeasonDataset);
    SeasonDatasetSeasonOpenningDate.Name := 'SeasonDatasetSeasonOpenningDate';
    SeasonDatasetSeasonOpenningDate.FieldName := 'SeasonOpenningDate';
    SeasonDatasetSeasonClosingDate.SetParentComponent(SeasonDataset);
    SeasonDatasetSeasonClosingDate.Name := 'SeasonDatasetSeasonClosingDate';
    SeasonDatasetSeasonClosingDate.FieldName := 'SeasonClosingDate';
    WebDataSource.SetParentComponent(Self);
    WebDataSource.Name := 'WebDataSource';
    WebDataSource.DataSet := SeasonDataset;
    WebDataSource.Left := 72;
    WebDataSource.Top := 200;
  finally
    lbPaginationInfo.AfterLoadDFMValues;
    btNew.AfterLoadDFMValues;
    WebDBTableControl1.AfterLoadDFMValues;
    cbPageSize.AfterLoadDFMValues;
    edSearch.AfterLoadDFMValues;
    lcPaginator.AfterLoadDFMValues;
    SeasonDataset.AfterLoadDFMValues;
    SeasonDatasetSeasonId.AfterLoadDFMValues;
    SeasonDatasetSeasonName.AfterLoadDFMValues;
    SeasonDatasetSeasonOpenningDate.AfterLoadDFMValues;
    SeasonDatasetSeasonClosingDate.AfterLoadDFMValues;
    WebDataSource.AfterLoadDFMValues;
  end;
end;

end.
