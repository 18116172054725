unit View.Season.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewSeasonEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbSeasonId: TLabel;
    edSeasonId: TDBEdit;
    lbSeasonName: TLabel;
    edSeasonName: TDBEdit;
    lbSeasonOpenningDate: TLabel;
    edSeasonOpenningDate: TDBEdit;
    lbSeasonClosingDate: TLabel;
    edSeasonClosingDate: TDBEdit;
    XDataClientSeasonGet: TXDataWebClient;
    XDataClientSeasonDelete: TXDataWebClient;
    SeasonDataset: TXDataWebDataSet;
    SeasonDatasetSeasonId: TIntegerField;
    SeasonDatasetSeasonName: TStringField;
    SeasonDatasetSeasonOpenningDate: TDateTimeField;
    SeasonDatasetSeasonClosingDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientSeasonGetLoad(Response: TXDataClientResponse);
    procedure XDataClientSeasonDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientSeasonDeleteError(Error: TXDataClientError);
    procedure SeasonDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewSeasonEdit: TFViewSeasonEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewSeasonEdit }

procedure TFViewSeasonEdit.btSaveClick(Sender: TObject);
begin
  if SeasonDataset.State in dsEditModes then
  begin
        SeasonDataset.Post;
    SeasonDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewSeasonEdit.btDeleteClick(Sender: TObject);
begin
  if SeasonDataset.State in dsEditModes then
    SeasonDataset.Post;
  XDataClientSeasonDelete.Delete(SeasonDataset.EntitySetName,
    TJSObject(SeasonDataset.CurrentData));
end;


class function TFViewSeasonEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewSeasonEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewSeasonEdit.InsertEntity;
begin
  SeasonDataset.Close;
  SeasonDataset.SetJsonData('{}');
  SeasonDataset.Open;
  SeasonDataset.Insert;
end;

class function TFViewSeasonEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewSeasonEdit(AForm).FInserting := AInserting;
    TFViewSeasonEdit(AForm).FId := AId;
    TFViewSeasonEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewSeasonEdit(AForm).InsertEntity
    else
      TFViewSeasonEdit(AForm).LoadEntity;
    TFViewSeasonEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewSeasonEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewSeasonEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    SeasonDataset.Connection.Model.DefaultSchema.FindEntityType(
      SeasonDataset.EntitySetName
    )
  );
  XDataClientSeasonGet.Get(SeasonDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewSeasonEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewSeasonEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewSeasonEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewSeasonEdit.XDataClientSeasonGetLoad(Response: TXDataClientResponse);
begin
  SeasonDataset.Close;
  SeasonDataset.SetJsonData(Response.Result);
  SeasonDataset.Open;
  
  SeasonDataset.Edit;  
end;

procedure TFViewSeasonEdit.XDataClientSeasonDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewSeasonEdit.XDataClientSeasonDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewSeasonEdit.SeasonDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewSeasonEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewSeasonEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbSeasonId := TLabel.Create('view.season.form.lbSeasonId');
  lbSeasonName := TLabel.Create('view.season.form.lbSeasonName');
  lbSeasonOpenningDate := TLabel.Create('view.season.form.lbSeasonOpenningDate');
  lbSeasonClosingDate := TLabel.Create('view.season.form.lbSeasonClosingDate');
  pnlMessage := TPanel.Create('view.season.form.message');
  lbMessage := TLabel.Create('view.season.form.message.label');
  btCloseMessage := TButton.Create('view.season.form.message.button');
  btCancel := TButton.Create('view.season.form.btBack');
  btSave := TButton.Create('view.season.form.btSave');
  btDelete := TButton.Create('view.season.form.btDelete');
  edSeasonId := TDBEdit.Create('view.season.form.edSeasonId');
  edSeasonName := TDBEdit.Create('view.season.form.edSeasonName');
  edSeasonOpenningDate := TDBEdit.Create('view.season.form.edSeasonOpenningDate');
  edSeasonClosingDate := TDBEdit.Create('view.season.form.edSeasonClosingDate');
  DataSource := TDataSource.Create(Self);
  SeasonDataset := TXDataWebDataSet.Create(Self);
  SeasonDatasetSeasonId := TIntegerField.Create(Self);
  SeasonDatasetSeasonName := TStringField.Create(Self);
  SeasonDatasetSeasonOpenningDate := TDateTimeField.Create(Self);
  SeasonDatasetSeasonClosingDate := TDateTimeField.Create(Self);
  XDataClientSeasonGet := TXDataWebClient.Create(Self);
  XDataClientSeasonDelete := TXDataWebClient.Create(Self);

  lbSeasonId.BeforeLoadDFMValues;
  lbSeasonName.BeforeLoadDFMValues;
  lbSeasonOpenningDate.BeforeLoadDFMValues;
  lbSeasonClosingDate.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edSeasonId.BeforeLoadDFMValues;
  edSeasonName.BeforeLoadDFMValues;
  edSeasonOpenningDate.BeforeLoadDFMValues;
  edSeasonClosingDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  SeasonDataset.BeforeLoadDFMValues;
  SeasonDatasetSeasonId.BeforeLoadDFMValues;
  SeasonDatasetSeasonName.BeforeLoadDFMValues;
  SeasonDatasetSeasonOpenningDate.BeforeLoadDFMValues;
  SeasonDatasetSeasonClosingDate.BeforeLoadDFMValues;
  XDataClientSeasonGet.BeforeLoadDFMValues;
  XDataClientSeasonDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewSeasonEdit';
    Width := 593;
    Height := 373;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbSeasonId.SetParentComponent(Self);
    lbSeasonId.Name := 'lbSeasonId';
    lbSeasonId.Left := 24;
    lbSeasonId.Top := 192;
    lbSeasonId.Width := 49;
    lbSeasonId.Height := 13;
    lbSeasonId.Caption := 'SeasonId:';
    lbSeasonId.HeightPercent := 100.000000000000000000;
    lbSeasonId.WidthPercent := 100.000000000000000000;
    lbSeasonName.SetParentComponent(Self);
    lbSeasonName.Name := 'lbSeasonName';
    lbSeasonName.Left := 24;
    lbSeasonName.Top := 219;
    lbSeasonName.Width := 66;
    lbSeasonName.Height := 13;
    lbSeasonName.Caption := 'SeasonName:';
    lbSeasonName.HeightPercent := 100.000000000000000000;
    lbSeasonName.WidthPercent := 100.000000000000000000;
    lbSeasonOpenningDate.SetParentComponent(Self);
    lbSeasonOpenningDate.Name := 'lbSeasonOpenningDate';
    lbSeasonOpenningDate.Left := 24;
    lbSeasonOpenningDate.Top := 246;
    lbSeasonOpenningDate.Width := 108;
    lbSeasonOpenningDate.Height := 13;
    lbSeasonOpenningDate.Caption := 'SeasonOpenningDate:';
    lbSeasonOpenningDate.HeightPercent := 100.000000000000000000;
    lbSeasonOpenningDate.WidthPercent := 100.000000000000000000;
    lbSeasonClosingDate.SetParentComponent(Self);
    lbSeasonClosingDate.Name := 'lbSeasonClosingDate';
    lbSeasonClosingDate.Left := 24;
    lbSeasonClosingDate.Top := 273;
    lbSeasonClosingDate.Width := 96;
    lbSeasonClosingDate.Height := 13;
    lbSeasonClosingDate.Caption := 'SeasonClosingDate:';
    lbSeasonClosingDate.HeightPercent := 100.000000000000000000;
    lbSeasonClosingDate.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 313;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 313;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 313;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edSeasonId.SetParentComponent(Self);
    edSeasonId.Name := 'edSeasonId';
    edSeasonId.Left := 221;
    edSeasonId.Top := 189;
    edSeasonId.Width := 200;
    edSeasonId.Height := 21;
    edSeasonId.HeightPercent := 100.000000000000000000;
    edSeasonId.TabOrder := 6;
    edSeasonId.Text := 'edSeasonId';
    edSeasonId.WidthPercent := 100.000000000000000000;
    edSeasonId.DataField := 'SeasonId';
    edSeasonId.DataSource := DataSource;
    edSeasonName.SetParentComponent(Self);
    edSeasonName.Name := 'edSeasonName';
    edSeasonName.Left := 221;
    edSeasonName.Top := 216;
    edSeasonName.Width := 200;
    edSeasonName.Height := 21;
    edSeasonName.HeightPercent := 100.000000000000000000;
    edSeasonName.TabOrder := 7;
    edSeasonName.Text := 'edSeasonName';
    edSeasonName.WidthPercent := 100.000000000000000000;
    edSeasonName.DataField := 'SeasonName';
    edSeasonName.DataSource := DataSource;
    edSeasonOpenningDate.SetParentComponent(Self);
    edSeasonOpenningDate.Name := 'edSeasonOpenningDate';
    edSeasonOpenningDate.Left := 221;
    edSeasonOpenningDate.Top := 243;
    edSeasonOpenningDate.Width := 200;
    edSeasonOpenningDate.Height := 21;
    edSeasonOpenningDate.HeightPercent := 100.000000000000000000;
    edSeasonOpenningDate.TabOrder := 8;
    edSeasonOpenningDate.Text := 'edSeasonOpenningDate';
    edSeasonOpenningDate.WidthPercent := 100.000000000000000000;
    edSeasonOpenningDate.DataField := 'SeasonOpenningDate';
    edSeasonOpenningDate.DataSource := DataSource;
    edSeasonClosingDate.SetParentComponent(Self);
    edSeasonClosingDate.Name := 'edSeasonClosingDate';
    edSeasonClosingDate.Left := 221;
    edSeasonClosingDate.Top := 270;
    edSeasonClosingDate.Width := 200;
    edSeasonClosingDate.Height := 21;
    edSeasonClosingDate.HeightPercent := 100.000000000000000000;
    edSeasonClosingDate.TabOrder := 9;
    edSeasonClosingDate.Text := 'edSeasonClosingDate';
    edSeasonClosingDate.WidthPercent := 100.000000000000000000;
    edSeasonClosingDate.DataField := 'SeasonClosingDate';
    edSeasonClosingDate.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := SeasonDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    SeasonDataset.SetParentComponent(Self);
    SeasonDataset.Name := 'SeasonDataset';
    SeasonDataset.AfterApplyUpdates := SeasonDatasetAfterApplyUpdates;
    SeasonDataset.EntitySetName := 'Season';
    SeasonDataset.Connection := DMConnection.ApiConnection;
    SeasonDataset.Left := 176;
    SeasonDataset.Top := 8;
    SeasonDatasetSeasonId.SetParentComponent(SeasonDataset);
    SeasonDatasetSeasonId.Name := 'SeasonDatasetSeasonId';
    SeasonDatasetSeasonId.FieldName := 'SeasonId';
    SeasonDatasetSeasonId.Required := True;
    SeasonDatasetSeasonName.SetParentComponent(SeasonDataset);
    SeasonDatasetSeasonName.Name := 'SeasonDatasetSeasonName';
    SeasonDatasetSeasonName.FieldName := 'SeasonName';
    SeasonDatasetSeasonName.Size := 50;
    SeasonDatasetSeasonOpenningDate.SetParentComponent(SeasonDataset);
    SeasonDatasetSeasonOpenningDate.Name := 'SeasonDatasetSeasonOpenningDate';
    SeasonDatasetSeasonOpenningDate.FieldName := 'SeasonOpenningDate';
    SeasonDatasetSeasonClosingDate.SetParentComponent(SeasonDataset);
    SeasonDatasetSeasonClosingDate.Name := 'SeasonDatasetSeasonClosingDate';
    SeasonDatasetSeasonClosingDate.FieldName := 'SeasonClosingDate';
    XDataClientSeasonGet.SetParentComponent(Self);
    XDataClientSeasonGet.Name := 'XDataClientSeasonGet';
    XDataClientSeasonGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientSeasonGet, Self, 'OnLoad', 'XDataClientSeasonGetLoad');
    XDataClientSeasonGet.Left := 64;
    XDataClientSeasonGet.Top := 8;
    XDataClientSeasonDelete.SetParentComponent(Self);
    XDataClientSeasonDelete.Name := 'XDataClientSeasonDelete';
    XDataClientSeasonDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientSeasonDelete, Self, 'OnLoad', 'XDataClientSeasonDeleteLoad');
    SetEvent(XDataClientSeasonDelete, Self, 'OnError', 'XDataClientSeasonDeleteError');
    XDataClientSeasonDelete.Left := 64;
    XDataClientSeasonDelete.Top := 56;
  finally
    lbSeasonId.AfterLoadDFMValues;
    lbSeasonName.AfterLoadDFMValues;
    lbSeasonOpenningDate.AfterLoadDFMValues;
    lbSeasonClosingDate.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edSeasonId.AfterLoadDFMValues;
    edSeasonName.AfterLoadDFMValues;
    edSeasonOpenningDate.AfterLoadDFMValues;
    edSeasonClosingDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    SeasonDataset.AfterLoadDFMValues;
    SeasonDatasetSeasonId.AfterLoadDFMValues;
    SeasonDatasetSeasonName.AfterLoadDFMValues;
    SeasonDatasetSeasonOpenningDate.AfterLoadDFMValues;
    SeasonDatasetSeasonClosingDate.AfterLoadDFMValues;
    XDataClientSeasonGet.AfterLoadDFMValues;
    XDataClientSeasonDelete.AfterLoadDFMValues;
  end;
end;

end.
