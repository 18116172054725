unit View.ContractClass.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewContractClassEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbctr_Id: TLabel;
    edctr_Id: TDBEdit;
    lbcat_Id: TLabel;
    edcat_Id: TDBEdit;
    lbctr_Name: TLabel;
    edctr_Name: TDBEdit;
    lbctr_Notes: TLabel;
    edctr_Notes: TDBEdit;
    lbctr_IsSeasonnal: TLabel;
    edctr_IsSeasonnal: TDBEdit;
    lbctr_DayPrice: TLabel;
    edctr_DayPrice: TDBEdit;
    lbctr_WeDayPrice: TLabel;
    edctr_WeDayPrice: TDBEdit;
    lbctr_WeekPrice: TLabel;
    edctr_WeekPrice: TDBEdit;
    lbctr_AnnualPrice: TLabel;
    edctr_AnnualPrice: TDBEdit;
    lbctr_CreatedBy: TLabel;
    edctr_CreatedBy: TDBEdit;
    lbctr_CreatedDate: TLabel;
    edctr_CreatedDate: TDBEdit;
    lbctr_LastModifiedBy: TLabel;
    edctr_LastModifiedBy: TDBEdit;
    lbctr_LastModifiedDate: TLabel;
    edctr_LastModifiedDate: TDBEdit;
    XDataClientContractClassGet: TXDataWebClient;
    XDataClientContractClassDelete: TXDataWebClient;
    ContractClassDataset: TXDataWebDataSet;
    ContractClassDatasetctr_Id: TIntegerField;
    ContractClassDatasetcat_Id: TIntegerField;
    ContractClassDatasetctr_Name: TStringField;
    ContractClassDatasetctr_Notes: TStringField;
    ContractClassDatasetctr_IsSeasonnal: TStringField;
    ContractClassDatasetctr_DayPrice: TFloatField;
    ContractClassDatasetctr_WeDayPrice: TFloatField;
    ContractClassDatasetctr_WeekPrice: TFloatField;
    ContractClassDatasetctr_AnnualPrice: TFloatField;
    ContractClassDatasetctr_CreatedBy: TIntegerField;
    ContractClassDatasetctr_CreatedDate: TDateTimeField;
    ContractClassDatasetctr_LastModifiedBy: TIntegerField;
    ContractClassDatasetctr_LastModifiedDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientContractClassGetLoad(Response: TXDataClientResponse);
    procedure XDataClientContractClassDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientContractClassDeleteError(Error: TXDataClientError);
    procedure ContractClassDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewContractClassEdit: TFViewContractClassEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewContractClassEdit }

procedure TFViewContractClassEdit.btSaveClick(Sender: TObject);
begin
  if ContractClassDataset.State in dsEditModes then
  begin
        ContractClassDataset.Post;
    ContractClassDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewContractClassEdit.btDeleteClick(Sender: TObject);
begin
  if ContractClassDataset.State in dsEditModes then
    ContractClassDataset.Post;
  XDataClientContractClassDelete.Delete(ContractClassDataset.EntitySetName,
    TJSObject(ContractClassDataset.CurrentData));
end;


class function TFViewContractClassEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewContractClassEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewContractClassEdit.InsertEntity;
begin
  ContractClassDataset.Close;
  ContractClassDataset.SetJsonData('{}');
  ContractClassDataset.Open;
  ContractClassDataset.Insert;
end;

class function TFViewContractClassEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewContractClassEdit(AForm).FInserting := AInserting;
    TFViewContractClassEdit(AForm).FId := AId;
    TFViewContractClassEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewContractClassEdit(AForm).InsertEntity
    else
      TFViewContractClassEdit(AForm).LoadEntity;
    TFViewContractClassEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewContractClassEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewContractClassEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    ContractClassDataset.Connection.Model.DefaultSchema.FindEntityType(
      ContractClassDataset.EntitySetName
    )
  );
  XDataClientContractClassGet.Get(ContractClassDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewContractClassEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewContractClassEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewContractClassEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewContractClassEdit.XDataClientContractClassGetLoad(Response: TXDataClientResponse);
begin
  ContractClassDataset.Close;
  ContractClassDataset.SetJsonData(Response.Result);
  ContractClassDataset.Open;
  
  ContractClassDataset.Edit;  
end;

procedure TFViewContractClassEdit.XDataClientContractClassDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewContractClassEdit.XDataClientContractClassDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewContractClassEdit.ContractClassDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewContractClassEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewContractClassEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbctr_Id := TLabel.Create('view.contractclass.form.lbctr_Id');
  lbcat_Id := TLabel.Create('view.contractclass.form.lbcat_Id');
  lbctr_Name := TLabel.Create('view.contractclass.form.lbctr_Name');
  lbctr_Notes := TLabel.Create('view.contractclass.form.lbctr_Notes');
  lbctr_IsSeasonnal := TLabel.Create('view.contractclass.form.lbctr_IsSeasonnal');
  lbctr_DayPrice := TLabel.Create('view.contractclass.form.lbctr_DayPrice');
  lbctr_WeDayPrice := TLabel.Create('view.contractclass.form.lbctr_WeDayPrice');
  lbctr_WeekPrice := TLabel.Create('view.contractclass.form.lbctr_WeekPrice');
  lbctr_AnnualPrice := TLabel.Create('view.contractclass.form.lbctr_AnnualPrice');
  lbctr_CreatedBy := TLabel.Create('view.contractclass.form.lbctr_CreatedBy');
  lbctr_CreatedDate := TLabel.Create('view.contractclass.form.lbctr_CreatedDate');
  lbctr_LastModifiedBy := TLabel.Create('view.contractclass.form.lbctr_LastModifiedBy');
  lbctr_LastModifiedDate := TLabel.Create('view.contractclass.form.lbctr_LastModifiedDate');
  pnlMessage := TPanel.Create('view.contractclass.form.message');
  lbMessage := TLabel.Create('view.contractclass.form.message.label');
  btCloseMessage := TButton.Create('view.contractclass.form.message.button');
  btCancel := TButton.Create('view.contractclass.form.btBack');
  btSave := TButton.Create('view.contractclass.form.btSave');
  btDelete := TButton.Create('view.contractclass.form.btDelete');
  edctr_Id := TDBEdit.Create('view.contractclass.form.edctr_Id');
  edcat_Id := TDBEdit.Create('view.contractclass.form.edcat_Id');
  edctr_Name := TDBEdit.Create('view.contractclass.form.edctr_Name');
  edctr_Notes := TDBEdit.Create('view.contractclass.form.edctr_Notes');
  edctr_IsSeasonnal := TDBEdit.Create('view.contractclass.form.edctr_IsSeasonnal');
  edctr_DayPrice := TDBEdit.Create('view.contractclass.form.edctr_DayPrice');
  edctr_WeDayPrice := TDBEdit.Create('view.contractclass.form.edctr_WeDayPrice');
  edctr_WeekPrice := TDBEdit.Create('view.contractclass.form.edctr_WeekPrice');
  edctr_AnnualPrice := TDBEdit.Create('view.contractclass.form.edctr_AnnualPrice');
  edctr_CreatedBy := TDBEdit.Create('view.contractclass.form.edctr_CreatedBy');
  edctr_CreatedDate := TDBEdit.Create('view.contractclass.form.edctr_CreatedDate');
  edctr_LastModifiedBy := TDBEdit.Create('view.contractclass.form.edctr_LastModifiedBy');
  edctr_LastModifiedDate := TDBEdit.Create('view.contractclass.form.edctr_LastModifiedDate');
  DataSource := TDataSource.Create(Self);
  ContractClassDataset := TXDataWebDataSet.Create(Self);
  ContractClassDatasetctr_Id := TIntegerField.Create(Self);
  ContractClassDatasetcat_Id := TIntegerField.Create(Self);
  ContractClassDatasetctr_Name := TStringField.Create(Self);
  ContractClassDatasetctr_Notes := TStringField.Create(Self);
  ContractClassDatasetctr_IsSeasonnal := TStringField.Create(Self);
  ContractClassDatasetctr_DayPrice := TFloatField.Create(Self);
  ContractClassDatasetctr_WeDayPrice := TFloatField.Create(Self);
  ContractClassDatasetctr_WeekPrice := TFloatField.Create(Self);
  ContractClassDatasetctr_AnnualPrice := TFloatField.Create(Self);
  ContractClassDatasetctr_CreatedBy := TIntegerField.Create(Self);
  ContractClassDatasetctr_CreatedDate := TDateTimeField.Create(Self);
  ContractClassDatasetctr_LastModifiedBy := TIntegerField.Create(Self);
  ContractClassDatasetctr_LastModifiedDate := TDateTimeField.Create(Self);
  XDataClientContractClassGet := TXDataWebClient.Create(Self);
  XDataClientContractClassDelete := TXDataWebClient.Create(Self);

  lbctr_Id.BeforeLoadDFMValues;
  lbcat_Id.BeforeLoadDFMValues;
  lbctr_Name.BeforeLoadDFMValues;
  lbctr_Notes.BeforeLoadDFMValues;
  lbctr_IsSeasonnal.BeforeLoadDFMValues;
  lbctr_DayPrice.BeforeLoadDFMValues;
  lbctr_WeDayPrice.BeforeLoadDFMValues;
  lbctr_WeekPrice.BeforeLoadDFMValues;
  lbctr_AnnualPrice.BeforeLoadDFMValues;
  lbctr_CreatedBy.BeforeLoadDFMValues;
  lbctr_CreatedDate.BeforeLoadDFMValues;
  lbctr_LastModifiedBy.BeforeLoadDFMValues;
  lbctr_LastModifiedDate.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edctr_Id.BeforeLoadDFMValues;
  edcat_Id.BeforeLoadDFMValues;
  edctr_Name.BeforeLoadDFMValues;
  edctr_Notes.BeforeLoadDFMValues;
  edctr_IsSeasonnal.BeforeLoadDFMValues;
  edctr_DayPrice.BeforeLoadDFMValues;
  edctr_WeDayPrice.BeforeLoadDFMValues;
  edctr_WeekPrice.BeforeLoadDFMValues;
  edctr_AnnualPrice.BeforeLoadDFMValues;
  edctr_CreatedBy.BeforeLoadDFMValues;
  edctr_CreatedDate.BeforeLoadDFMValues;
  edctr_LastModifiedBy.BeforeLoadDFMValues;
  edctr_LastModifiedDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  ContractClassDataset.BeforeLoadDFMValues;
  ContractClassDatasetctr_Id.BeforeLoadDFMValues;
  ContractClassDatasetcat_Id.BeforeLoadDFMValues;
  ContractClassDatasetctr_Name.BeforeLoadDFMValues;
  ContractClassDatasetctr_Notes.BeforeLoadDFMValues;
  ContractClassDatasetctr_IsSeasonnal.BeforeLoadDFMValues;
  ContractClassDatasetctr_DayPrice.BeforeLoadDFMValues;
  ContractClassDatasetctr_WeDayPrice.BeforeLoadDFMValues;
  ContractClassDatasetctr_WeekPrice.BeforeLoadDFMValues;
  ContractClassDatasetctr_AnnualPrice.BeforeLoadDFMValues;
  ContractClassDatasetctr_CreatedBy.BeforeLoadDFMValues;
  ContractClassDatasetctr_CreatedDate.BeforeLoadDFMValues;
  ContractClassDatasetctr_LastModifiedBy.BeforeLoadDFMValues;
  ContractClassDatasetctr_LastModifiedDate.BeforeLoadDFMValues;
  XDataClientContractClassGet.BeforeLoadDFMValues;
  XDataClientContractClassDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewContractClassEdit';
    Width := 593;
    Height := 616;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbctr_Id.SetParentComponent(Self);
    lbctr_Id.Name := 'lbctr_Id';
    lbctr_Id.Left := 24;
    lbctr_Id.Top := 192;
    lbctr_Id.Width := 33;
    lbctr_Id.Height := 13;
    lbctr_Id.Caption := 'ctr_Id:';
    lbctr_Id.HeightPercent := 100.000000000000000000;
    lbctr_Id.WidthPercent := 100.000000000000000000;
    lbcat_Id.SetParentComponent(Self);
    lbcat_Id.Name := 'lbcat_Id';
    lbcat_Id.Left := 24;
    lbcat_Id.Top := 219;
    lbcat_Id.Width := 35;
    lbcat_Id.Height := 13;
    lbcat_Id.Caption := 'cat_Id:';
    lbcat_Id.HeightPercent := 100.000000000000000000;
    lbcat_Id.WidthPercent := 100.000000000000000000;
    lbctr_Name.SetParentComponent(Self);
    lbctr_Name.Name := 'lbctr_Name';
    lbctr_Name.Left := 24;
    lbctr_Name.Top := 246;
    lbctr_Name.Width := 50;
    lbctr_Name.Height := 13;
    lbctr_Name.Caption := 'ctr_Name:';
    lbctr_Name.HeightPercent := 100.000000000000000000;
    lbctr_Name.WidthPercent := 100.000000000000000000;
    lbctr_Notes.SetParentComponent(Self);
    lbctr_Notes.Name := 'lbctr_Notes';
    lbctr_Notes.Left := 24;
    lbctr_Notes.Top := 273;
    lbctr_Notes.Width := 51;
    lbctr_Notes.Height := 13;
    lbctr_Notes.Caption := 'ctr_Notes:';
    lbctr_Notes.HeightPercent := 100.000000000000000000;
    lbctr_Notes.WidthPercent := 100.000000000000000000;
    lbctr_IsSeasonnal.SetParentComponent(Self);
    lbctr_IsSeasonnal.Name := 'lbctr_IsSeasonnal';
    lbctr_IsSeasonnal.Left := 24;
    lbctr_IsSeasonnal.Top := 300;
    lbctr_IsSeasonnal.Width := 81;
    lbctr_IsSeasonnal.Height := 13;
    lbctr_IsSeasonnal.Caption := 'ctr_IsSeasonnal:';
    lbctr_IsSeasonnal.HeightPercent := 100.000000000000000000;
    lbctr_IsSeasonnal.WidthPercent := 100.000000000000000000;
    lbctr_DayPrice.SetParentComponent(Self);
    lbctr_DayPrice.Name := 'lbctr_DayPrice';
    lbctr_DayPrice.Left := 24;
    lbctr_DayPrice.Top := 327;
    lbctr_DayPrice.Width := 65;
    lbctr_DayPrice.Height := 13;
    lbctr_DayPrice.Caption := 'ctr_DayPrice:';
    lbctr_DayPrice.HeightPercent := 100.000000000000000000;
    lbctr_DayPrice.WidthPercent := 100.000000000000000000;
    lbctr_WeDayPrice.SetParentComponent(Self);
    lbctr_WeDayPrice.Name := 'lbctr_WeDayPrice';
    lbctr_WeDayPrice.Left := 24;
    lbctr_WeDayPrice.Top := 354;
    lbctr_WeDayPrice.Width := 81;
    lbctr_WeDayPrice.Height := 13;
    lbctr_WeDayPrice.Caption := 'ctr_WeDayPrice:';
    lbctr_WeDayPrice.HeightPercent := 100.000000000000000000;
    lbctr_WeDayPrice.WidthPercent := 100.000000000000000000;
    lbctr_WeekPrice.SetParentComponent(Self);
    lbctr_WeekPrice.Name := 'lbctr_WeekPrice';
    lbctr_WeekPrice.Left := 24;
    lbctr_WeekPrice.Top := 381;
    lbctr_WeekPrice.Width := 73;
    lbctr_WeekPrice.Height := 13;
    lbctr_WeekPrice.Caption := 'ctr_WeekPrice:';
    lbctr_WeekPrice.HeightPercent := 100.000000000000000000;
    lbctr_WeekPrice.WidthPercent := 100.000000000000000000;
    lbctr_AnnualPrice.SetParentComponent(Self);
    lbctr_AnnualPrice.Name := 'lbctr_AnnualPrice';
    lbctr_AnnualPrice.Left := 24;
    lbctr_AnnualPrice.Top := 408;
    lbctr_AnnualPrice.Width := 79;
    lbctr_AnnualPrice.Height := 13;
    lbctr_AnnualPrice.Caption := 'ctr_AnnualPrice:';
    lbctr_AnnualPrice.HeightPercent := 100.000000000000000000;
    lbctr_AnnualPrice.WidthPercent := 100.000000000000000000;
    lbctr_CreatedBy.SetParentComponent(Self);
    lbctr_CreatedBy.Name := 'lbctr_CreatedBy';
    lbctr_CreatedBy.Left := 24;
    lbctr_CreatedBy.Top := 435;
    lbctr_CreatedBy.Width := 74;
    lbctr_CreatedBy.Height := 13;
    lbctr_CreatedBy.Caption := 'ctr_CreatedBy:';
    lbctr_CreatedBy.HeightPercent := 100.000000000000000000;
    lbctr_CreatedBy.WidthPercent := 100.000000000000000000;
    lbctr_CreatedDate.SetParentComponent(Self);
    lbctr_CreatedDate.Name := 'lbctr_CreatedDate';
    lbctr_CreatedDate.Left := 24;
    lbctr_CreatedDate.Top := 462;
    lbctr_CreatedDate.Width := 85;
    lbctr_CreatedDate.Height := 13;
    lbctr_CreatedDate.Caption := 'ctr_CreatedDate:';
    lbctr_CreatedDate.HeightPercent := 100.000000000000000000;
    lbctr_CreatedDate.WidthPercent := 100.000000000000000000;
    lbctr_LastModifiedBy.SetParentComponent(Self);
    lbctr_LastModifiedBy.Name := 'lbctr_LastModifiedBy';
    lbctr_LastModifiedBy.Left := 24;
    lbctr_LastModifiedBy.Top := 489;
    lbctr_LastModifiedBy.Width := 95;
    lbctr_LastModifiedBy.Height := 13;
    lbctr_LastModifiedBy.Caption := 'ctr_LastModifiedBy:';
    lbctr_LastModifiedBy.HeightPercent := 100.000000000000000000;
    lbctr_LastModifiedBy.WidthPercent := 100.000000000000000000;
    lbctr_LastModifiedDate.SetParentComponent(Self);
    lbctr_LastModifiedDate.Name := 'lbctr_LastModifiedDate';
    lbctr_LastModifiedDate.Left := 24;
    lbctr_LastModifiedDate.Top := 516;
    lbctr_LastModifiedDate.Width := 106;
    lbctr_LastModifiedDate.Height := 13;
    lbctr_LastModifiedDate.Caption := 'ctr_LastModifiedDate:';
    lbctr_LastModifiedDate.HeightPercent := 100.000000000000000000;
    lbctr_LastModifiedDate.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 556;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 556;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 556;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edctr_Id.SetParentComponent(Self);
    edctr_Id.Name := 'edctr_Id';
    edctr_Id.Left := 221;
    edctr_Id.Top := 189;
    edctr_Id.Width := 200;
    edctr_Id.Height := 21;
    edctr_Id.HeightPercent := 100.000000000000000000;
    edctr_Id.TabOrder := 6;
    edctr_Id.Text := 'edctr_Id';
    edctr_Id.WidthPercent := 100.000000000000000000;
    edctr_Id.DataField := 'ctr_Id';
    edctr_Id.DataSource := DataSource;
    edcat_Id.SetParentComponent(Self);
    edcat_Id.Name := 'edcat_Id';
    edcat_Id.Left := 221;
    edcat_Id.Top := 216;
    edcat_Id.Width := 200;
    edcat_Id.Height := 21;
    edcat_Id.HeightPercent := 100.000000000000000000;
    edcat_Id.TabOrder := 7;
    edcat_Id.Text := 'edcat_Id';
    edcat_Id.WidthPercent := 100.000000000000000000;
    edcat_Id.DataField := 'cat_Id';
    edcat_Id.DataSource := DataSource;
    edctr_Name.SetParentComponent(Self);
    edctr_Name.Name := 'edctr_Name';
    edctr_Name.Left := 221;
    edctr_Name.Top := 243;
    edctr_Name.Width := 200;
    edctr_Name.Height := 21;
    edctr_Name.HeightPercent := 100.000000000000000000;
    edctr_Name.TabOrder := 8;
    edctr_Name.Text := 'edctr_Name';
    edctr_Name.WidthPercent := 100.000000000000000000;
    edctr_Name.DataField := 'ctr_Name';
    edctr_Name.DataSource := DataSource;
    edctr_Notes.SetParentComponent(Self);
    edctr_Notes.Name := 'edctr_Notes';
    edctr_Notes.Left := 221;
    edctr_Notes.Top := 270;
    edctr_Notes.Width := 200;
    edctr_Notes.Height := 21;
    edctr_Notes.HeightPercent := 100.000000000000000000;
    edctr_Notes.TabOrder := 9;
    edctr_Notes.Text := 'edctr_Notes';
    edctr_Notes.WidthPercent := 100.000000000000000000;
    edctr_Notes.DataField := 'ctr_Notes';
    edctr_Notes.DataSource := DataSource;
    edctr_IsSeasonnal.SetParentComponent(Self);
    edctr_IsSeasonnal.Name := 'edctr_IsSeasonnal';
    edctr_IsSeasonnal.Left := 221;
    edctr_IsSeasonnal.Top := 297;
    edctr_IsSeasonnal.Width := 200;
    edctr_IsSeasonnal.Height := 21;
    edctr_IsSeasonnal.HeightPercent := 100.000000000000000000;
    edctr_IsSeasonnal.TabOrder := 10;
    edctr_IsSeasonnal.Text := 'edctr_IsSeasonnal';
    edctr_IsSeasonnal.WidthPercent := 100.000000000000000000;
    edctr_IsSeasonnal.DataField := 'ctr_IsSeasonnal';
    edctr_IsSeasonnal.DataSource := DataSource;
    edctr_DayPrice.SetParentComponent(Self);
    edctr_DayPrice.Name := 'edctr_DayPrice';
    edctr_DayPrice.Left := 221;
    edctr_DayPrice.Top := 324;
    edctr_DayPrice.Width := 200;
    edctr_DayPrice.Height := 21;
    edctr_DayPrice.HeightPercent := 100.000000000000000000;
    edctr_DayPrice.TabOrder := 11;
    edctr_DayPrice.Text := 'edctr_DayPrice';
    edctr_DayPrice.WidthPercent := 100.000000000000000000;
    edctr_DayPrice.DataField := 'ctr_DayPrice';
    edctr_DayPrice.DataSource := DataSource;
    edctr_WeDayPrice.SetParentComponent(Self);
    edctr_WeDayPrice.Name := 'edctr_WeDayPrice';
    edctr_WeDayPrice.Left := 221;
    edctr_WeDayPrice.Top := 351;
    edctr_WeDayPrice.Width := 200;
    edctr_WeDayPrice.Height := 21;
    edctr_WeDayPrice.HeightPercent := 100.000000000000000000;
    edctr_WeDayPrice.TabOrder := 12;
    edctr_WeDayPrice.Text := 'edctr_WeDayPrice';
    edctr_WeDayPrice.WidthPercent := 100.000000000000000000;
    edctr_WeDayPrice.DataField := 'ctr_WeDayPrice';
    edctr_WeDayPrice.DataSource := DataSource;
    edctr_WeekPrice.SetParentComponent(Self);
    edctr_WeekPrice.Name := 'edctr_WeekPrice';
    edctr_WeekPrice.Left := 221;
    edctr_WeekPrice.Top := 378;
    edctr_WeekPrice.Width := 200;
    edctr_WeekPrice.Height := 21;
    edctr_WeekPrice.HeightPercent := 100.000000000000000000;
    edctr_WeekPrice.TabOrder := 13;
    edctr_WeekPrice.Text := 'edctr_WeekPrice';
    edctr_WeekPrice.WidthPercent := 100.000000000000000000;
    edctr_WeekPrice.DataField := 'ctr_WeekPrice';
    edctr_WeekPrice.DataSource := DataSource;
    edctr_AnnualPrice.SetParentComponent(Self);
    edctr_AnnualPrice.Name := 'edctr_AnnualPrice';
    edctr_AnnualPrice.Left := 221;
    edctr_AnnualPrice.Top := 405;
    edctr_AnnualPrice.Width := 200;
    edctr_AnnualPrice.Height := 21;
    edctr_AnnualPrice.HeightPercent := 100.000000000000000000;
    edctr_AnnualPrice.TabOrder := 14;
    edctr_AnnualPrice.Text := 'edctr_AnnualPrice';
    edctr_AnnualPrice.WidthPercent := 100.000000000000000000;
    edctr_AnnualPrice.DataField := 'ctr_AnnualPrice';
    edctr_AnnualPrice.DataSource := DataSource;
    edctr_CreatedBy.SetParentComponent(Self);
    edctr_CreatedBy.Name := 'edctr_CreatedBy';
    edctr_CreatedBy.Left := 221;
    edctr_CreatedBy.Top := 432;
    edctr_CreatedBy.Width := 200;
    edctr_CreatedBy.Height := 21;
    edctr_CreatedBy.HeightPercent := 100.000000000000000000;
    edctr_CreatedBy.TabOrder := 15;
    edctr_CreatedBy.Text := 'edctr_CreatedBy';
    edctr_CreatedBy.WidthPercent := 100.000000000000000000;
    edctr_CreatedBy.DataField := 'ctr_CreatedBy';
    edctr_CreatedBy.DataSource := DataSource;
    edctr_CreatedDate.SetParentComponent(Self);
    edctr_CreatedDate.Name := 'edctr_CreatedDate';
    edctr_CreatedDate.Left := 221;
    edctr_CreatedDate.Top := 459;
    edctr_CreatedDate.Width := 200;
    edctr_CreatedDate.Height := 21;
    edctr_CreatedDate.HeightPercent := 100.000000000000000000;
    edctr_CreatedDate.TabOrder := 16;
    edctr_CreatedDate.Text := 'edctr_CreatedDate';
    edctr_CreatedDate.WidthPercent := 100.000000000000000000;
    edctr_CreatedDate.DataField := 'ctr_CreatedDate';
    edctr_CreatedDate.DataSource := DataSource;
    edctr_LastModifiedBy.SetParentComponent(Self);
    edctr_LastModifiedBy.Name := 'edctr_LastModifiedBy';
    edctr_LastModifiedBy.Left := 221;
    edctr_LastModifiedBy.Top := 486;
    edctr_LastModifiedBy.Width := 200;
    edctr_LastModifiedBy.Height := 21;
    edctr_LastModifiedBy.HeightPercent := 100.000000000000000000;
    edctr_LastModifiedBy.TabOrder := 17;
    edctr_LastModifiedBy.Text := 'edctr_LastModifiedBy';
    edctr_LastModifiedBy.WidthPercent := 100.000000000000000000;
    edctr_LastModifiedBy.DataField := 'ctr_LastModifiedBy';
    edctr_LastModifiedBy.DataSource := DataSource;
    edctr_LastModifiedDate.SetParentComponent(Self);
    edctr_LastModifiedDate.Name := 'edctr_LastModifiedDate';
    edctr_LastModifiedDate.Left := 221;
    edctr_LastModifiedDate.Top := 513;
    edctr_LastModifiedDate.Width := 200;
    edctr_LastModifiedDate.Height := 21;
    edctr_LastModifiedDate.HeightPercent := 100.000000000000000000;
    edctr_LastModifiedDate.TabOrder := 18;
    edctr_LastModifiedDate.Text := 'edctr_LastModifiedDate';
    edctr_LastModifiedDate.WidthPercent := 100.000000000000000000;
    edctr_LastModifiedDate.DataField := 'ctr_LastModifiedDate';
    edctr_LastModifiedDate.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := ContractClassDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    ContractClassDataset.SetParentComponent(Self);
    ContractClassDataset.Name := 'ContractClassDataset';
    ContractClassDataset.AfterApplyUpdates := ContractClassDatasetAfterApplyUpdates;
    ContractClassDataset.EntitySetName := 'ContractClass';
    ContractClassDataset.Connection := DMConnection.ApiConnection;
    ContractClassDataset.Left := 176;
    ContractClassDataset.Top := 8;
    ContractClassDatasetctr_Id.SetParentComponent(ContractClassDataset);
    ContractClassDatasetctr_Id.Name := 'ContractClassDatasetctr_Id';
    ContractClassDatasetctr_Id.FieldName := 'ctr_Id';
    ContractClassDatasetctr_Id.Required := True;
    ContractClassDatasetcat_Id.SetParentComponent(ContractClassDataset);
    ContractClassDatasetcat_Id.Name := 'ContractClassDatasetcat_Id';
    ContractClassDatasetcat_Id.FieldName := 'cat_Id';
    ContractClassDatasetctr_Name.SetParentComponent(ContractClassDataset);
    ContractClassDatasetctr_Name.Name := 'ContractClassDatasetctr_Name';
    ContractClassDatasetctr_Name.FieldName := 'ctr_Name';
    ContractClassDatasetctr_Name.Size := 50;
    ContractClassDatasetctr_Notes.SetParentComponent(ContractClassDataset);
    ContractClassDatasetctr_Notes.Name := 'ContractClassDatasetctr_Notes';
    ContractClassDatasetctr_Notes.FieldName := 'ctr_Notes';
    ContractClassDatasetctr_Notes.Size := 250;
    ContractClassDatasetctr_IsSeasonnal.SetParentComponent(ContractClassDataset);
    ContractClassDatasetctr_IsSeasonnal.Name := 'ContractClassDatasetctr_IsSeasonnal';
    ContractClassDatasetctr_IsSeasonnal.FieldName := 'ctr_IsSeasonnal';
    ContractClassDatasetctr_IsSeasonnal.Size := 255;
    ContractClassDatasetctr_DayPrice.SetParentComponent(ContractClassDataset);
    ContractClassDatasetctr_DayPrice.Name := 'ContractClassDatasetctr_DayPrice';
    ContractClassDatasetctr_DayPrice.FieldName := 'ctr_DayPrice';
    ContractClassDatasetctr_WeDayPrice.SetParentComponent(ContractClassDataset);
    ContractClassDatasetctr_WeDayPrice.Name := 'ContractClassDatasetctr_WeDayPrice';
    ContractClassDatasetctr_WeDayPrice.FieldName := 'ctr_WeDayPrice';
    ContractClassDatasetctr_WeekPrice.SetParentComponent(ContractClassDataset);
    ContractClassDatasetctr_WeekPrice.Name := 'ContractClassDatasetctr_WeekPrice';
    ContractClassDatasetctr_WeekPrice.FieldName := 'ctr_WeekPrice';
    ContractClassDatasetctr_AnnualPrice.SetParentComponent(ContractClassDataset);
    ContractClassDatasetctr_AnnualPrice.Name := 'ContractClassDatasetctr_AnnualPrice';
    ContractClassDatasetctr_AnnualPrice.FieldName := 'ctr_AnnualPrice';
    ContractClassDatasetctr_CreatedBy.SetParentComponent(ContractClassDataset);
    ContractClassDatasetctr_CreatedBy.Name := 'ContractClassDatasetctr_CreatedBy';
    ContractClassDatasetctr_CreatedBy.FieldName := 'ctr_CreatedBy';
    ContractClassDatasetctr_CreatedDate.SetParentComponent(ContractClassDataset);
    ContractClassDatasetctr_CreatedDate.Name := 'ContractClassDatasetctr_CreatedDate';
    ContractClassDatasetctr_CreatedDate.FieldName := 'ctr_CreatedDate';
    ContractClassDatasetctr_LastModifiedBy.SetParentComponent(ContractClassDataset);
    ContractClassDatasetctr_LastModifiedBy.Name := 'ContractClassDatasetctr_LastModifiedBy';
    ContractClassDatasetctr_LastModifiedBy.FieldName := 'ctr_LastModifiedBy';
    ContractClassDatasetctr_LastModifiedDate.SetParentComponent(ContractClassDataset);
    ContractClassDatasetctr_LastModifiedDate.Name := 'ContractClassDatasetctr_LastModifiedDate';
    ContractClassDatasetctr_LastModifiedDate.FieldName := 'ctr_LastModifiedDate';
    XDataClientContractClassGet.SetParentComponent(Self);
    XDataClientContractClassGet.Name := 'XDataClientContractClassGet';
    XDataClientContractClassGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientContractClassGet, Self, 'OnLoad', 'XDataClientContractClassGetLoad');
    XDataClientContractClassGet.Left := 64;
    XDataClientContractClassGet.Top := 8;
    XDataClientContractClassDelete.SetParentComponent(Self);
    XDataClientContractClassDelete.Name := 'XDataClientContractClassDelete';
    XDataClientContractClassDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientContractClassDelete, Self, 'OnLoad', 'XDataClientContractClassDeleteLoad');
    SetEvent(XDataClientContractClassDelete, Self, 'OnError', 'XDataClientContractClassDeleteError');
    XDataClientContractClassDelete.Left := 64;
    XDataClientContractClassDelete.Top := 56;
  finally
    lbctr_Id.AfterLoadDFMValues;
    lbcat_Id.AfterLoadDFMValues;
    lbctr_Name.AfterLoadDFMValues;
    lbctr_Notes.AfterLoadDFMValues;
    lbctr_IsSeasonnal.AfterLoadDFMValues;
    lbctr_DayPrice.AfterLoadDFMValues;
    lbctr_WeDayPrice.AfterLoadDFMValues;
    lbctr_WeekPrice.AfterLoadDFMValues;
    lbctr_AnnualPrice.AfterLoadDFMValues;
    lbctr_CreatedBy.AfterLoadDFMValues;
    lbctr_CreatedDate.AfterLoadDFMValues;
    lbctr_LastModifiedBy.AfterLoadDFMValues;
    lbctr_LastModifiedDate.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edctr_Id.AfterLoadDFMValues;
    edcat_Id.AfterLoadDFMValues;
    edctr_Name.AfterLoadDFMValues;
    edctr_Notes.AfterLoadDFMValues;
    edctr_IsSeasonnal.AfterLoadDFMValues;
    edctr_DayPrice.AfterLoadDFMValues;
    edctr_WeDayPrice.AfterLoadDFMValues;
    edctr_WeekPrice.AfterLoadDFMValues;
    edctr_AnnualPrice.AfterLoadDFMValues;
    edctr_CreatedBy.AfterLoadDFMValues;
    edctr_CreatedDate.AfterLoadDFMValues;
    edctr_LastModifiedBy.AfterLoadDFMValues;
    edctr_LastModifiedDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    ContractClassDataset.AfterLoadDFMValues;
    ContractClassDatasetctr_Id.AfterLoadDFMValues;
    ContractClassDatasetcat_Id.AfterLoadDFMValues;
    ContractClassDatasetctr_Name.AfterLoadDFMValues;
    ContractClassDatasetctr_Notes.AfterLoadDFMValues;
    ContractClassDatasetctr_IsSeasonnal.AfterLoadDFMValues;
    ContractClassDatasetctr_DayPrice.AfterLoadDFMValues;
    ContractClassDatasetctr_WeDayPrice.AfterLoadDFMValues;
    ContractClassDatasetctr_WeekPrice.AfterLoadDFMValues;
    ContractClassDatasetctr_AnnualPrice.AfterLoadDFMValues;
    ContractClassDatasetctr_CreatedBy.AfterLoadDFMValues;
    ContractClassDatasetctr_CreatedDate.AfterLoadDFMValues;
    ContractClassDatasetctr_LastModifiedBy.AfterLoadDFMValues;
    ContractClassDatasetctr_LastModifiedDate.AfterLoadDFMValues;
    XDataClientContractClassGet.AfterLoadDFMValues;
    XDataClientContractClassDelete.AfterLoadDFMValues;
  end;
end;

end.
