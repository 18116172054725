unit View.CompProducts.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewCompProductsEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbcp_Id: TLabel;
    edcp_Id: TDBEdit;
    lbcat_Id: TLabel;
    edcat_Id: TDBEdit;
    lbcp_ItemNumber: TLabel;
    edcp_ItemNumber: TDBEdit;
    lbcp_Name: TLabel;
    edcp_Name: TDBEdit;
    lbcp_Price: TLabel;
    edcp_Price: TDBEdit;
    lbcp_CreatedBy: TLabel;
    edcp_CreatedBy: TDBEdit;
    lbcp_CreatedDate: TLabel;
    edcp_CreatedDate: TDBEdit;
    lbcp_LastModifieddBy: TLabel;
    edcp_LastModifieddBy: TDBEdit;
    lbcp_LastModifiedDate: TLabel;
    edcp_LastModifiedDate: TDBEdit;
    XDataClientCompProductsGet: TXDataWebClient;
    XDataClientCompProductsDelete: TXDataWebClient;
    CompProductsDataset: TXDataWebDataSet;
    CompProductsDatasetcp_Id: TIntegerField;
    CompProductsDatasetcat_Id: TIntegerField;
    CompProductsDatasetcp_ItemNumber: TIntegerField;
    CompProductsDatasetcp_Name: TStringField;
    CompProductsDatasetcp_Price: TFloatField;
    CompProductsDatasetcp_CreatedBy: TIntegerField;
    CompProductsDatasetcp_CreatedDate: TDateTimeField;
    CompProductsDatasetcp_LastModifieddBy: TIntegerField;
    CompProductsDatasetcp_LastModifiedDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientCompProductsGetLoad(Response: TXDataClientResponse);
    procedure XDataClientCompProductsDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientCompProductsDeleteError(Error: TXDataClientError);
    procedure CompProductsDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewCompProductsEdit: TFViewCompProductsEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewCompProductsEdit }

procedure TFViewCompProductsEdit.btSaveClick(Sender: TObject);
begin
  if CompProductsDataset.State in dsEditModes then
  begin
        CompProductsDataset.Post;
    CompProductsDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewCompProductsEdit.btDeleteClick(Sender: TObject);
begin
  if CompProductsDataset.State in dsEditModes then
    CompProductsDataset.Post;
  XDataClientCompProductsDelete.Delete(CompProductsDataset.EntitySetName,
    TJSObject(CompProductsDataset.CurrentData));
end;


class function TFViewCompProductsEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewCompProductsEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewCompProductsEdit.InsertEntity;
begin
  CompProductsDataset.Close;
  CompProductsDataset.SetJsonData('{}');
  CompProductsDataset.Open;
  CompProductsDataset.Insert;
end;

class function TFViewCompProductsEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewCompProductsEdit(AForm).FInserting := AInserting;
    TFViewCompProductsEdit(AForm).FId := AId;
    TFViewCompProductsEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewCompProductsEdit(AForm).InsertEntity
    else
      TFViewCompProductsEdit(AForm).LoadEntity;
    TFViewCompProductsEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewCompProductsEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewCompProductsEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    CompProductsDataset.Connection.Model.DefaultSchema.FindEntityType(
      CompProductsDataset.EntitySetName
    )
  );
  XDataClientCompProductsGet.Get(CompProductsDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewCompProductsEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewCompProductsEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewCompProductsEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewCompProductsEdit.XDataClientCompProductsGetLoad(Response: TXDataClientResponse);
begin
  CompProductsDataset.Close;
  CompProductsDataset.SetJsonData(Response.Result);
  CompProductsDataset.Open;
  
  CompProductsDataset.Edit;  
end;

procedure TFViewCompProductsEdit.XDataClientCompProductsDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewCompProductsEdit.XDataClientCompProductsDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewCompProductsEdit.CompProductsDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewCompProductsEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewCompProductsEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbcp_Id := TLabel.Create('view.compproducts.form.lbcp_Id');
  lbcat_Id := TLabel.Create('view.compproducts.form.lbcat_Id');
  lbcp_ItemNumber := TLabel.Create('view.compproducts.form.lbcp_ItemNumber');
  lbcp_Name := TLabel.Create('view.compproducts.form.lbcp_Name');
  lbcp_Price := TLabel.Create('view.compproducts.form.lbcp_Price');
  lbcp_CreatedBy := TLabel.Create('view.compproducts.form.lbcp_CreatedBy');
  lbcp_CreatedDate := TLabel.Create('view.compproducts.form.lbcp_CreatedDate');
  lbcp_LastModifieddBy := TLabel.Create('view.compproducts.form.lbcp_LastModifieddBy');
  lbcp_LastModifiedDate := TLabel.Create('view.compproducts.form.lbcp_LastModifiedDate');
  pnlMessage := TPanel.Create('view.compproducts.form.message');
  lbMessage := TLabel.Create('view.compproducts.form.message.label');
  btCloseMessage := TButton.Create('view.compproducts.form.message.button');
  btCancel := TButton.Create('view.compproducts.form.btBack');
  btSave := TButton.Create('view.compproducts.form.btSave');
  btDelete := TButton.Create('view.compproducts.form.btDelete');
  edcp_Id := TDBEdit.Create('view.compproducts.form.edcp_Id');
  edcat_Id := TDBEdit.Create('view.compproducts.form.edcat_Id');
  edcp_ItemNumber := TDBEdit.Create('view.compproducts.form.edcp_ItemNumber');
  edcp_Name := TDBEdit.Create('view.compproducts.form.edcp_Name');
  edcp_Price := TDBEdit.Create('view.compproducts.form.edcp_Price');
  edcp_CreatedBy := TDBEdit.Create('view.compproducts.form.edcp_CreatedBy');
  edcp_CreatedDate := TDBEdit.Create('view.compproducts.form.edcp_CreatedDate');
  edcp_LastModifieddBy := TDBEdit.Create('view.compproducts.form.edcp_LastModifieddBy');
  edcp_LastModifiedDate := TDBEdit.Create('view.compproducts.form.edcp_LastModifiedDate');
  DataSource := TDataSource.Create(Self);
  CompProductsDataset := TXDataWebDataSet.Create(Self);
  CompProductsDatasetcp_Id := TIntegerField.Create(Self);
  CompProductsDatasetcat_Id := TIntegerField.Create(Self);
  CompProductsDatasetcp_ItemNumber := TIntegerField.Create(Self);
  CompProductsDatasetcp_Name := TStringField.Create(Self);
  CompProductsDatasetcp_Price := TFloatField.Create(Self);
  CompProductsDatasetcp_CreatedBy := TIntegerField.Create(Self);
  CompProductsDatasetcp_CreatedDate := TDateTimeField.Create(Self);
  CompProductsDatasetcp_LastModifieddBy := TIntegerField.Create(Self);
  CompProductsDatasetcp_LastModifiedDate := TDateTimeField.Create(Self);
  XDataClientCompProductsGet := TXDataWebClient.Create(Self);
  XDataClientCompProductsDelete := TXDataWebClient.Create(Self);

  lbcp_Id.BeforeLoadDFMValues;
  lbcat_Id.BeforeLoadDFMValues;
  lbcp_ItemNumber.BeforeLoadDFMValues;
  lbcp_Name.BeforeLoadDFMValues;
  lbcp_Price.BeforeLoadDFMValues;
  lbcp_CreatedBy.BeforeLoadDFMValues;
  lbcp_CreatedDate.BeforeLoadDFMValues;
  lbcp_LastModifieddBy.BeforeLoadDFMValues;
  lbcp_LastModifiedDate.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edcp_Id.BeforeLoadDFMValues;
  edcat_Id.BeforeLoadDFMValues;
  edcp_ItemNumber.BeforeLoadDFMValues;
  edcp_Name.BeforeLoadDFMValues;
  edcp_Price.BeforeLoadDFMValues;
  edcp_CreatedBy.BeforeLoadDFMValues;
  edcp_CreatedDate.BeforeLoadDFMValues;
  edcp_LastModifieddBy.BeforeLoadDFMValues;
  edcp_LastModifiedDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  CompProductsDataset.BeforeLoadDFMValues;
  CompProductsDatasetcp_Id.BeforeLoadDFMValues;
  CompProductsDatasetcat_Id.BeforeLoadDFMValues;
  CompProductsDatasetcp_ItemNumber.BeforeLoadDFMValues;
  CompProductsDatasetcp_Name.BeforeLoadDFMValues;
  CompProductsDatasetcp_Price.BeforeLoadDFMValues;
  CompProductsDatasetcp_CreatedBy.BeforeLoadDFMValues;
  CompProductsDatasetcp_CreatedDate.BeforeLoadDFMValues;
  CompProductsDatasetcp_LastModifieddBy.BeforeLoadDFMValues;
  CompProductsDatasetcp_LastModifiedDate.BeforeLoadDFMValues;
  XDataClientCompProductsGet.BeforeLoadDFMValues;
  XDataClientCompProductsDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewCompProductsEdit';
    Width := 593;
    Height := 508;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbcp_Id.SetParentComponent(Self);
    lbcp_Id.Name := 'lbcp_Id';
    lbcp_Id.Left := 24;
    lbcp_Id.Top := 192;
    lbcp_Id.Width := 31;
    lbcp_Id.Height := 13;
    lbcp_Id.Caption := 'cp_Id:';
    lbcp_Id.HeightPercent := 100.000000000000000000;
    lbcp_Id.WidthPercent := 100.000000000000000000;
    lbcat_Id.SetParentComponent(Self);
    lbcat_Id.Name := 'lbcat_Id';
    lbcat_Id.Left := 24;
    lbcat_Id.Top := 219;
    lbcat_Id.Width := 35;
    lbcat_Id.Height := 13;
    lbcat_Id.Caption := 'cat_Id:';
    lbcat_Id.HeightPercent := 100.000000000000000000;
    lbcat_Id.WidthPercent := 100.000000000000000000;
    lbcp_ItemNumber.SetParentComponent(Self);
    lbcp_ItemNumber.Name := 'lbcp_ItemNumber';
    lbcp_ItemNumber.Left := 24;
    lbcp_ItemNumber.Top := 246;
    lbcp_ItemNumber.Width := 80;
    lbcp_ItemNumber.Height := 13;
    lbcp_ItemNumber.Caption := 'cp_ItemNumber:';
    lbcp_ItemNumber.HeightPercent := 100.000000000000000000;
    lbcp_ItemNumber.WidthPercent := 100.000000000000000000;
    lbcp_Name.SetParentComponent(Self);
    lbcp_Name.Name := 'lbcp_Name';
    lbcp_Name.Left := 24;
    lbcp_Name.Top := 273;
    lbcp_Name.Width := 48;
    lbcp_Name.Height := 13;
    lbcp_Name.Caption := 'cp_Name:';
    lbcp_Name.HeightPercent := 100.000000000000000000;
    lbcp_Name.WidthPercent := 100.000000000000000000;
    lbcp_Price.SetParentComponent(Self);
    lbcp_Price.Name := 'lbcp_Price';
    lbcp_Price.Left := 24;
    lbcp_Price.Top := 300;
    lbcp_Price.Width := 44;
    lbcp_Price.Height := 13;
    lbcp_Price.Caption := 'cp_Price:';
    lbcp_Price.HeightPercent := 100.000000000000000000;
    lbcp_Price.WidthPercent := 100.000000000000000000;
    lbcp_CreatedBy.SetParentComponent(Self);
    lbcp_CreatedBy.Name := 'lbcp_CreatedBy';
    lbcp_CreatedBy.Left := 24;
    lbcp_CreatedBy.Top := 327;
    lbcp_CreatedBy.Width := 72;
    lbcp_CreatedBy.Height := 13;
    lbcp_CreatedBy.Caption := 'cp_CreatedBy:';
    lbcp_CreatedBy.HeightPercent := 100.000000000000000000;
    lbcp_CreatedBy.WidthPercent := 100.000000000000000000;
    lbcp_CreatedDate.SetParentComponent(Self);
    lbcp_CreatedDate.Name := 'lbcp_CreatedDate';
    lbcp_CreatedDate.Left := 24;
    lbcp_CreatedDate.Top := 354;
    lbcp_CreatedDate.Width := 83;
    lbcp_CreatedDate.Height := 13;
    lbcp_CreatedDate.Caption := 'cp_CreatedDate:';
    lbcp_CreatedDate.HeightPercent := 100.000000000000000000;
    lbcp_CreatedDate.WidthPercent := 100.000000000000000000;
    lbcp_LastModifieddBy.SetParentComponent(Self);
    lbcp_LastModifieddBy.Name := 'lbcp_LastModifieddBy';
    lbcp_LastModifieddBy.Left := 24;
    lbcp_LastModifieddBy.Top := 381;
    lbcp_LastModifieddBy.Width := 99;
    lbcp_LastModifieddBy.Height := 13;
    lbcp_LastModifieddBy.Caption := 'cp_LastModifieddBy:';
    lbcp_LastModifieddBy.HeightPercent := 100.000000000000000000;
    lbcp_LastModifieddBy.WidthPercent := 100.000000000000000000;
    lbcp_LastModifiedDate.SetParentComponent(Self);
    lbcp_LastModifiedDate.Name := 'lbcp_LastModifiedDate';
    lbcp_LastModifiedDate.Left := 24;
    lbcp_LastModifiedDate.Top := 408;
    lbcp_LastModifiedDate.Width := 104;
    lbcp_LastModifiedDate.Height := 13;
    lbcp_LastModifiedDate.Caption := 'cp_LastModifiedDate:';
    lbcp_LastModifiedDate.HeightPercent := 100.000000000000000000;
    lbcp_LastModifiedDate.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 448;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 448;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 448;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edcp_Id.SetParentComponent(Self);
    edcp_Id.Name := 'edcp_Id';
    edcp_Id.Left := 221;
    edcp_Id.Top := 189;
    edcp_Id.Width := 200;
    edcp_Id.Height := 21;
    edcp_Id.HeightPercent := 100.000000000000000000;
    edcp_Id.TabOrder := 6;
    edcp_Id.Text := 'edcp_Id';
    edcp_Id.WidthPercent := 100.000000000000000000;
    edcp_Id.DataField := 'cp_Id';
    edcp_Id.DataSource := DataSource;
    edcat_Id.SetParentComponent(Self);
    edcat_Id.Name := 'edcat_Id';
    edcat_Id.Left := 221;
    edcat_Id.Top := 216;
    edcat_Id.Width := 200;
    edcat_Id.Height := 21;
    edcat_Id.HeightPercent := 100.000000000000000000;
    edcat_Id.TabOrder := 7;
    edcat_Id.Text := 'edcat_Id';
    edcat_Id.WidthPercent := 100.000000000000000000;
    edcat_Id.DataField := 'cat_Id';
    edcat_Id.DataSource := DataSource;
    edcp_ItemNumber.SetParentComponent(Self);
    edcp_ItemNumber.Name := 'edcp_ItemNumber';
    edcp_ItemNumber.Left := 221;
    edcp_ItemNumber.Top := 243;
    edcp_ItemNumber.Width := 200;
    edcp_ItemNumber.Height := 21;
    edcp_ItemNumber.HeightPercent := 100.000000000000000000;
    edcp_ItemNumber.TabOrder := 8;
    edcp_ItemNumber.Text := 'edcp_ItemNumber';
    edcp_ItemNumber.WidthPercent := 100.000000000000000000;
    edcp_ItemNumber.DataField := 'cp_ItemNumber';
    edcp_ItemNumber.DataSource := DataSource;
    edcp_Name.SetParentComponent(Self);
    edcp_Name.Name := 'edcp_Name';
    edcp_Name.Left := 221;
    edcp_Name.Top := 270;
    edcp_Name.Width := 200;
    edcp_Name.Height := 21;
    edcp_Name.HeightPercent := 100.000000000000000000;
    edcp_Name.TabOrder := 9;
    edcp_Name.Text := 'edcp_Name';
    edcp_Name.WidthPercent := 100.000000000000000000;
    edcp_Name.DataField := 'cp_Name';
    edcp_Name.DataSource := DataSource;
    edcp_Price.SetParentComponent(Self);
    edcp_Price.Name := 'edcp_Price';
    edcp_Price.Left := 221;
    edcp_Price.Top := 297;
    edcp_Price.Width := 200;
    edcp_Price.Height := 21;
    edcp_Price.HeightPercent := 100.000000000000000000;
    edcp_Price.TabOrder := 10;
    edcp_Price.Text := 'edcp_Price';
    edcp_Price.WidthPercent := 100.000000000000000000;
    edcp_Price.DataField := 'cp_Price';
    edcp_Price.DataSource := DataSource;
    edcp_CreatedBy.SetParentComponent(Self);
    edcp_CreatedBy.Name := 'edcp_CreatedBy';
    edcp_CreatedBy.Left := 221;
    edcp_CreatedBy.Top := 324;
    edcp_CreatedBy.Width := 200;
    edcp_CreatedBy.Height := 21;
    edcp_CreatedBy.HeightPercent := 100.000000000000000000;
    edcp_CreatedBy.TabOrder := 11;
    edcp_CreatedBy.Text := 'edcp_CreatedBy';
    edcp_CreatedBy.WidthPercent := 100.000000000000000000;
    edcp_CreatedBy.DataField := 'cp_CreatedBy';
    edcp_CreatedBy.DataSource := DataSource;
    edcp_CreatedDate.SetParentComponent(Self);
    edcp_CreatedDate.Name := 'edcp_CreatedDate';
    edcp_CreatedDate.Left := 221;
    edcp_CreatedDate.Top := 351;
    edcp_CreatedDate.Width := 200;
    edcp_CreatedDate.Height := 21;
    edcp_CreatedDate.HeightPercent := 100.000000000000000000;
    edcp_CreatedDate.TabOrder := 12;
    edcp_CreatedDate.Text := 'edcp_CreatedDate';
    edcp_CreatedDate.WidthPercent := 100.000000000000000000;
    edcp_CreatedDate.DataField := 'cp_CreatedDate';
    edcp_CreatedDate.DataSource := DataSource;
    edcp_LastModifieddBy.SetParentComponent(Self);
    edcp_LastModifieddBy.Name := 'edcp_LastModifieddBy';
    edcp_LastModifieddBy.Left := 221;
    edcp_LastModifieddBy.Top := 378;
    edcp_LastModifieddBy.Width := 200;
    edcp_LastModifieddBy.Height := 21;
    edcp_LastModifieddBy.HeightPercent := 100.000000000000000000;
    edcp_LastModifieddBy.TabOrder := 13;
    edcp_LastModifieddBy.Text := 'edcp_LastModifieddBy';
    edcp_LastModifieddBy.WidthPercent := 100.000000000000000000;
    edcp_LastModifieddBy.DataField := 'cp_LastModifieddBy';
    edcp_LastModifieddBy.DataSource := DataSource;
    edcp_LastModifiedDate.SetParentComponent(Self);
    edcp_LastModifiedDate.Name := 'edcp_LastModifiedDate';
    edcp_LastModifiedDate.Left := 221;
    edcp_LastModifiedDate.Top := 405;
    edcp_LastModifiedDate.Width := 200;
    edcp_LastModifiedDate.Height := 21;
    edcp_LastModifiedDate.HeightPercent := 100.000000000000000000;
    edcp_LastModifiedDate.TabOrder := 14;
    edcp_LastModifiedDate.Text := 'edcp_LastModifiedDate';
    edcp_LastModifiedDate.WidthPercent := 100.000000000000000000;
    edcp_LastModifiedDate.DataField := 'cp_LastModifiedDate';
    edcp_LastModifiedDate.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := CompProductsDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    CompProductsDataset.SetParentComponent(Self);
    CompProductsDataset.Name := 'CompProductsDataset';
    CompProductsDataset.AfterApplyUpdates := CompProductsDatasetAfterApplyUpdates;
    CompProductsDataset.EntitySetName := 'CompProducts';
    CompProductsDataset.Connection := DMConnection.ApiConnection;
    CompProductsDataset.Left := 176;
    CompProductsDataset.Top := 8;
    CompProductsDatasetcp_Id.SetParentComponent(CompProductsDataset);
    CompProductsDatasetcp_Id.Name := 'CompProductsDatasetcp_Id';
    CompProductsDatasetcp_Id.FieldName := 'cp_Id';
    CompProductsDatasetcp_Id.Required := True;
    CompProductsDatasetcat_Id.SetParentComponent(CompProductsDataset);
    CompProductsDatasetcat_Id.Name := 'CompProductsDatasetcat_Id';
    CompProductsDatasetcat_Id.FieldName := 'cat_Id';
    CompProductsDatasetcp_ItemNumber.SetParentComponent(CompProductsDataset);
    CompProductsDatasetcp_ItemNumber.Name := 'CompProductsDatasetcp_ItemNumber';
    CompProductsDatasetcp_ItemNumber.FieldName := 'cp_ItemNumber';
    CompProductsDatasetcp_Name.SetParentComponent(CompProductsDataset);
    CompProductsDatasetcp_Name.Name := 'CompProductsDatasetcp_Name';
    CompProductsDatasetcp_Name.FieldName := 'cp_Name';
    CompProductsDatasetcp_Name.Size := 50;
    CompProductsDatasetcp_Price.SetParentComponent(CompProductsDataset);
    CompProductsDatasetcp_Price.Name := 'CompProductsDatasetcp_Price';
    CompProductsDatasetcp_Price.FieldName := 'cp_Price';
    CompProductsDatasetcp_CreatedBy.SetParentComponent(CompProductsDataset);
    CompProductsDatasetcp_CreatedBy.Name := 'CompProductsDatasetcp_CreatedBy';
    CompProductsDatasetcp_CreatedBy.FieldName := 'cp_CreatedBy';
    CompProductsDatasetcp_CreatedDate.SetParentComponent(CompProductsDataset);
    CompProductsDatasetcp_CreatedDate.Name := 'CompProductsDatasetcp_CreatedDate';
    CompProductsDatasetcp_CreatedDate.FieldName := 'cp_CreatedDate';
    CompProductsDatasetcp_LastModifieddBy.SetParentComponent(CompProductsDataset);
    CompProductsDatasetcp_LastModifieddBy.Name := 'CompProductsDatasetcp_LastModifieddBy';
    CompProductsDatasetcp_LastModifieddBy.FieldName := 'cp_LastModifieddBy';
    CompProductsDatasetcp_LastModifiedDate.SetParentComponent(CompProductsDataset);
    CompProductsDatasetcp_LastModifiedDate.Name := 'CompProductsDatasetcp_LastModifiedDate';
    CompProductsDatasetcp_LastModifiedDate.FieldName := 'cp_LastModifiedDate';
    XDataClientCompProductsGet.SetParentComponent(Self);
    XDataClientCompProductsGet.Name := 'XDataClientCompProductsGet';
    XDataClientCompProductsGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientCompProductsGet, Self, 'OnLoad', 'XDataClientCompProductsGetLoad');
    XDataClientCompProductsGet.Left := 64;
    XDataClientCompProductsGet.Top := 8;
    XDataClientCompProductsDelete.SetParentComponent(Self);
    XDataClientCompProductsDelete.Name := 'XDataClientCompProductsDelete';
    XDataClientCompProductsDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientCompProductsDelete, Self, 'OnLoad', 'XDataClientCompProductsDeleteLoad');
    SetEvent(XDataClientCompProductsDelete, Self, 'OnError', 'XDataClientCompProductsDeleteError');
    XDataClientCompProductsDelete.Left := 64;
    XDataClientCompProductsDelete.Top := 56;
  finally
    lbcp_Id.AfterLoadDFMValues;
    lbcat_Id.AfterLoadDFMValues;
    lbcp_ItemNumber.AfterLoadDFMValues;
    lbcp_Name.AfterLoadDFMValues;
    lbcp_Price.AfterLoadDFMValues;
    lbcp_CreatedBy.AfterLoadDFMValues;
    lbcp_CreatedDate.AfterLoadDFMValues;
    lbcp_LastModifieddBy.AfterLoadDFMValues;
    lbcp_LastModifiedDate.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edcp_Id.AfterLoadDFMValues;
    edcat_Id.AfterLoadDFMValues;
    edcp_ItemNumber.AfterLoadDFMValues;
    edcp_Name.AfterLoadDFMValues;
    edcp_Price.AfterLoadDFMValues;
    edcp_CreatedBy.AfterLoadDFMValues;
    edcp_CreatedDate.AfterLoadDFMValues;
    edcp_LastModifieddBy.AfterLoadDFMValues;
    edcp_LastModifiedDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    CompProductsDataset.AfterLoadDFMValues;
    CompProductsDatasetcp_Id.AfterLoadDFMValues;
    CompProductsDatasetcat_Id.AfterLoadDFMValues;
    CompProductsDatasetcp_ItemNumber.AfterLoadDFMValues;
    CompProductsDatasetcp_Name.AfterLoadDFMValues;
    CompProductsDatasetcp_Price.AfterLoadDFMValues;
    CompProductsDatasetcp_CreatedBy.AfterLoadDFMValues;
    CompProductsDatasetcp_CreatedDate.AfterLoadDFMValues;
    CompProductsDatasetcp_LastModifieddBy.AfterLoadDFMValues;
    CompProductsDatasetcp_LastModifiedDate.AfterLoadDFMValues;
    XDataClientCompProductsGet.AfterLoadDFMValues;
    XDataClientCompProductsDelete.AfterLoadDFMValues;
  end;
end;

end.
