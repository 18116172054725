unit View.ErrorPage;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Controls, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls,

  XData.Web.Connection, WEBLib.StdCtrls;

type
  TFViewErrorPage = class(TForm)
    lbTitle: TLabel;
    lbMessage: TLabel;
  public
    class procedure Display(AErrorMessage: string);
    class procedure DisplayConnectionError(AError: TXDataWebConnectionError);
  protected procedure LoadDFMValues; override; end;

var
  FViewErrorPage: TFViewErrorPage;

implementation

{$R *.dfm}

{ TFViewErrorPage }

class procedure TFViewErrorPage.Display(AErrorMessage: string);

  procedure AfterCreateProc(AForm: TObject);
  begin
    TFViewErrorPage(AForm).lbMessage.Caption := AErrorMessage;
  end;

begin
  if Assigned(FViewErrorPage) then
    FViewErrorPage.Free;
  FViewErrorPage := TFViewErrorPage.CreateNew(@AfterCreateProc);
end;

class procedure TFViewErrorPage.DisplayConnectionError(
  AError: TXDataWebConnectionError);
begin
  Display(AError.ErrorMessage + ': ' + AError.RequestUrl);
end;

procedure TFViewErrorPage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbTitle := TLabel.Create('view.errorpage.title');
  lbMessage := TLabel.Create('view.errorpage.message');

  lbTitle.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  try
    Name := 'FViewErrorPage';
    Left := 0;
    Top := 0;
    Width := 534;
    Height := 426;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    TabOrder := 1;
    lbTitle.SetParentComponent(Self);
    lbTitle.Name := 'lbTitle';
    lbTitle.Left := 24;
    lbTitle.Top := 24;
    lbTitle.Width := 128;
    lbTitle.Height := 13;
    lbTitle.Caption := 'Oops... an error occurred!';
    lbTitle.Transparent := False;
    lbMessage.SetParentComponent(Self);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 24;
    lbMessage.Top := 56;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.Transparent := False;
  finally
    lbTitle.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
  end;
end;

end.
