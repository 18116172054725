unit View.CampSite.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewCampSiteEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbcs_Id: TLabel;
    edcs_Id: TDBEdit;
    lbcs_UID: TLabel;
    edcs_UID: TDBEdit;
    lbcs_SiteName: TLabel;
    edcs_SiteName: TDBEdit;
    lbctr_Id: TLabel;
    edctr_Id: TDBEdit;
    lbcs_PseudoLength: TLabel;
    edcs_PseudoLength: TDBEdit;
    lbcs_PseudoWidth: TLabel;
    edcs_PseudoWidth: TDBEdit;
    lbcs_MaxRVLength: TLabel;
    edcs_MaxRVLength: TDBEdit;
    lbcs_ElecAmp: TLabel;
    edcs_ElecAmp: TDBEdit;
    lbcs_IsElec: TLabel;
    edcs_IsElec: TDBEdit;
    lbcs_IsWater: TLabel;
    edcs_IsWater: TDBEdit;
    lbcs_IsSewer: TLabel;
    edcs_IsSewer: TDBEdit;
    lbcs_IsPullThrough: TLabel;
    edcs_IsPullThrough: TDBEdit;
    lbcs_IsTentTrailerAddOnPossible: TLabel;
    edcs_IsTentTrailerAddOnPossible: TDBEdit;
    lbcs_IsFireTub: TLabel;
    edcs_IsFireTub: TDBEdit;
    lbcs_IsPickNickTable: TLabel;
    edcs_IsPickNickTable: TDBEdit;
    lbcs_IsTypeSeasonal: TLabel;
    edcs_IsTypeSeasonal: TDBEdit;
    lbcs_Notes: TLabel;
    edcs_Notes: TDBEdit;
    lbcs_IsUnavailable: TLabel;
    edcs_IsUnavailable: TDBEdit;
    lbcs_UnavailableFrom: TLabel;
    edcs_UnavailableFrom: TDBEdit;
    lbcs_UnavailableTo: TLabel;
    edcs_UnavailableTo: TDBEdit;
    lbcs_CreatedBy: TLabel;
    edcs_CreatedBy: TDBEdit;
    lbcs_CreatedDate: TLabel;
    edcs_CreatedDate: TDBEdit;
    lbcs_LastModifiedBy: TLabel;
    edcs_LastModifiedBy: TDBEdit;
    lbcs_LastModifiedDate: TLabel;
    edcs_LastModifiedDate: TDBEdit;
    XDataClientCampSiteGet: TXDataWebClient;
    XDataClientCampSiteDelete: TXDataWebClient;
    CampSiteDataset: TXDataWebDataSet;
    CampSiteDatasetcs_Id: TIntegerField;
    CampSiteDatasetcs_UID: TStringField;
    CampSiteDatasetcs_SiteName: TStringField;
    CampSiteDatasetctr_Id: TIntegerField;
    CampSiteDatasetcs_PseudoLength: TIntegerField;
    CampSiteDatasetcs_PseudoWidth: TIntegerField;
    CampSiteDatasetcs_MaxRVLength: TIntegerField;
    CampSiteDatasetcs_ElecAmp: TIntegerField;
    CampSiteDatasetcs_IsElec: TStringField;
    CampSiteDatasetcs_IsWater: TStringField;
    CampSiteDatasetcs_IsSewer: TStringField;
    CampSiteDatasetcs_IsPullThrough: TStringField;
    CampSiteDatasetcs_IsTentTrailerAddOnPossible: TStringField;
    CampSiteDatasetcs_IsFireTub: TStringField;
    CampSiteDatasetcs_IsPickNickTable: TStringField;
    CampSiteDatasetcs_IsTypeSeasonal: TStringField;
    CampSiteDatasetcs_Notes: TStringField;
    CampSiteDatasetcs_IsUnavailable: TStringField;
    CampSiteDatasetcs_UnavailableFrom: TDateTimeField;
    CampSiteDatasetcs_UnavailableTo: TIntegerField;
    CampSiteDatasetcs_CreatedBy: TIntegerField;
    CampSiteDatasetcs_CreatedDate: TDateTimeField;
    CampSiteDatasetcs_LastModifiedBy: TIntegerField;
    CampSiteDatasetcs_LastModifiedDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientCampSiteGetLoad(Response: TXDataClientResponse);
    procedure XDataClientCampSiteDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientCampSiteDeleteError(Error: TXDataClientError);
    procedure CampSiteDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewCampSiteEdit: TFViewCampSiteEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewCampSiteEdit }

procedure TFViewCampSiteEdit.btSaveClick(Sender: TObject);
begin
  if CampSiteDataset.State in dsEditModes then
  begin
        CampSiteDataset.Post;
    CampSiteDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewCampSiteEdit.btDeleteClick(Sender: TObject);
begin
  if CampSiteDataset.State in dsEditModes then
    CampSiteDataset.Post;
  XDataClientCampSiteDelete.Delete(CampSiteDataset.EntitySetName,
    TJSObject(CampSiteDataset.CurrentData));
end;


class function TFViewCampSiteEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewCampSiteEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewCampSiteEdit.InsertEntity;
begin
  CampSiteDataset.Close;
  CampSiteDataset.SetJsonData('{}');
  CampSiteDataset.Open;
  CampSiteDataset.Insert;
end;

class function TFViewCampSiteEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewCampSiteEdit(AForm).FInserting := AInserting;
    TFViewCampSiteEdit(AForm).FId := AId;
    TFViewCampSiteEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewCampSiteEdit(AForm).InsertEntity
    else
      TFViewCampSiteEdit(AForm).LoadEntity;
    TFViewCampSiteEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewCampSiteEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewCampSiteEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    CampSiteDataset.Connection.Model.DefaultSchema.FindEntityType(
      CampSiteDataset.EntitySetName
    )
  );
  XDataClientCampSiteGet.Get(CampSiteDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewCampSiteEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewCampSiteEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewCampSiteEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewCampSiteEdit.XDataClientCampSiteGetLoad(Response: TXDataClientResponse);
begin
  CampSiteDataset.Close;
  CampSiteDataset.SetJsonData(Response.Result);
  CampSiteDataset.Open;
  
  CampSiteDataset.Edit;  
end;

procedure TFViewCampSiteEdit.XDataClientCampSiteDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewCampSiteEdit.XDataClientCampSiteDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewCampSiteEdit.CampSiteDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewCampSiteEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewCampSiteEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbcs_Id := TLabel.Create('view.campsite.form.lbcs_Id');
  lbcs_UID := TLabel.Create('view.campsite.form.lbcs_UID');
  lbcs_SiteName := TLabel.Create('view.campsite.form.lbcs_SiteName');
  lbctr_Id := TLabel.Create('view.campsite.form.lbctr_Id');
  lbcs_PseudoLength := TLabel.Create('view.campsite.form.lbcs_PseudoLength');
  lbcs_PseudoWidth := TLabel.Create('view.campsite.form.lbcs_PseudoWidth');
  lbcs_MaxRVLength := TLabel.Create('view.campsite.form.lbcs_MaxRVLength');
  lbcs_ElecAmp := TLabel.Create('view.campsite.form.lbcs_ElecAmp');
  lbcs_IsElec := TLabel.Create('view.campsite.form.lbcs_IsElec');
  lbcs_IsWater := TLabel.Create('view.campsite.form.lbcs_IsWater');
  lbcs_IsSewer := TLabel.Create('view.campsite.form.lbcs_IsSewer');
  lbcs_IsPullThrough := TLabel.Create('view.campsite.form.lbcs_IsPullThrough');
  lbcs_IsTentTrailerAddOnPossible := TLabel.Create('view.campsite.form.lbcs_IsTentTrailerAddOnPossible');
  lbcs_IsFireTub := TLabel.Create('view.campsite.form.lbcs_IsFireTub');
  lbcs_IsPickNickTable := TLabel.Create('view.campsite.form.lbcs_IsPickNickTable');
  lbcs_IsTypeSeasonal := TLabel.Create('view.campsite.form.lbcs_IsTypeSeasonal');
  lbcs_Notes := TLabel.Create('view.campsite.form.lbcs_Notes');
  lbcs_IsUnavailable := TLabel.Create('view.campsite.form.lbcs_IsUnavailable');
  lbcs_UnavailableFrom := TLabel.Create('view.campsite.form.lbcs_UnavailableFrom');
  lbcs_UnavailableTo := TLabel.Create('view.campsite.form.lbcs_UnavailableTo');
  lbcs_CreatedBy := TLabel.Create('view.campsite.form.lbcs_CreatedBy');
  lbcs_CreatedDate := TLabel.Create('view.campsite.form.lbcs_CreatedDate');
  lbcs_LastModifiedBy := TLabel.Create('view.campsite.form.lbcs_LastModifiedBy');
  lbcs_LastModifiedDate := TLabel.Create('view.campsite.form.lbcs_LastModifiedDate');
  pnlMessage := TPanel.Create('view.campsite.form.message');
  lbMessage := TLabel.Create('view.campsite.form.message.label');
  btCloseMessage := TButton.Create('view.campsite.form.message.button');
  btCancel := TButton.Create('view.campsite.form.btBack');
  btSave := TButton.Create('view.campsite.form.btSave');
  btDelete := TButton.Create('view.campsite.form.btDelete');
  edcs_Id := TDBEdit.Create('view.campsite.form.edcs_Id');
  edcs_UID := TDBEdit.Create('view.campsite.form.edcs_UID');
  edcs_SiteName := TDBEdit.Create('view.campsite.form.edcs_SiteName');
  edctr_Id := TDBEdit.Create('view.campsite.form.edctr_Id');
  edcs_PseudoLength := TDBEdit.Create('view.campsite.form.edcs_PseudoLength');
  edcs_PseudoWidth := TDBEdit.Create('view.campsite.form.edcs_PseudoWidth');
  edcs_MaxRVLength := TDBEdit.Create('view.campsite.form.edcs_MaxRVLength');
  edcs_ElecAmp := TDBEdit.Create('view.campsite.form.edcs_ElecAmp');
  edcs_IsElec := TDBEdit.Create('view.campsite.form.edcs_IsElec');
  edcs_IsWater := TDBEdit.Create('view.campsite.form.edcs_IsWater');
  edcs_IsSewer := TDBEdit.Create('view.campsite.form.edcs_IsSewer');
  edcs_IsPullThrough := TDBEdit.Create('view.campsite.form.edcs_IsPullThrough');
  edcs_IsTentTrailerAddOnPossible := TDBEdit.Create('view.campsite.form.edcs_IsTentTrailerAddOnPossible');
  edcs_IsFireTub := TDBEdit.Create('view.campsite.form.edcs_IsFireTub');
  edcs_IsPickNickTable := TDBEdit.Create('view.campsite.form.edcs_IsPickNickTable');
  edcs_IsTypeSeasonal := TDBEdit.Create('view.campsite.form.edcs_IsTypeSeasonal');
  edcs_Notes := TDBEdit.Create('view.campsite.form.edcs_Notes');
  edcs_IsUnavailable := TDBEdit.Create('view.campsite.form.edcs_IsUnavailable');
  edcs_UnavailableFrom := TDBEdit.Create('view.campsite.form.edcs_UnavailableFrom');
  edcs_UnavailableTo := TDBEdit.Create('view.campsite.form.edcs_UnavailableTo');
  edcs_CreatedBy := TDBEdit.Create('view.campsite.form.edcs_CreatedBy');
  edcs_CreatedDate := TDBEdit.Create('view.campsite.form.edcs_CreatedDate');
  edcs_LastModifiedBy := TDBEdit.Create('view.campsite.form.edcs_LastModifiedBy');
  edcs_LastModifiedDate := TDBEdit.Create('view.campsite.form.edcs_LastModifiedDate');
  DataSource := TDataSource.Create(Self);
  CampSiteDataset := TXDataWebDataSet.Create(Self);
  CampSiteDatasetcs_Id := TIntegerField.Create(Self);
  CampSiteDatasetcs_UID := TStringField.Create(Self);
  CampSiteDatasetcs_SiteName := TStringField.Create(Self);
  CampSiteDatasetctr_Id := TIntegerField.Create(Self);
  CampSiteDatasetcs_PseudoLength := TIntegerField.Create(Self);
  CampSiteDatasetcs_PseudoWidth := TIntegerField.Create(Self);
  CampSiteDatasetcs_MaxRVLength := TIntegerField.Create(Self);
  CampSiteDatasetcs_ElecAmp := TIntegerField.Create(Self);
  CampSiteDatasetcs_IsElec := TStringField.Create(Self);
  CampSiteDatasetcs_IsWater := TStringField.Create(Self);
  CampSiteDatasetcs_IsSewer := TStringField.Create(Self);
  CampSiteDatasetcs_IsPullThrough := TStringField.Create(Self);
  CampSiteDatasetcs_IsTentTrailerAddOnPossible := TStringField.Create(Self);
  CampSiteDatasetcs_IsFireTub := TStringField.Create(Self);
  CampSiteDatasetcs_IsPickNickTable := TStringField.Create(Self);
  CampSiteDatasetcs_IsTypeSeasonal := TStringField.Create(Self);
  CampSiteDatasetcs_Notes := TStringField.Create(Self);
  CampSiteDatasetcs_IsUnavailable := TStringField.Create(Self);
  CampSiteDatasetcs_UnavailableFrom := TDateTimeField.Create(Self);
  CampSiteDatasetcs_UnavailableTo := TIntegerField.Create(Self);
  CampSiteDatasetcs_CreatedBy := TIntegerField.Create(Self);
  CampSiteDatasetcs_CreatedDate := TDateTimeField.Create(Self);
  CampSiteDatasetcs_LastModifiedBy := TIntegerField.Create(Self);
  CampSiteDatasetcs_LastModifiedDate := TDateTimeField.Create(Self);
  XDataClientCampSiteGet := TXDataWebClient.Create(Self);
  XDataClientCampSiteDelete := TXDataWebClient.Create(Self);

  lbcs_Id.BeforeLoadDFMValues;
  lbcs_UID.BeforeLoadDFMValues;
  lbcs_SiteName.BeforeLoadDFMValues;
  lbctr_Id.BeforeLoadDFMValues;
  lbcs_PseudoLength.BeforeLoadDFMValues;
  lbcs_PseudoWidth.BeforeLoadDFMValues;
  lbcs_MaxRVLength.BeforeLoadDFMValues;
  lbcs_ElecAmp.BeforeLoadDFMValues;
  lbcs_IsElec.BeforeLoadDFMValues;
  lbcs_IsWater.BeforeLoadDFMValues;
  lbcs_IsSewer.BeforeLoadDFMValues;
  lbcs_IsPullThrough.BeforeLoadDFMValues;
  lbcs_IsTentTrailerAddOnPossible.BeforeLoadDFMValues;
  lbcs_IsFireTub.BeforeLoadDFMValues;
  lbcs_IsPickNickTable.BeforeLoadDFMValues;
  lbcs_IsTypeSeasonal.BeforeLoadDFMValues;
  lbcs_Notes.BeforeLoadDFMValues;
  lbcs_IsUnavailable.BeforeLoadDFMValues;
  lbcs_UnavailableFrom.BeforeLoadDFMValues;
  lbcs_UnavailableTo.BeforeLoadDFMValues;
  lbcs_CreatedBy.BeforeLoadDFMValues;
  lbcs_CreatedDate.BeforeLoadDFMValues;
  lbcs_LastModifiedBy.BeforeLoadDFMValues;
  lbcs_LastModifiedDate.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edcs_Id.BeforeLoadDFMValues;
  edcs_UID.BeforeLoadDFMValues;
  edcs_SiteName.BeforeLoadDFMValues;
  edctr_Id.BeforeLoadDFMValues;
  edcs_PseudoLength.BeforeLoadDFMValues;
  edcs_PseudoWidth.BeforeLoadDFMValues;
  edcs_MaxRVLength.BeforeLoadDFMValues;
  edcs_ElecAmp.BeforeLoadDFMValues;
  edcs_IsElec.BeforeLoadDFMValues;
  edcs_IsWater.BeforeLoadDFMValues;
  edcs_IsSewer.BeforeLoadDFMValues;
  edcs_IsPullThrough.BeforeLoadDFMValues;
  edcs_IsTentTrailerAddOnPossible.BeforeLoadDFMValues;
  edcs_IsFireTub.BeforeLoadDFMValues;
  edcs_IsPickNickTable.BeforeLoadDFMValues;
  edcs_IsTypeSeasonal.BeforeLoadDFMValues;
  edcs_Notes.BeforeLoadDFMValues;
  edcs_IsUnavailable.BeforeLoadDFMValues;
  edcs_UnavailableFrom.BeforeLoadDFMValues;
  edcs_UnavailableTo.BeforeLoadDFMValues;
  edcs_CreatedBy.BeforeLoadDFMValues;
  edcs_CreatedDate.BeforeLoadDFMValues;
  edcs_LastModifiedBy.BeforeLoadDFMValues;
  edcs_LastModifiedDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  CampSiteDataset.BeforeLoadDFMValues;
  CampSiteDatasetcs_Id.BeforeLoadDFMValues;
  CampSiteDatasetcs_UID.BeforeLoadDFMValues;
  CampSiteDatasetcs_SiteName.BeforeLoadDFMValues;
  CampSiteDatasetctr_Id.BeforeLoadDFMValues;
  CampSiteDatasetcs_PseudoLength.BeforeLoadDFMValues;
  CampSiteDatasetcs_PseudoWidth.BeforeLoadDFMValues;
  CampSiteDatasetcs_MaxRVLength.BeforeLoadDFMValues;
  CampSiteDatasetcs_ElecAmp.BeforeLoadDFMValues;
  CampSiteDatasetcs_IsElec.BeforeLoadDFMValues;
  CampSiteDatasetcs_IsWater.BeforeLoadDFMValues;
  CampSiteDatasetcs_IsSewer.BeforeLoadDFMValues;
  CampSiteDatasetcs_IsPullThrough.BeforeLoadDFMValues;
  CampSiteDatasetcs_IsTentTrailerAddOnPossible.BeforeLoadDFMValues;
  CampSiteDatasetcs_IsFireTub.BeforeLoadDFMValues;
  CampSiteDatasetcs_IsPickNickTable.BeforeLoadDFMValues;
  CampSiteDatasetcs_IsTypeSeasonal.BeforeLoadDFMValues;
  CampSiteDatasetcs_Notes.BeforeLoadDFMValues;
  CampSiteDatasetcs_IsUnavailable.BeforeLoadDFMValues;
  CampSiteDatasetcs_UnavailableFrom.BeforeLoadDFMValues;
  CampSiteDatasetcs_UnavailableTo.BeforeLoadDFMValues;
  CampSiteDatasetcs_CreatedBy.BeforeLoadDFMValues;
  CampSiteDatasetcs_CreatedDate.BeforeLoadDFMValues;
  CampSiteDatasetcs_LastModifiedBy.BeforeLoadDFMValues;
  CampSiteDatasetcs_LastModifiedDate.BeforeLoadDFMValues;
  XDataClientCampSiteGet.BeforeLoadDFMValues;
  XDataClientCampSiteDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewCampSiteEdit';
    Width := 593;
    Height := 913;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbcs_Id.SetParentComponent(Self);
    lbcs_Id.Name := 'lbcs_Id';
    lbcs_Id.Left := 24;
    lbcs_Id.Top := 192;
    lbcs_Id.Width := 30;
    lbcs_Id.Height := 13;
    lbcs_Id.Caption := 'cs_Id:';
    lbcs_Id.HeightPercent := 100.000000000000000000;
    lbcs_Id.WidthPercent := 100.000000000000000000;
    lbcs_UID.SetParentComponent(Self);
    lbcs_UID.Name := 'lbcs_UID';
    lbcs_UID.Left := 24;
    lbcs_UID.Top := 219;
    lbcs_UID.Width := 38;
    lbcs_UID.Height := 13;
    lbcs_UID.Caption := 'cs_UID:';
    lbcs_UID.HeightPercent := 100.000000000000000000;
    lbcs_UID.WidthPercent := 100.000000000000000000;
    lbcs_SiteName.SetParentComponent(Self);
    lbcs_SiteName.Name := 'lbcs_SiteName';
    lbcs_SiteName.Left := 24;
    lbcs_SiteName.Top := 246;
    lbcs_SiteName.Width := 65;
    lbcs_SiteName.Height := 13;
    lbcs_SiteName.Caption := 'cs_SiteName:';
    lbcs_SiteName.HeightPercent := 100.000000000000000000;
    lbcs_SiteName.WidthPercent := 100.000000000000000000;
    lbctr_Id.SetParentComponent(Self);
    lbctr_Id.Name := 'lbctr_Id';
    lbctr_Id.Left := 24;
    lbctr_Id.Top := 273;
    lbctr_Id.Width := 33;
    lbctr_Id.Height := 13;
    lbctr_Id.Caption := 'ctr_Id:';
    lbctr_Id.HeightPercent := 100.000000000000000000;
    lbctr_Id.WidthPercent := 100.000000000000000000;
    lbcs_PseudoLength.SetParentComponent(Self);
    lbcs_PseudoLength.Name := 'lbcs_PseudoLength';
    lbcs_PseudoLength.Left := 24;
    lbcs_PseudoLength.Top := 300;
    lbcs_PseudoLength.Width := 88;
    lbcs_PseudoLength.Height := 13;
    lbcs_PseudoLength.Caption := 'cs_PseudoLength:';
    lbcs_PseudoLength.HeightPercent := 100.000000000000000000;
    lbcs_PseudoLength.WidthPercent := 100.000000000000000000;
    lbcs_PseudoWidth.SetParentComponent(Self);
    lbcs_PseudoWidth.Name := 'lbcs_PseudoWidth';
    lbcs_PseudoWidth.Left := 24;
    lbcs_PseudoWidth.Top := 327;
    lbcs_PseudoWidth.Width := 83;
    lbcs_PseudoWidth.Height := 13;
    lbcs_PseudoWidth.Caption := 'cs_PseudoWidth:';
    lbcs_PseudoWidth.HeightPercent := 100.000000000000000000;
    lbcs_PseudoWidth.WidthPercent := 100.000000000000000000;
    lbcs_MaxRVLength.SetParentComponent(Self);
    lbcs_MaxRVLength.Name := 'lbcs_MaxRVLength';
    lbcs_MaxRVLength.Left := 24;
    lbcs_MaxRVLength.Top := 354;
    lbcs_MaxRVLength.Width := 86;
    lbcs_MaxRVLength.Height := 13;
    lbcs_MaxRVLength.Caption := 'cs_MaxRVLength:';
    lbcs_MaxRVLength.HeightPercent := 100.000000000000000000;
    lbcs_MaxRVLength.WidthPercent := 100.000000000000000000;
    lbcs_ElecAmp.SetParentComponent(Self);
    lbcs_ElecAmp.Name := 'lbcs_ElecAmp';
    lbcs_ElecAmp.Left := 24;
    lbcs_ElecAmp.Top := 381;
    lbcs_ElecAmp.Width := 60;
    lbcs_ElecAmp.Height := 13;
    lbcs_ElecAmp.Caption := 'cs_ElecAmp:';
    lbcs_ElecAmp.HeightPercent := 100.000000000000000000;
    lbcs_ElecAmp.WidthPercent := 100.000000000000000000;
    lbcs_IsElec.SetParentComponent(Self);
    lbcs_IsElec.Name := 'lbcs_IsElec';
    lbcs_IsElec.Left := 24;
    lbcs_IsElec.Top := 408;
    lbcs_IsElec.Width := 48;
    lbcs_IsElec.Height := 13;
    lbcs_IsElec.Caption := 'cs_IsElec:';
    lbcs_IsElec.HeightPercent := 100.000000000000000000;
    lbcs_IsElec.WidthPercent := 100.000000000000000000;
    lbcs_IsWater.SetParentComponent(Self);
    lbcs_IsWater.Name := 'lbcs_IsWater';
    lbcs_IsWater.Left := 24;
    lbcs_IsWater.Top := 435;
    lbcs_IsWater.Width := 59;
    lbcs_IsWater.Height := 13;
    lbcs_IsWater.Caption := 'cs_IsWater:';
    lbcs_IsWater.HeightPercent := 100.000000000000000000;
    lbcs_IsWater.WidthPercent := 100.000000000000000000;
    lbcs_IsSewer.SetParentComponent(Self);
    lbcs_IsSewer.Name := 'lbcs_IsSewer';
    lbcs_IsSewer.Left := 24;
    lbcs_IsSewer.Top := 462;
    lbcs_IsSewer.Width := 59;
    lbcs_IsSewer.Height := 13;
    lbcs_IsSewer.Caption := 'cs_IsSewer:';
    lbcs_IsSewer.HeightPercent := 100.000000000000000000;
    lbcs_IsSewer.WidthPercent := 100.000000000000000000;
    lbcs_IsPullThrough.SetParentComponent(Self);
    lbcs_IsPullThrough.Name := 'lbcs_IsPullThrough';
    lbcs_IsPullThrough.Left := 24;
    lbcs_IsPullThrough.Top := 489;
    lbcs_IsPullThrough.Width := 85;
    lbcs_IsPullThrough.Height := 13;
    lbcs_IsPullThrough.Caption := 'cs_IsPullThrough:';
    lbcs_IsPullThrough.HeightPercent := 100.000000000000000000;
    lbcs_IsPullThrough.WidthPercent := 100.000000000000000000;
    lbcs_IsTentTrailerAddOnPossible.SetParentComponent(Self);
    lbcs_IsTentTrailerAddOnPossible.Name := 'lbcs_IsTentTrailerAddOnPossible';
    lbcs_IsTentTrailerAddOnPossible.Left := 24;
    lbcs_IsTentTrailerAddOnPossible.Top := 516;
    lbcs_IsTentTrailerAddOnPossible.Width := 152;
    lbcs_IsTentTrailerAddOnPossible.Height := 13;
    lbcs_IsTentTrailerAddOnPossible.Caption := 'cs_IsTentTrailerAddOnPossible:';
    lbcs_IsTentTrailerAddOnPossible.HeightPercent := 100.000000000000000000;
    lbcs_IsTentTrailerAddOnPossible.WidthPercent := 100.000000000000000000;
    lbcs_IsFireTub.SetParentComponent(Self);
    lbcs_IsFireTub.Name := 'lbcs_IsFireTub';
    lbcs_IsFireTub.Left := 24;
    lbcs_IsFireTub.Top := 543;
    lbcs_IsFireTub.Width := 65;
    lbcs_IsFireTub.Height := 13;
    lbcs_IsFireTub.Caption := 'cs_IsFireTub:';
    lbcs_IsFireTub.HeightPercent := 100.000000000000000000;
    lbcs_IsFireTub.WidthPercent := 100.000000000000000000;
    lbcs_IsPickNickTable.SetParentComponent(Self);
    lbcs_IsPickNickTable.Name := 'lbcs_IsPickNickTable';
    lbcs_IsPickNickTable.Left := 24;
    lbcs_IsPickNickTable.Top := 570;
    lbcs_IsPickNickTable.Width := 92;
    lbcs_IsPickNickTable.Height := 13;
    lbcs_IsPickNickTable.Caption := 'cs_IsPickNickTable:';
    lbcs_IsPickNickTable.HeightPercent := 100.000000000000000000;
    lbcs_IsPickNickTable.WidthPercent := 100.000000000000000000;
    lbcs_IsTypeSeasonal.SetParentComponent(Self);
    lbcs_IsTypeSeasonal.Name := 'lbcs_IsTypeSeasonal';
    lbcs_IsTypeSeasonal.Left := 24;
    lbcs_IsTypeSeasonal.Top := 597;
    lbcs_IsTypeSeasonal.Width := 96;
    lbcs_IsTypeSeasonal.Height := 13;
    lbcs_IsTypeSeasonal.Caption := 'cs_IsTypeSeasonal:';
    lbcs_IsTypeSeasonal.HeightPercent := 100.000000000000000000;
    lbcs_IsTypeSeasonal.WidthPercent := 100.000000000000000000;
    lbcs_Notes.SetParentComponent(Self);
    lbcs_Notes.Name := 'lbcs_Notes';
    lbcs_Notes.Left := 24;
    lbcs_Notes.Top := 624;
    lbcs_Notes.Width := 48;
    lbcs_Notes.Height := 13;
    lbcs_Notes.Caption := 'cs_Notes:';
    lbcs_Notes.HeightPercent := 100.000000000000000000;
    lbcs_Notes.WidthPercent := 100.000000000000000000;
    lbcs_IsUnavailable.SetParentComponent(Self);
    lbcs_IsUnavailable.Name := 'lbcs_IsUnavailable';
    lbcs_IsUnavailable.Left := 24;
    lbcs_IsUnavailable.Top := 651;
    lbcs_IsUnavailable.Width := 84;
    lbcs_IsUnavailable.Height := 13;
    lbcs_IsUnavailable.Caption := 'cs_IsUnavailable:';
    lbcs_IsUnavailable.HeightPercent := 100.000000000000000000;
    lbcs_IsUnavailable.WidthPercent := 100.000000000000000000;
    lbcs_UnavailableFrom.SetParentComponent(Self);
    lbcs_UnavailableFrom.Name := 'lbcs_UnavailableFrom';
    lbcs_UnavailableFrom.Left := 24;
    lbcs_UnavailableFrom.Top := 678;
    lbcs_UnavailableFrom.Width := 99;
    lbcs_UnavailableFrom.Height := 13;
    lbcs_UnavailableFrom.Caption := 'cs_UnavailableFrom:';
    lbcs_UnavailableFrom.HeightPercent := 100.000000000000000000;
    lbcs_UnavailableFrom.WidthPercent := 100.000000000000000000;
    lbcs_UnavailableTo.SetParentComponent(Self);
    lbcs_UnavailableTo.Name := 'lbcs_UnavailableTo';
    lbcs_UnavailableTo.Left := 24;
    lbcs_UnavailableTo.Top := 705;
    lbcs_UnavailableTo.Width := 87;
    lbcs_UnavailableTo.Height := 13;
    lbcs_UnavailableTo.Caption := 'cs_UnavailableTo:';
    lbcs_UnavailableTo.HeightPercent := 100.000000000000000000;
    lbcs_UnavailableTo.WidthPercent := 100.000000000000000000;
    lbcs_CreatedBy.SetParentComponent(Self);
    lbcs_CreatedBy.Name := 'lbcs_CreatedBy';
    lbcs_CreatedBy.Left := 24;
    lbcs_CreatedBy.Top := 732;
    lbcs_CreatedBy.Width := 71;
    lbcs_CreatedBy.Height := 13;
    lbcs_CreatedBy.Caption := 'cs_CreatedBy:';
    lbcs_CreatedBy.HeightPercent := 100.000000000000000000;
    lbcs_CreatedBy.WidthPercent := 100.000000000000000000;
    lbcs_CreatedDate.SetParentComponent(Self);
    lbcs_CreatedDate.Name := 'lbcs_CreatedDate';
    lbcs_CreatedDate.Left := 24;
    lbcs_CreatedDate.Top := 759;
    lbcs_CreatedDate.Width := 82;
    lbcs_CreatedDate.Height := 13;
    lbcs_CreatedDate.Caption := 'cs_CreatedDate:';
    lbcs_CreatedDate.HeightPercent := 100.000000000000000000;
    lbcs_CreatedDate.WidthPercent := 100.000000000000000000;
    lbcs_LastModifiedBy.SetParentComponent(Self);
    lbcs_LastModifiedBy.Name := 'lbcs_LastModifiedBy';
    lbcs_LastModifiedBy.Left := 24;
    lbcs_LastModifiedBy.Top := 786;
    lbcs_LastModifiedBy.Width := 92;
    lbcs_LastModifiedBy.Height := 13;
    lbcs_LastModifiedBy.Caption := 'cs_LastModifiedBy:';
    lbcs_LastModifiedBy.HeightPercent := 100.000000000000000000;
    lbcs_LastModifiedBy.WidthPercent := 100.000000000000000000;
    lbcs_LastModifiedDate.SetParentComponent(Self);
    lbcs_LastModifiedDate.Name := 'lbcs_LastModifiedDate';
    lbcs_LastModifiedDate.Left := 24;
    lbcs_LastModifiedDate.Top := 813;
    lbcs_LastModifiedDate.Width := 103;
    lbcs_LastModifiedDate.Height := 13;
    lbcs_LastModifiedDate.Caption := 'cs_LastModifiedDate:';
    lbcs_LastModifiedDate.HeightPercent := 100.000000000000000000;
    lbcs_LastModifiedDate.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 853;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 853;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 853;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edcs_Id.SetParentComponent(Self);
    edcs_Id.Name := 'edcs_Id';
    edcs_Id.Left := 221;
    edcs_Id.Top := 189;
    edcs_Id.Width := 200;
    edcs_Id.Height := 21;
    edcs_Id.HeightPercent := 100.000000000000000000;
    edcs_Id.TabOrder := 6;
    edcs_Id.Text := 'edcs_Id';
    edcs_Id.WidthPercent := 100.000000000000000000;
    edcs_Id.DataField := 'cs_Id';
    edcs_Id.DataSource := DataSource;
    edcs_UID.SetParentComponent(Self);
    edcs_UID.Name := 'edcs_UID';
    edcs_UID.Left := 221;
    edcs_UID.Top := 216;
    edcs_UID.Width := 200;
    edcs_UID.Height := 21;
    edcs_UID.HeightPercent := 100.000000000000000000;
    edcs_UID.TabOrder := 7;
    edcs_UID.Text := 'edcs_UID';
    edcs_UID.WidthPercent := 100.000000000000000000;
    edcs_UID.DataField := 'cs_UID';
    edcs_UID.DataSource := DataSource;
    edcs_SiteName.SetParentComponent(Self);
    edcs_SiteName.Name := 'edcs_SiteName';
    edcs_SiteName.Left := 221;
    edcs_SiteName.Top := 243;
    edcs_SiteName.Width := 200;
    edcs_SiteName.Height := 21;
    edcs_SiteName.HeightPercent := 100.000000000000000000;
    edcs_SiteName.TabOrder := 8;
    edcs_SiteName.Text := 'edcs_SiteName';
    edcs_SiteName.WidthPercent := 100.000000000000000000;
    edcs_SiteName.DataField := 'cs_SiteName';
    edcs_SiteName.DataSource := DataSource;
    edctr_Id.SetParentComponent(Self);
    edctr_Id.Name := 'edctr_Id';
    edctr_Id.Left := 221;
    edctr_Id.Top := 270;
    edctr_Id.Width := 200;
    edctr_Id.Height := 21;
    edctr_Id.HeightPercent := 100.000000000000000000;
    edctr_Id.TabOrder := 9;
    edctr_Id.Text := 'edctr_Id';
    edctr_Id.WidthPercent := 100.000000000000000000;
    edctr_Id.DataField := 'ctr_Id';
    edctr_Id.DataSource := DataSource;
    edcs_PseudoLength.SetParentComponent(Self);
    edcs_PseudoLength.Name := 'edcs_PseudoLength';
    edcs_PseudoLength.Left := 221;
    edcs_PseudoLength.Top := 297;
    edcs_PseudoLength.Width := 200;
    edcs_PseudoLength.Height := 21;
    edcs_PseudoLength.HeightPercent := 100.000000000000000000;
    edcs_PseudoLength.TabOrder := 10;
    edcs_PseudoLength.Text := 'edcs_PseudoLength';
    edcs_PseudoLength.WidthPercent := 100.000000000000000000;
    edcs_PseudoLength.DataField := 'cs_PseudoLength';
    edcs_PseudoLength.DataSource := DataSource;
    edcs_PseudoWidth.SetParentComponent(Self);
    edcs_PseudoWidth.Name := 'edcs_PseudoWidth';
    edcs_PseudoWidth.Left := 221;
    edcs_PseudoWidth.Top := 324;
    edcs_PseudoWidth.Width := 200;
    edcs_PseudoWidth.Height := 21;
    edcs_PseudoWidth.HeightPercent := 100.000000000000000000;
    edcs_PseudoWidth.TabOrder := 11;
    edcs_PseudoWidth.Text := 'edcs_PseudoWidth';
    edcs_PseudoWidth.WidthPercent := 100.000000000000000000;
    edcs_PseudoWidth.DataField := 'cs_PseudoWidth';
    edcs_PseudoWidth.DataSource := DataSource;
    edcs_MaxRVLength.SetParentComponent(Self);
    edcs_MaxRVLength.Name := 'edcs_MaxRVLength';
    edcs_MaxRVLength.Left := 221;
    edcs_MaxRVLength.Top := 351;
    edcs_MaxRVLength.Width := 200;
    edcs_MaxRVLength.Height := 21;
    edcs_MaxRVLength.HeightPercent := 100.000000000000000000;
    edcs_MaxRVLength.TabOrder := 12;
    edcs_MaxRVLength.Text := 'edcs_MaxRVLength';
    edcs_MaxRVLength.WidthPercent := 100.000000000000000000;
    edcs_MaxRVLength.DataField := 'cs_MaxRVLength';
    edcs_MaxRVLength.DataSource := DataSource;
    edcs_ElecAmp.SetParentComponent(Self);
    edcs_ElecAmp.Name := 'edcs_ElecAmp';
    edcs_ElecAmp.Left := 221;
    edcs_ElecAmp.Top := 378;
    edcs_ElecAmp.Width := 200;
    edcs_ElecAmp.Height := 21;
    edcs_ElecAmp.HeightPercent := 100.000000000000000000;
    edcs_ElecAmp.TabOrder := 13;
    edcs_ElecAmp.Text := 'edcs_ElecAmp';
    edcs_ElecAmp.WidthPercent := 100.000000000000000000;
    edcs_ElecAmp.DataField := 'cs_ElecAmp';
    edcs_ElecAmp.DataSource := DataSource;
    edcs_IsElec.SetParentComponent(Self);
    edcs_IsElec.Name := 'edcs_IsElec';
    edcs_IsElec.Left := 221;
    edcs_IsElec.Top := 405;
    edcs_IsElec.Width := 200;
    edcs_IsElec.Height := 21;
    edcs_IsElec.HeightPercent := 100.000000000000000000;
    edcs_IsElec.TabOrder := 14;
    edcs_IsElec.Text := 'edcs_IsElec';
    edcs_IsElec.WidthPercent := 100.000000000000000000;
    edcs_IsElec.DataField := 'cs_IsElec';
    edcs_IsElec.DataSource := DataSource;
    edcs_IsWater.SetParentComponent(Self);
    edcs_IsWater.Name := 'edcs_IsWater';
    edcs_IsWater.Left := 221;
    edcs_IsWater.Top := 432;
    edcs_IsWater.Width := 200;
    edcs_IsWater.Height := 21;
    edcs_IsWater.HeightPercent := 100.000000000000000000;
    edcs_IsWater.TabOrder := 15;
    edcs_IsWater.Text := 'edcs_IsWater';
    edcs_IsWater.WidthPercent := 100.000000000000000000;
    edcs_IsWater.DataField := 'cs_IsWater';
    edcs_IsWater.DataSource := DataSource;
    edcs_IsSewer.SetParentComponent(Self);
    edcs_IsSewer.Name := 'edcs_IsSewer';
    edcs_IsSewer.Left := 221;
    edcs_IsSewer.Top := 459;
    edcs_IsSewer.Width := 200;
    edcs_IsSewer.Height := 21;
    edcs_IsSewer.HeightPercent := 100.000000000000000000;
    edcs_IsSewer.TabOrder := 16;
    edcs_IsSewer.Text := 'edcs_IsSewer';
    edcs_IsSewer.WidthPercent := 100.000000000000000000;
    edcs_IsSewer.DataField := 'cs_IsSewer';
    edcs_IsSewer.DataSource := DataSource;
    edcs_IsPullThrough.SetParentComponent(Self);
    edcs_IsPullThrough.Name := 'edcs_IsPullThrough';
    edcs_IsPullThrough.Left := 221;
    edcs_IsPullThrough.Top := 486;
    edcs_IsPullThrough.Width := 200;
    edcs_IsPullThrough.Height := 21;
    edcs_IsPullThrough.HeightPercent := 100.000000000000000000;
    edcs_IsPullThrough.TabOrder := 17;
    edcs_IsPullThrough.Text := 'edcs_IsPullThrough';
    edcs_IsPullThrough.WidthPercent := 100.000000000000000000;
    edcs_IsPullThrough.DataField := 'cs_IsPullThrough';
    edcs_IsPullThrough.DataSource := DataSource;
    edcs_IsTentTrailerAddOnPossible.SetParentComponent(Self);
    edcs_IsTentTrailerAddOnPossible.Name := 'edcs_IsTentTrailerAddOnPossible';
    edcs_IsTentTrailerAddOnPossible.Left := 221;
    edcs_IsTentTrailerAddOnPossible.Top := 513;
    edcs_IsTentTrailerAddOnPossible.Width := 200;
    edcs_IsTentTrailerAddOnPossible.Height := 21;
    edcs_IsTentTrailerAddOnPossible.HeightPercent := 100.000000000000000000;
    edcs_IsTentTrailerAddOnPossible.TabOrder := 18;
    edcs_IsTentTrailerAddOnPossible.Text := 'edcs_IsTentTrailerAddOnPossible';
    edcs_IsTentTrailerAddOnPossible.WidthPercent := 100.000000000000000000;
    edcs_IsTentTrailerAddOnPossible.DataField := 'cs_IsTentTrailerAddOnPossible';
    edcs_IsTentTrailerAddOnPossible.DataSource := DataSource;
    edcs_IsFireTub.SetParentComponent(Self);
    edcs_IsFireTub.Name := 'edcs_IsFireTub';
    edcs_IsFireTub.Left := 221;
    edcs_IsFireTub.Top := 540;
    edcs_IsFireTub.Width := 200;
    edcs_IsFireTub.Height := 21;
    edcs_IsFireTub.HeightPercent := 100.000000000000000000;
    edcs_IsFireTub.TabOrder := 19;
    edcs_IsFireTub.Text := 'edcs_IsFireTub';
    edcs_IsFireTub.WidthPercent := 100.000000000000000000;
    edcs_IsFireTub.DataField := 'cs_IsFireTub';
    edcs_IsFireTub.DataSource := DataSource;
    edcs_IsPickNickTable.SetParentComponent(Self);
    edcs_IsPickNickTable.Name := 'edcs_IsPickNickTable';
    edcs_IsPickNickTable.Left := 221;
    edcs_IsPickNickTable.Top := 567;
    edcs_IsPickNickTable.Width := 200;
    edcs_IsPickNickTable.Height := 21;
    edcs_IsPickNickTable.HeightPercent := 100.000000000000000000;
    edcs_IsPickNickTable.TabOrder := 20;
    edcs_IsPickNickTable.Text := 'edcs_IsPickNickTable';
    edcs_IsPickNickTable.WidthPercent := 100.000000000000000000;
    edcs_IsPickNickTable.DataField := 'cs_IsPickNickTable';
    edcs_IsPickNickTable.DataSource := DataSource;
    edcs_IsTypeSeasonal.SetParentComponent(Self);
    edcs_IsTypeSeasonal.Name := 'edcs_IsTypeSeasonal';
    edcs_IsTypeSeasonal.Left := 221;
    edcs_IsTypeSeasonal.Top := 594;
    edcs_IsTypeSeasonal.Width := 200;
    edcs_IsTypeSeasonal.Height := 21;
    edcs_IsTypeSeasonal.HeightPercent := 100.000000000000000000;
    edcs_IsTypeSeasonal.TabOrder := 21;
    edcs_IsTypeSeasonal.Text := 'edcs_IsTypeSeasonal';
    edcs_IsTypeSeasonal.WidthPercent := 100.000000000000000000;
    edcs_IsTypeSeasonal.DataField := 'cs_IsTypeSeasonal';
    edcs_IsTypeSeasonal.DataSource := DataSource;
    edcs_Notes.SetParentComponent(Self);
    edcs_Notes.Name := 'edcs_Notes';
    edcs_Notes.Left := 221;
    edcs_Notes.Top := 621;
    edcs_Notes.Width := 200;
    edcs_Notes.Height := 21;
    edcs_Notes.HeightPercent := 100.000000000000000000;
    edcs_Notes.TabOrder := 22;
    edcs_Notes.Text := 'edcs_Notes';
    edcs_Notes.WidthPercent := 100.000000000000000000;
    edcs_Notes.DataField := 'cs_Notes';
    edcs_Notes.DataSource := DataSource;
    edcs_IsUnavailable.SetParentComponent(Self);
    edcs_IsUnavailable.Name := 'edcs_IsUnavailable';
    edcs_IsUnavailable.Left := 221;
    edcs_IsUnavailable.Top := 648;
    edcs_IsUnavailable.Width := 200;
    edcs_IsUnavailable.Height := 21;
    edcs_IsUnavailable.HeightPercent := 100.000000000000000000;
    edcs_IsUnavailable.TabOrder := 23;
    edcs_IsUnavailable.Text := 'edcs_IsUnavailable';
    edcs_IsUnavailable.WidthPercent := 100.000000000000000000;
    edcs_IsUnavailable.DataField := 'cs_IsUnavailable';
    edcs_IsUnavailable.DataSource := DataSource;
    edcs_UnavailableFrom.SetParentComponent(Self);
    edcs_UnavailableFrom.Name := 'edcs_UnavailableFrom';
    edcs_UnavailableFrom.Left := 221;
    edcs_UnavailableFrom.Top := 675;
    edcs_UnavailableFrom.Width := 200;
    edcs_UnavailableFrom.Height := 21;
    edcs_UnavailableFrom.HeightPercent := 100.000000000000000000;
    edcs_UnavailableFrom.TabOrder := 24;
    edcs_UnavailableFrom.Text := 'edcs_UnavailableFrom';
    edcs_UnavailableFrom.WidthPercent := 100.000000000000000000;
    edcs_UnavailableFrom.DataField := 'cs_UnavailableFrom';
    edcs_UnavailableFrom.DataSource := DataSource;
    edcs_UnavailableTo.SetParentComponent(Self);
    edcs_UnavailableTo.Name := 'edcs_UnavailableTo';
    edcs_UnavailableTo.Left := 221;
    edcs_UnavailableTo.Top := 702;
    edcs_UnavailableTo.Width := 200;
    edcs_UnavailableTo.Height := 21;
    edcs_UnavailableTo.HeightPercent := 100.000000000000000000;
    edcs_UnavailableTo.TabOrder := 25;
    edcs_UnavailableTo.Text := 'edcs_UnavailableTo';
    edcs_UnavailableTo.WidthPercent := 100.000000000000000000;
    edcs_UnavailableTo.DataField := 'cs_UnavailableTo';
    edcs_UnavailableTo.DataSource := DataSource;
    edcs_CreatedBy.SetParentComponent(Self);
    edcs_CreatedBy.Name := 'edcs_CreatedBy';
    edcs_CreatedBy.Left := 221;
    edcs_CreatedBy.Top := 729;
    edcs_CreatedBy.Width := 200;
    edcs_CreatedBy.Height := 21;
    edcs_CreatedBy.HeightPercent := 100.000000000000000000;
    edcs_CreatedBy.TabOrder := 26;
    edcs_CreatedBy.Text := 'edcs_CreatedBy';
    edcs_CreatedBy.WidthPercent := 100.000000000000000000;
    edcs_CreatedBy.DataField := 'cs_CreatedBy';
    edcs_CreatedBy.DataSource := DataSource;
    edcs_CreatedDate.SetParentComponent(Self);
    edcs_CreatedDate.Name := 'edcs_CreatedDate';
    edcs_CreatedDate.Left := 221;
    edcs_CreatedDate.Top := 756;
    edcs_CreatedDate.Width := 200;
    edcs_CreatedDate.Height := 21;
    edcs_CreatedDate.HeightPercent := 100.000000000000000000;
    edcs_CreatedDate.TabOrder := 27;
    edcs_CreatedDate.Text := 'edcs_CreatedDate';
    edcs_CreatedDate.WidthPercent := 100.000000000000000000;
    edcs_CreatedDate.DataField := 'cs_CreatedDate';
    edcs_CreatedDate.DataSource := DataSource;
    edcs_LastModifiedBy.SetParentComponent(Self);
    edcs_LastModifiedBy.Name := 'edcs_LastModifiedBy';
    edcs_LastModifiedBy.Left := 221;
    edcs_LastModifiedBy.Top := 783;
    edcs_LastModifiedBy.Width := 200;
    edcs_LastModifiedBy.Height := 21;
    edcs_LastModifiedBy.HeightPercent := 100.000000000000000000;
    edcs_LastModifiedBy.TabOrder := 28;
    edcs_LastModifiedBy.Text := 'edcs_LastModifiedBy';
    edcs_LastModifiedBy.WidthPercent := 100.000000000000000000;
    edcs_LastModifiedBy.DataField := 'cs_LastModifiedBy';
    edcs_LastModifiedBy.DataSource := DataSource;
    edcs_LastModifiedDate.SetParentComponent(Self);
    edcs_LastModifiedDate.Name := 'edcs_LastModifiedDate';
    edcs_LastModifiedDate.Left := 221;
    edcs_LastModifiedDate.Top := 810;
    edcs_LastModifiedDate.Width := 200;
    edcs_LastModifiedDate.Height := 21;
    edcs_LastModifiedDate.HeightPercent := 100.000000000000000000;
    edcs_LastModifiedDate.TabOrder := 29;
    edcs_LastModifiedDate.Text := 'edcs_LastModifiedDate';
    edcs_LastModifiedDate.WidthPercent := 100.000000000000000000;
    edcs_LastModifiedDate.DataField := 'cs_LastModifiedDate';
    edcs_LastModifiedDate.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := CampSiteDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    CampSiteDataset.SetParentComponent(Self);
    CampSiteDataset.Name := 'CampSiteDataset';
    CampSiteDataset.AfterApplyUpdates := CampSiteDatasetAfterApplyUpdates;
    CampSiteDataset.EntitySetName := 'CampSite';
    CampSiteDataset.Connection := DMConnection.ApiConnection;
    CampSiteDataset.Left := 176;
    CampSiteDataset.Top := 8;
    CampSiteDatasetcs_Id.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_Id.Name := 'CampSiteDatasetcs_Id';
    CampSiteDatasetcs_Id.FieldName := 'cs_Id';
    CampSiteDatasetcs_Id.Required := True;
    CampSiteDatasetcs_UID.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_UID.Name := 'CampSiteDatasetcs_UID';
    CampSiteDatasetcs_UID.FieldName := 'cs_UID';
    CampSiteDatasetcs_UID.Size := 15;
    CampSiteDatasetcs_SiteName.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_SiteName.Name := 'CampSiteDatasetcs_SiteName';
    CampSiteDatasetcs_SiteName.FieldName := 'cs_SiteName';
    CampSiteDatasetcs_SiteName.Size := 50;
    CampSiteDatasetctr_Id.SetParentComponent(CampSiteDataset);
    CampSiteDatasetctr_Id.Name := 'CampSiteDatasetctr_Id';
    CampSiteDatasetctr_Id.FieldName := 'ctr_Id';
    CampSiteDatasetcs_PseudoLength.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_PseudoLength.Name := 'CampSiteDatasetcs_PseudoLength';
    CampSiteDatasetcs_PseudoLength.FieldName := 'cs_PseudoLength';
    CampSiteDatasetcs_PseudoWidth.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_PseudoWidth.Name := 'CampSiteDatasetcs_PseudoWidth';
    CampSiteDatasetcs_PseudoWidth.FieldName := 'cs_PseudoWidth';
    CampSiteDatasetcs_MaxRVLength.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_MaxRVLength.Name := 'CampSiteDatasetcs_MaxRVLength';
    CampSiteDatasetcs_MaxRVLength.FieldName := 'cs_MaxRVLength';
    CampSiteDatasetcs_ElecAmp.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_ElecAmp.Name := 'CampSiteDatasetcs_ElecAmp';
    CampSiteDatasetcs_ElecAmp.FieldName := 'cs_ElecAmp';
    CampSiteDatasetcs_IsElec.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_IsElec.Name := 'CampSiteDatasetcs_IsElec';
    CampSiteDatasetcs_IsElec.FieldName := 'cs_IsElec';
    CampSiteDatasetcs_IsElec.Size := 255;
    CampSiteDatasetcs_IsWater.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_IsWater.Name := 'CampSiteDatasetcs_IsWater';
    CampSiteDatasetcs_IsWater.FieldName := 'cs_IsWater';
    CampSiteDatasetcs_IsWater.Size := 255;
    CampSiteDatasetcs_IsSewer.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_IsSewer.Name := 'CampSiteDatasetcs_IsSewer';
    CampSiteDatasetcs_IsSewer.FieldName := 'cs_IsSewer';
    CampSiteDatasetcs_IsSewer.Size := 255;
    CampSiteDatasetcs_IsPullThrough.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_IsPullThrough.Name := 'CampSiteDatasetcs_IsPullThrough';
    CampSiteDatasetcs_IsPullThrough.FieldName := 'cs_IsPullThrough';
    CampSiteDatasetcs_IsPullThrough.Size := 255;
    CampSiteDatasetcs_IsTentTrailerAddOnPossible.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_IsTentTrailerAddOnPossible.Name := 'CampSiteDatasetcs_IsTentTrailerAddOnPossible';
    CampSiteDatasetcs_IsTentTrailerAddOnPossible.FieldName := 'cs_IsTentTrailerAddOnPossible';
    CampSiteDatasetcs_IsTentTrailerAddOnPossible.Size := 255;
    CampSiteDatasetcs_IsFireTub.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_IsFireTub.Name := 'CampSiteDatasetcs_IsFireTub';
    CampSiteDatasetcs_IsFireTub.FieldName := 'cs_IsFireTub';
    CampSiteDatasetcs_IsFireTub.Size := 255;
    CampSiteDatasetcs_IsPickNickTable.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_IsPickNickTable.Name := 'CampSiteDatasetcs_IsPickNickTable';
    CampSiteDatasetcs_IsPickNickTable.FieldName := 'cs_IsPickNickTable';
    CampSiteDatasetcs_IsPickNickTable.Size := 255;
    CampSiteDatasetcs_IsTypeSeasonal.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_IsTypeSeasonal.Name := 'CampSiteDatasetcs_IsTypeSeasonal';
    CampSiteDatasetcs_IsTypeSeasonal.FieldName := 'cs_IsTypeSeasonal';
    CampSiteDatasetcs_IsTypeSeasonal.Size := 255;
    CampSiteDatasetcs_Notes.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_Notes.Name := 'CampSiteDatasetcs_Notes';
    CampSiteDatasetcs_Notes.FieldName := 'cs_Notes';
    CampSiteDatasetcs_Notes.Size := 500;
    CampSiteDatasetcs_IsUnavailable.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_IsUnavailable.Name := 'CampSiteDatasetcs_IsUnavailable';
    CampSiteDatasetcs_IsUnavailable.FieldName := 'cs_IsUnavailable';
    CampSiteDatasetcs_IsUnavailable.Size := 255;
    CampSiteDatasetcs_UnavailableFrom.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_UnavailableFrom.Name := 'CampSiteDatasetcs_UnavailableFrom';
    CampSiteDatasetcs_UnavailableFrom.FieldName := 'cs_UnavailableFrom';
    CampSiteDatasetcs_UnavailableTo.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_UnavailableTo.Name := 'CampSiteDatasetcs_UnavailableTo';
    CampSiteDatasetcs_UnavailableTo.FieldName := 'cs_UnavailableTo';
    CampSiteDatasetcs_CreatedBy.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_CreatedBy.Name := 'CampSiteDatasetcs_CreatedBy';
    CampSiteDatasetcs_CreatedBy.FieldName := 'cs_CreatedBy';
    CampSiteDatasetcs_CreatedDate.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_CreatedDate.Name := 'CampSiteDatasetcs_CreatedDate';
    CampSiteDatasetcs_CreatedDate.FieldName := 'cs_CreatedDate';
    CampSiteDatasetcs_LastModifiedBy.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_LastModifiedBy.Name := 'CampSiteDatasetcs_LastModifiedBy';
    CampSiteDatasetcs_LastModifiedBy.FieldName := 'cs_LastModifiedBy';
    CampSiteDatasetcs_LastModifiedDate.SetParentComponent(CampSiteDataset);
    CampSiteDatasetcs_LastModifiedDate.Name := 'CampSiteDatasetcs_LastModifiedDate';
    CampSiteDatasetcs_LastModifiedDate.FieldName := 'cs_LastModifiedDate';
    XDataClientCampSiteGet.SetParentComponent(Self);
    XDataClientCampSiteGet.Name := 'XDataClientCampSiteGet';
    XDataClientCampSiteGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientCampSiteGet, Self, 'OnLoad', 'XDataClientCampSiteGetLoad');
    XDataClientCampSiteGet.Left := 64;
    XDataClientCampSiteGet.Top := 8;
    XDataClientCampSiteDelete.SetParentComponent(Self);
    XDataClientCampSiteDelete.Name := 'XDataClientCampSiteDelete';
    XDataClientCampSiteDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientCampSiteDelete, Self, 'OnLoad', 'XDataClientCampSiteDeleteLoad');
    SetEvent(XDataClientCampSiteDelete, Self, 'OnError', 'XDataClientCampSiteDeleteError');
    XDataClientCampSiteDelete.Left := 64;
    XDataClientCampSiteDelete.Top := 56;
  finally
    lbcs_Id.AfterLoadDFMValues;
    lbcs_UID.AfterLoadDFMValues;
    lbcs_SiteName.AfterLoadDFMValues;
    lbctr_Id.AfterLoadDFMValues;
    lbcs_PseudoLength.AfterLoadDFMValues;
    lbcs_PseudoWidth.AfterLoadDFMValues;
    lbcs_MaxRVLength.AfterLoadDFMValues;
    lbcs_ElecAmp.AfterLoadDFMValues;
    lbcs_IsElec.AfterLoadDFMValues;
    lbcs_IsWater.AfterLoadDFMValues;
    lbcs_IsSewer.AfterLoadDFMValues;
    lbcs_IsPullThrough.AfterLoadDFMValues;
    lbcs_IsTentTrailerAddOnPossible.AfterLoadDFMValues;
    lbcs_IsFireTub.AfterLoadDFMValues;
    lbcs_IsPickNickTable.AfterLoadDFMValues;
    lbcs_IsTypeSeasonal.AfterLoadDFMValues;
    lbcs_Notes.AfterLoadDFMValues;
    lbcs_IsUnavailable.AfterLoadDFMValues;
    lbcs_UnavailableFrom.AfterLoadDFMValues;
    lbcs_UnavailableTo.AfterLoadDFMValues;
    lbcs_CreatedBy.AfterLoadDFMValues;
    lbcs_CreatedDate.AfterLoadDFMValues;
    lbcs_LastModifiedBy.AfterLoadDFMValues;
    lbcs_LastModifiedDate.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edcs_Id.AfterLoadDFMValues;
    edcs_UID.AfterLoadDFMValues;
    edcs_SiteName.AfterLoadDFMValues;
    edctr_Id.AfterLoadDFMValues;
    edcs_PseudoLength.AfterLoadDFMValues;
    edcs_PseudoWidth.AfterLoadDFMValues;
    edcs_MaxRVLength.AfterLoadDFMValues;
    edcs_ElecAmp.AfterLoadDFMValues;
    edcs_IsElec.AfterLoadDFMValues;
    edcs_IsWater.AfterLoadDFMValues;
    edcs_IsSewer.AfterLoadDFMValues;
    edcs_IsPullThrough.AfterLoadDFMValues;
    edcs_IsTentTrailerAddOnPossible.AfterLoadDFMValues;
    edcs_IsFireTub.AfterLoadDFMValues;
    edcs_IsPickNickTable.AfterLoadDFMValues;
    edcs_IsTypeSeasonal.AfterLoadDFMValues;
    edcs_Notes.AfterLoadDFMValues;
    edcs_IsUnavailable.AfterLoadDFMValues;
    edcs_UnavailableFrom.AfterLoadDFMValues;
    edcs_UnavailableTo.AfterLoadDFMValues;
    edcs_CreatedBy.AfterLoadDFMValues;
    edcs_CreatedDate.AfterLoadDFMValues;
    edcs_LastModifiedBy.AfterLoadDFMValues;
    edcs_LastModifiedDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    CampSiteDataset.AfterLoadDFMValues;
    CampSiteDatasetcs_Id.AfterLoadDFMValues;
    CampSiteDatasetcs_UID.AfterLoadDFMValues;
    CampSiteDatasetcs_SiteName.AfterLoadDFMValues;
    CampSiteDatasetctr_Id.AfterLoadDFMValues;
    CampSiteDatasetcs_PseudoLength.AfterLoadDFMValues;
    CampSiteDatasetcs_PseudoWidth.AfterLoadDFMValues;
    CampSiteDatasetcs_MaxRVLength.AfterLoadDFMValues;
    CampSiteDatasetcs_ElecAmp.AfterLoadDFMValues;
    CampSiteDatasetcs_IsElec.AfterLoadDFMValues;
    CampSiteDatasetcs_IsWater.AfterLoadDFMValues;
    CampSiteDatasetcs_IsSewer.AfterLoadDFMValues;
    CampSiteDatasetcs_IsPullThrough.AfterLoadDFMValues;
    CampSiteDatasetcs_IsTentTrailerAddOnPossible.AfterLoadDFMValues;
    CampSiteDatasetcs_IsFireTub.AfterLoadDFMValues;
    CampSiteDatasetcs_IsPickNickTable.AfterLoadDFMValues;
    CampSiteDatasetcs_IsTypeSeasonal.AfterLoadDFMValues;
    CampSiteDatasetcs_Notes.AfterLoadDFMValues;
    CampSiteDatasetcs_IsUnavailable.AfterLoadDFMValues;
    CampSiteDatasetcs_UnavailableFrom.AfterLoadDFMValues;
    CampSiteDatasetcs_UnavailableTo.AfterLoadDFMValues;
    CampSiteDatasetcs_CreatedBy.AfterLoadDFMValues;
    CampSiteDatasetcs_CreatedDate.AfterLoadDFMValues;
    CampSiteDatasetcs_LastModifiedBy.AfterLoadDFMValues;
    CampSiteDatasetcs_LastModifiedDate.AfterLoadDFMValues;
    XDataClientCampSiteGet.AfterLoadDFMValues;
    XDataClientCampSiteDelete.AfterLoadDFMValues;
  end;
end;

end.
