unit View.EstateInventory.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewEstateInventoryEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbei_Id: TLabel;
    edei_Id: TDBEdit;
    lbcs_Id: TLabel;
    edcs_Id: TDBEdit;
    lbcli_IdSeller: TLabel;
    edcli_IdSeller: TDBEdit;
    lbcli_IdBuyers: TLabel;
    edcli_IdBuyers: TDBEdit;
    lbei_DisplayName: TLabel;
    edei_DisplayName: TDBEdit;
    lbei_FullDescription: TLabel;
    edei_FullDescription: TDBEdit;
    lbei_InsctiptionDate: TLabel;
    edei_InsctiptionDate: TDBEdit;
    lbei_AskedPrice: TLabel;
    edei_AskedPrice: TDBEdit;
    lbei_ClosedSalePrice: TLabel;
    edei_ClosedSalePrice: TDBEdit;
    lbei_Notes: TLabel;
    edei_Notes: TDBEdit;
    lbei_CreatedBy: TLabel;
    edei_CreatedBy: TDBEdit;
    lbei_CreatedDate: TLabel;
    edei_CreatedDate: TDBEdit;
    lbei_LastModifiedBy: TLabel;
    edei_LastModifiedBy: TDBEdit;
    lbei_LastModifiedDate: TLabel;
    edei_LastModifiedDate: TDBEdit;
    XDataClientEstateInventoryGet: TXDataWebClient;
    XDataClientEstateInventoryDelete: TXDataWebClient;
    EstateInventoryDataset: TXDataWebDataSet;
    EstateInventoryDatasetei_Id: TIntegerField;
    EstateInventoryDatasetcs_Id: TIntegerField;
    EstateInventoryDatasetcli_IdSeller: TIntegerField;
    EstateInventoryDatasetcli_IdBuyers: TIntegerField;
    EstateInventoryDatasetei_DisplayName: TStringField;
    EstateInventoryDatasetei_FullDescription: TStringField;
    EstateInventoryDatasetei_InsctiptionDate: TIntegerField;
    EstateInventoryDatasetei_AskedPrice: TIntegerField;
    EstateInventoryDatasetei_ClosedSalePrice: TIntegerField;
    EstateInventoryDatasetei_Notes: TStringField;
    EstateInventoryDatasetei_CreatedBy: TIntegerField;
    EstateInventoryDatasetei_CreatedDate: TDateTimeField;
    EstateInventoryDatasetei_LastModifiedBy: TIntegerField;
    EstateInventoryDatasetei_LastModifiedDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientEstateInventoryGetLoad(Response: TXDataClientResponse);
    procedure XDataClientEstateInventoryDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientEstateInventoryDeleteError(Error: TXDataClientError);
    procedure EstateInventoryDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewEstateInventoryEdit: TFViewEstateInventoryEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewEstateInventoryEdit }

procedure TFViewEstateInventoryEdit.btSaveClick(Sender: TObject);
begin
  if EstateInventoryDataset.State in dsEditModes then
  begin
        EstateInventoryDataset.Post;
    EstateInventoryDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewEstateInventoryEdit.btDeleteClick(Sender: TObject);
begin
  if EstateInventoryDataset.State in dsEditModes then
    EstateInventoryDataset.Post;
  XDataClientEstateInventoryDelete.Delete(EstateInventoryDataset.EntitySetName,
    TJSObject(EstateInventoryDataset.CurrentData));
end;


class function TFViewEstateInventoryEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewEstateInventoryEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewEstateInventoryEdit.InsertEntity;
begin
  EstateInventoryDataset.Close;
  EstateInventoryDataset.SetJsonData('{}');
  EstateInventoryDataset.Open;
  EstateInventoryDataset.Insert;
end;

class function TFViewEstateInventoryEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewEstateInventoryEdit(AForm).FInserting := AInserting;
    TFViewEstateInventoryEdit(AForm).FId := AId;
    TFViewEstateInventoryEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewEstateInventoryEdit(AForm).InsertEntity
    else
      TFViewEstateInventoryEdit(AForm).LoadEntity;
    TFViewEstateInventoryEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewEstateInventoryEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewEstateInventoryEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    EstateInventoryDataset.Connection.Model.DefaultSchema.FindEntityType(
      EstateInventoryDataset.EntitySetName
    )
  );
  XDataClientEstateInventoryGet.Get(EstateInventoryDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewEstateInventoryEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewEstateInventoryEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewEstateInventoryEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewEstateInventoryEdit.XDataClientEstateInventoryGetLoad(Response: TXDataClientResponse);
begin
  EstateInventoryDataset.Close;
  EstateInventoryDataset.SetJsonData(Response.Result);
  EstateInventoryDataset.Open;
  
  EstateInventoryDataset.Edit;  
end;

procedure TFViewEstateInventoryEdit.XDataClientEstateInventoryDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewEstateInventoryEdit.XDataClientEstateInventoryDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewEstateInventoryEdit.EstateInventoryDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewEstateInventoryEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewEstateInventoryEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbei_Id := TLabel.Create('view.estateinventory.form.lbei_Id');
  lbcs_Id := TLabel.Create('view.estateinventory.form.lbcs_Id');
  lbcli_IdSeller := TLabel.Create('view.estateinventory.form.lbcli_IdSeller');
  lbcli_IdBuyers := TLabel.Create('view.estateinventory.form.lbcli_IdBuyers');
  lbei_DisplayName := TLabel.Create('view.estateinventory.form.lbei_DisplayName');
  lbei_FullDescription := TLabel.Create('view.estateinventory.form.lbei_FullDescription');
  lbei_InsctiptionDate := TLabel.Create('view.estateinventory.form.lbei_InsctiptionDate');
  lbei_AskedPrice := TLabel.Create('view.estateinventory.form.lbei_AskedPrice');
  lbei_ClosedSalePrice := TLabel.Create('view.estateinventory.form.lbei_ClosedSalePrice');
  lbei_Notes := TLabel.Create('view.estateinventory.form.lbei_Notes');
  lbei_CreatedBy := TLabel.Create('view.estateinventory.form.lbei_CreatedBy');
  lbei_CreatedDate := TLabel.Create('view.estateinventory.form.lbei_CreatedDate');
  lbei_LastModifiedBy := TLabel.Create('view.estateinventory.form.lbei_LastModifiedBy');
  lbei_LastModifiedDate := TLabel.Create('view.estateinventory.form.lbei_LastModifiedDate');
  pnlMessage := TPanel.Create('view.estateinventory.form.message');
  lbMessage := TLabel.Create('view.estateinventory.form.message.label');
  btCloseMessage := TButton.Create('view.estateinventory.form.message.button');
  btCancel := TButton.Create('view.estateinventory.form.btBack');
  btSave := TButton.Create('view.estateinventory.form.btSave');
  btDelete := TButton.Create('view.estateinventory.form.btDelete');
  edei_Id := TDBEdit.Create('view.estateinventory.form.edei_Id');
  edcs_Id := TDBEdit.Create('view.estateinventory.form.edcs_Id');
  edcli_IdSeller := TDBEdit.Create('view.estateinventory.form.edcli_IdSeller');
  edcli_IdBuyers := TDBEdit.Create('view.estateinventory.form.edcli_IdBuyers');
  edei_DisplayName := TDBEdit.Create('view.estateinventory.form.edei_DisplayName');
  edei_FullDescription := TDBEdit.Create('view.estateinventory.form.edei_FullDescription');
  edei_InsctiptionDate := TDBEdit.Create('view.estateinventory.form.edei_InsctiptionDate');
  edei_AskedPrice := TDBEdit.Create('view.estateinventory.form.edei_AskedPrice');
  edei_ClosedSalePrice := TDBEdit.Create('view.estateinventory.form.edei_ClosedSalePrice');
  edei_Notes := TDBEdit.Create('view.estateinventory.form.edei_Notes');
  edei_CreatedBy := TDBEdit.Create('view.estateinventory.form.edei_CreatedBy');
  edei_CreatedDate := TDBEdit.Create('view.estateinventory.form.edei_CreatedDate');
  edei_LastModifiedBy := TDBEdit.Create('view.estateinventory.form.edei_LastModifiedBy');
  edei_LastModifiedDate := TDBEdit.Create('view.estateinventory.form.edei_LastModifiedDate');
  DataSource := TDataSource.Create(Self);
  EstateInventoryDataset := TXDataWebDataSet.Create(Self);
  EstateInventoryDatasetei_Id := TIntegerField.Create(Self);
  EstateInventoryDatasetcs_Id := TIntegerField.Create(Self);
  EstateInventoryDatasetcli_IdSeller := TIntegerField.Create(Self);
  EstateInventoryDatasetcli_IdBuyers := TIntegerField.Create(Self);
  EstateInventoryDatasetei_DisplayName := TStringField.Create(Self);
  EstateInventoryDatasetei_FullDescription := TStringField.Create(Self);
  EstateInventoryDatasetei_InsctiptionDate := TIntegerField.Create(Self);
  EstateInventoryDatasetei_AskedPrice := TIntegerField.Create(Self);
  EstateInventoryDatasetei_ClosedSalePrice := TIntegerField.Create(Self);
  EstateInventoryDatasetei_Notes := TStringField.Create(Self);
  EstateInventoryDatasetei_CreatedBy := TIntegerField.Create(Self);
  EstateInventoryDatasetei_CreatedDate := TDateTimeField.Create(Self);
  EstateInventoryDatasetei_LastModifiedBy := TIntegerField.Create(Self);
  EstateInventoryDatasetei_LastModifiedDate := TDateTimeField.Create(Self);
  XDataClientEstateInventoryGet := TXDataWebClient.Create(Self);
  XDataClientEstateInventoryDelete := TXDataWebClient.Create(Self);

  lbei_Id.BeforeLoadDFMValues;
  lbcs_Id.BeforeLoadDFMValues;
  lbcli_IdSeller.BeforeLoadDFMValues;
  lbcli_IdBuyers.BeforeLoadDFMValues;
  lbei_DisplayName.BeforeLoadDFMValues;
  lbei_FullDescription.BeforeLoadDFMValues;
  lbei_InsctiptionDate.BeforeLoadDFMValues;
  lbei_AskedPrice.BeforeLoadDFMValues;
  lbei_ClosedSalePrice.BeforeLoadDFMValues;
  lbei_Notes.BeforeLoadDFMValues;
  lbei_CreatedBy.BeforeLoadDFMValues;
  lbei_CreatedDate.BeforeLoadDFMValues;
  lbei_LastModifiedBy.BeforeLoadDFMValues;
  lbei_LastModifiedDate.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edei_Id.BeforeLoadDFMValues;
  edcs_Id.BeforeLoadDFMValues;
  edcli_IdSeller.BeforeLoadDFMValues;
  edcli_IdBuyers.BeforeLoadDFMValues;
  edei_DisplayName.BeforeLoadDFMValues;
  edei_FullDescription.BeforeLoadDFMValues;
  edei_InsctiptionDate.BeforeLoadDFMValues;
  edei_AskedPrice.BeforeLoadDFMValues;
  edei_ClosedSalePrice.BeforeLoadDFMValues;
  edei_Notes.BeforeLoadDFMValues;
  edei_CreatedBy.BeforeLoadDFMValues;
  edei_CreatedDate.BeforeLoadDFMValues;
  edei_LastModifiedBy.BeforeLoadDFMValues;
  edei_LastModifiedDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  EstateInventoryDataset.BeforeLoadDFMValues;
  EstateInventoryDatasetei_Id.BeforeLoadDFMValues;
  EstateInventoryDatasetcs_Id.BeforeLoadDFMValues;
  EstateInventoryDatasetcli_IdSeller.BeforeLoadDFMValues;
  EstateInventoryDatasetcli_IdBuyers.BeforeLoadDFMValues;
  EstateInventoryDatasetei_DisplayName.BeforeLoadDFMValues;
  EstateInventoryDatasetei_FullDescription.BeforeLoadDFMValues;
  EstateInventoryDatasetei_InsctiptionDate.BeforeLoadDFMValues;
  EstateInventoryDatasetei_AskedPrice.BeforeLoadDFMValues;
  EstateInventoryDatasetei_ClosedSalePrice.BeforeLoadDFMValues;
  EstateInventoryDatasetei_Notes.BeforeLoadDFMValues;
  EstateInventoryDatasetei_CreatedBy.BeforeLoadDFMValues;
  EstateInventoryDatasetei_CreatedDate.BeforeLoadDFMValues;
  EstateInventoryDatasetei_LastModifiedBy.BeforeLoadDFMValues;
  EstateInventoryDatasetei_LastModifiedDate.BeforeLoadDFMValues;
  XDataClientEstateInventoryGet.BeforeLoadDFMValues;
  XDataClientEstateInventoryDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewEstateInventoryEdit';
    Width := 593;
    Height := 643;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbei_Id.SetParentComponent(Self);
    lbei_Id.Name := 'lbei_Id';
    lbei_Id.Left := 24;
    lbei_Id.Top := 192;
    lbei_Id.Width := 28;
    lbei_Id.Height := 13;
    lbei_Id.Caption := 'ei_Id:';
    lbei_Id.HeightPercent := 100.000000000000000000;
    lbei_Id.WidthPercent := 100.000000000000000000;
    lbcs_Id.SetParentComponent(Self);
    lbcs_Id.Name := 'lbcs_Id';
    lbcs_Id.Left := 24;
    lbcs_Id.Top := 219;
    lbcs_Id.Width := 30;
    lbcs_Id.Height := 13;
    lbcs_Id.Caption := 'cs_Id:';
    lbcs_Id.HeightPercent := 100.000000000000000000;
    lbcs_Id.WidthPercent := 100.000000000000000000;
    lbcli_IdSeller.SetParentComponent(Self);
    lbcli_IdSeller.Name := 'lbcli_IdSeller';
    lbcli_IdSeller.Left := 24;
    lbcli_IdSeller.Top := 246;
    lbcli_IdSeller.Width := 55;
    lbcli_IdSeller.Height := 13;
    lbcli_IdSeller.Caption := 'cli_IdSeller:';
    lbcli_IdSeller.HeightPercent := 100.000000000000000000;
    lbcli_IdSeller.WidthPercent := 100.000000000000000000;
    lbcli_IdBuyers.SetParentComponent(Self);
    lbcli_IdBuyers.Name := 'lbcli_IdBuyers';
    lbcli_IdBuyers.Left := 24;
    lbcli_IdBuyers.Top := 273;
    lbcli_IdBuyers.Width := 62;
    lbcli_IdBuyers.Height := 13;
    lbcli_IdBuyers.Caption := 'cli_IdBuyers:';
    lbcli_IdBuyers.HeightPercent := 100.000000000000000000;
    lbcli_IdBuyers.WidthPercent := 100.000000000000000000;
    lbei_DisplayName.SetParentComponent(Self);
    lbei_DisplayName.Name := 'lbei_DisplayName';
    lbei_DisplayName.Left := 24;
    lbei_DisplayName.Top := 300;
    lbei_DisplayName.Width := 79;
    lbei_DisplayName.Height := 13;
    lbei_DisplayName.Caption := 'ei_DisplayName:';
    lbei_DisplayName.HeightPercent := 100.000000000000000000;
    lbei_DisplayName.WidthPercent := 100.000000000000000000;
    lbei_FullDescription.SetParentComponent(Self);
    lbei_FullDescription.Name := 'lbei_FullDescription';
    lbei_FullDescription.Left := 24;
    lbei_FullDescription.Top := 327;
    lbei_FullDescription.Width := 87;
    lbei_FullDescription.Height := 13;
    lbei_FullDescription.Caption := 'ei_FullDescription:';
    lbei_FullDescription.HeightPercent := 100.000000000000000000;
    lbei_FullDescription.WidthPercent := 100.000000000000000000;
    lbei_InsctiptionDate.SetParentComponent(Self);
    lbei_InsctiptionDate.Name := 'lbei_InsctiptionDate';
    lbei_InsctiptionDate.Left := 24;
    lbei_InsctiptionDate.Top := 354;
    lbei_InsctiptionDate.Width := 91;
    lbei_InsctiptionDate.Height := 13;
    lbei_InsctiptionDate.Caption := 'ei_InsctiptionDate:';
    lbei_InsctiptionDate.HeightPercent := 100.000000000000000000;
    lbei_InsctiptionDate.WidthPercent := 100.000000000000000000;
    lbei_AskedPrice.SetParentComponent(Self);
    lbei_AskedPrice.Name := 'lbei_AskedPrice';
    lbei_AskedPrice.Left := 24;
    lbei_AskedPrice.Top := 381;
    lbei_AskedPrice.Width := 70;
    lbei_AskedPrice.Height := 13;
    lbei_AskedPrice.Caption := 'ei_AskedPrice:';
    lbei_AskedPrice.HeightPercent := 100.000000000000000000;
    lbei_AskedPrice.WidthPercent := 100.000000000000000000;
    lbei_ClosedSalePrice.SetParentComponent(Self);
    lbei_ClosedSalePrice.Name := 'lbei_ClosedSalePrice';
    lbei_ClosedSalePrice.Left := 24;
    lbei_ClosedSalePrice.Top := 408;
    lbei_ClosedSalePrice.Width := 93;
    lbei_ClosedSalePrice.Height := 13;
    lbei_ClosedSalePrice.Caption := 'ei_ClosedSalePrice:';
    lbei_ClosedSalePrice.HeightPercent := 100.000000000000000000;
    lbei_ClosedSalePrice.WidthPercent := 100.000000000000000000;
    lbei_Notes.SetParentComponent(Self);
    lbei_Notes.Name := 'lbei_Notes';
    lbei_Notes.Left := 24;
    lbei_Notes.Top := 435;
    lbei_Notes.Width := 46;
    lbei_Notes.Height := 13;
    lbei_Notes.Caption := 'ei_Notes:';
    lbei_Notes.HeightPercent := 100.000000000000000000;
    lbei_Notes.WidthPercent := 100.000000000000000000;
    lbei_CreatedBy.SetParentComponent(Self);
    lbei_CreatedBy.Name := 'lbei_CreatedBy';
    lbei_CreatedBy.Left := 24;
    lbei_CreatedBy.Top := 462;
    lbei_CreatedBy.Width := 69;
    lbei_CreatedBy.Height := 13;
    lbei_CreatedBy.Caption := 'ei_CreatedBy:';
    lbei_CreatedBy.HeightPercent := 100.000000000000000000;
    lbei_CreatedBy.WidthPercent := 100.000000000000000000;
    lbei_CreatedDate.SetParentComponent(Self);
    lbei_CreatedDate.Name := 'lbei_CreatedDate';
    lbei_CreatedDate.Left := 24;
    lbei_CreatedDate.Top := 489;
    lbei_CreatedDate.Width := 80;
    lbei_CreatedDate.Height := 13;
    lbei_CreatedDate.Caption := 'ei_CreatedDate:';
    lbei_CreatedDate.HeightPercent := 100.000000000000000000;
    lbei_CreatedDate.WidthPercent := 100.000000000000000000;
    lbei_LastModifiedBy.SetParentComponent(Self);
    lbei_LastModifiedBy.Name := 'lbei_LastModifiedBy';
    lbei_LastModifiedBy.Left := 24;
    lbei_LastModifiedBy.Top := 516;
    lbei_LastModifiedBy.Width := 90;
    lbei_LastModifiedBy.Height := 13;
    lbei_LastModifiedBy.Caption := 'ei_LastModifiedBy:';
    lbei_LastModifiedBy.HeightPercent := 100.000000000000000000;
    lbei_LastModifiedBy.WidthPercent := 100.000000000000000000;
    lbei_LastModifiedDate.SetParentComponent(Self);
    lbei_LastModifiedDate.Name := 'lbei_LastModifiedDate';
    lbei_LastModifiedDate.Left := 24;
    lbei_LastModifiedDate.Top := 543;
    lbei_LastModifiedDate.Width := 101;
    lbei_LastModifiedDate.Height := 13;
    lbei_LastModifiedDate.Caption := 'ei_LastModifiedDate:';
    lbei_LastModifiedDate.HeightPercent := 100.000000000000000000;
    lbei_LastModifiedDate.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 583;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 583;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 583;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edei_Id.SetParentComponent(Self);
    edei_Id.Name := 'edei_Id';
    edei_Id.Left := 221;
    edei_Id.Top := 189;
    edei_Id.Width := 200;
    edei_Id.Height := 21;
    edei_Id.HeightPercent := 100.000000000000000000;
    edei_Id.TabOrder := 6;
    edei_Id.Text := 'edei_Id';
    edei_Id.WidthPercent := 100.000000000000000000;
    edei_Id.DataField := 'ei_Id';
    edei_Id.DataSource := DataSource;
    edcs_Id.SetParentComponent(Self);
    edcs_Id.Name := 'edcs_Id';
    edcs_Id.Left := 221;
    edcs_Id.Top := 216;
    edcs_Id.Width := 200;
    edcs_Id.Height := 21;
    edcs_Id.HeightPercent := 100.000000000000000000;
    edcs_Id.TabOrder := 7;
    edcs_Id.Text := 'edcs_Id';
    edcs_Id.WidthPercent := 100.000000000000000000;
    edcs_Id.DataField := 'cs_Id';
    edcs_Id.DataSource := DataSource;
    edcli_IdSeller.SetParentComponent(Self);
    edcli_IdSeller.Name := 'edcli_IdSeller';
    edcli_IdSeller.Left := 221;
    edcli_IdSeller.Top := 243;
    edcli_IdSeller.Width := 200;
    edcli_IdSeller.Height := 21;
    edcli_IdSeller.HeightPercent := 100.000000000000000000;
    edcli_IdSeller.TabOrder := 8;
    edcli_IdSeller.Text := 'edcli_IdSeller';
    edcli_IdSeller.WidthPercent := 100.000000000000000000;
    edcli_IdSeller.DataField := 'cli_IdSeller';
    edcli_IdSeller.DataSource := DataSource;
    edcli_IdBuyers.SetParentComponent(Self);
    edcli_IdBuyers.Name := 'edcli_IdBuyers';
    edcli_IdBuyers.Left := 221;
    edcli_IdBuyers.Top := 270;
    edcli_IdBuyers.Width := 200;
    edcli_IdBuyers.Height := 21;
    edcli_IdBuyers.HeightPercent := 100.000000000000000000;
    edcli_IdBuyers.TabOrder := 9;
    edcli_IdBuyers.Text := 'edcli_IdBuyers';
    edcli_IdBuyers.WidthPercent := 100.000000000000000000;
    edcli_IdBuyers.DataField := 'cli_IdBuyers';
    edcli_IdBuyers.DataSource := DataSource;
    edei_DisplayName.SetParentComponent(Self);
    edei_DisplayName.Name := 'edei_DisplayName';
    edei_DisplayName.Left := 221;
    edei_DisplayName.Top := 297;
    edei_DisplayName.Width := 200;
    edei_DisplayName.Height := 21;
    edei_DisplayName.HeightPercent := 100.000000000000000000;
    edei_DisplayName.TabOrder := 10;
    edei_DisplayName.Text := 'edei_DisplayName';
    edei_DisplayName.WidthPercent := 100.000000000000000000;
    edei_DisplayName.DataField := 'ei_DisplayName';
    edei_DisplayName.DataSource := DataSource;
    edei_FullDescription.SetParentComponent(Self);
    edei_FullDescription.Name := 'edei_FullDescription';
    edei_FullDescription.Left := 221;
    edei_FullDescription.Top := 324;
    edei_FullDescription.Width := 200;
    edei_FullDescription.Height := 21;
    edei_FullDescription.HeightPercent := 100.000000000000000000;
    edei_FullDescription.TabOrder := 11;
    edei_FullDescription.Text := 'edei_FullDescription';
    edei_FullDescription.WidthPercent := 100.000000000000000000;
    edei_FullDescription.DataField := 'ei_FullDescription';
    edei_FullDescription.DataSource := DataSource;
    edei_InsctiptionDate.SetParentComponent(Self);
    edei_InsctiptionDate.Name := 'edei_InsctiptionDate';
    edei_InsctiptionDate.Left := 221;
    edei_InsctiptionDate.Top := 351;
    edei_InsctiptionDate.Width := 200;
    edei_InsctiptionDate.Height := 21;
    edei_InsctiptionDate.HeightPercent := 100.000000000000000000;
    edei_InsctiptionDate.TabOrder := 12;
    edei_InsctiptionDate.Text := 'edei_InsctiptionDate';
    edei_InsctiptionDate.WidthPercent := 100.000000000000000000;
    edei_InsctiptionDate.DataField := 'ei_InsctiptionDate';
    edei_InsctiptionDate.DataSource := DataSource;
    edei_AskedPrice.SetParentComponent(Self);
    edei_AskedPrice.Name := 'edei_AskedPrice';
    edei_AskedPrice.Left := 221;
    edei_AskedPrice.Top := 378;
    edei_AskedPrice.Width := 200;
    edei_AskedPrice.Height := 21;
    edei_AskedPrice.HeightPercent := 100.000000000000000000;
    edei_AskedPrice.TabOrder := 13;
    edei_AskedPrice.Text := 'edei_AskedPrice';
    edei_AskedPrice.WidthPercent := 100.000000000000000000;
    edei_AskedPrice.DataField := 'ei_AskedPrice';
    edei_AskedPrice.DataSource := DataSource;
    edei_ClosedSalePrice.SetParentComponent(Self);
    edei_ClosedSalePrice.Name := 'edei_ClosedSalePrice';
    edei_ClosedSalePrice.Left := 221;
    edei_ClosedSalePrice.Top := 405;
    edei_ClosedSalePrice.Width := 200;
    edei_ClosedSalePrice.Height := 21;
    edei_ClosedSalePrice.HeightPercent := 100.000000000000000000;
    edei_ClosedSalePrice.TabOrder := 14;
    edei_ClosedSalePrice.Text := 'edei_ClosedSalePrice';
    edei_ClosedSalePrice.WidthPercent := 100.000000000000000000;
    edei_ClosedSalePrice.DataField := 'ei_ClosedSalePrice';
    edei_ClosedSalePrice.DataSource := DataSource;
    edei_Notes.SetParentComponent(Self);
    edei_Notes.Name := 'edei_Notes';
    edei_Notes.Left := 221;
    edei_Notes.Top := 432;
    edei_Notes.Width := 200;
    edei_Notes.Height := 21;
    edei_Notes.HeightPercent := 100.000000000000000000;
    edei_Notes.TabOrder := 15;
    edei_Notes.Text := 'edei_Notes';
    edei_Notes.WidthPercent := 100.000000000000000000;
    edei_Notes.DataField := 'ei_Notes';
    edei_Notes.DataSource := DataSource;
    edei_CreatedBy.SetParentComponent(Self);
    edei_CreatedBy.Name := 'edei_CreatedBy';
    edei_CreatedBy.Left := 221;
    edei_CreatedBy.Top := 459;
    edei_CreatedBy.Width := 200;
    edei_CreatedBy.Height := 21;
    edei_CreatedBy.HeightPercent := 100.000000000000000000;
    edei_CreatedBy.TabOrder := 16;
    edei_CreatedBy.Text := 'edei_CreatedBy';
    edei_CreatedBy.WidthPercent := 100.000000000000000000;
    edei_CreatedBy.DataField := 'ei_CreatedBy';
    edei_CreatedBy.DataSource := DataSource;
    edei_CreatedDate.SetParentComponent(Self);
    edei_CreatedDate.Name := 'edei_CreatedDate';
    edei_CreatedDate.Left := 221;
    edei_CreatedDate.Top := 486;
    edei_CreatedDate.Width := 200;
    edei_CreatedDate.Height := 21;
    edei_CreatedDate.HeightPercent := 100.000000000000000000;
    edei_CreatedDate.TabOrder := 17;
    edei_CreatedDate.Text := 'edei_CreatedDate';
    edei_CreatedDate.WidthPercent := 100.000000000000000000;
    edei_CreatedDate.DataField := 'ei_CreatedDate';
    edei_CreatedDate.DataSource := DataSource;
    edei_LastModifiedBy.SetParentComponent(Self);
    edei_LastModifiedBy.Name := 'edei_LastModifiedBy';
    edei_LastModifiedBy.Left := 221;
    edei_LastModifiedBy.Top := 513;
    edei_LastModifiedBy.Width := 200;
    edei_LastModifiedBy.Height := 21;
    edei_LastModifiedBy.HeightPercent := 100.000000000000000000;
    edei_LastModifiedBy.TabOrder := 18;
    edei_LastModifiedBy.Text := 'edei_LastModifiedBy';
    edei_LastModifiedBy.WidthPercent := 100.000000000000000000;
    edei_LastModifiedBy.DataField := 'ei_LastModifiedBy';
    edei_LastModifiedBy.DataSource := DataSource;
    edei_LastModifiedDate.SetParentComponent(Self);
    edei_LastModifiedDate.Name := 'edei_LastModifiedDate';
    edei_LastModifiedDate.Left := 221;
    edei_LastModifiedDate.Top := 540;
    edei_LastModifiedDate.Width := 200;
    edei_LastModifiedDate.Height := 21;
    edei_LastModifiedDate.HeightPercent := 100.000000000000000000;
    edei_LastModifiedDate.TabOrder := 19;
    edei_LastModifiedDate.Text := 'edei_LastModifiedDate';
    edei_LastModifiedDate.WidthPercent := 100.000000000000000000;
    edei_LastModifiedDate.DataField := 'ei_LastModifiedDate';
    edei_LastModifiedDate.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := EstateInventoryDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    EstateInventoryDataset.SetParentComponent(Self);
    EstateInventoryDataset.Name := 'EstateInventoryDataset';
    EstateInventoryDataset.AfterApplyUpdates := EstateInventoryDatasetAfterApplyUpdates;
    EstateInventoryDataset.EntitySetName := 'EstateInventory';
    EstateInventoryDataset.Connection := DMConnection.ApiConnection;
    EstateInventoryDataset.Left := 176;
    EstateInventoryDataset.Top := 8;
    EstateInventoryDatasetei_Id.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_Id.Name := 'EstateInventoryDatasetei_Id';
    EstateInventoryDatasetei_Id.FieldName := 'ei_Id';
    EstateInventoryDatasetei_Id.Required := True;
    EstateInventoryDatasetcs_Id.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetcs_Id.Name := 'EstateInventoryDatasetcs_Id';
    EstateInventoryDatasetcs_Id.FieldName := 'cs_Id';
    EstateInventoryDatasetcli_IdSeller.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetcli_IdSeller.Name := 'EstateInventoryDatasetcli_IdSeller';
    EstateInventoryDatasetcli_IdSeller.FieldName := 'cli_IdSeller';
    EstateInventoryDatasetcli_IdBuyers.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetcli_IdBuyers.Name := 'EstateInventoryDatasetcli_IdBuyers';
    EstateInventoryDatasetcli_IdBuyers.FieldName := 'cli_IdBuyers';
    EstateInventoryDatasetei_DisplayName.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_DisplayName.Name := 'EstateInventoryDatasetei_DisplayName';
    EstateInventoryDatasetei_DisplayName.FieldName := 'ei_DisplayName';
    EstateInventoryDatasetei_DisplayName.Size := 75;
    EstateInventoryDatasetei_FullDescription.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_FullDescription.Name := 'EstateInventoryDatasetei_FullDescription';
    EstateInventoryDatasetei_FullDescription.FieldName := 'ei_FullDescription';
    EstateInventoryDatasetei_FullDescription.Size := 150;
    EstateInventoryDatasetei_InsctiptionDate.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_InsctiptionDate.Name := 'EstateInventoryDatasetei_InsctiptionDate';
    EstateInventoryDatasetei_InsctiptionDate.FieldName := 'ei_InsctiptionDate';
    EstateInventoryDatasetei_AskedPrice.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_AskedPrice.Name := 'EstateInventoryDatasetei_AskedPrice';
    EstateInventoryDatasetei_AskedPrice.FieldName := 'ei_AskedPrice';
    EstateInventoryDatasetei_ClosedSalePrice.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_ClosedSalePrice.Name := 'EstateInventoryDatasetei_ClosedSalePrice';
    EstateInventoryDatasetei_ClosedSalePrice.FieldName := 'ei_ClosedSalePrice';
    EstateInventoryDatasetei_Notes.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_Notes.Name := 'EstateInventoryDatasetei_Notes';
    EstateInventoryDatasetei_Notes.FieldName := 'ei_Notes';
    EstateInventoryDatasetei_Notes.Size := 250;
    EstateInventoryDatasetei_CreatedBy.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_CreatedBy.Name := 'EstateInventoryDatasetei_CreatedBy';
    EstateInventoryDatasetei_CreatedBy.FieldName := 'ei_CreatedBy';
    EstateInventoryDatasetei_CreatedDate.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_CreatedDate.Name := 'EstateInventoryDatasetei_CreatedDate';
    EstateInventoryDatasetei_CreatedDate.FieldName := 'ei_CreatedDate';
    EstateInventoryDatasetei_LastModifiedBy.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_LastModifiedBy.Name := 'EstateInventoryDatasetei_LastModifiedBy';
    EstateInventoryDatasetei_LastModifiedBy.FieldName := 'ei_LastModifiedBy';
    EstateInventoryDatasetei_LastModifiedDate.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_LastModifiedDate.Name := 'EstateInventoryDatasetei_LastModifiedDate';
    EstateInventoryDatasetei_LastModifiedDate.FieldName := 'ei_LastModifiedDate';
    XDataClientEstateInventoryGet.SetParentComponent(Self);
    XDataClientEstateInventoryGet.Name := 'XDataClientEstateInventoryGet';
    XDataClientEstateInventoryGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientEstateInventoryGet, Self, 'OnLoad', 'XDataClientEstateInventoryGetLoad');
    XDataClientEstateInventoryGet.Left := 64;
    XDataClientEstateInventoryGet.Top := 8;
    XDataClientEstateInventoryDelete.SetParentComponent(Self);
    XDataClientEstateInventoryDelete.Name := 'XDataClientEstateInventoryDelete';
    XDataClientEstateInventoryDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientEstateInventoryDelete, Self, 'OnLoad', 'XDataClientEstateInventoryDeleteLoad');
    SetEvent(XDataClientEstateInventoryDelete, Self, 'OnError', 'XDataClientEstateInventoryDeleteError');
    XDataClientEstateInventoryDelete.Left := 64;
    XDataClientEstateInventoryDelete.Top := 56;
  finally
    lbei_Id.AfterLoadDFMValues;
    lbcs_Id.AfterLoadDFMValues;
    lbcli_IdSeller.AfterLoadDFMValues;
    lbcli_IdBuyers.AfterLoadDFMValues;
    lbei_DisplayName.AfterLoadDFMValues;
    lbei_FullDescription.AfterLoadDFMValues;
    lbei_InsctiptionDate.AfterLoadDFMValues;
    lbei_AskedPrice.AfterLoadDFMValues;
    lbei_ClosedSalePrice.AfterLoadDFMValues;
    lbei_Notes.AfterLoadDFMValues;
    lbei_CreatedBy.AfterLoadDFMValues;
    lbei_CreatedDate.AfterLoadDFMValues;
    lbei_LastModifiedBy.AfterLoadDFMValues;
    lbei_LastModifiedDate.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edei_Id.AfterLoadDFMValues;
    edcs_Id.AfterLoadDFMValues;
    edcli_IdSeller.AfterLoadDFMValues;
    edcli_IdBuyers.AfterLoadDFMValues;
    edei_DisplayName.AfterLoadDFMValues;
    edei_FullDescription.AfterLoadDFMValues;
    edei_InsctiptionDate.AfterLoadDFMValues;
    edei_AskedPrice.AfterLoadDFMValues;
    edei_ClosedSalePrice.AfterLoadDFMValues;
    edei_Notes.AfterLoadDFMValues;
    edei_CreatedBy.AfterLoadDFMValues;
    edei_CreatedDate.AfterLoadDFMValues;
    edei_LastModifiedBy.AfterLoadDFMValues;
    edei_LastModifiedDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    EstateInventoryDataset.AfterLoadDFMValues;
    EstateInventoryDatasetei_Id.AfterLoadDFMValues;
    EstateInventoryDatasetcs_Id.AfterLoadDFMValues;
    EstateInventoryDatasetcli_IdSeller.AfterLoadDFMValues;
    EstateInventoryDatasetcli_IdBuyers.AfterLoadDFMValues;
    EstateInventoryDatasetei_DisplayName.AfterLoadDFMValues;
    EstateInventoryDatasetei_FullDescription.AfterLoadDFMValues;
    EstateInventoryDatasetei_InsctiptionDate.AfterLoadDFMValues;
    EstateInventoryDatasetei_AskedPrice.AfterLoadDFMValues;
    EstateInventoryDatasetei_ClosedSalePrice.AfterLoadDFMValues;
    EstateInventoryDatasetei_Notes.AfterLoadDFMValues;
    EstateInventoryDatasetei_CreatedBy.AfterLoadDFMValues;
    EstateInventoryDatasetei_CreatedDate.AfterLoadDFMValues;
    EstateInventoryDatasetei_LastModifiedBy.AfterLoadDFMValues;
    EstateInventoryDatasetei_LastModifiedDate.AfterLoadDFMValues;
    XDataClientEstateInventoryGet.AfterLoadDFMValues;
    XDataClientEstateInventoryDelete.AfterLoadDFMValues;
  end;
end;

end.
