unit View.EstateInventory.List;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, Math,

  WEBLib.StdCtrls, Web, JS, WEBLib.Lists,

  App.Types, WEBLib.Controls, WEBLib.Grids, Data.DB,
  XData.Web.Dataset, WEBLib.DBCtrls, WEBLib.DB, WEBLib.ExtCtrls,

  XData.Web.JsonDataset,

  Grid.Plugins,
  Crud.Utils;

type
  TFViewEstateInventoryList = class(TForm)
    btNew: TButton;
    EstateInventoryDataset: TXDataWebDataSet;
    EstateInventoryDatasetei_Id: TIntegerField;
    EstateInventoryDatasetcs_Id: TIntegerField;
    EstateInventoryDatasetcli_IdSeller: TIntegerField;
    EstateInventoryDatasetcli_IdBuyers: TIntegerField;
    EstateInventoryDatasetei_DisplayName: TStringField;
    EstateInventoryDatasetei_FullDescription: TStringField;
    EstateInventoryDatasetei_InsctiptionDate: TIntegerField;
    EstateInventoryDatasetei_AskedPrice: TIntegerField;
    EstateInventoryDatasetei_ClosedSalePrice: TIntegerField;
    EstateInventoryDatasetei_Notes: TStringField;
    EstateInventoryDatasetei_CreatedBy: TIntegerField;
    EstateInventoryDatasetei_CreatedDate: TDateTimeField;
    EstateInventoryDatasetei_LastModifiedBy: TIntegerField;
    EstateInventoryDatasetei_LastModifiedDate: TDateTimeField;
    WebDBTableControl1: TDBTableControl;
    WebDataSource: TDataSource;
    cbPageSize: TComboBox;
    edSearch: TEdit;
    lbPaginationInfo: TLabel;
    lcPaginator: TListControl;
    procedure WebFormCreate(Sender: TObject);
    procedure btNewClick(Sender: TObject);
    procedure WebDBTableControl1GetCellChildren(Sender: TObject; ACol,
      ARow: Integer; AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure cbPageSizeChange(Sender: TObject);
    procedure edSearchChange(Sender: TObject);
  private
    FEditProc: TEditProc;
    FGridPlugin: TGridPlugin;
  public
    class function CreateForm(AElementID: string; AShowEditProc: TEditProc): TForm;
  protected procedure LoadDFMValues; override; end;

var
  FViewEstateInventoryList: TFViewEstateInventoryList;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewEstateInventoryList }

procedure TFViewEstateInventoryList.cbPageSizeChange(Sender: TObject);
begin
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text), True);
end;

class function TFViewEstateInventoryList.CreateForm(AElementID: string;
  AShowEditProc: TEditProc): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewEstateInventoryList(AForm).FEditProc := AShowEditProc;
  end;

begin
  Application.CreateForm(TFViewEstateInventoryList, AElementID, Result, @AfterCreate);
end;

procedure TFViewEstateInventoryList.edSearchChange(Sender: TObject);
begin
  FGridPlugin.SetFilterText(edSearch.Text, True);
end;

procedure TFViewEstateInventoryList.btNewClick(Sender: TObject);
begin
  FEditProc( nil );
end;

procedure TFViewEstateInventoryList.WebDBTableControl1GetCellChildren(Sender: TObject;
  ACol, ARow: Integer; AField: TField; AValue: string;
  AElement: TJSHTMLElementRecord);
var
  RecordId: JSValue;

  procedure EditButtonClick(Sender: TObject);
  begin
    FEditProc(RecordId);
  end;

var
  Button: TButton;
begin
  if ARow = 0 then
    Exit;

  if WebDBTableControl1.Columns[ACol].Title = 'Actions' then
  begin
    RecordId := TCrudUtils.GetIdFromObject(
      EstateInventoryDataset.Connection.Model.DefaultSchema.FindEntityType(EstateInventoryDataset.EntitySetName),
      EstateInventoryDataset.CurrentData
    );
    Button := TButton.Create(nil);
    Button.Caption := 'Edit';
    Button.OnClick := @EditButtonClick;
    Button.ElementClassName := 'btn btn-outline btn-default btn-xs';
    Button.ParentElement := AElement.element;
    Button.WidthStyle := TSizeStyle.ssAuto;
    Button.HeightStyle := TSizeStyle.ssAuto;
  end;
end;

procedure TFViewEstateInventoryList.WebFormCreate(Sender: TObject);
begin
  FGridPlugin := TGridPlugin.Create(
    WebDBTableControl1,
    EstateInventoryDataset,
    lcPaginator,
    lbPaginationInfo
  );
  FGridPlugin.SetPageSize(StrToInt(cbPageSize.Text));
  FGridPlugin.SetFilterText(edSearch.Text);
  FGridPlugin.Load;
end;

procedure TFViewEstateInventoryList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbPaginationInfo := TLabel.Create('view.estateinventory.list.pagination.info');
  btNew := TButton.Create('view.estateinventory.list.newbutton');
  WebDBTableControl1 := TDBTableControl.Create('view.estateinventory.list.table');
  cbPageSize := TComboBox.Create('view.estateinventory.list.cbPageSize');
  edSearch := TEdit.Create('view.estateinventory.list.search');
  lcPaginator := TListControl.Create('view.estateinventory.list.pagination');
  EstateInventoryDataset := TXDataWebDataSet.Create(Self);
  EstateInventoryDatasetei_Id := TIntegerField.Create(Self);
  EstateInventoryDatasetcs_Id := TIntegerField.Create(Self);
  EstateInventoryDatasetcli_IdSeller := TIntegerField.Create(Self);
  EstateInventoryDatasetcli_IdBuyers := TIntegerField.Create(Self);
  EstateInventoryDatasetei_DisplayName := TStringField.Create(Self);
  EstateInventoryDatasetei_FullDescription := TStringField.Create(Self);
  EstateInventoryDatasetei_InsctiptionDate := TIntegerField.Create(Self);
  EstateInventoryDatasetei_AskedPrice := TIntegerField.Create(Self);
  EstateInventoryDatasetei_ClosedSalePrice := TIntegerField.Create(Self);
  EstateInventoryDatasetei_Notes := TStringField.Create(Self);
  EstateInventoryDatasetei_CreatedBy := TIntegerField.Create(Self);
  EstateInventoryDatasetei_CreatedDate := TDateTimeField.Create(Self);
  EstateInventoryDatasetei_LastModifiedBy := TIntegerField.Create(Self);
  EstateInventoryDatasetei_LastModifiedDate := TDateTimeField.Create(Self);
  WebDataSource := TDataSource.Create(Self);

  lbPaginationInfo.BeforeLoadDFMValues;
  btNew.BeforeLoadDFMValues;
  WebDBTableControl1.BeforeLoadDFMValues;
  cbPageSize.BeforeLoadDFMValues;
  edSearch.BeforeLoadDFMValues;
  lcPaginator.BeforeLoadDFMValues;
  EstateInventoryDataset.BeforeLoadDFMValues;
  EstateInventoryDatasetei_Id.BeforeLoadDFMValues;
  EstateInventoryDatasetcs_Id.BeforeLoadDFMValues;
  EstateInventoryDatasetcli_IdSeller.BeforeLoadDFMValues;
  EstateInventoryDatasetcli_IdBuyers.BeforeLoadDFMValues;
  EstateInventoryDatasetei_DisplayName.BeforeLoadDFMValues;
  EstateInventoryDatasetei_FullDescription.BeforeLoadDFMValues;
  EstateInventoryDatasetei_InsctiptionDate.BeforeLoadDFMValues;
  EstateInventoryDatasetei_AskedPrice.BeforeLoadDFMValues;
  EstateInventoryDatasetei_ClosedSalePrice.BeforeLoadDFMValues;
  EstateInventoryDatasetei_Notes.BeforeLoadDFMValues;
  EstateInventoryDatasetei_CreatedBy.BeforeLoadDFMValues;
  EstateInventoryDatasetei_CreatedDate.BeforeLoadDFMValues;
  EstateInventoryDatasetei_LastModifiedBy.BeforeLoadDFMValues;
  EstateInventoryDatasetei_LastModifiedDate.BeforeLoadDFMValues;
  WebDataSource.BeforeLoadDFMValues;
  try
    Name := 'FViewEstateInventoryList';
    Width := 695;
    Height := 500;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbPaginationInfo.SetParentComponent(Self);
    lbPaginationInfo.Name := 'lbPaginationInfo';
    lbPaginationInfo.Left := 8;
    lbPaginationInfo.Top := 376;
    lbPaginationInfo.Width := 141;
    lbPaginationInfo.Height := 13;
    lbPaginationInfo.Caption := 'Showing 1 to 10 of 57 entries';
    lbPaginationInfo.HeightPercent := 100.000000000000000000;
    lbPaginationInfo.WidthPercent := 100.000000000000000000;
    btNew.SetParentComponent(Self);
    btNew.Name := 'btNew';
    btNew.Left := 8;
    btNew.Top := 8;
    btNew.Width := 50;
    btNew.Height := 25;
    btNew.Caption := 'New';
    btNew.HeightPercent := 100.000000000000000000;
    btNew.WidthPercent := 100.000000000000000000;
    SetEvent(btNew, Self, 'OnClick', 'btNewClick');
    WebDBTableControl1.SetParentComponent(Self);
    WebDBTableControl1.Name := 'WebDBTableControl1';
    WebDBTableControl1.Left := 8;
    WebDBTableControl1.Top := 94;
    WebDBTableControl1.Width := 521;
    WebDBTableControl1.Height := 265;
    WebDBTableControl1.ElementClassName := 'table-responsive';
    WebDBTableControl1.HeightPercent := 100.000000000000000000;
    WebDBTableControl1.WidthPercent := 100.000000000000000000;
    WebDBTableControl1.BorderColor := clSilver;
    WebDBTableControl1.ColHeader := False;
    WebDBTableControl1.ElementTableClassName := 'table table-striped table-bordered table-hover dataTable';
    SetEvent(WebDBTableControl1, Self, 'OnGetCellChildren', 'WebDBTableControl1GetCellChildren');
    WebDBTableControl1.Columns.Clear;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'ei_Id';
      Title := 'ei_Id';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cs_Id';
      Title := 'cs_Id';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_IdSeller';
      Title := 'cli_IdSeller';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'cli_IdBuyers';
      Title := 'cli_IdBuyers';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'ei_DisplayName';
      Title := 'ei_DisplayName';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'ei_FullDescription';
      Title := 'ei_FullDescription';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'ei_InsctiptionDate';
      Title := 'ei_InsctiptionDate';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'ei_AskedPrice';
      Title := 'ei_AskedPrice';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'ei_ClosedSalePrice';
      Title := 'ei_ClosedSalePrice';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'ei_Notes';
      Title := 'ei_Notes';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'ei_CreatedBy';
      Title := 'ei_CreatedBy';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'ei_CreatedDate';
      Title := 'ei_CreatedDate';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'ei_LastModifiedBy';
      Title := 'ei_LastModifiedBy';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      DataField := 'ei_LastModifiedDate';
      Title := 'ei_LastModifiedDate';
    end;
    with WebDBTableControl1.Columns.Add do
    begin
      Title := 'Actions';
    end;
    WebDBTableControl1.DataSource := WebDataSource;
    cbPageSize.SetParentComponent(Self);
    cbPageSize.Name := 'cbPageSize';
    cbPageSize.Left := 8;
    cbPageSize.Top := 67;
    cbPageSize.Width := 81;
    cbPageSize.Height := 21;
    cbPageSize.HeightPercent := 100.000000000000000000;
    cbPageSize.TabOrder := 2;
    cbPageSize.Text := '10';
    cbPageSize.WidthPercent := 100.000000000000000000;
    SetEvent(cbPageSize, Self, 'OnChange', 'cbPageSizeChange');
    cbPageSize.ItemIndex := 0;
    cbPageSize.Items.BeginUpdate;
    try
      cbPageSize.Items.Clear;
      cbPageSize.Items.Add('10');
      cbPageSize.Items.Add('25');
      cbPageSize.Items.Add('50');
      cbPageSize.Items.Add('100');
    finally
      cbPageSize.Items.EndUpdate;
    end;
    edSearch.SetParentComponent(Self);
    edSearch.Name := 'edSearch';
    edSearch.Left := 408;
    edSearch.Top := 67;
    edSearch.Width := 121;
    edSearch.Height := 21;
    edSearch.HeightPercent := 100.000000000000000000;
    edSearch.TabOrder := 3;
    edSearch.WidthPercent := 100.000000000000000000;
    SetEvent(edSearch, Self, 'OnChange', 'edSearchChange');
    lcPaginator.SetParentComponent(Self);
    lcPaginator.Name := 'lcPaginator';
    lcPaginator.Left := 240;
    lcPaginator.Top := 365;
    lcPaginator.Width := 289;
    lcPaginator.Height := 25;
    lcPaginator.HeightPercent := 100.000000000000000000;
    lcPaginator.WidthPercent := 100.000000000000000000;
    lcPaginator.DefaultItemClassName := 'page-item';
    lcPaginator.DefaultItemLinkClassName := 'page-link';
    lcPaginator.ElementListClassName := 'pagination';
    lcPaginator.Style := lsPagination;
    EstateInventoryDataset.SetParentComponent(Self);
    EstateInventoryDataset.Name := 'EstateInventoryDataset';
    EstateInventoryDataset.EntitySetName := 'EstateInventory';
    EstateInventoryDataset.Connection := DMConnection.ApiConnection;
    EstateInventoryDataset.Left := 72;
    EstateInventoryDataset.Top := 144;
    EstateInventoryDatasetei_Id.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_Id.Name := 'EstateInventoryDatasetei_Id';
    EstateInventoryDatasetei_Id.FieldName := 'ei_Id';
    EstateInventoryDatasetei_Id.Required := True;
    EstateInventoryDatasetcs_Id.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetcs_Id.Name := 'EstateInventoryDatasetcs_Id';
    EstateInventoryDatasetcs_Id.FieldName := 'cs_Id';
    EstateInventoryDatasetcli_IdSeller.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetcli_IdSeller.Name := 'EstateInventoryDatasetcli_IdSeller';
    EstateInventoryDatasetcli_IdSeller.FieldName := 'cli_IdSeller';
    EstateInventoryDatasetcli_IdBuyers.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetcli_IdBuyers.Name := 'EstateInventoryDatasetcli_IdBuyers';
    EstateInventoryDatasetcli_IdBuyers.FieldName := 'cli_IdBuyers';
    EstateInventoryDatasetei_DisplayName.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_DisplayName.Name := 'EstateInventoryDatasetei_DisplayName';
    EstateInventoryDatasetei_DisplayName.FieldName := 'ei_DisplayName';
    EstateInventoryDatasetei_DisplayName.Size := 75;
    EstateInventoryDatasetei_FullDescription.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_FullDescription.Name := 'EstateInventoryDatasetei_FullDescription';
    EstateInventoryDatasetei_FullDescription.FieldName := 'ei_FullDescription';
    EstateInventoryDatasetei_FullDescription.Size := 150;
    EstateInventoryDatasetei_InsctiptionDate.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_InsctiptionDate.Name := 'EstateInventoryDatasetei_InsctiptionDate';
    EstateInventoryDatasetei_InsctiptionDate.FieldName := 'ei_InsctiptionDate';
    EstateInventoryDatasetei_AskedPrice.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_AskedPrice.Name := 'EstateInventoryDatasetei_AskedPrice';
    EstateInventoryDatasetei_AskedPrice.FieldName := 'ei_AskedPrice';
    EstateInventoryDatasetei_ClosedSalePrice.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_ClosedSalePrice.Name := 'EstateInventoryDatasetei_ClosedSalePrice';
    EstateInventoryDatasetei_ClosedSalePrice.FieldName := 'ei_ClosedSalePrice';
    EstateInventoryDatasetei_Notes.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_Notes.Name := 'EstateInventoryDatasetei_Notes';
    EstateInventoryDatasetei_Notes.FieldName := 'ei_Notes';
    EstateInventoryDatasetei_Notes.Size := 250;
    EstateInventoryDatasetei_CreatedBy.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_CreatedBy.Name := 'EstateInventoryDatasetei_CreatedBy';
    EstateInventoryDatasetei_CreatedBy.FieldName := 'ei_CreatedBy';
    EstateInventoryDatasetei_CreatedDate.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_CreatedDate.Name := 'EstateInventoryDatasetei_CreatedDate';
    EstateInventoryDatasetei_CreatedDate.FieldName := 'ei_CreatedDate';
    EstateInventoryDatasetei_LastModifiedBy.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_LastModifiedBy.Name := 'EstateInventoryDatasetei_LastModifiedBy';
    EstateInventoryDatasetei_LastModifiedBy.FieldName := 'ei_LastModifiedBy';
    EstateInventoryDatasetei_LastModifiedDate.SetParentComponent(EstateInventoryDataset);
    EstateInventoryDatasetei_LastModifiedDate.Name := 'EstateInventoryDatasetei_LastModifiedDate';
    EstateInventoryDatasetei_LastModifiedDate.FieldName := 'ei_LastModifiedDate';
    WebDataSource.SetParentComponent(Self);
    WebDataSource.Name := 'WebDataSource';
    WebDataSource.DataSet := EstateInventoryDataset;
    WebDataSource.Left := 72;
    WebDataSource.Top := 200;
  finally
    lbPaginationInfo.AfterLoadDFMValues;
    btNew.AfterLoadDFMValues;
    WebDBTableControl1.AfterLoadDFMValues;
    cbPageSize.AfterLoadDFMValues;
    edSearch.AfterLoadDFMValues;
    lcPaginator.AfterLoadDFMValues;
    EstateInventoryDataset.AfterLoadDFMValues;
    EstateInventoryDatasetei_Id.AfterLoadDFMValues;
    EstateInventoryDatasetcs_Id.AfterLoadDFMValues;
    EstateInventoryDatasetcli_IdSeller.AfterLoadDFMValues;
    EstateInventoryDatasetcli_IdBuyers.AfterLoadDFMValues;
    EstateInventoryDatasetei_DisplayName.AfterLoadDFMValues;
    EstateInventoryDatasetei_FullDescription.AfterLoadDFMValues;
    EstateInventoryDatasetei_InsctiptionDate.AfterLoadDFMValues;
    EstateInventoryDatasetei_AskedPrice.AfterLoadDFMValues;
    EstateInventoryDatasetei_ClosedSalePrice.AfterLoadDFMValues;
    EstateInventoryDatasetei_Notes.AfterLoadDFMValues;
    EstateInventoryDatasetei_CreatedBy.AfterLoadDFMValues;
    EstateInventoryDatasetei_CreatedDate.AfterLoadDFMValues;
    EstateInventoryDatasetei_LastModifiedBy.AfterLoadDFMValues;
    EstateInventoryDatasetei_LastModifiedDate.AfterLoadDFMValues;
    WebDataSource.AfterLoadDFMValues;
  end;
end;

end.
