unit View.RentalAsset.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewRentalAssetEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbra_Id: TLabel;
    edra_Id: TDBEdit;
    lbcat_Id: TLabel;
    edcat_Id: TDBEdit;
    lbra_Number: TLabel;
    edra_Number: TDBEdit;
    lbra_DisplayName: TLabel;
    edra_DisplayName: TDBEdit;
    lbra_Description: TLabel;
    edra_Description: TDBEdit;
    lbra_Price: TLabel;
    edra_Price: TDBEdit;
    lbra_IsInServicing: TLabel;
    edra_IsInServicing: TDBEdit;
    lbra_CreatedBy: TLabel;
    edra_CreatedBy: TDBEdit;
    lbra_CreatedDate: TLabel;
    edra_CreatedDate: TDBEdit;
    lbra_LastModifiedBy: TLabel;
    edra_LastModifiedBy: TDBEdit;
    lbra_LastModifiedDate: TLabel;
    edra_LastModifiedDate: TDBEdit;
    XDataClientRentalAssetGet: TXDataWebClient;
    XDataClientRentalAssetDelete: TXDataWebClient;
    RentalAssetDataset: TXDataWebDataSet;
    RentalAssetDatasetra_Id: TIntegerField;
    RentalAssetDatasetcat_Id: TIntegerField;
    RentalAssetDatasetra_Number: TIntegerField;
    RentalAssetDatasetra_DisplayName: TStringField;
    RentalAssetDatasetra_Description: TStringField;
    RentalAssetDatasetra_Price: TFloatField;
    RentalAssetDatasetra_IsInServicing: TStringField;
    RentalAssetDatasetra_CreatedBy: TIntegerField;
    RentalAssetDatasetra_CreatedDate: TDateTimeField;
    RentalAssetDatasetra_LastModifiedBy: TIntegerField;
    RentalAssetDatasetra_LastModifiedDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientRentalAssetGetLoad(Response: TXDataClientResponse);
    procedure XDataClientRentalAssetDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientRentalAssetDeleteError(Error: TXDataClientError);
    procedure RentalAssetDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewRentalAssetEdit: TFViewRentalAssetEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewRentalAssetEdit }

procedure TFViewRentalAssetEdit.btSaveClick(Sender: TObject);
begin
  if RentalAssetDataset.State in dsEditModes then
  begin
        RentalAssetDataset.Post;
    RentalAssetDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewRentalAssetEdit.btDeleteClick(Sender: TObject);
begin
  if RentalAssetDataset.State in dsEditModes then
    RentalAssetDataset.Post;
  XDataClientRentalAssetDelete.Delete(RentalAssetDataset.EntitySetName,
    TJSObject(RentalAssetDataset.CurrentData));
end;


class function TFViewRentalAssetEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewRentalAssetEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewRentalAssetEdit.InsertEntity;
begin
  RentalAssetDataset.Close;
  RentalAssetDataset.SetJsonData('{}');
  RentalAssetDataset.Open;
  RentalAssetDataset.Insert;
end;

class function TFViewRentalAssetEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewRentalAssetEdit(AForm).FInserting := AInserting;
    TFViewRentalAssetEdit(AForm).FId := AId;
    TFViewRentalAssetEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewRentalAssetEdit(AForm).InsertEntity
    else
      TFViewRentalAssetEdit(AForm).LoadEntity;
    TFViewRentalAssetEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewRentalAssetEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewRentalAssetEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    RentalAssetDataset.Connection.Model.DefaultSchema.FindEntityType(
      RentalAssetDataset.EntitySetName
    )
  );
  XDataClientRentalAssetGet.Get(RentalAssetDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewRentalAssetEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewRentalAssetEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewRentalAssetEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewRentalAssetEdit.XDataClientRentalAssetGetLoad(Response: TXDataClientResponse);
begin
  RentalAssetDataset.Close;
  RentalAssetDataset.SetJsonData(Response.Result);
  RentalAssetDataset.Open;
  
  RentalAssetDataset.Edit;  
end;

procedure TFViewRentalAssetEdit.XDataClientRentalAssetDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewRentalAssetEdit.XDataClientRentalAssetDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewRentalAssetEdit.RentalAssetDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewRentalAssetEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewRentalAssetEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbra_Id := TLabel.Create('view.rentalasset.form.lbra_Id');
  lbcat_Id := TLabel.Create('view.rentalasset.form.lbcat_Id');
  lbra_Number := TLabel.Create('view.rentalasset.form.lbra_Number');
  lbra_DisplayName := TLabel.Create('view.rentalasset.form.lbra_DisplayName');
  lbra_Description := TLabel.Create('view.rentalasset.form.lbra_Description');
  lbra_Price := TLabel.Create('view.rentalasset.form.lbra_Price');
  lbra_IsInServicing := TLabel.Create('view.rentalasset.form.lbra_IsInServicing');
  lbra_CreatedBy := TLabel.Create('view.rentalasset.form.lbra_CreatedBy');
  lbra_CreatedDate := TLabel.Create('view.rentalasset.form.lbra_CreatedDate');
  lbra_LastModifiedBy := TLabel.Create('view.rentalasset.form.lbra_LastModifiedBy');
  lbra_LastModifiedDate := TLabel.Create('view.rentalasset.form.lbra_LastModifiedDate');
  pnlMessage := TPanel.Create('view.rentalasset.form.message');
  lbMessage := TLabel.Create('view.rentalasset.form.message.label');
  btCloseMessage := TButton.Create('view.rentalasset.form.message.button');
  btCancel := TButton.Create('view.rentalasset.form.btBack');
  btSave := TButton.Create('view.rentalasset.form.btSave');
  btDelete := TButton.Create('view.rentalasset.form.btDelete');
  edra_Id := TDBEdit.Create('view.rentalasset.form.edra_Id');
  edcat_Id := TDBEdit.Create('view.rentalasset.form.edcat_Id');
  edra_Number := TDBEdit.Create('view.rentalasset.form.edra_Number');
  edra_DisplayName := TDBEdit.Create('view.rentalasset.form.edra_DisplayName');
  edra_Description := TDBEdit.Create('view.rentalasset.form.edra_Description');
  edra_Price := TDBEdit.Create('view.rentalasset.form.edra_Price');
  edra_IsInServicing := TDBEdit.Create('view.rentalasset.form.edra_IsInServicing');
  edra_CreatedBy := TDBEdit.Create('view.rentalasset.form.edra_CreatedBy');
  edra_CreatedDate := TDBEdit.Create('view.rentalasset.form.edra_CreatedDate');
  edra_LastModifiedBy := TDBEdit.Create('view.rentalasset.form.edra_LastModifiedBy');
  edra_LastModifiedDate := TDBEdit.Create('view.rentalasset.form.edra_LastModifiedDate');
  DataSource := TDataSource.Create(Self);
  RentalAssetDataset := TXDataWebDataSet.Create(Self);
  RentalAssetDatasetra_Id := TIntegerField.Create(Self);
  RentalAssetDatasetcat_Id := TIntegerField.Create(Self);
  RentalAssetDatasetra_Number := TIntegerField.Create(Self);
  RentalAssetDatasetra_DisplayName := TStringField.Create(Self);
  RentalAssetDatasetra_Description := TStringField.Create(Self);
  RentalAssetDatasetra_Price := TFloatField.Create(Self);
  RentalAssetDatasetra_IsInServicing := TStringField.Create(Self);
  RentalAssetDatasetra_CreatedBy := TIntegerField.Create(Self);
  RentalAssetDatasetra_CreatedDate := TDateTimeField.Create(Self);
  RentalAssetDatasetra_LastModifiedBy := TIntegerField.Create(Self);
  RentalAssetDatasetra_LastModifiedDate := TDateTimeField.Create(Self);
  XDataClientRentalAssetGet := TXDataWebClient.Create(Self);
  XDataClientRentalAssetDelete := TXDataWebClient.Create(Self);

  lbra_Id.BeforeLoadDFMValues;
  lbcat_Id.BeforeLoadDFMValues;
  lbra_Number.BeforeLoadDFMValues;
  lbra_DisplayName.BeforeLoadDFMValues;
  lbra_Description.BeforeLoadDFMValues;
  lbra_Price.BeforeLoadDFMValues;
  lbra_IsInServicing.BeforeLoadDFMValues;
  lbra_CreatedBy.BeforeLoadDFMValues;
  lbra_CreatedDate.BeforeLoadDFMValues;
  lbra_LastModifiedBy.BeforeLoadDFMValues;
  lbra_LastModifiedDate.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edra_Id.BeforeLoadDFMValues;
  edcat_Id.BeforeLoadDFMValues;
  edra_Number.BeforeLoadDFMValues;
  edra_DisplayName.BeforeLoadDFMValues;
  edra_Description.BeforeLoadDFMValues;
  edra_Price.BeforeLoadDFMValues;
  edra_IsInServicing.BeforeLoadDFMValues;
  edra_CreatedBy.BeforeLoadDFMValues;
  edra_CreatedDate.BeforeLoadDFMValues;
  edra_LastModifiedBy.BeforeLoadDFMValues;
  edra_LastModifiedDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  RentalAssetDataset.BeforeLoadDFMValues;
  RentalAssetDatasetra_Id.BeforeLoadDFMValues;
  RentalAssetDatasetcat_Id.BeforeLoadDFMValues;
  RentalAssetDatasetra_Number.BeforeLoadDFMValues;
  RentalAssetDatasetra_DisplayName.BeforeLoadDFMValues;
  RentalAssetDatasetra_Description.BeforeLoadDFMValues;
  RentalAssetDatasetra_Price.BeforeLoadDFMValues;
  RentalAssetDatasetra_IsInServicing.BeforeLoadDFMValues;
  RentalAssetDatasetra_CreatedBy.BeforeLoadDFMValues;
  RentalAssetDatasetra_CreatedDate.BeforeLoadDFMValues;
  RentalAssetDatasetra_LastModifiedBy.BeforeLoadDFMValues;
  RentalAssetDatasetra_LastModifiedDate.BeforeLoadDFMValues;
  XDataClientRentalAssetGet.BeforeLoadDFMValues;
  XDataClientRentalAssetDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewRentalAssetEdit';
    Width := 593;
    Height := 562;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbra_Id.SetParentComponent(Self);
    lbra_Id.Name := 'lbra_Id';
    lbra_Id.Left := 24;
    lbra_Id.Top := 192;
    lbra_Id.Width := 30;
    lbra_Id.Height := 13;
    lbra_Id.Caption := 'ra_Id:';
    lbra_Id.HeightPercent := 100.000000000000000000;
    lbra_Id.WidthPercent := 100.000000000000000000;
    lbcat_Id.SetParentComponent(Self);
    lbcat_Id.Name := 'lbcat_Id';
    lbcat_Id.Left := 24;
    lbcat_Id.Top := 219;
    lbcat_Id.Width := 35;
    lbcat_Id.Height := 13;
    lbcat_Id.Caption := 'cat_Id:';
    lbcat_Id.HeightPercent := 100.000000000000000000;
    lbcat_Id.WidthPercent := 100.000000000000000000;
    lbra_Number.SetParentComponent(Self);
    lbra_Number.Name := 'lbra_Number';
    lbra_Number.Left := 24;
    lbra_Number.Top := 246;
    lbra_Number.Width := 57;
    lbra_Number.Height := 13;
    lbra_Number.Caption := 'ra_Number:';
    lbra_Number.HeightPercent := 100.000000000000000000;
    lbra_Number.WidthPercent := 100.000000000000000000;
    lbra_DisplayName.SetParentComponent(Self);
    lbra_DisplayName.Name := 'lbra_DisplayName';
    lbra_DisplayName.Left := 24;
    lbra_DisplayName.Top := 273;
    lbra_DisplayName.Width := 81;
    lbra_DisplayName.Height := 13;
    lbra_DisplayName.Caption := 'ra_DisplayName:';
    lbra_DisplayName.HeightPercent := 100.000000000000000000;
    lbra_DisplayName.WidthPercent := 100.000000000000000000;
    lbra_Description.SetParentComponent(Self);
    lbra_Description.Name := 'lbra_Description';
    lbra_Description.Left := 24;
    lbra_Description.Top := 300;
    lbra_Description.Width := 73;
    lbra_Description.Height := 13;
    lbra_Description.Caption := 'ra_Description:';
    lbra_Description.HeightPercent := 100.000000000000000000;
    lbra_Description.WidthPercent := 100.000000000000000000;
    lbra_Price.SetParentComponent(Self);
    lbra_Price.Name := 'lbra_Price';
    lbra_Price.Left := 24;
    lbra_Price.Top := 327;
    lbra_Price.Width := 43;
    lbra_Price.Height := 13;
    lbra_Price.Caption := 'ra_Price:';
    lbra_Price.HeightPercent := 100.000000000000000000;
    lbra_Price.WidthPercent := 100.000000000000000000;
    lbra_IsInServicing.SetParentComponent(Self);
    lbra_IsInServicing.Name := 'lbra_IsInServicing';
    lbra_IsInServicing.Left := 24;
    lbra_IsInServicing.Top := 354;
    lbra_IsInServicing.Width := 82;
    lbra_IsInServicing.Height := 13;
    lbra_IsInServicing.Caption := 'ra_IsInServicing:';
    lbra_IsInServicing.HeightPercent := 100.000000000000000000;
    lbra_IsInServicing.WidthPercent := 100.000000000000000000;
    lbra_CreatedBy.SetParentComponent(Self);
    lbra_CreatedBy.Name := 'lbra_CreatedBy';
    lbra_CreatedBy.Left := 24;
    lbra_CreatedBy.Top := 381;
    lbra_CreatedBy.Width := 71;
    lbra_CreatedBy.Height := 13;
    lbra_CreatedBy.Caption := 'ra_CreatedBy:';
    lbra_CreatedBy.HeightPercent := 100.000000000000000000;
    lbra_CreatedBy.WidthPercent := 100.000000000000000000;
    lbra_CreatedDate.SetParentComponent(Self);
    lbra_CreatedDate.Name := 'lbra_CreatedDate';
    lbra_CreatedDate.Left := 24;
    lbra_CreatedDate.Top := 408;
    lbra_CreatedDate.Width := 82;
    lbra_CreatedDate.Height := 13;
    lbra_CreatedDate.Caption := 'ra_CreatedDate:';
    lbra_CreatedDate.HeightPercent := 100.000000000000000000;
    lbra_CreatedDate.WidthPercent := 100.000000000000000000;
    lbra_LastModifiedBy.SetParentComponent(Self);
    lbra_LastModifiedBy.Name := 'lbra_LastModifiedBy';
    lbra_LastModifiedBy.Left := 24;
    lbra_LastModifiedBy.Top := 435;
    lbra_LastModifiedBy.Width := 92;
    lbra_LastModifiedBy.Height := 13;
    lbra_LastModifiedBy.Caption := 'ra_LastModifiedBy:';
    lbra_LastModifiedBy.HeightPercent := 100.000000000000000000;
    lbra_LastModifiedBy.WidthPercent := 100.000000000000000000;
    lbra_LastModifiedDate.SetParentComponent(Self);
    lbra_LastModifiedDate.Name := 'lbra_LastModifiedDate';
    lbra_LastModifiedDate.Left := 24;
    lbra_LastModifiedDate.Top := 462;
    lbra_LastModifiedDate.Width := 103;
    lbra_LastModifiedDate.Height := 13;
    lbra_LastModifiedDate.Caption := 'ra_LastModifiedDate:';
    lbra_LastModifiedDate.HeightPercent := 100.000000000000000000;
    lbra_LastModifiedDate.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 502;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 502;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 502;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edra_Id.SetParentComponent(Self);
    edra_Id.Name := 'edra_Id';
    edra_Id.Left := 221;
    edra_Id.Top := 189;
    edra_Id.Width := 200;
    edra_Id.Height := 21;
    edra_Id.HeightPercent := 100.000000000000000000;
    edra_Id.TabOrder := 6;
    edra_Id.Text := 'edra_Id';
    edra_Id.WidthPercent := 100.000000000000000000;
    edra_Id.DataField := 'ra_Id';
    edra_Id.DataSource := DataSource;
    edcat_Id.SetParentComponent(Self);
    edcat_Id.Name := 'edcat_Id';
    edcat_Id.Left := 221;
    edcat_Id.Top := 216;
    edcat_Id.Width := 200;
    edcat_Id.Height := 21;
    edcat_Id.HeightPercent := 100.000000000000000000;
    edcat_Id.TabOrder := 7;
    edcat_Id.Text := 'edcat_Id';
    edcat_Id.WidthPercent := 100.000000000000000000;
    edcat_Id.DataField := 'cat_Id';
    edcat_Id.DataSource := DataSource;
    edra_Number.SetParentComponent(Self);
    edra_Number.Name := 'edra_Number';
    edra_Number.Left := 221;
    edra_Number.Top := 243;
    edra_Number.Width := 200;
    edra_Number.Height := 21;
    edra_Number.HeightPercent := 100.000000000000000000;
    edra_Number.TabOrder := 8;
    edra_Number.Text := 'edra_Number';
    edra_Number.WidthPercent := 100.000000000000000000;
    edra_Number.DataField := 'ra_Number';
    edra_Number.DataSource := DataSource;
    edra_DisplayName.SetParentComponent(Self);
    edra_DisplayName.Name := 'edra_DisplayName';
    edra_DisplayName.Left := 221;
    edra_DisplayName.Top := 270;
    edra_DisplayName.Width := 200;
    edra_DisplayName.Height := 21;
    edra_DisplayName.HeightPercent := 100.000000000000000000;
    edra_DisplayName.TabOrder := 9;
    edra_DisplayName.Text := 'edra_DisplayName';
    edra_DisplayName.WidthPercent := 100.000000000000000000;
    edra_DisplayName.DataField := 'ra_DisplayName';
    edra_DisplayName.DataSource := DataSource;
    edra_Description.SetParentComponent(Self);
    edra_Description.Name := 'edra_Description';
    edra_Description.Left := 221;
    edra_Description.Top := 297;
    edra_Description.Width := 200;
    edra_Description.Height := 21;
    edra_Description.HeightPercent := 100.000000000000000000;
    edra_Description.TabOrder := 10;
    edra_Description.Text := 'edra_Description';
    edra_Description.WidthPercent := 100.000000000000000000;
    edra_Description.DataField := 'ra_Description';
    edra_Description.DataSource := DataSource;
    edra_Price.SetParentComponent(Self);
    edra_Price.Name := 'edra_Price';
    edra_Price.Left := 221;
    edra_Price.Top := 324;
    edra_Price.Width := 200;
    edra_Price.Height := 21;
    edra_Price.HeightPercent := 100.000000000000000000;
    edra_Price.TabOrder := 11;
    edra_Price.Text := 'edra_Price';
    edra_Price.WidthPercent := 100.000000000000000000;
    edra_Price.DataField := 'ra_Price';
    edra_Price.DataSource := DataSource;
    edra_IsInServicing.SetParentComponent(Self);
    edra_IsInServicing.Name := 'edra_IsInServicing';
    edra_IsInServicing.Left := 221;
    edra_IsInServicing.Top := 351;
    edra_IsInServicing.Width := 200;
    edra_IsInServicing.Height := 21;
    edra_IsInServicing.HeightPercent := 100.000000000000000000;
    edra_IsInServicing.TabOrder := 12;
    edra_IsInServicing.Text := 'edra_IsInServicing';
    edra_IsInServicing.WidthPercent := 100.000000000000000000;
    edra_IsInServicing.DataField := 'ra_IsInServicing';
    edra_IsInServicing.DataSource := DataSource;
    edra_CreatedBy.SetParentComponent(Self);
    edra_CreatedBy.Name := 'edra_CreatedBy';
    edra_CreatedBy.Left := 221;
    edra_CreatedBy.Top := 378;
    edra_CreatedBy.Width := 200;
    edra_CreatedBy.Height := 21;
    edra_CreatedBy.HeightPercent := 100.000000000000000000;
    edra_CreatedBy.TabOrder := 13;
    edra_CreatedBy.Text := 'edra_CreatedBy';
    edra_CreatedBy.WidthPercent := 100.000000000000000000;
    edra_CreatedBy.DataField := 'ra_CreatedBy';
    edra_CreatedBy.DataSource := DataSource;
    edra_CreatedDate.SetParentComponent(Self);
    edra_CreatedDate.Name := 'edra_CreatedDate';
    edra_CreatedDate.Left := 221;
    edra_CreatedDate.Top := 405;
    edra_CreatedDate.Width := 200;
    edra_CreatedDate.Height := 21;
    edra_CreatedDate.HeightPercent := 100.000000000000000000;
    edra_CreatedDate.TabOrder := 14;
    edra_CreatedDate.Text := 'edra_CreatedDate';
    edra_CreatedDate.WidthPercent := 100.000000000000000000;
    edra_CreatedDate.DataField := 'ra_CreatedDate';
    edra_CreatedDate.DataSource := DataSource;
    edra_LastModifiedBy.SetParentComponent(Self);
    edra_LastModifiedBy.Name := 'edra_LastModifiedBy';
    edra_LastModifiedBy.Left := 221;
    edra_LastModifiedBy.Top := 432;
    edra_LastModifiedBy.Width := 200;
    edra_LastModifiedBy.Height := 21;
    edra_LastModifiedBy.HeightPercent := 100.000000000000000000;
    edra_LastModifiedBy.TabOrder := 15;
    edra_LastModifiedBy.Text := 'edra_LastModifiedBy';
    edra_LastModifiedBy.WidthPercent := 100.000000000000000000;
    edra_LastModifiedBy.DataField := 'ra_LastModifiedBy';
    edra_LastModifiedBy.DataSource := DataSource;
    edra_LastModifiedDate.SetParentComponent(Self);
    edra_LastModifiedDate.Name := 'edra_LastModifiedDate';
    edra_LastModifiedDate.Left := 221;
    edra_LastModifiedDate.Top := 459;
    edra_LastModifiedDate.Width := 200;
    edra_LastModifiedDate.Height := 21;
    edra_LastModifiedDate.HeightPercent := 100.000000000000000000;
    edra_LastModifiedDate.TabOrder := 16;
    edra_LastModifiedDate.Text := 'edra_LastModifiedDate';
    edra_LastModifiedDate.WidthPercent := 100.000000000000000000;
    edra_LastModifiedDate.DataField := 'ra_LastModifiedDate';
    edra_LastModifiedDate.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := RentalAssetDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    RentalAssetDataset.SetParentComponent(Self);
    RentalAssetDataset.Name := 'RentalAssetDataset';
    RentalAssetDataset.AfterApplyUpdates := RentalAssetDatasetAfterApplyUpdates;
    RentalAssetDataset.EntitySetName := 'RentalAsset';
    RentalAssetDataset.Connection := DMConnection.ApiConnection;
    RentalAssetDataset.Left := 176;
    RentalAssetDataset.Top := 8;
    RentalAssetDatasetra_Id.SetParentComponent(RentalAssetDataset);
    RentalAssetDatasetra_Id.Name := 'RentalAssetDatasetra_Id';
    RentalAssetDatasetra_Id.FieldName := 'ra_Id';
    RentalAssetDatasetra_Id.Required := True;
    RentalAssetDatasetcat_Id.SetParentComponent(RentalAssetDataset);
    RentalAssetDatasetcat_Id.Name := 'RentalAssetDatasetcat_Id';
    RentalAssetDatasetcat_Id.FieldName := 'cat_Id';
    RentalAssetDatasetra_Number.SetParentComponent(RentalAssetDataset);
    RentalAssetDatasetra_Number.Name := 'RentalAssetDatasetra_Number';
    RentalAssetDatasetra_Number.FieldName := 'ra_Number';
    RentalAssetDatasetra_DisplayName.SetParentComponent(RentalAssetDataset);
    RentalAssetDatasetra_DisplayName.Name := 'RentalAssetDatasetra_DisplayName';
    RentalAssetDatasetra_DisplayName.FieldName := 'ra_DisplayName';
    RentalAssetDatasetra_DisplayName.Size := 50;
    RentalAssetDatasetra_Description.SetParentComponent(RentalAssetDataset);
    RentalAssetDatasetra_Description.Name := 'RentalAssetDatasetra_Description';
    RentalAssetDatasetra_Description.FieldName := 'ra_Description';
    RentalAssetDatasetra_Description.Size := 150;
    RentalAssetDatasetra_Price.SetParentComponent(RentalAssetDataset);
    RentalAssetDatasetra_Price.Name := 'RentalAssetDatasetra_Price';
    RentalAssetDatasetra_Price.FieldName := 'ra_Price';
    RentalAssetDatasetra_IsInServicing.SetParentComponent(RentalAssetDataset);
    RentalAssetDatasetra_IsInServicing.Name := 'RentalAssetDatasetra_IsInServicing';
    RentalAssetDatasetra_IsInServicing.FieldName := 'ra_IsInServicing';
    RentalAssetDatasetra_IsInServicing.Size := 255;
    RentalAssetDatasetra_CreatedBy.SetParentComponent(RentalAssetDataset);
    RentalAssetDatasetra_CreatedBy.Name := 'RentalAssetDatasetra_CreatedBy';
    RentalAssetDatasetra_CreatedBy.FieldName := 'ra_CreatedBy';
    RentalAssetDatasetra_CreatedDate.SetParentComponent(RentalAssetDataset);
    RentalAssetDatasetra_CreatedDate.Name := 'RentalAssetDatasetra_CreatedDate';
    RentalAssetDatasetra_CreatedDate.FieldName := 'ra_CreatedDate';
    RentalAssetDatasetra_LastModifiedBy.SetParentComponent(RentalAssetDataset);
    RentalAssetDatasetra_LastModifiedBy.Name := 'RentalAssetDatasetra_LastModifiedBy';
    RentalAssetDatasetra_LastModifiedBy.FieldName := 'ra_LastModifiedBy';
    RentalAssetDatasetra_LastModifiedDate.SetParentComponent(RentalAssetDataset);
    RentalAssetDatasetra_LastModifiedDate.Name := 'RentalAssetDatasetra_LastModifiedDate';
    RentalAssetDatasetra_LastModifiedDate.FieldName := 'ra_LastModifiedDate';
    XDataClientRentalAssetGet.SetParentComponent(Self);
    XDataClientRentalAssetGet.Name := 'XDataClientRentalAssetGet';
    XDataClientRentalAssetGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientRentalAssetGet, Self, 'OnLoad', 'XDataClientRentalAssetGetLoad');
    XDataClientRentalAssetGet.Left := 64;
    XDataClientRentalAssetGet.Top := 8;
    XDataClientRentalAssetDelete.SetParentComponent(Self);
    XDataClientRentalAssetDelete.Name := 'XDataClientRentalAssetDelete';
    XDataClientRentalAssetDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientRentalAssetDelete, Self, 'OnLoad', 'XDataClientRentalAssetDeleteLoad');
    SetEvent(XDataClientRentalAssetDelete, Self, 'OnError', 'XDataClientRentalAssetDeleteError');
    XDataClientRentalAssetDelete.Left := 64;
    XDataClientRentalAssetDelete.Top := 56;
  finally
    lbra_Id.AfterLoadDFMValues;
    lbcat_Id.AfterLoadDFMValues;
    lbra_Number.AfterLoadDFMValues;
    lbra_DisplayName.AfterLoadDFMValues;
    lbra_Description.AfterLoadDFMValues;
    lbra_Price.AfterLoadDFMValues;
    lbra_IsInServicing.AfterLoadDFMValues;
    lbra_CreatedBy.AfterLoadDFMValues;
    lbra_CreatedDate.AfterLoadDFMValues;
    lbra_LastModifiedBy.AfterLoadDFMValues;
    lbra_LastModifiedDate.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edra_Id.AfterLoadDFMValues;
    edcat_Id.AfterLoadDFMValues;
    edra_Number.AfterLoadDFMValues;
    edra_DisplayName.AfterLoadDFMValues;
    edra_Description.AfterLoadDFMValues;
    edra_Price.AfterLoadDFMValues;
    edra_IsInServicing.AfterLoadDFMValues;
    edra_CreatedBy.AfterLoadDFMValues;
    edra_CreatedDate.AfterLoadDFMValues;
    edra_LastModifiedBy.AfterLoadDFMValues;
    edra_LastModifiedDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    RentalAssetDataset.AfterLoadDFMValues;
    RentalAssetDatasetra_Id.AfterLoadDFMValues;
    RentalAssetDatasetcat_Id.AfterLoadDFMValues;
    RentalAssetDatasetra_Number.AfterLoadDFMValues;
    RentalAssetDatasetra_DisplayName.AfterLoadDFMValues;
    RentalAssetDatasetra_Description.AfterLoadDFMValues;
    RentalAssetDatasetra_Price.AfterLoadDFMValues;
    RentalAssetDatasetra_IsInServicing.AfterLoadDFMValues;
    RentalAssetDatasetra_CreatedBy.AfterLoadDFMValues;
    RentalAssetDatasetra_CreatedDate.AfterLoadDFMValues;
    RentalAssetDatasetra_LastModifiedBy.AfterLoadDFMValues;
    RentalAssetDatasetra_LastModifiedDate.AfterLoadDFMValues;
    XDataClientRentalAssetGet.AfterLoadDFMValues;
    XDataClientRentalAssetDelete.AfterLoadDFMValues;
  end;
end;

end.
