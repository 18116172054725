unit View.Payments.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewPaymentsEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbpay_Id: TLabel;
    edpay_Id: TDBEdit;
    lbemp_Id: TLabel;
    edemp_Id: TDBEdit;
    lbcli_Id: TLabel;
    edcli_Id: TDBEdit;
    lbinv_Id: TLabel;
    edinv_Id: TDBEdit;
    lbpay_Date: TLabel;
    edpay_Date: TDBEdit;
    lbpay_Amount: TLabel;
    edpay_Amount: TDBEdit;
    lbpay_Cash: TLabel;
    edpay_Cash: TDBEdit;
    lbpay_Chq: TLabel;
    edpay_Chq: TDBEdit;
    lbpay_Dt: TLabel;
    edpay_Dt: TDBEdit;
    lbpay_Ct: TLabel;
    edpay_Ct: TDBEdit;
    lbpay_Online: TLabel;
    edpay_Online: TDBEdit;
    lbpay_Tranfer: TLabel;
    edpay_Tranfer: TDBEdit;
    lbpay_CreatedBy: TLabel;
    edpay_CreatedBy: TDBEdit;
    lbpay_CreatedDate: TLabel;
    edpay_CreatedDate: TDBEdit;
    lbpay_LastModifiedBy: TLabel;
    edpay_LastModifiedBy: TDBEdit;
    lbpay_LastModifiedDate: TLabel;
    edpay_LastModifiedDate: TDBEdit;
    XDataClientPaymentsGet: TXDataWebClient;
    XDataClientPaymentsDelete: TXDataWebClient;
    PaymentsDataset: TXDataWebDataSet;
    PaymentsDatasetpay_Id: TIntegerField;
    PaymentsDatasetemp_Id: TIntegerField;
    PaymentsDatasetcli_Id: TIntegerField;
    PaymentsDatasetinv_Id: TIntegerField;
    PaymentsDatasetpay_Date: TDateTimeField;
    PaymentsDatasetpay_Amount: TFloatField;
    PaymentsDatasetpay_Cash: TFloatField;
    PaymentsDatasetpay_Chq: TFloatField;
    PaymentsDatasetpay_Dt: TFloatField;
    PaymentsDatasetpay_Ct: TFloatField;
    PaymentsDatasetpay_Online: TFloatField;
    PaymentsDatasetpay_Tranfer: TFloatField;
    PaymentsDatasetpay_CreatedBy: TIntegerField;
    PaymentsDatasetpay_CreatedDate: TDateTimeField;
    PaymentsDatasetpay_LastModifiedBy: TIntegerField;
    PaymentsDatasetpay_LastModifiedDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientPaymentsGetLoad(Response: TXDataClientResponse);
    procedure XDataClientPaymentsDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientPaymentsDeleteError(Error: TXDataClientError);
    procedure PaymentsDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewPaymentsEdit: TFViewPaymentsEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewPaymentsEdit }

procedure TFViewPaymentsEdit.btSaveClick(Sender: TObject);
begin
  if PaymentsDataset.State in dsEditModes then
  begin
        PaymentsDataset.Post;
    PaymentsDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewPaymentsEdit.btDeleteClick(Sender: TObject);
begin
  if PaymentsDataset.State in dsEditModes then
    PaymentsDataset.Post;
  XDataClientPaymentsDelete.Delete(PaymentsDataset.EntitySetName,
    TJSObject(PaymentsDataset.CurrentData));
end;


class function TFViewPaymentsEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewPaymentsEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewPaymentsEdit.InsertEntity;
begin
  PaymentsDataset.Close;
  PaymentsDataset.SetJsonData('{}');
  PaymentsDataset.Open;
  PaymentsDataset.Insert;
end;

class function TFViewPaymentsEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewPaymentsEdit(AForm).FInserting := AInserting;
    TFViewPaymentsEdit(AForm).FId := AId;
    TFViewPaymentsEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewPaymentsEdit(AForm).InsertEntity
    else
      TFViewPaymentsEdit(AForm).LoadEntity;
    TFViewPaymentsEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewPaymentsEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewPaymentsEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    PaymentsDataset.Connection.Model.DefaultSchema.FindEntityType(
      PaymentsDataset.EntitySetName
    )
  );
  XDataClientPaymentsGet.Get(PaymentsDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewPaymentsEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewPaymentsEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewPaymentsEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewPaymentsEdit.XDataClientPaymentsGetLoad(Response: TXDataClientResponse);
begin
  PaymentsDataset.Close;
  PaymentsDataset.SetJsonData(Response.Result);
  PaymentsDataset.Open;
  
  PaymentsDataset.Edit;  
end;

procedure TFViewPaymentsEdit.XDataClientPaymentsDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewPaymentsEdit.XDataClientPaymentsDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewPaymentsEdit.PaymentsDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewPaymentsEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewPaymentsEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbpay_Id := TLabel.Create('view.payments.form.lbpay_Id');
  lbemp_Id := TLabel.Create('view.payments.form.lbemp_Id');
  lbcli_Id := TLabel.Create('view.payments.form.lbcli_Id');
  lbinv_Id := TLabel.Create('view.payments.form.lbinv_Id');
  lbpay_Date := TLabel.Create('view.payments.form.lbpay_Date');
  lbpay_Amount := TLabel.Create('view.payments.form.lbpay_Amount');
  lbpay_Cash := TLabel.Create('view.payments.form.lbpay_Cash');
  lbpay_Chq := TLabel.Create('view.payments.form.lbpay_Chq');
  lbpay_Dt := TLabel.Create('view.payments.form.lbpay_Dt');
  lbpay_Ct := TLabel.Create('view.payments.form.lbpay_Ct');
  lbpay_Online := TLabel.Create('view.payments.form.lbpay_Online');
  lbpay_Tranfer := TLabel.Create('view.payments.form.lbpay_Tranfer');
  lbpay_CreatedBy := TLabel.Create('view.payments.form.lbpay_CreatedBy');
  lbpay_CreatedDate := TLabel.Create('view.payments.form.lbpay_CreatedDate');
  lbpay_LastModifiedBy := TLabel.Create('view.payments.form.lbpay_LastModifiedBy');
  lbpay_LastModifiedDate := TLabel.Create('view.payments.form.lbpay_LastModifiedDate');
  pnlMessage := TPanel.Create('view.payments.form.message');
  lbMessage := TLabel.Create('view.payments.form.message.label');
  btCloseMessage := TButton.Create('view.payments.form.message.button');
  btCancel := TButton.Create('view.payments.form.btBack');
  btSave := TButton.Create('view.payments.form.btSave');
  btDelete := TButton.Create('view.payments.form.btDelete');
  edpay_Id := TDBEdit.Create('view.payments.form.edpay_Id');
  edemp_Id := TDBEdit.Create('view.payments.form.edemp_Id');
  edcli_Id := TDBEdit.Create('view.payments.form.edcli_Id');
  edinv_Id := TDBEdit.Create('view.payments.form.edinv_Id');
  edpay_Date := TDBEdit.Create('view.payments.form.edpay_Date');
  edpay_Amount := TDBEdit.Create('view.payments.form.edpay_Amount');
  edpay_Cash := TDBEdit.Create('view.payments.form.edpay_Cash');
  edpay_Chq := TDBEdit.Create('view.payments.form.edpay_Chq');
  edpay_Dt := TDBEdit.Create('view.payments.form.edpay_Dt');
  edpay_Ct := TDBEdit.Create('view.payments.form.edpay_Ct');
  edpay_Online := TDBEdit.Create('view.payments.form.edpay_Online');
  edpay_Tranfer := TDBEdit.Create('view.payments.form.edpay_Tranfer');
  edpay_CreatedBy := TDBEdit.Create('view.payments.form.edpay_CreatedBy');
  edpay_CreatedDate := TDBEdit.Create('view.payments.form.edpay_CreatedDate');
  edpay_LastModifiedBy := TDBEdit.Create('view.payments.form.edpay_LastModifiedBy');
  edpay_LastModifiedDate := TDBEdit.Create('view.payments.form.edpay_LastModifiedDate');
  DataSource := TDataSource.Create(Self);
  PaymentsDataset := TXDataWebDataSet.Create(Self);
  PaymentsDatasetpay_Id := TIntegerField.Create(Self);
  PaymentsDatasetemp_Id := TIntegerField.Create(Self);
  PaymentsDatasetcli_Id := TIntegerField.Create(Self);
  PaymentsDatasetinv_Id := TIntegerField.Create(Self);
  PaymentsDatasetpay_Date := TDateTimeField.Create(Self);
  PaymentsDatasetpay_Amount := TFloatField.Create(Self);
  PaymentsDatasetpay_Cash := TFloatField.Create(Self);
  PaymentsDatasetpay_Chq := TFloatField.Create(Self);
  PaymentsDatasetpay_Dt := TFloatField.Create(Self);
  PaymentsDatasetpay_Ct := TFloatField.Create(Self);
  PaymentsDatasetpay_Online := TFloatField.Create(Self);
  PaymentsDatasetpay_Tranfer := TFloatField.Create(Self);
  PaymentsDatasetpay_CreatedBy := TIntegerField.Create(Self);
  PaymentsDatasetpay_CreatedDate := TDateTimeField.Create(Self);
  PaymentsDatasetpay_LastModifiedBy := TIntegerField.Create(Self);
  PaymentsDatasetpay_LastModifiedDate := TDateTimeField.Create(Self);
  XDataClientPaymentsGet := TXDataWebClient.Create(Self);
  XDataClientPaymentsDelete := TXDataWebClient.Create(Self);

  lbpay_Id.BeforeLoadDFMValues;
  lbemp_Id.BeforeLoadDFMValues;
  lbcli_Id.BeforeLoadDFMValues;
  lbinv_Id.BeforeLoadDFMValues;
  lbpay_Date.BeforeLoadDFMValues;
  lbpay_Amount.BeforeLoadDFMValues;
  lbpay_Cash.BeforeLoadDFMValues;
  lbpay_Chq.BeforeLoadDFMValues;
  lbpay_Dt.BeforeLoadDFMValues;
  lbpay_Ct.BeforeLoadDFMValues;
  lbpay_Online.BeforeLoadDFMValues;
  lbpay_Tranfer.BeforeLoadDFMValues;
  lbpay_CreatedBy.BeforeLoadDFMValues;
  lbpay_CreatedDate.BeforeLoadDFMValues;
  lbpay_LastModifiedBy.BeforeLoadDFMValues;
  lbpay_LastModifiedDate.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edpay_Id.BeforeLoadDFMValues;
  edemp_Id.BeforeLoadDFMValues;
  edcli_Id.BeforeLoadDFMValues;
  edinv_Id.BeforeLoadDFMValues;
  edpay_Date.BeforeLoadDFMValues;
  edpay_Amount.BeforeLoadDFMValues;
  edpay_Cash.BeforeLoadDFMValues;
  edpay_Chq.BeforeLoadDFMValues;
  edpay_Dt.BeforeLoadDFMValues;
  edpay_Ct.BeforeLoadDFMValues;
  edpay_Online.BeforeLoadDFMValues;
  edpay_Tranfer.BeforeLoadDFMValues;
  edpay_CreatedBy.BeforeLoadDFMValues;
  edpay_CreatedDate.BeforeLoadDFMValues;
  edpay_LastModifiedBy.BeforeLoadDFMValues;
  edpay_LastModifiedDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  PaymentsDataset.BeforeLoadDFMValues;
  PaymentsDatasetpay_Id.BeforeLoadDFMValues;
  PaymentsDatasetemp_Id.BeforeLoadDFMValues;
  PaymentsDatasetcli_Id.BeforeLoadDFMValues;
  PaymentsDatasetinv_Id.BeforeLoadDFMValues;
  PaymentsDatasetpay_Date.BeforeLoadDFMValues;
  PaymentsDatasetpay_Amount.BeforeLoadDFMValues;
  PaymentsDatasetpay_Cash.BeforeLoadDFMValues;
  PaymentsDatasetpay_Chq.BeforeLoadDFMValues;
  PaymentsDatasetpay_Dt.BeforeLoadDFMValues;
  PaymentsDatasetpay_Ct.BeforeLoadDFMValues;
  PaymentsDatasetpay_Online.BeforeLoadDFMValues;
  PaymentsDatasetpay_Tranfer.BeforeLoadDFMValues;
  PaymentsDatasetpay_CreatedBy.BeforeLoadDFMValues;
  PaymentsDatasetpay_CreatedDate.BeforeLoadDFMValues;
  PaymentsDatasetpay_LastModifiedBy.BeforeLoadDFMValues;
  PaymentsDatasetpay_LastModifiedDate.BeforeLoadDFMValues;
  XDataClientPaymentsGet.BeforeLoadDFMValues;
  XDataClientPaymentsDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewPaymentsEdit';
    Width := 593;
    Height := 697;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbpay_Id.SetParentComponent(Self);
    lbpay_Id.Name := 'lbpay_Id';
    lbpay_Id.Left := 24;
    lbpay_Id.Top := 192;
    lbpay_Id.Width := 38;
    lbpay_Id.Height := 13;
    lbpay_Id.Caption := 'pay_Id:';
    lbpay_Id.HeightPercent := 100.000000000000000000;
    lbpay_Id.WidthPercent := 100.000000000000000000;
    lbemp_Id.SetParentComponent(Self);
    lbemp_Id.Name := 'lbemp_Id';
    lbemp_Id.Left := 24;
    lbemp_Id.Top := 219;
    lbemp_Id.Width := 40;
    lbemp_Id.Height := 13;
    lbemp_Id.Caption := 'emp_Id:';
    lbemp_Id.HeightPercent := 100.000000000000000000;
    lbemp_Id.WidthPercent := 100.000000000000000000;
    lbcli_Id.SetParentComponent(Self);
    lbcli_Id.Name := 'lbcli_Id';
    lbcli_Id.Left := 24;
    lbcli_Id.Top := 246;
    lbcli_Id.Width := 29;
    lbcli_Id.Height := 13;
    lbcli_Id.Caption := 'cli_Id:';
    lbcli_Id.HeightPercent := 100.000000000000000000;
    lbcli_Id.WidthPercent := 100.000000000000000000;
    lbinv_Id.SetParentComponent(Self);
    lbinv_Id.Name := 'lbinv_Id';
    lbinv_Id.Left := 24;
    lbinv_Id.Top := 273;
    lbinv_Id.Width := 34;
    lbinv_Id.Height := 13;
    lbinv_Id.Caption := 'inv_Id:';
    lbinv_Id.HeightPercent := 100.000000000000000000;
    lbinv_Id.WidthPercent := 100.000000000000000000;
    lbpay_Date.SetParentComponent(Self);
    lbpay_Date.Name := 'lbpay_Date';
    lbpay_Date.Left := 24;
    lbpay_Date.Top := 300;
    lbpay_Date.Width := 51;
    lbpay_Date.Height := 13;
    lbpay_Date.Caption := 'pay_Date:';
    lbpay_Date.HeightPercent := 100.000000000000000000;
    lbpay_Date.WidthPercent := 100.000000000000000000;
    lbpay_Amount.SetParentComponent(Self);
    lbpay_Amount.Name := 'lbpay_Amount';
    lbpay_Amount.Left := 24;
    lbpay_Amount.Top := 327;
    lbpay_Amount.Width := 65;
    lbpay_Amount.Height := 13;
    lbpay_Amount.Caption := 'pay_Amount:';
    lbpay_Amount.HeightPercent := 100.000000000000000000;
    lbpay_Amount.WidthPercent := 100.000000000000000000;
    lbpay_Cash.SetParentComponent(Self);
    lbpay_Cash.Name := 'lbpay_Cash';
    lbpay_Cash.Left := 24;
    lbpay_Cash.Top := 354;
    lbpay_Cash.Width := 52;
    lbpay_Cash.Height := 13;
    lbpay_Cash.Caption := 'pay_Cash:';
    lbpay_Cash.HeightPercent := 100.000000000000000000;
    lbpay_Cash.WidthPercent := 100.000000000000000000;
    lbpay_Chq.SetParentComponent(Self);
    lbpay_Chq.Name := 'lbpay_Chq';
    lbpay_Chq.Left := 24;
    lbpay_Chq.Top := 381;
    lbpay_Chq.Width := 47;
    lbpay_Chq.Height := 13;
    lbpay_Chq.Caption := 'pay_Chq:';
    lbpay_Chq.HeightPercent := 100.000000000000000000;
    lbpay_Chq.WidthPercent := 100.000000000000000000;
    lbpay_Dt.SetParentComponent(Self);
    lbpay_Dt.Name := 'lbpay_Dt';
    lbpay_Dt.Left := 24;
    lbpay_Dt.Top := 408;
    lbpay_Dt.Width := 39;
    lbpay_Dt.Height := 13;
    lbpay_Dt.Caption := 'pay_Dt:';
    lbpay_Dt.HeightPercent := 100.000000000000000000;
    lbpay_Dt.WidthPercent := 100.000000000000000000;
    lbpay_Ct.SetParentComponent(Self);
    lbpay_Ct.Name := 'lbpay_Ct';
    lbpay_Ct.Left := 24;
    lbpay_Ct.Top := 435;
    lbpay_Ct.Width := 39;
    lbpay_Ct.Height := 13;
    lbpay_Ct.Caption := 'pay_Ct:';
    lbpay_Ct.HeightPercent := 100.000000000000000000;
    lbpay_Ct.WidthPercent := 100.000000000000000000;
    lbpay_Online.SetParentComponent(Self);
    lbpay_Online.Name := 'lbpay_Online';
    lbpay_Online.Left := 24;
    lbpay_Online.Top := 462;
    lbpay_Online.Width := 58;
    lbpay_Online.Height := 13;
    lbpay_Online.Caption := 'pay_Online:';
    lbpay_Online.HeightPercent := 100.000000000000000000;
    lbpay_Online.WidthPercent := 100.000000000000000000;
    lbpay_Tranfer.SetParentComponent(Self);
    lbpay_Tranfer.Name := 'lbpay_Tranfer';
    lbpay_Tranfer.Left := 24;
    lbpay_Tranfer.Top := 489;
    lbpay_Tranfer.Width := 64;
    lbpay_Tranfer.Height := 13;
    lbpay_Tranfer.Caption := 'pay_Tranfer:';
    lbpay_Tranfer.HeightPercent := 100.000000000000000000;
    lbpay_Tranfer.WidthPercent := 100.000000000000000000;
    lbpay_CreatedBy.SetParentComponent(Self);
    lbpay_CreatedBy.Name := 'lbpay_CreatedBy';
    lbpay_CreatedBy.Left := 24;
    lbpay_CreatedBy.Top := 516;
    lbpay_CreatedBy.Width := 79;
    lbpay_CreatedBy.Height := 13;
    lbpay_CreatedBy.Caption := 'pay_CreatedBy:';
    lbpay_CreatedBy.HeightPercent := 100.000000000000000000;
    lbpay_CreatedBy.WidthPercent := 100.000000000000000000;
    lbpay_CreatedDate.SetParentComponent(Self);
    lbpay_CreatedDate.Name := 'lbpay_CreatedDate';
    lbpay_CreatedDate.Left := 24;
    lbpay_CreatedDate.Top := 543;
    lbpay_CreatedDate.Width := 90;
    lbpay_CreatedDate.Height := 13;
    lbpay_CreatedDate.Caption := 'pay_CreatedDate:';
    lbpay_CreatedDate.HeightPercent := 100.000000000000000000;
    lbpay_CreatedDate.WidthPercent := 100.000000000000000000;
    lbpay_LastModifiedBy.SetParentComponent(Self);
    lbpay_LastModifiedBy.Name := 'lbpay_LastModifiedBy';
    lbpay_LastModifiedBy.Left := 24;
    lbpay_LastModifiedBy.Top := 570;
    lbpay_LastModifiedBy.Width := 100;
    lbpay_LastModifiedBy.Height := 13;
    lbpay_LastModifiedBy.Caption := 'pay_LastModifiedBy:';
    lbpay_LastModifiedBy.HeightPercent := 100.000000000000000000;
    lbpay_LastModifiedBy.WidthPercent := 100.000000000000000000;
    lbpay_LastModifiedDate.SetParentComponent(Self);
    lbpay_LastModifiedDate.Name := 'lbpay_LastModifiedDate';
    lbpay_LastModifiedDate.Left := 24;
    lbpay_LastModifiedDate.Top := 597;
    lbpay_LastModifiedDate.Width := 111;
    lbpay_LastModifiedDate.Height := 13;
    lbpay_LastModifiedDate.Caption := 'pay_LastModifiedDate:';
    lbpay_LastModifiedDate.HeightPercent := 100.000000000000000000;
    lbpay_LastModifiedDate.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 637;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 637;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 637;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edpay_Id.SetParentComponent(Self);
    edpay_Id.Name := 'edpay_Id';
    edpay_Id.Left := 221;
    edpay_Id.Top := 189;
    edpay_Id.Width := 200;
    edpay_Id.Height := 21;
    edpay_Id.HeightPercent := 100.000000000000000000;
    edpay_Id.TabOrder := 6;
    edpay_Id.Text := 'edpay_Id';
    edpay_Id.WidthPercent := 100.000000000000000000;
    edpay_Id.DataField := 'pay_Id';
    edpay_Id.DataSource := DataSource;
    edemp_Id.SetParentComponent(Self);
    edemp_Id.Name := 'edemp_Id';
    edemp_Id.Left := 221;
    edemp_Id.Top := 216;
    edemp_Id.Width := 200;
    edemp_Id.Height := 21;
    edemp_Id.HeightPercent := 100.000000000000000000;
    edemp_Id.TabOrder := 7;
    edemp_Id.Text := 'edemp_Id';
    edemp_Id.WidthPercent := 100.000000000000000000;
    edemp_Id.DataField := 'emp_Id';
    edemp_Id.DataSource := DataSource;
    edcli_Id.SetParentComponent(Self);
    edcli_Id.Name := 'edcli_Id';
    edcli_Id.Left := 221;
    edcli_Id.Top := 243;
    edcli_Id.Width := 200;
    edcli_Id.Height := 21;
    edcli_Id.HeightPercent := 100.000000000000000000;
    edcli_Id.TabOrder := 8;
    edcli_Id.Text := 'edcli_Id';
    edcli_Id.WidthPercent := 100.000000000000000000;
    edcli_Id.DataField := 'cli_Id';
    edcli_Id.DataSource := DataSource;
    edinv_Id.SetParentComponent(Self);
    edinv_Id.Name := 'edinv_Id';
    edinv_Id.Left := 221;
    edinv_Id.Top := 270;
    edinv_Id.Width := 200;
    edinv_Id.Height := 21;
    edinv_Id.HeightPercent := 100.000000000000000000;
    edinv_Id.TabOrder := 9;
    edinv_Id.Text := 'edinv_Id';
    edinv_Id.WidthPercent := 100.000000000000000000;
    edinv_Id.DataField := 'inv_Id';
    edinv_Id.DataSource := DataSource;
    edpay_Date.SetParentComponent(Self);
    edpay_Date.Name := 'edpay_Date';
    edpay_Date.Left := 221;
    edpay_Date.Top := 297;
    edpay_Date.Width := 200;
    edpay_Date.Height := 21;
    edpay_Date.HeightPercent := 100.000000000000000000;
    edpay_Date.TabOrder := 10;
    edpay_Date.Text := 'edpay_Date';
    edpay_Date.WidthPercent := 100.000000000000000000;
    edpay_Date.DataField := 'pay_Date';
    edpay_Date.DataSource := DataSource;
    edpay_Amount.SetParentComponent(Self);
    edpay_Amount.Name := 'edpay_Amount';
    edpay_Amount.Left := 221;
    edpay_Amount.Top := 324;
    edpay_Amount.Width := 200;
    edpay_Amount.Height := 21;
    edpay_Amount.HeightPercent := 100.000000000000000000;
    edpay_Amount.TabOrder := 11;
    edpay_Amount.Text := 'edpay_Amount';
    edpay_Amount.WidthPercent := 100.000000000000000000;
    edpay_Amount.DataField := 'pay_Amount';
    edpay_Amount.DataSource := DataSource;
    edpay_Cash.SetParentComponent(Self);
    edpay_Cash.Name := 'edpay_Cash';
    edpay_Cash.Left := 221;
    edpay_Cash.Top := 351;
    edpay_Cash.Width := 200;
    edpay_Cash.Height := 21;
    edpay_Cash.HeightPercent := 100.000000000000000000;
    edpay_Cash.TabOrder := 12;
    edpay_Cash.Text := 'edpay_Cash';
    edpay_Cash.WidthPercent := 100.000000000000000000;
    edpay_Cash.DataField := 'pay_Cash';
    edpay_Cash.DataSource := DataSource;
    edpay_Chq.SetParentComponent(Self);
    edpay_Chq.Name := 'edpay_Chq';
    edpay_Chq.Left := 221;
    edpay_Chq.Top := 378;
    edpay_Chq.Width := 200;
    edpay_Chq.Height := 21;
    edpay_Chq.HeightPercent := 100.000000000000000000;
    edpay_Chq.TabOrder := 13;
    edpay_Chq.Text := 'edpay_Chq';
    edpay_Chq.WidthPercent := 100.000000000000000000;
    edpay_Chq.DataField := 'pay_Chq';
    edpay_Chq.DataSource := DataSource;
    edpay_Dt.SetParentComponent(Self);
    edpay_Dt.Name := 'edpay_Dt';
    edpay_Dt.Left := 221;
    edpay_Dt.Top := 405;
    edpay_Dt.Width := 200;
    edpay_Dt.Height := 21;
    edpay_Dt.HeightPercent := 100.000000000000000000;
    edpay_Dt.TabOrder := 14;
    edpay_Dt.Text := 'edpay_Dt';
    edpay_Dt.WidthPercent := 100.000000000000000000;
    edpay_Dt.DataField := 'pay_Dt';
    edpay_Dt.DataSource := DataSource;
    edpay_Ct.SetParentComponent(Self);
    edpay_Ct.Name := 'edpay_Ct';
    edpay_Ct.Left := 221;
    edpay_Ct.Top := 432;
    edpay_Ct.Width := 200;
    edpay_Ct.Height := 21;
    edpay_Ct.HeightPercent := 100.000000000000000000;
    edpay_Ct.TabOrder := 15;
    edpay_Ct.Text := 'edpay_Ct';
    edpay_Ct.WidthPercent := 100.000000000000000000;
    edpay_Ct.DataField := 'pay_Ct';
    edpay_Ct.DataSource := DataSource;
    edpay_Online.SetParentComponent(Self);
    edpay_Online.Name := 'edpay_Online';
    edpay_Online.Left := 221;
    edpay_Online.Top := 459;
    edpay_Online.Width := 200;
    edpay_Online.Height := 21;
    edpay_Online.HeightPercent := 100.000000000000000000;
    edpay_Online.TabOrder := 16;
    edpay_Online.Text := 'edpay_Online';
    edpay_Online.WidthPercent := 100.000000000000000000;
    edpay_Online.DataField := 'pay_Online';
    edpay_Online.DataSource := DataSource;
    edpay_Tranfer.SetParentComponent(Self);
    edpay_Tranfer.Name := 'edpay_Tranfer';
    edpay_Tranfer.Left := 221;
    edpay_Tranfer.Top := 486;
    edpay_Tranfer.Width := 200;
    edpay_Tranfer.Height := 21;
    edpay_Tranfer.HeightPercent := 100.000000000000000000;
    edpay_Tranfer.TabOrder := 17;
    edpay_Tranfer.Text := 'edpay_Tranfer';
    edpay_Tranfer.WidthPercent := 100.000000000000000000;
    edpay_Tranfer.DataField := 'pay_Tranfer';
    edpay_Tranfer.DataSource := DataSource;
    edpay_CreatedBy.SetParentComponent(Self);
    edpay_CreatedBy.Name := 'edpay_CreatedBy';
    edpay_CreatedBy.Left := 221;
    edpay_CreatedBy.Top := 513;
    edpay_CreatedBy.Width := 200;
    edpay_CreatedBy.Height := 21;
    edpay_CreatedBy.HeightPercent := 100.000000000000000000;
    edpay_CreatedBy.TabOrder := 18;
    edpay_CreatedBy.Text := 'edpay_CreatedBy';
    edpay_CreatedBy.WidthPercent := 100.000000000000000000;
    edpay_CreatedBy.DataField := 'pay_CreatedBy';
    edpay_CreatedBy.DataSource := DataSource;
    edpay_CreatedDate.SetParentComponent(Self);
    edpay_CreatedDate.Name := 'edpay_CreatedDate';
    edpay_CreatedDate.Left := 221;
    edpay_CreatedDate.Top := 540;
    edpay_CreatedDate.Width := 200;
    edpay_CreatedDate.Height := 21;
    edpay_CreatedDate.HeightPercent := 100.000000000000000000;
    edpay_CreatedDate.TabOrder := 19;
    edpay_CreatedDate.Text := 'edpay_CreatedDate';
    edpay_CreatedDate.WidthPercent := 100.000000000000000000;
    edpay_CreatedDate.DataField := 'pay_CreatedDate';
    edpay_CreatedDate.DataSource := DataSource;
    edpay_LastModifiedBy.SetParentComponent(Self);
    edpay_LastModifiedBy.Name := 'edpay_LastModifiedBy';
    edpay_LastModifiedBy.Left := 221;
    edpay_LastModifiedBy.Top := 567;
    edpay_LastModifiedBy.Width := 200;
    edpay_LastModifiedBy.Height := 21;
    edpay_LastModifiedBy.HeightPercent := 100.000000000000000000;
    edpay_LastModifiedBy.TabOrder := 20;
    edpay_LastModifiedBy.Text := 'edpay_LastModifiedBy';
    edpay_LastModifiedBy.WidthPercent := 100.000000000000000000;
    edpay_LastModifiedBy.DataField := 'pay_LastModifiedBy';
    edpay_LastModifiedBy.DataSource := DataSource;
    edpay_LastModifiedDate.SetParentComponent(Self);
    edpay_LastModifiedDate.Name := 'edpay_LastModifiedDate';
    edpay_LastModifiedDate.Left := 221;
    edpay_LastModifiedDate.Top := 594;
    edpay_LastModifiedDate.Width := 200;
    edpay_LastModifiedDate.Height := 21;
    edpay_LastModifiedDate.HeightPercent := 100.000000000000000000;
    edpay_LastModifiedDate.TabOrder := 21;
    edpay_LastModifiedDate.Text := 'edpay_LastModifiedDate';
    edpay_LastModifiedDate.WidthPercent := 100.000000000000000000;
    edpay_LastModifiedDate.DataField := 'pay_LastModifiedDate';
    edpay_LastModifiedDate.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := PaymentsDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    PaymentsDataset.SetParentComponent(Self);
    PaymentsDataset.Name := 'PaymentsDataset';
    PaymentsDataset.AfterApplyUpdates := PaymentsDatasetAfterApplyUpdates;
    PaymentsDataset.EntitySetName := 'Payments';
    PaymentsDataset.Connection := DMConnection.ApiConnection;
    PaymentsDataset.Left := 176;
    PaymentsDataset.Top := 8;
    PaymentsDatasetpay_Id.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Id.Name := 'PaymentsDatasetpay_Id';
    PaymentsDatasetpay_Id.FieldName := 'pay_Id';
    PaymentsDatasetpay_Id.Required := True;
    PaymentsDatasetemp_Id.SetParentComponent(PaymentsDataset);
    PaymentsDatasetemp_Id.Name := 'PaymentsDatasetemp_Id';
    PaymentsDatasetemp_Id.FieldName := 'emp_Id';
    PaymentsDatasetcli_Id.SetParentComponent(PaymentsDataset);
    PaymentsDatasetcli_Id.Name := 'PaymentsDatasetcli_Id';
    PaymentsDatasetcli_Id.FieldName := 'cli_Id';
    PaymentsDatasetinv_Id.SetParentComponent(PaymentsDataset);
    PaymentsDatasetinv_Id.Name := 'PaymentsDatasetinv_Id';
    PaymentsDatasetinv_Id.FieldName := 'inv_Id';
    PaymentsDatasetpay_Date.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Date.Name := 'PaymentsDatasetpay_Date';
    PaymentsDatasetpay_Date.FieldName := 'pay_Date';
    PaymentsDatasetpay_Amount.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Amount.Name := 'PaymentsDatasetpay_Amount';
    PaymentsDatasetpay_Amount.FieldName := 'pay_Amount';
    PaymentsDatasetpay_Cash.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Cash.Name := 'PaymentsDatasetpay_Cash';
    PaymentsDatasetpay_Cash.FieldName := 'pay_Cash';
    PaymentsDatasetpay_Chq.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Chq.Name := 'PaymentsDatasetpay_Chq';
    PaymentsDatasetpay_Chq.FieldName := 'pay_Chq';
    PaymentsDatasetpay_Dt.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Dt.Name := 'PaymentsDatasetpay_Dt';
    PaymentsDatasetpay_Dt.FieldName := 'pay_Dt';
    PaymentsDatasetpay_Ct.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Ct.Name := 'PaymentsDatasetpay_Ct';
    PaymentsDatasetpay_Ct.FieldName := 'pay_Ct';
    PaymentsDatasetpay_Online.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Online.Name := 'PaymentsDatasetpay_Online';
    PaymentsDatasetpay_Online.FieldName := 'pay_Online';
    PaymentsDatasetpay_Tranfer.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_Tranfer.Name := 'PaymentsDatasetpay_Tranfer';
    PaymentsDatasetpay_Tranfer.FieldName := 'pay_Tranfer';
    PaymentsDatasetpay_CreatedBy.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_CreatedBy.Name := 'PaymentsDatasetpay_CreatedBy';
    PaymentsDatasetpay_CreatedBy.FieldName := 'pay_CreatedBy';
    PaymentsDatasetpay_CreatedDate.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_CreatedDate.Name := 'PaymentsDatasetpay_CreatedDate';
    PaymentsDatasetpay_CreatedDate.FieldName := 'pay_CreatedDate';
    PaymentsDatasetpay_LastModifiedBy.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_LastModifiedBy.Name := 'PaymentsDatasetpay_LastModifiedBy';
    PaymentsDatasetpay_LastModifiedBy.FieldName := 'pay_LastModifiedBy';
    PaymentsDatasetpay_LastModifiedDate.SetParentComponent(PaymentsDataset);
    PaymentsDatasetpay_LastModifiedDate.Name := 'PaymentsDatasetpay_LastModifiedDate';
    PaymentsDatasetpay_LastModifiedDate.FieldName := 'pay_LastModifiedDate';
    XDataClientPaymentsGet.SetParentComponent(Self);
    XDataClientPaymentsGet.Name := 'XDataClientPaymentsGet';
    XDataClientPaymentsGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientPaymentsGet, Self, 'OnLoad', 'XDataClientPaymentsGetLoad');
    XDataClientPaymentsGet.Left := 64;
    XDataClientPaymentsGet.Top := 8;
    XDataClientPaymentsDelete.SetParentComponent(Self);
    XDataClientPaymentsDelete.Name := 'XDataClientPaymentsDelete';
    XDataClientPaymentsDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientPaymentsDelete, Self, 'OnLoad', 'XDataClientPaymentsDeleteLoad');
    SetEvent(XDataClientPaymentsDelete, Self, 'OnError', 'XDataClientPaymentsDeleteError');
    XDataClientPaymentsDelete.Left := 64;
    XDataClientPaymentsDelete.Top := 56;
  finally
    lbpay_Id.AfterLoadDFMValues;
    lbemp_Id.AfterLoadDFMValues;
    lbcli_Id.AfterLoadDFMValues;
    lbinv_Id.AfterLoadDFMValues;
    lbpay_Date.AfterLoadDFMValues;
    lbpay_Amount.AfterLoadDFMValues;
    lbpay_Cash.AfterLoadDFMValues;
    lbpay_Chq.AfterLoadDFMValues;
    lbpay_Dt.AfterLoadDFMValues;
    lbpay_Ct.AfterLoadDFMValues;
    lbpay_Online.AfterLoadDFMValues;
    lbpay_Tranfer.AfterLoadDFMValues;
    lbpay_CreatedBy.AfterLoadDFMValues;
    lbpay_CreatedDate.AfterLoadDFMValues;
    lbpay_LastModifiedBy.AfterLoadDFMValues;
    lbpay_LastModifiedDate.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edpay_Id.AfterLoadDFMValues;
    edemp_Id.AfterLoadDFMValues;
    edcli_Id.AfterLoadDFMValues;
    edinv_Id.AfterLoadDFMValues;
    edpay_Date.AfterLoadDFMValues;
    edpay_Amount.AfterLoadDFMValues;
    edpay_Cash.AfterLoadDFMValues;
    edpay_Chq.AfterLoadDFMValues;
    edpay_Dt.AfterLoadDFMValues;
    edpay_Ct.AfterLoadDFMValues;
    edpay_Online.AfterLoadDFMValues;
    edpay_Tranfer.AfterLoadDFMValues;
    edpay_CreatedBy.AfterLoadDFMValues;
    edpay_CreatedDate.AfterLoadDFMValues;
    edpay_LastModifiedBy.AfterLoadDFMValues;
    edpay_LastModifiedDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    PaymentsDataset.AfterLoadDFMValues;
    PaymentsDatasetpay_Id.AfterLoadDFMValues;
    PaymentsDatasetemp_Id.AfterLoadDFMValues;
    PaymentsDatasetcli_Id.AfterLoadDFMValues;
    PaymentsDatasetinv_Id.AfterLoadDFMValues;
    PaymentsDatasetpay_Date.AfterLoadDFMValues;
    PaymentsDatasetpay_Amount.AfterLoadDFMValues;
    PaymentsDatasetpay_Cash.AfterLoadDFMValues;
    PaymentsDatasetpay_Chq.AfterLoadDFMValues;
    PaymentsDatasetpay_Dt.AfterLoadDFMValues;
    PaymentsDatasetpay_Ct.AfterLoadDFMValues;
    PaymentsDatasetpay_Online.AfterLoadDFMValues;
    PaymentsDatasetpay_Tranfer.AfterLoadDFMValues;
    PaymentsDatasetpay_CreatedBy.AfterLoadDFMValues;
    PaymentsDatasetpay_CreatedDate.AfterLoadDFMValues;
    PaymentsDatasetpay_LastModifiedBy.AfterLoadDFMValues;
    PaymentsDatasetpay_LastModifiedDate.AfterLoadDFMValues;
    XDataClientPaymentsGet.AfterLoadDFMValues;
    XDataClientPaymentsDelete.AfterLoadDFMValues;
  end;
end;

end.
