unit View.Employee.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewEmployeeEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbemp_Id: TLabel;
    edemp_Id: TDBEdit;
    lbemp_Active: TLabel;
    edemp_Active: TDBEdit;
    lbemp_FirstName: TLabel;
    edemp_FirstName: TDBEdit;
    lbemp_LastName: TLabel;
    edemp_LastName: TDBEdit;
    lbemp_Adresse: TLabel;
    edemp_Adresse: TDBEdit;
    lbemp_City: TLabel;
    edemp_City: TDBEdit;
    lbemp_PostalCode: TLabel;
    edemp_PostalCode: TDBEdit;
    lbemp_Telephone: TLabel;
    edemp_Telephone: TDBEdit;
    lbemp_Email: TLabel;
    edemp_Email: TDBEdit;
    lbemp_BirthDate: TLabel;
    edemp_BirthDate: TDBEdit;
    lbemp_SocialNumber: TLabel;
    edemp_SocialNumber: TDBEdit;
    lbemp_Position: TLabel;
    edemp_Position: TDBEdit;
    lbemp_HourlySal: TLabel;
    edemp_HourlySal: TDBEdit;
    lbemp_StartDate: TLabel;
    edemp_StartDate: TDBEdit;
    lbemp_EndDate: TLabel;
    edemp_EndDate: TDBEdit;
    lbemp_Notes: TLabel;
    edemp_Notes: TDBEdit;
    lbemp_CreatedBy: TLabel;
    edemp_CreatedBy: TDBEdit;
    lbemp_CreatedDate: TLabel;
    edemp_CreatedDate: TDBEdit;
    lbemp_LastModifiedBy: TLabel;
    edemp_LastModifiedBy: TDBEdit;
    lbemp_LastModifiedDate: TLabel;
    edemp_LastModifiedDate: TDBEdit;
    XDataClientEmployeeGet: TXDataWebClient;
    XDataClientEmployeeDelete: TXDataWebClient;
    EmployeeDataset: TXDataWebDataSet;
    EmployeeDatasetemp_Id: TIntegerField;
    EmployeeDatasetemp_Active: TStringField;
    EmployeeDatasetemp_FirstName: TStringField;
    EmployeeDatasetemp_LastName: TStringField;
    EmployeeDatasetemp_Adresse: TStringField;
    EmployeeDatasetemp_City: TStringField;
    EmployeeDatasetemp_PostalCode: TStringField;
    EmployeeDatasetemp_Telephone: TStringField;
    EmployeeDatasetemp_Email: TStringField;
    EmployeeDatasetemp_BirthDate: TDateTimeField;
    EmployeeDatasetemp_SocialNumber: TStringField;
    EmployeeDatasetemp_Position: TStringField;
    EmployeeDatasetemp_HourlySal: TFloatField;
    EmployeeDatasetemp_StartDate: TDateTimeField;
    EmployeeDatasetemp_EndDate: TDateTimeField;
    EmployeeDatasetemp_Notes: TStringField;
    EmployeeDatasetemp_CreatedBy: TIntegerField;
    EmployeeDatasetemp_CreatedDate: TDateTimeField;
    EmployeeDatasetemp_LastModifiedBy: TIntegerField;
    EmployeeDatasetemp_LastModifiedDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientEmployeeGetLoad(Response: TXDataClientResponse);
    procedure XDataClientEmployeeDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientEmployeeDeleteError(Error: TXDataClientError);
    procedure EmployeeDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewEmployeeEdit: TFViewEmployeeEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewEmployeeEdit }

procedure TFViewEmployeeEdit.btSaveClick(Sender: TObject);
begin
  if EmployeeDataset.State in dsEditModes then
  begin
        EmployeeDataset.Post;
    EmployeeDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewEmployeeEdit.btDeleteClick(Sender: TObject);
begin
  if EmployeeDataset.State in dsEditModes then
    EmployeeDataset.Post;
  XDataClientEmployeeDelete.Delete(EmployeeDataset.EntitySetName,
    TJSObject(EmployeeDataset.CurrentData));
end;


class function TFViewEmployeeEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewEmployeeEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewEmployeeEdit.InsertEntity;
begin
  EmployeeDataset.Close;
  EmployeeDataset.SetJsonData('{}');
  EmployeeDataset.Open;
  EmployeeDataset.Insert;
end;

class function TFViewEmployeeEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewEmployeeEdit(AForm).FInserting := AInserting;
    TFViewEmployeeEdit(AForm).FId := AId;
    TFViewEmployeeEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewEmployeeEdit(AForm).InsertEntity
    else
      TFViewEmployeeEdit(AForm).LoadEntity;
    TFViewEmployeeEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewEmployeeEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewEmployeeEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    EmployeeDataset.Connection.Model.DefaultSchema.FindEntityType(
      EmployeeDataset.EntitySetName
    )
  );
  XDataClientEmployeeGet.Get(EmployeeDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewEmployeeEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewEmployeeEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewEmployeeEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewEmployeeEdit.XDataClientEmployeeGetLoad(Response: TXDataClientResponse);
begin
  EmployeeDataset.Close;
  EmployeeDataset.SetJsonData(Response.Result);
  EmployeeDataset.Open;
  
  EmployeeDataset.Edit;  
end;

procedure TFViewEmployeeEdit.XDataClientEmployeeDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewEmployeeEdit.XDataClientEmployeeDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewEmployeeEdit.EmployeeDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewEmployeeEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewEmployeeEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbemp_Id := TLabel.Create('view.employee.form.lbemp_Id');
  lbemp_Active := TLabel.Create('view.employee.form.lbemp_Active');
  lbemp_FirstName := TLabel.Create('view.employee.form.lbemp_FirstName');
  lbemp_LastName := TLabel.Create('view.employee.form.lbemp_LastName');
  lbemp_Adresse := TLabel.Create('view.employee.form.lbemp_Adresse');
  lbemp_City := TLabel.Create('view.employee.form.lbemp_City');
  lbemp_PostalCode := TLabel.Create('view.employee.form.lbemp_PostalCode');
  lbemp_Telephone := TLabel.Create('view.employee.form.lbemp_Telephone');
  lbemp_Email := TLabel.Create('view.employee.form.lbemp_Email');
  lbemp_BirthDate := TLabel.Create('view.employee.form.lbemp_BirthDate');
  lbemp_SocialNumber := TLabel.Create('view.employee.form.lbemp_SocialNumber');
  lbemp_Position := TLabel.Create('view.employee.form.lbemp_Position');
  lbemp_HourlySal := TLabel.Create('view.employee.form.lbemp_HourlySal');
  lbemp_StartDate := TLabel.Create('view.employee.form.lbemp_StartDate');
  lbemp_EndDate := TLabel.Create('view.employee.form.lbemp_EndDate');
  lbemp_Notes := TLabel.Create('view.employee.form.lbemp_Notes');
  lbemp_CreatedBy := TLabel.Create('view.employee.form.lbemp_CreatedBy');
  lbemp_CreatedDate := TLabel.Create('view.employee.form.lbemp_CreatedDate');
  lbemp_LastModifiedBy := TLabel.Create('view.employee.form.lbemp_LastModifiedBy');
  lbemp_LastModifiedDate := TLabel.Create('view.employee.form.lbemp_LastModifiedDate');
  pnlMessage := TPanel.Create('view.employee.form.message');
  lbMessage := TLabel.Create('view.employee.form.message.label');
  btCloseMessage := TButton.Create('view.employee.form.message.button');
  btCancel := TButton.Create('view.employee.form.btBack');
  btSave := TButton.Create('view.employee.form.btSave');
  btDelete := TButton.Create('view.employee.form.btDelete');
  edemp_Id := TDBEdit.Create('view.employee.form.edemp_Id');
  edemp_Active := TDBEdit.Create('view.employee.form.edemp_Active');
  edemp_FirstName := TDBEdit.Create('view.employee.form.edemp_FirstName');
  edemp_LastName := TDBEdit.Create('view.employee.form.edemp_LastName');
  edemp_Adresse := TDBEdit.Create('view.employee.form.edemp_Adresse');
  edemp_City := TDBEdit.Create('view.employee.form.edemp_City');
  edemp_PostalCode := TDBEdit.Create('view.employee.form.edemp_PostalCode');
  edemp_Telephone := TDBEdit.Create('view.employee.form.edemp_Telephone');
  edemp_Email := TDBEdit.Create('view.employee.form.edemp_Email');
  edemp_BirthDate := TDBEdit.Create('view.employee.form.edemp_BirthDate');
  edemp_SocialNumber := TDBEdit.Create('view.employee.form.edemp_SocialNumber');
  edemp_Position := TDBEdit.Create('view.employee.form.edemp_Position');
  edemp_HourlySal := TDBEdit.Create('view.employee.form.edemp_HourlySal');
  edemp_StartDate := TDBEdit.Create('view.employee.form.edemp_StartDate');
  edemp_EndDate := TDBEdit.Create('view.employee.form.edemp_EndDate');
  edemp_Notes := TDBEdit.Create('view.employee.form.edemp_Notes');
  edemp_CreatedBy := TDBEdit.Create('view.employee.form.edemp_CreatedBy');
  edemp_CreatedDate := TDBEdit.Create('view.employee.form.edemp_CreatedDate');
  edemp_LastModifiedBy := TDBEdit.Create('view.employee.form.edemp_LastModifiedBy');
  edemp_LastModifiedDate := TDBEdit.Create('view.employee.form.edemp_LastModifiedDate');
  DataSource := TDataSource.Create(Self);
  EmployeeDataset := TXDataWebDataSet.Create(Self);
  EmployeeDatasetemp_Id := TIntegerField.Create(Self);
  EmployeeDatasetemp_Active := TStringField.Create(Self);
  EmployeeDatasetemp_FirstName := TStringField.Create(Self);
  EmployeeDatasetemp_LastName := TStringField.Create(Self);
  EmployeeDatasetemp_Adresse := TStringField.Create(Self);
  EmployeeDatasetemp_City := TStringField.Create(Self);
  EmployeeDatasetemp_PostalCode := TStringField.Create(Self);
  EmployeeDatasetemp_Telephone := TStringField.Create(Self);
  EmployeeDatasetemp_Email := TStringField.Create(Self);
  EmployeeDatasetemp_BirthDate := TDateTimeField.Create(Self);
  EmployeeDatasetemp_SocialNumber := TStringField.Create(Self);
  EmployeeDatasetemp_Position := TStringField.Create(Self);
  EmployeeDatasetemp_HourlySal := TFloatField.Create(Self);
  EmployeeDatasetemp_StartDate := TDateTimeField.Create(Self);
  EmployeeDatasetemp_EndDate := TDateTimeField.Create(Self);
  EmployeeDatasetemp_Notes := TStringField.Create(Self);
  EmployeeDatasetemp_CreatedBy := TIntegerField.Create(Self);
  EmployeeDatasetemp_CreatedDate := TDateTimeField.Create(Self);
  EmployeeDatasetemp_LastModifiedBy := TIntegerField.Create(Self);
  EmployeeDatasetemp_LastModifiedDate := TDateTimeField.Create(Self);
  XDataClientEmployeeGet := TXDataWebClient.Create(Self);
  XDataClientEmployeeDelete := TXDataWebClient.Create(Self);

  lbemp_Id.BeforeLoadDFMValues;
  lbemp_Active.BeforeLoadDFMValues;
  lbemp_FirstName.BeforeLoadDFMValues;
  lbemp_LastName.BeforeLoadDFMValues;
  lbemp_Adresse.BeforeLoadDFMValues;
  lbemp_City.BeforeLoadDFMValues;
  lbemp_PostalCode.BeforeLoadDFMValues;
  lbemp_Telephone.BeforeLoadDFMValues;
  lbemp_Email.BeforeLoadDFMValues;
  lbemp_BirthDate.BeforeLoadDFMValues;
  lbemp_SocialNumber.BeforeLoadDFMValues;
  lbemp_Position.BeforeLoadDFMValues;
  lbemp_HourlySal.BeforeLoadDFMValues;
  lbemp_StartDate.BeforeLoadDFMValues;
  lbemp_EndDate.BeforeLoadDFMValues;
  lbemp_Notes.BeforeLoadDFMValues;
  lbemp_CreatedBy.BeforeLoadDFMValues;
  lbemp_CreatedDate.BeforeLoadDFMValues;
  lbemp_LastModifiedBy.BeforeLoadDFMValues;
  lbemp_LastModifiedDate.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edemp_Id.BeforeLoadDFMValues;
  edemp_Active.BeforeLoadDFMValues;
  edemp_FirstName.BeforeLoadDFMValues;
  edemp_LastName.BeforeLoadDFMValues;
  edemp_Adresse.BeforeLoadDFMValues;
  edemp_City.BeforeLoadDFMValues;
  edemp_PostalCode.BeforeLoadDFMValues;
  edemp_Telephone.BeforeLoadDFMValues;
  edemp_Email.BeforeLoadDFMValues;
  edemp_BirthDate.BeforeLoadDFMValues;
  edemp_SocialNumber.BeforeLoadDFMValues;
  edemp_Position.BeforeLoadDFMValues;
  edemp_HourlySal.BeforeLoadDFMValues;
  edemp_StartDate.BeforeLoadDFMValues;
  edemp_EndDate.BeforeLoadDFMValues;
  edemp_Notes.BeforeLoadDFMValues;
  edemp_CreatedBy.BeforeLoadDFMValues;
  edemp_CreatedDate.BeforeLoadDFMValues;
  edemp_LastModifiedBy.BeforeLoadDFMValues;
  edemp_LastModifiedDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  EmployeeDataset.BeforeLoadDFMValues;
  EmployeeDatasetemp_Id.BeforeLoadDFMValues;
  EmployeeDatasetemp_Active.BeforeLoadDFMValues;
  EmployeeDatasetemp_FirstName.BeforeLoadDFMValues;
  EmployeeDatasetemp_LastName.BeforeLoadDFMValues;
  EmployeeDatasetemp_Adresse.BeforeLoadDFMValues;
  EmployeeDatasetemp_City.BeforeLoadDFMValues;
  EmployeeDatasetemp_PostalCode.BeforeLoadDFMValues;
  EmployeeDatasetemp_Telephone.BeforeLoadDFMValues;
  EmployeeDatasetemp_Email.BeforeLoadDFMValues;
  EmployeeDatasetemp_BirthDate.BeforeLoadDFMValues;
  EmployeeDatasetemp_SocialNumber.BeforeLoadDFMValues;
  EmployeeDatasetemp_Position.BeforeLoadDFMValues;
  EmployeeDatasetemp_HourlySal.BeforeLoadDFMValues;
  EmployeeDatasetemp_StartDate.BeforeLoadDFMValues;
  EmployeeDatasetemp_EndDate.BeforeLoadDFMValues;
  EmployeeDatasetemp_Notes.BeforeLoadDFMValues;
  EmployeeDatasetemp_CreatedBy.BeforeLoadDFMValues;
  EmployeeDatasetemp_CreatedDate.BeforeLoadDFMValues;
  EmployeeDatasetemp_LastModifiedBy.BeforeLoadDFMValues;
  EmployeeDatasetemp_LastModifiedDate.BeforeLoadDFMValues;
  XDataClientEmployeeGet.BeforeLoadDFMValues;
  XDataClientEmployeeDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewEmployeeEdit';
    Width := 593;
    Height := 805;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbemp_Id.SetParentComponent(Self);
    lbemp_Id.Name := 'lbemp_Id';
    lbemp_Id.Left := 24;
    lbemp_Id.Top := 192;
    lbemp_Id.Width := 40;
    lbemp_Id.Height := 13;
    lbemp_Id.Caption := 'emp_Id:';
    lbemp_Id.HeightPercent := 100.000000000000000000;
    lbemp_Id.WidthPercent := 100.000000000000000000;
    lbemp_Active.SetParentComponent(Self);
    lbemp_Active.Name := 'lbemp_Active';
    lbemp_Active.Left := 24;
    lbemp_Active.Top := 219;
    lbemp_Active.Width := 60;
    lbemp_Active.Height := 13;
    lbemp_Active.Caption := 'emp_Active:';
    lbemp_Active.HeightPercent := 100.000000000000000000;
    lbemp_Active.WidthPercent := 100.000000000000000000;
    lbemp_FirstName.SetParentComponent(Self);
    lbemp_FirstName.Name := 'lbemp_FirstName';
    lbemp_FirstName.Left := 24;
    lbemp_FirstName.Top := 246;
    lbemp_FirstName.Width := 78;
    lbemp_FirstName.Height := 13;
    lbemp_FirstName.Caption := 'emp_FirstName:';
    lbemp_FirstName.HeightPercent := 100.000000000000000000;
    lbemp_FirstName.WidthPercent := 100.000000000000000000;
    lbemp_LastName.SetParentComponent(Self);
    lbemp_LastName.Name := 'lbemp_LastName';
    lbemp_LastName.Left := 24;
    lbemp_LastName.Top := 273;
    lbemp_LastName.Width := 77;
    lbemp_LastName.Height := 13;
    lbemp_LastName.Caption := 'emp_LastName:';
    lbemp_LastName.HeightPercent := 100.000000000000000000;
    lbemp_LastName.WidthPercent := 100.000000000000000000;
    lbemp_Adresse.SetParentComponent(Self);
    lbemp_Adresse.Name := 'lbemp_Adresse';
    lbemp_Adresse.Left := 24;
    lbemp_Adresse.Top := 300;
    lbemp_Adresse.Width := 69;
    lbemp_Adresse.Height := 13;
    lbemp_Adresse.Caption := 'emp_Adresse:';
    lbemp_Adresse.HeightPercent := 100.000000000000000000;
    lbemp_Adresse.WidthPercent := 100.000000000000000000;
    lbemp_City.SetParentComponent(Self);
    lbemp_City.Name := 'lbemp_City';
    lbemp_City.Left := 24;
    lbemp_City.Top := 327;
    lbemp_City.Width := 49;
    lbemp_City.Height := 13;
    lbemp_City.Caption := 'emp_City:';
    lbemp_City.HeightPercent := 100.000000000000000000;
    lbemp_City.WidthPercent := 100.000000000000000000;
    lbemp_PostalCode.SetParentComponent(Self);
    lbemp_PostalCode.Name := 'lbemp_PostalCode';
    lbemp_PostalCode.Left := 24;
    lbemp_PostalCode.Top := 354;
    lbemp_PostalCode.Width := 84;
    lbemp_PostalCode.Height := 13;
    lbemp_PostalCode.Caption := 'emp_PostalCode:';
    lbemp_PostalCode.HeightPercent := 100.000000000000000000;
    lbemp_PostalCode.WidthPercent := 100.000000000000000000;
    lbemp_Telephone.SetParentComponent(Self);
    lbemp_Telephone.Name := 'lbemp_Telephone';
    lbemp_Telephone.Left := 24;
    lbemp_Telephone.Top := 381;
    lbemp_Telephone.Width := 80;
    lbemp_Telephone.Height := 13;
    lbemp_Telephone.Caption := 'emp_Telephone:';
    lbemp_Telephone.HeightPercent := 100.000000000000000000;
    lbemp_Telephone.WidthPercent := 100.000000000000000000;
    lbemp_Email.SetParentComponent(Self);
    lbemp_Email.Name := 'lbemp_Email';
    lbemp_Email.Left := 24;
    lbemp_Email.Top := 408;
    lbemp_Email.Width := 54;
    lbemp_Email.Height := 13;
    lbemp_Email.Caption := 'emp_Email:';
    lbemp_Email.HeightPercent := 100.000000000000000000;
    lbemp_Email.WidthPercent := 100.000000000000000000;
    lbemp_BirthDate.SetParentComponent(Self);
    lbemp_BirthDate.Name := 'lbemp_BirthDate';
    lbemp_BirthDate.Left := 24;
    lbemp_BirthDate.Top := 435;
    lbemp_BirthDate.Width := 75;
    lbemp_BirthDate.Height := 13;
    lbemp_BirthDate.Caption := 'emp_BirthDate:';
    lbemp_BirthDate.HeightPercent := 100.000000000000000000;
    lbemp_BirthDate.WidthPercent := 100.000000000000000000;
    lbemp_SocialNumber.SetParentComponent(Self);
    lbemp_SocialNumber.Name := 'lbemp_SocialNumber';
    lbemp_SocialNumber.Left := 24;
    lbemp_SocialNumber.Top := 462;
    lbemp_SocialNumber.Width := 94;
    lbemp_SocialNumber.Height := 13;
    lbemp_SocialNumber.Caption := 'emp_SocialNumber:';
    lbemp_SocialNumber.HeightPercent := 100.000000000000000000;
    lbemp_SocialNumber.WidthPercent := 100.000000000000000000;
    lbemp_Position.SetParentComponent(Self);
    lbemp_Position.Name := 'lbemp_Position';
    lbemp_Position.Left := 24;
    lbemp_Position.Top := 489;
    lbemp_Position.Width := 67;
    lbemp_Position.Height := 13;
    lbemp_Position.Caption := 'emp_Position:';
    lbemp_Position.HeightPercent := 100.000000000000000000;
    lbemp_Position.WidthPercent := 100.000000000000000000;
    lbemp_HourlySal.SetParentComponent(Self);
    lbemp_HourlySal.Name := 'lbemp_HourlySal';
    lbemp_HourlySal.Left := 24;
    lbemp_HourlySal.Top := 516;
    lbemp_HourlySal.Width := 75;
    lbemp_HourlySal.Height := 13;
    lbemp_HourlySal.Caption := 'emp_HourlySal:';
    lbemp_HourlySal.HeightPercent := 100.000000000000000000;
    lbemp_HourlySal.WidthPercent := 100.000000000000000000;
    lbemp_StartDate.SetParentComponent(Self);
    lbemp_StartDate.Name := 'lbemp_StartDate';
    lbemp_StartDate.Left := 24;
    lbemp_StartDate.Top := 543;
    lbemp_StartDate.Width := 77;
    lbemp_StartDate.Height := 13;
    lbemp_StartDate.Caption := 'emp_StartDate:';
    lbemp_StartDate.HeightPercent := 100.000000000000000000;
    lbemp_StartDate.WidthPercent := 100.000000000000000000;
    lbemp_EndDate.SetParentComponent(Self);
    lbemp_EndDate.Name := 'lbemp_EndDate';
    lbemp_EndDate.Left := 24;
    lbemp_EndDate.Top := 570;
    lbemp_EndDate.Width := 71;
    lbemp_EndDate.Height := 13;
    lbemp_EndDate.Caption := 'emp_EndDate:';
    lbemp_EndDate.HeightPercent := 100.000000000000000000;
    lbemp_EndDate.WidthPercent := 100.000000000000000000;
    lbemp_Notes.SetParentComponent(Self);
    lbemp_Notes.Name := 'lbemp_Notes';
    lbemp_Notes.Left := 24;
    lbemp_Notes.Top := 597;
    lbemp_Notes.Width := 58;
    lbemp_Notes.Height := 13;
    lbemp_Notes.Caption := 'emp_Notes:';
    lbemp_Notes.HeightPercent := 100.000000000000000000;
    lbemp_Notes.WidthPercent := 100.000000000000000000;
    lbemp_CreatedBy.SetParentComponent(Self);
    lbemp_CreatedBy.Name := 'lbemp_CreatedBy';
    lbemp_CreatedBy.Left := 24;
    lbemp_CreatedBy.Top := 624;
    lbemp_CreatedBy.Width := 81;
    lbemp_CreatedBy.Height := 13;
    lbemp_CreatedBy.Caption := 'emp_CreatedBy:';
    lbemp_CreatedBy.HeightPercent := 100.000000000000000000;
    lbemp_CreatedBy.WidthPercent := 100.000000000000000000;
    lbemp_CreatedDate.SetParentComponent(Self);
    lbemp_CreatedDate.Name := 'lbemp_CreatedDate';
    lbemp_CreatedDate.Left := 24;
    lbemp_CreatedDate.Top := 651;
    lbemp_CreatedDate.Width := 92;
    lbemp_CreatedDate.Height := 13;
    lbemp_CreatedDate.Caption := 'emp_CreatedDate:';
    lbemp_CreatedDate.HeightPercent := 100.000000000000000000;
    lbemp_CreatedDate.WidthPercent := 100.000000000000000000;
    lbemp_LastModifiedBy.SetParentComponent(Self);
    lbemp_LastModifiedBy.Name := 'lbemp_LastModifiedBy';
    lbemp_LastModifiedBy.Left := 24;
    lbemp_LastModifiedBy.Top := 678;
    lbemp_LastModifiedBy.Width := 102;
    lbemp_LastModifiedBy.Height := 13;
    lbemp_LastModifiedBy.Caption := 'emp_LastModifiedBy:';
    lbemp_LastModifiedBy.HeightPercent := 100.000000000000000000;
    lbemp_LastModifiedBy.WidthPercent := 100.000000000000000000;
    lbemp_LastModifiedDate.SetParentComponent(Self);
    lbemp_LastModifiedDate.Name := 'lbemp_LastModifiedDate';
    lbemp_LastModifiedDate.Left := 24;
    lbemp_LastModifiedDate.Top := 705;
    lbemp_LastModifiedDate.Width := 113;
    lbemp_LastModifiedDate.Height := 13;
    lbemp_LastModifiedDate.Caption := 'emp_LastModifiedDate:';
    lbemp_LastModifiedDate.HeightPercent := 100.000000000000000000;
    lbemp_LastModifiedDate.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 745;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 745;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 745;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edemp_Id.SetParentComponent(Self);
    edemp_Id.Name := 'edemp_Id';
    edemp_Id.Left := 221;
    edemp_Id.Top := 189;
    edemp_Id.Width := 200;
    edemp_Id.Height := 21;
    edemp_Id.HeightPercent := 100.000000000000000000;
    edemp_Id.TabOrder := 6;
    edemp_Id.Text := 'edemp_Id';
    edemp_Id.WidthPercent := 100.000000000000000000;
    edemp_Id.DataField := 'emp_Id';
    edemp_Id.DataSource := DataSource;
    edemp_Active.SetParentComponent(Self);
    edemp_Active.Name := 'edemp_Active';
    edemp_Active.Left := 221;
    edemp_Active.Top := 216;
    edemp_Active.Width := 200;
    edemp_Active.Height := 21;
    edemp_Active.HeightPercent := 100.000000000000000000;
    edemp_Active.TabOrder := 7;
    edemp_Active.Text := 'edemp_Active';
    edemp_Active.WidthPercent := 100.000000000000000000;
    edemp_Active.DataField := 'emp_Active';
    edemp_Active.DataSource := DataSource;
    edemp_FirstName.SetParentComponent(Self);
    edemp_FirstName.Name := 'edemp_FirstName';
    edemp_FirstName.Left := 221;
    edemp_FirstName.Top := 243;
    edemp_FirstName.Width := 200;
    edemp_FirstName.Height := 21;
    edemp_FirstName.HeightPercent := 100.000000000000000000;
    edemp_FirstName.TabOrder := 8;
    edemp_FirstName.Text := 'edemp_FirstName';
    edemp_FirstName.WidthPercent := 100.000000000000000000;
    edemp_FirstName.DataField := 'emp_FirstName';
    edemp_FirstName.DataSource := DataSource;
    edemp_LastName.SetParentComponent(Self);
    edemp_LastName.Name := 'edemp_LastName';
    edemp_LastName.Left := 221;
    edemp_LastName.Top := 270;
    edemp_LastName.Width := 200;
    edemp_LastName.Height := 21;
    edemp_LastName.HeightPercent := 100.000000000000000000;
    edemp_LastName.TabOrder := 9;
    edemp_LastName.Text := 'edemp_LastName';
    edemp_LastName.WidthPercent := 100.000000000000000000;
    edemp_LastName.DataField := 'emp_LastName';
    edemp_LastName.DataSource := DataSource;
    edemp_Adresse.SetParentComponent(Self);
    edemp_Adresse.Name := 'edemp_Adresse';
    edemp_Adresse.Left := 221;
    edemp_Adresse.Top := 297;
    edemp_Adresse.Width := 200;
    edemp_Adresse.Height := 21;
    edemp_Adresse.HeightPercent := 100.000000000000000000;
    edemp_Adresse.TabOrder := 10;
    edemp_Adresse.Text := 'edemp_Adresse';
    edemp_Adresse.WidthPercent := 100.000000000000000000;
    edemp_Adresse.DataField := 'emp_Adresse';
    edemp_Adresse.DataSource := DataSource;
    edemp_City.SetParentComponent(Self);
    edemp_City.Name := 'edemp_City';
    edemp_City.Left := 221;
    edemp_City.Top := 324;
    edemp_City.Width := 200;
    edemp_City.Height := 21;
    edemp_City.HeightPercent := 100.000000000000000000;
    edemp_City.TabOrder := 11;
    edemp_City.Text := 'edemp_City';
    edemp_City.WidthPercent := 100.000000000000000000;
    edemp_City.DataField := 'emp_City';
    edemp_City.DataSource := DataSource;
    edemp_PostalCode.SetParentComponent(Self);
    edemp_PostalCode.Name := 'edemp_PostalCode';
    edemp_PostalCode.Left := 221;
    edemp_PostalCode.Top := 351;
    edemp_PostalCode.Width := 200;
    edemp_PostalCode.Height := 21;
    edemp_PostalCode.HeightPercent := 100.000000000000000000;
    edemp_PostalCode.TabOrder := 12;
    edemp_PostalCode.Text := 'edemp_PostalCode';
    edemp_PostalCode.WidthPercent := 100.000000000000000000;
    edemp_PostalCode.DataField := 'emp_PostalCode';
    edemp_PostalCode.DataSource := DataSource;
    edemp_Telephone.SetParentComponent(Self);
    edemp_Telephone.Name := 'edemp_Telephone';
    edemp_Telephone.Left := 221;
    edemp_Telephone.Top := 378;
    edemp_Telephone.Width := 200;
    edemp_Telephone.Height := 21;
    edemp_Telephone.HeightPercent := 100.000000000000000000;
    edemp_Telephone.TabOrder := 13;
    edemp_Telephone.Text := 'edemp_Telephone';
    edemp_Telephone.WidthPercent := 100.000000000000000000;
    edemp_Telephone.DataField := 'emp_Telephone';
    edemp_Telephone.DataSource := DataSource;
    edemp_Email.SetParentComponent(Self);
    edemp_Email.Name := 'edemp_Email';
    edemp_Email.Left := 221;
    edemp_Email.Top := 405;
    edemp_Email.Width := 200;
    edemp_Email.Height := 21;
    edemp_Email.HeightPercent := 100.000000000000000000;
    edemp_Email.TabOrder := 14;
    edemp_Email.Text := 'edemp_Email';
    edemp_Email.WidthPercent := 100.000000000000000000;
    edemp_Email.DataField := 'emp_Email';
    edemp_Email.DataSource := DataSource;
    edemp_BirthDate.SetParentComponent(Self);
    edemp_BirthDate.Name := 'edemp_BirthDate';
    edemp_BirthDate.Left := 221;
    edemp_BirthDate.Top := 432;
    edemp_BirthDate.Width := 200;
    edemp_BirthDate.Height := 21;
    edemp_BirthDate.HeightPercent := 100.000000000000000000;
    edemp_BirthDate.TabOrder := 15;
    edemp_BirthDate.Text := 'edemp_BirthDate';
    edemp_BirthDate.WidthPercent := 100.000000000000000000;
    edemp_BirthDate.DataField := 'emp_BirthDate';
    edemp_BirthDate.DataSource := DataSource;
    edemp_SocialNumber.SetParentComponent(Self);
    edemp_SocialNumber.Name := 'edemp_SocialNumber';
    edemp_SocialNumber.Left := 221;
    edemp_SocialNumber.Top := 459;
    edemp_SocialNumber.Width := 200;
    edemp_SocialNumber.Height := 21;
    edemp_SocialNumber.HeightPercent := 100.000000000000000000;
    edemp_SocialNumber.TabOrder := 16;
    edemp_SocialNumber.Text := 'edemp_SocialNumber';
    edemp_SocialNumber.WidthPercent := 100.000000000000000000;
    edemp_SocialNumber.DataField := 'emp_SocialNumber';
    edemp_SocialNumber.DataSource := DataSource;
    edemp_Position.SetParentComponent(Self);
    edemp_Position.Name := 'edemp_Position';
    edemp_Position.Left := 221;
    edemp_Position.Top := 486;
    edemp_Position.Width := 200;
    edemp_Position.Height := 21;
    edemp_Position.HeightPercent := 100.000000000000000000;
    edemp_Position.TabOrder := 17;
    edemp_Position.Text := 'edemp_Position';
    edemp_Position.WidthPercent := 100.000000000000000000;
    edemp_Position.DataField := 'emp_Position';
    edemp_Position.DataSource := DataSource;
    edemp_HourlySal.SetParentComponent(Self);
    edemp_HourlySal.Name := 'edemp_HourlySal';
    edemp_HourlySal.Left := 221;
    edemp_HourlySal.Top := 513;
    edemp_HourlySal.Width := 200;
    edemp_HourlySal.Height := 21;
    edemp_HourlySal.HeightPercent := 100.000000000000000000;
    edemp_HourlySal.TabOrder := 18;
    edemp_HourlySal.Text := 'edemp_HourlySal';
    edemp_HourlySal.WidthPercent := 100.000000000000000000;
    edemp_HourlySal.DataField := 'emp_HourlySal';
    edemp_HourlySal.DataSource := DataSource;
    edemp_StartDate.SetParentComponent(Self);
    edemp_StartDate.Name := 'edemp_StartDate';
    edemp_StartDate.Left := 221;
    edemp_StartDate.Top := 540;
    edemp_StartDate.Width := 200;
    edemp_StartDate.Height := 21;
    edemp_StartDate.HeightPercent := 100.000000000000000000;
    edemp_StartDate.TabOrder := 19;
    edemp_StartDate.Text := 'edemp_StartDate';
    edemp_StartDate.WidthPercent := 100.000000000000000000;
    edemp_StartDate.DataField := 'emp_StartDate';
    edemp_StartDate.DataSource := DataSource;
    edemp_EndDate.SetParentComponent(Self);
    edemp_EndDate.Name := 'edemp_EndDate';
    edemp_EndDate.Left := 221;
    edemp_EndDate.Top := 567;
    edemp_EndDate.Width := 200;
    edemp_EndDate.Height := 21;
    edemp_EndDate.HeightPercent := 100.000000000000000000;
    edemp_EndDate.TabOrder := 20;
    edemp_EndDate.Text := 'edemp_EndDate';
    edemp_EndDate.WidthPercent := 100.000000000000000000;
    edemp_EndDate.DataField := 'emp_EndDate';
    edemp_EndDate.DataSource := DataSource;
    edemp_Notes.SetParentComponent(Self);
    edemp_Notes.Name := 'edemp_Notes';
    edemp_Notes.Left := 221;
    edemp_Notes.Top := 594;
    edemp_Notes.Width := 200;
    edemp_Notes.Height := 21;
    edemp_Notes.HeightPercent := 100.000000000000000000;
    edemp_Notes.TabOrder := 21;
    edemp_Notes.Text := 'edemp_Notes';
    edemp_Notes.WidthPercent := 100.000000000000000000;
    edemp_Notes.DataField := 'emp_Notes';
    edemp_Notes.DataSource := DataSource;
    edemp_CreatedBy.SetParentComponent(Self);
    edemp_CreatedBy.Name := 'edemp_CreatedBy';
    edemp_CreatedBy.Left := 221;
    edemp_CreatedBy.Top := 621;
    edemp_CreatedBy.Width := 200;
    edemp_CreatedBy.Height := 21;
    edemp_CreatedBy.HeightPercent := 100.000000000000000000;
    edemp_CreatedBy.TabOrder := 22;
    edemp_CreatedBy.Text := 'edemp_CreatedBy';
    edemp_CreatedBy.WidthPercent := 100.000000000000000000;
    edemp_CreatedBy.DataField := 'emp_CreatedBy';
    edemp_CreatedBy.DataSource := DataSource;
    edemp_CreatedDate.SetParentComponent(Self);
    edemp_CreatedDate.Name := 'edemp_CreatedDate';
    edemp_CreatedDate.Left := 221;
    edemp_CreatedDate.Top := 648;
    edemp_CreatedDate.Width := 200;
    edemp_CreatedDate.Height := 21;
    edemp_CreatedDate.HeightPercent := 100.000000000000000000;
    edemp_CreatedDate.TabOrder := 23;
    edemp_CreatedDate.Text := 'edemp_CreatedDate';
    edemp_CreatedDate.WidthPercent := 100.000000000000000000;
    edemp_CreatedDate.DataField := 'emp_CreatedDate';
    edemp_CreatedDate.DataSource := DataSource;
    edemp_LastModifiedBy.SetParentComponent(Self);
    edemp_LastModifiedBy.Name := 'edemp_LastModifiedBy';
    edemp_LastModifiedBy.Left := 221;
    edemp_LastModifiedBy.Top := 675;
    edemp_LastModifiedBy.Width := 200;
    edemp_LastModifiedBy.Height := 21;
    edemp_LastModifiedBy.HeightPercent := 100.000000000000000000;
    edemp_LastModifiedBy.TabOrder := 24;
    edemp_LastModifiedBy.Text := 'edemp_LastModifiedBy';
    edemp_LastModifiedBy.WidthPercent := 100.000000000000000000;
    edemp_LastModifiedBy.DataField := 'emp_LastModifiedBy';
    edemp_LastModifiedBy.DataSource := DataSource;
    edemp_LastModifiedDate.SetParentComponent(Self);
    edemp_LastModifiedDate.Name := 'edemp_LastModifiedDate';
    edemp_LastModifiedDate.Left := 221;
    edemp_LastModifiedDate.Top := 702;
    edemp_LastModifiedDate.Width := 200;
    edemp_LastModifiedDate.Height := 21;
    edemp_LastModifiedDate.HeightPercent := 100.000000000000000000;
    edemp_LastModifiedDate.TabOrder := 25;
    edemp_LastModifiedDate.Text := 'edemp_LastModifiedDate';
    edemp_LastModifiedDate.WidthPercent := 100.000000000000000000;
    edemp_LastModifiedDate.DataField := 'emp_LastModifiedDate';
    edemp_LastModifiedDate.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := EmployeeDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    EmployeeDataset.SetParentComponent(Self);
    EmployeeDataset.Name := 'EmployeeDataset';
    EmployeeDataset.AfterApplyUpdates := EmployeeDatasetAfterApplyUpdates;
    EmployeeDataset.EntitySetName := 'Employee';
    EmployeeDataset.Connection := DMConnection.ApiConnection;
    EmployeeDataset.Left := 176;
    EmployeeDataset.Top := 8;
    EmployeeDatasetemp_Id.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_Id.Name := 'EmployeeDatasetemp_Id';
    EmployeeDatasetemp_Id.FieldName := 'emp_Id';
    EmployeeDatasetemp_Id.Required := True;
    EmployeeDatasetemp_Active.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_Active.Name := 'EmployeeDatasetemp_Active';
    EmployeeDatasetemp_Active.FieldName := 'emp_Active';
    EmployeeDatasetemp_Active.Size := 255;
    EmployeeDatasetemp_FirstName.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_FirstName.Name := 'EmployeeDatasetemp_FirstName';
    EmployeeDatasetemp_FirstName.FieldName := 'emp_FirstName';
    EmployeeDatasetemp_FirstName.Size := 50;
    EmployeeDatasetemp_LastName.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_LastName.Name := 'EmployeeDatasetemp_LastName';
    EmployeeDatasetemp_LastName.FieldName := 'emp_LastName';
    EmployeeDatasetemp_LastName.Size := 50;
    EmployeeDatasetemp_Adresse.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_Adresse.Name := 'EmployeeDatasetemp_Adresse';
    EmployeeDatasetemp_Adresse.FieldName := 'emp_Adresse';
    EmployeeDatasetemp_Adresse.Size := 150;
    EmployeeDatasetemp_City.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_City.Name := 'EmployeeDatasetemp_City';
    EmployeeDatasetemp_City.FieldName := 'emp_City';
    EmployeeDatasetemp_City.Size := 100;
    EmployeeDatasetemp_PostalCode.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_PostalCode.Name := 'EmployeeDatasetemp_PostalCode';
    EmployeeDatasetemp_PostalCode.FieldName := 'emp_PostalCode';
    EmployeeDatasetemp_PostalCode.Size := 50;
    EmployeeDatasetemp_Telephone.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_Telephone.Name := 'EmployeeDatasetemp_Telephone';
    EmployeeDatasetemp_Telephone.FieldName := 'emp_Telephone';
    EmployeeDatasetemp_Telephone.Size := 50;
    EmployeeDatasetemp_Email.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_Email.Name := 'EmployeeDatasetemp_Email';
    EmployeeDatasetemp_Email.FieldName := 'emp_Email';
    EmployeeDatasetemp_Email.Size := 100;
    EmployeeDatasetemp_BirthDate.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_BirthDate.Name := 'EmployeeDatasetemp_BirthDate';
    EmployeeDatasetemp_BirthDate.FieldName := 'emp_BirthDate';
    EmployeeDatasetemp_SocialNumber.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_SocialNumber.Name := 'EmployeeDatasetemp_SocialNumber';
    EmployeeDatasetemp_SocialNumber.FieldName := 'emp_SocialNumber';
    EmployeeDatasetemp_SocialNumber.Size := 50;
    EmployeeDatasetemp_Position.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_Position.Name := 'EmployeeDatasetemp_Position';
    EmployeeDatasetemp_Position.FieldName := 'emp_Position';
    EmployeeDatasetemp_Position.Size := 50;
    EmployeeDatasetemp_HourlySal.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_HourlySal.Name := 'EmployeeDatasetemp_HourlySal';
    EmployeeDatasetemp_HourlySal.FieldName := 'emp_HourlySal';
    EmployeeDatasetemp_StartDate.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_StartDate.Name := 'EmployeeDatasetemp_StartDate';
    EmployeeDatasetemp_StartDate.FieldName := 'emp_StartDate';
    EmployeeDatasetemp_EndDate.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_EndDate.Name := 'EmployeeDatasetemp_EndDate';
    EmployeeDatasetemp_EndDate.FieldName := 'emp_EndDate';
    EmployeeDatasetemp_Notes.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_Notes.Name := 'EmployeeDatasetemp_Notes';
    EmployeeDatasetemp_Notes.FieldName := 'emp_Notes';
    EmployeeDatasetemp_Notes.Size := 500;
    EmployeeDatasetemp_CreatedBy.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_CreatedBy.Name := 'EmployeeDatasetemp_CreatedBy';
    EmployeeDatasetemp_CreatedBy.FieldName := 'emp_CreatedBy';
    EmployeeDatasetemp_CreatedDate.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_CreatedDate.Name := 'EmployeeDatasetemp_CreatedDate';
    EmployeeDatasetemp_CreatedDate.FieldName := 'emp_CreatedDate';
    EmployeeDatasetemp_LastModifiedBy.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_LastModifiedBy.Name := 'EmployeeDatasetemp_LastModifiedBy';
    EmployeeDatasetemp_LastModifiedBy.FieldName := 'emp_LastModifiedBy';
    EmployeeDatasetemp_LastModifiedDate.SetParentComponent(EmployeeDataset);
    EmployeeDatasetemp_LastModifiedDate.Name := 'EmployeeDatasetemp_LastModifiedDate';
    EmployeeDatasetemp_LastModifiedDate.FieldName := 'emp_LastModifiedDate';
    XDataClientEmployeeGet.SetParentComponent(Self);
    XDataClientEmployeeGet.Name := 'XDataClientEmployeeGet';
    XDataClientEmployeeGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientEmployeeGet, Self, 'OnLoad', 'XDataClientEmployeeGetLoad');
    XDataClientEmployeeGet.Left := 64;
    XDataClientEmployeeGet.Top := 8;
    XDataClientEmployeeDelete.SetParentComponent(Self);
    XDataClientEmployeeDelete.Name := 'XDataClientEmployeeDelete';
    XDataClientEmployeeDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientEmployeeDelete, Self, 'OnLoad', 'XDataClientEmployeeDeleteLoad');
    SetEvent(XDataClientEmployeeDelete, Self, 'OnError', 'XDataClientEmployeeDeleteError');
    XDataClientEmployeeDelete.Left := 64;
    XDataClientEmployeeDelete.Top := 56;
  finally
    lbemp_Id.AfterLoadDFMValues;
    lbemp_Active.AfterLoadDFMValues;
    lbemp_FirstName.AfterLoadDFMValues;
    lbemp_LastName.AfterLoadDFMValues;
    lbemp_Adresse.AfterLoadDFMValues;
    lbemp_City.AfterLoadDFMValues;
    lbemp_PostalCode.AfterLoadDFMValues;
    lbemp_Telephone.AfterLoadDFMValues;
    lbemp_Email.AfterLoadDFMValues;
    lbemp_BirthDate.AfterLoadDFMValues;
    lbemp_SocialNumber.AfterLoadDFMValues;
    lbemp_Position.AfterLoadDFMValues;
    lbemp_HourlySal.AfterLoadDFMValues;
    lbemp_StartDate.AfterLoadDFMValues;
    lbemp_EndDate.AfterLoadDFMValues;
    lbemp_Notes.AfterLoadDFMValues;
    lbemp_CreatedBy.AfterLoadDFMValues;
    lbemp_CreatedDate.AfterLoadDFMValues;
    lbemp_LastModifiedBy.AfterLoadDFMValues;
    lbemp_LastModifiedDate.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edemp_Id.AfterLoadDFMValues;
    edemp_Active.AfterLoadDFMValues;
    edemp_FirstName.AfterLoadDFMValues;
    edemp_LastName.AfterLoadDFMValues;
    edemp_Adresse.AfterLoadDFMValues;
    edemp_City.AfterLoadDFMValues;
    edemp_PostalCode.AfterLoadDFMValues;
    edemp_Telephone.AfterLoadDFMValues;
    edemp_Email.AfterLoadDFMValues;
    edemp_BirthDate.AfterLoadDFMValues;
    edemp_SocialNumber.AfterLoadDFMValues;
    edemp_Position.AfterLoadDFMValues;
    edemp_HourlySal.AfterLoadDFMValues;
    edemp_StartDate.AfterLoadDFMValues;
    edemp_EndDate.AfterLoadDFMValues;
    edemp_Notes.AfterLoadDFMValues;
    edemp_CreatedBy.AfterLoadDFMValues;
    edemp_CreatedDate.AfterLoadDFMValues;
    edemp_LastModifiedBy.AfterLoadDFMValues;
    edemp_LastModifiedDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    EmployeeDataset.AfterLoadDFMValues;
    EmployeeDatasetemp_Id.AfterLoadDFMValues;
    EmployeeDatasetemp_Active.AfterLoadDFMValues;
    EmployeeDatasetemp_FirstName.AfterLoadDFMValues;
    EmployeeDatasetemp_LastName.AfterLoadDFMValues;
    EmployeeDatasetemp_Adresse.AfterLoadDFMValues;
    EmployeeDatasetemp_City.AfterLoadDFMValues;
    EmployeeDatasetemp_PostalCode.AfterLoadDFMValues;
    EmployeeDatasetemp_Telephone.AfterLoadDFMValues;
    EmployeeDatasetemp_Email.AfterLoadDFMValues;
    EmployeeDatasetemp_BirthDate.AfterLoadDFMValues;
    EmployeeDatasetemp_SocialNumber.AfterLoadDFMValues;
    EmployeeDatasetemp_Position.AfterLoadDFMValues;
    EmployeeDatasetemp_HourlySal.AfterLoadDFMValues;
    EmployeeDatasetemp_StartDate.AfterLoadDFMValues;
    EmployeeDatasetemp_EndDate.AfterLoadDFMValues;
    EmployeeDatasetemp_Notes.AfterLoadDFMValues;
    EmployeeDatasetemp_CreatedBy.AfterLoadDFMValues;
    EmployeeDatasetemp_CreatedDate.AfterLoadDFMValues;
    EmployeeDatasetemp_LastModifiedBy.AfterLoadDFMValues;
    EmployeeDatasetemp_LastModifiedDate.AfterLoadDFMValues;
    XDataClientEmployeeGet.AfterLoadDFMValues;
    XDataClientEmployeeDelete.AfterLoadDFMValues;
  end;
end;

end.
