unit View.Users.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewUsersEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbusr_Id: TLabel;
    edusr_Id: TDBEdit;
    lbusr_Login: TLabel;
    edusr_Login: TDBEdit;
    lbusr_Pwd: TLabel;
    edusr_Pwd: TDBEdit;
    lbusr_Active: TLabel;
    edusr_Active: TDBEdit;
    lbusr_FullName: TLabel;
    edusr_FullName: TDBEdit;
    lbemp_Id: TLabel;
    edemp_Id: TDBEdit;
    lbcli_Id: TLabel;
    edcli_Id: TDBEdit;
    lbusr_Type: TLabel;
    edusr_Type: TDBEdit;
    lbusr_Level: TLabel;
    edusr_Level: TDBEdit;
    lbusr_CreatedBy: TLabel;
    edusr_CreatedBy: TDBEdit;
    lbusr_CreatedDate: TLabel;
    edusr_CreatedDate: TDBEdit;
    lbusr_LastModifiedBy: TLabel;
    edusr_LastModifiedBy: TDBEdit;
    lbusr_LastModifiedDate: TLabel;
    edusr_LastModifiedDate: TDBEdit;
    XDataClientUsersGet: TXDataWebClient;
    XDataClientUsersDelete: TXDataWebClient;
    UsersDataset: TXDataWebDataSet;
    UsersDatasetusr_Id: TIntegerField;
    UsersDatasetusr_Login: TStringField;
    UsersDatasetusr_Pwd: TStringField;
    UsersDatasetusr_Active: TIntegerField;
    UsersDatasetusr_FullName: TStringField;
    UsersDatasetemp_Id: TIntegerField;
    UsersDatasetcli_Id: TIntegerField;
    UsersDatasetusr_Type: TIntegerField;
    UsersDatasetusr_Level: TIntegerField;
    UsersDatasetusr_CreatedBy: TIntegerField;
    UsersDatasetusr_CreatedDate: TDateTimeField;
    UsersDatasetusr_LastModifiedBy: TIntegerField;
    UsersDatasetusr_LastModifiedDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientUsersGetLoad(Response: TXDataClientResponse);
    procedure XDataClientUsersDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientUsersDeleteError(Error: TXDataClientError);
    procedure UsersDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewUsersEdit: TFViewUsersEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewUsersEdit }

procedure TFViewUsersEdit.btSaveClick(Sender: TObject);
begin
  if UsersDataset.State in dsEditModes then
  begin
        UsersDataset.Post;
    UsersDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewUsersEdit.btDeleteClick(Sender: TObject);
begin
  if UsersDataset.State in dsEditModes then
    UsersDataset.Post;
  XDataClientUsersDelete.Delete(UsersDataset.EntitySetName,
    TJSObject(UsersDataset.CurrentData));
end;


class function TFViewUsersEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewUsersEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewUsersEdit.InsertEntity;
begin
  UsersDataset.Close;
  UsersDataset.SetJsonData('{}');
  UsersDataset.Open;
  UsersDataset.Insert;
end;

class function TFViewUsersEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewUsersEdit(AForm).FInserting := AInserting;
    TFViewUsersEdit(AForm).FId := AId;
    TFViewUsersEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewUsersEdit(AForm).InsertEntity
    else
      TFViewUsersEdit(AForm).LoadEntity;
    TFViewUsersEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewUsersEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewUsersEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    UsersDataset.Connection.Model.DefaultSchema.FindEntityType(
      UsersDataset.EntitySetName
    )
  );
  XDataClientUsersGet.Get(UsersDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewUsersEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewUsersEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewUsersEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewUsersEdit.XDataClientUsersGetLoad(Response: TXDataClientResponse);
begin
  UsersDataset.Close;
  UsersDataset.SetJsonData(Response.Result);
  UsersDataset.Open;
  
  UsersDataset.Edit;  
end;

procedure TFViewUsersEdit.XDataClientUsersDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewUsersEdit.XDataClientUsersDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewUsersEdit.UsersDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewUsersEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewUsersEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbusr_Id := TLabel.Create('view.users.form.lbusr_Id');
  lbusr_Login := TLabel.Create('view.users.form.lbusr_Login');
  lbusr_Pwd := TLabel.Create('view.users.form.lbusr_Pwd');
  lbusr_Active := TLabel.Create('view.users.form.lbusr_Active');
  lbusr_FullName := TLabel.Create('view.users.form.lbusr_FullName');
  lbemp_Id := TLabel.Create('view.users.form.lbemp_Id');
  lbcli_Id := TLabel.Create('view.users.form.lbcli_Id');
  lbusr_Type := TLabel.Create('view.users.form.lbusr_Type');
  lbusr_Level := TLabel.Create('view.users.form.lbusr_Level');
  lbusr_CreatedBy := TLabel.Create('view.users.form.lbusr_CreatedBy');
  lbusr_CreatedDate := TLabel.Create('view.users.form.lbusr_CreatedDate');
  lbusr_LastModifiedBy := TLabel.Create('view.users.form.lbusr_LastModifiedBy');
  lbusr_LastModifiedDate := TLabel.Create('view.users.form.lbusr_LastModifiedDate');
  pnlMessage := TPanel.Create('view.users.form.message');
  lbMessage := TLabel.Create('view.users.form.message.label');
  btCloseMessage := TButton.Create('view.users.form.message.button');
  btCancel := TButton.Create('view.users.form.btBack');
  btSave := TButton.Create('view.users.form.btSave');
  btDelete := TButton.Create('view.users.form.btDelete');
  edusr_Id := TDBEdit.Create('view.users.form.edusr_Id');
  edusr_Login := TDBEdit.Create('view.users.form.edusr_Login');
  edusr_Pwd := TDBEdit.Create('view.users.form.edusr_Pwd');
  edusr_Active := TDBEdit.Create('view.users.form.edusr_Active');
  edusr_FullName := TDBEdit.Create('view.users.form.edusr_FullName');
  edemp_Id := TDBEdit.Create('view.users.form.edemp_Id');
  edcli_Id := TDBEdit.Create('view.users.form.edcli_Id');
  edusr_Type := TDBEdit.Create('view.users.form.edusr_Type');
  edusr_Level := TDBEdit.Create('view.users.form.edusr_Level');
  edusr_CreatedBy := TDBEdit.Create('view.users.form.edusr_CreatedBy');
  edusr_CreatedDate := TDBEdit.Create('view.users.form.edusr_CreatedDate');
  edusr_LastModifiedBy := TDBEdit.Create('view.users.form.edusr_LastModifiedBy');
  edusr_LastModifiedDate := TDBEdit.Create('view.users.form.edusr_LastModifiedDate');
  DataSource := TDataSource.Create(Self);
  UsersDataset := TXDataWebDataSet.Create(Self);
  UsersDatasetusr_Id := TIntegerField.Create(Self);
  UsersDatasetusr_Login := TStringField.Create(Self);
  UsersDatasetusr_Pwd := TStringField.Create(Self);
  UsersDatasetusr_Active := TIntegerField.Create(Self);
  UsersDatasetusr_FullName := TStringField.Create(Self);
  UsersDatasetemp_Id := TIntegerField.Create(Self);
  UsersDatasetcli_Id := TIntegerField.Create(Self);
  UsersDatasetusr_Type := TIntegerField.Create(Self);
  UsersDatasetusr_Level := TIntegerField.Create(Self);
  UsersDatasetusr_CreatedBy := TIntegerField.Create(Self);
  UsersDatasetusr_CreatedDate := TDateTimeField.Create(Self);
  UsersDatasetusr_LastModifiedBy := TIntegerField.Create(Self);
  UsersDatasetusr_LastModifiedDate := TDateTimeField.Create(Self);
  XDataClientUsersGet := TXDataWebClient.Create(Self);
  XDataClientUsersDelete := TXDataWebClient.Create(Self);

  lbusr_Id.BeforeLoadDFMValues;
  lbusr_Login.BeforeLoadDFMValues;
  lbusr_Pwd.BeforeLoadDFMValues;
  lbusr_Active.BeforeLoadDFMValues;
  lbusr_FullName.BeforeLoadDFMValues;
  lbemp_Id.BeforeLoadDFMValues;
  lbcli_Id.BeforeLoadDFMValues;
  lbusr_Type.BeforeLoadDFMValues;
  lbusr_Level.BeforeLoadDFMValues;
  lbusr_CreatedBy.BeforeLoadDFMValues;
  lbusr_CreatedDate.BeforeLoadDFMValues;
  lbusr_LastModifiedBy.BeforeLoadDFMValues;
  lbusr_LastModifiedDate.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edusr_Id.BeforeLoadDFMValues;
  edusr_Login.BeforeLoadDFMValues;
  edusr_Pwd.BeforeLoadDFMValues;
  edusr_Active.BeforeLoadDFMValues;
  edusr_FullName.BeforeLoadDFMValues;
  edemp_Id.BeforeLoadDFMValues;
  edcli_Id.BeforeLoadDFMValues;
  edusr_Type.BeforeLoadDFMValues;
  edusr_Level.BeforeLoadDFMValues;
  edusr_CreatedBy.BeforeLoadDFMValues;
  edusr_CreatedDate.BeforeLoadDFMValues;
  edusr_LastModifiedBy.BeforeLoadDFMValues;
  edusr_LastModifiedDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  UsersDataset.BeforeLoadDFMValues;
  UsersDatasetusr_Id.BeforeLoadDFMValues;
  UsersDatasetusr_Login.BeforeLoadDFMValues;
  UsersDatasetusr_Pwd.BeforeLoadDFMValues;
  UsersDatasetusr_Active.BeforeLoadDFMValues;
  UsersDatasetusr_FullName.BeforeLoadDFMValues;
  UsersDatasetemp_Id.BeforeLoadDFMValues;
  UsersDatasetcli_Id.BeforeLoadDFMValues;
  UsersDatasetusr_Type.BeforeLoadDFMValues;
  UsersDatasetusr_Level.BeforeLoadDFMValues;
  UsersDatasetusr_CreatedBy.BeforeLoadDFMValues;
  UsersDatasetusr_CreatedDate.BeforeLoadDFMValues;
  UsersDatasetusr_LastModifiedBy.BeforeLoadDFMValues;
  UsersDatasetusr_LastModifiedDate.BeforeLoadDFMValues;
  XDataClientUsersGet.BeforeLoadDFMValues;
  XDataClientUsersDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewUsersEdit';
    Width := 593;
    Height := 616;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbusr_Id.SetParentComponent(Self);
    lbusr_Id.Name := 'lbusr_Id';
    lbusr_Id.Left := 24;
    lbusr_Id.Top := 192;
    lbusr_Id.Width := 35;
    lbusr_Id.Height := 13;
    lbusr_Id.Caption := 'usr_Id:';
    lbusr_Id.HeightPercent := 100.000000000000000000;
    lbusr_Id.WidthPercent := 100.000000000000000000;
    lbusr_Login.SetParentComponent(Self);
    lbusr_Login.Name := 'lbusr_Login';
    lbusr_Login.Left := 24;
    lbusr_Login.Top := 219;
    lbusr_Login.Width := 50;
    lbusr_Login.Height := 13;
    lbusr_Login.Caption := 'usr_Login:';
    lbusr_Login.HeightPercent := 100.000000000000000000;
    lbusr_Login.WidthPercent := 100.000000000000000000;
    lbusr_Pwd.SetParentComponent(Self);
    lbusr_Pwd.Name := 'lbusr_Pwd';
    lbusr_Pwd.Left := 24;
    lbusr_Pwd.Top := 246;
    lbusr_Pwd.Width := 45;
    lbusr_Pwd.Height := 13;
    lbusr_Pwd.Caption := 'usr_Pwd:';
    lbusr_Pwd.HeightPercent := 100.000000000000000000;
    lbusr_Pwd.WidthPercent := 100.000000000000000000;
    lbusr_Active.SetParentComponent(Self);
    lbusr_Active.Name := 'lbusr_Active';
    lbusr_Active.Left := 24;
    lbusr_Active.Top := 273;
    lbusr_Active.Width := 55;
    lbusr_Active.Height := 13;
    lbusr_Active.Caption := 'usr_Active:';
    lbusr_Active.HeightPercent := 100.000000000000000000;
    lbusr_Active.WidthPercent := 100.000000000000000000;
    lbusr_FullName.SetParentComponent(Self);
    lbusr_FullName.Name := 'lbusr_FullName';
    lbusr_FullName.Left := 24;
    lbusr_FullName.Top := 300;
    lbusr_FullName.Width := 68;
    lbusr_FullName.Height := 13;
    lbusr_FullName.Caption := 'usr_FullName:';
    lbusr_FullName.HeightPercent := 100.000000000000000000;
    lbusr_FullName.WidthPercent := 100.000000000000000000;
    lbemp_Id.SetParentComponent(Self);
    lbemp_Id.Name := 'lbemp_Id';
    lbemp_Id.Left := 24;
    lbemp_Id.Top := 327;
    lbemp_Id.Width := 40;
    lbemp_Id.Height := 13;
    lbemp_Id.Caption := 'emp_Id:';
    lbemp_Id.HeightPercent := 100.000000000000000000;
    lbemp_Id.WidthPercent := 100.000000000000000000;
    lbcli_Id.SetParentComponent(Self);
    lbcli_Id.Name := 'lbcli_Id';
    lbcli_Id.Left := 24;
    lbcli_Id.Top := 354;
    lbcli_Id.Width := 29;
    lbcli_Id.Height := 13;
    lbcli_Id.Caption := 'cli_Id:';
    lbcli_Id.HeightPercent := 100.000000000000000000;
    lbcli_Id.WidthPercent := 100.000000000000000000;
    lbusr_Type.SetParentComponent(Self);
    lbusr_Type.Name := 'lbusr_Type';
    lbusr_Type.Left := 24;
    lbusr_Type.Top := 381;
    lbusr_Type.Width := 49;
    lbusr_Type.Height := 13;
    lbusr_Type.Caption := 'usr_Type:';
    lbusr_Type.HeightPercent := 100.000000000000000000;
    lbusr_Type.WidthPercent := 100.000000000000000000;
    lbusr_Level.SetParentComponent(Self);
    lbusr_Level.Name := 'lbusr_Level';
    lbusr_Level.Left := 24;
    lbusr_Level.Top := 408;
    lbusr_Level.Width := 50;
    lbusr_Level.Height := 13;
    lbusr_Level.Caption := 'usr_Level:';
    lbusr_Level.HeightPercent := 100.000000000000000000;
    lbusr_Level.WidthPercent := 100.000000000000000000;
    lbusr_CreatedBy.SetParentComponent(Self);
    lbusr_CreatedBy.Name := 'lbusr_CreatedBy';
    lbusr_CreatedBy.Left := 24;
    lbusr_CreatedBy.Top := 435;
    lbusr_CreatedBy.Width := 76;
    lbusr_CreatedBy.Height := 13;
    lbusr_CreatedBy.Caption := 'usr_CreatedBy:';
    lbusr_CreatedBy.HeightPercent := 100.000000000000000000;
    lbusr_CreatedBy.WidthPercent := 100.000000000000000000;
    lbusr_CreatedDate.SetParentComponent(Self);
    lbusr_CreatedDate.Name := 'lbusr_CreatedDate';
    lbusr_CreatedDate.Left := 24;
    lbusr_CreatedDate.Top := 462;
    lbusr_CreatedDate.Width := 87;
    lbusr_CreatedDate.Height := 13;
    lbusr_CreatedDate.Caption := 'usr_CreatedDate:';
    lbusr_CreatedDate.HeightPercent := 100.000000000000000000;
    lbusr_CreatedDate.WidthPercent := 100.000000000000000000;
    lbusr_LastModifiedBy.SetParentComponent(Self);
    lbusr_LastModifiedBy.Name := 'lbusr_LastModifiedBy';
    lbusr_LastModifiedBy.Left := 24;
    lbusr_LastModifiedBy.Top := 489;
    lbusr_LastModifiedBy.Width := 97;
    lbusr_LastModifiedBy.Height := 13;
    lbusr_LastModifiedBy.Caption := 'usr_LastModifiedBy:';
    lbusr_LastModifiedBy.HeightPercent := 100.000000000000000000;
    lbusr_LastModifiedBy.WidthPercent := 100.000000000000000000;
    lbusr_LastModifiedDate.SetParentComponent(Self);
    lbusr_LastModifiedDate.Name := 'lbusr_LastModifiedDate';
    lbusr_LastModifiedDate.Left := 24;
    lbusr_LastModifiedDate.Top := 516;
    lbusr_LastModifiedDate.Width := 108;
    lbusr_LastModifiedDate.Height := 13;
    lbusr_LastModifiedDate.Caption := 'usr_LastModifiedDate:';
    lbusr_LastModifiedDate.HeightPercent := 100.000000000000000000;
    lbusr_LastModifiedDate.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 556;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 556;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 556;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edusr_Id.SetParentComponent(Self);
    edusr_Id.Name := 'edusr_Id';
    edusr_Id.Left := 221;
    edusr_Id.Top := 189;
    edusr_Id.Width := 200;
    edusr_Id.Height := 21;
    edusr_Id.HeightPercent := 100.000000000000000000;
    edusr_Id.TabOrder := 6;
    edusr_Id.Text := 'edusr_Id';
    edusr_Id.WidthPercent := 100.000000000000000000;
    edusr_Id.DataField := 'usr_Id';
    edusr_Id.DataSource := DataSource;
    edusr_Login.SetParentComponent(Self);
    edusr_Login.Name := 'edusr_Login';
    edusr_Login.Left := 221;
    edusr_Login.Top := 216;
    edusr_Login.Width := 200;
    edusr_Login.Height := 21;
    edusr_Login.HeightPercent := 100.000000000000000000;
    edusr_Login.TabOrder := 7;
    edusr_Login.Text := 'edusr_Login';
    edusr_Login.WidthPercent := 100.000000000000000000;
    edusr_Login.DataField := 'usr_Login';
    edusr_Login.DataSource := DataSource;
    edusr_Pwd.SetParentComponent(Self);
    edusr_Pwd.Name := 'edusr_Pwd';
    edusr_Pwd.Left := 221;
    edusr_Pwd.Top := 243;
    edusr_Pwd.Width := 200;
    edusr_Pwd.Height := 21;
    edusr_Pwd.HeightPercent := 100.000000000000000000;
    edusr_Pwd.TabOrder := 8;
    edusr_Pwd.Text := 'edusr_Pwd';
    edusr_Pwd.WidthPercent := 100.000000000000000000;
    edusr_Pwd.DataField := 'usr_Pwd';
    edusr_Pwd.DataSource := DataSource;
    edusr_Active.SetParentComponent(Self);
    edusr_Active.Name := 'edusr_Active';
    edusr_Active.Left := 221;
    edusr_Active.Top := 270;
    edusr_Active.Width := 200;
    edusr_Active.Height := 21;
    edusr_Active.HeightPercent := 100.000000000000000000;
    edusr_Active.TabOrder := 9;
    edusr_Active.Text := 'edusr_Active';
    edusr_Active.WidthPercent := 100.000000000000000000;
    edusr_Active.DataField := 'usr_Active';
    edusr_Active.DataSource := DataSource;
    edusr_FullName.SetParentComponent(Self);
    edusr_FullName.Name := 'edusr_FullName';
    edusr_FullName.Left := 221;
    edusr_FullName.Top := 297;
    edusr_FullName.Width := 200;
    edusr_FullName.Height := 21;
    edusr_FullName.HeightPercent := 100.000000000000000000;
    edusr_FullName.TabOrder := 10;
    edusr_FullName.Text := 'edusr_FullName';
    edusr_FullName.WidthPercent := 100.000000000000000000;
    edusr_FullName.DataField := 'usr_FullName';
    edusr_FullName.DataSource := DataSource;
    edemp_Id.SetParentComponent(Self);
    edemp_Id.Name := 'edemp_Id';
    edemp_Id.Left := 221;
    edemp_Id.Top := 324;
    edemp_Id.Width := 200;
    edemp_Id.Height := 21;
    edemp_Id.HeightPercent := 100.000000000000000000;
    edemp_Id.TabOrder := 11;
    edemp_Id.Text := 'edemp_Id';
    edemp_Id.WidthPercent := 100.000000000000000000;
    edemp_Id.DataField := 'emp_Id';
    edemp_Id.DataSource := DataSource;
    edcli_Id.SetParentComponent(Self);
    edcli_Id.Name := 'edcli_Id';
    edcli_Id.Left := 221;
    edcli_Id.Top := 351;
    edcli_Id.Width := 200;
    edcli_Id.Height := 21;
    edcli_Id.HeightPercent := 100.000000000000000000;
    edcli_Id.TabOrder := 12;
    edcli_Id.Text := 'edcli_Id';
    edcli_Id.WidthPercent := 100.000000000000000000;
    edcli_Id.DataField := 'cli_Id';
    edcli_Id.DataSource := DataSource;
    edusr_Type.SetParentComponent(Self);
    edusr_Type.Name := 'edusr_Type';
    edusr_Type.Left := 221;
    edusr_Type.Top := 378;
    edusr_Type.Width := 200;
    edusr_Type.Height := 21;
    edusr_Type.HeightPercent := 100.000000000000000000;
    edusr_Type.TabOrder := 13;
    edusr_Type.Text := 'edusr_Type';
    edusr_Type.WidthPercent := 100.000000000000000000;
    edusr_Type.DataField := 'usr_Type';
    edusr_Type.DataSource := DataSource;
    edusr_Level.SetParentComponent(Self);
    edusr_Level.Name := 'edusr_Level';
    edusr_Level.Left := 221;
    edusr_Level.Top := 405;
    edusr_Level.Width := 200;
    edusr_Level.Height := 21;
    edusr_Level.HeightPercent := 100.000000000000000000;
    edusr_Level.TabOrder := 14;
    edusr_Level.Text := 'edusr_Level';
    edusr_Level.WidthPercent := 100.000000000000000000;
    edusr_Level.DataField := 'usr_Level';
    edusr_Level.DataSource := DataSource;
    edusr_CreatedBy.SetParentComponent(Self);
    edusr_CreatedBy.Name := 'edusr_CreatedBy';
    edusr_CreatedBy.Left := 221;
    edusr_CreatedBy.Top := 432;
    edusr_CreatedBy.Width := 200;
    edusr_CreatedBy.Height := 21;
    edusr_CreatedBy.HeightPercent := 100.000000000000000000;
    edusr_CreatedBy.TabOrder := 15;
    edusr_CreatedBy.Text := 'edusr_CreatedBy';
    edusr_CreatedBy.WidthPercent := 100.000000000000000000;
    edusr_CreatedBy.DataField := 'usr_CreatedBy';
    edusr_CreatedBy.DataSource := DataSource;
    edusr_CreatedDate.SetParentComponent(Self);
    edusr_CreatedDate.Name := 'edusr_CreatedDate';
    edusr_CreatedDate.Left := 221;
    edusr_CreatedDate.Top := 459;
    edusr_CreatedDate.Width := 200;
    edusr_CreatedDate.Height := 21;
    edusr_CreatedDate.HeightPercent := 100.000000000000000000;
    edusr_CreatedDate.TabOrder := 16;
    edusr_CreatedDate.Text := 'edusr_CreatedDate';
    edusr_CreatedDate.WidthPercent := 100.000000000000000000;
    edusr_CreatedDate.DataField := 'usr_CreatedDate';
    edusr_CreatedDate.DataSource := DataSource;
    edusr_LastModifiedBy.SetParentComponent(Self);
    edusr_LastModifiedBy.Name := 'edusr_LastModifiedBy';
    edusr_LastModifiedBy.Left := 221;
    edusr_LastModifiedBy.Top := 486;
    edusr_LastModifiedBy.Width := 200;
    edusr_LastModifiedBy.Height := 21;
    edusr_LastModifiedBy.HeightPercent := 100.000000000000000000;
    edusr_LastModifiedBy.TabOrder := 17;
    edusr_LastModifiedBy.Text := 'edusr_LastModifiedBy';
    edusr_LastModifiedBy.WidthPercent := 100.000000000000000000;
    edusr_LastModifiedBy.DataField := 'usr_LastModifiedBy';
    edusr_LastModifiedBy.DataSource := DataSource;
    edusr_LastModifiedDate.SetParentComponent(Self);
    edusr_LastModifiedDate.Name := 'edusr_LastModifiedDate';
    edusr_LastModifiedDate.Left := 221;
    edusr_LastModifiedDate.Top := 513;
    edusr_LastModifiedDate.Width := 200;
    edusr_LastModifiedDate.Height := 21;
    edusr_LastModifiedDate.HeightPercent := 100.000000000000000000;
    edusr_LastModifiedDate.TabOrder := 18;
    edusr_LastModifiedDate.Text := 'edusr_LastModifiedDate';
    edusr_LastModifiedDate.WidthPercent := 100.000000000000000000;
    edusr_LastModifiedDate.DataField := 'usr_LastModifiedDate';
    edusr_LastModifiedDate.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := UsersDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    UsersDataset.SetParentComponent(Self);
    UsersDataset.Name := 'UsersDataset';
    UsersDataset.AfterApplyUpdates := UsersDatasetAfterApplyUpdates;
    UsersDataset.EntitySetName := 'Users';
    UsersDataset.Connection := DMConnection.ApiConnection;
    UsersDataset.Left := 176;
    UsersDataset.Top := 8;
    UsersDatasetusr_Id.SetParentComponent(UsersDataset);
    UsersDatasetusr_Id.Name := 'UsersDatasetusr_Id';
    UsersDatasetusr_Id.FieldName := 'usr_Id';
    UsersDatasetusr_Id.Required := True;
    UsersDatasetusr_Login.SetParentComponent(UsersDataset);
    UsersDatasetusr_Login.Name := 'UsersDatasetusr_Login';
    UsersDatasetusr_Login.FieldName := 'usr_Login';
    UsersDatasetusr_Login.Required := True;
    UsersDatasetusr_Login.Size := 50;
    UsersDatasetusr_Pwd.SetParentComponent(UsersDataset);
    UsersDatasetusr_Pwd.Name := 'UsersDatasetusr_Pwd';
    UsersDatasetusr_Pwd.FieldName := 'usr_Pwd';
    UsersDatasetusr_Pwd.Required := True;
    UsersDatasetusr_Pwd.Size := 100;
    UsersDatasetusr_Active.SetParentComponent(UsersDataset);
    UsersDatasetusr_Active.Name := 'UsersDatasetusr_Active';
    UsersDatasetusr_Active.FieldName := 'usr_Active';
    UsersDatasetusr_FullName.SetParentComponent(UsersDataset);
    UsersDatasetusr_FullName.Name := 'UsersDatasetusr_FullName';
    UsersDatasetusr_FullName.FieldName := 'usr_FullName';
    UsersDatasetusr_FullName.Size := 150;
    UsersDatasetemp_Id.SetParentComponent(UsersDataset);
    UsersDatasetemp_Id.Name := 'UsersDatasetemp_Id';
    UsersDatasetemp_Id.FieldName := 'emp_Id';
    UsersDatasetcli_Id.SetParentComponent(UsersDataset);
    UsersDatasetcli_Id.Name := 'UsersDatasetcli_Id';
    UsersDatasetcli_Id.FieldName := 'cli_Id';
    UsersDatasetusr_Type.SetParentComponent(UsersDataset);
    UsersDatasetusr_Type.Name := 'UsersDatasetusr_Type';
    UsersDatasetusr_Type.FieldName := 'usr_Type';
    UsersDatasetusr_Level.SetParentComponent(UsersDataset);
    UsersDatasetusr_Level.Name := 'UsersDatasetusr_Level';
    UsersDatasetusr_Level.FieldName := 'usr_Level';
    UsersDatasetusr_CreatedBy.SetParentComponent(UsersDataset);
    UsersDatasetusr_CreatedBy.Name := 'UsersDatasetusr_CreatedBy';
    UsersDatasetusr_CreatedBy.FieldName := 'usr_CreatedBy';
    UsersDatasetusr_CreatedDate.SetParentComponent(UsersDataset);
    UsersDatasetusr_CreatedDate.Name := 'UsersDatasetusr_CreatedDate';
    UsersDatasetusr_CreatedDate.FieldName := 'usr_CreatedDate';
    UsersDatasetusr_LastModifiedBy.SetParentComponent(UsersDataset);
    UsersDatasetusr_LastModifiedBy.Name := 'UsersDatasetusr_LastModifiedBy';
    UsersDatasetusr_LastModifiedBy.FieldName := 'usr_LastModifiedBy';
    UsersDatasetusr_LastModifiedDate.SetParentComponent(UsersDataset);
    UsersDatasetusr_LastModifiedDate.Name := 'UsersDatasetusr_LastModifiedDate';
    UsersDatasetusr_LastModifiedDate.FieldName := 'usr_LastModifiedDate';
    XDataClientUsersGet.SetParentComponent(Self);
    XDataClientUsersGet.Name := 'XDataClientUsersGet';
    XDataClientUsersGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientUsersGet, Self, 'OnLoad', 'XDataClientUsersGetLoad');
    XDataClientUsersGet.Left := 64;
    XDataClientUsersGet.Top := 8;
    XDataClientUsersDelete.SetParentComponent(Self);
    XDataClientUsersDelete.Name := 'XDataClientUsersDelete';
    XDataClientUsersDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientUsersDelete, Self, 'OnLoad', 'XDataClientUsersDeleteLoad');
    SetEvent(XDataClientUsersDelete, Self, 'OnError', 'XDataClientUsersDeleteError');
    XDataClientUsersDelete.Left := 64;
    XDataClientUsersDelete.Top := 56;
  finally
    lbusr_Id.AfterLoadDFMValues;
    lbusr_Login.AfterLoadDFMValues;
    lbusr_Pwd.AfterLoadDFMValues;
    lbusr_Active.AfterLoadDFMValues;
    lbusr_FullName.AfterLoadDFMValues;
    lbemp_Id.AfterLoadDFMValues;
    lbcli_Id.AfterLoadDFMValues;
    lbusr_Type.AfterLoadDFMValues;
    lbusr_Level.AfterLoadDFMValues;
    lbusr_CreatedBy.AfterLoadDFMValues;
    lbusr_CreatedDate.AfterLoadDFMValues;
    lbusr_LastModifiedBy.AfterLoadDFMValues;
    lbusr_LastModifiedDate.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edusr_Id.AfterLoadDFMValues;
    edusr_Login.AfterLoadDFMValues;
    edusr_Pwd.AfterLoadDFMValues;
    edusr_Active.AfterLoadDFMValues;
    edusr_FullName.AfterLoadDFMValues;
    edemp_Id.AfterLoadDFMValues;
    edcli_Id.AfterLoadDFMValues;
    edusr_Type.AfterLoadDFMValues;
    edusr_Level.AfterLoadDFMValues;
    edusr_CreatedBy.AfterLoadDFMValues;
    edusr_CreatedDate.AfterLoadDFMValues;
    edusr_LastModifiedBy.AfterLoadDFMValues;
    edusr_LastModifiedDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    UsersDataset.AfterLoadDFMValues;
    UsersDatasetusr_Id.AfterLoadDFMValues;
    UsersDatasetusr_Login.AfterLoadDFMValues;
    UsersDatasetusr_Pwd.AfterLoadDFMValues;
    UsersDatasetusr_Active.AfterLoadDFMValues;
    UsersDatasetusr_FullName.AfterLoadDFMValues;
    UsersDatasetemp_Id.AfterLoadDFMValues;
    UsersDatasetcli_Id.AfterLoadDFMValues;
    UsersDatasetusr_Type.AfterLoadDFMValues;
    UsersDatasetusr_Level.AfterLoadDFMValues;
    UsersDatasetusr_CreatedBy.AfterLoadDFMValues;
    UsersDatasetusr_CreatedDate.AfterLoadDFMValues;
    UsersDatasetusr_LastModifiedBy.AfterLoadDFMValues;
    UsersDatasetusr_LastModifiedDate.AfterLoadDFMValues;
    XDataClientUsersGet.AfterLoadDFMValues;
    XDataClientUsersDelete.AfterLoadDFMValues;
  end;
end;

end.
