unit View.Main;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.Forms, WEBLib.Controls, WEBLib.ExtCtrls, Vcl.StdCtrls, WEBLib.StdCtrls,
  App.Types,
  Container.Connection, WEBLib.Lists,

  View.Crud.Container;

type
  TFViewMain = class(TForm)
    WebPanel1: TPanel;
    lbViewHome: TLinkLabel;  
    lbViewInvoiceDetail: TLinkLabel;
    lbViewEmployee: TLinkLabel;
    lbViewSalesReports: TLinkLabel;
    lbViewSeason: TLinkLabel;
    lbViewSettings: TLinkLabel;
    lbViewActivities: TLinkLabel;
    lbViewContratModelType: TLinkLabel;
    lbViewPayments: TLinkLabel;
    lbViewInvoiceHeader: TLinkLabel;
    lbViewTokens: TLinkLabel;
    lbViewAnnounceList: TLinkLabel;
    lbViewCampSite: TLinkLabel;
    lbViewCompProducts: TLinkLabel;
    lbViewCampSitePhoto: TLinkLabel;
    lbViewContractClass: TLinkLabel;
    lbViewCompanyInfo: TLinkLabel;
    lbViewIncomeCategory: TLinkLabel;
    lbViewActivityBooking: TLinkLabel;
    lbViewRentalAsset: TLinkLabel;
    lbViewServicingList: TLinkLabel;
    lbViewUsers: TLinkLabel;
    lbViewReserv: TLinkLabel;
    lbViewClients: TLinkLabel;
    lbViewEstateInventory: TLinkLabel;
    WebLinkLabel1: TLinkLabel;
    lbUsername: TLabel;
    WebLinkLabel_UserProfile: TLinkLabel;
    WebLinkLabel_Logout: TLinkLabel;
    procedure WebFormCreate(Sender: TObject);    
	  procedure lbViewHomeClick(Sender: TObject);
    procedure lbViewInvoiceDetailClick(Sender: TObject);
    procedure lbViewEmployeeClick(Sender: TObject);
    procedure lbViewSalesReportsClick(Sender: TObject);
    procedure lbViewSeasonClick(Sender: TObject);
    procedure lbViewSettingsClick(Sender: TObject);
    procedure lbViewActivitiesClick(Sender: TObject);
    procedure lbViewContratModelTypeClick(Sender: TObject);
    procedure lbViewPaymentsClick(Sender: TObject);
    procedure lbViewInvoiceHeaderClick(Sender: TObject);
    procedure lbViewTokensClick(Sender: TObject);
    procedure lbViewAnnounceListClick(Sender: TObject);
    procedure lbViewCampSiteClick(Sender: TObject);
    procedure lbViewCompProductsClick(Sender: TObject);
    procedure lbViewCampSitePhotoClick(Sender: TObject);
    procedure lbViewContractClassClick(Sender: TObject);
    procedure lbViewCompanyInfoClick(Sender: TObject);
    procedure lbViewIncomeCategoryClick(Sender: TObject);
    procedure lbViewActivityBookingClick(Sender: TObject);
    procedure lbViewRentalAssetClick(Sender: TObject);
    procedure lbViewServicingListClick(Sender: TObject);
    procedure lbViewUsersClick(Sender: TObject);
    procedure lbViewReservClick(Sender: TObject);
    procedure lbViewClientsClick(Sender: TObject);
    procedure lbViewEstateInventoryClick(Sender: TObject);
    procedure WebLinkLabel_LogoutClick(Sender: TObject);
    procedure WebLinkLabel_UserProfileClick(Sender: TObject);
  private
    FChildForm: TForm;
    FLogoutProc: TLogoutProc;                                                  //aded for login-ing
    procedure ShowForm(AFormClass: TFormClass);
    procedure ShowCrudContainer(ACrudFormClass: TFormClass; ATitle: string;
      AShowCrudListProc: TShowCrudListProc; AShowCrudEditProc: TShowCrudEditProc);
  public
    class procedure Display(AElementId: string; LogoutProc: TLogoutProc);
  protected procedure LoadDFMValues; override; end;

var
  FViewMain: TFViewMain;

implementation

uses
  JS, Web,
  Auth.Service,
  View.Login,
  View.UserProfile,
  View.Home,
  View.InvoiceDetail.List,
  View.InvoiceDetail.Edit,
  View.Employee.List,
  View.Employee.Edit,
  View.SalesReports.List,
  View.SalesReports.Edit,
  View.Season.List,
  View.Season.Edit,
  View.Settings.List,
  View.Settings.Edit,
  View.Activities.List,
  View.Activities.Edit,
  View.ContratModelType.List,
  View.ContratModelType.Edit,
  View.Payments.List,
  View.Payments.Edit,
  View.InvoiceHeader.List,
  View.InvoiceHeader.Edit,
  View.Tokens.List,
  View.Tokens.Edit,
  View.AnnounceList.List,
  View.AnnounceList.Edit,
  View.CampSite.List,
  View.CampSite.Edit,
  View.CompProducts.List,
  View.CompProducts.Edit,
  View.CampSitePhoto.List,
  View.CampSitePhoto.Edit,
  View.ContractClass.List,
  View.ContractClass.Edit,
  View.CompanyInfo.List,
  View.CompanyInfo.Edit,
  View.IncomeCategory.List,
  View.IncomeCategory.Edit,
  View.ActivityBooking.List,
  View.ActivityBooking.Edit,
  View.RentalAsset.List,
  View.RentalAsset.Edit,
  View.ServicingList.List,
  View.ServicingList.Edit,
  View.Users.List,
  View.Users.Edit,
  View.Reserv.List,
  View.Reserv.Edit,
  View.Clients.List,
  View.Clients.Edit,
  View.EstateInventory.List,
  View.EstateInventory.Edit;

{$R *.dfm}

procedure TFViewMain.WebFormCreate(Sender: TObject);
begin
  //aded for login-ing BEGIN
  lbUsername.Caption := JS.toString(AuthService.TokenPayload.Properties['user']);

  if AuthService.TokenPayload.Properties['admin'] then
    lbUsername.Caption := lbUsername.Caption +  ' [admin]'
  else
    lbUsername.Caption := lbUsername.Caption +  ' [non-admin]';
  //aded for login-ing END

  FChildForm := nil;
  ShowForm(TFViewHome);
end;

//aded for login-ing BEGIN
procedure TFViewMain.WebLinkLabel_LogoutClick(Sender: TObject);
begin
  // logout
  FLogoutProc;
end;

procedure TFViewMain.WebLinkLabel_UserProfileClick(Sender: TObject);
begin
  ShowForm(TFViewUserProfile);
end;
//aded for login-ing END

procedure TFViewMain.lbViewHomeClick(Sender: TObject);
begin
  ShowForm(TFViewHome);
end;

procedure TFViewMain.lbViewInvoiceDetailClick(Sender: TObject);

  function CreateInvoiceDetailListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewInvoiceDetailList.CreateForm(AElementId, AEditProc);
  end;

  function CreateInvoiceDetailEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewInvoiceDetailEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewInvoiceDetailList, 'InvoiceDetail', @CreateInvoiceDetailListForm, @CreateInvoiceDetailEditForm);
end;

procedure TFViewMain.lbViewEmployeeClick(Sender: TObject);

  function CreateEmployeeListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewEmployeeList.CreateForm(AElementId, AEditProc);
  end;

  function CreateEmployeeEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewEmployeeEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewEmployeeList, 'Employee', @CreateEmployeeListForm, @CreateEmployeeEditForm);
end;

procedure TFViewMain.lbViewSalesReportsClick(Sender: TObject);

  function CreateSalesReportsListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewSalesReportsList.CreateForm(AElementId, AEditProc);
  end;

  function CreateSalesReportsEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewSalesReportsEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewSalesReportsList, 'SalesReports', @CreateSalesReportsListForm, @CreateSalesReportsEditForm);
end;

procedure TFViewMain.lbViewSeasonClick(Sender: TObject);

  function CreateSeasonListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewSeasonList.CreateForm(AElementId, AEditProc);
  end;

  function CreateSeasonEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewSeasonEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewSeasonList, 'Season', @CreateSeasonListForm, @CreateSeasonEditForm);
end;

procedure TFViewMain.lbViewSettingsClick(Sender: TObject);

  function CreateSettingsListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewSettingsList.CreateForm(AElementId, AEditProc);
  end;

  function CreateSettingsEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewSettingsEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewSettingsList, 'Settings', @CreateSettingsListForm, @CreateSettingsEditForm);
end;

procedure TFViewMain.lbViewActivitiesClick(Sender: TObject);

  function CreateActivitiesListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewActivitiesList.CreateForm(AElementId, AEditProc);
  end;

  function CreateActivitiesEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewActivitiesEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewActivitiesList, 'Activities', @CreateActivitiesListForm, @CreateActivitiesEditForm);
end;

procedure TFViewMain.lbViewContratModelTypeClick(Sender: TObject);

  function CreateContratModelTypeListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewContratModelTypeList.CreateForm(AElementId, AEditProc);
  end;

  function CreateContratModelTypeEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewContratModelTypeEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewContratModelTypeList, 'ContratModelType', @CreateContratModelTypeListForm, @CreateContratModelTypeEditForm);
end;

procedure TFViewMain.lbViewPaymentsClick(Sender: TObject);

  function CreatePaymentsListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewPaymentsList.CreateForm(AElementId, AEditProc);
  end;

  function CreatePaymentsEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewPaymentsEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewPaymentsList, 'Payments', @CreatePaymentsListForm, @CreatePaymentsEditForm);
end;

procedure TFViewMain.lbViewInvoiceHeaderClick(Sender: TObject);

  function CreateInvoiceHeaderListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewInvoiceHeaderList.CreateForm(AElementId, AEditProc);
  end;

  function CreateInvoiceHeaderEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewInvoiceHeaderEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewInvoiceHeaderList, 'InvoiceHeader', @CreateInvoiceHeaderListForm, @CreateInvoiceHeaderEditForm);
end;

procedure TFViewMain.lbViewTokensClick(Sender: TObject);

  function CreateTokensListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewTokensList.CreateForm(AElementId, AEditProc);
  end;

  function CreateTokensEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewTokensEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewTokensList, 'Tokens', @CreateTokensListForm, @CreateTokensEditForm);
end;

procedure TFViewMain.lbViewAnnounceListClick(Sender: TObject);

  function CreateAnnounceListListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewAnnounceListList.CreateForm(AElementId, AEditProc);
  end;

  function CreateAnnounceListEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewAnnounceListEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewAnnounceListList, 'AnnounceList', @CreateAnnounceListListForm, @CreateAnnounceListEditForm);
end;

procedure TFViewMain.lbViewCampSiteClick(Sender: TObject);

  function CreateCampSiteListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewCampSiteList.CreateForm(AElementId, AEditProc);
  end;

  function CreateCampSiteEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewCampSiteEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewCampSiteList, 'CampSite', @CreateCampSiteListForm, @CreateCampSiteEditForm);
end;

procedure TFViewMain.lbViewCompProductsClick(Sender: TObject);

  function CreateCompProductsListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewCompProductsList.CreateForm(AElementId, AEditProc);
  end;

  function CreateCompProductsEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewCompProductsEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewCompProductsList, 'CompProducts', @CreateCompProductsListForm, @CreateCompProductsEditForm);
end;

procedure TFViewMain.lbViewCampSitePhotoClick(Sender: TObject);

  function CreateCampSitePhotoListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewCampSitePhotoList.CreateForm(AElementId, AEditProc);
  end;

  function CreateCampSitePhotoEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewCampSitePhotoEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewCampSitePhotoList, 'CampSitePhoto', @CreateCampSitePhotoListForm, @CreateCampSitePhotoEditForm);
end;

procedure TFViewMain.lbViewContractClassClick(Sender: TObject);

  function CreateContractClassListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewContractClassList.CreateForm(AElementId, AEditProc);
  end;

  function CreateContractClassEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewContractClassEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewContractClassList, 'ContractClass', @CreateContractClassListForm, @CreateContractClassEditForm);
end;

procedure TFViewMain.lbViewCompanyInfoClick(Sender: TObject);

  function CreateCompanyInfoListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewCompanyInfoList.CreateForm(AElementId, AEditProc);
  end;

  function CreateCompanyInfoEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewCompanyInfoEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewCompanyInfoList, 'CompanyInfo', @CreateCompanyInfoListForm, @CreateCompanyInfoEditForm);
end;

procedure TFViewMain.lbViewIncomeCategoryClick(Sender: TObject);

  function CreateIncomeCategoryListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewIncomeCategoryList.CreateForm(AElementId, AEditProc);
  end;

  function CreateIncomeCategoryEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewIncomeCategoryEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewIncomeCategoryList, 'IncomeCategory', @CreateIncomeCategoryListForm, @CreateIncomeCategoryEditForm);
end;

procedure TFViewMain.lbViewActivityBookingClick(Sender: TObject);

  function CreateActivityBookingListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewActivityBookingList.CreateForm(AElementId, AEditProc);
  end;

  function CreateActivityBookingEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewActivityBookingEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewActivityBookingList, 'ActivityBooking', @CreateActivityBookingListForm, @CreateActivityBookingEditForm);
end;

procedure TFViewMain.lbViewRentalAssetClick(Sender: TObject);

  function CreateRentalAssetListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewRentalAssetList.CreateForm(AElementId, AEditProc);
  end;

  function CreateRentalAssetEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewRentalAssetEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewRentalAssetList, 'RentalAsset', @CreateRentalAssetListForm, @CreateRentalAssetEditForm);
end;

procedure TFViewMain.lbViewServicingListClick(Sender: TObject);

  function CreateServicingListListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewServicingListList.CreateForm(AElementId, AEditProc);
  end;

  function CreateServicingListEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewServicingListEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewServicingListList, 'ServicingList', @CreateServicingListListForm, @CreateServicingListEditForm);
end;

procedure TFViewMain.lbViewUsersClick(Sender: TObject);

  function CreateUsersListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewUsersList.CreateForm(AElementId, AEditProc);
  end;

  function CreateUsersEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewUsersEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewUsersList, 'Users', @CreateUsersListForm, @CreateUsersEditForm);
end;

procedure TFViewMain.lbViewReservClick(Sender: TObject);

  function CreateReservListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewReservList.CreateForm(AElementId, AEditProc);
  end;

  function CreateReservEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewReservEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin                                 //'Reserv'
  ShowCrudContainer(TFViewReservList, 'Reservation', @CreateReservListForm, @CreateReservEditForm);
end;

procedure TFViewMain.lbViewClientsClick(Sender: TObject);

  function CreateClientsListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewClientsList.CreateForm(AElementId, AEditProc);
  end;

  function CreateClientsEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewClientsEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewClientsList, 'Clients', @CreateClientsListForm, @CreateClientsEditForm);
end;

procedure TFViewMain.lbViewEstateInventoryClick(Sender: TObject);

  function CreateEstateInventoryListForm(const AElementId: string; AEditProc: TEditProc): TForm;
  begin
    Result := TFViewEstateInventoryList.CreateForm(AElementId, AEditProc);
  end;

  function CreateEstateInventoryEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
  begin
    Result := TFViewEstateInventoryEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  ShowCrudContainer(TFViewEstateInventoryList, 'EstateInventory', @CreateEstateInventoryListForm, @CreateEstateInventoryEditForm);
end;



class procedure TFViewMain.Display(AElementId: string; LogoutProc: TLogoutProc);

  procedure AfterCreate(AForm: TObject);
  begin
    //TFViewMain(AForm).FLogoutProc := LogoutProc;
  end;

begin
  if Assigned(FViewMain) then
    FViewMain.Free;
  FViewMain := TFViewMain.CreateNew(AElementId, @AfterCreate);
  FViewMain.FLogoutProc := LogoutProc;                                          //aded for login-ing
end;

procedure TFViewMain.ShowCrudContainer(ACrudFormClass: TFormClass; ATitle: string;
  AShowCrudListProc: TShowCrudListProc; AShowCrudEditProc: TShowCrudEditProc);

  function CreateAlbumListForm(const AElementId: string; AEditProc: TEditProc): TForm;
    procedure AfterCreateListForm(Form: TObject);
    begin
      TJSObject(Form)['FEditProc'] := AEditProc;
    end;
  begin
    Application.CreateForm(ACrudFormClass, AElementId, Result, @AfterCreateListForm);
  end;

  function CreateAlbumEditForm(const AElementId: string; AListProc: TListProc;
    AId: JSValue): TForm;
    procedure AfterCreateEditForm(Form: TObject);
    begin
      //TJSObject(Form)['FEditProc'] := AEditProc;
    end;
  begin
    //Application.CreateForm(ACrudFormClass, AElementId, Result, @AfterCreateListForm);

    //Result := TFViewAlbumEdit.CreateForm(AElementId, AListProc, AId);
  end;

begin
  if Assigned(FChildForm) then
    FChildForm.Free;
  FChildForm := TFViewCrudContainer.CreateForm(WebPanel1.ElementID,
    ATitle, @CreateAlbumListForm, AShowCrudEditProc
  );
end;

procedure TFViewMain.ShowForm(AFormClass: TFormClass);
begin
  if Assigned(FChildForm) then
    FChildForm.Free;
  Application.CreateForm(AFormClass, WebPanel1.ElementID, FChildForm);
end;

procedure TFViewMain.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbViewHome := TLinkLabel.Create('main.menu.home');
  lbViewInvoiceDetail := TLinkLabel.Create('main.menu.invoicedetail');
  lbViewEmployee := TLinkLabel.Create('main.menu.employee');
  lbViewSalesReports := TLinkLabel.Create('main.menu.salesreports');
  lbViewSeason := TLinkLabel.Create('main.menu.season');
  lbViewSettings := TLinkLabel.Create('main.menu.settings');
  lbViewActivities := TLinkLabel.Create('main.menu.activities');
  lbViewContratModelType := TLinkLabel.Create('main.menu.contratmodeltype');
  lbViewPayments := TLinkLabel.Create('main.menu.payments');
  lbViewInvoiceHeader := TLinkLabel.Create('main.menu.invoiceheader');
  lbViewTokens := TLinkLabel.Create('main.menu.tokens');
  lbViewAnnounceList := TLinkLabel.Create('main.menu.announcelist');
  lbViewCampSite := TLinkLabel.Create('main.menu.campsite');
  lbViewCompProducts := TLinkLabel.Create('main.menu.compproducts');
  lbViewCampSitePhoto := TLinkLabel.Create('main.menu.campsitephoto');
  lbViewContractClass := TLinkLabel.Create('main.menu.contractclass');
  lbViewCompanyInfo := TLinkLabel.Create('main.menu.companyinfo');
  lbViewIncomeCategory := TLinkLabel.Create('main.menu.incomecategory');
  lbViewActivityBooking := TLinkLabel.Create('main.menu.activitybooking');
  lbViewRentalAsset := TLinkLabel.Create('main.menu.rentalasset');
  lbViewServicingList := TLinkLabel.Create('main.menu.servicinglist');
  lbViewUsers := TLinkLabel.Create('main.menu.users');
  lbViewReserv := TLinkLabel.Create('main.menu.reserv');
  lbViewClients := TLinkLabel.Create('main.menu.clients');
  lbViewEstateInventory := TLinkLabel.Create('main.menu.estateinventory');
  WebLinkLabel1 := TLinkLabel.Create(Self);
  lbUsername := TLabel.Create('view.main.username');
  WebLinkLabel_UserProfile := TLinkLabel.Create('main.menu.userprofile');
  WebLinkLabel_Logout := TLinkLabel.Create('main.menu.logout');
  WebPanel1 := TPanel.Create('main.webpanel');

  lbViewHome.BeforeLoadDFMValues;
  lbViewInvoiceDetail.BeforeLoadDFMValues;
  lbViewEmployee.BeforeLoadDFMValues;
  lbViewSalesReports.BeforeLoadDFMValues;
  lbViewSeason.BeforeLoadDFMValues;
  lbViewSettings.BeforeLoadDFMValues;
  lbViewActivities.BeforeLoadDFMValues;
  lbViewContratModelType.BeforeLoadDFMValues;
  lbViewPayments.BeforeLoadDFMValues;
  lbViewInvoiceHeader.BeforeLoadDFMValues;
  lbViewTokens.BeforeLoadDFMValues;
  lbViewAnnounceList.BeforeLoadDFMValues;
  lbViewCampSite.BeforeLoadDFMValues;
  lbViewCompProducts.BeforeLoadDFMValues;
  lbViewCampSitePhoto.BeforeLoadDFMValues;
  lbViewContractClass.BeforeLoadDFMValues;
  lbViewCompanyInfo.BeforeLoadDFMValues;
  lbViewIncomeCategory.BeforeLoadDFMValues;
  lbViewActivityBooking.BeforeLoadDFMValues;
  lbViewRentalAsset.BeforeLoadDFMValues;
  lbViewServicingList.BeforeLoadDFMValues;
  lbViewUsers.BeforeLoadDFMValues;
  lbViewReserv.BeforeLoadDFMValues;
  lbViewClients.BeforeLoadDFMValues;
  lbViewEstateInventory.BeforeLoadDFMValues;
  WebLinkLabel1.BeforeLoadDFMValues;
  lbUsername.BeforeLoadDFMValues;
  WebLinkLabel_UserProfile.BeforeLoadDFMValues;
  WebLinkLabel_Logout.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  try
    Name := 'FViewMain';
    Width := 640;
    Height := 593;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbViewHome.SetParentComponent(Self);
    lbViewHome.Name := 'lbViewHome';
    lbViewHome.Left := 23;
    lbViewHome.Top := 88;
    lbViewHome.Width := 44;
    lbViewHome.Height := 13;
    lbViewHome.HeightPercent := 100.000000000000000000;
    lbViewHome.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewHome, Self, 'OnClick', 'lbViewHomeClick');
    lbViewHome.Caption := 'Summary';
    lbViewInvoiceDetail.SetParentComponent(Self);
    lbViewInvoiceDetail.Name := 'lbViewInvoiceDetail';
    lbViewInvoiceDetail.Left := 24;
    lbViewInvoiceDetail.Top := 143;
    lbViewInvoiceDetail.Width := 62;
    lbViewInvoiceDetail.Height := 13;
    lbViewInvoiceDetail.HeightPercent := 100.000000000000000000;
    lbViewInvoiceDetail.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewInvoiceDetail, Self, 'OnClick', 'lbViewInvoiceDetailClick');
    lbViewInvoiceDetail.Caption := 'InvoiceDetail';
    lbViewEmployee.SetParentComponent(Self);
    lbViewEmployee.Name := 'lbViewEmployee';
    lbViewEmployee.Left := 24;
    lbViewEmployee.Top := 162;
    lbViewEmployee.Width := 46;
    lbViewEmployee.Height := 13;
    lbViewEmployee.HeightPercent := 100.000000000000000000;
    lbViewEmployee.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewEmployee, Self, 'OnClick', 'lbViewEmployeeClick');
    lbViewEmployee.Caption := 'Employee';
    lbViewSalesReports.SetParentComponent(Self);
    lbViewSalesReports.Name := 'lbViewSalesReports';
    lbViewSalesReports.Left := 24;
    lbViewSalesReports.Top := 181;
    lbViewSalesReports.Width := 63;
    lbViewSalesReports.Height := 13;
    lbViewSalesReports.HeightPercent := 100.000000000000000000;
    lbViewSalesReports.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewSalesReports, Self, 'OnClick', 'lbViewSalesReportsClick');
    lbViewSalesReports.Caption := 'SalesReports';
    lbViewSeason.SetParentComponent(Self);
    lbViewSeason.Name := 'lbViewSeason';
    lbViewSeason.Left := 24;
    lbViewSeason.Top := 200;
    lbViewSeason.Width := 35;
    lbViewSeason.Height := 13;
    lbViewSeason.HeightPercent := 100.000000000000000000;
    lbViewSeason.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewSeason, Self, 'OnClick', 'lbViewSeasonClick');
    lbViewSeason.Caption := 'Season';
    lbViewSettings.SetParentComponent(Self);
    lbViewSettings.Name := 'lbViewSettings';
    lbViewSettings.Left := 24;
    lbViewSettings.Top := 219;
    lbViewSettings.Width := 39;
    lbViewSettings.Height := 13;
    lbViewSettings.HeightPercent := 100.000000000000000000;
    lbViewSettings.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewSettings, Self, 'OnClick', 'lbViewSettingsClick');
    lbViewSettings.Caption := 'Settings';
    lbViewActivities.SetParentComponent(Self);
    lbViewActivities.Name := 'lbViewActivities';
    lbViewActivities.Left := 24;
    lbViewActivities.Top := 238;
    lbViewActivities.Width := 43;
    lbViewActivities.Height := 13;
    lbViewActivities.HeightPercent := 100.000000000000000000;
    lbViewActivities.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewActivities, Self, 'OnClick', 'lbViewActivitiesClick');
    lbViewActivities.Caption := 'Activities';
    lbViewContratModelType.SetParentComponent(Self);
    lbViewContratModelType.Name := 'lbViewContratModelType';
    lbViewContratModelType.Left := 24;
    lbViewContratModelType.Top := 257;
    lbViewContratModelType.Width := 89;
    lbViewContratModelType.Height := 13;
    lbViewContratModelType.HeightPercent := 100.000000000000000000;
    lbViewContratModelType.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewContratModelType, Self, 'OnClick', 'lbViewContratModelTypeClick');
    lbViewContratModelType.Caption := 'ContratModelType';
    lbViewPayments.SetParentComponent(Self);
    lbViewPayments.Name := 'lbViewPayments';
    lbViewPayments.Left := 24;
    lbViewPayments.Top := 276;
    lbViewPayments.Width := 47;
    lbViewPayments.Height := 13;
    lbViewPayments.HeightPercent := 100.000000000000000000;
    lbViewPayments.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewPayments, Self, 'OnClick', 'lbViewPaymentsClick');
    lbViewPayments.Caption := 'Payments';
    lbViewInvoiceHeader.SetParentComponent(Self);
    lbViewInvoiceHeader.Name := 'lbViewInvoiceHeader';
    lbViewInvoiceHeader.Left := 24;
    lbViewInvoiceHeader.Top := 295;
    lbViewInvoiceHeader.Width := 70;
    lbViewInvoiceHeader.Height := 13;
    lbViewInvoiceHeader.HeightPercent := 100.000000000000000000;
    lbViewInvoiceHeader.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewInvoiceHeader, Self, 'OnClick', 'lbViewInvoiceHeaderClick');
    lbViewInvoiceHeader.Caption := 'InvoiceHeader';
    lbViewTokens.SetParentComponent(Self);
    lbViewTokens.Name := 'lbViewTokens';
    lbViewTokens.Left := 24;
    lbViewTokens.Top := 314;
    lbViewTokens.Width := 34;
    lbViewTokens.Height := 13;
    lbViewTokens.HeightPercent := 100.000000000000000000;
    lbViewTokens.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewTokens, Self, 'OnClick', 'lbViewTokensClick');
    lbViewTokens.Caption := 'Tokens';
    lbViewAnnounceList.SetParentComponent(Self);
    lbViewAnnounceList.Name := 'lbViewAnnounceList';
    lbViewAnnounceList.Left := 24;
    lbViewAnnounceList.Top := 333;
    lbViewAnnounceList.Width := 64;
    lbViewAnnounceList.Height := 13;
    lbViewAnnounceList.HeightPercent := 100.000000000000000000;
    lbViewAnnounceList.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewAnnounceList, Self, 'OnClick', 'lbViewAnnounceListClick');
    lbViewAnnounceList.Caption := 'AnnounceList';
    lbViewCampSite.SetParentComponent(Self);
    lbViewCampSite.Name := 'lbViewCampSite';
    lbViewCampSite.Left := 25;
    lbViewCampSite.Top := 107;
    lbViewCampSite.Width := 72;
    lbViewCampSite.Height := 13;
    lbViewCampSite.HeightPercent := 100.000000000000000000;
    lbViewCampSite.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewCampSite, Self, 'OnClick', 'lbViewCampSiteClick');
    lbViewCampSite.Caption := '==> CampSite';
    lbViewCompProducts.SetParentComponent(Self);
    lbViewCompProducts.Name := 'lbViewCompProducts';
    lbViewCompProducts.Left := 24;
    lbViewCompProducts.Top := 355;
    lbViewCompProducts.Width := 69;
    lbViewCompProducts.Height := 13;
    lbViewCompProducts.HeightPercent := 100.000000000000000000;
    lbViewCompProducts.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewCompProducts, Self, 'OnClick', 'lbViewCompProductsClick');
    lbViewCompProducts.Caption := 'CompProducts';
    lbViewCampSitePhoto.SetParentComponent(Self);
    lbViewCampSitePhoto.Name := 'lbViewCampSitePhoto';
    lbViewCampSitePhoto.Left := 24;
    lbViewCampSitePhoto.Top := 374;
    lbViewCampSitePhoto.Width := 73;
    lbViewCampSitePhoto.Height := 13;
    lbViewCampSitePhoto.HeightPercent := 100.000000000000000000;
    lbViewCampSitePhoto.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewCampSitePhoto, Self, 'OnClick', 'lbViewCampSitePhotoClick');
    lbViewCampSitePhoto.Caption := 'CampSitePhoto';
    lbViewContractClass.SetParentComponent(Self);
    lbViewContractClass.Name := 'lbViewContractClass';
    lbViewContractClass.Left := 24;
    lbViewContractClass.Top := 393;
    lbViewContractClass.Width := 67;
    lbViewContractClass.Height := 13;
    lbViewContractClass.HeightPercent := 100.000000000000000000;
    lbViewContractClass.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewContractClass, Self, 'OnClick', 'lbViewContractClassClick');
    lbViewContractClass.Caption := 'ContractClass';
    lbViewCompanyInfo.SetParentComponent(Self);
    lbViewCompanyInfo.Name := 'lbViewCompanyInfo';
    lbViewCompanyInfo.Left := 24;
    lbViewCompanyInfo.Top := 412;
    lbViewCompanyInfo.Width := 65;
    lbViewCompanyInfo.Height := 13;
    lbViewCompanyInfo.HeightPercent := 100.000000000000000000;
    lbViewCompanyInfo.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewCompanyInfo, Self, 'OnClick', 'lbViewCompanyInfoClick');
    lbViewCompanyInfo.Caption := 'CompanyInfo';
    lbViewIncomeCategory.SetParentComponent(Self);
    lbViewIncomeCategory.Name := 'lbViewIncomeCategory';
    lbViewIncomeCategory.Left := 24;
    lbViewIncomeCategory.Top := 431;
    lbViewIncomeCategory.Width := 80;
    lbViewIncomeCategory.Height := 13;
    lbViewIncomeCategory.HeightPercent := 100.000000000000000000;
    lbViewIncomeCategory.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewIncomeCategory, Self, 'OnClick', 'lbViewIncomeCategoryClick');
    lbViewIncomeCategory.Caption := 'IncomeCategory';
    lbViewActivityBooking.SetParentComponent(Self);
    lbViewActivityBooking.Name := 'lbViewActivityBooking';
    lbViewActivityBooking.Left := 24;
    lbViewActivityBooking.Top := 450;
    lbViewActivityBooking.Width := 73;
    lbViewActivityBooking.Height := 13;
    lbViewActivityBooking.HeightPercent := 100.000000000000000000;
    lbViewActivityBooking.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewActivityBooking, Self, 'OnClick', 'lbViewActivityBookingClick');
    lbViewActivityBooking.Caption := 'ActivityBooking';
    lbViewRentalAsset.SetParentComponent(Self);
    lbViewRentalAsset.Name := 'lbViewRentalAsset';
    lbViewRentalAsset.Left := 24;
    lbViewRentalAsset.Top := 469;
    lbViewRentalAsset.Width := 58;
    lbViewRentalAsset.Height := 13;
    lbViewRentalAsset.HeightPercent := 100.000000000000000000;
    lbViewRentalAsset.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewRentalAsset, Self, 'OnClick', 'lbViewRentalAssetClick');
    lbViewRentalAsset.Caption := 'RentalAsset';
    lbViewServicingList.SetParentComponent(Self);
    lbViewServicingList.Name := 'lbViewServicingList';
    lbViewServicingList.Left := 24;
    lbViewServicingList.Top := 488;
    lbViewServicingList.Width := 59;
    lbViewServicingList.Height := 13;
    lbViewServicingList.HeightPercent := 100.000000000000000000;
    lbViewServicingList.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewServicingList, Self, 'OnClick', 'lbViewServicingListClick');
    lbViewServicingList.Caption := 'ServicingList';
    lbViewUsers.SetParentComponent(Self);
    lbViewUsers.Name := 'lbViewUsers';
    lbViewUsers.Left := 24;
    lbViewUsers.Top := 507;
    lbViewUsers.Width := 27;
    lbViewUsers.Height := 13;
    lbViewUsers.HeightPercent := 100.000000000000000000;
    lbViewUsers.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewUsers, Self, 'OnClick', 'lbViewUsersClick');
    lbViewUsers.Caption := 'Users';
    lbViewReserv.SetParentComponent(Self);
    lbViewReserv.Name := 'lbViewReserv';
    lbViewReserv.Left := 24;
    lbViewReserv.Top := 124;
    lbViewReserv.Width := 58;
    lbViewReserv.Height := 13;
    lbViewReserv.HeightPercent := 100.000000000000000000;
    lbViewReserv.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewReserv, Self, 'OnClick', 'lbViewReservClick');
    lbViewReserv.Caption := 'Reservation';
    lbViewClients.SetParentComponent(Self);
    lbViewClients.Name := 'lbViewClients';
    lbViewClients.Left := 24;
    lbViewClients.Top := 529;
    lbViewClients.Width := 32;
    lbViewClients.Height := 13;
    lbViewClients.HeightPercent := 100.000000000000000000;
    lbViewClients.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewClients, Self, 'OnClick', 'lbViewClientsClick');
    lbViewClients.Caption := 'Clients';
    lbViewEstateInventory.SetParentComponent(Self);
    lbViewEstateInventory.Name := 'lbViewEstateInventory';
    lbViewEstateInventory.Left := 24;
    lbViewEstateInventory.Top := 548;
    lbViewEstateInventory.Width := 79;
    lbViewEstateInventory.Height := 13;
    lbViewEstateInventory.HeightPercent := 100.000000000000000000;
    lbViewEstateInventory.WidthPercent := 100.000000000000000000;
    SetEvent(lbViewEstateInventory, Self, 'OnClick', 'lbViewEstateInventoryClick');
    lbViewEstateInventory.Caption := 'EstateInventory';
    WebLinkLabel1.SetParentComponent(Self);
    WebLinkLabel1.Name := 'WebLinkLabel1';
    WebLinkLabel1.Left := 25;
    WebLinkLabel1.Top := 564;
    WebLinkLabel1.Width := 114;
    WebLinkLabel1.Height := 13;
    WebLinkLabel1.HeightPercent := 100.000000000000000000;
    WebLinkLabel1.WidthPercent := 100.000000000000000000;
    SetEvent(WebLinkLabel1, Self, 'OnClick', 'lbViewEstateInventoryClick');
    WebLinkLabel1.Caption := 'NEW_DFM_MENU_ITEM';
    lbUsername.SetParentComponent(Self);
    lbUsername.Name := 'lbUsername';
    lbUsername.Left := 528;
    lbUsername.Top := 8;
    lbUsername.Width := 56;
    lbUsername.Height := 13;
    lbUsername.Caption := 'lbUsername';
    lbUsername.HeightPercent := 100.000000000000000000;
    lbUsername.Transparent := False;
    lbUsername.WidthPercent := 100.000000000000000000;
    WebLinkLabel_UserProfile.SetParentComponent(Self);
    WebLinkLabel_UserProfile.Name := 'WebLinkLabel_UserProfile';
    WebLinkLabel_UserProfile.Left := 529;
    WebLinkLabel_UserProfile.Top := 27;
    WebLinkLabel_UserProfile.Width := 55;
    WebLinkLabel_UserProfile.Height := 13;
    WebLinkLabel_UserProfile.HeightPercent := 100.000000000000000000;
    WebLinkLabel_UserProfile.WidthPercent := 100.000000000000000000;
    SetEvent(WebLinkLabel_UserProfile, Self, 'OnClick', 'WebLinkLabel_UserProfileClick');
    WebLinkLabel_UserProfile.Caption := 'User Profile';
    WebLinkLabel_Logout.SetParentComponent(Self);
    WebLinkLabel_Logout.Name := 'WebLinkLabel_Logout';
    WebLinkLabel_Logout.Left := 551;
    WebLinkLabel_Logout.Top := 46;
    WebLinkLabel_Logout.Width := 33;
    WebLinkLabel_Logout.Height := 13;
    WebLinkLabel_Logout.HeightPercent := 100.000000000000000000;
    WebLinkLabel_Logout.WidthPercent := 100.000000000000000000;
    SetEvent(WebLinkLabel_Logout, Self, 'OnClick', 'WebLinkLabel_LogoutClick');
    WebLinkLabel_Logout.Caption := 'Logout';
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 136;
    WebPanel1.Top := 92;
    WebPanel1.Width := 471;
    WebPanel1.Height := 369;
  finally
    lbViewHome.AfterLoadDFMValues;
    lbViewInvoiceDetail.AfterLoadDFMValues;
    lbViewEmployee.AfterLoadDFMValues;
    lbViewSalesReports.AfterLoadDFMValues;
    lbViewSeason.AfterLoadDFMValues;
    lbViewSettings.AfterLoadDFMValues;
    lbViewActivities.AfterLoadDFMValues;
    lbViewContratModelType.AfterLoadDFMValues;
    lbViewPayments.AfterLoadDFMValues;
    lbViewInvoiceHeader.AfterLoadDFMValues;
    lbViewTokens.AfterLoadDFMValues;
    lbViewAnnounceList.AfterLoadDFMValues;
    lbViewCampSite.AfterLoadDFMValues;
    lbViewCompProducts.AfterLoadDFMValues;
    lbViewCampSitePhoto.AfterLoadDFMValues;
    lbViewContractClass.AfterLoadDFMValues;
    lbViewCompanyInfo.AfterLoadDFMValues;
    lbViewIncomeCategory.AfterLoadDFMValues;
    lbViewActivityBooking.AfterLoadDFMValues;
    lbViewRentalAsset.AfterLoadDFMValues;
    lbViewServicingList.AfterLoadDFMValues;
    lbViewUsers.AfterLoadDFMValues;
    lbViewReserv.AfterLoadDFMValues;
    lbViewClients.AfterLoadDFMValues;
    lbViewEstateInventory.AfterLoadDFMValues;
    WebLinkLabel1.AfterLoadDFMValues;
    lbUsername.AfterLoadDFMValues;
    WebLinkLabel_UserProfile.AfterLoadDFMValues;
    WebLinkLabel_Logout.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
  end;
end;

end.
