unit View.ServicingList.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewServicingListEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbser_Id: TLabel;
    edser_Id: TDBEdit;
    lbser_Description: TLabel;
    edser_Description: TDBEdit;
    lbser_Notes: TLabel;
    edser_Notes: TDBEdit;
    lbcs_Id: TLabel;
    edcs_Id: TDBEdit;
    lbser_AssignTo: TLabel;
    edser_AssignTo: TDBEdit;
    lbser_DueDate: TLabel;
    edser_DueDate: TDBEdit;
    lbser_CompletedDate: TLabel;
    edser_CompletedDate: TDBEdit;
    lbser_Completed: TLabel;
    edser_Completed: TDBEdit;
    lbser_CreatedBy: TLabel;
    edser_CreatedBy: TDBEdit;
    lbser_CreatedDate: TLabel;
    edser_CreatedDate: TDBEdit;
    lbser_LastModifiedBy: TLabel;
    edser_LastModifiedBy: TDBEdit;
    lbser_LastModifiedDate: TLabel;
    edser_LastModifiedDate: TDBEdit;
    XDataClientServicingListGet: TXDataWebClient;
    XDataClientServicingListDelete: TXDataWebClient;
    ServicingListDataset: TXDataWebDataSet;
    ServicingListDatasetser_Id: TIntegerField;
    ServicingListDatasetser_Description: TStringField;
    ServicingListDatasetser_Notes: TStringField;
    ServicingListDatasetcs_Id: TIntegerField;
    ServicingListDatasetser_AssignTo: TIntegerField;
    ServicingListDatasetser_DueDate: TDateTimeField;
    ServicingListDatasetser_CompletedDate: TDateTimeField;
    ServicingListDatasetser_Completed: TIntegerField;
    ServicingListDatasetser_CreatedBy: TIntegerField;
    ServicingListDatasetser_CreatedDate: TDateTimeField;
    ServicingListDatasetser_LastModifiedBy: TIntegerField;
    ServicingListDatasetser_LastModifiedDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientServicingListGetLoad(Response: TXDataClientResponse);
    procedure XDataClientServicingListDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientServicingListDeleteError(Error: TXDataClientError);
    procedure ServicingListDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewServicingListEdit: TFViewServicingListEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewServicingListEdit }

procedure TFViewServicingListEdit.btSaveClick(Sender: TObject);
begin
  if ServicingListDataset.State in dsEditModes then
  begin
        ServicingListDataset.Post;
    ServicingListDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewServicingListEdit.btDeleteClick(Sender: TObject);
begin
  if ServicingListDataset.State in dsEditModes then
    ServicingListDataset.Post;
  XDataClientServicingListDelete.Delete(ServicingListDataset.EntitySetName,
    TJSObject(ServicingListDataset.CurrentData));
end;


class function TFViewServicingListEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewServicingListEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewServicingListEdit.InsertEntity;
begin
  ServicingListDataset.Close;
  ServicingListDataset.SetJsonData('{}');
  ServicingListDataset.Open;
  ServicingListDataset.Insert;
end;

class function TFViewServicingListEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewServicingListEdit(AForm).FInserting := AInserting;
    TFViewServicingListEdit(AForm).FId := AId;
    TFViewServicingListEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewServicingListEdit(AForm).InsertEntity
    else
      TFViewServicingListEdit(AForm).LoadEntity;
    TFViewServicingListEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewServicingListEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewServicingListEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    ServicingListDataset.Connection.Model.DefaultSchema.FindEntityType(
      ServicingListDataset.EntitySetName
    )
  );
  XDataClientServicingListGet.Get(ServicingListDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewServicingListEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewServicingListEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewServicingListEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewServicingListEdit.XDataClientServicingListGetLoad(Response: TXDataClientResponse);
begin
  ServicingListDataset.Close;
  ServicingListDataset.SetJsonData(Response.Result);
  ServicingListDataset.Open;
  
  ServicingListDataset.Edit;  
end;

procedure TFViewServicingListEdit.XDataClientServicingListDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewServicingListEdit.XDataClientServicingListDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewServicingListEdit.ServicingListDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewServicingListEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewServicingListEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbser_Id := TLabel.Create('view.servicinglist.form.lbser_Id');
  lbser_Description := TLabel.Create('view.servicinglist.form.lbser_Description');
  lbser_Notes := TLabel.Create('view.servicinglist.form.lbser_Notes');
  lbcs_Id := TLabel.Create('view.servicinglist.form.lbcs_Id');
  lbser_AssignTo := TLabel.Create('view.servicinglist.form.lbser_AssignTo');
  lbser_DueDate := TLabel.Create('view.servicinglist.form.lbser_DueDate');
  lbser_CompletedDate := TLabel.Create('view.servicinglist.form.lbser_CompletedDate');
  lbser_Completed := TLabel.Create('view.servicinglist.form.lbser_Completed');
  lbser_CreatedBy := TLabel.Create('view.servicinglist.form.lbser_CreatedBy');
  lbser_CreatedDate := TLabel.Create('view.servicinglist.form.lbser_CreatedDate');
  lbser_LastModifiedBy := TLabel.Create('view.servicinglist.form.lbser_LastModifiedBy');
  lbser_LastModifiedDate := TLabel.Create('view.servicinglist.form.lbser_LastModifiedDate');
  pnlMessage := TPanel.Create('view.servicinglist.form.message');
  lbMessage := TLabel.Create('view.servicinglist.form.message.label');
  btCloseMessage := TButton.Create('view.servicinglist.form.message.button');
  btCancel := TButton.Create('view.servicinglist.form.btBack');
  btSave := TButton.Create('view.servicinglist.form.btSave');
  btDelete := TButton.Create('view.servicinglist.form.btDelete');
  edser_Id := TDBEdit.Create('view.servicinglist.form.edser_Id');
  edser_Description := TDBEdit.Create('view.servicinglist.form.edser_Description');
  edser_Notes := TDBEdit.Create('view.servicinglist.form.edser_Notes');
  edcs_Id := TDBEdit.Create('view.servicinglist.form.edcs_Id');
  edser_AssignTo := TDBEdit.Create('view.servicinglist.form.edser_AssignTo');
  edser_DueDate := TDBEdit.Create('view.servicinglist.form.edser_DueDate');
  edser_CompletedDate := TDBEdit.Create('view.servicinglist.form.edser_CompletedDate');
  edser_Completed := TDBEdit.Create('view.servicinglist.form.edser_Completed');
  edser_CreatedBy := TDBEdit.Create('view.servicinglist.form.edser_CreatedBy');
  edser_CreatedDate := TDBEdit.Create('view.servicinglist.form.edser_CreatedDate');
  edser_LastModifiedBy := TDBEdit.Create('view.servicinglist.form.edser_LastModifiedBy');
  edser_LastModifiedDate := TDBEdit.Create('view.servicinglist.form.edser_LastModifiedDate');
  DataSource := TDataSource.Create(Self);
  ServicingListDataset := TXDataWebDataSet.Create(Self);
  ServicingListDatasetser_Id := TIntegerField.Create(Self);
  ServicingListDatasetser_Description := TStringField.Create(Self);
  ServicingListDatasetser_Notes := TStringField.Create(Self);
  ServicingListDatasetcs_Id := TIntegerField.Create(Self);
  ServicingListDatasetser_AssignTo := TIntegerField.Create(Self);
  ServicingListDatasetser_DueDate := TDateTimeField.Create(Self);
  ServicingListDatasetser_CompletedDate := TDateTimeField.Create(Self);
  ServicingListDatasetser_Completed := TIntegerField.Create(Self);
  ServicingListDatasetser_CreatedBy := TIntegerField.Create(Self);
  ServicingListDatasetser_CreatedDate := TDateTimeField.Create(Self);
  ServicingListDatasetser_LastModifiedBy := TIntegerField.Create(Self);
  ServicingListDatasetser_LastModifiedDate := TDateTimeField.Create(Self);
  XDataClientServicingListGet := TXDataWebClient.Create(Self);
  XDataClientServicingListDelete := TXDataWebClient.Create(Self);

  lbser_Id.BeforeLoadDFMValues;
  lbser_Description.BeforeLoadDFMValues;
  lbser_Notes.BeforeLoadDFMValues;
  lbcs_Id.BeforeLoadDFMValues;
  lbser_AssignTo.BeforeLoadDFMValues;
  lbser_DueDate.BeforeLoadDFMValues;
  lbser_CompletedDate.BeforeLoadDFMValues;
  lbser_Completed.BeforeLoadDFMValues;
  lbser_CreatedBy.BeforeLoadDFMValues;
  lbser_CreatedDate.BeforeLoadDFMValues;
  lbser_LastModifiedBy.BeforeLoadDFMValues;
  lbser_LastModifiedDate.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edser_Id.BeforeLoadDFMValues;
  edser_Description.BeforeLoadDFMValues;
  edser_Notes.BeforeLoadDFMValues;
  edcs_Id.BeforeLoadDFMValues;
  edser_AssignTo.BeforeLoadDFMValues;
  edser_DueDate.BeforeLoadDFMValues;
  edser_CompletedDate.BeforeLoadDFMValues;
  edser_Completed.BeforeLoadDFMValues;
  edser_CreatedBy.BeforeLoadDFMValues;
  edser_CreatedDate.BeforeLoadDFMValues;
  edser_LastModifiedBy.BeforeLoadDFMValues;
  edser_LastModifiedDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  ServicingListDataset.BeforeLoadDFMValues;
  ServicingListDatasetser_Id.BeforeLoadDFMValues;
  ServicingListDatasetser_Description.BeforeLoadDFMValues;
  ServicingListDatasetser_Notes.BeforeLoadDFMValues;
  ServicingListDatasetcs_Id.BeforeLoadDFMValues;
  ServicingListDatasetser_AssignTo.BeforeLoadDFMValues;
  ServicingListDatasetser_DueDate.BeforeLoadDFMValues;
  ServicingListDatasetser_CompletedDate.BeforeLoadDFMValues;
  ServicingListDatasetser_Completed.BeforeLoadDFMValues;
  ServicingListDatasetser_CreatedBy.BeforeLoadDFMValues;
  ServicingListDatasetser_CreatedDate.BeforeLoadDFMValues;
  ServicingListDatasetser_LastModifiedBy.BeforeLoadDFMValues;
  ServicingListDatasetser_LastModifiedDate.BeforeLoadDFMValues;
  XDataClientServicingListGet.BeforeLoadDFMValues;
  XDataClientServicingListDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewServicingListEdit';
    Width := 593;
    Height := 589;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbser_Id.SetParentComponent(Self);
    lbser_Id.Name := 'lbser_Id';
    lbser_Id.Left := 24;
    lbser_Id.Top := 192;
    lbser_Id.Width := 35;
    lbser_Id.Height := 13;
    lbser_Id.Caption := 'ser_Id:';
    lbser_Id.HeightPercent := 100.000000000000000000;
    lbser_Id.WidthPercent := 100.000000000000000000;
    lbser_Description.SetParentComponent(Self);
    lbser_Description.Name := 'lbser_Description';
    lbser_Description.Left := 24;
    lbser_Description.Top := 219;
    lbser_Description.Width := 78;
    lbser_Description.Height := 13;
    lbser_Description.Caption := 'ser_Description:';
    lbser_Description.HeightPercent := 100.000000000000000000;
    lbser_Description.WidthPercent := 100.000000000000000000;
    lbser_Notes.SetParentComponent(Self);
    lbser_Notes.Name := 'lbser_Notes';
    lbser_Notes.Left := 24;
    lbser_Notes.Top := 246;
    lbser_Notes.Width := 53;
    lbser_Notes.Height := 13;
    lbser_Notes.Caption := 'ser_Notes:';
    lbser_Notes.HeightPercent := 100.000000000000000000;
    lbser_Notes.WidthPercent := 100.000000000000000000;
    lbcs_Id.SetParentComponent(Self);
    lbcs_Id.Name := 'lbcs_Id';
    lbcs_Id.Left := 24;
    lbcs_Id.Top := 273;
    lbcs_Id.Width := 30;
    lbcs_Id.Height := 13;
    lbcs_Id.Caption := 'cs_Id:';
    lbcs_Id.HeightPercent := 100.000000000000000000;
    lbcs_Id.WidthPercent := 100.000000000000000000;
    lbser_AssignTo.SetParentComponent(Self);
    lbser_AssignTo.Name := 'lbser_AssignTo';
    lbser_AssignTo.Left := 24;
    lbser_AssignTo.Top := 300;
    lbser_AssignTo.Width := 68;
    lbser_AssignTo.Height := 13;
    lbser_AssignTo.Caption := 'ser_AssignTo:';
    lbser_AssignTo.HeightPercent := 100.000000000000000000;
    lbser_AssignTo.WidthPercent := 100.000000000000000000;
    lbser_DueDate.SetParentComponent(Self);
    lbser_DueDate.Name := 'lbser_DueDate';
    lbser_DueDate.Left := 24;
    lbser_DueDate.Top := 327;
    lbser_DueDate.Width := 67;
    lbser_DueDate.Height := 13;
    lbser_DueDate.Caption := 'ser_DueDate:';
    lbser_DueDate.HeightPercent := 100.000000000000000000;
    lbser_DueDate.WidthPercent := 100.000000000000000000;
    lbser_CompletedDate.SetParentComponent(Self);
    lbser_CompletedDate.Name := 'lbser_CompletedDate';
    lbser_CompletedDate.Left := 24;
    lbser_CompletedDate.Top := 354;
    lbser_CompletedDate.Width := 99;
    lbser_CompletedDate.Height := 13;
    lbser_CompletedDate.Caption := 'ser_CompletedDate:';
    lbser_CompletedDate.HeightPercent := 100.000000000000000000;
    lbser_CompletedDate.WidthPercent := 100.000000000000000000;
    lbser_Completed.SetParentComponent(Self);
    lbser_Completed.Name := 'lbser_Completed';
    lbser_Completed.Left := 24;
    lbser_Completed.Top := 381;
    lbser_Completed.Width := 76;
    lbser_Completed.Height := 13;
    lbser_Completed.Caption := 'ser_Completed:';
    lbser_Completed.HeightPercent := 100.000000000000000000;
    lbser_Completed.WidthPercent := 100.000000000000000000;
    lbser_CreatedBy.SetParentComponent(Self);
    lbser_CreatedBy.Name := 'lbser_CreatedBy';
    lbser_CreatedBy.Left := 24;
    lbser_CreatedBy.Top := 408;
    lbser_CreatedBy.Width := 76;
    lbser_CreatedBy.Height := 13;
    lbser_CreatedBy.Caption := 'ser_CreatedBy:';
    lbser_CreatedBy.HeightPercent := 100.000000000000000000;
    lbser_CreatedBy.WidthPercent := 100.000000000000000000;
    lbser_CreatedDate.SetParentComponent(Self);
    lbser_CreatedDate.Name := 'lbser_CreatedDate';
    lbser_CreatedDate.Left := 24;
    lbser_CreatedDate.Top := 435;
    lbser_CreatedDate.Width := 87;
    lbser_CreatedDate.Height := 13;
    lbser_CreatedDate.Caption := 'ser_CreatedDate:';
    lbser_CreatedDate.HeightPercent := 100.000000000000000000;
    lbser_CreatedDate.WidthPercent := 100.000000000000000000;
    lbser_LastModifiedBy.SetParentComponent(Self);
    lbser_LastModifiedBy.Name := 'lbser_LastModifiedBy';
    lbser_LastModifiedBy.Left := 24;
    lbser_LastModifiedBy.Top := 462;
    lbser_LastModifiedBy.Width := 97;
    lbser_LastModifiedBy.Height := 13;
    lbser_LastModifiedBy.Caption := 'ser_LastModifiedBy:';
    lbser_LastModifiedBy.HeightPercent := 100.000000000000000000;
    lbser_LastModifiedBy.WidthPercent := 100.000000000000000000;
    lbser_LastModifiedDate.SetParentComponent(Self);
    lbser_LastModifiedDate.Name := 'lbser_LastModifiedDate';
    lbser_LastModifiedDate.Left := 24;
    lbser_LastModifiedDate.Top := 489;
    lbser_LastModifiedDate.Width := 108;
    lbser_LastModifiedDate.Height := 13;
    lbser_LastModifiedDate.Caption := 'ser_LastModifiedDate:';
    lbser_LastModifiedDate.HeightPercent := 100.000000000000000000;
    lbser_LastModifiedDate.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 529;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 529;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 529;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edser_Id.SetParentComponent(Self);
    edser_Id.Name := 'edser_Id';
    edser_Id.Left := 221;
    edser_Id.Top := 189;
    edser_Id.Width := 200;
    edser_Id.Height := 21;
    edser_Id.HeightPercent := 100.000000000000000000;
    edser_Id.TabOrder := 6;
    edser_Id.Text := 'edser_Id';
    edser_Id.WidthPercent := 100.000000000000000000;
    edser_Id.DataField := 'ser_Id';
    edser_Id.DataSource := DataSource;
    edser_Description.SetParentComponent(Self);
    edser_Description.Name := 'edser_Description';
    edser_Description.Left := 221;
    edser_Description.Top := 216;
    edser_Description.Width := 200;
    edser_Description.Height := 21;
    edser_Description.HeightPercent := 100.000000000000000000;
    edser_Description.TabOrder := 7;
    edser_Description.Text := 'edser_Description';
    edser_Description.WidthPercent := 100.000000000000000000;
    edser_Description.DataField := 'ser_Description';
    edser_Description.DataSource := DataSource;
    edser_Notes.SetParentComponent(Self);
    edser_Notes.Name := 'edser_Notes';
    edser_Notes.Left := 221;
    edser_Notes.Top := 243;
    edser_Notes.Width := 200;
    edser_Notes.Height := 21;
    edser_Notes.HeightPercent := 100.000000000000000000;
    edser_Notes.TabOrder := 8;
    edser_Notes.Text := 'edser_Notes';
    edser_Notes.WidthPercent := 100.000000000000000000;
    edser_Notes.DataField := 'ser_Notes';
    edser_Notes.DataSource := DataSource;
    edcs_Id.SetParentComponent(Self);
    edcs_Id.Name := 'edcs_Id';
    edcs_Id.Left := 221;
    edcs_Id.Top := 270;
    edcs_Id.Width := 200;
    edcs_Id.Height := 21;
    edcs_Id.HeightPercent := 100.000000000000000000;
    edcs_Id.TabOrder := 9;
    edcs_Id.Text := 'edcs_Id';
    edcs_Id.WidthPercent := 100.000000000000000000;
    edcs_Id.DataField := 'cs_Id';
    edcs_Id.DataSource := DataSource;
    edser_AssignTo.SetParentComponent(Self);
    edser_AssignTo.Name := 'edser_AssignTo';
    edser_AssignTo.Left := 221;
    edser_AssignTo.Top := 297;
    edser_AssignTo.Width := 200;
    edser_AssignTo.Height := 21;
    edser_AssignTo.HeightPercent := 100.000000000000000000;
    edser_AssignTo.TabOrder := 10;
    edser_AssignTo.Text := 'edser_AssignTo';
    edser_AssignTo.WidthPercent := 100.000000000000000000;
    edser_AssignTo.DataField := 'ser_AssignTo';
    edser_AssignTo.DataSource := DataSource;
    edser_DueDate.SetParentComponent(Self);
    edser_DueDate.Name := 'edser_DueDate';
    edser_DueDate.Left := 221;
    edser_DueDate.Top := 324;
    edser_DueDate.Width := 200;
    edser_DueDate.Height := 21;
    edser_DueDate.HeightPercent := 100.000000000000000000;
    edser_DueDate.TabOrder := 11;
    edser_DueDate.Text := 'edser_DueDate';
    edser_DueDate.WidthPercent := 100.000000000000000000;
    edser_DueDate.DataField := 'ser_DueDate';
    edser_DueDate.DataSource := DataSource;
    edser_CompletedDate.SetParentComponent(Self);
    edser_CompletedDate.Name := 'edser_CompletedDate';
    edser_CompletedDate.Left := 221;
    edser_CompletedDate.Top := 351;
    edser_CompletedDate.Width := 200;
    edser_CompletedDate.Height := 21;
    edser_CompletedDate.HeightPercent := 100.000000000000000000;
    edser_CompletedDate.TabOrder := 12;
    edser_CompletedDate.Text := 'edser_CompletedDate';
    edser_CompletedDate.WidthPercent := 100.000000000000000000;
    edser_CompletedDate.DataField := 'ser_CompletedDate';
    edser_CompletedDate.DataSource := DataSource;
    edser_Completed.SetParentComponent(Self);
    edser_Completed.Name := 'edser_Completed';
    edser_Completed.Left := 221;
    edser_Completed.Top := 378;
    edser_Completed.Width := 200;
    edser_Completed.Height := 21;
    edser_Completed.HeightPercent := 100.000000000000000000;
    edser_Completed.TabOrder := 13;
    edser_Completed.Text := 'edser_Completed';
    edser_Completed.WidthPercent := 100.000000000000000000;
    edser_Completed.DataField := 'ser_Completed';
    edser_Completed.DataSource := DataSource;
    edser_CreatedBy.SetParentComponent(Self);
    edser_CreatedBy.Name := 'edser_CreatedBy';
    edser_CreatedBy.Left := 221;
    edser_CreatedBy.Top := 405;
    edser_CreatedBy.Width := 200;
    edser_CreatedBy.Height := 21;
    edser_CreatedBy.HeightPercent := 100.000000000000000000;
    edser_CreatedBy.TabOrder := 14;
    edser_CreatedBy.Text := 'edser_CreatedBy';
    edser_CreatedBy.WidthPercent := 100.000000000000000000;
    edser_CreatedBy.DataField := 'ser_CreatedBy';
    edser_CreatedBy.DataSource := DataSource;
    edser_CreatedDate.SetParentComponent(Self);
    edser_CreatedDate.Name := 'edser_CreatedDate';
    edser_CreatedDate.Left := 221;
    edser_CreatedDate.Top := 432;
    edser_CreatedDate.Width := 200;
    edser_CreatedDate.Height := 21;
    edser_CreatedDate.HeightPercent := 100.000000000000000000;
    edser_CreatedDate.TabOrder := 15;
    edser_CreatedDate.Text := 'edser_CreatedDate';
    edser_CreatedDate.WidthPercent := 100.000000000000000000;
    edser_CreatedDate.DataField := 'ser_CreatedDate';
    edser_CreatedDate.DataSource := DataSource;
    edser_LastModifiedBy.SetParentComponent(Self);
    edser_LastModifiedBy.Name := 'edser_LastModifiedBy';
    edser_LastModifiedBy.Left := 221;
    edser_LastModifiedBy.Top := 459;
    edser_LastModifiedBy.Width := 200;
    edser_LastModifiedBy.Height := 21;
    edser_LastModifiedBy.HeightPercent := 100.000000000000000000;
    edser_LastModifiedBy.TabOrder := 16;
    edser_LastModifiedBy.Text := 'edser_LastModifiedBy';
    edser_LastModifiedBy.WidthPercent := 100.000000000000000000;
    edser_LastModifiedBy.DataField := 'ser_LastModifiedBy';
    edser_LastModifiedBy.DataSource := DataSource;
    edser_LastModifiedDate.SetParentComponent(Self);
    edser_LastModifiedDate.Name := 'edser_LastModifiedDate';
    edser_LastModifiedDate.Left := 221;
    edser_LastModifiedDate.Top := 486;
    edser_LastModifiedDate.Width := 200;
    edser_LastModifiedDate.Height := 21;
    edser_LastModifiedDate.HeightPercent := 100.000000000000000000;
    edser_LastModifiedDate.TabOrder := 17;
    edser_LastModifiedDate.Text := 'edser_LastModifiedDate';
    edser_LastModifiedDate.WidthPercent := 100.000000000000000000;
    edser_LastModifiedDate.DataField := 'ser_LastModifiedDate';
    edser_LastModifiedDate.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := ServicingListDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    ServicingListDataset.SetParentComponent(Self);
    ServicingListDataset.Name := 'ServicingListDataset';
    ServicingListDataset.AfterApplyUpdates := ServicingListDatasetAfterApplyUpdates;
    ServicingListDataset.EntitySetName := 'ServicingList';
    ServicingListDataset.Connection := DMConnection.ApiConnection;
    ServicingListDataset.Left := 176;
    ServicingListDataset.Top := 8;
    ServicingListDatasetser_Id.SetParentComponent(ServicingListDataset);
    ServicingListDatasetser_Id.Name := 'ServicingListDatasetser_Id';
    ServicingListDatasetser_Id.FieldName := 'ser_Id';
    ServicingListDatasetser_Id.Required := True;
    ServicingListDatasetser_Description.SetParentComponent(ServicingListDataset);
    ServicingListDatasetser_Description.Name := 'ServicingListDatasetser_Description';
    ServicingListDatasetser_Description.FieldName := 'ser_Description';
    ServicingListDatasetser_Description.Size := 75;
    ServicingListDatasetser_Notes.SetParentComponent(ServicingListDataset);
    ServicingListDatasetser_Notes.Name := 'ServicingListDatasetser_Notes';
    ServicingListDatasetser_Notes.FieldName := 'ser_Notes';
    ServicingListDatasetser_Notes.Size := 250;
    ServicingListDatasetcs_Id.SetParentComponent(ServicingListDataset);
    ServicingListDatasetcs_Id.Name := 'ServicingListDatasetcs_Id';
    ServicingListDatasetcs_Id.FieldName := 'cs_Id';
    ServicingListDatasetser_AssignTo.SetParentComponent(ServicingListDataset);
    ServicingListDatasetser_AssignTo.Name := 'ServicingListDatasetser_AssignTo';
    ServicingListDatasetser_AssignTo.FieldName := 'ser_AssignTo';
    ServicingListDatasetser_DueDate.SetParentComponent(ServicingListDataset);
    ServicingListDatasetser_DueDate.Name := 'ServicingListDatasetser_DueDate';
    ServicingListDatasetser_DueDate.FieldName := 'ser_DueDate';
    ServicingListDatasetser_CompletedDate.SetParentComponent(ServicingListDataset);
    ServicingListDatasetser_CompletedDate.Name := 'ServicingListDatasetser_CompletedDate';
    ServicingListDatasetser_CompletedDate.FieldName := 'ser_CompletedDate';
    ServicingListDatasetser_Completed.SetParentComponent(ServicingListDataset);
    ServicingListDatasetser_Completed.Name := 'ServicingListDatasetser_Completed';
    ServicingListDatasetser_Completed.FieldName := 'ser_Completed';
    ServicingListDatasetser_CreatedBy.SetParentComponent(ServicingListDataset);
    ServicingListDatasetser_CreatedBy.Name := 'ServicingListDatasetser_CreatedBy';
    ServicingListDatasetser_CreatedBy.FieldName := 'ser_CreatedBy';
    ServicingListDatasetser_CreatedDate.SetParentComponent(ServicingListDataset);
    ServicingListDatasetser_CreatedDate.Name := 'ServicingListDatasetser_CreatedDate';
    ServicingListDatasetser_CreatedDate.FieldName := 'ser_CreatedDate';
    ServicingListDatasetser_LastModifiedBy.SetParentComponent(ServicingListDataset);
    ServicingListDatasetser_LastModifiedBy.Name := 'ServicingListDatasetser_LastModifiedBy';
    ServicingListDatasetser_LastModifiedBy.FieldName := 'ser_LastModifiedBy';
    ServicingListDatasetser_LastModifiedDate.SetParentComponent(ServicingListDataset);
    ServicingListDatasetser_LastModifiedDate.Name := 'ServicingListDatasetser_LastModifiedDate';
    ServicingListDatasetser_LastModifiedDate.FieldName := 'ser_LastModifiedDate';
    XDataClientServicingListGet.SetParentComponent(Self);
    XDataClientServicingListGet.Name := 'XDataClientServicingListGet';
    XDataClientServicingListGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientServicingListGet, Self, 'OnLoad', 'XDataClientServicingListGetLoad');
    XDataClientServicingListGet.Left := 64;
    XDataClientServicingListGet.Top := 8;
    XDataClientServicingListDelete.SetParentComponent(Self);
    XDataClientServicingListDelete.Name := 'XDataClientServicingListDelete';
    XDataClientServicingListDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientServicingListDelete, Self, 'OnLoad', 'XDataClientServicingListDeleteLoad');
    SetEvent(XDataClientServicingListDelete, Self, 'OnError', 'XDataClientServicingListDeleteError');
    XDataClientServicingListDelete.Left := 64;
    XDataClientServicingListDelete.Top := 56;
  finally
    lbser_Id.AfterLoadDFMValues;
    lbser_Description.AfterLoadDFMValues;
    lbser_Notes.AfterLoadDFMValues;
    lbcs_Id.AfterLoadDFMValues;
    lbser_AssignTo.AfterLoadDFMValues;
    lbser_DueDate.AfterLoadDFMValues;
    lbser_CompletedDate.AfterLoadDFMValues;
    lbser_Completed.AfterLoadDFMValues;
    lbser_CreatedBy.AfterLoadDFMValues;
    lbser_CreatedDate.AfterLoadDFMValues;
    lbser_LastModifiedBy.AfterLoadDFMValues;
    lbser_LastModifiedDate.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edser_Id.AfterLoadDFMValues;
    edser_Description.AfterLoadDFMValues;
    edser_Notes.AfterLoadDFMValues;
    edcs_Id.AfterLoadDFMValues;
    edser_AssignTo.AfterLoadDFMValues;
    edser_DueDate.AfterLoadDFMValues;
    edser_CompletedDate.AfterLoadDFMValues;
    edser_Completed.AfterLoadDFMValues;
    edser_CreatedBy.AfterLoadDFMValues;
    edser_CreatedDate.AfterLoadDFMValues;
    edser_LastModifiedBy.AfterLoadDFMValues;
    edser_LastModifiedDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    ServicingListDataset.AfterLoadDFMValues;
    ServicingListDatasetser_Id.AfterLoadDFMValues;
    ServicingListDatasetser_Description.AfterLoadDFMValues;
    ServicingListDatasetser_Notes.AfterLoadDFMValues;
    ServicingListDatasetcs_Id.AfterLoadDFMValues;
    ServicingListDatasetser_AssignTo.AfterLoadDFMValues;
    ServicingListDatasetser_DueDate.AfterLoadDFMValues;
    ServicingListDatasetser_CompletedDate.AfterLoadDFMValues;
    ServicingListDatasetser_Completed.AfterLoadDFMValues;
    ServicingListDatasetser_CreatedBy.AfterLoadDFMValues;
    ServicingListDatasetser_CreatedDate.AfterLoadDFMValues;
    ServicingListDatasetser_LastModifiedBy.AfterLoadDFMValues;
    ServicingListDatasetser_LastModifiedDate.AfterLoadDFMValues;
    XDataClientServicingListGet.AfterLoadDFMValues;
    XDataClientServicingListDelete.AfterLoadDFMValues;
  end;
end;

end.
