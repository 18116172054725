unit View.InvoiceDetail.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewInvoiceDetailEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbdet_Id: TLabel;
    eddet_Id: TDBEdit;
    lbdet_Qty: TLabel;
    eddet_Qty: TDBEdit;
    lbcat_Id: TLabel;
    edcat_Id: TDBEdit;
    lbcs_Id: TLabel;
    edcs_Id: TDBEdit;
    lbra_Id: TLabel;
    edra_Id: TDBEdit;
    lbcp_Id: TLabel;
    edcp_Id: TDBEdit;
    lbdet_Description: TLabel;
    eddet_Description: TDBEdit;
    lbdet_Price: TLabel;
    eddet_Price: TDBEdit;
    lbdet_Ext: TLabel;
    eddet_Ext: TDBEdit;
    XDataClientInvoiceDetailGet: TXDataWebClient;
    XDataClientInvoiceDetailDelete: TXDataWebClient;
    InvoiceDetailDataset: TXDataWebDataSet;
    InvoiceDetailDatasetdet_Id: TIntegerField;
    InvoiceDetailDatasetdet_Qty: TFloatField;
    InvoiceDetailDatasetcat_Id: TIntegerField;
    InvoiceDetailDatasetcs_Id: TIntegerField;
    InvoiceDetailDatasetra_Id: TIntegerField;
    InvoiceDetailDatasetcp_Id: TIntegerField;
    InvoiceDetailDatasetdet_Description: TStringField;
    InvoiceDetailDatasetdet_Price: TFloatField;
    InvoiceDetailDatasetdet_Ext: TFloatField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientInvoiceDetailGetLoad(Response: TXDataClientResponse);
    procedure XDataClientInvoiceDetailDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientInvoiceDetailDeleteError(Error: TXDataClientError);
    procedure InvoiceDetailDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewInvoiceDetailEdit: TFViewInvoiceDetailEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewInvoiceDetailEdit }

procedure TFViewInvoiceDetailEdit.btSaveClick(Sender: TObject);
begin
  if InvoiceDetailDataset.State in dsEditModes then
  begin
        InvoiceDetailDataset.Post;
    InvoiceDetailDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewInvoiceDetailEdit.btDeleteClick(Sender: TObject);
begin
  if InvoiceDetailDataset.State in dsEditModes then
    InvoiceDetailDataset.Post;
  XDataClientInvoiceDetailDelete.Delete(InvoiceDetailDataset.EntitySetName,
    TJSObject(InvoiceDetailDataset.CurrentData));
end;


class function TFViewInvoiceDetailEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewInvoiceDetailEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewInvoiceDetailEdit.InsertEntity;
begin
  InvoiceDetailDataset.Close;
  InvoiceDetailDataset.SetJsonData('{}');
  InvoiceDetailDataset.Open;
  InvoiceDetailDataset.Insert;
end;

class function TFViewInvoiceDetailEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewInvoiceDetailEdit(AForm).FInserting := AInserting;
    TFViewInvoiceDetailEdit(AForm).FId := AId;
    TFViewInvoiceDetailEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewInvoiceDetailEdit(AForm).InsertEntity
    else
      TFViewInvoiceDetailEdit(AForm).LoadEntity;
    TFViewInvoiceDetailEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewInvoiceDetailEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewInvoiceDetailEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    InvoiceDetailDataset.Connection.Model.DefaultSchema.FindEntityType(
      InvoiceDetailDataset.EntitySetName
    )
  );
  XDataClientInvoiceDetailGet.Get(InvoiceDetailDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewInvoiceDetailEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewInvoiceDetailEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewInvoiceDetailEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewInvoiceDetailEdit.XDataClientInvoiceDetailGetLoad(Response: TXDataClientResponse);
begin
  InvoiceDetailDataset.Close;
  InvoiceDetailDataset.SetJsonData(Response.Result);
  InvoiceDetailDataset.Open;
  
  InvoiceDetailDataset.Edit;  
end;

procedure TFViewInvoiceDetailEdit.XDataClientInvoiceDetailDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewInvoiceDetailEdit.XDataClientInvoiceDetailDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewInvoiceDetailEdit.InvoiceDetailDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewInvoiceDetailEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewInvoiceDetailEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbdet_Id := TLabel.Create('view.invoicedetail.form.lbdet_Id');
  lbdet_Qty := TLabel.Create('view.invoicedetail.form.lbdet_Qty');
  lbcat_Id := TLabel.Create('view.invoicedetail.form.lbcat_Id');
  lbcs_Id := TLabel.Create('view.invoicedetail.form.lbcs_Id');
  lbra_Id := TLabel.Create('view.invoicedetail.form.lbra_Id');
  lbcp_Id := TLabel.Create('view.invoicedetail.form.lbcp_Id');
  lbdet_Description := TLabel.Create('view.invoicedetail.form.lbdet_Description');
  lbdet_Price := TLabel.Create('view.invoicedetail.form.lbdet_Price');
  lbdet_Ext := TLabel.Create('view.invoicedetail.form.lbdet_Ext');
  pnlMessage := TPanel.Create('view.invoicedetail.form.message');
  lbMessage := TLabel.Create('view.invoicedetail.form.message.label');
  btCloseMessage := TButton.Create('view.invoicedetail.form.message.button');
  btCancel := TButton.Create('view.invoicedetail.form.btBack');
  btSave := TButton.Create('view.invoicedetail.form.btSave');
  btDelete := TButton.Create('view.invoicedetail.form.btDelete');
  eddet_Id := TDBEdit.Create('view.invoicedetail.form.eddet_Id');
  eddet_Qty := TDBEdit.Create('view.invoicedetail.form.eddet_Qty');
  edcat_Id := TDBEdit.Create('view.invoicedetail.form.edcat_Id');
  edcs_Id := TDBEdit.Create('view.invoicedetail.form.edcs_Id');
  edra_Id := TDBEdit.Create('view.invoicedetail.form.edra_Id');
  edcp_Id := TDBEdit.Create('view.invoicedetail.form.edcp_Id');
  eddet_Description := TDBEdit.Create('view.invoicedetail.form.eddet_Description');
  eddet_Price := TDBEdit.Create('view.invoicedetail.form.eddet_Price');
  eddet_Ext := TDBEdit.Create('view.invoicedetail.form.eddet_Ext');
  DataSource := TDataSource.Create(Self);
  InvoiceDetailDataset := TXDataWebDataSet.Create(Self);
  InvoiceDetailDatasetdet_Id := TIntegerField.Create(Self);
  InvoiceDetailDatasetdet_Qty := TFloatField.Create(Self);
  InvoiceDetailDatasetcat_Id := TIntegerField.Create(Self);
  InvoiceDetailDatasetcs_Id := TIntegerField.Create(Self);
  InvoiceDetailDatasetra_Id := TIntegerField.Create(Self);
  InvoiceDetailDatasetcp_Id := TIntegerField.Create(Self);
  InvoiceDetailDatasetdet_Description := TStringField.Create(Self);
  InvoiceDetailDatasetdet_Price := TFloatField.Create(Self);
  InvoiceDetailDatasetdet_Ext := TFloatField.Create(Self);
  XDataClientInvoiceDetailGet := TXDataWebClient.Create(Self);
  XDataClientInvoiceDetailDelete := TXDataWebClient.Create(Self);

  lbdet_Id.BeforeLoadDFMValues;
  lbdet_Qty.BeforeLoadDFMValues;
  lbcat_Id.BeforeLoadDFMValues;
  lbcs_Id.BeforeLoadDFMValues;
  lbra_Id.BeforeLoadDFMValues;
  lbcp_Id.BeforeLoadDFMValues;
  lbdet_Description.BeforeLoadDFMValues;
  lbdet_Price.BeforeLoadDFMValues;
  lbdet_Ext.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  eddet_Id.BeforeLoadDFMValues;
  eddet_Qty.BeforeLoadDFMValues;
  edcat_Id.BeforeLoadDFMValues;
  edcs_Id.BeforeLoadDFMValues;
  edra_Id.BeforeLoadDFMValues;
  edcp_Id.BeforeLoadDFMValues;
  eddet_Description.BeforeLoadDFMValues;
  eddet_Price.BeforeLoadDFMValues;
  eddet_Ext.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  InvoiceDetailDataset.BeforeLoadDFMValues;
  InvoiceDetailDatasetdet_Id.BeforeLoadDFMValues;
  InvoiceDetailDatasetdet_Qty.BeforeLoadDFMValues;
  InvoiceDetailDatasetcat_Id.BeforeLoadDFMValues;
  InvoiceDetailDatasetcs_Id.BeforeLoadDFMValues;
  InvoiceDetailDatasetra_Id.BeforeLoadDFMValues;
  InvoiceDetailDatasetcp_Id.BeforeLoadDFMValues;
  InvoiceDetailDatasetdet_Description.BeforeLoadDFMValues;
  InvoiceDetailDatasetdet_Price.BeforeLoadDFMValues;
  InvoiceDetailDatasetdet_Ext.BeforeLoadDFMValues;
  XDataClientInvoiceDetailGet.BeforeLoadDFMValues;
  XDataClientInvoiceDetailDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewInvoiceDetailEdit';
    Width := 593;
    Height := 508;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbdet_Id.SetParentComponent(Self);
    lbdet_Id.Name := 'lbdet_Id';
    lbdet_Id.Left := 24;
    lbdet_Id.Top := 192;
    lbdet_Id.Width := 36;
    lbdet_Id.Height := 13;
    lbdet_Id.Caption := 'det_Id:';
    lbdet_Id.HeightPercent := 100.000000000000000000;
    lbdet_Id.WidthPercent := 100.000000000000000000;
    lbdet_Qty.SetParentComponent(Self);
    lbdet_Qty.Name := 'lbdet_Qty';
    lbdet_Qty.Left := 24;
    lbdet_Qty.Top := 219;
    lbdet_Qty.Width := 44;
    lbdet_Qty.Height := 13;
    lbdet_Qty.Caption := 'det_Qty:';
    lbdet_Qty.HeightPercent := 100.000000000000000000;
    lbdet_Qty.WidthPercent := 100.000000000000000000;
    lbcat_Id.SetParentComponent(Self);
    lbcat_Id.Name := 'lbcat_Id';
    lbcat_Id.Left := 24;
    lbcat_Id.Top := 246;
    lbcat_Id.Width := 35;
    lbcat_Id.Height := 13;
    lbcat_Id.Caption := 'cat_Id:';
    lbcat_Id.HeightPercent := 100.000000000000000000;
    lbcat_Id.WidthPercent := 100.000000000000000000;
    lbcs_Id.SetParentComponent(Self);
    lbcs_Id.Name := 'lbcs_Id';
    lbcs_Id.Left := 24;
    lbcs_Id.Top := 273;
    lbcs_Id.Width := 30;
    lbcs_Id.Height := 13;
    lbcs_Id.Caption := 'cs_Id:';
    lbcs_Id.HeightPercent := 100.000000000000000000;
    lbcs_Id.WidthPercent := 100.000000000000000000;
    lbra_Id.SetParentComponent(Self);
    lbra_Id.Name := 'lbra_Id';
    lbra_Id.Left := 24;
    lbra_Id.Top := 300;
    lbra_Id.Width := 30;
    lbra_Id.Height := 13;
    lbra_Id.Caption := 'ra_Id:';
    lbra_Id.HeightPercent := 100.000000000000000000;
    lbra_Id.WidthPercent := 100.000000000000000000;
    lbcp_Id.SetParentComponent(Self);
    lbcp_Id.Name := 'lbcp_Id';
    lbcp_Id.Left := 24;
    lbcp_Id.Top := 327;
    lbcp_Id.Width := 31;
    lbcp_Id.Height := 13;
    lbcp_Id.Caption := 'cp_Id:';
    lbcp_Id.HeightPercent := 100.000000000000000000;
    lbcp_Id.WidthPercent := 100.000000000000000000;
    lbdet_Description.SetParentComponent(Self);
    lbdet_Description.Name := 'lbdet_Description';
    lbdet_Description.Left := 24;
    lbdet_Description.Top := 354;
    lbdet_Description.Width := 79;
    lbdet_Description.Height := 13;
    lbdet_Description.Caption := 'det_Description:';
    lbdet_Description.HeightPercent := 100.000000000000000000;
    lbdet_Description.WidthPercent := 100.000000000000000000;
    lbdet_Price.SetParentComponent(Self);
    lbdet_Price.Name := 'lbdet_Price';
    lbdet_Price.Left := 24;
    lbdet_Price.Top := 381;
    lbdet_Price.Width := 49;
    lbdet_Price.Height := 13;
    lbdet_Price.Caption := 'det_Price:';
    lbdet_Price.HeightPercent := 100.000000000000000000;
    lbdet_Price.WidthPercent := 100.000000000000000000;
    lbdet_Ext.SetParentComponent(Self);
    lbdet_Ext.Name := 'lbdet_Ext';
    lbdet_Ext.Left := 24;
    lbdet_Ext.Top := 408;
    lbdet_Ext.Width := 42;
    lbdet_Ext.Height := 13;
    lbdet_Ext.Caption := 'det_Ext:';
    lbdet_Ext.HeightPercent := 100.000000000000000000;
    lbdet_Ext.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 448;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 448;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 448;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    eddet_Id.SetParentComponent(Self);
    eddet_Id.Name := 'eddet_Id';
    eddet_Id.Left := 221;
    eddet_Id.Top := 189;
    eddet_Id.Width := 200;
    eddet_Id.Height := 21;
    eddet_Id.HeightPercent := 100.000000000000000000;
    eddet_Id.TabOrder := 6;
    eddet_Id.Text := 'eddet_Id';
    eddet_Id.WidthPercent := 100.000000000000000000;
    eddet_Id.DataField := 'det_Id';
    eddet_Id.DataSource := DataSource;
    eddet_Qty.SetParentComponent(Self);
    eddet_Qty.Name := 'eddet_Qty';
    eddet_Qty.Left := 221;
    eddet_Qty.Top := 216;
    eddet_Qty.Width := 200;
    eddet_Qty.Height := 21;
    eddet_Qty.HeightPercent := 100.000000000000000000;
    eddet_Qty.TabOrder := 7;
    eddet_Qty.Text := 'eddet_Qty';
    eddet_Qty.WidthPercent := 100.000000000000000000;
    eddet_Qty.DataField := 'det_Qty';
    eddet_Qty.DataSource := DataSource;
    edcat_Id.SetParentComponent(Self);
    edcat_Id.Name := 'edcat_Id';
    edcat_Id.Left := 221;
    edcat_Id.Top := 243;
    edcat_Id.Width := 200;
    edcat_Id.Height := 21;
    edcat_Id.HeightPercent := 100.000000000000000000;
    edcat_Id.TabOrder := 8;
    edcat_Id.Text := 'edcat_Id';
    edcat_Id.WidthPercent := 100.000000000000000000;
    edcat_Id.DataField := 'cat_Id';
    edcat_Id.DataSource := DataSource;
    edcs_Id.SetParentComponent(Self);
    edcs_Id.Name := 'edcs_Id';
    edcs_Id.Left := 221;
    edcs_Id.Top := 270;
    edcs_Id.Width := 200;
    edcs_Id.Height := 21;
    edcs_Id.HeightPercent := 100.000000000000000000;
    edcs_Id.TabOrder := 9;
    edcs_Id.Text := 'edcs_Id';
    edcs_Id.WidthPercent := 100.000000000000000000;
    edcs_Id.DataField := 'cs_Id';
    edcs_Id.DataSource := DataSource;
    edra_Id.SetParentComponent(Self);
    edra_Id.Name := 'edra_Id';
    edra_Id.Left := 221;
    edra_Id.Top := 297;
    edra_Id.Width := 200;
    edra_Id.Height := 21;
    edra_Id.HeightPercent := 100.000000000000000000;
    edra_Id.TabOrder := 10;
    edra_Id.Text := 'edra_Id';
    edra_Id.WidthPercent := 100.000000000000000000;
    edra_Id.DataField := 'ra_Id';
    edra_Id.DataSource := DataSource;
    edcp_Id.SetParentComponent(Self);
    edcp_Id.Name := 'edcp_Id';
    edcp_Id.Left := 221;
    edcp_Id.Top := 324;
    edcp_Id.Width := 200;
    edcp_Id.Height := 21;
    edcp_Id.HeightPercent := 100.000000000000000000;
    edcp_Id.TabOrder := 11;
    edcp_Id.Text := 'edcp_Id';
    edcp_Id.WidthPercent := 100.000000000000000000;
    edcp_Id.DataField := 'cp_Id';
    edcp_Id.DataSource := DataSource;
    eddet_Description.SetParentComponent(Self);
    eddet_Description.Name := 'eddet_Description';
    eddet_Description.Left := 221;
    eddet_Description.Top := 351;
    eddet_Description.Width := 200;
    eddet_Description.Height := 21;
    eddet_Description.HeightPercent := 100.000000000000000000;
    eddet_Description.TabOrder := 12;
    eddet_Description.Text := 'eddet_Description';
    eddet_Description.WidthPercent := 100.000000000000000000;
    eddet_Description.DataField := 'det_Description';
    eddet_Description.DataSource := DataSource;
    eddet_Price.SetParentComponent(Self);
    eddet_Price.Name := 'eddet_Price';
    eddet_Price.Left := 221;
    eddet_Price.Top := 378;
    eddet_Price.Width := 200;
    eddet_Price.Height := 21;
    eddet_Price.HeightPercent := 100.000000000000000000;
    eddet_Price.TabOrder := 13;
    eddet_Price.Text := 'eddet_Price';
    eddet_Price.WidthPercent := 100.000000000000000000;
    eddet_Price.DataField := 'det_Price';
    eddet_Price.DataSource := DataSource;
    eddet_Ext.SetParentComponent(Self);
    eddet_Ext.Name := 'eddet_Ext';
    eddet_Ext.Left := 221;
    eddet_Ext.Top := 405;
    eddet_Ext.Width := 200;
    eddet_Ext.Height := 21;
    eddet_Ext.HeightPercent := 100.000000000000000000;
    eddet_Ext.TabOrder := 14;
    eddet_Ext.Text := 'eddet_Ext';
    eddet_Ext.WidthPercent := 100.000000000000000000;
    eddet_Ext.DataField := 'det_Ext';
    eddet_Ext.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := InvoiceDetailDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    InvoiceDetailDataset.SetParentComponent(Self);
    InvoiceDetailDataset.Name := 'InvoiceDetailDataset';
    InvoiceDetailDataset.AfterApplyUpdates := InvoiceDetailDatasetAfterApplyUpdates;
    InvoiceDetailDataset.EntitySetName := 'InvoiceDetail';
    InvoiceDetailDataset.Connection := DMConnection.ApiConnection;
    InvoiceDetailDataset.Left := 176;
    InvoiceDetailDataset.Top := 8;
    InvoiceDetailDatasetdet_Id.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetdet_Id.Name := 'InvoiceDetailDatasetdet_Id';
    InvoiceDetailDatasetdet_Id.FieldName := 'det_Id';
    InvoiceDetailDatasetdet_Id.Required := True;
    InvoiceDetailDatasetdet_Qty.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetdet_Qty.Name := 'InvoiceDetailDatasetdet_Qty';
    InvoiceDetailDatasetdet_Qty.FieldName := 'det_Qty';
    InvoiceDetailDatasetcat_Id.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetcat_Id.Name := 'InvoiceDetailDatasetcat_Id';
    InvoiceDetailDatasetcat_Id.FieldName := 'cat_Id';
    InvoiceDetailDatasetcs_Id.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetcs_Id.Name := 'InvoiceDetailDatasetcs_Id';
    InvoiceDetailDatasetcs_Id.FieldName := 'cs_Id';
    InvoiceDetailDatasetra_Id.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetra_Id.Name := 'InvoiceDetailDatasetra_Id';
    InvoiceDetailDatasetra_Id.FieldName := 'ra_Id';
    InvoiceDetailDatasetcp_Id.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetcp_Id.Name := 'InvoiceDetailDatasetcp_Id';
    InvoiceDetailDatasetcp_Id.FieldName := 'cp_Id';
    InvoiceDetailDatasetdet_Description.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetdet_Description.Name := 'InvoiceDetailDatasetdet_Description';
    InvoiceDetailDatasetdet_Description.FieldName := 'det_Description';
    InvoiceDetailDatasetdet_Description.Size := 150;
    InvoiceDetailDatasetdet_Price.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetdet_Price.Name := 'InvoiceDetailDatasetdet_Price';
    InvoiceDetailDatasetdet_Price.FieldName := 'det_Price';
    InvoiceDetailDatasetdet_Ext.SetParentComponent(InvoiceDetailDataset);
    InvoiceDetailDatasetdet_Ext.Name := 'InvoiceDetailDatasetdet_Ext';
    InvoiceDetailDatasetdet_Ext.FieldName := 'det_Ext';
    XDataClientInvoiceDetailGet.SetParentComponent(Self);
    XDataClientInvoiceDetailGet.Name := 'XDataClientInvoiceDetailGet';
    XDataClientInvoiceDetailGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientInvoiceDetailGet, Self, 'OnLoad', 'XDataClientInvoiceDetailGetLoad');
    XDataClientInvoiceDetailGet.Left := 64;
    XDataClientInvoiceDetailGet.Top := 8;
    XDataClientInvoiceDetailDelete.SetParentComponent(Self);
    XDataClientInvoiceDetailDelete.Name := 'XDataClientInvoiceDetailDelete';
    XDataClientInvoiceDetailDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientInvoiceDetailDelete, Self, 'OnLoad', 'XDataClientInvoiceDetailDeleteLoad');
    SetEvent(XDataClientInvoiceDetailDelete, Self, 'OnError', 'XDataClientInvoiceDetailDeleteError');
    XDataClientInvoiceDetailDelete.Left := 64;
    XDataClientInvoiceDetailDelete.Top := 56;
  finally
    lbdet_Id.AfterLoadDFMValues;
    lbdet_Qty.AfterLoadDFMValues;
    lbcat_Id.AfterLoadDFMValues;
    lbcs_Id.AfterLoadDFMValues;
    lbra_Id.AfterLoadDFMValues;
    lbcp_Id.AfterLoadDFMValues;
    lbdet_Description.AfterLoadDFMValues;
    lbdet_Price.AfterLoadDFMValues;
    lbdet_Ext.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    eddet_Id.AfterLoadDFMValues;
    eddet_Qty.AfterLoadDFMValues;
    edcat_Id.AfterLoadDFMValues;
    edcs_Id.AfterLoadDFMValues;
    edra_Id.AfterLoadDFMValues;
    edcp_Id.AfterLoadDFMValues;
    eddet_Description.AfterLoadDFMValues;
    eddet_Price.AfterLoadDFMValues;
    eddet_Ext.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    InvoiceDetailDataset.AfterLoadDFMValues;
    InvoiceDetailDatasetdet_Id.AfterLoadDFMValues;
    InvoiceDetailDatasetdet_Qty.AfterLoadDFMValues;
    InvoiceDetailDatasetcat_Id.AfterLoadDFMValues;
    InvoiceDetailDatasetcs_Id.AfterLoadDFMValues;
    InvoiceDetailDatasetra_Id.AfterLoadDFMValues;
    InvoiceDetailDatasetcp_Id.AfterLoadDFMValues;
    InvoiceDetailDatasetdet_Description.AfterLoadDFMValues;
    InvoiceDetailDatasetdet_Price.AfterLoadDFMValues;
    InvoiceDetailDatasetdet_Ext.AfterLoadDFMValues;
    XDataClientInvoiceDetailGet.AfterLoadDFMValues;
    XDataClientInvoiceDetailDelete.AfterLoadDFMValues;
  end;
end;

end.
