unit View.Reserv.Edit;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,

  Bcl.Rtti.Common, XData.Web.Client, Data.DB, WEBLib.CDS,
  WEBLib.DBCtrls, XData.Web.JsonDataset, XData.Web.Dataset, JS,

  App.Types, Crud.Utils, WEBLib.DB, WEBLib.Controls, WEBLib.ExtCtrls;

type
  TFViewReservEdit = class(TForm)
    pnlMessage: TPanel;
    lbMessage: TLabel;
    btCloseMessage: TButton;
    DataSource: TDatasource;
    btCancel: TButton;
    btSave: TButton;
    btDelete: TButton;
    lbres_Id: TLabel;
    edres_Id: TDBEdit;
    lbcs_Id: TLabel;
    edcs_Id: TDBEdit;
    lbcli_Id: TLabel;
    edcli_Id: TDBEdit;
    lbinv_Id: TLabel;
    edinv_Id: TDBEdit;
    lbres_DateFrom: TLabel;
    edres_DateFrom: TDBEdit;
    lbres_DateTo: TLabel;
    edres_DateTo: TDBEdit;
    lbres_NbrDays: TLabel;
    edres_NbrDays: TDBEdit;
    lbres_AmountToPay: TLabel;
    edres_AmountToPay: TDBEdit;
    lbres_ManualOrOnline: TLabel;
    edres_ManualOrOnline: TDBEdit;
    lbres_CreatedBy: TLabel;
    edres_CreatedBy: TDBEdit;
    lbres_CreatedDate: TLabel;
    edres_CreatedDate: TDBEdit;
    lbres_LastModifiedBy: TLabel;
    edres_LastModifiedBy: TDBEdit;
    lbres_LastModifiedDate: TLabel;
    edres_LastModifiedDate: TDBEdit;
    XDataClientReservGet: TXDataWebClient;
    XDataClientReservDelete: TXDataWebClient;
    ReservDataset: TXDataWebDataSet;
    ReservDatasetres_Id: TIntegerField;
    ReservDatasetcs_Id: TIntegerField;
    ReservDatasetcli_Id: TIntegerField;
    ReservDatasetinv_Id: TIntegerField;
    ReservDatasetres_DateFrom: TDateField;
    ReservDatasetres_DateTo: TDateField;
    ReservDatasetres_NbrDays: TIntegerField;
    ReservDatasetres_AmountToPay: TIntegerField;
    ReservDatasetres_ManualOrOnline: TIntegerField;
    ReservDatasetres_CreatedBy: TIntegerField;
    ReservDatasetres_CreatedDate: TDateTimeField;
    ReservDatasetres_LastModifiedBy: TIntegerField;
    ReservDatasetres_LastModifiedDate: TDateTimeField;
    procedure btCloseMessageClick(Sender: TObject);
    procedure btCancelClick(Sender: TObject);
    procedure btSaveClick(Sender: TObject);
    procedure btDeleteClick(Sender: TObject);
    procedure XDataClientReservGetLoad(Response: TXDataClientResponse);
    procedure XDataClientReservDeleteLoad(Response: TXDataClientResponse);
    procedure XDataClientReservDeleteError(Error: TXDataClientError);
    procedure ReservDatasetAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebFormCreate(Sender: TObject);
  private
    FId: JSValue;
    FShowListProc: TListProc;
    procedure InsertEntity;
    procedure LoadEntity;
    procedure ShowNotification(Notification: string);
    procedure HiddenNotification;
    class function InternalCreateForm(AElementID: string;
      AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm; overload;
  protected
    FInserting: Boolean;
  public
    class function CreateForm(AElementID: string;
      AShowListProc: TListProc; AId: JSValue): TForm; overload;
  protected procedure LoadDFMValues; override; end;

var
  FViewReservEdit: TFViewReservEdit;

implementation

uses
  Container.Connection;

{$R *.dfm}

{ TFViewReservEdit }

procedure TFViewReservEdit.btSaveClick(Sender: TObject);
begin
  if ReservDataset.State in dsEditModes then
  begin
        ReservDataset.Post;
    ReservDataset.ApplyUpdates;
  end
  else
    FShowListProc;
end;

procedure TFViewReservEdit.btDeleteClick(Sender: TObject);
begin
  if ReservDataset.State in dsEditModes then
    ReservDataset.Post;
  XDataClientReservDelete.Delete(ReservDataset.EntitySetName,
    TJSObject(ReservDataset.CurrentData));
end;


class function TFViewReservEdit.CreateForm(AElementID: string;
  AShowListProc: TListProc; AId: JSValue): TForm;
var
  Inserting: Boolean;
begin
  Inserting := isNull(AId);
  Result := InternalCreateForm(AElementID, AShowListProc, Inserting, AId);
end;

procedure TFViewReservEdit.HiddenNotification;
begin
  pnlMessage.ElementHandle.hidden := True;
end;

procedure TFViewReservEdit.InsertEntity;
begin
  ReservDataset.Close;
  ReservDataset.SetJsonData('{}');
  ReservDataset.Open;
  ReservDataset.Insert;
end;

class function TFViewReservEdit.InternalCreateForm(AElementID: string;
  AShowListProc: TListProc; AInserting: Boolean; AId: JSValue): TForm;

  procedure AfterCreate(AForm: TObject);
  begin
    TFViewReservEdit(AForm).FInserting := AInserting;
    TFViewReservEdit(AForm).FId := AId;
    TFViewReservEdit(AForm).FShowListProc := AShowListProc;
    if AInserting then
      TFViewReservEdit(AForm).InsertEntity
    else
      TFViewReservEdit(AForm).LoadEntity;
    TFViewReservEdit(AForm).btDelete.Enabled := not AInserting;
  end;

begin
  Application.CreateForm(TFViewReservEdit, AElementID, Result, @AfterCreate);
end;

procedure TFViewReservEdit.LoadEntity;
var
  QueryString: string;
begin
  QueryString := TCrudUtils.GetEntityGetQueryString(
    ReservDataset.Connection.Model.DefaultSchema.FindEntityType(
      ReservDataset.EntitySetName
    )
  );
  XDataClientReservGet.Get(ReservDataset.EntitySetName, QueryString, FId);
end;

procedure TFViewReservEdit.ShowNotification(Notification: string);
begin
  lbMessage.Caption := Notification;
  pnlMessage.ElementHandle.hidden := False;
end;

procedure TFViewReservEdit.btCloseMessageClick(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewReservEdit.WebFormCreate(Sender: TObject);
begin
  HiddenNotification;
end;

procedure TFViewReservEdit.XDataClientReservGetLoad(Response: TXDataClientResponse);
begin
  ReservDataset.Close;
  ReservDataset.SetJsonData(Response.Result);
  ReservDataset.Open;
  
  ReservDataset.Edit;  
end;

procedure TFViewReservEdit.XDataClientReservDeleteError(
  Error: TXDataClientError);
begin
  ShowNotification(Error.ErrorMessage);
end;

procedure TFViewReservEdit.XDataClientReservDeleteLoad(
  Response: TXDataClientResponse);
begin
  FShowListProc;
end;

procedure TFViewReservEdit.ReservDatasetAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
var
  I: integer;
  ResolveInfo: TResolveInfo;
begin
  for I := 0 to Length(Info.Records) - 1 do
  begin
    ResolveInfo := Info.Records[I];
//    if ResolveInfo.ResolveStatus = TResolveStatus.rsResolveFailed then
    if ResolveInfo.Error <> '' then
    begin
      ShowNotification(ResolveInfo.Error);
      Exit;
    end;
  end;
  FShowListProc;
end;

procedure TFViewReservEdit.btCancelClick(Sender: TObject);
begin
  FShowListProc;
end;

procedure TFViewReservEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbres_Id := TLabel.Create('view.reserv.form.lbres_Id');
  lbcs_Id := TLabel.Create('view.reserv.form.lbcs_Id');
  lbcli_Id := TLabel.Create('view.reserv.form.lbcli_Id');
  lbinv_Id := TLabel.Create('view.reserv.form.lbinv_Id');
  lbres_DateFrom := TLabel.Create('view.reserv.form.lbres_DateFrom');
  lbres_DateTo := TLabel.Create('view.reserv.form.lbres_DateTo');
  lbres_NbrDays := TLabel.Create('view.reserv.form.lbres_NbrDays');
  lbres_AmountToPay := TLabel.Create('view.reserv.form.lbres_AmountToPay');
  lbres_ManualOrOnline := TLabel.Create('view.reserv.form.lbres_ManualOrOnline');
  lbres_CreatedBy := TLabel.Create('view.reserv.form.lbres_CreatedBy');
  lbres_CreatedDate := TLabel.Create('view.reserv.form.lbres_CreatedDate');
  lbres_LastModifiedBy := TLabel.Create('view.reserv.form.lbres_LastModifiedBy');
  lbres_LastModifiedDate := TLabel.Create('view.reserv.form.lbres_LastModifiedDate');
  pnlMessage := TPanel.Create('view.reserv.form.message');
  lbMessage := TLabel.Create('view.reserv.form.message.label');
  btCloseMessage := TButton.Create('view.reserv.form.message.button');
  btCancel := TButton.Create('view.reserv.form.btBack');
  btSave := TButton.Create('view.reserv.form.btSave');
  btDelete := TButton.Create('view.reserv.form.btDelete');
  edres_Id := TDBEdit.Create('view.reserv.form.edres_Id');
  edcs_Id := TDBEdit.Create('view.reserv.form.edcs_Id');
  edcli_Id := TDBEdit.Create('view.reserv.form.edcli_Id');
  edinv_Id := TDBEdit.Create('view.reserv.form.edinv_Id');
  edres_DateFrom := TDBEdit.Create('view.reserv.form.edres_DateFrom');
  edres_DateTo := TDBEdit.Create('view.reserv.form.edres_DateTo');
  edres_NbrDays := TDBEdit.Create('view.reserv.form.edres_NbrDays');
  edres_AmountToPay := TDBEdit.Create('view.reserv.form.edres_AmountToPay');
  edres_ManualOrOnline := TDBEdit.Create('view.reserv.form.edres_ManualOrOnline');
  edres_CreatedBy := TDBEdit.Create('view.reserv.form.edres_CreatedBy');
  edres_CreatedDate := TDBEdit.Create('view.reserv.form.edres_CreatedDate');
  edres_LastModifiedBy := TDBEdit.Create('view.reserv.form.edres_LastModifiedBy');
  edres_LastModifiedDate := TDBEdit.Create('view.reserv.form.edres_LastModifiedDate');
  DataSource := TDataSource.Create(Self);
  ReservDataset := TXDataWebDataSet.Create(Self);
  ReservDatasetres_Id := TIntegerField.Create(Self);
  ReservDatasetcs_Id := TIntegerField.Create(Self);
  ReservDatasetcli_Id := TIntegerField.Create(Self);
  ReservDatasetinv_Id := TIntegerField.Create(Self);
  ReservDatasetres_DateFrom := TDateField.Create(Self);
  ReservDatasetres_DateTo := TDateField.Create(Self);
  ReservDatasetres_NbrDays := TIntegerField.Create(Self);
  ReservDatasetres_AmountToPay := TIntegerField.Create(Self);
  ReservDatasetres_ManualOrOnline := TIntegerField.Create(Self);
  ReservDatasetres_CreatedBy := TIntegerField.Create(Self);
  ReservDatasetres_CreatedDate := TDateTimeField.Create(Self);
  ReservDatasetres_LastModifiedBy := TIntegerField.Create(Self);
  ReservDatasetres_LastModifiedDate := TDateTimeField.Create(Self);
  XDataClientReservGet := TXDataWebClient.Create(Self);
  XDataClientReservDelete := TXDataWebClient.Create(Self);

  lbres_Id.BeforeLoadDFMValues;
  lbcs_Id.BeforeLoadDFMValues;
  lbcli_Id.BeforeLoadDFMValues;
  lbinv_Id.BeforeLoadDFMValues;
  lbres_DateFrom.BeforeLoadDFMValues;
  lbres_DateTo.BeforeLoadDFMValues;
  lbres_NbrDays.BeforeLoadDFMValues;
  lbres_AmountToPay.BeforeLoadDFMValues;
  lbres_ManualOrOnline.BeforeLoadDFMValues;
  lbres_CreatedBy.BeforeLoadDFMValues;
  lbres_CreatedDate.BeforeLoadDFMValues;
  lbres_LastModifiedBy.BeforeLoadDFMValues;
  lbres_LastModifiedDate.BeforeLoadDFMValues;
  pnlMessage.BeforeLoadDFMValues;
  lbMessage.BeforeLoadDFMValues;
  btCloseMessage.BeforeLoadDFMValues;
  btCancel.BeforeLoadDFMValues;
  btSave.BeforeLoadDFMValues;
  btDelete.BeforeLoadDFMValues;
  edres_Id.BeforeLoadDFMValues;
  edcs_Id.BeforeLoadDFMValues;
  edcli_Id.BeforeLoadDFMValues;
  edinv_Id.BeforeLoadDFMValues;
  edres_DateFrom.BeforeLoadDFMValues;
  edres_DateTo.BeforeLoadDFMValues;
  edres_NbrDays.BeforeLoadDFMValues;
  edres_AmountToPay.BeforeLoadDFMValues;
  edres_ManualOrOnline.BeforeLoadDFMValues;
  edres_CreatedBy.BeforeLoadDFMValues;
  edres_CreatedDate.BeforeLoadDFMValues;
  edres_LastModifiedBy.BeforeLoadDFMValues;
  edres_LastModifiedDate.BeforeLoadDFMValues;
  DataSource.BeforeLoadDFMValues;
  ReservDataset.BeforeLoadDFMValues;
  ReservDatasetres_Id.BeforeLoadDFMValues;
  ReservDatasetcs_Id.BeforeLoadDFMValues;
  ReservDatasetcli_Id.BeforeLoadDFMValues;
  ReservDatasetinv_Id.BeforeLoadDFMValues;
  ReservDatasetres_DateFrom.BeforeLoadDFMValues;
  ReservDatasetres_DateTo.BeforeLoadDFMValues;
  ReservDatasetres_NbrDays.BeforeLoadDFMValues;
  ReservDatasetres_AmountToPay.BeforeLoadDFMValues;
  ReservDatasetres_ManualOrOnline.BeforeLoadDFMValues;
  ReservDatasetres_CreatedBy.BeforeLoadDFMValues;
  ReservDatasetres_CreatedDate.BeforeLoadDFMValues;
  ReservDatasetres_LastModifiedBy.BeforeLoadDFMValues;
  ReservDatasetres_LastModifiedDate.BeforeLoadDFMValues;
  XDataClientReservGet.BeforeLoadDFMValues;
  XDataClientReservDelete.BeforeLoadDFMValues;
  try
    Name := 'FViewReservEdit';
    Width := 593;
    Height := 616;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    lbres_Id.SetParentComponent(Self);
    lbres_Id.Name := 'lbres_Id';
    lbres_Id.Left := 24;
    lbres_Id.Top := 192;
    lbres_Id.Width := 35;
    lbres_Id.Height := 13;
    lbres_Id.Caption := 'res_Id:';
    lbres_Id.HeightPercent := 100.000000000000000000;
    lbres_Id.WidthPercent := 100.000000000000000000;
    lbcs_Id.SetParentComponent(Self);
    lbcs_Id.Name := 'lbcs_Id';
    lbcs_Id.Left := 24;
    lbcs_Id.Top := 219;
    lbcs_Id.Width := 30;
    lbcs_Id.Height := 13;
    lbcs_Id.Caption := 'cs_Id:';
    lbcs_Id.HeightPercent := 100.000000000000000000;
    lbcs_Id.WidthPercent := 100.000000000000000000;
    lbcli_Id.SetParentComponent(Self);
    lbcli_Id.Name := 'lbcli_Id';
    lbcli_Id.Left := 24;
    lbcli_Id.Top := 246;
    lbcli_Id.Width := 29;
    lbcli_Id.Height := 13;
    lbcli_Id.Caption := 'cli_Id:';
    lbcli_Id.HeightPercent := 100.000000000000000000;
    lbcli_Id.WidthPercent := 100.000000000000000000;
    lbinv_Id.SetParentComponent(Self);
    lbinv_Id.Name := 'lbinv_Id';
    lbinv_Id.Left := 24;
    lbinv_Id.Top := 273;
    lbinv_Id.Width := 34;
    lbinv_Id.Height := 13;
    lbinv_Id.Caption := 'inv_Id:';
    lbinv_Id.HeightPercent := 100.000000000000000000;
    lbinv_Id.WidthPercent := 100.000000000000000000;
    lbres_DateFrom.SetParentComponent(Self);
    lbres_DateFrom.Name := 'lbres_DateFrom';
    lbres_DateFrom.Left := 24;
    lbres_DateFrom.Top := 300;
    lbres_DateFrom.Width := 72;
    lbres_DateFrom.Height := 13;
    lbres_DateFrom.Caption := 'res_DateFrom:';
    lbres_DateFrom.HeightPercent := 100.000000000000000000;
    lbres_DateFrom.WidthPercent := 100.000000000000000000;
    lbres_DateTo.SetParentComponent(Self);
    lbres_DateTo.Name := 'lbres_DateTo';
    lbres_DateTo.Left := 24;
    lbres_DateTo.Top := 327;
    lbres_DateTo.Width := 60;
    lbres_DateTo.Height := 13;
    lbres_DateTo.Caption := 'res_DateTo:';
    lbres_DateTo.HeightPercent := 100.000000000000000000;
    lbres_DateTo.WidthPercent := 100.000000000000000000;
    lbres_NbrDays.SetParentComponent(Self);
    lbres_NbrDays.Name := 'lbres_NbrDays';
    lbres_NbrDays.Left := 24;
    lbres_NbrDays.Top := 354;
    lbres_NbrDays.Width := 66;
    lbres_NbrDays.Height := 13;
    lbres_NbrDays.Caption := 'res_NbrDays:';
    lbres_NbrDays.HeightPercent := 100.000000000000000000;
    lbres_NbrDays.WidthPercent := 100.000000000000000000;
    lbres_AmountToPay.SetParentComponent(Self);
    lbres_AmountToPay.Name := 'lbres_AmountToPay';
    lbres_AmountToPay.Left := 24;
    lbres_AmountToPay.Top := 381;
    lbres_AmountToPay.Width := 92;
    lbres_AmountToPay.Height := 13;
    lbres_AmountToPay.Caption := 'res_AmountToPay:';
    lbres_AmountToPay.HeightPercent := 100.000000000000000000;
    lbres_AmountToPay.WidthPercent := 100.000000000000000000;
    lbres_ManualOrOnline.SetParentComponent(Self);
    lbres_ManualOrOnline.Name := 'lbres_ManualOrOnline';
    lbres_ManualOrOnline.Left := 24;
    lbres_ManualOrOnline.Top := 408;
    lbres_ManualOrOnline.Width := 101;
    lbres_ManualOrOnline.Height := 13;
    lbres_ManualOrOnline.Caption := 'res_ManualOrOnline:';
    lbres_ManualOrOnline.HeightPercent := 100.000000000000000000;
    lbres_ManualOrOnline.WidthPercent := 100.000000000000000000;
    lbres_CreatedBy.SetParentComponent(Self);
    lbres_CreatedBy.Name := 'lbres_CreatedBy';
    lbres_CreatedBy.Left := 24;
    lbres_CreatedBy.Top := 435;
    lbres_CreatedBy.Width := 76;
    lbres_CreatedBy.Height := 13;
    lbres_CreatedBy.Caption := 'res_CreatedBy:';
    lbres_CreatedBy.HeightPercent := 100.000000000000000000;
    lbres_CreatedBy.WidthPercent := 100.000000000000000000;
    lbres_CreatedDate.SetParentComponent(Self);
    lbres_CreatedDate.Name := 'lbres_CreatedDate';
    lbres_CreatedDate.Left := 24;
    lbres_CreatedDate.Top := 462;
    lbres_CreatedDate.Width := 87;
    lbres_CreatedDate.Height := 13;
    lbres_CreatedDate.Caption := 'res_CreatedDate:';
    lbres_CreatedDate.HeightPercent := 100.000000000000000000;
    lbres_CreatedDate.WidthPercent := 100.000000000000000000;
    lbres_LastModifiedBy.SetParentComponent(Self);
    lbres_LastModifiedBy.Name := 'lbres_LastModifiedBy';
    lbres_LastModifiedBy.Left := 24;
    lbres_LastModifiedBy.Top := 489;
    lbres_LastModifiedBy.Width := 97;
    lbres_LastModifiedBy.Height := 13;
    lbres_LastModifiedBy.Caption := 'res_LastModifiedBy:';
    lbres_LastModifiedBy.HeightPercent := 100.000000000000000000;
    lbres_LastModifiedBy.WidthPercent := 100.000000000000000000;
    lbres_LastModifiedDate.SetParentComponent(Self);
    lbres_LastModifiedDate.Name := 'lbres_LastModifiedDate';
    lbres_LastModifiedDate.Left := 24;
    lbres_LastModifiedDate.Top := 516;
    lbres_LastModifiedDate.Width := 108;
    lbres_LastModifiedDate.Height := 13;
    lbres_LastModifiedDate.Caption := 'res_LastModifiedDate:';
    lbres_LastModifiedDate.HeightPercent := 100.000000000000000000;
    lbres_LastModifiedDate.WidthPercent := 100.000000000000000000;
    pnlMessage.SetParentComponent(Self);
    pnlMessage.Name := 'pnlMessage';
    pnlMessage.Left := 296;
    pnlMessage.Top := 113;
    pnlMessage.Width := 273;
    pnlMessage.Height := 60;
    pnlMessage.HeightPercent := 100.000000000000000000;
    pnlMessage.WidthPercent := 100.000000000000000000;
    pnlMessage.BorderColor := clSilver;
    pnlMessage.BorderStyle := bsSingle;
    lbMessage.SetParentComponent(pnlMessage);
    lbMessage.Name := 'lbMessage';
    lbMessage.Left := 16;
    lbMessage.Top := 24;
    lbMessage.Width := 42;
    lbMessage.Height := 13;
    lbMessage.Caption := 'Message';
    lbMessage.HeightPercent := 100.000000000000000000;
    lbMessage.WidthPercent := 100.000000000000000000;
    btCloseMessage.SetParentComponent(pnlMessage);
    btCloseMessage.Name := 'btCloseMessage';
    btCloseMessage.Left := 248;
    btCloseMessage.Top := 11;
    btCloseMessage.Width := 22;
    btCloseMessage.Height := 25;
    btCloseMessage.Caption := 'x';
    btCloseMessage.HeightPercent := 100.000000000000000000;
    btCloseMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btCloseMessage, Self, 'OnClick', 'btCloseMessageClick');
    btCancel.SetParentComponent(Self);
    btCancel.Name := 'btCancel';
    btCancel.Left := 126;
    btCancel.Top := 556;
    btCancel.Width := 96;
    btCancel.Height := 25;
    btCancel.Caption := 'Cancel';
    btCancel.HeightPercent := 100.000000000000000000;
    btCancel.TabOrder := 1;
    btCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btCancel, Self, 'OnClick', 'btCancelClick');
    btSave.SetParentComponent(Self);
    btSave.Name := 'btSave';
    btSave.Left := 24;
    btSave.Top := 556;
    btSave.Width := 96;
    btSave.Height := 25;
    btSave.Caption := 'Save';
    btSave.HeightPercent := 100.000000000000000000;
    btSave.TabOrder := 4;
    btSave.WidthPercent := 100.000000000000000000;
    SetEvent(btSave, Self, 'OnClick', 'btSaveClick');
    btDelete.SetParentComponent(Self);
    btDelete.Name := 'btDelete';
    btDelete.Left := 305;
    btDelete.Top := 556;
    btDelete.Width := 96;
    btDelete.Height := 25;
    btDelete.Caption := 'Delete';
    btDelete.HeightPercent := 100.000000000000000000;
    btDelete.TabOrder := 5;
    btDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btDelete, Self, 'OnClick', 'btDeleteClick');
    edres_Id.SetParentComponent(Self);
    edres_Id.Name := 'edres_Id';
    edres_Id.Left := 221;
    edres_Id.Top := 189;
    edres_Id.Width := 200;
    edres_Id.Height := 21;
    edres_Id.HeightPercent := 100.000000000000000000;
    edres_Id.TabOrder := 6;
    edres_Id.Text := 'edres_Id';
    edres_Id.WidthPercent := 100.000000000000000000;
    edres_Id.DataField := 'res_Id';
    edres_Id.DataSource := DataSource;
    edcs_Id.SetParentComponent(Self);
    edcs_Id.Name := 'edcs_Id';
    edcs_Id.Left := 221;
    edcs_Id.Top := 216;
    edcs_Id.Width := 200;
    edcs_Id.Height := 21;
    edcs_Id.HeightPercent := 100.000000000000000000;
    edcs_Id.TabOrder := 7;
    edcs_Id.Text := 'edcs_Id';
    edcs_Id.WidthPercent := 100.000000000000000000;
    edcs_Id.DataField := 'cs_Id';
    edcs_Id.DataSource := DataSource;
    edcli_Id.SetParentComponent(Self);
    edcli_Id.Name := 'edcli_Id';
    edcli_Id.Left := 221;
    edcli_Id.Top := 243;
    edcli_Id.Width := 200;
    edcli_Id.Height := 21;
    edcli_Id.HeightPercent := 100.000000000000000000;
    edcli_Id.TabOrder := 8;
    edcli_Id.Text := 'edcli_Id';
    edcli_Id.WidthPercent := 100.000000000000000000;
    edcli_Id.DataField := 'cli_Id';
    edcli_Id.DataSource := DataSource;
    edinv_Id.SetParentComponent(Self);
    edinv_Id.Name := 'edinv_Id';
    edinv_Id.Left := 221;
    edinv_Id.Top := 270;
    edinv_Id.Width := 200;
    edinv_Id.Height := 21;
    edinv_Id.HeightPercent := 100.000000000000000000;
    edinv_Id.TabOrder := 9;
    edinv_Id.Text := 'edinv_Id';
    edinv_Id.WidthPercent := 100.000000000000000000;
    edinv_Id.DataField := 'inv_Id';
    edinv_Id.DataSource := DataSource;
    edres_DateFrom.SetParentComponent(Self);
    edres_DateFrom.Name := 'edres_DateFrom';
    edres_DateFrom.Left := 221;
    edres_DateFrom.Top := 297;
    edres_DateFrom.Width := 200;
    edres_DateFrom.Height := 21;
    edres_DateFrom.HeightPercent := 100.000000000000000000;
    edres_DateFrom.TabOrder := 10;
    edres_DateFrom.Text := 'edres_DateFrom';
    edres_DateFrom.WidthPercent := 100.000000000000000000;
    edres_DateFrom.DataField := 'res_DateFrom';
    edres_DateFrom.DataSource := DataSource;
    edres_DateTo.SetParentComponent(Self);
    edres_DateTo.Name := 'edres_DateTo';
    edres_DateTo.Left := 221;
    edres_DateTo.Top := 324;
    edres_DateTo.Width := 200;
    edres_DateTo.Height := 21;
    edres_DateTo.HeightPercent := 100.000000000000000000;
    edres_DateTo.TabOrder := 11;
    edres_DateTo.Text := 'edres_DateTo';
    edres_DateTo.WidthPercent := 100.000000000000000000;
    edres_DateTo.DataField := 'res_DateTo';
    edres_DateTo.DataSource := DataSource;
    edres_NbrDays.SetParentComponent(Self);
    edres_NbrDays.Name := 'edres_NbrDays';
    edres_NbrDays.Left := 221;
    edres_NbrDays.Top := 351;
    edres_NbrDays.Width := 200;
    edres_NbrDays.Height := 21;
    edres_NbrDays.HeightPercent := 100.000000000000000000;
    edres_NbrDays.TabOrder := 12;
    edres_NbrDays.Text := 'edres_NbrDays';
    edres_NbrDays.WidthPercent := 100.000000000000000000;
    edres_NbrDays.DataField := 'res_NbrDays';
    edres_NbrDays.DataSource := DataSource;
    edres_AmountToPay.SetParentComponent(Self);
    edres_AmountToPay.Name := 'edres_AmountToPay';
    edres_AmountToPay.Left := 221;
    edres_AmountToPay.Top := 378;
    edres_AmountToPay.Width := 200;
    edres_AmountToPay.Height := 21;
    edres_AmountToPay.HeightPercent := 100.000000000000000000;
    edres_AmountToPay.TabOrder := 13;
    edres_AmountToPay.Text := 'edres_AmountToPay';
    edres_AmountToPay.WidthPercent := 100.000000000000000000;
    edres_AmountToPay.DataField := 'res_AmountToPay';
    edres_AmountToPay.DataSource := DataSource;
    edres_ManualOrOnline.SetParentComponent(Self);
    edres_ManualOrOnline.Name := 'edres_ManualOrOnline';
    edres_ManualOrOnline.Left := 221;
    edres_ManualOrOnline.Top := 405;
    edres_ManualOrOnline.Width := 200;
    edres_ManualOrOnline.Height := 21;
    edres_ManualOrOnline.HeightPercent := 100.000000000000000000;
    edres_ManualOrOnline.TabOrder := 14;
    edres_ManualOrOnline.Text := 'edres_ManualOrOnline';
    edres_ManualOrOnline.WidthPercent := 100.000000000000000000;
    edres_ManualOrOnline.DataField := 'res_ManualOrOnline';
    edres_ManualOrOnline.DataSource := DataSource;
    edres_CreatedBy.SetParentComponent(Self);
    edres_CreatedBy.Name := 'edres_CreatedBy';
    edres_CreatedBy.Left := 221;
    edres_CreatedBy.Top := 432;
    edres_CreatedBy.Width := 200;
    edres_CreatedBy.Height := 21;
    edres_CreatedBy.HeightPercent := 100.000000000000000000;
    edres_CreatedBy.TabOrder := 15;
    edres_CreatedBy.Text := 'edres_CreatedBy';
    edres_CreatedBy.WidthPercent := 100.000000000000000000;
    edres_CreatedBy.DataField := 'res_CreatedBy';
    edres_CreatedBy.DataSource := DataSource;
    edres_CreatedDate.SetParentComponent(Self);
    edres_CreatedDate.Name := 'edres_CreatedDate';
    edres_CreatedDate.Left := 221;
    edres_CreatedDate.Top := 459;
    edres_CreatedDate.Width := 200;
    edres_CreatedDate.Height := 21;
    edres_CreatedDate.HeightPercent := 100.000000000000000000;
    edres_CreatedDate.TabOrder := 16;
    edres_CreatedDate.Text := 'edres_CreatedDate';
    edres_CreatedDate.WidthPercent := 100.000000000000000000;
    edres_CreatedDate.DataField := 'res_CreatedDate';
    edres_CreatedDate.DataSource := DataSource;
    edres_LastModifiedBy.SetParentComponent(Self);
    edres_LastModifiedBy.Name := 'edres_LastModifiedBy';
    edres_LastModifiedBy.Left := 221;
    edres_LastModifiedBy.Top := 486;
    edres_LastModifiedBy.Width := 200;
    edres_LastModifiedBy.Height := 21;
    edres_LastModifiedBy.HeightPercent := 100.000000000000000000;
    edres_LastModifiedBy.TabOrder := 17;
    edres_LastModifiedBy.Text := 'edres_LastModifiedBy';
    edres_LastModifiedBy.WidthPercent := 100.000000000000000000;
    edres_LastModifiedBy.DataField := 'res_LastModifiedBy';
    edres_LastModifiedBy.DataSource := DataSource;
    edres_LastModifiedDate.SetParentComponent(Self);
    edres_LastModifiedDate.Name := 'edres_LastModifiedDate';
    edres_LastModifiedDate.Left := 221;
    edres_LastModifiedDate.Top := 513;
    edres_LastModifiedDate.Width := 200;
    edres_LastModifiedDate.Height := 21;
    edres_LastModifiedDate.HeightPercent := 100.000000000000000000;
    edres_LastModifiedDate.TabOrder := 18;
    edres_LastModifiedDate.Text := 'edres_LastModifiedDate';
    edres_LastModifiedDate.WidthPercent := 100.000000000000000000;
    edres_LastModifiedDate.DataField := 'res_LastModifiedDate';
    edres_LastModifiedDate.DataSource := DataSource;
    DataSource.SetParentComponent(Self);
    DataSource.Name := 'DataSource';
    DataSource.DataSet := ReservDataset;
    DataSource.Left := 176;
    DataSource.Top := 56;
    ReservDataset.SetParentComponent(Self);
    ReservDataset.Name := 'ReservDataset';
    ReservDataset.AfterApplyUpdates := ReservDatasetAfterApplyUpdates;
    ReservDataset.EntitySetName := 'Reserv';
    ReservDataset.Connection := DMConnection.ApiConnection;
    ReservDataset.Left := 176;
    ReservDataset.Top := 8;
    ReservDatasetres_Id.SetParentComponent(ReservDataset);
    ReservDatasetres_Id.Name := 'ReservDatasetres_Id';
    ReservDatasetres_Id.FieldName := 'res_Id';
    ReservDatasetres_Id.Required := True;
    ReservDatasetcs_Id.SetParentComponent(ReservDataset);
    ReservDatasetcs_Id.Name := 'ReservDatasetcs_Id';
    ReservDatasetcs_Id.FieldName := 'cs_Id';
    ReservDatasetcli_Id.SetParentComponent(ReservDataset);
    ReservDatasetcli_Id.Name := 'ReservDatasetcli_Id';
    ReservDatasetcli_Id.FieldName := 'cli_Id';
    ReservDatasetinv_Id.SetParentComponent(ReservDataset);
    ReservDatasetinv_Id.Name := 'ReservDatasetinv_Id';
    ReservDatasetinv_Id.FieldName := 'inv_Id';
    ReservDatasetres_DateFrom.SetParentComponent(ReservDataset);
    ReservDatasetres_DateFrom.Name := 'ReservDatasetres_DateFrom';
    ReservDatasetres_DateFrom.FieldName := 'res_DateFrom';
    ReservDatasetres_DateTo.SetParentComponent(ReservDataset);
    ReservDatasetres_DateTo.Name := 'ReservDatasetres_DateTo';
    ReservDatasetres_DateTo.FieldName := 'res_DateTo';
    ReservDatasetres_NbrDays.SetParentComponent(ReservDataset);
    ReservDatasetres_NbrDays.Name := 'ReservDatasetres_NbrDays';
    ReservDatasetres_NbrDays.FieldName := 'res_NbrDays';
    ReservDatasetres_AmountToPay.SetParentComponent(ReservDataset);
    ReservDatasetres_AmountToPay.Name := 'ReservDatasetres_AmountToPay';
    ReservDatasetres_AmountToPay.FieldName := 'res_AmountToPay';
    ReservDatasetres_ManualOrOnline.SetParentComponent(ReservDataset);
    ReservDatasetres_ManualOrOnline.Name := 'ReservDatasetres_ManualOrOnline';
    ReservDatasetres_ManualOrOnline.FieldName := 'res_ManualOrOnline';
    ReservDatasetres_CreatedBy.SetParentComponent(ReservDataset);
    ReservDatasetres_CreatedBy.Name := 'ReservDatasetres_CreatedBy';
    ReservDatasetres_CreatedBy.FieldName := 'res_CreatedBy';
    ReservDatasetres_CreatedDate.SetParentComponent(ReservDataset);
    ReservDatasetres_CreatedDate.Name := 'ReservDatasetres_CreatedDate';
    ReservDatasetres_CreatedDate.FieldName := 'res_CreatedDate';
    ReservDatasetres_LastModifiedBy.SetParentComponent(ReservDataset);
    ReservDatasetres_LastModifiedBy.Name := 'ReservDatasetres_LastModifiedBy';
    ReservDatasetres_LastModifiedBy.FieldName := 'res_LastModifiedBy';
    ReservDatasetres_LastModifiedDate.SetParentComponent(ReservDataset);
    ReservDatasetres_LastModifiedDate.Name := 'ReservDatasetres_LastModifiedDate';
    ReservDatasetres_LastModifiedDate.FieldName := 'res_LastModifiedDate';
    XDataClientReservGet.SetParentComponent(Self);
    XDataClientReservGet.Name := 'XDataClientReservGet';
    XDataClientReservGet.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientReservGet, Self, 'OnLoad', 'XDataClientReservGetLoad');
    XDataClientReservGet.Left := 64;
    XDataClientReservGet.Top := 8;
    XDataClientReservDelete.SetParentComponent(Self);
    XDataClientReservDelete.Name := 'XDataClientReservDelete';
    XDataClientReservDelete.Connection := DMConnection.ApiConnection;
    SetEvent(XDataClientReservDelete, Self, 'OnLoad', 'XDataClientReservDeleteLoad');
    SetEvent(XDataClientReservDelete, Self, 'OnError', 'XDataClientReservDeleteError');
    XDataClientReservDelete.Left := 64;
    XDataClientReservDelete.Top := 56;
  finally
    lbres_Id.AfterLoadDFMValues;
    lbcs_Id.AfterLoadDFMValues;
    lbcli_Id.AfterLoadDFMValues;
    lbinv_Id.AfterLoadDFMValues;
    lbres_DateFrom.AfterLoadDFMValues;
    lbres_DateTo.AfterLoadDFMValues;
    lbres_NbrDays.AfterLoadDFMValues;
    lbres_AmountToPay.AfterLoadDFMValues;
    lbres_ManualOrOnline.AfterLoadDFMValues;
    lbres_CreatedBy.AfterLoadDFMValues;
    lbres_CreatedDate.AfterLoadDFMValues;
    lbres_LastModifiedBy.AfterLoadDFMValues;
    lbres_LastModifiedDate.AfterLoadDFMValues;
    pnlMessage.AfterLoadDFMValues;
    lbMessage.AfterLoadDFMValues;
    btCloseMessage.AfterLoadDFMValues;
    btCancel.AfterLoadDFMValues;
    btSave.AfterLoadDFMValues;
    btDelete.AfterLoadDFMValues;
    edres_Id.AfterLoadDFMValues;
    edcs_Id.AfterLoadDFMValues;
    edcli_Id.AfterLoadDFMValues;
    edinv_Id.AfterLoadDFMValues;
    edres_DateFrom.AfterLoadDFMValues;
    edres_DateTo.AfterLoadDFMValues;
    edres_NbrDays.AfterLoadDFMValues;
    edres_AmountToPay.AfterLoadDFMValues;
    edres_ManualOrOnline.AfterLoadDFMValues;
    edres_CreatedBy.AfterLoadDFMValues;
    edres_CreatedDate.AfterLoadDFMValues;
    edres_LastModifiedBy.AfterLoadDFMValues;
    edres_LastModifiedDate.AfterLoadDFMValues;
    DataSource.AfterLoadDFMValues;
    ReservDataset.AfterLoadDFMValues;
    ReservDatasetres_Id.AfterLoadDFMValues;
    ReservDatasetcs_Id.AfterLoadDFMValues;
    ReservDatasetcli_Id.AfterLoadDFMValues;
    ReservDatasetinv_Id.AfterLoadDFMValues;
    ReservDatasetres_DateFrom.AfterLoadDFMValues;
    ReservDatasetres_DateTo.AfterLoadDFMValues;
    ReservDatasetres_NbrDays.AfterLoadDFMValues;
    ReservDatasetres_AmountToPay.AfterLoadDFMValues;
    ReservDatasetres_ManualOrOnline.AfterLoadDFMValues;
    ReservDatasetres_CreatedBy.AfterLoadDFMValues;
    ReservDatasetres_CreatedDate.AfterLoadDFMValues;
    ReservDatasetres_LastModifiedBy.AfterLoadDFMValues;
    ReservDatasetres_LastModifiedDate.AfterLoadDFMValues;
    XDataClientReservGet.AfterLoadDFMValues;
    XDataClientReservDelete.AfterLoadDFMValues;
  end;
end;

end.
