unit View.UserProfile;

interface

uses
  System.SysUtils, System.Classes, WEBLib.Graphics, WEBLib.Controls, WEBLib.Forms, WEBLib.Dialogs,
  Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TFViewUserProfile = class(TForm)
    WebLabel1: TLabel;
    WebLabel3: TLabel;
    edUser: TEdit;
    WebLabel2: TLabel;
    edJwt: TEdit;
    procedure WebFormShow(Sender: TObject);
  protected procedure LoadDFMValues; override; end;

var
  FViewUserProfile: TFViewUserProfile;

implementation

uses
  JS,
  Auth.Service;

{$R *.dfm}

procedure TFViewUserProfile.WebFormShow(Sender: TObject);
begin
  edUser.Text := JS.toString(AuthService.TokenPayload.Properties['user']);
  edJwt.Text := TJSJSON.stringify(AuthService.TokenPayload);
end;

procedure TFViewUserProfile.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create('view.userprofile.title');
  WebLabel3 := TLabel.Create('view.userprofile.form.lbUser');
  WebLabel2 := TLabel.Create('view.userprofile.form.lbJwt');
  edUser := TEdit.Create('view.userprofile.form.edUser');
  edJwt := TEdit.Create('view.userprofile.form.edJwt');

  WebLabel1.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  edUser.BeforeLoadDFMValues;
  edJwt.BeforeLoadDFMValues;
  try
    Name := 'FViewUserProfile';
    Width := 604;
    Height := 434;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 24;
    WebLabel1.Top := 24;
    WebLabel1.Width := 55;
    WebLabel1.Height := 13;
    WebLabel1.Caption := 'User Profile';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 24;
    WebLabel3.Top := 59;
    WebLabel3.Width := 26;
    WebLabel3.Height := 13;
    WebLabel3.Caption := 'User:';
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 24;
    WebLabel2.Top := 86;
    WebLabel2.Width := 21;
    WebLabel2.Height := 13;
    WebLabel2.Caption := 'Jwt:';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    edUser.SetParentComponent(Self);
    edUser.Name := 'edUser';
    edUser.Left := 56;
    edUser.Top := 56;
    edUser.Width := 121;
    edUser.Height := 21;
    edUser.HeightPercent := 100.000000000000000000;
    edUser.ReadOnly := True;
    edUser.WidthPercent := 100.000000000000000000;
    edJwt.SetParentComponent(Self);
    edJwt.Name := 'edJwt';
    edJwt.Left := 56;
    edJwt.Top := 83;
    edJwt.Width := 121;
    edJwt.Height := 21;
    edJwt.HeightPercent := 100.000000000000000000;
    edJwt.ReadOnly := True;
    edJwt.TabOrder := 1;
    edJwt.WidthPercent := 100.000000000000000000;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    edUser.AfterLoadDFMValues;
    edJwt.AfterLoadDFMValues;
  end;
end;

end.
